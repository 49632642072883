import React, { PureComponent } from 'react'
import CN from 'classnames';

import { withUserContext } from 'Context/UserContext.js';
import { Button, Icon, Checkbox, Notification } from 'Atoms';
import { Lookup } from 'Molecules';
import { CustomerLookup, InternetLookup, LocationCombo, PortLookup, BandwidthLookup, TermLookup, FolderLookup, CarrierLookup, CurrencyLookup, DiversityLookup } from 'Molecules';
import PartnerPricing from './PartnerPricing.jsx';

import { getQuoteOptions, getRecentLocations, getColos, getOrganization, upsertQuote, createQuotes, getSystemStatusNotification, updateUser } from 'Actions/ccm/api.js';
import { withAnalytics, withRouterAndRef } from 'Hooks';
import { getInternetOptions, toSortedQuoteOptions, getPermissions } from './CreateQuoteMapper.js';
import { toRecentLocationOptions } from 'Molecules/Lookups/Location/LocationMapper.js';
import { constrainPortAndBandwidth } from 'Utils/ccm.js';
import { getInternetServiceRequirements, toIpv4Option } from 'Utils/formatter.js';

import './CreateQuote.scss';

class CreateQuote extends PureComponent {
  constructor(props) {
    super(props);
    const { defaultValues={}, validationErrors={}, user, showAdvancedOptions, showPartnerData, allowUpdateEmail=false } = props;
    const partnerDataRequired = props.user.is_csa || props.user.is_sales_representative;

    const getDefaultValues = (opts={}) => Object.assign({
      customer: null,
      locationA: null,
      locationZ: null,
      locationZType: null,
      port: null, // { label: 'Least cost', value: 'least-cost', sortVal: 0 },
      bandwidth: null,
      ipv4: null,
      term: [{ label: '36', value: '36', sortVal: 36, valueType: 'list', constraints: {} }],
      folder: null,
      crossConnectA: false,
      crossConnectZ: false,
      serviceClass: { value: 'best-effort' },
      QinQ: false,
      jumboFraming: false,
      layerOne: false,
      sdWan: false,
      excludedCarriers: [],
      unitasIpEndUserRate: undefined,
      sendUpdateEmail: allowUpdateEmail ? false : undefined, // default unchecked when shown.  Overriden by passing in defaultValues props.
    },
    opts,
    ({ currency: opts?.currency || user.preferences?.default_currency_code || 'USD' }),

    // Do not select a least cost pop by organization default for external users.  Select least-cost instead.
    (!user.is_internal && opts?.locationZ?.popSelectionOrganizationKey)
       ? { locationZ: { value: 'leastCost' } } :
    // Select Unitas Global least cost pops instead of least cost for internal users
    (!!user.is_internal && opts.locationZ?.value === 'leastCost')
       ? { locationZ: { popSelectionOrganizationKey: opts.locationZ.popSelectionOrganizationKey || 'unitas-global' } }
       : {},
    );

    this.state = {
      ...getDefaultValues(defaultValues),

      errors: [],
      validationErrors,
      permissions: {},
      visibleOptions: {},
      folders: [],
      colos: [],
      recentLocations: { locationA: [], locationZ: [] },
      isCreatingQuote: false,
      isGettingQuoteOptions: false,
      showAdvancedOptions,
      partnerDataRequired,
      showPartnerData: showPartnerData || partnerDataRequired,
      outageNotification: null,
      showMultiBandwidth: false,
    };

    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeCurrency = this.onChangeCurrency.bind(this);
    this.callGetQuoteOptions = this.callGetQuoteOptions.bind(this);
    this.callGetRecentLocations = this.callGetRecentLocations.bind(this);
    this.callGetColos = this.callGetColos.bind(this);
    this.callGetOrganization = this.callGetOrganization.bind(this);
    
    this.constrainOptions = this.constrainOptions.bind(this);
    this.getValidationErrors = this.getValidationErrors.bind(this);

    this.toggleAdvancedOptions = this.toggleAdvancedOptions.bind(this);
    this.togglePartnerData = this.togglePartnerData.bind(this);
    this.toggleIpRate = this.toggleIpRate.bind(this);
    this.isButtonDisabled = this.isButtonDisabled.bind(this);
    this.updatePartnerData = this.updatePartnerData.bind(this);
    this.updateNoBid = this.updateNoBid.bind(this);

    this.FormCheckbox = this.FormCheckbox.bind(this);
    this.submit = this.submit.bind(this);
    this.getFormValues = this.getFormValues.bind(this);
  }

  componentDidMount() {
    const { noFetch } = this.props;
    // Use case: ImportErrorsModal will fetch values and errors of failed import row, 
    // so mount placeholder component with prop noFetch until component is remounted with defaultValues and errors
    if (!noFetch) {
      this.callGetQuoteOptions();
      this.callGetRecentLocations();
      this.callGetColos();
      this.callGetOrganization();
      this.callCheckC2fAvailability();
    }
  }

  callGetRecentLocations() {
    getRecentLocations().then(({ locationA, locationZ, error }) => {
      if (locationA.length || locationZ.length) {
        const recentLocations = {
          locationA: toRecentLocationOptions(locationA), 
          locationZ: toRecentLocationOptions(locationZ),
        };

        this.setState({ recentLocations });
      }
    })  
  }

  callGetOrganization() {
    const { user } = this.props;
    const isEditing = !!this.state.id; // edited quote can only have one bandwidth

    getOrganization(user.organization_id).then(org => {
      this.setState({ showMultiBandwidth: (org.may_request_multiple_bandwidths && !isEditing) });
    });
  }

  callCheckC2fAvailability() {
    getSystemStatusNotification().then(outageNotification => this.setState({ outageNotification }))
  }

  callGetColos() {
    getColos({ includeHubs: false }).then(colos => this.setState({ colos }));
  }

  callGetQuoteOptions() {
    const { port, bandwidth } = this.state;

    this.setState({ isGettingQuoteOptions: true });
    getQuoteOptions()
      .then(quoteOptions => {
        // STEP 1 - Map valid state options
        const options = toSortedQuoteOptions(quoteOptions);
        options.term
          .filter(x => x.value !== "1") // Hide month-to-month option
        const permissions = getPermissions(options);

        // STEP 2 - Get dropdown options for lookups
        const visibleOptions = {
          port: options.port,
          bandwidth: options.bandwidth,
          term: options.term,
          ipv4: options.ipv4_addresses.map(toIpv4Option)
        };

        // STEP 3 - Set defaultValues for lookups with options
        const bandwidthSelection = (bandwidth && bandwidth.value && !bandwidth.label) 
          ? options.bandwidth.find(x => x.value === bandwidth.value)
          : bandwidth;

        const portSelection = (port && port.value && !port.label) 
          ? options.port.find(x => x.value === port.value)
          : port;

        // STEP 4 - SET STATE, then constrain options
        this.setState({ 
          permissions,
          options,
          visibleOptions, 
          isGettingQuoteOptions: false,
          bandwidth: bandwidthSelection,
          port: portSelection
         }, this.constrainOptions);
      });
  }

  constrainOptions() {
    const { port, bandwidth, layerOne, options, validationErrors } = this.state;

    const constraints = constrainPortAndBandwidth({ port, bandwidth, minValue: layerOne ? 10000 : 0, options, validationErrors });
    const visibleOptions = { ...this.state.visibleOptions, ...constraints.options };
    this.setState({ visibleOptions, ...constraints.selections });
  }

  onChange(name, callback) {
    const onSetState = () => callback && callback();
    return (option) => {
      const validationErrors = Object.assign({}, this.state.validationErrors, { [name]: undefined });
      this.setState({[name]: option, validationErrors }, onSetState);
    };
  }

  onChangeCheckbox(name, callback) {
    return (event) => this.setState({ [name]: event.target.checked }, () => callback && callback());
  }

  onChangeCurrency(option) {
    const { id, refresh } = this.props.user;
    const currency = option.value;

    if (this.state.currency !== currency) {
      updateUser(id, { preferences: { default_currency_code: currency }}).then(refresh);
      this.setState({ currency });
    }
  }

  FormCheckbox({ name, label, children, onChange, style }) {
    return <Checkbox onChange={this.onChangeCheckbox(name, onChange)} checked={!!this.state[name]} label={label} children={children} style={style} />
  }

  isButtonDisabled() {
    const { customer, locationA, locationZ, term=[], port, bandwidth, isCreatingQuote, isGettingQuoteOptions, options={}} = this.state;
    const internetPermissions = !!(getInternetOptions(options) || []).length;
    const isValidLocations = internetPermissions ? (!!locationA) : (!!locationA && !!locationZ);

    return !(!isCreatingQuote && !isGettingQuoteOptions && customer && isValidLocations && port && bandwidth && term.length);
  }

  getFormValues() {
    const {
      id,
      reference,
      autoName,
      name,
      layerOne,
      locationA, 
      locationZ,
      locationZType,
      customer, 
      port, 
      bandwidth, 
      term, 
      crossConnectA, 
      crossConnectZ, 
      serviceClass,
      ipv4,
      QinQ, 
      jumboFraming,
      sdWan,
      kmz,
      diversity,
      routeProtection,
      folder = {},
      excludedCarriers,
      currency,
      partnerType,
      partner,
      csaRep,
      salesRep,
      priceAsOrganizationKey,
      sendUpdateEmail,
      options,
      permissions,
      unitasIpEndUserRate,
      noBid,
    } = this.state;

    const val = (form) => {
      return form && form.value ? form.value : null;
    }

    const vals = (form=[]) => {
      return (form && form.length) ? form.map(val): undefined;
    }

    const locVal = (loc = {}) => {
      if (!loc || loc?.value === 'leastCost' || !!getInternetServiceRequirements(loc?.value))
        return null;
      if (loc.popSelectionOrganizationKey) return null;

      const { url, id, data_center_url, data_center_identifier, additional_details } = loc;
      if (url)  return { data_center_url: url };
      if (data_center_identifier) return { data_center_identifier, additional_details }
      if (id)   return { id, data_center_url, additional_details }
      return loc;
    }

    const getServiceType = (locZ, layerOne) => {
      const internetTypes = getInternetOptions(options).map(x => x.value);
      return (
        (layerOne) 
          ? 'layer-1-wave' :
        (!internetTypes.length) // No internet options available
          ? 'ethernet' : 
        (!locZ) // No selected locationZ, use default sorted internet option
          ? internetTypes[0] : 
        (internetTypes.includes(locZ.value)) // Use known internetType else ethernet
          ? locZ.value : 'ethernet'
      );
    }

    const multiBandwidthVal = b => b.length > 1 ? vals(b) : val(b[0]);
    const popSelectionOrganizationKey = (locationZ || {}).popSelectionOrganizationKey || null;
    const isDemarcLoc = (loc) => (loc && !loc.data_center_url && (loc.floor || loc.suite));
    const serviceRequirements = getServiceType(locationZ, layerOne);
    const ipProducts = [ 'internet', 'internet-dedicated-access', 'internet-unitas-ip'];
    const enableIpEndUserRate = (
      unitasIpEndUserRate
      && permissions['use-unitas-ip-end-user-rate']
      && ipProducts.includes(serviceRequirements)
    );

    const internetOptions = getInternetOptions(options);
    const isInternet = (
      (!permissions.ethernet && !!internetOptions.length)
      || locationZType === 'internet'
    );

    const form = {
      id,
      reference,
      autoName,
      name: autoName ? undefined : name,
      locationA: locVal(locationA),
      locationZ: locVal(locationZ),
      customerName: val(customer),
      port: val(port),
      bandwidth: Array.isArray(bandwidth) ? multiBandwidthVal(bandwidth) : val(bandwidth),
      term: (term || []).map(i => val(i)),
      ipv4: isInternet ? val(ipv4) : undefined,
      crossConnectA,
      crossConnectZ,
      serviceClass: val(serviceClass),
      QinQ,
      jumboFraming,
      sdWan,
      kmz,
      diversity: val(diversity),
      routeProtection,
      serviceRequirements,
      folder: folder?.url,
      excludedCarriers: vals(excludedCarriers),
      currency,
      partnerType,
      partner,
      csaRep,
      salesRep,
      priceAsOrganizationKey,
      popSelectionOrganizationKey,
      extendedDemarc: permissions['end-user-extended-demarc'] && (
        isDemarcLoc(locationA) || isDemarcLoc(locationZ)
      ),
      unitasIpEndUserRate: enableIpEndUserRate || undefined,
      sendUpdateEmail,
      noBid,
    };

    // const formState = [
    //   'name', 'locationA', 'locationZ', 'customer', 'folder', 'port', 'bandwidth', 'term', 
    //   'crossConnectA', 'crossConnectZ', 'serviceClass', 'QinQ', 'jumboFraming', 'sdWan',
    //   'excludedCarriers', 'currency', 'partnerType', 'partner', 'csaRep', 'salesRep'
    // ];
    return form;
  }

  getValidationErrors(form) {
    const { partnerDataRequired, options, layerOne, locationZ, locationZType } = this.state;
    const internetPermissions = !!(getInternetOptions(options) || []).length;
    
    const layer1Validation = () => ({
      bandwidth: !['10-gbps', '40-gbps', '100-gbps'].includes(form.bandwidth || '') && 'Unsupported speed for Layer 1/Wave',
      port: !['least-cost', '10-gb', '100-gb'].includes(form.port || '') && 'Unsupported speed for Layer 1/Wave',
    });
    
    const isValidLocationZ = (
      !!form.locationZ 
      || internetPermissions 
      || locationZ?.value === "leastCost"
    ) && !(
      locationZType === 'leastCostByOrganization' && !locationZ?.value
    );

    const validationErrors = {
      //customer: !form.customer && 'Please enter a customer name.',
      locationA: !form.locationA && 'Please select a "From" location.',
      locationZ: !isValidLocationZ && 'Please select a "To" location.',
      port: !form.port && 'Please select a port speed.',
      bandwidth: !form.bandwidth && 'Please select a bandwidth speed.',
      term: (!form.term || !form.term.length) && 'Please select a monthly term.',
      partnerData: (partnerDataRequired && (!form.partner || !form.salesRep)) && 'Please provide required opportunity details to ensure accurate pricing and reporting.',
      ...(layerOne && form.bandwidth && form.port && layer1Validation()),
    };

    // Filter out falsey values and reassemble as object
    return Object.entries(validationErrors)
      .filter(([key, val]) => !!val)
      .reduce((acc, [key, val]) => { acc[key] = val; return acc; }, {});
  }

  submit() {
    // disable button
    const { showMultiBandwidth, showPartnerData, isCreatingQuote, isGettingQuoteOptions } = this.state;

    if (isCreatingQuote || isGettingQuoteOptions) return;

    const form = this.getFormValues();
    const validationErrors = this.getValidationErrors(form);
    const hasMultiBandwidth = showMultiBandwidth && Array.isArray(form.bandwidth) && form.bandwidth.length > 1;

    const redirectToQuote = ({ reference }) => this.props.history.push(`/quote/${reference}/`);
    const redirectToFolder = ({ folderName }) => this.props.history.push(`/quote-manager/${encodeURIComponent(folderName)}/`);

    const onSubmit = this.props.onSubmit || (hasMultiBandwidth ? redirectToFolder : redirectToQuote);
    const upsert = hasMultiBandwidth ? createQuotes : upsertQuote; 

    if (Object.keys(validationErrors).length) {
      return this.setState({ 
        validationErrors, 
        showPartnerData: (!!validationErrors.partner || showPartnerData)
      });
    }

    this.setState({ isCreatingQuote: true }, () => {
      upsert(form).then(resp => {
        if (resp.error) {
          const errors = this.getCreateQuoteErrors(resp.error);
          return this.setState({ errors, isCreatingQuote: false });
        }

        const category = form.reference ? 'Update Quote' : 'Create Quote';
        this.props.trackEvent({ category, action: 'click' });
        onSubmit(resp);
      })
    });
  }

  // TODO: This functionality is possibly made redundent by validationErrors or should be integrated with it.
  // Will keep for now but look to depreciate in the future. (5/13/19)
  getCreateQuoteErrors({ specification, location_a, location_z }) {
    const unhandledError = ['Unhandled Error. Please contact support.'];
    const errors = [];

    if (specification && specification.option_selections) {
      errors.push(Object.entries(specification.option_selections).map(([key, val]) => `${key}: ${val}`));
    }
    if (location_a) {
      errors.push('Issue validating "From" location. Please contact support.');
    }
    if (location_z) {
      errors.push('Issue validating "To" location. Please contact support.');
    }
    return errors.length ? errors : unhandledError;
  }

  toggleAdvancedOptions() {
    const showAdvancedOptions = !this.state.showAdvancedOptions;
    this.setState({ showAdvancedOptions });
    this.props?.onToggleAdvancedOptions?.({ expanded: showAdvancedOptions });
  }

  togglePartnerData() {
    this.setState({ showPartnerData: !this.state.showPartnerData });
  }

  toggleIpRate() {
    this.setState({ unitasIpEndUserRate: !this.state.unitasIpEndUserRate });
  }

  updatePartnerData(data) {
    const { partnerType, csaRep, salesRep } = data;
    const { partnerData, ...validationErrors } = this.state.validationErrors || {};
    this.setState({ partnerType, partner: (data.partner || {}).url, csaRep, salesRep, validationErrors });
  }

  updateNoBid(noBid) {
    this.setState({ noBid });
  }

  render() {
    const { 
      togglePartnerData, toggleAdvancedOptions, 
      updatePartnerData, updateNoBid,
      onChange, onChangeCurrency, 
      submit, FormCheckbox, constrainOptions,
    } = this;

    const { 
      permissions, customer, locationA, locationZ, diversity, 
      showAdvancedOptions, partnerDataRequired, showPartnerData, 
      visibleOptions, folders, colos, errors, validationErrors, 
      folder, isCreatingQuote, excludedCarriers, outageNotification, 
      currency, showMultiBandwidth, recentLocations, isGettingQuoteOptions, 
      options={}, ipv4,
    } = this.state;
  
    const { defaultValues={}, user, oboOrgId, allowUpdateEmail } = this.props;

    const isInternal = !!user.is_internal;

    const togglerClassName = (isVisible) => CN('toggler button-link', { 'toggler--collapse': isVisible });
    const toggledClassName = (isVisible, cn='') => CN(`toggleable ${cn}`, { 'toggleable--collapsed': !isVisible });
    const constrainOnChange = type => onChange(type, constrainOptions);
    const changeLocationZType = opt => this.setState({ locationZType: opt.value });

    const dropdownProps = type => ({
      onChange: constrainOnChange(type),
      options: visibleOptions[type],
      value: this.state[type],
      error: validationErrors[type],
      isLoading: isGettingQuoteOptions,
    });

    const multiBandwidthProps = { isMulti: true, onChange: onChange('bandwidth'), onMenuClose: constrainOptions, multiSortKey: 'sortVal' };
    const partnerPricingErrors = { partnerData: validationErrors.partnerData };
    const internetOptions = getInternetOptions(options);

    const isInternet = (
      (!permissions.ethernet && !!internetOptions.length)
      || this.state.locationZType === 'internet'
    );

    return (
      <div className="widget-create-quote">

        { !!errors.length && (
          <ul className="widget-create-quote-errors">
            { errors.map((e, i) => <li key={i} children={e} /> )}
          </ul>
        )}

        { !!outageNotification && (
          <Notification.Warning children={outageNotification} />
        )}

        <div className="widget-create-quote__top-row">
          <div className="widget-create-quote__currency-lookup">
            <span className="widget-create-quote__currency-label">Currency:</span>
            <CurrencyLookup onChange={onChangeCurrency} defaultValue={currency} />
          </div>

          {(!!isInternal && !partnerDataRequired) && (
            <Button onClick={togglePartnerData} className={togglerClassName(showPartnerData)}>
              Opportunity Details <Icon name="caret-down" />
            </Button>
          )}
        </div>

        {(!!isInternal || !!partnerDataRequired) &&  (
          <div className={toggledClassName(showPartnerData, 'partner-data-wrapper')}>
            <PartnerPricing updatePartnerData={updatePartnerData} updateNoBid={updateNoBid} defaultValues={defaultValues.pricing} errors={partnerPricingErrors} />
          </div>
        )}

        <div className="lookups">
          <CustomerLookup onChange={onChange('customer')} error={validationErrors.customer} defaultValue={customer} organizationId={oboOrgId || user.organization_id} />
          <LocationCombo name="locationA" label="From" onChange={onChange('locationA')} colos={colos} error={validationErrors.locationA} defaultValue={locationA} allowInvalidInput={true} allowDemarc={permissions['end-user-extended-demarc']} defaultOptions={recentLocations.locationA} />
          <div className="location-combo-container">
            {(!!permissions.ethernet || !internetOptions.length)
              ? <LocationCombo name="locationZ" label="To" onChange={onChange('locationZ')} 
                  onChangeType={changeLocationZType}
                  colos={colos} 
                  error={validationErrors.locationZ} 
                  showLeastCost={!isInternal} showLeastCostByOrg={isInternal}
                  showInternet={!!internetOptions.length} internetOptions={internetOptions} 
                  defaultValue={locationZ} 
                  defaultOptions={recentLocations.locationZ} 
                  allowInvalidInput={true}
                  allowDemarc={permissions['end-user-extended-demarc']}
                  ipRateSelection={this.state.unitasIpEndUserRate}
                  toggleIpRate={(permissions['use-unitas-ip-end-user-rate'] && this.toggleIpRate )}

                />
              : <InternetLookup 
                  name="locationZ" 
                  options={internetOptions}
                  onChange={onChange('locationZ')}
                  ipRateSelection={this.state.unitasIpEndUserRate}
                  toggleIpRate={(permissions['use-unitas-ip-end-user-rate'] && this.toggleIpRate )}
                />
            }
          {!!isInternet && (
            <div className="ipv4-container">
              <Lookup 
                {...dropdownProps('ipv4')}
                formatOptionLabel={(opt) => <>{opt?.label}{!!opt?.value && opt.value !== 'cidr-30' && <sup>*</sup>}</>}
                label="IPv4 Addresses"
                onChange={opt => this.setState({ ipv4: opt })}
              />
              {!!ipv4?.value && ipv4.value !== 'cidr-30' && (
                <div className="widget-create-quote__additional-costs">
                  <sup>*</sup>Additional costs may apply
                </div>
              )}
            </div>
          )}
          </div>
        </div>

        <div className="options">
          <BandwidthLookup {...dropdownProps('bandwidth')} {...(showMultiBandwidth && multiBandwidthProps)} />
          <PortLookup {...dropdownProps('port')} />
          <TermLookup {...dropdownProps('term')} onChange={onChange('term')} />
        </div>

        <div className="advanced-options-toggler-container">
          <Button onClick={toggleAdvancedOptions} className={togglerClassName(showAdvancedOptions)}>
            Advanced options <Icon name="caret-down" />
          </Button>
          {!!allowUpdateEmail && (
          <div>
            <FormCheckbox name="sendUpdateEmail" label="Send an email update" />
          </div>
        )}

        </div>

        <div className={toggledClassName(showAdvancedOptions, 'advanced-options')} aria-hidden={!showAdvancedOptions}>
          <div className="lookups advanced-options-lookups">
            <FolderLookup name="folder" label="Save Quote to Folder" onChange={onChange('folder')} options={folders} defaultValue={folder} ownerId={user.id} />
            <CarrierLookup name="excluded-carriers" label="Excluded Carriers" icon="not-allowed" onChange={onChange('excludedCarriers')} defaultValue={excludedCarriers} asChips hideSelectedOptions />
            {( permissions['carrier-diverse'] || permissions['route-diverse'] || permissions['route-diverse-same-carrier'] ) && (
              <DiversityLookup onChange={onChange('diversity')} value={diversity} />
            )}
            {/* { permissions.serviceClass && (
              <ServiceClassLookup onChange={onChange('serviceClass')} defaultValue={serviceClass} />
            )} */}
          </div>
          
          <div className="advanced-options-groups">
            {(permissions['sd-wan'] || permissions['layer-1-wave']) && (
              <div className="advanced-options-group">
                <h3>Service Options</h3>
                { permissions['sd-wan'] && <FormCheckbox name="sdWan" label="SD-WAN Overlay" /> }
                { permissions['layer-1-wave'] && (
                  <FormCheckbox name="layerOne" onChange={constrainOptions}>
                    Layer 1 Wave products only<div className="checkbox__description">(For 10 GigE and 100 GigE port speeds only)</div>
                  </FormCheckbox>
                )}
              </div>
            )}
            {(permissions.sourcecrossconnect || permissions.destcrossconnect) && (
              <div className="advanced-options-group">
                <h3>Cross Connects</h3>
                { permissions.sourcecrossconnect && <FormCheckbox name="crossConnectA" label='At From location' /> }
                { permissions.destcrossconnect && <FormCheckbox name="crossConnectZ" label='At To location' /> }
              </div>
            )}
            {(permissions['q-in-q'] || permissions['jumbo-framing']) && (
              <div className="advanced-options-group">
                <h3>Packet Options</h3>
                { permissions['q-in-q'] && <FormCheckbox name="QinQ" label="Q-in-Q" /> }
                { permissions['jumbo-framing'] && <FormCheckbox name="jumboFraming" label="Jumbo Framing" /> }
              </div>
            )}
            {(permissions['kmz-file'] || permissions['route-protection']) && (
              <div className="advanced-options-group">
                <h3>Routing Requirements</h3>
                { permissions['kmz-file'] && <FormCheckbox name="kmz" label="KMZ File" /> }
                {/* { permissions['route-diverse'] && 
                  <FormCheckbox name="routeDiverse" label="Route Diverse" onChange={onChangeRd} /> 
                }

                { permissions['route-diverse-same-carrier'] && 
                  <FormCheckbox name="routeDiverseSameCarrier" label="Restricted to same carrier" onChange={onChangeRdsc}
                    style={{ fontSize: '.75rem', marginLeft: '1.5rem', marginTop: '-.5rem' }} 
                  /> 
                } */}
                { permissions['route-protection'] && <FormCheckbox name="routeProtection" label="Route Protection" /> }
              </div>
            )}
          </div>
        </div>

        <div className="create-quote-button-container">
          <Button className="create-quote-button" onClick={submit} disabled={isCreatingQuote}>Generate Quote</Button>
        </div>
      </div>
    )
  }
}

export default withAnalytics(withUserContext(withRouterAndRef(CreateQuote)));

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export const useTimeout = (callback, timerDelay=0) => {
  const [ timerId, setTimerId ] = useState();

  const savedCallback = useRef(callback);

  const methods = useMemo(() => ({
    clear: () => timerId && setTimerId(clearTimeout(timerId)), // set to undefined
    update: (newCallback, newDelay) => {
      methods.clear();
      savedCallback.current = newCallback;
      setTimerId(setTimeout(() => savedCallback.current(), newDelay));
    }
  }), [timerId, savedCallback]);

  const { clear, update } = methods;

  const start = useCallback(() => update(callback, timerDelay), [callback, timerDelay, update]);

  useEffect(() => clear(), [clear]);

  return { clear, start, update };
}
import React from 'react';
import { LabelledInput } from 'Atoms';

const roundTo = (value, decimals) => Number(Math.round(value + 'e'+decimals)+'e-'+decimals)+'';

const PercentageInput = (props) => {
  const { value, ...otherProps } = props;
  const formatted = (!value || isNaN(value)) 
    ? '0' 
    : roundTo((value * 100), 2).replace('.00', '');

  return (
    <LabelledInput className="percentage-input" value={formatted} type="number" label="%" {...otherProps} />
  )
}

export default PercentageInput;
import React from 'react';
import Bowser from 'bowser';

import { EmailLink } from 'Atoms';
import { SYSTEM_NAME, PRICING_SUPPORT_EMAIL, TECH_SUPPORT_EMAIL } from 'Utils/branding.js';


const Pricing = ({ children, to={PRICING_SUPPORT_EMAIL}, ...props }) => {
  const emailProps = {
    to,
    children: children || 'Pricing Questions',
    ...props 
  };

  return <EmailLink {...emailProps} />;
};

const Technical = ({ children, to=`${TECH_SUPPORT_EMAIL}`, ...props }) => {
  const getBody = () => {  
    const info = Bowser.getParser(window.navigator.userAgent);
    const { browser={}, os={}, platform={} } = (info.parsedResult || {});
  
    return (`${SYSTEM_NAME} Support Team,`
      + `\n\nRequesting support for user environment `
      + `${browser.name} ${browser.version} on ${os.name} ${os.version} ${platform.type}...`
      + '\n\n(Please provide as much information as possible about your support issue.)'
    );
  };

  const emailProps = {
    to,
    children: children || 'Need Help?',
    subject: `${SYSTEM_NAME} Support Questions`,
    body: getBody(),
    ...props 
  };

  return <EmailLink {...emailProps} />;
}

const SupportLinks = { Pricing, Technical }; 

export default SupportLinks;
import React, { createRef } from 'react';
import CN from 'classnames';
import sortBy from 'lodash/sortBy';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import Createable from 'react-select/creatable';
import { Checkbox, Icon, ErrorIcon } from 'Atoms';
import LookupTooltip from './LookupTooltip.jsx';

import './Lookup.scss';

const Lookup = props => {
  const { icon, readOnly, label = '', className='', placeholder='', editable=false, name, error, async, createable, components = {}, inputRef, closeMenuOnSelect, getOptionValue, multiSortKey='label', isDisabled, ...selectProps } = props;

  const _inputRef = inputRef || createRef();

  const _getOptionValue = option =>
    (getOptionValue) ? getOptionValue(option) : 
    (typeof option === 'string') ? { label: option, value: option } :
    (option.value || option.label);

  const LookupComponent = (async && createable) ? AsyncCreatable : (async) ? AsyncSelect : (createable) ? Createable : Select;
  const classNames = CN(`lookup react-select ${className}`, { 
    error, 
    [name]: !!name, 
    'react-select--editable': editable,
    'react-select--read-only': readOnly,
    'react-select--multi-chips': selectProps.asChips,
   });

  const styles = {
    input: css => ({ ...css, flex: '1 1 auto' })
  };

  return (
    <div className={classNames}>
      <LookupTooltip inputRef={_inputRef}>
        <LookupComponent
          name={name}
          inputId={`${name}Input`}
          classNamePrefix="react-select"
          styles={styles}
          components={{
            Group,
            GroupHeading,
            IndicatorSeparator: null,
            Input,
            MultiValue,
            // Menu,
            MenuList,
            Option,
            Placeholder: () => null,
            ValueContainer,
            ...components
          }}
          icon={icon}
          label={label}
          error={error}
          editable={editable}
          placeholder={placeholder}
          closeMenuOnSelect={!selectProps.isMulti && closeMenuOnSelect}
          getOptionValue={_getOptionValue}
          hideSelectedOptions={false}
          joinValues={true}
          multiSortKey={multiSortKey}
          ref={_inputRef}
          getRef={() => _inputRef}
          isDisabled={isDisabled || readOnly}
          // formatOptionLabel={this.props.isMulti ? formatMultiOptionLabel : this.props.formatOptionLabel}

          { ...selectProps }
//x          menuIsOpen
        />
      </LookupTooltip>

      {!!error && <div className="react-select--error">{error}</div> }
    </div>
  )
}

const delimitedValue = (values, sortKey) => (
  values && sortKey && sortBy(values, sortKey).map(x => x.label).join(', ')
);

const ValueContainer = ({ children, ...props }) => {
  const { icon, error, inputId, label, isSearchable, controlShouldRenderValue } = props.selectProps;
  const classNames = {
    icon: 'react-select__icon',
    label: CN('react-select__label', { 'react-select__label--no-value-rendered': !controlShouldRenderValue }),
    value: CN('react-select__value', { 'react-select__value--no-label': !label }),
    valueContainer: CN({ 'react-select__value-container--not-searchable': !isSearchable }),
  };

  return (
    <components.ValueContainer {...props} className={classNames.valueContainer}>
      {(!!icon) && (error
        ? <ErrorIcon />
        : <Icon className={classNames.icon} name={icon} />
      )}
      <div className={classNames.value}>
        { children }
        { !!label && <label htmlFor={inputId} className={classNames.label}>{label}</label> }
      </div>
    </components.ValueContainer>
  );
};

const Option = ({ children, ...props}) => {
  const { isSelected, value, isMulti, data } = props;
  const { icon, iconClassName='', className='' } = props.data;
  const optionTextClassName = CN('react-select__option-text', className, { 'react-select__option-text--no-icon': !icon });

  const onClickMultiOption = (e) => {
    props.selectOption({...data});
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <components.Option {...props}>
      { icon && <Icon className={iconClassName} name={icon} /> }
      { isMulti ? (
        <div onClick={onClickMultiOption}>
          <Checkbox checked={isSelected} label={children} value={value} />
        </div>
      ) : (
        <div className={optionTextClassName} children={children} />
      )}
    </components.Option>
  );
}

const MultiValue = (props) => {
  const { getValue, index, selectProps } = props;
  const { multiSortKey, formatMultiLabel, asChips } = selectProps;

  if (asChips) return <components.MultiValue {...props} />;
  if (index) return null;

  const values = getValue();

  const children = formatMultiLabel ? formatMultiLabel(values) : delimitedValue(values, multiSortKey);

  return (
    <components.SingleValue {...props} children={children} />
  );
}

const Menu = (props) => {
  const { MenuHeader=null, MenuFooter=null } = props.selectProps.components;

  return (
    <>
      { MenuHeader }
      <components.Menu {...props} />
      { MenuFooter }
    </>
  );
};

const MenuList = (props) => {
  const { MenuListHeader=null, MenuListFooter=null } = props.selectProps.components;

  return (
    <components.MenuList {...props}>
      { MenuListHeader }
      { props.children }
      { MenuListFooter }
    </components.MenuList>
  );
};

const Input = (props) => {
  const { placeholder, label, maxLength } = props.selectProps;
  let inputProps = { ...props, maxLength };

  if (!!placeholder && !!label) {

    inputProps.onFocus = e => {
      const target = e.target;
      setTimeout(() => { target.placeholder = placeholder }, 200);
      //https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164
      target.autocomplete = 'chrome-off';
      props.onFocus(e);
    };

    inputProps.onBlur = e => {
      const target = e.target;
      
      e.target.placeholder = '';
      // In case blur does not happen before onFocus timeout
      setTimeout(() => { target.placeholder = '' }, 200); 
      
      props.onBlur(e);
    };
  }
  
  return (
    <components.Input {...inputProps} />
  );
};

const Group = props => {
  const { options, label, ...data } = props.data;
  const headingProps = { ...props.headingProps, data }
  
  return <components.Group {...props} headingProps={headingProps} />
}

const GroupHeading = ({ data:{ icon }, children, ...props }) => (
  <components.GroupHeading {...props} >
    {!!icon && <Icon name={icon} />}
    {children}
  </components.GroupHeading>
)


export default React.forwardRef((props, ref) => <Lookup inputRef={ref} {...props} />);
import React from 'react';
import './FooterLinks.scss';

const FooterLinks = () => (
  <ul className="footer-links">
    <li><a href="/usage-and-privacy" target="_blank">Acceptable Use & Privacy Policy</a></li>
    <li><a href="/terms-and-conditions" target="_blank">Terms & Conditions</a></li>
  </ul>
)

export default FooterLinks;
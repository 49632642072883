import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { withAuthContext } from "Context/AuthContext.js";

class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = { authenticated: null };

    document.title = 'Signing out...';
  }

  async componentDidMount() {
    const authenticated = await this.props.isAuthenticated();
    this.setState({ authenticated })
  }

  componentDidUpdate() {
    if (this.state.authenticated) {
      this.props.logout('/login' + window.location.search);
    }
  }

  componentDidCatch() {
    return <Redirect to={'/login' + window.location.search} />
  }

  render() {
    return (this.state.authenticated === false) 
      ? <Redirect to={'/login' + window.location.search} /> 
      : null;
  }
}

export default withAuthContext(Logout);
import React, { Component } from 'react';
import { MenuProvider } from 'react-contexify';
import { withUserContext } from 'Context/UserContext.js';
import { withModalContext } from 'Context/ModalContext.js';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { parse, stringifyUrl } from 'query-string';
import { Breadcrumbs, Button, Pager, PagerStatus, Notification, ContextMenu } from 'Atoms';
import { MODALS } from 'Molecules';
import { QuoteManager } from 'Widgets';

import QuoteManagerFilter from './QuoteManagerFilter.jsx';
import { queryStringify } from './QuoteManagerMapper.js';

import { decodeURIComponentSafe, slugify, count } from 'Utils/strings.js';
import { DefFavicon, DefFaviconName, PAGE_TITLE_NAME } from 'Utils/branding.js';
import { PERMISSIONS, STORAGE_KEYS } from 'Utils/const.js';
import { assignFolder, getQuotesAndFolders, exportQuotes, exportFolder, priceQuote, updateQuote, priceFolder, getFolders, getQuoteBatches, getAssignableUsers } from 'Actions/ccm/api.js';
import { exportRateTest } from 'Actions/analysis/api.js';

import '../page.scss';
import './QuoteManager.scss';

class QuoteManagerPage extends Component {
  constructor(props) {
    super(props);

    // Filtering query params -> f: folderId, s: status, u: userId
    const { ownerId, folderName } = props.match.params;
    const showingFilterView = (props.location.search && props.location.search !== `?u=${props.user.id}`)

    this.folders = [];
    this.dotFolder = null;
    this.notificationTimer;
    this.abortController = null;

    this.state = {
      ownerId,
      isFetchingFolderId: false,
      folderId: null,
      folderName,
      items: [],
      isLoading: true,

      filterUser: null,
      filterOrg: null,
      filterStatus: [],
      filterRegion: null,
      filterService: null,

      page: props.page || 1,
      pageSize: props.pageSize || 50,
      ordering: props.ordering || '-agg_modified_time,-id',
      pageCount: 0,
      totalItems: 0,

      showingFilterView,
      hideTable: false,
      actionNotification: null,
      notificationType: null,
      exporting: [],
      importing: false,
      assignableUsers: {},
    };

    this.fetchData = this.fetchData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.dismissNotification = this.dismissNotification.bind(this);
    this.fetchFolderId = this.fetchFolderId.bind(this);

    this.notify = this.notify.bind(this);

    this.onClickNewQuote = this.onClickNewQuote.bind(this);
    this.onCreateFolder = this.onCreateFolder.bind(this);
    this.onImportQuotes = this.onImportQuotes.bind(this);
    this.onMoveQuote = this.onMoveQuote.bind(this);
    this.onRenameItem = this.onRenameItem.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.onRepriceQuote = this.onRepriceQuote.bind(this);
    this.onRepriceFolder = this.onRepriceFolder.bind(this);
    this.onExportQuote = this.onExportQuote.bind(this);
    this.onExportFolder = this.onExportFolder.bind(this);
    this.onHandleImportError = this.onHandleImportError.bind(this);
    this.onExportRateTest = this.onExportRateTest.bind(this);

    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.toggleFilterView = this.toggleFilterView.bind(this);
    this.onSort = this.onSort.bind(this);

    this.NoQuotesNotification = this.NoQuotesNotification.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.getImporting = this.getImporting.bind(this);
    this.getRowData = this.getRowData.bind(this);
    this.importingPoller = this.importingPoller.bind(this);
    this.breadcrumbs = this.breadcrumbs.bind(this);
  }

  getContextMenuItems() {
    const { onExportFolder, onExportQuote, onRepriceQuote, onRepriceFolder, onRenameItem, onHandleImportError, onDeleteItem, onMoveQuote, fetchData, onExportRateTest, showTransferModal, showTransferFolderModal, notify } = this;
    const { showModal, user, history } = this.props;
    const { folderId, isLoading, items, assignableUsers } = this.state;
    const { RENAME, DELETE, MOVE, IMPORT_ERROR, CLONE_FOLDER, TRANSFER_QUOTE, TRANSFER_FOLDER } = MODALS;
    const filterUser = parse(this.props.location.search || {}).u;
    const lockedQuoteStatuses = ['closed', 'installed', 'ordering-pending-approval', 'ordered', 'pending', 'provisioning'];

    const disabled = ({ props }) => lockedQuoteStatuses.includes(props.status);
    const disabledExport = !!isLoading || !items || !items.length;
    const disableResolve = filterUser && filterUser != user.id;
    const disabledTransfer = ({ props }) => lockedQuoteStatuses.includes(props.status);

    const onCloneFolder = ({ name }) => history.replace(`/quote-manager/${encodeURIComponent(name)}/`);
    const onAssignQuote = (name, email) => async ({ props }) => {
      this.setState({ isLoading: true });
      const { error } = await updateQuote(props.id, { assignee_email: email });
      this.setState({ isLoading: false });

      if (error) {
        return alert(`Unable to assign quote to user: ${error}`);
      }

      notify(<>Quote <b>{props.name}</b> has been assigned to <b>{name}</b></>);
      fetchData();
    };

    const onAssignFolder = (name, email, folderId) => async ({ props }) => {
      const id = props.id || folderId;

      this.setState({ isLoading: true });
      const { error } = await assignFolder(id, email);
      this.setState({ isLoading: false });

      if (error) {
        return alert(`Unable to assign folder to user: ${error}`);
      }
      notify(<>Folder <b>{props.name}</b> has been {!email ? 'unassigned' : <>assigned to <b>{name}</b></>}</>);
      fetchData();
    };

    const hidden = {
      assign: !user.is_pricing_desk,
      developerSubmenu: !user.is_developer,
      exportSubmenu: !user.is_internal,
      move: !!user.isEcx || !!user.isPF,
      reprice: !user.hasPermission(PERMISSIONS.REPRICE_QUOTE),
      transfer: !user.is_internal && !user.is_org_manager,
    };

    const isSelected = name => ({ assigned }) => assigned && assigned === name;
    const assignees = Object.entries(assignableUsers);

    const assigneeQuoteItems = assignees.map(([email, name]) => ({
      label: name, isSelected: isSelected(name), onClick: onAssignQuote(name, email)
    }));

    const assignFolderItems = [ [ null, 'Unassigned' ], [ 'separator' ], ...assignees ]
      .map(([email, name]) => (email === 'separator' 
        ? { separator: true }
        : { label: name, onClick: onAssignFolder(name, email, folderId) }
    ));

    const onTransferQuote = ({ transferredTo, reference }) => {
      notify(<span>Ownership of quote <b>{reference}</b> has been transferred successfully to <b>{transferredTo}</b></span>);
    };

    const onTransferFolder = ({ failedQuotes, newFolderName, transferredTo }) => {
      const result = !newFolderName ? 'error' : failedQuotes ? 'warning' : 'success';
      const notifications = {
        error: <span>Unable to transfer folder. Please verify the folder's quotes have all been priced and are on behalf of the selected user's organization.</span>,
        warning: (
          <span><b>{newFolderName}</b> was created and all but {failedQuotes} quote(s) were unable to be transferred.
            Please verify the folder's remaining quotes have all been priced and are on behalf of the selected user's organization.
          </span>
        ),
        success: <span>Ownership of folder <b>{newFolderName}</b> and all its quotes have been transferred successfully to <b>{transferredTo}</b></span>,
      };

      notify(notifications[result], 7500, result);
    };

    const menuItems = {
      folder: [
        { label: 'Batch Clone', icon: 'copy', onClick: showModal(CLONE_FOLDER, { onConfirm: onCloneFolder }), hidden: hidden.reprice },
        { label: 'Rename', icon: 'pencil', onClick: showModal(RENAME, { onConfirm: onRenameItem }) },
        { label: 'Reprice', icon: 'refresh-price', onClick: onRepriceFolder, hidden: hidden.reprice },
        { label: 'Transfer', icon: 'key', onClick: showModal(TRANSFER_FOLDER, { onConfirm: onTransferFolder }), hidden: hidden.transfer },
        { submenu: "Assign", icon: 'user', hidden: hidden.assign, items: assignFolderItems },
        hidden.exportSubmenu ?
        { label: 'Export', icon: 'export', onClick: onExportFolder() } :
        { submenu: "Export", icon: 'export', items: [
          { label: 'Internal view', onClick: onExportFolder(false, true) },
          { label: 'Internal view showing all designs', onClick: onExportFolder(false, false) },
          { label: 'External view', onClick: onExportFolder(true, false) },
          hidden.developerSubmenu ? {} : { label: 'As Rate Test file', onClick: onExportRateTest },
        ] },
        { label: 'Delete', icon: 'trash', onClick: showModal(DELETE, { onConfirm: onDeleteItem }) },
      ],
      quote: [
        { label: 'Rename', icon: 'pencil', onClick: showModal(RENAME, { onConfirm: onRenameItem }) },
        { label: 'Reprice', icon: 'refresh-price', onClick: onRepriceQuote, hidden: hidden.reprice, disabled },
        { label: 'Move', icon: 'move', onClick: showModal(MOVE, { onConfirm: onMoveQuote, userId: user.id }), hidden: hidden.move },
        { label: 'Transfer', icon: 'key', onClick: showModal(TRANSFER_QUOTE, { onConfirm: onTransferQuote }), disabled: disabledTransfer, hidden: hidden.transfer },
        { submenu: "Assign", icon: 'user', selectable: true, hidden: hidden.assign, items: assigneeQuoteItems },
        hidden.exportSubmenu
        ? { label: 'Export', icon: 'export', onClick: onExportQuote() }
        : { submenu: "Export", icon: 'export', items: [
            { label: 'Internal view', onClick: onExportQuote(false, true) },
            { label: 'Internal view showing all designs', onClick: onExportQuote(false, false) },
            { label: 'External view', onClick: onExportQuote(true, false) },
          ]},
        { label: 'Delete', icon: 'trash', onClick: showModal(DELETE, { onConfirm: onDeleteItem }), disabled },
      ],
      quoteError: [
        { label: 'Resolve', icon: 'pencil', onClick: showModal(IMPORT_ERROR, { onConfirm: onHandleImportError }), disabled: disableResolve },
        { label: 'Delete', icon: 'trash', onClick: showModal(DELETE, { onConfirm: onDeleteItem }) },
      ],
      folderExport: [
        { label: 'Internal view', onClick: onExportFolder(false, true), disabled: disabledExport },
        { label: 'Internal view showing all designs', onClick: onExportFolder(false, false), disabled: disabledExport },
        { label: 'External view', onClick: onExportFolder(true, false), disabled: disabledExport },
      ],
      folderAssign: assignFolderItems,
    };

    return menuItems;
  }


  notify(actionNotification, timeout=7500, notificationType='success') {
    const { fetchData, dismissNotification } = this;

    this.setState({ actionNotification, notificationType }, fetchData);
    dismissNotification(timeout);
  }

  dismissNotification(wait=0) {
    this.notificationTimer && clearInterval(this.notificationTimer);
    this.notificationTimer = setTimeout(() => this.setState({ actionNotification: null, notificationType: undefined }), wait);
  }

  onClickNewQuote() {
    const { history, user={} } = this.props;
    const { folderId } = this.state;
    const url = user.isEcx || user.isPF ? '/' : '/request-quote' + (folderId ? `?folder=${folderId}` : '');

    history.push(url);
  }

  onImportQuotes(folder) {
    const showImportedRows = (folder && folder.name !== this.state.folderName) 
      ? () => this.props.history.push(`/quote-manager/${encodeURIComponent(folder.name)}/`)
      : () => {};

    this.setState({ importing: true }, showImportedRows);
  }

  onCreateFolder(folder) {
    // this.notify(`Created folder "${folder.name}"`);
    this.props.history.replace(`/quote-manager/${encodeURIComponent(folder.name)}/`);
  }

  onMoveQuote({ name, folder_name }) {
    const isDotFolder = (folder_name === '.');
    const folderName = isDotFolder ? 'Quote Manager Home' : folder_name;
    this.notify(<span>Quote <b>{name}</b> moved successfully into{!isDotFolder && ' folder'} <b>{folderName}</b></span>);
  }

  onRenameItem({ name, prevName }) {
    this.notify(<span><b>{prevName}</b> renamed to <b>{name}</b></span>);
  }

  onHandleImportError() {
    this.fetchData();
  }

  onDeleteItem({ name }) {
    this.notify(<span><b>{name}</b> has been deleted</span>);
  }

  onExportQuote(externalView, filterForSolutions) {
    return ({ props }) => {
      const fileName = slugify(props.name) + '.xlsx';
      const exportKey = `quote_${props.id}`;

      this.setState({ exporting: [...this.state.exporting, exportKey] });
      const data = {
        quoteId: props.id,
        externalView: externalView,
        filterForSolutions: filterForSolutions
      }
      return exportQuotes(fileName, data).then((resp={})=> {
        const errorMessage = !!resp.error && "There was an error downloading your quote. If a copy is not sent to your email account, then please contact support."
        const exporting = this.state.exporting.filter(x => x !== exportKey);
        const actionNotification = !exporting.length ? 'Quote export successful.' : this.state.actionNotification;

        errorMessage
          ? this.setState({ exporting, errorMessage })
          : this.setState({ exporting, actionNotification }, () => this.dismissNotification(3000));
      });
    }
  }

  onExportFolder(externalView, filterForSolutions) {
    return ({ props }) => {
      const folderId = (!!props && props.id) || this.state.folderId;
      const exportKey = `folder_${folderId}`;
      const request = {
        folderId: folderId,
        externalView: externalView,
        filterForSolutions: filterForSolutions
      };

      this.setState({ exporting: [...this.state.exporting, exportKey] });

      return exportFolder(request).then((resp={})=> {
        const errorMessage = !!resp.error && "There was an error downloading your quotes. If a copy is not sent to your email account, please contact support."
        const exporting = this.state.exporting.filter(x => x !== exportKey);
        const actionNotification = !exporting.length ? 'Folder export request received. A copy will be emailed to you shortly.' : this.state.actionNotification;

        errorMessage
          ? this.setState({ exporting, errorMessage })
          : this.setState({ exporting, actionNotification }, () => this.dismissNotification(3000));
      });
    }
  }

  onRepriceQuote({ props }) {
    return priceQuote(props.id).then(({ error }) => {
      (error) ? this.setState({ error }) : this.fetchData();
    });
  }

  onRepriceFolder({ props }) {
    return priceFolder(props.id).then(({ error }) => {
      (error) && this.setState({ error });
    });
  }

  onSort(name) {
    const columnNames = (
      (name === 'date') ? 'agg_modified_time,id' :
      (name === 'type') ? 'row_type,agg_modified_time,id' :
      (name === 'status') ? 'status_value,agg_modified_time,id' : name + ',agg_modified_time,id'
    );
    const sortDirection = (this.state.ordering === columnNames) ? '-' : '';
    const ordering = sortDirection + columnNames.replaceAll(',', ',' + sortDirection);

    this.fetchData({ ordering });
    return ordering;
  }

  onExportRateTest({ props }) {
    const name = (!!props && props.name) || this.state.folderName;
    const folderId = (!!props && props.id) || this.state.folderId;
    const fileName = slugify(name) + '.yml';
    const exportKey = `ratetest_${folderId}`;
    this.setState({ exporting: [...this.state.exporting, exportKey] });
    const data = {
      folderId: folderId,
      fileName,
    };
    return exportRateTest(data).then((resp={})=> {
      const errorMessage = !!resp.error && "There was an error generating the Rate Test"
      const exporting = this.state.exporting.filter(x => x !== exportKey);
      const actionNotification = !exporting.length ? 'RateTest export successful.' : this.state.actionNotification;

      errorMessage
        ? this.setState({ exporting, errorMessage })
        : this.setState({ exporting, actionNotification }, () => this.dismissNotification(3000));
    });
  }

  getImporting() {
    const { folderName = '.' } = this.state;
    const filter = parse(window.location.search);

    // filters on user, search, status, region, and service will pull in results from multiple folders if folderName isn't specified
    const filterExcludesFolder = ['u', 'o', 's', 'region', 'service'].some(x => !!filter[x]);

    // undefined folderName defaults to dot folderName
    if (folderName === '.' && filterExcludesFolder) {
      return;
    }

    return getQuoteBatches({
      folderName,
      requestingUserId: (filter.u || this.props.user.id),
      status: 'processing'
    })
      .then((results) => {
        const importing = results && !!results.length;
        if (this.state.importing !== importing) {
          this.setState({ importing });
        }
        return importing;
      });
  }

  importingPoller() {
    const { getImporting, importingPoller, getRowData } = this;

    getImporting().then((importing) => importing
       ? setTimeout(importingPoller, 5000)
       : getRowData({ page: 1 })
    );
  }

  getRowData(options={}) {
    const { folderName, page=1, pageSize, ordering } = this.state;
    const { filterOrg, filterUser, filterStatus='', filterFolderId, region, service } = options;
    const { user } = this.props;

    const status = filterStatus.split(',')
      .map(s => s === 'manual' ? 'manual,error' : s)
      .join(',') || undefined;

    const request = {
      page: page > 0 ? page : undefined,
      pageSize,
      ordering,
      folderName: (folderName !== '.' ? folderName : undefined),
      folderId: (folderName !== '.' ? filterFolderId : undefined),
      orgId: filterOrg || undefined,
      ownerId: (filterUser || (filterOrg ? undefined : user.id)),
      status,
      region,
      service,
      ...options
    };

    const redirectToPageOne = () => {
      const { pathname, search } = this.props.location;
      const query = {...parse(search || {}), p: null };
      const url = stringifyUrl({ url: pathname, query }, { skipNull: true });

      this.props.history.replace(url);
    };

    this.abortController?.abort();
    this.abortController = new AbortController();

    this.setState({ isLoading: true });

    getQuotesAndFolders(request, { signal: this.abortController.signal })
      .then(({ error, totalItems=0, pageCount=0, items }) => {
        if (error?.detail === 'Invalid page.') return redirectToPageOne();
        if (error?.name === 'AbortError') return;
        const pageError = error ? '404 - Could not find requested folder' : null;

        this.setState({ 
          isLoading: false,
          error: pageError,
          hideTable: !!pageError,
          page: request.page,
          pageSize: request.pageSize,
          ordering: request.ordering,
          pageCount,
          totalItems,
          items
        });

        this.abortController = null;
      });
  }

  fetchFolderId() {
    const { folderName } = this.state;
    const filterUser = parse(this.props.location.search || {}).u;

    this.setState({ isFetchingFolderId: true });
    getFolders({ name: folderName, owner_id: (filterUser || this.props.user.id)})
      .then(({ results }) => (
        results && results.length && this.setState({ folderId: results[0].id })
      )).finally(() => {
        this.setState({ isfetchingFolderId: false });
      });
  }

  fetchData(request) {
    const { o, u, s, f, service, region, p=1 } = parse(this.props.location.search || {});
    this.getRowData({ ...request, page: p, filterOrg: o, filterUser: u, filterStatus: s, filterFolderId: f, service, region });
    this.getImporting();
  }

  onChangeFilter(filterUpdate={}) {
    const { pathname, search } = window.location;

    // build new query string parameter url
    const redirect = queryStringify(filterUpdate);

    if (pathname + search !== redirect) {
      this.props.history.replace(redirect);
    }
  }

  breadcrumbs() {
    const { filterOrg, filterUser, folderName } = this.state;
    const { user } = this.props;
    const { p, ...search } = parse(window.location.search || {});
    const { o, u, s } = search;
    const isDot = folderName === '.';


    const toUrl = (query) => stringifyUrl({ url: '/quote-manager/', query }, { skipNull: true });

    const showAllOrgUsers = (
      !user.is_internal
      && user.hasPermission(`${PERMISSIONS.VIEW_ORG_USERS}=${user.organization_id}`)
      && +window.localStorage.getItem(STORAGE_KEYS.QUOTE_MANAGER_ALL_ORG_USERS)
    );

    const quoteMangerLink = (
      showAllOrgUsers
        ? toUrl({ o: showAllOrgUsers }) :
      (!isDot || u || o) 
        ? toUrl()
        : null
    );

    const orgText = (
      (filterOrg && filterOrg.name) ? filterOrg.name : 
      filterUser ? filterUser.organization_name :
      (o || u) ? 'Organization' : null
    );
    const orgLink = (orgText && filterUser && filterUser.id) ? toUrl({ o: o || filterUser.organization_id, s }) : null;
    const userText = !u ? null : !filterUser ? 'User' : filterUser.name;
    const userLink = !isDot ? toUrl(search) : null;

    const crumbs = [
      { text: 'Home', link: '/' },
      { text: 'Quote Manager', link: quoteMangerLink },
      (orgText ? { text: orgText, link: orgLink } : null),
      (userText ? { text: userText, link: userLink } : null),
      (!isDot ? { text: folderName } : null)
    ].filter(x=>x);

    return crumbs;
  }

  componentDidMount() {
    const { history, user, showModal } = this.props;
    const { hash, search, pathname } = window.location;
    const { folderId, folderName } = this.state;
    const folder = folderName !== '.' ? folderId : undefined;

    const filterUser = parse(search || {}).u;
    const ownerId = filterUser || user.id;

    const showAllOrgUsers = (
      !user.is_internal
      && user.hasPermission(`${PERMISSIONS.VIEW_ORG_USERS}=${user.organization_id}`)
      && +window.localStorage.getItem(STORAGE_KEYS.QUOTE_MANAGER_ALL_ORG_USERS)
    );

    // Weird react-router bug that ignores query string parameters when redirected after session timeout
    // if (hash !== window.location.hash || search !== window.location.search) {
    //   return window.location.reload();
    // }

    if (search === `?u=${user.id}`) {
      return history.replace(pathname);
    }

    if (hash === '#import') {
      showModal(MODALS.IMPORT, { folder, ownerId, onConfirm: this.onImportQuotes });
    }

    if (user.is_internal) {
      getAssignableUsers().then((assignableUsers) => this.setState({assignableUsers}));
    }

    if (showAllOrgUsers) {
      this.setState({ showingFilterView: true });

      // build new query string parameter url
      const redirect = queryStringify({ showAllOrgUsers });
  
      if (pathname + search !== redirect) {
        this.props.history.replace(redirect)
      } else {
        this.fetchData();
      }
    } else {
      this.fetchData();
      this.fetchFolderId();
    }
  }

  static getDerivedStateFromProps(props, state) {
    let folderName;
    try {
      folderName = decodeURIComponentSafe(props.match.params.folderName || '.');
    } catch (e) {
      this.setState({ showTable: false, error: 'There was an error retrieving your folder' })
    }

    return ((folderName || '.') !== (state.folderName || '.'))
      ? { folderName, items: [], pageCount: 0, page: 1, hideTable: false, error: null }
      : null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { importing, folderId, isFetchingFolderId } = this.state;
    const updatedFolder = prevProps.match.params.folderName !== this.props.match.params.folderName;
    const updatedFilter = prevProps.location.search !== this.props.location.search;

    if (!prevState.importing && importing) {
      this.importingPoller();
    }

    if (updatedFolder || updatedFilter) {
      this.fetchData();
    }

    if (updatedFolder || (!folderId && !isFetchingFolderId)) {
      this.fetchFolderId();
    }
  }

  componentWillUnmount() {
    this.state = {};
    this.abortController?.abort();
  }

  toggleFilterView() {
    this.setState({ showingFilterView: !this.state.showingFilterView });
  }

  handlePageClick({ selected=0 }) {
    const page = selected + 1;
    const { search, pathname } = location;

    if (!this.state.isLoading && page > 0 && this.state.page !== page) {
      const query = parse(search || {});
      query.p = (page === 1) ? null : page;

      const url = stringifyUrl({ url: pathname, query }, { skipNull: true });
      this.props.history.replace(url);
    }
  }

  componentDidCatch(e) {
    console.error(e);
    debugger;

    return null;
  }

  NoQuotesNotification() {
    const { folderId, folderName } = this.state;
    const { showModal, user } = this.props;
    const filterUser = parse(this.props.location.search || {}).u;
    const ownerId = filterUser || user.id;
    const folder = folderName !== '.' ? folderId : undefined;

    const toNewQuote = {
      pathname: '/request-quote',
      search: (folderName !== '.' ? `?folder=${folderId}` : ''),
    };

    const handleImport = () => showModal(MODALS.IMPORT, { onConfirm: this.onImportQuotes, folder, ownerId })();

    return (user.isEcx || user.isPF) 
      ? (
        <Notification.Info>
          You don't have any quotes yet. <Link to="/">Request a new quote.</Link>
        </Notification.Info>
      ) : (
        <Notification.Info>
          <Link to={toNewQuote}>Request a new quote</Link> or <Button type="inline" onClick={handleImport}>import quotes.</Button>
        </Notification.Info>
      );
  }

  render() {
    const { IMPORT, NEW_FOLDER, BULK_COMMENT } = MODALS;
    const { showModal, user={}, location={} } = this.props;
    const filter = parse(location.search || {});
    const isFolderOwner = !filter.u || filter.u === user.id.toString();
    const { getContextMenuItems, breadcrumbs, onClickNewQuote, handlePageClick, onCreateFolder, onImportQuotes, onChangeFilter, dismissNotification } = this;
    const { error, actionNotification, notificationType, hideTable, items, pageCount, pageSize, page, totalItems, isLoading, folderName='.', folderId, showingFilterView, exporting, errorMessage, importing } = this.state;
    const isDotFolder = (folderName === '.');
    const hasExportDropdown = user.is_internal;
    const loadingMessage = isLoading ? " " : null;
    const filterClassName = showingFilterView ? '' : '--collapsed';
    const contextMenuItems = getContextMenuItems();
    const crumbs = breadcrumbs();
    const disabled = !!isLoading || !totalItems;
    const pendingExports = exporting?.length;
    const exportingMessage = pendingExports && `Preparing to export ${count(pendingExports, 'file', 'files')}. A copy will be emailed to you for your records.`;
    const importingMessage = 'Quotes are currently being imported into this folder. You will receive an email notification when the import has completed.';
    const NoQuotes = (!isLoading && (!items || !items.length)) ? this.NoQuotesNotification() : undefined;

    const onClick = {
      newFolder: showModal(NEW_FOLDER, { 
        onConfirm: onCreateFolder 
      }),
      import: showModal(IMPORT, { 
        onConfirm: onImportQuotes, 
        folder: !isDotFolder && folderId, 
        ownerId: user.id ,
      }),
      bulkComment: showModal(BULK_COMMENT, { 
        onConfirm: onBulkComment,
        folderId,
        status: filter.s,
        count: totalItems,
      }),
      export: this.onExportFolder(),
    };

    const dismissExportMessage = () => this.setState({ exporting: [] });
    const dismissExportError = () => this.setState({ errorMessage: null });

    const onBulkComment = () => this.notify('Comment successfully created and added to quotes.');

    return (
      <section className="quote-manager-page">
        <Helmet>
          <title>{PAGE_TITLE_NAME}: Quote Manager</title>
          <link rel={DefFaviconName} type="image/png" href={DefFavicon} />
        </Helmet>

        <Breadcrumbs crumbs={crumbs} />

        <div className="page-content">
          <div className="quote-manager-content">
            <div className="header-wrap --sticky">
              <h1 className="page-header">
                { isDotFolder ? 'Quote Manager' : folderName }
              </h1>

              {!hideTable && (
                <div className="quote-manager-actions">
                  {isFolderOwner && (
                    <Button type="link" icon="add-circle-filled" onClick={onClickNewQuote} disabled={!!isLoading} children="New Quote" />
                  )}
                  {!!isDotFolder && !user.isEcx && !user.isPF && isFolderOwner && (
                    <Button type="link" icon="folder" className="new-folder-button" onClick={onClick.newFolder} disabled={!!isLoading} children="New Folder" />
                  )}
                  {!user.isEcx && !user.isPF && isFolderOwner && (
                    <Button type="link" className="import-button" onClick={onClick.import} disabled={!!isLoading} icon="import" children="Import" />
                  )}
                  {!isDotFolder && !user.isEcx && !user.isPF && (
                    (hasExportDropdown && !disabled)
                    ? <MenuProvider id="folder_export_menu" event='onClick'>
                        <Button type="link" className="export-button" icon="export" children="Export" />
                      </MenuProvider>
                    : <Button type="link" className="export-button" onClick={onClick.export} disabled={disabled} icon="export" children="Export" />
                  )}
                  {!user.isEcx && !user.isPF && !isDotFolder && !!user.is_pricing_desk && (
                    <MenuProvider id="folder_assignee_menu" event={disabled ? null : 'onClick'}>
                      <Button type="link" className="assignee-button" icon="user" children="Assign" disabled={disabled} />
                    </MenuProvider>
                  )}
                  {!user.isEcx && !user.isPF && !isDotFolder && !!user.hasPermission(PERMISSIONS.BULK_COMMENT) && (
                    <Button type="link" icon="comments" className="bulk-comment-button" onClick={onClick.bulkComment} disabled={disabled} children="Comment" />
                  )}
                  {!user.isEcx && !user.isPF && (
                    <Button className="filter-button" type="link" onClick={this.toggleFilterView} icon="filter" children="Filter" />
                  )}
                </div>
              )}

              <QuoteManagerFilter 
                className={filterClassName}
                onChangeFilter={onChangeFilter} 
              />
              <div className="quote-manager__importing-notification">
              { !!importing &&
                <Notification.Warning children={importingMessage} />
              }
              </div>
              <div className="quote-manager-notifications">
                {/* // TODO: ADD NOTIFICATION/NOTIFIER STRUCTURE */}
                { !!exportingMessage && 
                  <Notification.Warning className="action-notification spinning" children={exportingMessage} icon="loading" onDismiss={dismissExportMessage} />
                }
                { !!actionNotification && (
                  <Notification.Info type={notificationType} className="action-notification" children={actionNotification} onDismiss={dismissNotification} />
                )}
                { !!errorMessage && 
                  <Notification.Error className="action-notification" children={errorMessage} onDismiss={dismissExportError} />
                }
              </div>
            </div>

            { !!error &&
              <Notification.Error children={error} />
            }

            { !hideTable && (
              <QuoteManager items={items} folderName={folderName} userFilter={filter.u} loadingMessage={loadingMessage} noQuotes={NoQuotes} onSort={this.onSort} onHandleImportError={this.onHandleImportError} />
            )}

            {!isLoading && (
              <div className="pager-row">
                <PagerStatus page={page} pageSize={pageSize} totalItems={totalItems} />
                <Pager pageCount={pageCount} initialPage={page} onPageClick={handlePageClick} isLoading={isLoading} />
              </div>
            )}
          </div>
        </div>

        {/* CONTEXT MENUS */}
        <ContextMenu id="folder_menu" items={contextMenuItems.folder} />
        <ContextMenu id="quote_menu" items={contextMenuItems.quote} />
        <ContextMenu id="import-error_menu" items={contextMenuItems.quoteError} />
        <ContextMenu id="folder_export_menu" className="quote-manager__context_menu context-menu" position="auto" items={contextMenuItems.folderExport} />
        <ContextMenu id="folder_assignee_menu" className="quote-manager__context_menu context-menu" position="auto" items={contextMenuItems.folderAssign} />
      </section>
    );
  }
}

export default withRouter(withUserContext(withModalContext(QuoteManagerPage)));

import React, { useEffect, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import CN from 'classnames';

import { Button, Notification, TextInput } from 'Atoms';
import { Popup } from 'Molecules';
import { OrderSection, FormLookup, FormCheckbox, FormDatePicker, LabelValue } from './OrderSection.jsx';
import { getOrderOptions } from '../QuoteOrderMapper.js';
import { toIpv4Option } from 'Utils/formatter.js';

export const DetailsOrderSection = ({ full }) => {
  const { register, readOnly } = useFormContext();

  return (
    <OrderSection header="Order Details" full={full}>
      <TextInput label="Purchase Order Number" name="orderNumber" register={register} readOnly={readOnly} asText={readOnly} maxLength="50" placeholder={readOnly ? 'None' : undefined} />
      <FormDatePicker label="Requested Installation Date" name="installDate" />
    </OrderSection>
  )
}

// Order Contact
export const ContactOrderSection = ({ full }) => {
  const { readOnly, register } = useFormContext();

  return (
    <OrderSection header="Order Contact" full={full}>
      <TextInput label="Name" name="orderContactName" register={register} className="quote-order-form--full-width" asText={readOnly} maxLength="100" />      
      <TextInput label="Email" name="orderContactEmail" register={register} maxLength="100" asText={readOnly} className={CN({ 'quote-order-form--full-width': !!readOnly })} />
      <TextInput label="Contact Phone" name="orderContactPhone" register={register} maxLength="20" asText={readOnly} />
    </OrderSection>
  )
}

// Technical Contact
export const TechnicalContactOrderSection = ({ full }) => {
  const { readOnly, register } = useFormContext();
  const { onChange, checked } = useOrderContact('techName', 'techEmail', 'techPhone');

  const header = (
    <>Technical Contact
    {!readOnly && (
      <FormCheckbox name="techIsOrderContact" className="quote-order-header-checkbox" onChange={onChange} label="Same as order contact" />
    )}
    </>
  );

  return (
    <OrderSection header={header} full={full}>
      <TextInput label="Name" name="techName" className="quote-order-form--full-width" register={register} readOnly={!!checked} asText={readOnly} maxLength="255" />
      <TextInput label="Email" name="techEmail" register={register} readOnly={!!checked} asText={readOnly} maxLength="254" className={CN({ 'quote-order-form--full-width': !!readOnly })} />
      <TextInput label="Contact Phone" name="techPhone" register={register} readOnly={!!checked} asText={readOnly} maxLength="20" />
    </OrderSection>
  )
}

// Billing Contact
export const BillingContactOrderSection = ({ full }) => {
  const { readOnly, register } = useFormContext();
  const { onChange, checked } = useOrderContact('billingName', 'billingEmail', 'billingPhone');

  const header = (
    <>Billing Contact
    {!readOnly && (
      <FormCheckbox name="billingIsOrderContact" className="quote-order-header-checkbox" label="Same as order contact" onChange={onChange} />
    )}</>
  );

  return (
    <OrderSection header={header}>
      <TextInput label="Name" name="billingName" className="quote-order-form--full-width" register={register} maxLength="255" readOnly={!!checked} asText={readOnly} />
      <TextInput label="Email" name="billingEmail" register={register} maxLength="254" readOnly={!!checked} asText={readOnly} className={CN({ 'quote-order-form--full-width': !!readOnly })} />
      <TextInput label="Phone" name="billingPhone" register={register} maxLength="20" readOnly={!!checked} asText={readOnly} />
      <TextInput label="Billing Address" className="quote-order-form--full-width" name="billingAddress" register={register} maxLength="255" asText={readOnly} />
    </OrderSection>
  )
}

// Location Equipment
export const NetworkInterfaceDeviceOrderSection = ({ locationA, locationZ, full }) => {
  const location = locationA ? 'locationA' : 'locationZ';

  const { readOnly, register } = useFormContext();
  const { onChange, checked } = useOnsiteContact(locationA, locationZ);

  const { powerTypes, powerSources, mountings } = getOrderOptions();

  return (
    <OrderSection header="Customer Premises Equipment" full={full}>
      <FormLookup label="Power Type" name={`${location}.powerType`} options={powerTypes} readOnly={readOnly} />
      <FormLookup label="Power Source" name={`${location}.powerSource`} options={powerSources} readOnly={readOnly} />
      <FormLookup label="Mounting" name={`${location}.mounting`} options={mountings} readOnly={readOnly} />
      <b />

      <div className="quote-order-section-subheader">
        <span>Shipping Contact</span>
        {!readOnly && <FormCheckbox label={"Same as On-Site Contact"} name={`${location}.shippingIsOrderContact`} onChange={onChange} />}
      </div>

      <TextInput label="Shipping Address" className="quote-order-form--full-width" name={`${location}.shippingAddress`} register={register} maxLength="255" asText={readOnly} />
      <TextInput label="Shipping Contact Name" name={`${location}.shippingName`} register={register} maxLength="100" readOnly={!!checked} asText={readOnly} />
      <TextInput label="Shipping Contact Email" name={`${location}.shippingEmail`} register={register} maxLength="100" readOnly={!!checked} asText={readOnly} className={CN({ 'quote-order-form--full-width': !!readOnly })} />
      <TextInput label="Shipping Contact Phone" name={`${location}.shippingPhone`} register={register} maxLength="20" readOnly={!!checked} asText={readOnly} />
    </OrderSection>
  )
}

// Ethernet Interconnect
export const EthernetInterconnectInfoOrderSection = ({ full }) => {
  const { readOnly, register } = useFormContext();
  const { frameTypes, maxLinks } = getOrderOptions();

  return (
    <OrderSection header="Ethernet Interconnect Information" full={full}>
      <TextInput label="Customer Equipment (Make/Model)" name="ethernetEquipment" register={register} maxLength="100" asText={readOnly} />

      <FormLookup label="Frame Type" name="frameType" options={frameTypes} readOnly={readOnly} />

      <TextInput label="Port Size" name="portSize" register={register} maxLength="20"  asText={readOnly}/>

      <div className="quote-order-group">
        <FormCheckbox label="Auto Negotiation" name="autoNegotiation" register={register} />
        <FormCheckbox label="Link Protection (LAG)" name="lag" register={register} />
        <FormCheckbox label="LCAP" name="lcap" register={register} />
      </div>

      <TextInput label="Max Links" name="maxLinks" register={register} options={maxLinks} maxLength="3" asText={readOnly} />

      <div>
        <TextInput label="VLAN Range" name="vlanRange" register={register} maxLength="100" asText={readOnly} />
        <div className="quote-order-comment">Unitas Global will manage the VLAN assignments.</div>
      </div>
    </OrderSection>
  )
}

// Ethernet
export const EthernetInfoOrderSection = ({ full, product }) => {
  const { readOnly, register } = useFormContext();
  const { frameMtus, vlanTags, yesNo } = getOrderOptions();
  const defaultNo = useRef(yesNo.map(x => x.label === 'No' ? { ...x, isDefault: true } : x)).current;
  const defaultYes = useRef(yesNo.map(x => x.value === 1 ? { ...x, isDefault: true } : x)).current;

  return (
    <OrderSection header="Ethernet Information" full={full}>
      <LabelValue label="Product">{product}</LabelValue>
      <LabelValue label="Class of Service">Best Effort</LabelValue>

      <FormLookup label="Frame MTU" name="ethernet.frameMtu" options={frameMtus} register={register} readOnly={readOnly} />
      <FormLookup label="VLAN Tag" name="ethernet.vlanTag" options={vlanTags} register={register} readOnly={readOnly} />
      
      <FormLookup label="L2CP" name="ethernet.l2cp" options={defaultNo} register={register} readOnly={readOnly} />
      <FormLookup label="Q-in-Q" name="ethernet.QinQ" options={defaultNo} register={register} readOnly={readOnly} />
      <FormLookup label="Auto Negotiation" name="ethernet.autoNegotiation" options={defaultYes} register={register} readOnly={readOnly} />
    </OrderSection>
  )
}

export const InternetInfoOrderSection = ({ full }) => {
  const { readOnly, register } = useFormContext();
  const { vlanTags, yesNo } = getOrderOptions();

  return (
    <OrderSection header="Internet Information" full={full}>
      <LabelValue label="Service Type">Fixed</LabelValue>
      <FormLookup name="internet.vlanTag" label="VLAN Tag" options={vlanTags}  readOnly={readOnly}/>
      <div className="quote-order-group">
        <FormLookup name="internet.redundantPort" label="Redundant Port" options={yesNo} register={register} readOnly={readOnly} />
      </div>
    </OrderSection>
  )
}

export const IpRoutingOrderSection = ({ ipv4 }) => {
  const { readOnly, register, watch } = useFormContext();
  const { routingProtocols, yesNo } = getOrderOptions({ isEcx:true });
  const routingType = watch('ip.routing');
  const isBgp = routingType === 'BGP4';
  const ipv4Label = toIpv4Option({ value: ipv4 })?.label;

  return (
    <OrderSection header="Select Routing">
      <FormLookup name="ip.routing" label="Routing" options={routingProtocols} register={register} readOnly={readOnly} />
      <LabelValue label="IPv4 Addresses">{ipv4Label}</LabelValue>
      {!!isBgp && (
        <>
          <TextInput label="ASN" name="ip.asn" register={register} maxLength="50" asText={readOnly} />
          <TextInput label="IRR AS-SET" name="ip.irr" register={register} maxLength="50" asText={readOnly} />
          <FormLookup label="Receive Full Routes?" name="ip.fullRoutes" register={register} options={yesNo} readOnly={readOnly} />
          <FormLookup label="Receive Default Route?" name="ip.defaultRoutes" register={register} options={yesNo} readOnly={readOnly} />
        </>
      )}
    </OrderSection>
  )
}

// Customer Information
export const CustomerInformationOrderSection = () => {
  const { readOnly, register } = useFormContext();

  return (
    <OrderSection header="Company Information">
      <TextInput label="Company Name" name="companyName" className="quote-order-form--full-width" register={register} maxLength="255" asText={readOnly} />
      <TextInput label="Company Phone" name="companyPhone" register={register}  maxLength="20" asText={readOnly} />
      <TextInput label="Website" name="companyUrl" register={register} maxLength="255" asText={readOnly} />
      <TextInput label="Company Address" name="companyAddress" className="quote-order-form--full-width" register={register} maxLength="255" asText={readOnly} />
    </OrderSection>
  );
}

// Location
export const LocationOrderSection = props => {
  const { locationA, locationZ, isEcx } = props;
  const { requiresEquipment, isCustomerNni, line1, line2 } = (locationA || locationZ);
  const location = locationA ? 'locationA' : 'locationZ';
  const header = props.header || (      
    `${locationA ? 'From ' : 'To '} ${
      isCustomerNni ? 'Unitas Global NNI Location' :
      !requiresEquipment ? 'Unitas PoP' : 'Location'
    }`
  );

  const { readOnly, register } = useFormContext();
  const { onChange, checked } = useOrderContact(`${location}.siteContactName`, `${location}.siteContactEmail`, `${location}.siteContactPhone`);

  const { handOffs, connectorTypes, yesNo } = getOrderOptions({ isEcx });
  //const onsiteIsOrderContact = watch(`${location}.onsiteIsOrderContact`);

  return (
    <>
      <OrderSection header={header} full={!requiresEquipment}>
        {isCustomerNni
          ? null :
        requiresEquipment 
          ? <TextInput className="quote-order-form--full-width" label="Company Name" name={`${location}.companyName`} register={register} maxLength="100" asText={readOnly} />
          : <TextInput className="quote-order-form--full-width" label="Company Name to appear on LOA/CFA" name={`${location}.loaCfa`} register={register} maxLength="255" asText={readOnly} />
        }

        <LabelValue label="Address" fullWidth={true}>
          <div>{line1}</div>
          <div>{line2}</div>
        </LabelValue>

        {!!requiresEquipment && (<>
          <TextInput label="Floor, suite, room, etc." name={`${location}.address2`} register={register} maxLength="100" readOnly={readOnly} asText={readOnly} placeholder={readOnly ? 'N/A' : undefined} />
          <div>
            <FormLookup label="Request Demarc?" options={yesNo} name={`${location}.requestDemarc`} register={register} readOnly={readOnly} />
            {!readOnly && <div className="quote-order__request-demarc-disclaimer">*Additional costs will apply based on scope.</div> }
          </div>
          <FormLookup label="Physical Hand off" name={`${location}.handoff`} options={handOffs} readOnly={readOnly} />
          <FormLookup label="Connector Type" name={`${location}.connector`} options={connectorTypes} readOnly={readOnly} />
        
          <div className="quote-order-section-subheader">
            <span>On-Site Contact</span>
            {!readOnly && (
              <FormCheckbox label={"Same as Order Contact"} name={`${location}.onsiteIsOrderContact`} onChange={onChange} />
            )}
          </div>

          <TextInput label="On-Site Contact Name" name={`${location}.siteContactName`} register={register} maxLength="100" readOnly={!!checked} asText={readOnly} />
          <TextInput label="On-Site Contact Phone" name={`${location}.siteContactPhone`} register={register} maxLength="20" readOnly={!!checked} asText={readOnly} />
          <TextInput label="On-Site Contact Email" name={`${location}.siteContactEmail`} register={register} maxLength="100"readOnly={!!checked} asText={readOnly} className={CN({ 'quote-order-form--full-width': !!readOnly })} />
        </>)}
      </OrderSection>

      {!!requiresEquipment && (
        <NetworkInterfaceDeviceOrderSection locationA={locationA} locationZ={locationZ} />
      )}
    </>
  )
}

// Notes
export const NotesOrderSection = ({ full=true }) => {
  const { readOnly, register, watch } = useFormContext();
  const name = 'notes';
  const notes = watch(name);

  useEffect(() => {
    register({ name });
  }, [register, name]);
  
  return (
    <OrderSection header={!readOnly ? 'Notes' : null} className="quote-order-section--no-border" full={full}>
      {!readOnly
       ? <textarea name={name} className="quote-order-notes" placeholder="Notes/comments" ref={register} maxLength="10000" />
       : <LabelValue label="Notes/comments" fullWidth={true}>{notes}</LabelValue> 
      }
    </OrderSection>
  )
}

// DocuSign
export const DocuSignOrderSection = ({ full=false, docusignApprovers=[] }) => {
  const { readOnly, register } = useFormContext();

  return (
    <OrderSection header="DocuSign Approver" className="quote-order-section--no-border" full={full}>
      <FormLookup label="Approved signer" name="approverEmail" register={register} options={docusignApprovers} readOnly={readOnly} className={CN({ 'quote-order-form--full-width': !!readOnly })} />
    </OrderSection>
  )
}

// Terms and Conditions
export const TermsConditionsOrderSection = ({ full=true, isIpTransit }) => {
  const { readOnly, register } = useFormContext();
  const [showTermsModal, setShowTermsModal] = useState(false);

  const termsModal = {
    open: () => setShowTermsModal(true),
    close: () => setShowTermsModal(false)
  };
  
  const link = <Button type="inline" children="Terms & Conditions" onClick={termsModal.open} />;
  const checkboxLabel = <>
    I have read and accept the {link}
    {!!isIpTransit && (<>
      &nbsp;and <a href="/src/static/Unitas_Global_IP_Service_Addendum.pdf" target="_blank" className="button button-inline">IP Service Addendum</a>
      </>
    )}
    </>;

  const popupProps = {
    label: 'Terms & Conditions',
    id: 'quote-order-terms-modal',
    onClose: termsModal.close,
    button1: { label: 'Close', primary: true, disabled: false, onClick: termsModal.close },
  };

  return (
    <OrderSection header={readOnly ? null : "Terms & Conditions"} className="quote-order-section--no-border" full={full}>
      <FormCheckbox className="quote-order-terms" label={checkboxLabel} name='acceptTerms' register={register({ required: true })} readOnly={readOnly} />

      {!!showTermsModal && (
        <Popup {...popupProps}>
          <p>
            By executing this Service Order Form, Customer hereby agrees, acknowledges, and consents to
            be bound by the terms and conditions set forth in this Service Order Form and Customer’s
            current Master Services Agreement (“MSA”), with Unitas Global applicable to the Service(s)
            ordered herein, which when executed together shall form a binding contract. If Customer and
            Unitas Global have not executed MSA documentation, or if a previously executed MSA has
            expired, Unitas Global&#39;s current Standard General Terms and Conditions and Connectivity
            Schedule of Terms and Conditions which may be reviewed on 
            <a href="https://www.unitascloud.com/terms-and-conditions" target="_blank">www.unitascloud.com/terms-and-conditions </a>
            shall apply to the Service(s) identified in this Service Order Form which when this
            Service Order is executed shall together form a binding contract.
          </p>
          <p>
          In cases when Customer places an order and DocuSign is integrated with the application being
          used, the CCM platform will send the order to the integrated CCM DocuSign function and
          require approval before the order is officially submitted to the Unitas Service Delivery team.
          In any event a copy of Customer’s Order details and a copy of the current T&amp;Cs will be emailed
          to the email address given on the Service Order by Customer. All terms are subject to credit
          approval, and all prices may prior to execution (or as may be specified in the Terms and
          Conditions) be subject to change. The pricing offered in this Service Order Form does not
          include any lawful applicable taxes and such pricing shall expire thirty (30) days after delivery
          hereof to Customer, unless the Service Order Form is executed by Customer and received by
          Unitas Global within such thirty (30)-day period.
          </p>
        </Popup>
      )}
    </OrderSection>
  )
}

export const EcxEthernetInfoOrderSection = (props={}) => {
  const { readOnly, register, watch } = useFormContext();
  const { frameMtus, vlanTags } = getOrderOptions({ isEcx: true });

  const vlanTag = watch('ethernet.vlanTag');

  return (
    <>
      <Notification.Info className="quote-order__header-notification">
        The configuration items below are specific to your end-user location.
      </Notification.Info>

      <OrderSection header="Ethernet Information" full>
        <LabelValue label="Product">{props.product}</LabelValue>
        <LabelValue label="Class of Service">Best Effort</LabelValue>

        <FormCheckbox label="Auto Negotiation" className="checkbox--negotiation" name="ethernet.autoNegotiation" defaultValue={true} register={register} />
        <FormLookup label="Frame MTU" name="ethernet.frameMtu" options={frameMtus} readOnly={readOnly} />
        
        <div>
          <div className="text-input-caption">Do you wish to have support for multiple connections delivered over this link?</div>
          <FormLookup label="VLAN Support" name="ethernet.vlanTag" options={vlanTags} readOnly={readOnly} />

          {(vlanTag === 'Tagged') && (
            <div style={{ marginTop: '1.5rem' }}>
              <div className="text-input-caption">Please enter a VLAN ID for the initial connection to be delivered on this link.</div>
              <TextInput label="VLAN ID" name="ethernet.vlanId" register={register} maxLength="5" asText={readOnly} readOnly={readOnly} />
            </div>
          )}
        </div>
      </OrderSection>
    </>
  )
}

const useOrderContact = (name, email, phone) => {
  const { clearErrors, setValue, watch } = useFormContext();
  const orderName = watch('orderContactName');
  const orderEmail = watch('orderContactEmail');
  const orderPhone = watch('orderContactPhone');

  // TODO: Set default value
  const [ checked, setChecked ] = useState(false);

  const onChange = ({ target }) => {
    setValue(name, target.checked ? orderName : '');
    clearErrors(name);
    setValue(email, target.checked ? orderEmail : '');
    clearErrors(email);
    setValue(phone, target.checked ? orderPhone : '');
    clearErrors(phone);

    setChecked(target.checked);
  }

  useEffect(() => {
    if (checked) {
      setValue(name, orderName);
      clearErrors(name)
    }
  }, [checked, orderName])

  useEffect(() => {
    if (checked) {
      setValue(email, orderEmail);
      clearErrors(email)
    } 
  }, [checked, orderEmail])

  useEffect(() => {
    if (checked) {
      setValue(phone, orderPhone);
      clearErrors(phone)
    } 
  }, [checked, orderPhone])

  return { onChange, checked }
}


const useOnsiteContact = (locationA, locationZ) => {
  const { line1, line2 } = (locationA || locationZ);
  const location = locationA ? 'locationA' : 'locationZ';

  const { clearErrors, setValue, watch } = useFormContext();
  const siteName = watch(`${location}.siteContactName`);
  const siteEmail = watch(`${location}.siteContactEmail`);
  const sitePhone = watch(`${location}.siteContactPhone`);

  const shippingName = `${location}.shippingName`;
  const shippingEmail = `${location}.shippingEmail`;
  const shippingPhone = `${location}.shippingPhone`;
  const shippingAddress = `${location}.shippingAddress`;

  // TODO: Set default value
  const [ checked, setChecked ] = useState(false);

  const onChange = ({ target }) => {
    setValue(shippingName, target.checked ? siteName : '');
    clearErrors(shippingName);
    setValue(shippingEmail, target.checked ? siteEmail : '');
    clearErrors(shippingEmail);
    setValue(shippingPhone, target.checked ? sitePhone : '');
    clearErrors(shippingPhone);
    setValue(shippingAddress, target.checked ? `${line1}, ${line2}` : '');
    clearErrors(shippingAddress);
    
    setChecked(target.checked);
  }

  useEffect(() => {
    if (checked) {
      setValue(shippingName, siteName);
      clearErrors(shippingName)
    }
  }, [checked, siteName])

  useEffect(() => {
    if (checked) {
      setValue(shippingEmail, siteEmail);
      clearErrors(shippingEmail)
    } 
  }, [checked, siteEmail])

  useEffect(() => {
    if (checked) {
      setValue(shippingPhone, sitePhone);
      clearErrors(shippingPhone)
    } 
  }, [checked, sitePhone])

  useEffect(() => {
    if (checked) {
      setValue(shippingAddress, `${line1}, ${line2}`);
      clearErrors(shippingAddress)
    } 
  }, [checked])

  return { onChange, checked }
}
import React from 'react';
import { useUserContext } from 'Context/UserContext';
import { DesignItemRow, DesignItemLabelsRow, DesignItemLocationRow } from './index.js';
import DesignTotals from '../DesignTotals.jsx';
import { Icon } from 'Atoms';
import { isSameLocation } from '../../../QuoteViewerMapper.js';

import './DesignItems.scss';

export const DesignItems = (props) => {
  const { design={}, quoteOrgName, partnerName } = props;
  const { mrc, mrr, nrc, nrr, mrv, nrv,
    external_mrc, external_nrc, port, bandwidth, upload, term, 
    designType, displayCurrency, leadTime,
    marginAmt, marginPct,
    userMarginPct,
    commissionAmt, commissionPct,
  } = design;
  const termLabel = term.replace('Months', 'Month Term');
  const mrcIsUgMrc = (design.ug_mrr);

  const totals = { 
    mrc, mrr, mrv,
    nrc, nrr, nrv,
    externalMrc: external_mrc || null,
    externalNrc: external_nrc || null,
    port, bandwidth, upload, term, displayCurrency,
    marginAmt, marginPct, markupAmt: (nrr - nrc),
    commissionAmt, commissionPct,
    userMarginPct, userMarkupAmt: mrcIsUgMrc ? (nrv - nrr) : (nrr - nrc),
    quoteOrgName: partnerName || quoteOrgName,
    hasMrc: mrc !== undefined,
    hasMrr: mrr !== undefined,
    hasUgMrr: design.ug_mrr !== undefined,
  }

  const user = useUserContext();
  const isInternal = (totals.mrv !== null);

  const labelProps = {
    isInternal,
    showingRate: !!mrr && !!nrr,
    showingEndUserRate: (mrv !== mrr || nrv !== nrr || userMarginPct),
  };

  return (
    <div className="design-items">
      <DesignItemLabelsRow className="design-items__header" {...labelProps} showingBandwidth={isInternal}>
        <div className="flex-column">
          <div className="icon-text"><Icon name="calendar" />{termLabel}</div>
          {!!user.is_internal && (
            <div className="icon-text"><Icon name="clock" />{leadTime}</div>
          )}
        </div>
      </DesignItemLabelsRow>
      
      <div className="design-items__rows">
        <DesignItemRows {...props} />
      </div>

      <DesignTotals {...totals} />
    </div>
  )
};

const DesignItemRows = (props) => {
  const { extendedDemarc, quoteOrgName, partnerName } = props;
  const { designType, designItems=[{}], locationZ, internetType } = props.design;

  const hasLocationZ = !internetType && designItems.find(x => (
    x.type === 'location' && isSameLocation(x.address, locationZ)
  ));

  const linkTypes = ['provider-connection', 'royalty'];

  const rowData = [
    ...designItems,
    (!hasLocationZ && { type: 'location', isLocationZ: true, address: locationZ, internetType, extendedDemarc, isNodeEnd: true })
  ].filter(Boolean);

  const toDesignItemRows = (item, i, arr) => {
    // current item is location and next iteam is link type - Node start point
    const isNodeStart = (!i || (arr[i]?.type === 'location' && linkTypes.includes(arr[i-1]?.type)));
    
    // current item is link and last item was node type - Node end point
    const isNodeEnd = (i === arr.length - 1  || (!linkTypes.includes(arr[i]?.type) && linkTypes.includes(arr[i+1]?.type)));
    
    return (item.type === 'location')
      ? <DesignItemLocationRow key={`loc_${i}`} extendedDemarc={extendedDemarc} {...item} isNodeStart={isNodeStart} isNodeEnd={isNodeEnd} /> 
      : <DesignItemRow key={item.id} {...item} designType={designType} quoteOrgName={quoteOrgName} partnerName={partnerName} isNodeStart={isNodeStart} isNodeEnd={isNodeEnd} />
  };

  return rowData.map(toDesignItemRows);
}
import React from 'react';
import { getAlpha2 } from 'Utils/countries.js';

import 'Sass/twemoji-flags.scss';

const Flag = ({ alpha2='', alpha3='', className='' }) => {
  const code = alpha2 || getAlpha2(alpha3);
  const flag = code?.toLowerCase();
  return flag ? <span className={`em-flag em-flag-${flag} ${className}`} /> : null;
};

export default Flag;
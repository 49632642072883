import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import SVG from 'react-inlinesvg';
import { Button, Icon, Notification } from 'Atoms';
import { MODALS, ModalManager } from 'Molecules';
import CreateQuote from './CreateQuote.jsx';

import { getQuoteOptions, getSystemStatusNotification } from 'Actions/ccm/api.js';

import './PacketFabricHomePage.scss';
import { DefFavicon, DefFaviconName, SvgFullWhiteLogo, FullWhiteLogoName, COMPANY_SHORT_NAME, PAGE_TITLE_NAME, APP_PRODUCT_NAME } from 'Utils/branding.js';

const PacketFabricHomePage = () => {
  const [ outageNotification, setOutageNotification ] = useState(null);
  const [ notificationMessage, setNotificationMessage ] = useState();
  const [ quoteOptions, setQuoteOptions ] = useState();

  const modalRef = useRef();

  const onClick = () => modalRef.current.open(MODALS.SUPPORT, {
    onConfirm: () => setNotificationMessage(`Your information request has been sent! A ${COMPANY_SHORT_NAME} representative will be in touch with you shortly.`)
   });

  const onDismiss = () => setNotificationMessage(null);

  useEffect(() => {
    getSystemStatusNotification().then(msg => setOutageNotification(msg));
    getQuoteOptions({ organizationKey: 'packetfabric' }).then(resp => !resp.error && setQuoteOptions(resp))
  }, [])

  return (
    <div className="packetfabric-home">
      <Helmet>
        <title>{PAGE_TITLE_NAME}: Last Mile Access</title>
        <link rel={DefFaviconName} type="image/png" href={DefFavicon} />
      </Helmet>

      {!!outageNotification && (<Notification.Warning children={outageNotification} />)}
      {!!notificationMessage && (<Notification.Success duration={7500} onDismiss={onDismiss} children={notificationMessage} />)}

      <section className="packetfabric-home__content">
        <div className="packetfabric-home__content-underlay patterned--dark"></div>
        <h1>Last Mile Access on PacketFabric Converge<sup>®</sup> with {APP_PRODUCT_NAME}</h1>
        <div className="packetfabric-home__forms">
          <div className="form1 packetfabric-home__form form1--expanded">
            <div className="packetfabric-home__form-icons"><Icon name="customer" /><Icon name="long-arrow" /><Icon name="cloud-data-center" /></div>
            <p>Connect from any location to a PacketFabric location</p>
            <CreateQuote quoteOptions={quoteOptions} />
          </div>
        </div>
      </section>

      <section className="packetfabric-home-section packetfabric-home-section--footer">
        <div className="packetfabric-home-section__support-questions">
          <ul>
            <li>Have a question about your quote?</li>
            <li>Looking for a different data center to connect to?</li>
            <li>Looking for a multi-site WAN opportunity?</li>
            <li>Have carrier diversity needs?</li>
          </ul>
          <Button onClick={onClick} icon="support" children="Let us help" />
        </div>

        <div className="text__service-delivery">Services are delivered by</div>
        <SVG src={SvgFullWhiteLogo} alt={FullWhiteLogoName} />
      </section>

      <ModalManager ref={modalRef} />
    </div>
  )
}

export default PacketFabricHomePage;

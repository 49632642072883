import React, { useState, useEffect } from 'react';
import { getOrganizations } from 'Actions/ccm/api.js';
import { Lookup } from 'Molecules';
import { putFirst } from 'Utils/array.js';

const LeastCostByOrganizationLookup = props => {

  const { name, label, onChange, error, inputRef, defaultValue={}, isDisabled } = props;
  const toOrgOption = (org) => ({ label: `${org.name} - Least Cost`, value: org.company_key, popSelectionOrganizationKey: org.company_key });
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getOrganizations({ has_data_center_lists: true }).then(response => {
      const options = (response.results || []).map(toOrgOption);
      const isUnitas = (({ value }) => value === 'unitas-global');

      // Set unitas as the first option
      const mappedOrgs = putFirst(options, isUnitas);

      // Set default value
      if (defaultValue && defaultValue.popSelectionOrganizationKey) {
        const defaultSelection = mappedOrgs.find(({ value }) => value === defaultValue.popSelectionOrganizationKey) || mappedOrgs[0];
        if (defaultSelection) {
          inputRef.current.select.onChange(defaultSelection, { action: 'select-option' });
        }
      }

      setOrganizations(mappedOrgs);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Lookup
      name={name}
      label={label}
      error={error}
      options={organizations}
      isClearable={true}
      onChange={onChange}
      ref={inputRef}
      openMenuOnFocus={true}
      isLoading={isLoading}
      isDisabled={isDisabled}
    />
  )
}

export default React.forwardRef((props, ref) => <LeastCostByOrganizationLookup inputRef={ref} {...props} />);

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Flag, Icon, LoadingOverlay, SortableTable } from 'Atoms';

import { getColos, getPopLists } from 'Actions/ccm/api.js';

import './AdminPops.scss';

const AdminPops = (props) => {
  const { organizationId } = props;

  const [ isFetching, setIsFetching ] = useState(false);
  const [ colos, setColos ] = useState(); 
  const [ popLists, setPopLists ] = useState();

  const toTypeLabel = type => type === 'colocation' ? 'COLO' : 'POP';

  const columnData = useRef([
    {
      key: "icon",
      header: " ",
      sortable: false,
      render: ({ type }) => <><Icon name="customer" /><div>{toTypeLabel(type)}</div></>,
    },
    { keys: ["popName", "addressLabel"] },
    { key: "countryCode",
      render: ({ countryCode, rows=[] }) => <div><Flag alpha3={countryCode} />{`${countryCode} (${rows.length})`}</div>,
    },
  ]).current;
  
  useEffect(() => {
    setIsFetching(true);
    getColos().then(resp => {
      const coloMap = new Map();
      resp.forEach(colo => coloMap.set(colo.id, colo));
      setColos(coloMap);
    });
  }, []);

  useEffect(() => {
    setIsFetching(true);
    getPopLists(organizationId).then(({ results=[] }) => {
      setPopLists(results);
    });
  }, [organizationId]);

  const mappedLists = useMemo(() => {
    if (colos && popLists) {
      setIsFetching(false);
      return popLists.map(({ pops, ...popList }) => ({ 
        ...popList,
        totalCount: pops.length,
        pops: pops.map(id => colos.get(id)).filter(Boolean),
      }));
    }
  }, [colos, popLists]);

  return (
    <div className="admin-pops">
      <h4>PoP Lists</h4>
      {!!isFetching && <LoadingOverlay /> }
      <div className="admin-pops__lists">
        {mappedLists?.length 
          ? mappedLists.map(mappedList => <AdminPopList key={mappedList.id} columnData={columnData} {...mappedList} />) : 
        (isFetching)
          ? <div>Fetching PoPs...</div>
          : <div>This organization does not have any assigned PoP lists.</div>
        }
      </div>
      {/* <div>What PoPs can the customer use?</div>
      <select>
        <option value="1">All PoPs in the CMM system</option>
        <option value="2">Only Unitas PoPs</option>
        <option value="3">Only their PoPs</option>
        <option value="4">Only their PoPs with NNI(s) connected to Unitas</option>
        <option value="5">Other...</option>
      </select>

      <div>Which PoPs should be considered when customer selects least-cost PoPs?</div>
      <select>
        <option value="1">Consider all PoPs the user can see</option>
        <option value="2">Only Unitas PoPs</option>
        <option value="3">Other...</option>
      </select>


      <div>Force Cross-Connects on the PoP side?</div>
      <select>
        <option value="1">Yes</option>
        <option value="2">No</option>
      </select> */}
    </div>
    
  )
}


const AdminPopList = ({ columnData, name, pops }) => (
  <details className="admin-pop-list">
    <summary className="admin-pop-list__header">{name} ({ pops.length })</summary>
    { !pops.length 
      ? <div className="admin-pops-list__no-results">There are no active PoPs in this list</div>
      : <SortableTable columnData={columnData} rowData={pops} groupedBy="countryCode" defaultSort="popName" />
    }
  </details>
);

export default AdminPops;
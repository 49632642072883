import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { AsyncQuoteTooltip, ContextMenu, ContextMenuButton, Icon, LoadingOverlay, Page, Pager, PagerStatus, SortableTable, TimeAgo } from 'Atoms';

import { getAllComments, updateComment } from 'Actions/ccm/api.js';

import './Notifications.scss';

const Notifications = () => {
  const PAGE_SIZE = 25;
  const CONTEXT_MENU_ID = 'quote-comments_menu';
  
  const crumbs = useRef([{ text: 'Home', link: '/' }, { text: 'Quote Comments' }]);
  const columnData = useRef([
    { key: 'icon', header: ' ', sortable: false, render: c => <><Icon name="comments" /><i className="quote-comment-count">{c.count}</i></> },
    { key: 'comment', render: c => <div className="line-clamped">{c.comment}</div> },
    { key: 'reference', header: 'Quote Reference', render: c => <AsyncQuoteTooltip id={c.quoteId}><Link to={`/quote/${c.reference}/#comments`} children={c.reference}/></AsyncQuoteTooltip> },
    { key: 'user' },
    { key: 'organization' },
    { key: 'date', render: ({ date }) => <TimeAgo date={date} /> },
    { key: 'actions',
      header: ' ',
      sortable: false,
      render: props => <ContextMenuButton menuId={CONTEXT_MENU_ID} data={props} />
    }  
  ]);

  const [ rows, setRows ] = useState([]);
  const [ isFetching, setIsFetching ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ totalItems, setTotalItems ] = useState(0);

  const pageCount = Math.ceil(totalItems/PAGE_SIZE);

  const handlePageClick = ({ selected }) => setPage(selected+1);

  const fetchComments = (page) => {
    setIsFetching(true);
    const toRow = x => ({ id: x.id, comment: x.content, user: x.create_user_name, date: x.modified_time, reference: x.object_reference, quoteId: x.object_id, count: x.object_comment_count, organization: x.object_organization });

    getAllComments({ page_size: PAGE_SIZE, page, latest_per_thread: true })
      .then(({ count=0, results }) => {
        setRows(results.map(toRow));
        setTotalItems(count);
      })
      .finally(() => setIsFetching(false));
  };

  const dismissComment = async (item) => {
    const { id, quoteId } = item.props.data;

    setIsFetching(true);
    const resp = await updateComment(quoteId, id, { dismissed: true });
    setIsFetching(false);
    (resp.error)
      ? alert('There was an error dismissing the comment')
      : setRows(rows.filter(x => x.id !== id))
  };

  const contextItems = [
    { label: 'Dismiss Comment', icon: 'subtract-circle-filled', onClick: dismissComment },
  ]

  useEffect(() => { fetchComments(page) }, [page]);

  return (
    <Page header="Quote Comments" className="notifications-page" crumbs={crumbs.current}>
      {/* <div className="notifications-page__description">Viewing comments from the last 5 days</div> */}
      {!!isFetching && <LoadingOverlay /> }

      <SortableTable columnData={columnData.current} rowData={rows} />
      {!isFetching && (
        <div className="pager-row">
          <PagerStatus page={page} pageSize={PAGE_SIZE} totalItems={totalItems} />
          <Pager pageCount={pageCount} initialPage={page} onPageClick={handlePageClick} />
        </div>
      )}
      <ContextMenu id={CONTEXT_MENU_ID} items={contextItems} />
    </Page>
  )
}

export default Notifications

import React, { useState, useCallback, useEffect, useRef, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import CN from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import get from 'lodash/get';

import { Checkbox, RadioGroup, TextInput } from 'Atoms';
import { Lookup } from 'Molecules';

import 'react-day-picker/lib/style.css';

// Extend dayjs
dayjs.extend(customParseFormat);

// TODO: Make readOnly attribute automatically set by asText (and possibly rename prop asText)

// OrderSection - Base HTML structure for each QuoteOrderSection
export const OrderSection = ({ children, header, full=false, className='' }) => {
  const classNames = {
    section: CN(`quote-order-section ${className}`, { 'quote-order-section--full-width': !!full }),
    header: 'quote-order-section-header',
    content: 'quote-order-section-content'
  };

  return (
    <section className={classNames.section}>
      { !!header && <div className={classNames.header} children={header} /> }
      <div className={classNames.content} children={children} />
    </section>
  );
}

export const LabelValue = ({ label, fullWidth, children }) => (
  <div className={CN({ "quote-order-form--full-width": fullWidth })}>
    <div className="quote-order__detail-label">{label}</div>
    <div className="quote-order__detail-value">{children}</div>
  </div>
);

// FormLookup - Lookup with useFormContext
export const FormLookup = props => {
  const { className='', onChange, isDisabled, error, name, options, fetchingOptions } = props;
  const { clearErrors, control: { mode }, errors, readOnly, register, setValue, watch, trigger } = useFormContext();
  const inputRef = useRef(null);

  const watcher = watch(name);

  const errorMsg = error || get(errors, name)?.message;
  const validateOnBlur = mode.isOnAll || mode.isOnBlur || mode.isOnTouch;

  if (!name) {
    console.warn('This component requires a name.', props);
  }

  const onChangeLookup = useCallback(option => {
    setValue(name, option.value);
    clearErrors(name);
    onChange?.(option);
  }, [setValue, name, clearErrors, onChange]);

  const onBlur = useCallback(() => {
    if (validateOnBlur) {
      trigger(name);
    }
  }, [name, validateOnBlur, trigger ]);

  useEffect(() => {
    register?.({ name });
  }, [register, name]);

  useEffect(() => {
    const defaultOption = options.find(x => !!x.isDefault);
    inputRef.current && !!defaultOption && !watcher && inputRef.current.select.setValue(defaultOption);
  }, [options]);

  useEffect(() => {
    if (!options || !options.length || !!fetchingOptions) return;
    const selectedOption = watcher !== undefined && options.find(x => x.value === watcher);
    inputRef.current && !!selectedOption && inputRef.current.select.setValue(selectedOption);
  }, [watcher, fetchingOptions]);

  const cn = CN(className, { 'react-select--read-only': !!readOnly });
  return <Lookup inputRef={inputRef} {...props} className={cn} isDisabled={isDisabled || readOnly} error={errorMsg} onChange={onChangeLookup} onBlur={onBlur} />;
}

// FormCheckbox - Checkbox with userFormContext
export const FormCheckbox = props => {
  const { readOnly, register, watch, setValue } = useFormContext();
  const { label, name, className, defaultValue, onChange } = props;

  const [ checked, setChecked ] = useState(!!props.checked);
  const watcher = watch(name);

  const onChangeCheckbox = useCallback(e => {
    setValue(name, e.target.checked);
    setChecked(e.target.checked);
    onChange?.(e);
  }, [ name, onChange, setValue]);

  useEffect(() => {
    register?.({ name });
    if (defaultValue !== undefined) {
      setValue(name, defaultValue);
      setChecked(defaultValue);
    }
  }, [register, setValue, defaultValue, name]);

  useEffect(() => {
    // Ignore undefined values
    (watcher===true || watcher===false) && setChecked(watcher);
  }, [watcher]);

  return !readOnly 
    ? <Checkbox name={name} label={label} className={className} onChange={onChangeCheckbox} checked={checked} />
    : <LabelValue label={label} children={checked ? 'Yes' : 'No'} fullWidth={true} />
}

// FormRadioGroup - RadioGroup with useFormContext and hard coded classNames
export const FormRadioGroup = props => {
  const { register, watch, setValue } = useFormContext();
  const { label, name, options=[{}] } = props;

  const [ radioValue, setRadioValue ] = useState(options[0].value);
  const watcher = watch(name);

  const onChange = e => {
    setValue(name, e.target.value);
    setRadioValue(e.target.value)
    props?.onChange(e);
  }

  useEffect(() => {
    register?.({ name });
  }, [register, name]);

  useEffect(() => {
    !!watcher && setRadioValue(watcher);
  }, [watcher]);

  return (
    <div>
      <div className="quote-order-info-header">{ label }</div>
      <RadioGroup className="quote-order-radio-group" name={name} options={options} onChange={onChange} value={radioValue} />
    </div>
  );
};

// FormDatePicker - DayPickerInput with useFormContext
export const FormDatePicker = props => {
  const { label, name } = props;
  const [ calendarValue, setCalendarValue ] = useState(undefined);
  const { clearErrors, errors, readOnly, register, setValue, watch } = useFormContext();

  const watcher = watch(name);

  const format="MM-DD-YYYY";
  const today = new Date();

  const parseDate = (str) => {
    const parsed = dayjs(str, format);
    return parsed.isValid() ? parsed.toDate() : undefined;
  }

  const formatDate = (date) => dayjs(date).format(format);

  const onDayChange = date => {
    setValue(name, date?.toISOString().slice(0, 10));
    errors[name] && clearErrors({ name });
  }

  useEffect(() => {
    register({ name });
  }, [register, name]);

  useEffect(() => {
    !!watcher && setCalendarValue(formatDate(watcher));
  }, [watcher]);

  useEffect(() => {
    errors[name] && window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [name, errors[name]]);

  return (
    <div>
      {readOnly ? (   
        <TextInput {...props} value={calendarValue || 'None'} asText={true} readOnly={readOnly} placeholder={null} label={label} name={name} className={CN({ 'text-input--has-error': !!errors[name] })} />
      ) : (
        <DayPickerInput
          dayPickerProps={{
            fromMonth: today,
            modifiers: { disabled: { before: today }},
            showOutsideDays: true
          }}
          format={format}
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={onDayChange}
          value={calendarValue}
          component={forwardRef((props, ref) => (
            <TextInput className="date-picker__input" {...props} icon="calendar" readOnly={true} placeholder={null} label={label} name={name} className={CN({ 'text-input--has-error': !!errors[name] })} />
          ))}
        />
      )}
      {!!errors[name] && <div className="text-input__error">{errors[name].message}</div>}
    </div>
  );
};
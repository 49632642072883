import React, { useRef, useEffect, useState, forwardRef } from 'react';
import CN from 'classnames';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

import { Button, Icon } from 'Atoms';

import './TextInput.scss';

const TextInput = props => {
  const { className='', asText, label, type='text', icon, id=uniqueId('textInput_'), inputRef, error, register, isClearable, onChange=()=>{}, ...inputProps } = props;
  const [ hasValue, setHasValue ] = useState(!!props.value);
  const formContext = useFormContext();
  const refInput = useRef(inputRef);

  const disabled = (inputProps.disabled || inputProps.readOnly) && !asText;
  const errorMsg = error || (register && props.name && get(formContext, `errors.${props.name}.message`));
  const watcher = register && formContext && formContext.watch && formContext.watch(props.name);

  const classNames = {
    div: CN(`text-input ${className}`, {
      'text-input--has-icon': !!icon, 
      'text-input--has-error': !!errorMsg,
      'text-input--disabled': disabled,
      'text-input--read-only': asText,
      'text-input--no-height': type === 'textarea',
    }),
    inputWrapper: 'text-input__input-wrapper',
    input: CN('text-input__input', {
      'text-input__input--textarea': type === 'textarea',
      'text-input__input--has-label': !!label,
      'text-input__input--has-value': hasValue,
      'text-input__input--disabled': disabled,
      'text-input__input--is-clearable': hasValue && !disabled && isClearable,
    }),
    label: CN('text-input__label', {
      'text-input__label--disabled': disabled,
    }),
    button: 'text-input__clear-button',
    icon: 'text-input__icon',
    error: 'text-input__error',
  };

  const onInput = (e) => {
    if (!!e.target.value !== hasValue) {
      setHasValue(!!e.target.value);
    }

    return props?.onInput?.(e);
  };

  const focusInput = () => refInput.current?.focus();

  const onClear = () => {
    setHasValue(false);

    if (formContext) {
      formContext.setValue(props.name, '')
    } else if (refInput && refInput.current) {
      props.onInput && props.onInput({ target: { id, value: '' }});
    }
  };

  // Props value changed: For use with controlled component
  useEffect(() => { 
    setHasValue(!!props.value)
  }, [props.value]);

  useEffect(() => {
    props.name && register && (watcher !== undefined) && setHasValue(!!watcher);
  }, [props.name, watcher]);

  useEffect(() => {
    !!props.autoFocus && focusInput();
  }, []);

  return (
    <div className={classNames.div}>
      <div className={classNames.inputWrapper}>
        {type === 'textarea'
          ? <textarea className={classNames.input} id={id} ref={register || refInput} {...inputProps} readOnly={asText || inputProps.readOnly} onInput={onInput} onChange={onChange} autoComplete="off" />          
          : <input className={classNames.input} id={id} type={type} ref={register || refInput} {...inputProps} readOnly={asText || inputProps.readOnly} onInput={onInput} onChange={onChange} autoComplete="off" />
        }
        <label className={classNames.label} htmlFor={id} children={label} />
        {!!isClearable && !!hasValue && (
          <Button type="link" icon="close" className={classNames.button} onClick={onClear} icon="clear-input" tabIndex="-1" /> 
        )}
      </div>

      { !!icon && ( <Icon className={classNames.icon} name={errorMsg ? 'exclamation-point-circle-outline' : icon} onClick={focusInput} /> )}
      { !!errorMsg && ( <div className={classNames.error}>{errorMsg}</div> )}
    </div>
  );
};

export default forwardRef((props, ref) => <TextInput inputRef={ref} {...props} />);
import { stringifyUrl } from 'query-string';
import { entries } from 'Utils/array.js';

export const PRICING_DESK_TABS = {
  QUOTES: 'quotes',
  ORDERS: 'orders',
};

const VALID_KEYS = {
  [PRICING_DESK_TABS.QUOTES]: [ 'org', 'region', 'service', 'assignee', 'followup' ],
  [PRICING_DESK_TABS.ORDERS]: [ 'org', 'region', 'service', 'unapproved' ],
};

export const stringifyFilterUrl = (filters, tab) => {
  const isValidKey = ([ key ]) => VALID_KEYS[tab]?.includes(key);

  const query = { ...entries(filters, arr => arr.filter(isValidKey)), tab };

  return stringifyUrl({ url: window.location.pathname, query }, { skipNull: false });
}
import React, { forwardRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export const withRouterAndRef = (Component) => forwardRef((props, ref) =>  {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  
  return <Component ref={ref} {...props} history={history} location={location} params={params} />
});


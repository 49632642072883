import React from 'react';
import CN from 'classnames';
import { Button, ContextMenuButton } from 'Atoms';

const SplitButton = ({ menuId, disabled, className='', data, ...props }) => {
  const SelectorButton = <Button className="split-button__context-button" icon="caret-down" disabled={disabled}/>

  return (
    <div className="split-button">
      <Button className={`${className} split-button__button`} disabled={disabled} {...props} />
      <span className={CN("split-button__split", { 'split-button__split--disabled': disabled })} />
      {!disabled
        ? <ContextMenuButton menuId={menuId} data={data} children={SelectorButton} />
        : SelectorButton
      }
    </div>
  )
}

export default SplitButton
import React, { PureComponent, createRef } from 'react';
import CN from 'classnames';
import { Button, Icon, LoadingOverlay, Notification, VisibilityToggle } from 'Atoms';
import UserContext from 'Context/UserContext.js';
import { INTERNAL_COMPANY_NAME } from 'Utils/branding.js';

import { getComments, createComment } from 'Actions/ccm/api.js';

import './QuoteComments.scss';

class QuoteComments extends PureComponent {
  constructor() {
    super();

    this.state = {
      comments: [],
      inputVal: '',
      isInternal: true,
      isFetching: true
    };

    this.inputRef = createRef();

    this.getComments = this.getComments.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onChangePrivacy = this.onChangePrivacy.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getComments() {
    const user = this.context;

    // call api, then setState, update comment count
    this.setState({ isFetching: true });
    return getComments(this.props.id).then(comments => {
      this.setState({ comments, isInternal: user.is_internal, inputVal: '', isFetching: false });
      this.props.setCount(comments.length);
    });
  }

  onChangeText(event) {
    this.setState({ inputVal: event.target.value });
  }

  onChangePrivacy(event) {
    this.setState({ isInternal: event.target.value });
  }

  onSubmit() {
    const user = this.context;
    const { inputVal, isInternal } = this.state;
    const request = {
      content: inputVal.trim(),
      is_internal: isInternal && user.is_internal
    };

    if (request.content) {
      this.setState({ isFetching: true });
      createComment(this.props.id, request).then(()=> {
        this.inputRef.current.value = '';
        this.inputRef.current.blur();
        this.getComments();
      });
    }
  }

  componentDidMount() {
    this.getComments();
  }

  render() {
    const user = this.context;
    const { status } = this.props;
    const { comments, isInternal, isFetching } = this.state;

    const disableCommenting = !user.is_pricing_desk && status === 'expired';

    return (
      <div className="quote-comments">
        { comments.map(comment => <QuoteComment {...comment} key={comment.id} />)}

        { disableCommenting ? (
          <Notification.Info children="Commenting is disabled for expired quotes." />
        ) : (
          <div className="quote-comment__form">
            <textarea className="quote-comment__form-textarea" ref={this.inputRef} onChange={this.onChangeText} placeholder="Write a comment..." disabled={isFetching} required maxLength="10000" />
            <div className="quote-comment__form-actions">
              {!!user.is_internal && (
                <VisibilityToggle isInternal={isInternal} onChange={this.onChangePrivacy} />
              )}
              <Button className="quote-comments__submit-button" onClick={this.onSubmit} disabled={isFetching}>Send</Button>
            </div>

            { !!isFetching && <LoadingOverlay /> }
          </div>
        )}
      </div>
    )
  }
}
QuoteComments.contextType = UserContext;

const QuoteComment = ({ name='user', date, isInternal, content }) => {
  const className = CN('quote-comment', { 'quote-comment--internal': isInternal });
  const icon = isInternal ? 'cloud' : 'internet';
  const viewability = isInternal
    ? `Viewable only by ${INTERNAL_COMPANY_NAME} users`
    : 'Viewable by all users';

  return (
    <div className={className}>
      <div className="quote-comment__header">
        <div>
          <Icon name="avatar" />
          <span className="quote-comment__user">{name}</span>
          <span className="quote-comment__date">{` on ${date}`}</span>   
        </div>
        <div className="quote-comment__viewability">{viewability}<Icon name={icon} /></div>
      </div>
      <div className="quote-comment__message">{content}</div>
    </div>
  )
}

export default QuoteComments;
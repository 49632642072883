import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

// import { intersection } from 'Utils/set.js';
const toObject = (acc, item) => Object.assign(acc, item);

export const apiUrlIdStripper = (url='') => {
  // split on slash, remove empty strings, grab last item
  return +(url?.split('/')?.filter(p => !!p)?.pop());
};

export const getConstraints = (optionSelections, optionFilter=(() => true)) => {

  // For each selectedOption
  const constraints = Object.values(optionSelections)
    .filter(optionFilter)
    .map(({ constraints }) => constraints)
    .reduce(toObject, {});

    // TODO: handle set intersections

    // // For each value...
    // .forEach({} => {
    //   // Coherse value into array if not already one
    //   ([].concat(selection)).forEach(sel => {
    //     debugger;
    //     Object.entries(sel.constraints)
    //       .filter((constraintType, constraint) => !!constraint.selections)
    //       .forEach(([constraintType, { selections }]) => {
    //         let presentConstraints = constraints[constraintType];
    //         constraints[constraintType] = ((presentConstraints === undefined)
    //           ? selections
    //           : intersection(constraints[constraintType], selections)
    //         );
    //       });
    //     });
    //   }
    // );
  return constraints;
}

export const getConstrainedOptions = (options, constraints) => {
  // Method to sort filtered values
  const bySortVal = (a, b) => ((a.sortVal < b.sortVal) ? -1 : (a.sortVal > b.sortVal) ? 1 : 0);

  return Object.entries(options).map(([optionType, optionValue]) => {
    const optionConstraints = constraints[optionType] && constraints[optionType].selections;

    // If there is a constraint for that optionType, filter from that constraint list
    const newValue = ((optionConstraints)
      ? Object.values(optionValue).filter(({ value }) => optionConstraints.has(value))
      : Object.values(optionValue)
    );

    return { [optionType]: newValue.sort(bySortVal) };

  }).reduce(toObject, {});
}

// optionSelections = { port, bandwidth, term }
export const getConstrainedSelections = (selections, options, constraints) => {
  const getSelectionValue = ([ type, selection ]) => {
    if (!selection) return selection;

    // TODO: Handle lists later (ie: term)
    if (Array.isArray(selection)) return selection;

    const inOptions = !!options[type][selection.value];
    const constraintSet = constraints && constraints[type] && constraints[type].selections;
    const notInConstraints = (constraintSet && !constraintSet.has(selection.value));

    return (!inOptions || notInConstraints) ? null : selection;
  };

  const optionSelections = Object.entries(selections).map(item => {
    const [ type, ] = item;

    return { [type]: getSelectionValue(item) };
  }).reduce(toObject, {});

  return optionSelections;
}

export const constrainPortAndBandwidth = ({ port, bandwidth, minValue=0, options={}, validationErrors={} }) => {
  const isMulti = Array.isArray(bandwidth);

  const constrainBandwidths = (bandwidths, p) => bandwidths.filter(b => 
    (!p || !p.sortVal || p.sortVal >= b.sortVal) && (b.sortVal >= minValue)
  );

  const bandwidthSortVal = isMulti
    ? Math.max(...bandwidth.map(x => x.sortVal), 0) 
    : (bandwidth || {}).sortVal;

  // Constrain bandwidth and port options
  const constrainedOptions = {
    bandwidth: constrainBandwidths(options.bandwidth, port),
    port: options.port.filter(p =>
      !p.sortVal || ((!bandwidthSortVal || p.sortVal >= bandwidthSortVal) && (p.sortVal >= minValue))
    )
  };

  // Determine invalid selections outside constraints
  const invalidBandwidth = (!bandwidth || (!isMulti && port && port.sortVal && bandwidth.sortVal > port.sortVal));
  const invalidPort = (!port || (bandwidth && bandwidth.sortVal > port.sortVal && port.sortVal !== 0) || !constrainedOptions.port.some(x => x.sortVal === port.sortVal));

  // Default values for invalid selections
  const maxBandwidth = port && port.sortVal && constrainedOptions.bandwidth[constrainedOptions.bandwidth.length-1];
  const minPort = constrainedOptions.port[0];

  // If no selections, then just return constrained options
  const userValidationErrors = (validationErrors.port || validationErrors.bandwidth);
  if (!port && !bandwidth || userValidationErrors) return { options: constrainedOptions };

  // Constrained bandwidth selections and initialize port with given value 
  const constrainedSelections = {
    port,
    bandwidth: isMulti ? constrainBandwidths(bandwidth, port) : invalidBandwidth ? maxBandwidth : bandwidth
  };

  // If port was invalid or undefined, then set new default value and restrict bandwidth options against new port value
  if (invalidPort) {
    constrainedSelections.port = minPort;
    constrainedOptions.bandwidth = constrainBandwidths(constrainedOptions.bandwidth, minPort);
  }

  return {
    options: constrainedOptions,
    selections: constrainedSelections,
  };
}

export const getInternetType = (designType) => {
  const internetTypes = {
    'direct-internet-access': 'Direct Internet Access',
    'broadband-internet': 'Broadband Internet',
    'ip-transit': 'IP Transit',
    'ip-transit-with-access': 'IP Transit with Access'
  };

  return internetTypes[designType];
}

export const getReferenceType = inputRef => {
  const validReferenceTypes = ['Q', 'D', 'I'];
  const ref = inputRef.trim();

  // Check truthy string with length of at least 12 (6 for date, 1 for type, 4 for id)
  if (!ref || ref.length < 11) {
    return false;
  }

  const refType = ref[6];
  const split = ref.split(refType);

  // Check date format, check type format, check id is numeric
  return (split.length === 2 
    && dayjs(split[0], 'YYMMDD').isValid() // invalid date
    && validReferenceTypes.includes(refType) // invalid reference type
    && !isNaN(split[1]) && Number.isInteger(+split[1]) // invalid id
  ) ? refType : undefined;
}

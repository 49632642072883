import { useState } from 'react';
import { entries } from 'Utils/array.js';

export const useFilter = ({ onChangeFilter }) => {

  // useState
  const [ values, setValues ] = useState();

  const updateFilter = update => {
    const toValueFromOption = ([ key, val ]) => !val ? 0 : ([ key, val?.value ]);

    const newValues = { ...(values || {}), ...update };
    
    setValues(newValues);

    const newFilter = entries(newValues, arr => arr.map(toValueFromOption).filter(Boolean));

    onChangeFilter?.(newFilter);
  }

  return { updateFilter, values  };
}
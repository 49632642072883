import { useCallback, useState } from 'react';

const useDesignEditLogger = () => {
  const [ edits, setEdits ] = useState([]);

  const logEdit = useCallback(edit => {
    setEdits(prev => [...prev, edit])
  }, []);

  const clearEdits = useCallback(() => {
    setEdits([]);
  }, []);

  const hasEdits = !!edits.length;

  return { editLog: edits, logEdit, clearEdits, hasEdits };
}

export default useDesignEditLogger;
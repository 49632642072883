import React, {Fragment} from 'react';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute } from 'Auth/SecureRoute.jsx';
import { GenericNotFoundPage } from 'Pages';
import { PricingDeskPage, QuoteManagerPage, QuoteOrderPage, QuoteViewPage, AdminPage } from 'Pages/CCM';
import PacketFabricHomePage from 'Pages/PacketFabric/PacketFabricHomePage.jsx';
import FooterLinks from 'Pages/ECX/FooterLinks.jsx';
import { URLS } from 'Utils/const.js';

const PacketFabricRouter = () => {
  return (
    <Fragment>
      <Switch>
        <SecureRoute path={URLS.QUOTE_MANAGER_FOLDER} component={QuoteManagerPage} />
        <SecureRoute path={URLS.QUOTE_MANAGER} component={QuoteManagerPage} />
        <SecureRoute path={URLS.QUOTE_DESIGN} component={QuoteOrderPage} />
        <SecureRoute path={URLS.QUOTE} component={QuoteViewPage} />
        <Route exact path={URLS.HOME_PAGE} component={PacketFabricHomePage} />
        <Route component={GenericNotFoundPage} />
      </Switch>
      <FooterLinks />
    </Fragment>
  );
};

export default PacketFabricRouter;
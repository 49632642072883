import React, { useState, Component } from 'react'
import { withUserContext } from 'Context/UserContext.js';
import { TabBadge, Tabs, TabPane } from 'Atoms';
import PricingDeskTable from './PricingDeskTable.jsx';
import OrderValidationTable from './OrderValidationTable.jsx';

import './PricingDesk.scss';

const TAB_KEYS = {
  ORDERS: 'orders',
  QUOTES: 'quotes',
};

const PricingDeskTabs = props => {
  const [ activeKey, setActiveKey ] = useState(props.activeTab || TAB_KEYS.QUOTES);
  const [ quoteCount, setQuoteCount ] = useState();
  const [ orderCount, setOrderCount ] = useState();

  const onTabChange = newActiveKey => {
    props.onTabChange?.(newActiveKey);
    setActiveKey(newActiveKey);
  };

  const quoteTableRef = activeKey === TAB_KEYS.QUOTES ? props.innerRef : null;
  const orderTableRef = activeKey === TAB_KEYS.ORDERS ? props.innerRef : null;
  
  return (
    <div className="pricing-desk-widget">
      <Tabs onChange={onTabChange} activeKey={activeKey}>
        <TabPane tab={<TabBadge value={quoteCount} children="Quotes" />} key={TAB_KEYS.QUOTES} forceRender>
          <PricingDeskTable ref={quoteTableRef} setCount={setQuoteCount} {...props} />
        </TabPane>

        <TabPane tab={<TabBadge value={orderCount} children="Orders" />} key={TAB_KEYS.ORDERS} forceRender>
          <OrderValidationTable ref={orderTableRef} setCount={setOrderCount} {...props} status="design-validation" />
        </TabPane>
      </Tabs>
    </div>
  )
}

class PricingDeskWidget extends Component {
  render() {
    return this.props.user.is_internal 
      ? <PricingDeskTabs {...this.props} />
      : <PricingDeskTable ref={this.props.innerRef} {...this.props} />
  }
}

export default withUserContext(PricingDeskWidget);

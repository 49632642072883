import React, { Component } from 'react';
import styles from './grid_section.scss';

export default class GridSection extends Component {
  render() {
    const {
      children,
      width = 1,
      height = 1,
      color = 'white',
      className,
    } = this.props;
    return (
      <div
        className={`grid-section grid-section-${color} grid-section-${width}x${height} ${className}`}
        style={{
          gridColumnEnd: `span ${width}`,
          gridRowEnd: `span ${height}`,
        }}
        >
        {children}
      </div>
    );
  }
}
import React, { useState, useRef } from 'react';
import CN from 'classnames';
import { useForm } from 'react-hook-form';
import { Button, LoadingOverlay } from 'Atoms';
import { Popup } from 'Molecules';

import { createUsers } from 'Actions/ccm/api.js';

import './AddUsersModal.scss';

let rowsCreated = 0;
const FORM_ID = 'add_users_form';

const AddUsersModal = props => {
  const { onClose, onConfirm, organizationId } = props;

  const [ isFetching, setIsFetching ] = useState(false);
  const [ userRows, setUserRows ] = useState([{ rowKey: rowsCreated }]);
  const { errors, handleSubmit, register } = useForm();
  const formRef = useRef();

  const onSubmit = (values) => {
    // Join user fields
    const users = (values.email).map((email, i) => ({
      firstName: values.firstName[i],
      lastName: values.lastName[i],
      email
    }));

    const request = { organizationId, users };

    setIsFetching(true);
    createUsers(request).then(({ process_entries, skip_entries, error }) => {
      const message = (error
        ? `Error: ${Object.values(error)}`
        : `${process_entries.length} user(s) created! The login information will be emailed to you shortly.`
      );

      setIsFetching(false);

      (!error && onConfirm) && onConfirm();
      onClose();
      alert(message);
    })
  }

  const addRow = () => setUserRows([...userRows, { rowKey: ++rowsCreated }]);

  const popupProps = {
    label: 'Add users',
    id: 'add-users-popup',
    onClose,
    button1: { label: 'Cancel', onClick: onClose },
    button2: { label: 'Create User Accounts', primary: true, disabled: isFetching, buttonType: 'submit', form: FORM_ID },
  };

  return (
    <Popup {...popupProps} >
      { !!isFetching && <LoadingOverlay /> }
      <form id={FORM_ID} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        { userRows.map(({ rowKey }, i) => {
          const errorFirst = errors && (errors[`firstName[${i}]`] || {}).message;
          const errorLast = errors && (errors[`lastName[${i}]`] || {}).message;
          const errorEmail = errors && (errors[`email[${i}]`] || {}).message;
          const removeRow = () => setUserRows(userRows.filter(row => row.rowKey !== rowKey));

          return (
            <div className="add-user-inputs" key={ rowKey }>
              <InputField error={errorFirst}>
                <input placeholder="First Name" name={`firstName[${i}]`} type="text" ref={register({ required: 'First name is required.' })} autoFocus={rowKey === rowsCreated} />
              </InputField>

              <InputField error={errorLast}>
                <input placeholder="Last Name" name={`lastName[${i}]`} type="text" ref={register({ required: 'Last name is required.' })} />
              </InputField>
         
              <InputField error={errorEmail} className="add-user-input-field--email">
                <input placeholder="Email Address" name={`email[${i}]`} type="email" ref={register({ required: 'Email is required.' })} />
              </InputField>
              
              {!!i && (
                <Button tabIndex="-1" className="add-user-inputs__remover" icon="close" onClick={removeRow} /> 
              )}
            </div>
          )
        })}

        <Button className="add-user-inputs__adder" onClick={addRow} icon="add">Add Another User</Button>
      </form>
    </Popup>
  );
}

const InputField = ({ children, className='', error }) => (
  <div className={CN(`add-user-input-field ${className}`, { 'add-user-input-field--errored': error })}>
    {children}
    {!!error && <div className="add-user-input-field-error">{error}</div> }
  </div>
);

export default AddUsersModal;
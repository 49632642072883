import React, { Component, createRef } from 'react';
import CN from 'classnames';
import UserContext from 'Context/UserContext.js';

import { Button, Icon } from 'Atoms';

import { getQuotes, getDesignByReference, getQuoteRequirements } from 'Actions/ccm/api.js';
import { withRouter } from 'react-router-dom';
import { getReferenceType, apiUrlIdStripper } from 'Utils/ccm.js';

import './SearchInput.scss';

class SearchInput extends Component {
  constructor() {
    super();

    this.state = {
      inputVal: '',
      collapsed: true,
      error: null,
      isSearching: false,
    }

    this.inputRef = createRef();

    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onInput = this.onInput.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.cancel = this.cancel.bind(this);
    this.getQuoteRef = this.getQuoteRef.bind(this);
  }

  cancel() {
    this.setState({ collapsed: true, error: null, isSearching: false, inputVal: '' });
  }

  toggle() {
    this.setState({ collapsed: !this.state.collapsed, error: null }, () => {
      if (!this.state.collapsed) {
        this.inputRef.current.focus();
        this.inputRef.current.select();
      }
    });
  }

  onInput(event) {
    this.setState({ error: null, inputVal: event.currentTarget.value });
  }

  onSubmit(event) {
    const { inputVal, error } = this.state;
    const { history } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (!inputVal || !!error) return;
    
    const referenceType = getReferenceType(inputVal);
    if (!referenceType) {
      return this.setState({ error: 'Not a valid reference ID' });
    }
      
    this.setState({ isSearching: true, error: null });
    this.getQuoteRef(inputVal, referenceType).then(({ error, quoteRef }) => {
      if (error) {
        return this.setState({ error, isSearching: false });
      }

      history.push(`/quote/${quoteRef}`);
      this.setState({ collapsed: true, isSearching: false });
    })
  }

  getQuoteRef(ref, refType) {
    const user = this.context;

    const byQuoteRef = (reference) => getQuotes({ reference, include_closed: user.is_internal }).then(({ results=[], error }) => (
      (error || results.length !== 1) ? { error: 'Invalid quote reference ID' } : { quoteRef: reference }
    ));

    const byDesignRef = (reference) => getDesignByReference(reference).then(({ results=[], error }) => {
      if (error || results.length !== 1) {
        return { error: 'Invalid design reference ID' }
      }

      const id = apiUrlIdStripper(results[0].quote_url);
      return getQuoteRequirements(id).then(({ reference }) => ({ quoteRef: reference }))
    })

    switch(refType) {
      case 'Q': return byQuoteRef(ref);
      case 'D': return byDesignRef(ref);
      default : return { error: 'Unknown reference ID' };
    }
  }

  goToQuote(quoteRef) {
    history.push(`/quote/${quoteRef}`);
    this.setState({ collapsed: true, isSearching: false });
  }

  onKeyDown({key}) {
    (key === 'Escape') && this.toggle();
  }

  render() {
    const { collapsed, error, isSearching, inputVal } = this.state;

    const className = CN('search-input', {
      'search-input--errored': !!error & !isSearching,
      'search-input--collapsed': collapsed,
      'search-input--expanded': !collapsed,
      'search-input--searching': isSearching
    });

    const iconName = (collapsed) ? 'magnifying-glass' :
      (isSearching) ? 'loading' : 
      (error) ? 'exclamation-point-circle-outline' : 
      'magnifying-glass';

    return (
      <div className={className}>
        <Button className="search-input__button" type="inline" onClick={this.toggle}>
          <Icon className={CN('search-input__icon', { 'spinning': isSearching }) } name={iconName} />
        </Button>
        <form className="search-input__form" name="search-quotes-input-form" onSubmit={this.onSubmit}>
          { !!error && 
            <div className="search-input__error-label">{error}</div> 
          }
          <input name="search-input" type="text" className="search-input__input" 
            ref={this.inputRef} 
            placeholder="Search for quote by reference ID" 
            spellCheck={false} 
            onInput={this.onInput} 
            onKeyDown={this.onKeyDown}
          />
          { !!inputVal && !collapsed && (
            <Button className="search-input__cancel" type="inline" onClick={this.cancel}>
              <Icon name="close-menu" />
            </Button>
          )}
        </form>
      </div>
    )
  }
}
SearchInput.contextType = UserContext;

export default withRouter(SearchInput);
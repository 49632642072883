import React from 'react';
import { MsalProvider } from "@azure/msal-react";
import { isUgAuth, isPFContext } from 'Utils/auth.js';

import OktaTokenSecurity from './OktaTokenSecurity.jsx';
import UgcoreTokenSecurity from './UgcoreTokenSecurity.jsx';
import AuthManager from './AuthManager.jsx';
import PacketFabricAuthProvider from './PacketFabricAuthProvider.jsx';

const AuthServiceProvider = ({ msalInstance, children }) => {
  if (isPFContext()) return <PacketFabricAuthProvider children={children} />; 

  const OktaSecurity = isUgAuth() ? UgcoreTokenSecurity : OktaTokenSecurity;

  return (
    <MsalProvider instance={msalInstance}>
      <OktaSecurity>
        <AuthManager children={children} />
      </OktaSecurity>
    </MsalProvider>
  );
}

export default AuthServiceProvider;

import React, { PureComponent, createRef } from 'react'
import CN from 'classnames';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { getRateTests, importRateTest, getCcmEnvironments } from 'Actions/analysis/api.js'
import { Breadcrumbs, Button, Icon, Notification, LoadingOverlay, SortableTable, TimeAgo } from 'Atoms';
import { DefFavicon, DefFaviconName, PAGE_TITLE_NAME } from 'Utils/branding.js';

import '../../CCM/page.scss';
import '../../CCM/QuoteManager/QuoteManager.scss';
import '../../../Widgets/QuoteManager/QuoteManager.scss';

const columnData = [
  {
    key: 'type',
    header: ' ',
    render: ({ completed }) => completed && <Icon name='file-spreadsheet' />
  },
  {
    key: 'name', render: ({ name, runReference, completed }) =>
      completed
        ? <Link to={`/rate-testing/${runReference}`}>{name}</Link>
        : <span>{name}</span>
  },
  { key: 'description', sortable: false },
  { key: 'environmentName', header: 'Environment', sortable: true },
  { key: 'userEmail', header: 'Ran As', sortable: true },
  { key: 'runDate', header: 'Run Date', render: ({ runDate }) => <TimeAgo date={runDate} /> },
  {
    key: 'completed', header: 'Status',
    render: ({ completed }) => {
      const status = completed ? "Completed" : "Processing";
      return (
        <span>{status}</span>
      )
    }
  },
];

class RateTestLanding extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      items: [],
      fileName: '',
      environments: null,
      environment: null,
      error: null,
      message: null
    };

    this.getRateTests = this.getRateTests.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.getEnvronments = this.getEnvronments.bind(this);
    this.onEnvironmentChanged = this.onEnvironmentChanged.bind(this);
    this.fileInput = createRef();
  }

  componentDidMount() {
    this.getEnvronments();
    this.getRateTests();
  }

  getEnvronments() {
    getCcmEnvironments().then((items) => {
      this.setState({
        environments: items,
        environment: !!items ? items[0] : undefined
      });

    });
  }

  getRateTests() {
    this.setState({
      isLoading: true,
    });

    getRateTests().then((items) => {

      this.setState({
        isLoading: false,
        items
      });
    });
  }


  handleImport() {
    const { environment } = this.state;

    const request = {
      environment,
      file: this.fileInput.current.files[0]
    };
    this.setState({ isLoading: true, error: null, message: null });

    importRateTest(request)
      .then((response) => {
        (response) && this.setState({ isLoading: false, error: response.error, message: response.message });
        this.getRateTests();
      });
  }

  onInputChange(event) {
    const fileName = event.target.files[0].name;
    this.setState({ fileName });
  }

  onEnvironmentChanged(event) {
    console.log(event);
    this.setState({environment: event.target.value});
  }

  render() {

    const { fileInput, onInputChange, onEnvironmentChanged } = this;
    const { error, items, isLoading, fileName, message, environment, environments } = this.state;
    const crumbs = [
      { text: 'Home', link: '/' },
      { text: 'Rate Testing', link: '/rate-testing/' }
    ];
    const className = CN('quote-manager', { '--isLoading': isLoading });

    return (
      <section className="quote-manager-page">
        <Helmet>
          <title>{PAGE_TITLE_NAME}: Rate Testing</title>
          <link rel={DefFaviconName} type="image/png" href={DefFavicon} />
        </Helmet>

        <Breadcrumbs crumbs={crumbs} />

        <div className="page-content">
          <div className="quote-manager-content">
            <div className="header-wrap --sticky">
              <h1 className="page-header">
                CCM Rate Testing
                        </h1>

              <div className="quote-manager-actions">
                <Button type="link" icon="recent" onClick={this.getRateTests} children="Refresh" />
              </div>
              {!!error &&
                <Notification.Error children={error} />
              }
              {!!message &&
                <Notification.Success children={message} />
              }
            </div>
            <div className={className}>
              {isLoading && (
                <LoadingOverlay children="Retrieving values" />
              )}
              {!isLoading && (
                <SortableTable className="quote-manager__table" columnData={columnData} rowData={items} />
              )}

              {!!environment && (
              <div className="import-data-container">

                <div className="import-quotes-modal-section-title">Upload test</div>
                <div className='import-quotes-modal-section'>
                  <button className="import-button" onClick={() => fileInput.current.click()} children="Choose File" />
                  <div className="import-file-label">{fileName}</div>
                  <input type="file" accept=".yml" ref={fileInput} onInput={onInputChange} hidden />
                </div>
                <label>
                  Select Environment:&nbsp;
                  <select value={environment} onChange={onEnvironmentChanged}>
                    { environments && environments.map( e=> 
                      <option key={e} value={e}>{e}</option>
                    )}
                  </select>
                </label>
                <button className="import-button" onClick={() => this.handleImport()} children="Import" disabled={fileName === ''} />
              </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RateTestLanding;
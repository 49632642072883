import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet-async';
import { getRateTest } from 'Actions/analysis/api.js'
import { Breadcrumbs, LoadingOverlay, SortableTable, Icon } from 'Atoms';
import { getQuoteStatus } from 'Utils/formatter.js';

import { DefFavicon, DefFaviconName } from 'Utils/branding.js';

const columnData = [
  {
    key: 'name', header: 'Name', render: ({ name, onClickLink }) =>
      <span className="text-link" onClick={onClickLink}>{name}</span>
  },
  { key: 'quoteUrl', header: 'Link to Quote', sortable: false , 
    render: ({ quoteUrl }) => {
      const quoteRef = quoteUrl ? quoteUrl.split('/').pop() : null;
      return !quoteRef ? null : (
        <span className="text-link"><a href={quoteUrl} target={quoteRef}>{quoteRef}</a></span>
      )
    }
  },
  { key: 'requirementReference', header: 'Requirement Reference', sortable: false },
  { key: 'totalTests', header: 'Tests' },
  { key: 'passedTests', header: 'Passed' },
  { key: 'failedTests', header: 'Failed' },
];

const resultsColumnData = [
  { key: 'passed', render: ({passed}) => {
    const status = passed ? 'quoted' : 'manual';
    const { icon, text } = getQuoteStatus(status)
    return (
      <span className={`status-icon status-icon--${status}`}>
        <Icon name={icon} />
      </span>
    )}
  },
  { key: 'description' },
  { key: 'errors', render: ({errors}) =>
    !!errors && (
        <ul>
          {errors.map( e =>
            <li key={e}>{e}</li>)
            }
        </ul>
        )
      },
];

class RateTestPage extends PureComponent {
  constructor(props) {
    super(props);

    const { runReference } = props.match.params;
    this.state = {
      isLoading: true,
      hideTable: false,
      runReference,
      rateTest: null,
      selected: null,
      results: null,
    };

    this.getRateTest = this.getRateTest.bind(this);
  }

  componentDidMount() {
    this.getRateTest();
  }

  getRateTest() {
    const { runReference } = this.state;
    getRateTest(runReference).then((rateTest) => {
      const pageError = null;//error ? '404 - Could not find any tests' : null;

      this.setState({
        isLoading: false,
        error: pageError,
        hideTable: !!pageError,
        rateTest
      });
    });

  }


  generateTable() {
    const { rateTest, isLoading } = this.state;

    if (!isLoading) {
      const items = rateTest.map((test) => (
        {
          name: test.testName,
          requirementReference: test.requirementReference,
          quoteUrl: test.quoteUrls ? test.quoteUrls[0] : null,
          totalTests: test.designTestResults.length,
          passedTests: test.designTestResults.filter((item) => item.priceTestResult.testPassed).length,
          failedTests: test.designTestResults.filter((item) => !item.priceTestResult.testPassed).length,
          onClickLink: () => this.showResults(test.requirementReference)
        }
      ));

      return (
        <div className="quote-manager__table">
          <SortableTable columnData={columnData} rowData={items} />
        </div>
      )

    } else {
      return (
        <LoadingOverlay children="Retrieving values" />
      )
    }
  }

  showResults(requirementReference) {
    const { rateTest, isLoading } = this.state;

    const resultFilter = rateTest.filter((test) => test.requirementReference == requirementReference);

    if(resultFilter && resultFilter[0]) {
      const test = resultFilter[0];

      const results = test.designTestResults.map((result) => ({
        description: result.designTest.description,
        passed: result.priceTestResult.testPassed,
        errors: result.priceTestResult.errors
      }));

      const selected = test.testName;

      this.setState({ results, selected });
    }

  }

  render() {

    const { error, results, rateTest, isLoading, runReference, selected } = this.state;

    const crumbs = [
      { text: 'Home', link: '/' },
      { text: 'Rate Testing', link: '/rate-testing/' },
    ];

    const planName = rateTest ? rateTest[0].planName : "Loading...";

    if (!isLoading) {
      crumbs.push({ text: planName, link: `/rate-testing/${runReference}` });
    }

    return (
      <section className="rate-test-page">
        <Helmet>
          <title>{planName}</title>
          <link rel={DefFaviconName} type="image/png" href={DefFavicon} />
        </Helmet>

        <Breadcrumbs crumbs={crumbs} />

        <div className="page-content">
          <div className="quote-manager-content">
            <div className="header-wrap --sticky">
              <h1 className="page-header">
                {planName}
              </h1>
            </div>

            {!!error &&
              <Notification.Error children={error} />
            }
            {this.generateTable()}

            { !!results && (
              <div>
                <h2>
                  Results for {selected}
                </h2>
                <div className="quote-manager__table">
                  <SortableTable columnData={resultsColumnData} rowData={results} />
                </div> 
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default RateTestPage;
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useUserContext } from 'Context/UserContext.js';
import { Button, LoadingOverlay } from 'Atoms';
import { AddressLookup, CurrencyLookup, Lookup, PortLookup, BandwidthLookup, TermLookup } from 'Molecules';

import { useCreateQuote } from 'Hooks';
import { getColos, updateUser } from 'Actions/ccm/api.js';
import { getAlpha2 } from 'Utils/countries.js';
import { mapToEcxColoOptions } from 'Utils/ecx.js';

import './CreateQuote.scss';

const CreateQuote = props => {
  const { type, history, quoteOptions } = props;
  const [ colos, setColos ] = useState([]);

  const user = useUserContext();

  //TODO: pass in validation schema
  const config = useRef({
    defaultValues: { 
      serviceClass: 'best-effort',
      currency: user.preferences.default_currency_code || 'USD',
    },
    onSubmit: ({ reference, error }) => (error 
      ? alert('Unable to submit quote request. Please contact support.')
      : history.push(`/quote/${reference}/`)
    ),
    createQuoteParams: { 
      priceAsOrganizationKey: 'equinix-ecx' 
    },
    quoteOptions
  }).current;

  // onMount: Get colos for coloLookup
  useEffect(() => {
    getColos({ organizationKey: 'equinix-ecx', includeHubs: false })
      .then(mapToEcxColoOptions)
      .then(opts => setColos(opts));
  }, []);

  useEffect(function quoteOptionsLoaded() {
    !!quoteOptions && (config.quoteOptions = quoteOptions);
  }, [ quoteOptions ]);

  const onChangeCurrency = (option, onChange) => {
    onChange(option);

    if (option.value !== user.preferences.default_currency_code) {
      updateUser(user.id, { preferences: { default_currency_code: option.value }}).then(user.refresh);
    }
  }

  return (type === 'ethernet'
    ? <Forms.Ethernet colos={colos} config={config} updateCurrency={onChangeCurrency} />
    : <Forms.IP colos={colos} config={config} updateCurrency={onChangeCurrency} />
  )
}

const Forms = {
  Ethernet: ({ colos, updateCurrency, config }) => {
    const defaultValues = {
      ...config.defaultValues,
      term: [{ label: '36 Months', value: '36' }],
      serviceRequirements: 'ethernet',
    };
    const createQuoteParams = {
      ...config.createQuoteParams,
      serviceRequirements: 'ethernet',
    };
    const { lookupProps={}, submit, isLoading } = useCreateQuote({ ...config, defaultValues, createQuoteParams });
    const onChangeCurrency = opt => updateCurrency(opt, lookupProps.currency().onChange);

    return (
      <div className="create-quote">
        {!!isLoading && <LoadingOverlay />}
        <div className="create-quote__currency">
          <span className="create-quote__currency-label">Currency:</span>
          <CurrencyLookup onChange={onChangeCurrency} defaultValue={config.defaultValues.currency} />
        </div>
        <div className="grid col--2">
          <AddressLookup {...lookupProps.locationA()} label="Enter any address" icon="location" />
          <EcxColoLookup {...lookupProps.locationZ()} label="To an Equinix Fabric location" colos={colos} />
        </div>
        <div className="grid col--3">
          <BandwidthLookup {...lookupProps.bandwidth()} />
          <PortLookup {...lookupProps.port()} label="On-Prem Port Speed" />
          <TermLookup {...lookupProps.term()} />
        </div>
        <div className="create-quote__button-wrapper">
          <Button className="button--rounded" onClick={submit} disabled={isLoading} primary children="Get a quote for last mile access" />
        </div>
      </div>
    )
  },
  IP: ({ colos, updateCurrency, config }) => {
    const defaultValues = { 
      ...config.defaultValues,
      term: undefined,
      //port: { value: '10-gb', sortVal: 10000 },
      port: { value: 'least-cost', sortVal: 0 },
    };
    const createQuoteParams = {
      ...config.createQuoteParams,
      serviceRequirements: 'internet',
      productType: 'ip-transit',
    };
    const { lookupProps={}, submit, isLoading, updateForm } = useCreateQuote({ ...config, defaultValues, createQuoteParams });
    const onChangeCurrency = opt => updateCurrency(opt, lookupProps.currency().onChange);
    const onChangeBandwidth = val => updateForm('bandwidth', val);
    const onChangeIpv4 = val => updateForm('ipv4', val);
    const ipv4Props = lookupProps.ipv4();
    const ipv4Val = ipv4Props?.value?.value;

    const termOptions = useRef([
      { label: 'Month to Month', value: '1', sortVal: 1 },
      { label: '12 Months', value: '12', sortVal: 12 },
      { label: '24 Months', value: '24', sortVal: 24 },
      { label: '36 Months', value: '36', sortVal: 36 },
    ]).current;

    return (
      <div className="create-quote">
        {!!isLoading && <LoadingOverlay />}
        <div className="create-quote__currency">
          <span className="create-quote__currency-label">Currency:</span>
          <CurrencyLookup onChange={onChangeCurrency} defaultValue={config.defaultValues.currency} />
        </div>
        <div className="grid col--3">
          <EcxColoLookup {...lookupProps.locationA()} label="Equinix Fabric location" colos={colos} />
          <BandwidthLookup {...lookupProps.bandwidth()} onChange={onChangeBandwidth} />
          <TermLookup {...lookupProps.term()} options={termOptions} />
        </div>
        <div className="grid" style={{ justifyContent: 'space-around', rowGap: '0.25rem' }}>
          <Lookup 
            {...ipv4Props}
            formatOptionLabel={(opt) => <>{opt?.label}{!!opt?.value && opt.value !== 'cidr-30' && <sup>*</sup>}</>}
            label="IPv4 Addresses"
            onChange={onChangeIpv4}
          />
          {!!ipv4Val && ipv4Val !== 'cidr-30' && (
            <div className="widget-create-quote__additional-costs">
              <sup>*</sup>Additional costs may apply
            </div>
          )}
        </div>
        <div className="create-quote__button-wrapper">
          <Button className="button--rounded" onClick={submit} disabled={isLoading} primary children="Get a quote for internet" />
        </div>
      </div>
    )
  }
}

const EcxColoLookup = ({ colos, ...props }) => {
  const getOptionValue = opt => opt.id;
  const getOptionLabel = opt => opt.name;

  const ColoOption = ({ name, countryCode }) => {
    const alpha2 = (getAlpha2(countryCode) || '').toLowerCase();
    return (
      <div className={`address-option em-flag em-flag-${alpha2}`}>{name}</div>
    )
  }

  const coloFormatOptionLabel = (option, { context }) => (
    (context === 'menu') ? <ColoOption {...option} /> : option.name
  );
  
  return <Lookup {...props} icon="data-center" options={colos} formatOptionLabel={coloFormatOptionLabel} getOptionValue={getOptionValue} getOptionLabel={getOptionLabel} />
}

export default withRouter(CreateQuote);

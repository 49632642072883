import React, { useState, useEffect } from 'react';
import groupBy from 'lodash/groupBy';
import Lookup from './Lookup.jsx';
import { getUsers } from 'Actions/ccm/api.js';
import { sortByLabel } from 'Utils/array.js';

const UserDropdown = props => {
  const { onFetch, optionsFilter, organization, managedUsers=true, includeInternal, isActive=true, ...otherProps } = props;
  const [ options, setOptions ] = useState([]);

  useEffect(function onMount(){
    const toUserOption = (data) => ({ label: (data.name || data.email), value: data.id, data });
    const toGroupOption = ([label, users]) => ({ 
      label,
      orgId: users[0].organization_id,
      isInternal: users[0].is_internal,
      options: users.map(toUserOption).sort(sortByLabel),
    });

    const orParams = organization && includeInternal ? 'organization,is_internal' : undefined;

    getUsers({
      page_size: 2000, 
      managed_users: managedUsers, 
      organization, 
      is_internal: includeInternal, 
      or_params: orParams,
      is_active: isActive || undefined,
    }).then(resp => { 
      const orgUsers = groupBy(resp.results, 'organization_name');
      const options = Object.entries(orgUsers)
        .map(toGroupOption)
        .sort(sortByLabel);

      setOptions(options);
      onFetch && onFetch(resp.results);
    });
  }, []);

  const visibleOptions = optionsFilter ? options.filter(optionsFilter) : options;

  return (
    <Lookup name="users" label="Users" options={visibleOptions} isClearable={false} isLoading={!options.length} {...otherProps} />
  )
}

export default UserDropdown;
import React, { useState, useEffect } from 'react';

import { Checkbox, LoadingOverlay, TextInput } from 'Atoms';
import { CurrencyLookup, Lookup, LocationCombo, TermLookup, CarrierLookup, Popup } from 'Molecules';

import { toSortedQuoteOptions, getInternetOptions } from 'Widgets/CreateQuote/CreateQuoteMapper.js';
import { getQuoteOptions, getColos, cloneFolder } from 'Actions/ccm/api.js';


import './CloneFolderModal.scss';

const CloneFolderModal = props => {
  const { onConfirm, onClose, name, id } = props;

  const [ quoteOptions, setQuoteOptions ] = useState([]);
  const [ colos, setColos ] = useState([]);
  const [ form, setForm ] = useState({ folder: name + ' (CLONED)' });
  const [ enabled, setEnabled ] = useState({ locationZ: false, excludedCarriers: false, currency: false, term: false, bandwidth: false, port: false });
  const [ errors, setErrors ] = useState({});
  const [ isFetching, setIsFetching ] = useState(false);

  useEffect(() => {
    getQuoteOptions().then(resp => {
      const options = toSortedQuoteOptions(resp);
      options.term = options.term
        .filter(x => x.value !== "1") // Hide month-to-month option
      setQuoteOptions(options);
    });
    getColos({ includeHubs: false }).then(colos => setColos(colos));
  }, []);

  const unsetError = key => errors[key] && setErrors({ ...errors, [key]: undefined });

  const updateForm = (key, value) => {
    setForm({...form, [key]: value});
    unsetError(key);
  }

  const onCheck = ({ target }) => {
    const name = target.id.replace('cb_', '');
    setEnabled({ ...enabled, [name]: target.checked });
    errors.form && unsetError('form');
    !target.checked && errors[name] && unsetError([name]);
  }

  const onInput = ({ target: {id, value} }) => updateForm(id, value);
  const onChange = key => opt => updateForm(key, opt);

  const isValid = () => {
    const err = {
      bandwidth: enabled.bandwidth && !form.bandwidth && '* Please specify bandwidth' || undefined,
      port: enabled.port && !form.port && '* Please specify port' || undefined,
      term: enabled.term && (!form.term || !form.term.length) && '* Please specify term' || undefined,
      folder: (!form.folder.trim()) && '* Please specify folder name' || undefined,

      form: !Object.values(enabled).some(x => !!x) && '* Please select at least one override'
    };

    setErrors(err);
    return !Object.values(err).filter(x => !!x).length;
  }

  const onSubmit = () => {
    if (isValid()) {
      const requirements = Object.entries(enabled)
        .filter(x => !!x[1])
        .reduce((acc, [key, val]) => ({...acc, [key]: form[key] }), {});

      setIsFetching(true);
      cloneFolder({ folderId: id, folder: form.folder, requirements }).then(({ error, ...resp }) => {
        if (error) {
          const deepVal = x => typeof(x) === 'object' ? Object.values(x).map(deepVal) : x;
          const getErrors = x => Object.values(x).map(deepVal).flatten(100);
          
          const { new_folder_name, ...specs } = error;
          setIsFetching(false);
          return setErrors({ specs: getErrors(specs), folder: new_folder_name });
        }

        return onConfirm(resp);
      })
    }
  }
  const internetOptions = getInternetOptions(quoteOptions);

  const popupProps = {
    label: "Clone quotes into new folder",
    onClose,
    button1: { label: 'Cancel', onClick: onClose },
    button2: { label: 'Submit', onClick: onSubmit, primary: true, disabled: isFetching },
    id: "clone-folder-popup",
  };

  const checkboxProps = (name, label) => ({
    label: `Override ${label}`, id: `cb_${name}`, checked: enabled[name], onChange: onCheck, className: 'clone-folder__checkbox'
  });

  const lookupProps = (id, label, ...otherProps) => ({
    label, name: id, onChange: onChange(id), isDisabled: !enabled[id], error: errors[id], ...otherProps 
  });

  return (
    <Popup {...popupProps}>
      {!!isFetching && <LoadingOverlay />}
      <TextInput label="Cloned Folder Name" id="folder" className="text-input__new-folder" icon="folder" onInput={onInput} value={form.folder} error={errors.folder} />
      <br />
      <div>
        <Checkbox {...checkboxProps('locationZ', 'To Location')} />
        <LocationCombo {...lookupProps('locationZ', 'To Location')} colos={colos} showInternet internetOptions={internetOptions} showLeastCostByOrg />
      </div>
      
      <div className="flex-row flex-row--3-col">
        <div>
          <Checkbox {...checkboxProps('bandwidth', 'Bandwidth')} />
          <Lookup {...lookupProps('bandwidth', 'Bandwidth')} options={quoteOptions.bandwidth} />
        </div>
        <div>
          <Checkbox {...checkboxProps('port', 'Port')} />
          <Lookup {...lookupProps('port', 'Port')} options={quoteOptions.port} />
        </div>
        <div>
          <Checkbox {...checkboxProps('term', 'Term')} />
          <TermLookup {...lookupProps('term', 'Term')} options={quoteOptions.term} />
        </div>
      </div>

      <div className="flex-row">
        <div className="flex--2-over-3">
          <Checkbox {...checkboxProps('excludedCarriers', 'Excluded Carriers')} />
          <CarrierLookup {...lookupProps('excludedCarriers', 'Excluded Carriers')} icon="not-allowed" />
        </div>
        <div>
          <Checkbox {...checkboxProps('currency', 'Currency')} />
          <CurrencyLookup {...lookupProps('currency')} defaultValue='USD' />
        </div>
      </div>

      { !!errors.form && ( 
        <div className="clone-folder__form-errors">{errors.form}</div>
      )}
      { !!errors.specs && !!errors.specs.length && (
        <div className="clone-folder__spec-errors">
          The following errors occurred while attempting to clone quotes in the folder. Please review these errors and try again later.
          { errors.specs.map((children, key) => <div key={key}>{children}</div> )}
         </div>
      )}
    </Popup>
  );
}

export default CloneFolderModal;

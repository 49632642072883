import React, { useCallback, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { Tooltip } from 'Atoms';

const delimitedValue = (values, sortKey) => (
  values && sortKey && sortBy(values, sortKey).map(x => x.label).join(', ')
);

const getSelectRef = (inputRef) => {
  const isSelectRef = r => r?.constructor.name === 'Select' ? r : null;
  const ref = inputRef?.current?.select;

  return isSelectRef(ref) || isSelectRef(ref?.select) || isSelectRef(ref?.select?.select);
};

const getTooltipTitle = (ref) => {
  // Get inner select reference from its state manager to retrieve its props and refs
  const selectRef = getSelectRef(ref) || {}
  const { commonProps, inputRef, controlRef } = selectRef;
  const { selectProps, hasValue, getValue, multiSortKey='label' } = (commonProps || {});
  
  // Get inner DOM element
  const innerRef = selectProps?.editable 
    ? inputRef 
    : controlRef?.querySelector('.react-select__single-value');

  // Determine if there is a value and that DOM element is overflowing
  const overflowing = hasValue && innerRef && (innerRef.scrollWidth > innerRef.clientWidth);

  // If value is overflowing, return the computed label, else return empty string
  return overflowing ? delimitedValue(getValue(), multiSortKey) : '';
};

const LookupTooltip = ({ inputRef, children }) => {
  const [ titleText, setTitleText ] = useState('');

  const onMouseEnter = useCallback(() => {
    const newTitle = getTooltipTitle(inputRef);
    setTitleText(newTitle);
  }, [inputRef])

  // Falsey value will destroy tooltip and interrupt DOM and component structure. 
  // Always return title but hide visibility instead.
  const open = !titleText ? false : undefined;
  const title = titleText || ' ';
  const className = 'react-select__tooltip';

  return (
    <div onMouseEnter={onMouseEnter}>
      <Tooltip title={title} className={className} open={open}>
        <div>{children}</div>
      </Tooltip>
    </div>
  )
}

export default LookupTooltip
import { useCallback, useMemo, useState } from 'react';
import { getInternetType } from 'Utils/ccm.js';

const LINK_TYPES = ['cross-connect', 'network-allocation', 'provider-connection'];

const VALIDATION_MSG = {
  designTerm: 'Please select a Product Access Type.',
  term: 'Please select a term.',
  leadTime: 'Please select a lead time.',
  locationPairs: 'Please ensure each location pair has a provider connection or cross-connect between them.',
  missingLocation: 'A provider connection or cross-connect cannot appear after the final quote destination.',
  noItems: 'Please select a design item to add.',
  internetItem: 'Design Items can not appear after Internet.',
  marginPct: 'Invalid margin.',
  commissionPct: 'Invalid commission.',
}

// Hook used specifically for Design Editor before submitting to api
const useDesignValidator = () => {

  // useState - store lookup for current validation errors
  const [ validationErrors, setValidationErrors ] = useState({});

  // Internal method to map invalid assertions to validation error messages
  const assertErrors = verifyObj => Object.entries(verifyObj)
    .map(([ key, test ]) => (test ? { [key]: VALIDATION_MSG[key] } : false))
    .filter(Boolean)
    .reduce((acc, item) => ({ ...item, ...acc }), {});

  // VALIDATION - Validate all required editable design fields have values
  const validateDesignFields = ({ term, leadTime, designType }) => {
    return {
      term: !term,
      leadTime: !leadTime,
      designType: !designType,
    }
  }

  // VALIDATION - Validate that every location is connected with a qualifying link type
  const validateLinks = ({ items }) => {
    const containsLink = group => group
      .split(',')
      .filter(Boolean)
      .some(type => LINK_TYPES.includes(type));

    const groups = items
      .map(x => x.type === 'location' ? '|' : x.type)
      .join()
      .split('|');

    const firstLoc = groups.shift();
    const lastLoc = groups.pop();

    // Invalid if there is a link before locationA or if any grouping does not contain a link
    const isMissingLink = !!firstLoc || !groups.every(containsLink);

    return {
      locationPairs: isMissingLink,
      missingLocation: containsLink(lastLoc),
    }
  }

  // VALIDATION - Validate that design has design items
  const validateHasItems = ({ items }) => {
    return {
      noItems: !((items.filter(x => x.type !== 'location')).length)
    }
  }

  // VALIDATION - Validate that internet location is last item
  const validateInternetItems = ({ designType, items }) => {
    const isInternet = !!getInternetType(designType);
    const lastItem = items[items.length-1];

    return {
      internetItems: isInternet && lastItem.type === 'location' && lastItem.internetType
    }
  }

  // VALIDATION - Validate that internet location is last item
  const validatePricing = ({ pricing: { marginPct=0, commissionPct=0 } }) => {
    const invalidPct = pct => pct > 100;

    return {
      marginPct: invalidPct(marginPct),
      commissionPct: invalidPct(commissionPct),
    }
  }

  // validate all validation methods - props: { designType, leadTime, term, items }
  const validate = useCallback((props) => {
    const newValidationErrors = assertErrors({
      ...validateDesignFields(props),
      ...validateLinks(props),
      ...validateHasItems(props),
      ...validateInternetItems(props),
      ...validatePricing(props),
    });

    setValidationErrors(newValidationErrors);

    return {
      validationErrors: newValidationErrors,
      isValid: !Object.keys(newValidationErrors).length
    }
  }, []);

  // clear an error message
  const clearError = useCallback(key => {
    if (!validationErrors[key]) return;

    const newValidationErrors = { ...validationErrors };
    delete newValidationErrors[key];
    setValidationErrors({...newValidationErrors})
  }, [validationErrors]);
  
  // clear all errors
  const clearErrors = useCallback(() => setValidationErrors({}), []);

  const setServerErrors = useCallback((errs={}) => {
    const serverErrors = Object.entries(errs).map(([key, val]) => `${key}: ${val}`);
    
    setValidationErrors(prev => ({ ...prev, serverErrors }));
  }, []);

  // useMemo - Determine if any validation errors exists
  const isValid = useMemo(() => (
    !Object.keys(validationErrors).length
  ), [validationErrors]);

  // return hook methods
  return {
    isValid,
    validationErrors,
    validate,
    clearError,
    clearErrors,
    setServerErrors,
  }
}

export default useDesignValidator;

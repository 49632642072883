import React, { useRef, useState } from 'react';
import { LoadingOverlay, TextInput } from 'Atoms';
import { Lookup, Popup } from 'Molecules';
import { useUserContext } from 'Context/UserContext';

import { reviewRequest } from 'Actions/ccm/api.js';

import './QuoteReviewModal.scss';

const QuoteReviewModal = props => {
  const { onConfirm, onClose, quoteId } = props;

  const [ isFetching, setIsFetching ] = useState(false);
  const [ reasons, setReasons ] = useState([]);
  const [ comment, setComment ] = useState('');

  const user = useUserContext();

  const reviewOptions = useRef([
    ...(user.is_internal ? [
      'Order in hand',
    ]:[]),
    'Carrier Diversity',
    'Extended Demarc',
    'KMZ File',
    'Latency',
    'Route Diversity',
    'Route Protection',
    'Target Pricing',
    'Other',
  ].map(label => ({ label, value: label }))).current;

  const onChange = (opts) => setReasons(opts);
  const onInput = e => setComment(e.target.value);

  const handleSubmit = async () => {

    setIsFetching(true);
    const reasonVals = reasons && reasons.map(x => x.value);
    const resp = await reviewRequest(quoteId, reasonVals, comment.trim());
    if (!resp.error) return onConfirm && onConfirm();
    
    setIsFetching(false);
    alert('There was an error setting quote to review status. Please contact support');
  }

  const noButton = { label: 'Cancel', onClick: onClose };
  const yesButton = { label: 'Confirm', onClick: handleSubmit, disabled: isFetching || !reasons || !reasons.length, primary: true };

  return (
    <Popup label="Request a review of this quote" onClose={onClose} button1={noButton} button2={yesButton} id="review-quote-modal">
      { isFetching && <LoadingOverlay /> }
      <Lookup label="Reasons for review" name="request-review" options={reviewOptions} isMulti={true} onChange={onChange} />
      <br />
      <TextInput label="Details" placeholder="Provide details if you would like to include a new quote comment." onChange={onInput} rows="6" style={{ width: '100%' }} type="textarea" maxLength="2000" />
    </Popup>  
  );
}

export default QuoteReviewModal;
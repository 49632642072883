import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CN from 'classnames';
import uniqueId from 'lodash/uniqueId';
import groupBy from 'lodash/groupBy';

import { ContextMenu, ContextMenuButton } from 'Atoms';

import './SortableTable.scss';

class SortableTable extends Component {
  constructor(props) {
    super(props);

    const { defaultSort = '-date' } = props;

    const sortAsc = defaultSort.charAt(0) !== '-';
    const sortBy = (!!defaultSort && !sortAsc) ? defaultSort.substring(1) : defaultSort;

    const activeKeys = props.columnData.reduce((acc, { keys=[] }) => 
      (keys.length ? [...acc, keys[0]] : acc)
    , []);

    this.state = {
      activeKeys,
      contextMenuItems: [],
      sortAsc,
      sortBy,
      selected: {}
    }

    this.getSelected = this.getSelected.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.toggleKeys = this.toggleKeys.bind(this);
    this.toTableHeader = this.toTableHeader.bind(this);
    this.toStackedheader = this.toStackedheader.bind(this);
    this.toGroupedData = this.toGroupedData.bind(this);
    this.toGroupedRows = this.toGroupedRows.bind(this);
    this.toTableRow = this.toTableRow.bind(this);

    this.CONTEXT_MENU_ID = uniqueId('STACKED_SORTING_HEADER');
  }

  componentDidMount() {
    
  }

  updateSort(name) {
    const { sortBy, sortAsc } = this.state;
    let propSortOrdering = {};

    if (this.props.onSort) {
      const ordering = this.props.onSort(name);
      
      if (ordering) {
        propSortOrdering = { sortAsc: ordering[0] !== '-' };
      }
    }

    this.setState({
      sortBy: name,
      sortAsc: (sortBy === name ? !sortAsc : 0),
      ...propSortOrdering
    })
  }

  toggleKeys(oldKey, newKey) {
    this.setState({ activeKeys: this.state.activeKeys.map(key => key === oldKey ? newKey : key) })
  }

  toTableHeader(column) {
    const { sortBy, sortAsc } = this.state;
    const { key, keys, header, headers, sortable=true } = column;

    const className = CN(`sortable-table-column sortable-table-column--${key} sortable-table-column__header sortable-table-column__header--${key}`, {
      '--sort-ascending': ((key === sortBy) && sortAsc),
      '--sort-descending': ((key === sortBy) && !sortAsc),
      '--not-sortable': !sortable,
    })
    
    const onClick = sortable ? (() => this.updateSort(key)) : null;

    return (
      keys
        ? this.toStackedheader({ keys, header, headers, sortable }) :
      (!!key && key === this.props.groupedBy)
        ? null
        : <th onClick={onClick} className={className} key={key}>{header || key}</th>
    );
  }

  toStackedheader({ keys, header, headers={}, sortable=true }) {
    const { sortBy, sortAsc, activeKeys=[] } = this.state;
    
    const activeKey = keys && activeKeys.find(k => keys.includes(k));
    const inactiveKey = activeKey && keys.find(x => x !== activeKey);

    const className = CN(`
    sortable-table-column sortable-table-column--${activeKey} 
    sortable-table-column__header 
    sortable-table-column__stacked-header
    sortable-table-column__header--${activeKey}`, {
      '--sort-ascending': ((activeKey === sortBy) && sortAsc),
      '--sort-descending': ((activeKey === sortBy) && !sortAsc),
      '--not-sortable': !sortable,
    });

    const contextMenuItems = keys && keys.map(key => ({ 
      label: headers[key] || key, 
      icon: key === activeKey ? 'checkmark' : null, 
      className: CN({ 'context-menu-item--unselected': key === inactiveKey }),
      onClick: () => {
        this.updateSort(key);
        (key === inactiveKey) && this.toggleKeys(activeKey, inactiveKey);
      }
    }))

    const children = header || headers[activeKey] || activeKey;
    const onClick = () => this.setState({ contextMenuItems });

    return (
      <th className={className} key={activeKey}>
        {sortable 
          ? <ContextMenuButton menuId={this.CONTEXT_MENU_ID} children={children} onClick={onClick} />
          : children
        }
      </th>
    )
  }

  toGroupedRows([key, rows]) {
    const { columnData, groupedBy } = this.props;

    const className = `sortable-table-group-header sortable-table-group-header--${groupedBy}`;
    const Cell = columnData.find(x => x.key === groupedBy)?.render?.({ [groupedBy]: key, rows }) || key;

    return (
      <>
        <tr key={key} className={`sortable-table-row__grouped_row`}>
          <td className={className} colSpan={columnData.length-1}>
            { Cell }
          </td>
        </tr>
        {this.toSortedData(rows).map(this.toTableRow)}
      </>
    )
  }

  toTableRow(rowData, r) {
    const { columnData, groupedBy, isSelectable } = this.props;
    const { sortAsc, sortBy, activeKeys=[] } = this.state;
    const rowKey = rowData.id || `${rowData[groupedBy] || 'row'}_${r}`;
    const isSelected = this.state.selected[rowKey];
    const classNames = {
      row: CN(`sortable-table-row ${rowData.className || ''}`, { 
        'sortable-table-row--selected': isSelected }
      ),
      column: key => `sortable-table-column sortable-table-column--${key}`,
      columnData: key => `sortable_table_column_data--${key}`,
    };

    const onClick = isSelectable ? () => this.toggleSelected(rowKey, rowData) : undefined;

    return (
      <tr className={classNames.row} key={rowKey} onClick={onClick}>
        {columnData.map((col, c) => {
          const key = `r${r}c${c}`;
          const activeKey = col.keys && activeKeys.find(k => col.keys.includes(k));
          const inactiveKey = activeKey && col.keys.find(x => x !== activeKey);

          const cell = (
            (col.render)
              ? col.render(rowData, { row: r, column: c, sortAsc, sortBy, activeKey, inactiveKey, keys: col.keys }) : 
            (col.keys) ? (
              <>
                <div className={classNames.columnData('primary')} children={rowData[activeKey]} />
                <div className={classNames.columnData('secondary')} children={rowData[inactiveKey]} />
              </>
            ) : rowData[col.key]
          );
          
          return (groupedBy && col.key === groupedBy) 
            ? null 
            : <td key={key} className={classNames.column(col.key || activeKey)}>{cell}</td>
        })}
      </tr>
    )
  }

  toGroupedData(rowData, groupedBy) {
    const byKey = ([keyA,], [keyB,]) => (keyA.toLowerCase() > keyB.toLowerCase() ? 1 : -1 );
    return Object.entries(groupBy(rowData, groupedBy)).sort(byKey);
  }

  toSortedData(rowData) {
    const { sortBy, sortAsc } = this.state;
    
    if (!sortBy || this.props.onSort) {
      return rowData
    }

    return (sortAsc)
      ? rowData.sort((a, b) => ((typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') ? a[sortBy].toLowerCase() > b[sortBy].toLowerCase() : a[sortBy] > b[sortBy]) ? 1 : (a[sortBy] === b[sortBy] ? 0 : -1 )) 
      : rowData.sort((a, b) => ((typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') ? a[sortBy].toLowerCase() < b[sortBy].toLowerCase() : a[sortBy] < b[sortBy]) ? 1 : (a[sortBy] === b[sortBy] ? 0 : -1 ));
  }

  toggleSelected(rowKey, rowData) {
    let selected = { ...this.state.selected };

    (selected[rowKey])
      ? delete selected[rowKey]
      : selected[rowKey] = rowData;

    this.setState({ selected });
    this.props.onSelect && this.props.onSelect(Object.values(selected));
  }

  getSelected() {
    return Object.values(this.state.selected);
  }

  clearSelected() {
    this.setState({ selected: {} });
    this.props.onSelect && this.props.onSelect([]);
  }

  render() {
    const { className="", rowData, columnData, isSelectable, filter, groupedBy } = this.props;
    const classNames = CN(`sortable-table ${className}`, { 
      'sortable-table--selectable': isSelectable 
    });

    // const filtered = filter ? rowData.filter(filter) : rowData;
    // const sorted = this.toSortedData(filtered);
    const items = this.state.contextMenuItems;

    const TableRows = groupedBy
      ? this.toGroupedData(rowData, groupedBy).map(this.toGroupedRows)
      : this.toSortedData(rowData).map(this.toTableRow);

    return (<>
      <table className={classNames}>
        <thead>
          <tr>
            {columnData.map(this.toTableHeader)}
          </tr>
        </thead>
        <tbody>
          { TableRows }
        </tbody>
      </table>
      <ContextMenu id={this.CONTEXT_MENU_ID} items={items} position="auto" className="sortable-table__context-menu" />
    </>);
  }
}

SortableTable.proptypes = {
  className: PropTypes.string,
  rowData: PropTypes.array,
  columnData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      header: PropTypes.node,
      render: PropTypes.func,
      sortable: PropTypes.bool
    })
  )
}

export default SortableTable;

import React from 'react';
import { withUserContext } from 'Context/UserContext.js';
import { useToggle } from 'Hooks';

import CN from 'classnames';
import { Button, Icon, LoadingIcon, TimeAgo, Tooltip } from 'Atoms';
import { ECX_PORT_LABEL } from 'Utils/ecx.js';
import { toIpv4Option } from 'Utils/formatter.js';
import { PERMISSIONS } from 'Utils/const.js';
import { useUserContext } from 'Context/UserContext';

import './QuoteRequirements.scss';

const QuoteRequirements = props => {
  const { id, locationA={}, locationZ, term, port, bandwidth, ipv4,
    serviceRequirements, crossConnectA, crossConnectZ,
    excludedCarrierNames, extendedDemarc, productType, isLoading = !id,
    csaName, salesRepName, assignedName, followUpCategory, unitasIpEndUserRate,
    popSelectionOrganizationKey, tags=[], userName,
    userOrganization, partnerType, createTime,
  } = (props.requirements || {});

  const excludedCarriers = excludedCarrierNames?.join(', ');

  // Hooks
  const user = useUserContext();
  const [ advancedIsCollapsed, toggleAdvanced ] = useToggle(true);

  // User/quote identifiers
  const isInternet = (serviceRequirements !== 'Ethernet' && serviceRequirements !== 'Layer-1-wave');
  const isInternal = user.is_internal;
  const isEcx = user.isEcx;
  const isEcxIp = isEcx && (productType || []).includes('ip-transit');

  // String formatting
  const pricingOrg = (
    user.isEcx 
      ? '' :
    partnerType 
      ? ` priced for ${userOrganization}`
      : ` (${userOrganization})`
  );

  const unitasReps = [
    salesRepName && `${salesRepName} (Sales Rep)`,
    csaName && `${csaName} (CSA)`,
    assignedName && `${assignedName} (Pricing Desk)`,
  ].filter(Boolean).join(', ');
 
  // Classnames
  const classNames ={
    component: CN('quote-spec', { 'quote-spec--ecx': isEcx }),
    button: CN('quote-spec__toggle-button', { '--expanded': !advancedIsCollapsed }),
    followUp: CN('quote-spec__follow-up'),
  };

  const locationZProps = (isInternet ? {
    line1: serviceRequirements,
    icon: 'internet',
    tooltip: false,
    ipv4
  } : isEcx ? {
    line1: locationZ ? 'Equinix Fabric in ' +  locationZ.displayName : null,
    icon: 'data-center',
    tooltip: false,
  } : {
    icon: !locationZ && !isLoading && 'least-cost',
    line1: locationZ ? locationZ.line1 : !isLoading ? 'Least Cost' + (isInternal ? ` (${popSelectionOrganizationKey})` : '') : null,
    floorSuite: extendedDemarc && locationZ && !locationZ.data_center_url && locationZ.floorSuite,
    line2: locationZ && locationZ.line2,
    popName: locationZ && locationZ.popName,
  });

  return (
    <div className={classNames.component}>
      <div className="quote-spec-title">
        {!!userName && (
          <div className="flex-row">{userName}{pricingOrg}<TimeAgo date={createTime} /></div>
        )}
        {!!isInternal && unitasReps && (
          <div>
            <div>{unitasReps}</div>
            {!!followUpCategory && (
              <div className={classNames.followUp}>
                <Icon name="calendar" />{followUpCategory}
              </div>
            )}
          </div>
        )}
      </div>
      {!id ? <LoadingIcon /> : (
        <div className="quote-spec-options">
          <QuoteLocation
            popName={!isEcx && locationA ? locationA.popName : undefined}
            line1={!isEcxIp ? locationA.line1 : locationA.displayName }
            floorSuite={extendedDemarc && !isEcxIp && !locationA.data_center_url ? locationA.floorSuite : undefined}
            line2={!isEcxIp ? locationA.line2 : undefined }
            xconn={crossConnectA}
            location={locationA}
          />
          <QuoteLocation {...locationZProps} xconn={crossConnectZ} location={locationZ} unitasIpEndUserRate={unitasIpEndUserRate} />

          <div className="quote-spec-connection">
            <div className="quote-spec--bandwidth">
              <div className="quote-spec__label">Bandwidth</div>
              <div className="quote-spec__value">{bandwidth}</div>
            </div>
            {!isEcxIp && (
              <div className="quote-spec--port">
                <div className="quote-spec__label">{user.isEcx ? ECX_PORT_LABEL : 'Port Speed'}</div>
                <div className="quote-spec__value">{port}</div>
              </div>
            )}
            <div className="quote-spec--term">
              <div className="quote-spec__label">Term</div>
              <div className="quote-spec__value">{term}</div>
            </div>
          </div>
      </div>
    )}

    {!!id && (
      <div className="quote-spec__bottom-row">
        <div className="quote-spec__tags">
          {!!excludedCarriers && <div className="quote-spec__tag">Excluded Carriers &gt; <span className="quote-spec__tag-extra">{excludedCarriers}</span></div> }
          { tags.map(x => <div className="quote-spec__tag" key={x} children={x} />) }
        </div>

        <div className="quote-spec-button-wrapper">
          <Button type="link" className={classNames.button} onClick={toggleAdvanced}>Disclaimer</Button>
        </div>
      </div>
    )}

    {(!advancedIsCollapsed) && (
      <div className="quote-spec-advanced">
        <div className="quote-spec-disclaimer">
          <span className="advanced-option__value">Pricing is valid for 60 days. Taxes, surcharges, and government fees are not included in the pricing provided and will be invoiced separately. Pricing provided stops at the minimum point of entry (MPOE), demarc extensions are an additional cost component. Pricing does not include cross-connects, unless specifically requested. Pricing is based on available capacity at time of order and is subject to change based on network augments required. This price estimate is based on currency conversion rates at the time it was created. Such rates may fluctuate up or down by the time of order and will be reflected in the order price.</span>
        </div>
      </div>
    )}
  </div>
)}


const QuoteLocation = ({ line1, line2='', popName, floorSuite, icon, xconn, tooltip, location, unitasIpEndUserRate, ipv4 }) => {
  const user = useUserContext();

  const { latLong, data_center_url } = (location || {})
  const locationType = location && (location.location_type || location.locationType);
  const isAtDatacenter = location && (data_center_url || ['pop', 'hub', 'colocation'].includes(locationType));
  const locationIcon = icon || (isAtDatacenter ? 'data-center' : 'location');
  const ipv4Label = ipv4 && ipv4 !== 'cidr-30' && (
    `IPv4 Addresses: ${toIpv4Option({ value: ipv4 }).label}`
  );
  const showLatLong = user.hasPermission(PERMISSIONS.VIEW_LATLONG) && !!latLong;

  // Location props
  const { string_address, additional_details={} } = (location || {});
  const { import_input, autocomplete_input, autocomplete_selection } = additional_details;

  const tooltipDetails = (tooltip === false || !location)  ? [] : [
    { label: 'User Input', value: import_input || autocomplete_input || string_address },
    { label: 'Selected Option', value: autocomplete_selection }
  ].filter(Boolean);

  const LocationTooltip = tooltipDetails.map(({ label, value }) => (
    <div key={label} className="quote-location-tooltip__user-input">
      <dt>{label}</dt><dd>{value}</dd>
    </div>
  ));

  return (
    <div className="quote-spec-location">
      <div className="quote-spec__value">
        <Tooltip trigger='click' title={LocationTooltip}>
          <div><Icon name={locationIcon} className={CN({ 'location-icon--has-tooltip': !!tooltip })} /></div>
        </Tooltip>
        {!!popName && <div>{ popName }</div>}
        <div>{ line1 }</div>
        {!!floorSuite && <div>{ floorSuite}</div>}
        <div>{ line2 }</div>
        { !!showLatLong && (
          <div className="quote-spec-location__latlong">{latLong}</div>
        )}
        {!!xconn && (
          <div className="quote-spec-location__xconn">
            <Icon name="cross-connect" />Cross-connect requested
          </div>
        )}
      </div>
      {!!unitasIpEndUserRate && !!user.is_internal && (
        <div className="quote-spec-location__user-rate">(Unitas IP End-User rate)</div>
      )}
      {!!ipv4Label && (
        <div className="quote-spec-location__ipv4">{ ipv4Label }</div>
      )}
    </div>
  );
}

export default withUserContext(QuoteRequirements);

import React, { useState } from 'react';
import { Button, Icon, LoadingOverlay, Notification, EmailLink } from 'Atoms';
import { useUserContext } from 'Context/UserContext.js';

import { updateQuote } from 'Actions/ccm/api.js';
import { SUPPORT_EMAILS } from 'Utils/const.js';
import { INTERNAL_COMPANY_NAME } from 'Utils/branding.js';
import './ManualPricing.scss';

const ManualPricingNotification = ({ quoteRef, status, locationA={}, locationZ={}, requirements={} }) => {
  const { statusDetails } = requirements;
  const hasManualLocation = locationA.skip_validation || (locationZ && locationZ.skip_validation);
  const subject = `Inquiry regarding manual pricing for Quote Reference: ${quoteRef}`;

  const user = useUserContext();
  const manualPricingMessage = (
    (status === 'error')
      ? 'This quote has received a pricing error from the system and requires manual pricing.' :
    (user.isEcx || user.isPF)
      ? `This quote has been sent to the ${INTERNAL_COMPANY_NAME} Pricing Desk team to be manually-priced. You will receive an email when the quote has been priced.` :
    hasManualLocation && user.is_pricing_desk
      ? 'This quote requires manual pricing due to an unvalidated end-user location. Please provide details below and select a design as the quote solution.' :
    hasManualLocation
      ? 'This quote requires manual pricing due to an unvalidated end-user location.' :
    user.is_internal
      ? `This quote requires manual pricing. ${statusDetails || ''}`
      : 'This quote has been sent to the pricing team for manual pricing. You will receive an email when the quote has been priced.'
  );

  let emailTo = 'connectivitypricing@unitasglobal.com';
  if (user.isEcx) emailTo = SUPPORT_EMAILS.EQUINIX_FABRIC;
  else if (user.isPF) emailTo = SUPPORT_EMAILS.PACKETFABRIC;

  return (
    <Notification.Error className="quote-viewer__manual-pricing-notification">
      {manualPricingMessage}
      {!user.is_internal && (
        <>&nbsp;For further information, please contact:&nbsp;<EmailLink to={emailTo} subject={subject} />.</>
      )}
    </Notification.Error>
  );
}

const ManualPricingReasons = ({ requirements={} }) => {
  const { serviceRequirements } = requirements;

  const [ isFetching, setIsFetching ] = useState(false);

  const repriceAsEthernet = async () => {
    const { id, selections } = requirements;
    const request = { specification: { 
      option_selections: { ...selections, service_requirements: 'ethernet' }}
    };

    setIsFetching(true);
    const { error } = await updateQuote(id, request);
    if (error) {
      alert('There was an error updating this quote to ethernet. Please contact support') 
      return setIsFetching(false);
    }
    window.location.reload();
  }

  return (
    <div className="manual-pricing">
      { !!isFetching && <LoadingOverlay />}
      <header className="manual-pricing__header">
        <Icon name="question-mark" />
        <span>WHY DID THIS HAPPEN?</span>
      </header>
      <div className="manual-pricing__content">
        Quotes can sometimes fail to price for several reasons:
        <ul>
          <li>End-user location contains an invalid address</li>
          <li>End-user location is under contruction</li>
          <li>No on-net or near-net location could be found with the given address</li>
          {(serviceRequirements === 'layer-1-wave')
          ? (
            <li>No Layer 1 Wave products available for this location.
              <div style={{ margin: '1.5rem 0 .75rem' }}>Would you like to try pricing as Ethernet?</div>
              <Button primary onClick={repriceAsEthernet}>Yes, reprice as ethernet</Button>
            </li>
          ) : (        
            <li>Unsupported bandwidth speed requirements at the location</li>
          )}
        </ul>
      </div>
    </div>
  );
}

const ManualPricing = (props) => {
  const user = useUserContext();

  return (
    <>
      <ManualPricingNotification {...props} />

      {!user.is_internal && (
        <ManualPricingReasons requirements={props.requirements}/>
      )}
    </>
  )
}


export default ManualPricing;
import uniqueId from 'lodash/uniqueId';
import findLastIndex from 'lodash/findLastIndex';

import { getLocationValue } from './DesignItem/DesignItemRowHelper.js';

import { getInternetType } from 'Utils/ccm.js';
import { uniqueArray } from 'Utils/array.js';
import { getLabels } from 'Utils/const.js';
import { toIpv4Option } from 'Utils/formatter.js';

import { isSameLocation } from '../../QuoteViewerMapper.js';

export const getDesignEditorOptions = (designType) => ({
  term: [
    (designType?.includes('ip-transit') && { 
      value: '1', label: 'Month-to-Month'
    }),
    { value: '12', label: '12 Months' },
    { value: '24', label: '24 Months' },
    { value: '36', label: '36 Months' },
    { value: '60', label: '60 Months' },
  ].filter(Boolean),
  leadTime: [
    { value: '30-45 Days', label: '30-45 Days' },
    { value: '45-60 Days', label: '45-60 Days' },
    { value: '60-90 Days', label: '60-90 Days' },
    { value: '90-120 Days', label: '90-120 Days' },
  ],
  designType: [
    { value: 'aggregated-ethernet', label: 'Aggregated Ethernet Access' },
    { value: 'aggregated-ethernet-hairpin', label: 'Aggregated Ethernet Access via Hairpin' },
    { value: 'aggregated-ethernet-inter-market-ixc', label: 'Aggregated Ethernet Access via Inter-Market IXC' },
    { value: 'aggregated-ethernet-inter-market-ixc-hairpin', label: 'Aggregated Ethernet Access via Inter-Market IXC and Hairpin' },
    { value: 'broadband-internet', label: 'Broadband Internet', isInternet: 1 },
    { value: 'cross-connect', label: 'Cross Connect' },
    { value: 'direct-internet-access', label: 'Direct Internet Access', isInternet: 1 },
    { value: 'end-to-end-ethernet-in-market-hairpin', label: 'End to End Ethernet via In-Market Hairpin' },
    { value: 'end-to-end-ethernet-inter-market-ixc-hairpin', label: 'End to End Ethernet via Inter-Market IXC Hairpin' },
    { value: 'end-to-end-ethernet-out-of-market-hairpin', label: 'End to End Ethernet via Out-of-Market Hairpin' },
    { value: 'end-to-end-ethernet-point-to-point', label: 'End to End Ethernet via Point to Point' },
    { value: 'end-to-end-ethernet-pop-to-pop', label: 'End to End Ethernet via PoP to PoP' },
    { value: 'ip-transit', label: 'IP Transit', isInternet: 1 },
    { value: 'ip-transit-with-access', label: 'IP Transit with Access', isInternet: 1 },
    { value: 'metro-connect', label: 'Metro Connect' },
    { value: 'out-of-market-ethernet', label: 'Out-of-Market Ethernet Access' },
  ].filter(x => !!x.isInternet === !!getInternetType(designType))
});

const bySortVal = (a, b) => ((a.sortVal < b.sortVal) ? -1 : (a.sortVal > b.sortVal) ? 1 : 0);



export const mapItemsToRows = (design, locationZ=design.locationZ) => {
  const rows = design.designItems.map(toRowsMapper);

  const internetType = getInternetType(design.designType);

  const isItemLocationZ = item => (item
    && !internetType 
    && item.type === 'location' 
    && item.leastCostLocation 
    || (isSameLocation(item, locationZ))
  );

  const locZIndex = findLastIndex(rows, isItemLocationZ);
  const hasLocationZ = !internetType && locZIndex > 0;

  if (!internetType && locZIndex > 0) {
    rows[locZIndex].isLocationZ = true;
    rows[locZIndex].rowKey = 'location_z';
  }

  if (!hasLocationZ) {
    const locZ = toLocationRow({
      address: locationZ,
      isLocationZ: true,
      internetType,
      ...getLocationValue(locationZ)
    });

    rows.push(locZ);
  }
// console.log(rows);
// debugger;
  return rows;
}

const toLocationRow = (locProps) => {
  const { address, isLocationA, isLocationZ, internetType, leastCostLocation } = locProps;
  const label = address?.addressLines?.join(', ');

  return { 
    type: 'location', 
    defaultValue: label ? { label, value: address } : undefined,
    address,
    ...getLocationValue(address),
    isLocationA,
    isLocationZ, 
    internetType: isLocationZ ? internetType : undefined,
    leastCostLocation: isLocationZ ? leastCostLocation : undefined,
    rowKey: uniqueId('locationItem_'),
  };
};

const toItemRow = ({ id, key, mrc, nrc, upload, ...designItem }) => {
  const num = n => Number(n) || 0;

  return {
    ...designItem,
    rowKey: `designItemId_${id || uniqueId('newDesignItem_')}`,
    key: key || id,
    mrc: num(mrc),
    nrc: num(nrc),
    _mrc: num(mrc),
    _nrc: num(nrc),
    upload: num(upload),
  }
};

export const toRowsMapper = item => item.type === 'location' 
  ? toLocationRow(item) 
  : toItemRow(item);



export const getRowOptions = (quoteOptions, design) => {
  const toBandwidth = label => {
    const sortVal = parseFloat(label.replace(' Mbps', '').replace(' Gbps', '')) * (label.endsWith(' Gbps') ? 1000 : 1);
    const value = label.replace(' G', '-g').replace(' M', '-m');
    return { label, sortVal, value };
  };

  const bandwidths = quoteOptions.filter(opt => opt.optionType === 'bandwidth');

  const missing = uniqueArray([design, ...design.designItems]
    .map(x => x.bandwidth)
    .filter(Boolean)
    .filter(x => !bandwidths.find(b => b.label === x))
  );

  return {
    bandwidth: [...bandwidths, missing.map(toBandwidth)].sort(bySortVal),
    port: quoteOptions
      .filter(opt => opt.optionType === 'port' && opt.value !=='least-cost')
      .sort(bySortVal),
    term: [
      { value: '1', label: 'Month-to-Month'},
      { value: '12', label: '12 Months' },
      { value: '24', label: '24 Months' },
      { value: '36', label: '36 Months' },
      { value: '60', label: '60 Months' },
    ],
    ipv4: quoteOptions
      .filter(opt => opt.optionType === 'ipv4_addresses')
      .sort(bySortVal)
      .map(toIpv4Option),
  };
}

export const getTotalLabels = user => {
  const { mrc, nrc, ug_mrr, ug_nrr, mrr, nrr } = getLabels(user);

  const mergeOrg = (m, n) => {
    const parts = `${m} ${n}`.split(' ');
    return parts.length === 4 ? `${parts[0]} ${parts[1]} / ${parts[3]}` : `${m} / ${n}`;
  }

  return {
    cost: mergeOrg(mrc, nrc),
    ugRate: mergeOrg(ug_mrr, ug_nrr),
    rate: mergeOrg(mrr, nrr),
  }
}

export const EDITOR_MODALS = {
  CANCEL: 'CANCEL',
  NO_MARGIN: 'NO_MARGIN',
}

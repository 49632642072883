export const putFirst = (collection, predicate) => {
  const foundIndex = typeof(predicate) === 'function' ? collection.findIndex(predicate) : -1;

  return (foundIndex > -1) 
    ? [collection[foundIndex], ...collection.filter((item, index) => foundIndex !== index)]
    : collection;
}

export const sortByLabel = ((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1);

export const uniqueArray = arr => [ ...new Set(arr) ];

export const uniqueObjectArray = (arr, key) => {
  return arr.filter((x, i) => i === arr.findLastIndex(y => y?.[key] === x?.[key]));
};

export const entries = (obj, callback) => Object.fromEntries(
  callback(Object.entries(obj))
);

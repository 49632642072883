import React, { useEffect, useRef, useState } from 'react';
import CN from 'classnames';
import groupBy from 'lodash/groupBy';
import { Button, Icon } from 'Atoms';
import { Lookup, ModalManager, MODALS, Popup } from 'Molecules';

import { sortByLabel } from 'Utils/array.js';
import { getBandwidthLabel, getInternetServiceRequirements, getTermLabel, mailTo, toAddress } from 'Utils/formatter.js';
import { count } from 'Utils/strings.js';

import { getVendorContacts } from 'Actions/ccm/api.js';

import './ContactVendorModal.scss';

const ContactVendorModal = props => {
  const { selected, onConfirm, onClose } = props;
  const [ value, setValue ] = useState([]);
  const [ options, setOptions ] = useState();
  const [ addingComment, setAddingComment ] = useState(false);
  const [ hasCommented, setHasCommented ] = useState(false);

  const modalRef = useRef();

  useEffect(() => {
    getVendorContacts().then(({ error, results=[] }) => {
      if (error) {
        return alert(`Unable to retrieve contact lists: ${error}`);
      }

      const vendors = Object.entries(groupBy(results, 'vendor_name'));      
      const vendorOptions = vendors.map(([label, contacts=[]]) => ({ 
        label, 
        value: contacts.map(c => c.contact_email).join(';')
      }));
      setOptions(vendorOptions.sort(sortByLabel));
    })
  }, []);

// TODO: Group by location and service type

  const onChange = val => setValue(val);

  const quotes = selected.map(toGroupItem);

  const onClick = () => {
    const body = quotes.map(toEmailList).join('\n\n');
    const refs = selected.map(x => x.reference).slice(0, 10).join(', ')
    const overflow = selected.length > 10 ? '...' : '';

    const href = mailTo({
      to: '',
      cc: 'icbpricing@unitasglobal.com',
      bcc: value.map(val => val.value).join(';'),
      subject: `Pricing Request: ${refs}${overflow}`,
      body,
    });
    window.location.href = href;
    onConfirm?.();
  };

  const noButton = { label: 'Cancel', onClick: onClose };
  const yesButton = { label: 'Generate Email', onClick, primary: true, disabled: !value.length };

  const addComment = () => {
    setAddingComment(true);
    modalRef.current.open(MODALS.BULK_COMMENT, { 
      onConfirm: () => setHasCommented(true),
      onClose: () => setAddingComment(false),
      quotes: selected,
      comment: value?.length 
        ? `ICB request sent to vendors: ${value.map(x => x.label).join(', ')}\n\n`
        : undefined
    });
  }

  return (
    <Popup className={CN({ 'popup--popped-under': addingComment})}
      label="Send ICB request to vendors" 
      onClose={onClose} 
      button1={noButton} 
      button2={yesButton} 
      id="contact-vendor-modal"
    >
      <div className="contact-vendor__actions">
        <VendorLookup options={options} value={value} onChange={onChange} />
        <div className="contact-vendor__add-comment">
        { !hasCommented
          ? <Button type="link" onClick={addComment} icon="comments">Add comment?</Button> 
          : <div className="contact-vendor__comment-added">
              <Icon name="checkmark-circle-outline" color="inherit" />
              <span className="contact-vendor__comment-added-label">Comment added</span>
            </div>
        }
        </div>
      </div>
      <div className="contact-vendor__header">
        {`Send pricing request for these ${count(selected.length, 'quote', 'quotes')}`}
      </div>
      <div className="contact-vendor__list">
        { quotes.map(quote => <QuoteListItem key={quote.reference} {...quote} />) }
      </div>
      <ModalManager ref={modalRef} />
    </Popup>
  )
}

const VendorLookup = ({ options, onChange, value }) => (
  <Lookup label="Vendors" 
    isMulti 
    asChips 
    options={options} 
    isDisabled={!options} 
    hideSelectedOptions={true} 
    value={value} 
    onChange={onChange} 
    components={{ NoOptionsMessage }} 
    isLoading={!options}
    inputId="vendor-request"
    maxMenuHeight="16rem"
  />
);

const NoOptionsMessage = () => null;

const toLocZ = ({ locationZ, serviceRequirements }) => {
  const address = locationZ && toAddress(locationZ);
  return (address) 
    ? `${address.line1}, ${address.line2}`
    : getInternetServiceRequirements(serviceRequirements) || 'Least-Cost';
}

const toGroupItem = quote => {
  const locA = quote.locationA && toAddress(quote.locationA);
  const locZ = toLocZ(quote);
  const serviceType = quote.serviceRequirements;

  return {
    key: quote.reference, //[locA,locZ,serviceType].join('|'),
    locA: locA && `• ${locA.line1}, ${locA.line2}`,
    locZ: locZ && `• ${locZ}`,
    serviceType,
    term: getTermLabel([quote.term]),
    bandwidth: getBandwidthLabel(quote.bandwidth),
    routeDiverse: quote.routeDiverse,
    reference: quote.reference,
  }
}

// const groupQuotes = (quotes=[]) => {
//   const groupItems = quotes.map(toGroupItem);
//   //const groups = groupBy(groupItems, 'key')
//   // groups.map(({ reference, bandwidth, ...group }) => ({ 
//   //   ...group
//   // }))

//   return groupItems;
// }

const QuoteListItem = props => {
  const { reference, locA, locZ, bandwidth, term, routeDiverse, serviceType } = props;
  const dl = (serviceType === 'internet-broadband') ? ' (download) ' : '';

  return (
    <div className="contact-vendor__list-item">
      <div>{`#${reference}`}</div>
      <div>{locA}</div>
      <div>{locZ}</div>
      <div>{`${bandwidth}${dl} — ${term}${routeDiverse ? ' (with route diversity)' : ''}`}</div>
    </div>
  );
}

const toEmailList = props => {
  const { reference, locA, locZ, bandwidth, term, routeDiverse, serviceType } = props;
  const dl = (serviceType === 'internet-broadband') ? ' (download) ' : '';

  return [`#${reference}`, locA, locZ, `${bandwidth}${dl} — ${term}${routeDiverse ? ' (with route diversity)' : ''}`]
    .filter(Boolean)
    .join('\n');
}

export default ContactVendorModal
import React, { Component } from 'react';
import { TextInput } from 'Atoms';
import { Popup } from 'Molecules';
import { updateFolder, updateQuote } from 'Actions/ccm/api.js';
import { capitalize } from 'Utils/strings.js';


class RenameItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      itemName: props.name || '',
      error: null
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(event) {
    const { itemName, isFetching } = this.state;
    const { id, type, name, onConfirm } = this.props;

    // Handle update name request
    const handleRequest = ({ error, ...item }) => {
      const errorName = error && (error.name || []).join(', ');
      if (errorName) {
        return this.setState({ error: errorName, isFetching: false });
      }
      onConfirm({ ...item, prevName: name });
    };
    // Verify not already fetching, has new non-blank name, is valid type
    const isInvalidRequest = (
      (!!isFetching) || (name === itemName) || (!itemName.trim().length) || (type !== 'folder' && type !== 'quote')
    );

    // Stop event form bubbling
    event.preventDefault();
    event.stopPropagation();

    if (isInvalidRequest) return; //this.setState({ error: 'Invalid request' });
    
    this.setState({ isFetching: true, error: null });
    if (type === 'folder') {
      return updateFolder(id, { name: itemName }).then(handleRequest);
    }
    if (type === 'quote') {
      return updateQuote(id, { name: itemName }).then(handleRequest);
    }
  }

  onChange(event) {
    const itemName = event.target.value;
    this.setState({ itemName, error: undefined });
  }

  render() {
    const { onClose, type, name } = this.props;
    const { error, itemName, isFetching } = this.state;

    const formattedType = capitalize(type);
    const label = `Rename ${formattedType}`;
    const inputLabel = `New ${type} name`;
    const disabled = isFetching || (name === itemName) || (!itemName.trim().length);

    const yesButton = {
      label,
      onClick: this.onSubmit,
      primary: true,
      disabled
    };

    const noButton = { label: 'Cancel', onClick: onClose };

    return (
      <Popup label={label} onClose={onClose} button1={noButton} button2={yesButton} id="renameItemModal">
        <form onSubmit={this.onSubmit}>
          <TextInput 
            label={inputLabel}
            id='renameItemModalInput' 
            required 
            onChange={this.onChange} 
            maxLength="255" 
            value={itemName} 
            error={error}
            autoFocus
          />
        </form>
      </Popup>
    );
  }
}

export default RenameItemModal;
import React from 'react';
import { AuthProvider } from 'Context/AuthContext.js';
import { parseTokenFromUrl } from 'Utils/auth.js';
import { useStorage } from 'Hooks';
import { STORAGE_KEYS, ORGANIZATION_HOMEPAGE } from 'Utils/const.js';

const { PACKETFABRIC_ACCESS_TOKEN, PACKETFABRIC_PORTAL_TOKEN, CCM_USER } = STORAGE_KEYS;
const storage = window.localStorage;

const cleanupLocalStorage = () => {
    storage.removeItem(CCM_USER);
    storage.removeItem(PACKETFABRIC_ACCESS_TOKEN);
    storage.removeItem(PACKETFABRIC_PORTAL_TOKEN);
};

const contextValue = {
    logout: (href = "/") => {
        cleanupLocalStorage();
        window.location.href = ORGANIZATION_HOMEPAGE.PACKETFABRIC;
    },
    login: () => {},
    isAuthenticated: () => { 
        const accessToken = storage.getItem(PACKETFABRIC_ACCESS_TOKEN);
        return !!accessToken;
    },
};

const PacketFabricAuthProvider = ({ children }) => {
    const { hash } = window.location;
    const [_, setAccessToken] = useStorage(PACKETFABRIC_ACCESS_TOKEN);
    const [__, setPortalToken] = useStorage(PACKETFABRIC_PORTAL_TOKEN);

    const tokenKey = '#token=';
    if (hash && hash.startsWith(tokenKey)) {
        cleanupLocalStorage();
        setPortalToken(hash.replace(tokenKey, ''));
        window.location.href = `/login${window.location.search}`;
    }

    const accessToken = parseTokenFromUrl();
    if (accessToken) {
        setAccessToken({ accessToken });
        window.location.href = `/${window.location.search}`;
    }
    
    return <AuthProvider value={contextValue}>{children}</AuthProvider>;
};

export default PacketFabricAuthProvider;
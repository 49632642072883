import React from 'react'
import { Button } from 'Atoms';

import './ModalButton.scss';

const ModalButton = (props) => {
  const { label, className='', ...otherProps } = props;
  return (
    <Button className={`modal-button ${className}`} secondary={!otherProps.primary} {...otherProps}>
      {label}
    </Button>
  )
}

export default ModalButton;
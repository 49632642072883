import React, { useRef } from 'react';
import { useUserContext } from 'Context/UserContext.js';
import { useHistory } from "react-router-dom";

import { Page } from 'Atoms';
import { CreateQuote } from 'Widgets';

import { toQueryStringObject } from 'Utils/strings.js';

import '../page.scss';

const CreateQuotePage = () => {
  const user = useUserContext();
  const history = useHistory();

  const qs = toQueryStringObject(window.location.search);
  const defaultValues = {
    folder: qs.folder ? { value: qs.folder } : undefined
  };

  const crumbs = useRef([
    { text: 'Home', link: '/' },
    { text: 'Request a Quote' }
  ]).current;

  return (
    <Page header="Request a Quote" className="create-quote-page" crumbs={crumbs}>
      <CreateQuote showAdvancedOptions={true} defaultValues={defaultValues} history={history} user={user} />
    </Page>
  );
}

export default CreateQuotePage;
import React, { Component } from "react";
import classNames from "classnames";

import { Icon, Radio, Toggle } from "Atoms";

class AccordionPanel extends Component {
  getPanelIcon() {
    const { success, error, icon } = this.props;

    if (success) 
      return <Icon name="checkmark-circle-filled" title="Success" role="decorative" />;
    
    if (error) 
      return <Icon name="exclamation-point-circle-filled" title="Error" role="decorative" />;
      
    if (icon) 
      return <Icon name={icon} role="decorative" />;
      
    return null;
  }

  render() {
    const {
      children, disabled, error, expanded, icon, name,
      onClick, radio, small, success, title, toggle, hidden
    } = this.props;

    const panelClassName = classNames("panel", name, { expanded, disabled, empty: !children, hidden });
    const buttonClassName = classNames("panel-header", { success, error, icon: icon || success || error });
    const showCollapsibleIcon = (!radio && !toggle);

    const PanelIcon = this.getPanelIcon();

    return (
      <div className={panelClassName}>
        <button className={buttonClassName} onClick={onClick} type="button">
          {PanelIcon}
          {radio && (
            <Radio checked={expanded} disabled={disabled} value={name} />
          )}

          {toggle && (
            <Toggle value={expanded} small={small} />
          )}

          {title}

          {showCollapsibleIcon && (
            <Icon name="caret-down" title={expanded ? "Collapse" : "Expand"} className="control" />
          )}
        </button>
        <div className="panel-content">{children}</div>
      </div>
    );
  }
}

export default AccordionPanel;

import React from 'react';
import ReactDOM from "react-dom";
import { EventType, PublicClientApplication } from "@azure/msal-browser";

import App from 'Templates/App.jsx';

const { MSAL_AUTHORITY, MSAL_CLIENT_ID, MSAL_KNOWN_AUTHORITIES } = process.env;

const knownAuthorities = MSAL_KNOWN_AUTHORITIES.split(',');

const msalConfig = {
  auth: {
      clientId: MSAL_CLIENT_ID,
      authority: MSAL_AUTHORITY,
      knownAuthorities,
      redirectUri: `${window.location.origin}/implicit/callback`,
  },
  cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  //   system: {
  //     allowRedirectInIframe: true
  // },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});


const init = () => ReactDOM.render(<App msalInstance={msalInstance} />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept(init);
}

init();
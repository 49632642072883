import React, { useState } from 'react';
import { LoadingOverlay } from 'Atoms';
import { Popup } from 'Molecules';
import { resetPassword } from 'Actions/ccm/api.js';

const ResetPasswordModal = ({ onClose, user }) => {
  const { id, name, email } = user;

  const [ isResetting, setIsResetting ] = useState(false);
  const [ tempPassword, setTempPassword ] = useState(null);
  const [ emailSent, setEmailSent ] = useState(false);

  const onConfirm = () => {
    setIsResetting(true);
    resetPassword(id).then(resp => {
      setTempPassword(resp.tempPassword);
      setEmailSent(resp.emailSent);
    }).finally(() => setIsResetting(false))
  };

  const closeModal = () => !isResetting && onClose();

  const popUpButtons = (!tempPassword ? 
    {
      button1: { label: 'Cancel', onClick: closeModal },
      button2: { label: 'Reset User Password', primary: true, onClick: onConfirm },
    } : {
      button1: { label: 'Close', onClick: closeModal },
    }
  );

  const popupProps = {
    label: 'Reset Password',
    id: 'reset-password-popup',
    onClose: closeModal,
    ...popUpButtons
  };

  return (
    <Popup {...popupProps}>
      {!tempPassword ? (
        <div>
          <div>Warning! This will reset the user password for <strong>{name}</strong> and will invalidate their previous password.</div>
          <br />
          <div>Are you sure you want to proceed?</div>
          {!!isResetting && <LoadingOverlay />}
        </div>
      ) : (
        <div>
          <div>The user password was reset for {name}.</div>
          <br />
          <div><b>Username: </b>{email}</div>
          <div><b>Password: </b>{tempPassword}</div>
          <br />
          {!!emailSent && (
            <div>Note: An email was sent to you with this information for your records.</div>
          )}
        </div>
      )}
    </Popup>
  );
}

export default ResetPasswordModal;
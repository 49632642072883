import React, { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { useUserContext } from 'Context/UserContext.js';
import { Button, LoadingOverlay, TextInput } from 'Atoms';
import { AddressLookup, PortLookup, BandwidthLookup, TermLookup } from 'Molecules';
import { getPlaceDetails } from 'Actions/ccm/api.js';
import { v4 as uuidV4 } from 'uuid';

import { useCreateQuote } from 'Hooks';

import './CreateQuote.scss';

const CreateQuote = props => {
  const { history, quoteOptions } = props;
  const { circuit_id = "", address = "" } = queryString.parse(window.location.search);
  const [addressDetails, setAddressDetails] = useState(null)

  const user = useUserContext();

  const config = useRef({
    defaultValues: { 
      serviceClass: 'best-effort',
      currency: user.preferences.default_currency_code || 'USD',
    },
    onSubmit: ({ reference, error }) => (error 
      ? alert('Unable to submit quote request. Please contact support.')
      : history.push(`/quote/${reference}/`)
    ),
    createQuoteParams: { 
      priceAsOrganizationKey: 'packetfabric' 
    },
    quoteOptions
  }).current;

  useEffect(function quoteOptionsLoaded() {
    !!quoteOptions && (config.quoteOptions = quoteOptions);
  }, [ quoteOptions ]);

  useEffect(() => {
    if (address) {
      getPlaceDetails(decodeURI(address), uuidV4())
      .then(details => {
        setAddressDetails({
          ...details, 
          label: details.display_name, 
          value: details.display_name,
          string_address: details.display_name, 
          skip_validation: true
        })
      });
    }
  }, []);

  const defaultValues = {
    ...config.defaultValues,
    term: [{ label: '36 Months', value: '36' }],
    serviceRequirements: 'ethernet',
    circuitId: circuit_id,
  };
  const createQuoteParams = {
    ...config.createQuoteParams,
    serviceRequirements: 'ethernet',
  };
  const { lookupProps={}, submit, isLoading } = useCreateQuote({ ...config, defaultValues, createQuoteParams });
  const aLocationAddressProps = {
    label: "Enter any address", 
    icon: "location",
  };
  const zLocationAddressProps = {
    label: "To a PacketFabric location",
    icon: "location",
  };

  if (addressDetails) {
    zLocationAddressProps.value = addressDetails;
    zLocationAddressProps.isDisabled = true;
  }

  return (
    <div className="create-quote">
      {!!isLoading && <LoadingOverlay />}
      {address ? (
        <>
          <div hidden={true}>
            <AddressLookup {...lookupProps.locationZ()} {...zLocationAddressProps} />
          </div>
          <div className="grid col--2">
            <AddressLookup {...lookupProps.locationA()} {...aLocationAddressProps} />
            <TextInput label="Circuit ID" {...lookupProps.circuitId()} disabled={!!circuit_id} />
          </div>
        </>
      ) : (
        <div className="grid col--3">
          <AddressLookup {...lookupProps.locationA()} {...aLocationAddressProps} />
          <AddressLookup {...lookupProps.locationZ()} {...zLocationAddressProps} />
          <TextInput label="Circuit ID" {...lookupProps.circuitId()} disabled={!!circuit_id} />
        </div>
      )}
      
      <div className="grid col--3">
        <BandwidthLookup {...lookupProps.bandwidth()} />
        <PortLookup {...lookupProps.port()} label="On-Prem Port Speed" />
        <TermLookup {...lookupProps.term()} />
      </div>
      <div className="create-quote__button-wrapper">
        <Button className="button--rounded" onClick={submit} disabled={isLoading} primary children="Get a quote for last mile access" />
      </div>
    </div>
  )
}

export default withRouter(CreateQuote);


import React, { useEffect, useState } from 'react';
import { Button, LoadingOverlay, SortableTable } from 'Atoms';
import { Popup } from 'Molecules';
import { getRecentFolderStats, requeueFolderPricingRequests } from 'Actions/ccm/api.js';

const RecentFolderStats = () => {
  const [ rowData=[], setRowData ] = useState();

  useEffect(() => {
    getRecentFolderStats().then(({ results }) => setRowData(results));
  }, []);

  const onConfirmRequeue = (folderId, name) =>{
    const confirm = window.confirm(
      `Are you sure you want to requeue items for ${name} (${folderId})`
    )

    if(confirm === true) {
      requeueFolderPricingRequests(folderId).then((result) => window.alert(result.message));
    }
  };

  const COLUMN_DATA = [
    { keys: ['owner_name', 'owner_organization_name'], 
      headers: { 'owner_name': 'Owner', 'owner_organization_name': 'Organization' }
    },
    { key: 'name', header: 'Folder Name' },
    { key: 'id' },
    // { key: 'num_pending', header: 'Pending' },
    { key: 'num_pricing', 
    header: 'Pricing',
    render: ({id, name, num_pricing}) => num_pricing > 0 ?
      <Button type="link" onClick={() => onConfirmRequeue(id, name)} children={num_pricing} /> : `0`
  },
    { key: 'num_manual', header: 'Manual' },
    { key: 'num_error', header: 'Error' },
    { key: 'num_quoted', header: 'Quoted' },
    { key: 'total' },
    { key: 'percent_completed', 
      header: 'Percent Done', 
      render: ({ percent_completed }) => `${Math.round(percent_completed * 100)/100}%` 
    },
  ];

  return (
    <div className="recent-folder-stats">
      <SortableTable className="recent-folder-stats__table" columnData={COLUMN_DATA} rowData={rowData} />
    </div>
  )
}

export default RecentFolderStats;
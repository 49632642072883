import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useInterval } from 'Hooks';
import { APP_PRODUCT_NAME } from 'Utils/branding.js';

import './EcxLoginPage.scss';

const EcxLoginPage = () => {
  const LOGIN_URL = 'https://fabric.equinix.com/unitas';
  const TIMER_DURATION = 10;

  const [ counter, setCounter ] = useState(TIMER_DURATION);
  const { redirectUri } = queryString.parse(window.location.search);

  const href = (window.location.host === 'equinixfabric.unitascloud.com') 
    ? LOGIN_URL 
    : window.location.origin.replace('equinixfabric.', '').replace('equinixfabric-', '') + '/login-poc';

  useInterval(() => setCounter(counter - 1), (counter > 0 ? 1000 : null));

  // onMount: Save redirectUri querystring parameter to localStorage
  useEffect(() => {
    redirectUri && localStorage.setItem('secureRouterReferrerPath', JSON.stringify(redirectUri));
  }, [redirectUri]);

  // Redirect user automatically after 5 seconds...
  useEffect(() => {
    if (counter === TIMER_DURATION - 5) {
      window.location.href = href;
    }
  }, [counter])

  return (
    <div className="ecx-login-page">
      <div className="ecx-redirect-text-container">
        <div className="ecx-redirect-text--reauthenticate">In order to access your {APP_PRODUCT_NAME} information, we need to redirect you to Equinix to login and re-authenticate your identity.</div>
        {!!counter && (
          <div className="ecx-redirect-text--wait">Please wait while we redirect you…</div>
        )}
        <div className="ecx-redirect-text--redirect">
          {(counter 
            ? `If you haven’t been redirected in ${TIMER_DURATION} seconds, please click below.`
            : "Please click below to be redirected."
          )}
        </div>
        <div>
          <a href={href} className="ecx-redirect-link">Continue</a>
        </div>
      </div>
    </div>
  )
}

export default EcxLoginPage;

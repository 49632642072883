import React, { memo, useEffect, useRef } from 'react';
import Lookup from './Lookup.jsx'; 

import 'Sass/twemoji-flags.scss';
import './Currency.scss';


const CurrencyDropdown = memo((props) => {
  const { defaultValue, ...otherProps } = props;

  const currencyRef = useRef(null);
  const currencyOptions = useRef([
    { value: 'USD', flag: 'us' },
    { value: 'EUR', flag: 'eu' },
    { value: 'GBP', flag: 'gb' },
    { value: 'AUD', flag: 'au' },
    { value: 'BRL', flag: 'br' },
    { value: 'CAD', flag: 'ca' },
    { value: 'HKD', flag: 'hk' },
    { value: 'INR', flag: 'in' },
    { value: 'JPY', flag: 'jp' },
    { value: 'SGD', flag: 'sg' },
    { value: 'ZAR', flag: 'za' },
  ]);

  const formatOptionLabel = ({ value, flag }) => (
    <div className={`em-flag em-flag-${flag.toLowerCase()}`}>{value}</div>
  );

  useEffect(() => {
    defaultValue && currencyRef.current && currencyRef.current.select.setValue(currencyOptions.current.find(x => x.value === defaultValue));
  }, [defaultValue]);

  return (
    <Lookup name="currency" isSearchable={false} formatOptionLabel={formatOptionLabel} options={currencyOptions.current} ref={currencyRef} {...otherProps} />
  );
});

export default CurrencyDropdown;
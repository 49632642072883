import React, { Component, createRef } from 'react';
import CN from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { parse, stringifyUrl } from 'query-string';

import { AsyncQuoteTooltip, ContextMenuButton, Icon, LoadingOverlay, Notification, SortableTable, StatusIcon, TimeAgo } from 'Atoms';
import { ModalManager, MODALS } from 'Molecules';
import { getQuoteStatus } from 'Utils/formatter.js';

import './QuoteManager.scss';

const columnData = [
  {
    key: 'type',
    header: ' ',
    render: ({ icon, quoteCount }) => icon && (
      <><Icon name={icon} />{ !!quoteCount && <i className="quote-manager__folder-badge">{quoteCount}</i> }</>
    )
  }, {
    key: 'name',
    render: ({ type, id, to, name, onClickLink, reference }) => (
      (type === 'quote') ? (
        <>
          <AsyncQuoteTooltip id={id}>
            <Link to={to} onClick={onClickLink}>{name}</Link>
          </AsyncQuoteTooltip>
          <div style={{ fontSize: '.75rem', marginTop: '.25rem' }}>Quote Reference: {reference}</div>
        </>
      ) : (type === 'folder') 
        ? <Link to={to} onClick={onClickLink}>{name}</Link> 
        : <span className="text-link" onClick={onClickLink}>{name}</span>
    )
  }, {
    key: 'bandwidth',
  }, { 
    keys: ['owner', 'organization'],
    headers: { owner: 'User', organization: 'Organization' },
    sortable: false,
  }, {
    key: 'date',
    render: ({ date }) => <TimeAgo date={date} />
  }, {
    key: 'status',
    render: ({ status }) => {
      if (!status) return null;
      const { text='' } = getQuoteStatus(status) || {};
      const [ label, substatus ] = text.split(': ');
      return (
        <>
          <StatusIcon status={status} text={label} />
          {!!substatus && (
            <div className="status-icon__substatus">{substatus}</div>
          )}
        </>
      );
    }
  }, { 
    key: 'actions',
    header: ' ',
    sortable: false,
    render: ({ type, ...data }) => <ContextMenuButton type={type} {...data} icon="ellipsis" />
  }
];

class QuoteManager extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      isRedirecting: false
    }

    this.handleImportError = this.handleImportError.bind(this);
    this.toItemRow = this.toItemRow.bind(this);

    this.modalRef = createRef();
  }

  handleImportError(data) {
    const { onHandleImportError, userFilter } = this.props;
    const { open, onClose } = (this.modalRef.current || {});
    const onConfirm = () => {
      onHandleImportError && onHandleImportError();
      onClose();
    }

    return !userFilter && open(MODALS.IMPORT_ERROR, { onConfirm, ...data });
  }

  toItemRow(item) {
    const { pathname, search } = this.props.location;
    const { type, name, id, status, date, reference, bandwidth, assigned, quoteCount, owner, organization, organizationKey } = item;

    const { p, ...query } = parse(search);
    const isQmPage = pathname.startsWith('/quote-manager/');
    const url = `/quote-manager/${encodeURIComponent(name)}/`;

    // If on Quote Manager page, preserve filter params (but remove page number)
    const toFolder = isQmPage ? stringifyUrl({ url, query }, { skipNull: true }) : url;

    const props = (
      (type === 'folder') ? {
        icon: 'folder',
        to: toFolder 
      } : 
      (type === 'quote') ? { 
        icon: 'quote',
        to: `/quote/${reference}/`
      } : 
      (type === 'import-error') ? {
        onClickLink: () => this.handleImportError(item),
        icon: 'quote',
        className: 'sortable-table-row--import-error'
      } : {}
    );
    return { name, id, status, type, reference, bandwidth, menuId: `${type}_menu`, date, assigned, quoteCount, owner, organization, organizationKey, ...props };
  }

  render() {
    const { isRedirecting } = this.state;
    const { items=[], folderName = '.', folderId=0, loadingMessage, noQuotes, onSort } = this.props;

    const isLoading = !!loadingMessage || isRedirecting;
    const showTable = isLoading || (!!items && !!items.length);
    const rowData = items.map(this.toItemRow);
    const className = CN('quote-manager', { '--isLoading': isLoading });
    const showNoQuotes = (!loadingMessage && !items.length);

    return (
      <div className={className}>
        {(!!showTable) &&
          <SortableTable className="quote-manager__table" columnData={columnData} rowData={rowData} onSort={onSort} />
        }
        {(!!isLoading) &&
          <LoadingOverlay>{loadingMessage}</LoadingOverlay>
        }
        {(showNoQuotes && (
          noQuotes || <DefaultNoQuotes folderId={folderId} folderName={folderName} />
        ))}

        <ModalManager ref={this.modalRef} />
      </div>
    )
  }
}

const DefaultNoQuotes = ({folderId, folderName}) => {
  const toQuote = '/request-quote/' + (!folderId ? '' : `?folder=${folderId}`);
  const toImport = '/quote-manager/' + (!folderId ? '' : `${encodeURIComponent(folderName)}/`) + '#import';

  return (
    <Notification.Info>
      You don't have any quotes yet. <Link to={toQuote}>Request a new quote</Link> or <Link to={toImport}>import quotes.</Link>
    </Notification.Info>
  );
}

export default withRouter(QuoteManager);

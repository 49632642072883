import React, { Component } from 'react';
import CN from 'classnames';
import debounce from 'lodash/debounce';

import { Button, Icon } from 'Atoms';
import Modal from '../Modal.jsx';
import ModalButton from '../ModalButton.jsx';

import './Popup.scss';

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = { scrolled: false };

    this.content = React.createRef();
    this.debouncedHasScrolled = debounce(this.hasScrolled, 50);
    this.debouncedHasScrolled = this.debouncedHasScrolled.bind(this);
  }

  hasScrolled() {
    this.setState({ scrolled: this.content.current.scrollTop > 0})
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.scrolled !== nextState.scrolled ||
      this.props !== nextProps
    );
  }

  componentDidMount() {
    this.content.current.addEventListener("scroll", this.debouncedHasScrolled);
  }

  componentWillUnmount() {
    this.content.current.removeEventListener("scroll", this.debouncedHasScrolled);
  }

  render() {
    const {
      label,
      children,
      className='',
      onClose,
      // the button object consists of {
      //   label :: string,
      //   onClick :: fn,
      //   className :: string | object
      //   primary :: Boolean
      //   secondary :: Boolean
      // }
      button1,
      button2,
      button3,
      minimizable,
      id,
    } = this.props;

    return (
      <Modal close={onClose} id={id}>
        <div className={`popup ${className}`}>
          <div className={
            CN('popup-header', { scrolled: this.state.scrolled })
          }>
            <span className="popup-label">{label}</span>
            {minimizable ?
              <Button
                type="link"
                title="Minimize"
                className="close-popup minimize"
                onClick={event => onClose(event)}
              >
                <Icon name="minimize-window" title="Minimize" />
              </Button> :
              <Button
                type="link"
                title="Close Modal"
                className="close-popup"
                onClick={event => onClose(event)}
              >
                <Icon name="close-modal" title="Close" />
              </Button>
            }
          </div>

          <div className="popup-content" ref={this.content}>
            {children}
          </div>

          <div className="popup-footer">
            <ModalButton {...button1} />
            {button2 && <ModalButton {...button2} /> }
            {button3 && <ModalButton {...button3} /> }
          </div>

        </div>
      </Modal>
    );
  }
}

export default Popup;
import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ImplicitCallback } from "@okta/okta-react";
import { SecureRoute } from 'Auth/SecureRoute.jsx';
import { GenericNotFoundPage, LoadablePages, UnsupportedBrowser } from 'Pages';
import EcxLoginPage from 'Pages/ECX/EcxLoginPage.jsx';
import PacketFabricLoginPage from 'Pages/PacketFabric/PacketFabricLoginPage.jsx';
import Logout from 'Auth/Logout.jsx';
import AuthUserContainer from "Auth/AuthUserContainer.jsx";
import ServiceMetricsPage from 'Pages/ServiceMetrics/ServiceMetricsPage.jsx';
import { useAnalytics, useBrowserDetection } from 'Hooks';
import { isEcxContext, isPFContext } from 'Utils/auth.js';

const { Login, StyleGuide, Documentation, UsageAndPrivacy, TermsAndConditions } = LoadablePages;

const AppRouter = () => {
  const history = useHistory();
  const { initAnalytics } = useAnalytics();
  const { isSupported } = useBrowserDetection();

  let LoginComponent =  Login;
  if (isEcxContext()) LoginComponent = EcxLoginPage;
  else if (isPFContext()) LoginComponent = PacketFabricLoginPage;

  useEffect(() => {
    !isSupported && history.push('/unsupported-browser');
    initAnalytics();
  }, [isSupported, history, initAnalytics]);

  return (
    <Switch>
      <Route path="/sla" component={ServiceMetricsPage} />
      <Route path="/unsupported-browser" component={UnsupportedBrowser} />
      <Route exact path="/login" component={LoginComponent} />
      <Route path="/implicit/callback" component={ImplicitCallback} />
      <Route exact path="/styleguide" component={StyleGuide} />
      <Route path="/docs" component={Documentation} />
      <Route path="/usage-and-privacy" component={UsageAndPrivacy} />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />
      <Route exact path="/404" component={GenericNotFoundPage} />
      <Route path="/logout" component={Logout} />
      <SecureRoute path="/" component={AuthUserContainer} />
      <Route component={GenericNotFoundPage} />
    </Switch>
  );
}

export default AppRouter;
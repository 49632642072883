import React, { Component } from 'react';

import './button_group.scss';

/**
 * The ButtonGroup component locks together the Buttons within it into an
 * unbroken row. This is different than a RadioButtonGroup, which styles a radio
 * (one-hot) input as buttons; they have very similar appearances, but the
 * ButtonGroup doesn't have "selected" options.
 *
 * Conjoining Buttons of greatly different types ("tile" or "link", for example)
 * might result in some ...weirdness--it's best that they're all the same type.
 * Secondary or disabled buttons should be just great, though.
 */
export default class ButtonGroup extends Component {
  render() {
    return (
      <div className="button-group">
        {this.props.children}
      </div>
    );
  }
}

import React, { useEffect, useRef, useState } from 'react';
import { ContextMenu, ContextMenuButton, Icon, IconLabel, LoadingOverlay, Notification, SortableTable, TimeAgo } from 'Atoms';
import RelatedDesignsFilter from './RelatedDesignsFilter.jsx';
import { copyDesign, getRelatedDesigns } from 'Actions/ccm/api.js';
import { toCurrency } from 'Utils/formatter.js';
import { useUserContext } from 'Context/UserContext';
import { PERMISSIONS } from 'Utils/const.js';

import './RelatedDesigns.scss';

const columnData = [{
    key: 'from', 
    render: ({ locationA, distanceA }) => <RelatedDesignLocation location={locationA} distance={distanceA} />,
    sortable: false,
  }, {
    key: 'to', 
    render: ({ locationZ, distanceZ }) => <RelatedDesignLocation location={locationZ} distance={distanceZ} />,
    sortable: false,
  }, {
    key: 'requirements',
    render: ({ bandwidth, term, advancedOptions }) => (<>
        <div><Icon name="bandwidth" />{bandwidth}</div>
        <div><Icon name="calendar" />{term} Months</div>
        <div className="related-designs-design__advanced-requirements">{advancedOptions}</div>
      </>),
    sortable: false,
  }, {
    key: 'vendor-product', 
    render: props => (<>
      <div className="sortable_table_column_data--primary">{props.vendor}</div>
      <div className="sortable_table_column_data--secondary">{props.product}</div>
    </>),
    sortable: false,
  }, {
    key: 'status-date', 
    render: props => (<>
      <div className="sortable_table_column_data--primary">{props.status}</div>
      <TimeAgo date={props.date} className="sortable_table_column_data--secondary" />
    </>),
    sortable: false,
  }, {
    key: 'mrc-nrc',
    sortable: false,
    render: props => (<>
      <div className="sortable_table_column_data--primary">{props.mrc}</div>
      <div className="sortable_table_column_data--secondary">+ {props.nrc}</div>
    </>),
  }, {
    key: 'actions',
    header: ' ',
    sortable: false,
    render: ({ type, ...data }) => <ContextMenuButton type={type} menuId="related-designs_menu" {...data} /> 
  }
];

const toRowData = (props) => {
  // nearest tenth of a mile
  const metersToMiles = m => m === null ? null : Math.round(10 * m / 1609.344)/10;
  const {
    design_bandwidth_mbps,
    design_create_date,
    design_creation_source,
    design_edited,
    design_id,
    design_location_a, 
    design_location_z,
    design_mrc_usd,
    design_nrc_usd,
    design_primary_vendor_name,
    design_connectivity_providers=[],
    design_reference,
    design_term_months,
    design_type_name,
    distance_a,
    distance_z,
    quote_advanced_options,
    quote_id,
    quote_reference,
    quote_status,
  } = props;

  const toLocation = loc => {
    if (!loc) return null;
    const { address={}, data_center_name, display_name } = (loc || {});
    const { street_address, locality, secondary_designation } = address;

    return {
      displayName: display_name,
      popName: data_center_name,
      line1: street_address,
      line2: locality,
      floorSuite: secondary_designation,
    };
  };

  const bandwidth = design_bandwidth_mbps < 1000
    ? `${design_bandwidth_mbps} Mbps`
    : `${design_bandwidth_mbps/1000} Gbps`;

  const status = (
    design_edited ? 'Edited' : 
    design_creation_source === 'CCM Web' ? 'Manually Priced' :
    design_creation_source === 'CCM Rate Engine' ? 'Auto Priced' : ''
  );

  return {
    designId: design_id,
    locationA: toLocation(design_location_a),
    distanceA: metersToMiles(distance_a),
    locationZ: toLocation(design_location_z),
    distanceZ: metersToMiles(distance_z),
    vendor: design_connectivity_providers.join(', '),
    product: design_type_name,
    date: design_create_date,
    status,
    term: design_term_months,
    bandwidth,
    advancedOptions: quote_advanced_options.join(', '),
    mrc: toCurrency(design_mrc_usd),
    nrc: toCurrency(design_nrc_usd),
    quoteRef: quote_reference,
  }
}

const RelatedDesigns = (props) => {
  const { id, serviceType, focusDesign } = props;

  const colData = useRef(columnData).current;
  const user = useUserContext();

  const [ isFetching, setIsFetching ] = useState(true);
  const [ rowData, setRowData ] = useState([]);
  const [ locationRadius, setLocationRadius ] = useState(160935); // 100 miles in meters
  const [ terms, setTerms ] = useState([12, 24, 36, 60]);
  const [ minBandwidth, setMinBandwidth ] = useState(10);
  const [ maxBandwidth, setMaxBandwidth ] = useState(100000);
  const [ maxAge, setMaxAge ] = useState(365);
  const [ service, setService ] = useState(serviceType);

  useEffect(() => {
    if (!service) return;
    const req = {
      "quote_id": id,
      "location_a_radius": locationRadius === -1 ? undefined : locationRadius,
      "location_z_radius": locationRadius === -1 ? undefined : locationRadius,
      "ordering": ["exact", "distance"],
      "bandwidth_mbps_min": minBandwidth,
      "bandwidth_mbps_max": maxBandwidth,
      "max_age_days": maxAge,
      "service_type": service,
      //"terms": terms,
    };
    
    setIsFetching(true);
    getRelatedDesigns(req).then(({ results }) => {
      const data = results.map(toRowData);
      setRowData(data);
      setIsFetching(false);
    });
  }, [id, minBandwidth, maxBandwidth, maxAge, locationRadius, service]);

  useEffect(() => {
    serviceType && setService(serviceType);
  }, [serviceType]);

  const copyDesignHidden = !(user?.permissions?.some((p) => p === PERMISSIONS.COPY_DESIGN) ?? false);
  const menuItems = [
    { 
      label: 'View Quote', 
      icon: 'quote', 
      onClick: ({ props }) => window.open(`/quote/${props.quoteRef}/`, '_blank'),
    }, {
      label: 'Copy Design', 
      icon: 'copy', 
      onClick: async ({ props: { designId } }) => {
        setIsFetching(true);
        const resp = await copyDesign(designId, id);
        setIsFetching(false);
        resp.design_ids
          ? focusDesign(resp.design_ids[0])
          : alert('Error copying design. Please contact support');
      },
      disabled: ({ props }) => props.distanceA || props.distanceZ,
      hidden: copyDesignHidden
    }];

  return (
    <div className="related-designs">
      <div className="related-designs__header">
        {!isFetching 
          ? <>Showing&nbsp;<b>{rowData.length}</b>&nbsp;similar designs</>
          : 'Fetching similar designs...' 
        }
      </div>
      {!!isFetching && <LoadingOverlay />}
      
      <RelatedDesignsFilter
        locationRadius={locationRadius}
        setLocationRadius={setLocationRadius}
        minBandwidth={minBandwidth}
        maxBandwidth={maxBandwidth}
        setMinBandwidth={setMinBandwidth}
        setMaxBandwidth={setMaxBandwidth}
        maxAge={maxAge}
        setMaxAge={setMaxAge}
        service={service}
        setService={setService}
      />
      {!rowData.length && !isFetching 
        ? <Notification.Info children="No matching designs found." />
        : <SortableTable className="related-designs__table" columnData={colData} rowData={rowData} defaultSort={''} />
      }
      <ContextMenu id="related-designs_menu" items={menuItems} />
    </div>
  )
}

const RelatedDesignLocation = ({ location, distance }) => {
  const isInternet = (location === null);
  const { popName, line1, line2, floorSuite } = (location || {});

  return (
    <div className="related-design-location">
      <Icon name={isInternet ? 'internet' : 'location'} />
      <div>
        { isInternet 
          ? <div className="related-design-location__line1">Internet</div> 
          : <>
              { popName && <div className="related-design-location__pop-name">{popName}</div> }
              { line1 && <div className="related-design-location__line1">{line1}</div> }
              { line2 && <div className="related-design-location__line2">{line2}</div> }
              {/* { floorSuite && <div className="related-design-location__floor-suite">{floorSuite}</div> } */}
            </>
        }
        {distance !== null && (
          <div className="related-design-location__match">
            {(distance === 0)
              ? <IconLabel name="checkmark-circle-filled" label="Exact location match" />
              : <IconLabel name="scale" label={`${distance} miles away`} />
            }
          </div>
        )}
      </div>
    </div>
  )
};

export default RelatedDesigns;

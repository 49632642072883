import React, { Component } from "react";
import classNames from "classnames";
import Select from "antd/es/select";
import { ErrorIcon } from "Atoms";
import Checkbox from "../Checkboxes/Checkbox.jsx";

import "antd/lib/select/style/index.css";
import "./dropdown.scss";

const { Option } = Select;

/**
 * @param {String | Object} className - additional classes
 * @param {Boolean} disabled - disables the _entire_ dropdown, not just
 * individual options
 * @param {Function} getPopupContainer - overrides the default
 * getPopupContainer function
 * @param {Boolean} inline - render this Dropdown as an inline element, with
 * its width determined by the widest of its options
 * @param {Object} input - an abstraction of a form element's attributes. See
 * redux-form's docs: https://redux-form.com/6.7.0/docs/api/field.md/#props
 * @param {String} input.value - the current value of the Dropdown. If
 * input.value is not equivalent to a key in options, it won't be displayed or
 * available to chose. Instead, an empty space will be shown.
 * @param {Object} meta - meta values about the imput elemet, passed in by a
 * wrapper, such as redux-form.
 * @param {String} mode - The type of Dropdown. Used for "multiple" selections.
 * Passed directly through to the ant.d Select
 * @param {Function} onChange - fired on a new option being selected
 * @param {Array[Object]} options - a list of the available options. options:
 *   * {String} key - unique key of the option
 *   * {String} value - the displayed name of the option
 *   * {Boolean} disabled - can this option be selected?
 * @param {String} placeholder - the first option, "Choose One", e.g.
 * @param {Boolean} small - a hint that this should be displayed small
 */
export default class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.updateMultipleSelections = this.updateMultipleSelections.bind(this);
  }

  updateMultipleSelections(newValue) {
    if (!this.props.onChange) {
      return;
    }
    
    const values = this.props.input.value;
    const newIndex = values.indexOf(newValue);
    const update = newIndex >= 0 ?
      [...values.slice(0, newIndex), ...values.slice(newIndex + 1)] :
      [...values, newValue];
    
    this.props.onChange(update);
  };

  render() {
    const {
      className,
      disabled,
      getPopupContainer = () => document.querySelector(".ant-tabs-tabpane-active") || document.body,
      inline,
      input = {},
      meta = {},
      multiple,
      onChange,
      options,
      placeholder,
      small,
      onFocus = () => {}
    } = this.props;

    // We need to guard against passing empty keys and values to Ant.d Menus
    const filteredOptions = options.filter(x => !!x.key && !!x.value);

    // Check that input.value \in options and we have something to display
    if (!input.value && !placeholder) {
      console.warn(`Dropdown has no default display values.
        input: ${JSON.stringify(input)}
        placeholder: ${JSON.stringify(placeholder)}
      `);
    }

    const dropdownStyle = {
      fontSize: small ? "0.5625rem" : null
    };

    // If the value string does not match any options' value props, Ant Design
    // will display that same string. We abuse this behavior to display the
    // formatted values of a multiselect box
    const formattedValues = values => {
      if (!values) {
        return values;
      }

      const keys = values.map(
        v => filteredOptions.find(({ key }) => key === v).value
      );
      return keys.join(", ");
    };

    const value = (multiple ? formattedValues(input.value) : input.value) || undefined;

    const isChecked = (key) => (multiple && value && Array.isArray(value) && value.includes(key))

    const filterOption = (inputValue = "", option) => {
      const { label = "" } = option.props;
      return label.toLowerCase().includes(inputValue.toLowerCase());
    }

    return (
      <div
        data-test={input.name}
        className={classNames("dropdown", { inline, small }, className)}
      >
        <Select
          onFocus={onFocus}
          placeholder={placeholder}
          filterOption={filterOption}
          value={value}
          onChange={multiple ? this.updateMultipleSelections : onChange}
          disabled={disabled}
          getPopupContainer={getPopupContainer}
          dropdownStyle={dropdownStyle}
          showSearch
        >
          {filteredOptions.map(({key, disabled, value}) => (
            <Option key={key} value={key} disabled={disabled} label={value}>
              {multiple && (
                <Checkbox checked={isChecked(key)} readOnly/>
              )}
              {value}
            </Option>
          ))}
        </Select>

        {meta.touched && meta.error && (
        <span className="error">
          <ErrorIcon /> {meta.error}
        </span>
        )}
      </div>
    );
  }
}

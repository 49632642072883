import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { useUserContext } from 'Context/UserContext.js';

import { Breadcrumbs, Steps, Support } from 'Atoms';
import { QuoteOrder } from 'Widgets';
import { getQuotes, getDesign, getQuoteOrderDrafts, getOrganization, getOrders, getUsers } from 'Actions/ccm/api.js';
import { toGetDesignResponse, toResumeOrder, getFormOptions } from 'Widgets/QuoteOrder/QuoteOrderMapper.js';
import { putFirst, sortByLabel } from 'Utils/array.js';
import { DefFavicon, DefFaviconName, PAGE_TITLE_NAME } from 'Utils/branding.js';
import { apiUrlIdStripper } from 'Utils/ccm.js';
import { PERMISSIONS } from 'Utils/const.js';

import '../page.scss';
import './QuoteOrder.scss';
import { SUPPORT_EMAILS, UNITAS_GLOBAL_CONNECTIVITY_PRICING_EMAIL } from 'Utils/const.js';

const CUSTOMER_ORDERED_STATUS = ['ordered', 'design-validation'];

const QuoteOrderPage = () => {
  const [ quote, setQuote ] = useState({});
  const [ design, setDesign ] = useState({});
  const [ isFetching, setIsFetching ] = useState(false);
  const [ docusignApprovers, setDocusignApprovers ] = useState();

  const user = useUserContext();
  const history = useHistory();
  const { quoteRef, designId } = useParams();

  const crumbs = [
    { text: 'Home', link: '/' },
    { text: `Quote Reference: ${quoteRef}`, link: `/quote/${quoteRef}` },
    { text: 'Order' }
  ];

  const redirect = useRef({
    toQuoteManager: () => history.push('/quote-manager'),
    toQuoteView: () => history.push(`/quote/${quoteRef}`)
  });

  const fetchQuote = useCallback(() => {
    quoteRef && getQuotes({ reference: quoteRef }).then(({ results=[] }) => {
      if (!results.length) {
        alert('Error! Unable to find quote!');
        return redirect.current.toQuoteManager();
      }
      const ipv4 = results[0]?.specification?.option_selections?.ipv4_addresses;

      const quoteData = {
        name: results[0].name,
        reference: results[0].reference,
        id: results[0].id,
        ipv4,
        status: results[0].status,
        statusDetails: results[0].status_details || {},
        priceAsOrganizationKey: results[0].price_as_organization_key,
        orgName: results[0].organization_name,
        orgId: apiUrlIdStripper(results[0].organization_url),
      };

      setQuote(quoteData);
      return quoteData;
    });
  }, [ quoteRef ]);

  const fetchApprovers = useCallback(() => {
    if (!quote?.orgId) return;

    const isPrimaryApprover = ({ permissions }) => !!permissions.find(p => p.split(':organization')[0] === PERMISSIONS.DOCUSIGN_PRIMARY_APPROVER);
    const mapper = x => ({ label: x.name, value: x.email, isDefault: isPrimaryApprover(x) });

    return getUsers({ 
      is_active: true, 
      include_permissions: true, 
      permissions__in: `${PERMISSIONS.DOCUSIGN_APPROVER}:organization=${quote.orgId}`,
    }).then(({ results=[] }) => {
      const mapped = results.map(mapper).sort(sortByLabel);
      const sorted = putFirst(mapped, x => !!x.isDefault);

      setDocusignApprovers(sorted);
      return sorted;
    })
  }, [ quote ]);

  const fetchDesign = useCallback(async () => {
    const resp = await getDesign(designId, { include_has_order_draft: true });
    const designResponse = toGetDesignResponse(resp);
    const { error, hasOrderDraft, hasOrderReview, quoteId, designStatus } = designResponse;
  
    if (error) return redirect.current.toQuoteView();

    setDesign({ ...designResponse });

    const toSavedDraft = ({ results }) => results.length 
      ? toResumeOrder(results[0]) 
      : undefined;

    const fetchOrderDraft = (hasOrderDraft && !hasOrderReview)
      && await getQuoteOrderDrafts(quoteId, { design_id: designId }).then(toSavedDraft);
    const fetchSavedOrder = (hasOrderReview || CUSTOMER_ORDERED_STATUS.includes(designStatus))
      && await getOrders(designId).then(toSavedDraft);
    
    const savedDraft = (fetchOrderDraft || fetchSavedOrder);
    savedDraft && setDesign({ ...designResponse, savedDraft });

    return {};
  }, [ designId ]);

  const fetchData = useCallback(() => {
    setIsFetching(true);
    
    fetchQuote();
    fetchDesign();
  }, [ fetchQuote, fetchDesign ]);

  const verifyPermissions = () => {
    const { organization_id, is_order_manager } = user;

    getOrganization(organization_id).then(org => {
      const { requires_order_manager } = org;
      if (requires_order_manager && !is_order_manager) {
        return redirect.current.toQuoteView();
      }
    });
  };


  // onMount
  useEffect(fetchData, [ fetchData ]);

  useEffect(verifyPermissions, [ user, redirect ]);

  useEffect(() => {
    quote && fetchApprovers();
  }, [ quote, fetchApprovers ]);

  useEffect(function doneFetching() {
    (isFetching && quote && design && docusignApprovers) && setIsFetching(false);
  }, [ isFetching, quote, design, docusignApprovers ]);

  const formOptions = getFormOptions(user, quote, design);
  const isDocusignEnabled = !!docusignApprovers?.length;
  let emailTo = UNITAS_GLOBAL_CONNECTIVITY_PRICING_EMAIL;
  if (quote.priceAsOrganizationKey === 'equinix-ecx') emailTo = SUPPORT_EMAILS.EQUINIX_FABRIC;
  else if (quote.priceAsOrganizationKey === 'packetfabric') emailTo = SUPPORT_EMAILS.PACKETFABRIC;
  const steps = isDocusignEnabled 
    ? [ 'Provide Details', 'Review', 'Pending Approval', 'Ordered' ]
    : [ 'Provide Details', 'Review', 'Ordered' ];
  
  const current = 
    !design.designStatus ? -1 :
    design.designStatus === 'ordering-pending-approval' ? 2 :
    design.designStatus === 'ordering-review' ? 1 :
    CUSTOMER_ORDERED_STATUS.includes(design.designStatus) ? (isDocusignEnabled ? 4 : 3) : 0;

    return (
    <section className="create-quote-page">
      <Helmet>
        <title>{PAGE_TITLE_NAME}: Order Quote</title>
        <link rel={DefFaviconName} type="image/png" href={DefFavicon} />
      </Helmet>

      <Breadcrumbs crumbs={crumbs} />
      <div className="page-content">
        <Steps current={current} steps={steps} />
        <QuoteOrder {...design} formOptions={formOptions} quote={quote} fetchData={fetchData} isFetching={isFetching} docusignApprovers={docusignApprovers} />
      </div>
      <Support pricingEmail={emailTo} technicalEmail={emailTo} />
    </section>
  );
}

export default QuoteOrderPage;

import React from 'react'
import { Popup } from 'Molecules';

import './DeleteModal.scss';

const ConfirmationModal = props => {
  const { label='Confirm', id='confirmation-modal', cancelText='Cancel', confirmText='Confirm', children, onConfirm, onClose } = props;

  const noButton = { label: cancelText, onClick: onClose };
  const yesButton = { label: confirmText, onClick: onConfirm, primary: true };

  return <Popup label={label} onClose={onClose} button1={noButton} button2={yesButton} id={id} children={children} />
}

export default ConfirmationModal
import React from "react";
import classNames from "classnames";

import { Icon } from "Atoms";

import "./feedback.scss";

/**
 * Create an inset block with a colored background, used to highlight important
 * notices.
 * @param {Boolean} small - a cue to render the Feedback with tighter padding
 * @param {Boolean} strong - render the Feedback block in stronger colors, white
 * on a deep red, blue, or green. This can look obnoxious or glaring at larger
 * sizes.
 * @param  {String} type - the type (and thus color and icon) of this Feedback.
 * There are three possible types:
 * * error: red with an exclamation point
 * * info (default): blue with an "i"
 * * success: green with a checkmark
 */
const Feedback = props => {
  const { children, small, strong, type = "info" } = props;

  const outlineIconNames = {
    error: "exclamation-point-circle-outline",
    info: "info-circle-outline",
    success: "checkmark-circle-outline"
  };
  const filledIconNames = {
    error: "exclamation-point-circle-filled",
    info: "info-circle-filled",
    success: "checkmark-circle-filled"
  };
  const icon = strong ? filledIconNames[type] : outlineIconNames[type];

  return (
    <div className={classNames("feedback", type, { small, strong })}>
      <div className="feedback-icon">
        <Icon name={icon} role="decorative" giant />
      </div>
      {children}
    </div>
  );
};

export default Feedback;

import React, { Component, createRef } from 'react'
import Lookup from './Lookup.jsx';
import { getProviders } from 'Actions/ccm/api.js';
import { sortByLabel } from 'Utils/array.js';

class CarrierDropdown extends Component {
  constructor() {
    super();

    this.state = {
      options: []
    }
    this.fetchData = this.fetchData.bind(this);
    this.inputRef = createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { defaultValue=[] } = this.props;
    // Only retrieve organizations that actually exist in the rate engine and
    // exclude user created provider orgs.
    const request = { provider_exists_in_pricing_engine: true };
    getProviders('provider-connection', request).then(({results=[{}]}) => {
      const options = results.map(({ name, key }) => ({ label: name, value: key })).sort(sortByLabel);

      const defaultOption = defaultValue.reduce((defaultOptions, currentOption) => {
        if (!!currentOption.value && currentOption.label === undefined) {
          const replacement = options.find(({ value }) => value == currentOption.value);
          if (replacement) {
            defaultOptions.push(replacement);
          }
        } else {
          defaultOptions.push(currentOption);
        }
        return defaultOptions;
      }, []);

      this.inputRef.current && this.inputRef.current.select.setValue(defaultOption);
      this.setState({ options });
    });
  }

  render() {
    const { label='Carrier', name=`carrier_lookup`, defaultValue, ...otherProps } = this.props;
    const { options } = this.state;
    const { inputRef } = this;

    return (
      <Lookup name={name} label={label} options={options} isMulti {...otherProps} ref={inputRef} />
    );
  }
}

export default CarrierDropdown;

/// OKTA TOKEN
import React from 'react';
import { Security } from '@okta/okta-react';
import { stringifyUrl } from 'query-string';


const OktaTokenSecurity = ({ children }) => {
  const { OKTA_ISSUER_URL, OKTA_CLIENT_ID } = process.env;

  const oktaProps = {
    issuer: OKTA_ISSUER_URL,
    client_id: OKTA_CLIENT_ID,
    redirect_uri: window.location.origin + '/implicit/callback',
    onAuthRequired: ({ history }) => {
      const { pathname, search } = window.location;
      const uri = pathname + search;
      const query = { redirectUri: uri === '/' ? null : uri };
      const url = stringifyUrl({ url: '/login', query }, { skipNull: true }); 
      history.push(url)
    }
  };

  return <Security {...oktaProps}>{children}</Security>
}

export default OktaTokenSecurity;
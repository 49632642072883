import { useRef } from 'react';
import Bowser from 'bowser';

export const useBrowserDetection = () => {
  const parser = useRef();
  parser.current = Bowser.parse(window.navigator.userAgent);

  const result = parser.current;
  const isSupported = result && result.browser.name !== 'Internet Explorer';

  return { result, isSupported };
};
import React, { useState } from 'react';
import { TextInput } from 'Atoms';
import { Popup } from 'Molecules';

const CancelOrderModal = props => {
  const { id='cancel-order-modal', onConfirm, onClose } = props;
  const [ reason, setReason ] = useState('');
  
  const onChange = e => setReason(e.currentTarget.value);
  const handleConfirm = () => onConfirm(reason);
  
  const noButton = { label: 'No, nevermind', onClick: onClose };
  const yesButton = { label: 'Yes, cancel this order', onClick: handleConfirm, primary: true, disabled: !reason };

  return (
    <Popup label="Confirm order cancellation." onClose={onClose} button1={noButton} button2={yesButton} id={id}>
      <p><b>WARNING!</b> Do you wish to proceed with cancelling this customer quote order. Doing so will also cancel the order in ServiceNow.</p>
      <TextInput maxLength="9000" label="Reason for cancellation" onChange={onChange} value={reason} />
    </Popup>
  )
}

export default CancelOrderModal;
import React, { useState, useRef } from 'react';

import { Icon } from 'Atoms';
import { Lookup } from 'Molecules';

import './LocationType.scss';

const getOptions = () => {
  return [
    { value: '', label: 'Least-cost', icon: 'least-cost' },
    { value: '', label: 'Datacenter/PoP', icon: 'data-center' },
    { value: '', label: 'Address', icon: 'location' },
    { value: '', label: 'Internet', icon: 'internet' },
  ];
}

const LocationTypeDropdown = props => {
  const options = getOptions();
  const onChange = option => props.onChange(option);
  const formatOptionLabel = ({ label, icon }, { context }) => (context === 'value' ? <Icon name={icon} /> : label);
  
  return (
    <Lookup 
      name="location-type" 
      options={options} 
      formatOptionLabel={formatOptionLabel}
      isSearchable={false}
      onChange={onChange}
    />
  );
}

export default LocationTypeDropdown;
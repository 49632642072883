import React from 'react';
import { MenuProvider } from 'react-contexify';
import { useUserContext } from 'Context/UserContext';
import { Button, ContextMenu, Icon, TimeAgo, Tooltip } from 'Atoms';

import { PERMISSIONS } from 'Utils/const.js';
import { toDesignApprovers } from 'Utils/formatter.js';

import './DesignValidation.scss';

export const VALIDATION_MENU_ID = {
  MULTIPLE_DESIGN_APPROVE: (id) => `design-validation-multiple-approve-roles-menu_${id}`,
  MULTIPLE_DESIGN_REJECT: (id) => `design-validation-multiple-reject-roles-menu_${id}`,
};

export const DesignApprovalButtons = ({ validation={}, isFetching, designId, onClick:{ order, approveDesign, rejectDesign }}) => {
  const { 
    canApprove, canApprovePricing, canApproveEngineer, canApproveCSA, canApproveMultiple, 
    canReject, canRejectEngineer, canRejectCSA, canRejectMultiple,
  } = useDesignValidationPermissions(validation);

  const classNames = {
    approveButton: 'design-approval-button design-approval-button--accept',
    rejectButton: 'design-approval-button design-approval-button--reject',
    waitingButton: 'design-approval-button--waiting',
  };

  const approve = () => {
    const approver = canApprovePricing ? 'pricing_desk' : canApproveEngineer ? 'network_engineering' : canApproveCSA ? 'csa' : undefined;
    approveDesign(approver);
  };

  const reject = () => {
    const rejecter = canRejectEngineer ? 'network_engineering' : canRejectCSA ? 'csa' : undefined;
    rejectDesign(rejecter);
  };

  return (
    <>
      <Button secondary onClick={order} children="View CCM Order" />
      { !!canApprove && (canApproveMultiple ? (
        <MenuProvider id={VALIDATION_MENU_ID.MULTIPLE_DESIGN_APPROVE(designId)} event="onClick">
          <Button className={classNames.approveButton} icon="checkmark-circle-filled" children="Approve Design" />
        </MenuProvider>
      ) : (
        <Button className={classNames.approveButton} icon="checkmark-circle-filled" onClick={approve} disabled={isFetching} children="Approve Design" />  
      ))}

      { !!canReject && (canRejectMultiple ? (
        <MenuProvider id={VALIDATION_MENU_ID.MULTIPLE_DESIGN_REJECT(designId)} event="onClick">
          <Button className={classNames.rejectButton} icon="add-circle-filled" children="Reject Design" />
        </MenuProvider>
      ) : (
        <Button className={classNames.rejectButton} icon="add-circle-filled" onClick={reject} disabled={isFetching} children="Reject Design" />
      ))}

      {/* { !canApprove && !canReject && (
        <Button type="link" className={classNames.waitingButton} secondary disabled children="Awaiting Other Approvals" />
      )} */}
    </>
  );
} 

export const DesignApprovals = ({ validation, onClick }) => {
  const user = useUserContext();
  const isPricingDesk = !!user.is_pricing_desk;
  const { networkEngineer, csa } = validation;

  const rejectedTooltip = ({ reason, timestamp, user_name}) => (
    <><div>{user_name} <TimeAgo date={timestamp} />:</div>{reason}</>
  );

  const approvedApprovals = toDesignApprovers(validation, 'approved');
  const rejectedTooltipEngineer = networkEngineer?.status === 'rejected' && rejectedTooltip(networkEngineer);
  const rejectedTooltipCsa = csa?.status === 'rejected' && rejectedTooltip(csa);

  return (
    <>
      {!!approvedApprovals.length && (
        <DesignApproval status="approved" text={`Approved by ${approvedApprovals}`} onClear={isPricingDesk && onClick.clearApprovals()} />
      )}
      {!!rejectedTooltipEngineer && (
        <DesignApproval status="rejected" tooltip={rejectedTooltipEngineer} text="Engineering Rejected" onClear={isPricingDesk && onClick.clearRejection('networkEngineer')}/>
      )}              
      {!!rejectedTooltipCsa && (
        <DesignApproval status="rejected" tooltip={rejectedTooltipCsa} text="CSA Rejected" onClear={isPricingDesk && onClick.clearRejection('csa')}/>
      )}
    </>
  )
}

const DesignApproval = ({ tooltip, status, text, onClear }) => {
  const icon = (status === 'approved') ? 'checkmark-circle-outline' : 'exclamation-point-circle-outline';
  const classNames = {
    tooltip: `tooltip design-approval__tooltip design-approval__tooltip--${status}`,
    component:`design-approval design-approval--${status}`,
    icon: `design-approval__icon design-approval__icon--${status}`,
    text: 'design-approval__text',
    button: 'design-approval__button',
  };

  return (
    <div className="flex-row">
      <Tooltip overlayClassName={classNames.tooltip} title={tooltip}>
        <div className={classNames.component}>
          <Icon name={icon} className={classNames.icon} />
          <div className={classNames.text}>{text}</div>
          {!!onClear && (
            <Button className={classNames.button} icon="close" onClick={onClear} />
          )}
        </div>
      </Tooltip>
    </div>
  )
}

export const DesignApprovalContextMenus = ({ validation, designId, onClick: { approveDesign, rejectDesign } }) => {
  const {
    canApprovePricing, canApproveEngineer, canApproveCSA,
    canApproveMultiple, 
    canRejectEngineer, canRejectCSA,
    canRejectMultiple 
  } = useDesignValidationPermissions(validation);

  const acceptItems = canApproveMultiple && [
    canApprovePricing && { label: 'Approve as Pricing Desk', onClick: () => approveDesign('pricing_desk') },
    canApproveEngineer && { label: 'Approve as Network Engineer', onClick: () => approveDesign('network_engineering') },
    canApproveCSA && { label: 'Approve as CSA', onClick: () => approveDesign('csa') },
  ].filter(Boolean);

  const rejectItems = canRejectMultiple && [
    canRejectEngineer && { label: 'Reject as Network Engineer', onClick: () => rejectDesign('network_engineering') },
    canRejectCSA && { label: 'Reject as CSA', onClick: () => rejectDesign('csa') },
  ].filter(Boolean);

  return (
    <>
      {!!acceptItems && <ContextMenu id={VALIDATION_MENU_ID.MULTIPLE_DESIGN_APPROVE(designId)} items={acceptItems} /> }
      {!!rejectItems && <ContextMenu id={VALIDATION_MENU_ID.MULTIPLE_DESIGN_REJECT(designId)} items={rejectItems} /> }
    </>
  )
}

const useDesignValidationPermissions = (validation) => {
  const { hasPermission } = useUserContext();
  const { DESIGN_APPROVE_CSA,
    DESIGN_APPROVE_ENGINEERING,
    DESIGN_APPROVE_PRICING_DESK,
    DESIGN_REJECT_CSA,
    DESIGN_REJECT_ENGINEERING
  } = PERMISSIONS;

  const canApprovePricing = !validation.pricingDesk && hasPermission(DESIGN_APPROVE_PRICING_DESK);
  const canApproveEngineer = !validation.networkEngineer && hasPermission(DESIGN_APPROVE_ENGINEERING);
  const canApproveCSA = !validation.csa && hasPermission(DESIGN_APPROVE_CSA);

  const canRejectEngineer = !validation.networkEngineer && hasPermission(DESIGN_REJECT_ENGINEERING);
  const canRejectCSA = !validation.csa && hasPermission(DESIGN_REJECT_CSA);

  const canApprove = canApprovePricing || canApproveEngineer || canApproveCSA;
  const canApproveMultiple = [canApprovePricing, canApproveEngineer, canApproveCSA].filter(Boolean).length > 1;

  const canReject = canRejectEngineer || canRejectCSA;
  const canRejectMultiple = canRejectEngineer && canRejectCSA;

  return {
    canApproveCSA,
    canApprovePricing,
    canApproveEngineer,
    canApprove,
    canApproveMultiple,
    
    canRejectCSA,
    canRejectEngineer,
    canReject,
    canRejectMultiple,
  }
}
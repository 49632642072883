export function count(n, singular, plural, zero = null) {
  if (n === 1) 
    return `1 ${singular}`;

  if (zero !== null && n === 0)
    return zero;
    
  return `${n} ${plural || (singular + 's')}`;
}

export function toQueryStringObject(url) {
  const arr = url.slice(1).split(/&|=/); // remove the "?", "&" and "="
  let params = {}, i = 0, l = arr.length;

  for(; i < l; i += 2){
    const key = arr[i], value = arr[i + 1];
    params[key] = value ; // build the object = { limit: "10", page:"1", status:"APPROVED" }
  }
  return params;
}

export function capitalize(str) {
  return !str ? '' : str[0].toUpperCase() + str.substring(1)
}

export function decodeURIComponentSafe(s) {
  const makeSafe = str => str.replace(/%(?![0-9][\da-f]+)/gi, '%25');

  return (!s) ? s : decodeURIComponent(makeSafe(s));
}

export function slugify (text) {
  const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
  const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(p, c =>
        b.charAt(a.indexOf(c)))     // Replace special chars
    .replace(/&/g, '-and-')         // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '')             // Trim - from end of text
}

export function getFileExtension(fileName='') {
  const extensionRegEx = /\.([0-9a-z]+)(?:[\?#]|$)/i;

  return ((fileName.match(extensionRegEx) || [''])[0] || '').toLowerCase();
}
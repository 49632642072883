import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Breadcrumbs } from 'Atoms';

import { DefFavicon, DefFaviconName, PAGE_TITLE_NAME } from 'Utils/branding.js';

import 'Pages/CCM/page.scss';

const Page = props => {
  const {
  className='',
  header,
  title=`${PAGE_TITLE_NAME}: ${header}`,
  favIcon=DefFavicon,
  faviconName=DefFaviconName,
  crumbs,
  children
  } = props;
  
  return (
    <section className={className}>
      <Helmet>
        <title>{title}</title>
        <link rel={faviconName} type="image/png" href={favIcon} />
      </Helmet>

      <Breadcrumbs crumbs={crumbs} />
      <div className="page-content">
        <h1 className="page-header">{header}</h1>
        {children}
      </div>
    </section>
  )
}

export default Page;
import React, { useState } from 'react';
import CN from 'classnames';
import { Button, CurrencyInput, Icon, PopConfirm } from 'Atoms';
import { toCurrency } from 'Utils/formatter.js';

import './ExternalCostView.scss';


export const AddExternalCostViewButton = props => {
  const { _mrc, _nrc, externalMrc, externalNrc, onChange, isEditable, quoteOrgName } = props;
  const className = "external-cost-view__add-button";
  const addView = () => {
    onChange.externalCost({ 
      externalMrc: externalMrc || _mrc, 
      externalNrc: externalNrc || _nrc,
    });

  }

  return !!isEditable && <Button icon="lock" type="link" onClick={addView} className={className}>Preserve {quoteOrgName} view of UG costs</Button>
}

export const ExternalCostView = props => {
  const { className='', externalMrc, externalNrc, isEditable, onChange={}, currency, quoteOrgName, partnerName } = props;

  const [ isShowingPopConfirm, setIsShowingPopConfirm ] = useState(false);
  const orgName = partnerName || quoteOrgName;

  const inputProps = propName => ({
    value: props[propName],
    onChange: isEditable ? onChange[propName] : undefined,
    readOnly: !isEditable,
    disabled: !isEditable,
    currency
  });

  const showPopConfirm = () => setIsShowingPopConfirm(true);
  const closePopConfirm = () => setIsShowingPopConfirm(false);

  const onPopConfirm = () => {
    closePopConfirm();
    onChange.externalCost({ externalMrc: null, externalNrc: null });
  };

  const popConfirmTitle = (<>
    <div>Are you sure you want to remove this view?</div><br />
    <p>Removing this view will expose the actual build costs to {orgName} pricing desk users once design is saved.</p>
  </>);

  const popConfirmProps = {
    visible: isShowingPopConfirm,
    overlayClassName: 'external-cost-view__remove-popconfirm',
    title: popConfirmTitle,
    onConfirm: onPopConfirm,
    onCancel: closePopConfirm,
    placement: 'leftBottom',
  }

  const classNames =  {
    component: CN(`external-cost-view ${className}`, {
      'external-cost-view--read-only': !isEditable,
      'external-cost-view--editable': !!isEditable,
    }),
  }

  return (
    <div className={classNames.component}>
      <div className="external-cost-view__title-bar">
        <span className="external-cost-view__title-text">{`${orgName} view of vendor cost`}</span>
        {!!isEditable && (
          <PopConfirm {...popConfirmProps} >
            <div className="external-cost-view__remove-button" onClick={showPopConfirm}><Icon name="close" /></div>
          </PopConfirm>
        )}
      </div>

      <div className="external-cost-view__content">
        <div className="external-cost-view__pricing-container design-item-group--pricing">
          <div className="price-margin">
            {!isEditable
              ? <div>{toCurrency(externalMrc, currency)}</div>
              : <CurrencyInput {...inputProps('externalMrc')} />
            }
          </div>
        </div>

        <div className="external-cost-view__pricing-container design-item-group--pricing">
          <div className="price-margin">
          {!isEditable
            ? <div>{toCurrency(externalNrc, currency)}</div>
            : <CurrencyInput {...inputProps('externalNrc')} />
          }
          </div>
        </div>
      </div>
    </div>
  );
}

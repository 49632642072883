import React from "react";

import './button_bar.scss';

const ButtonBar = props => {
  return (
    <div className="button-bar">
      {props.children}
    </div>
  );
}

export default ButtonBar;

import React from 'react'
import CN from 'classnames';
import { Button, Checkbox, LoadingOverlay, Notification } from 'Atoms';
import { Lookup, Popup } from 'Molecules';
import DesignItemsEditor from './DesignItem/DesignItemsEditor.jsx';
import useDesignEditor from './useDesignEditor';
import { EDITOR_MODALS } from './DesignEditorHelper.js';
import { toCurrency } from 'Utils/formatter.js';

import './DesignEditor.scss';

const DesignEditor = ({ domId, onToggleIsSolution, design, onSave, onCancel, ...props }) => {
  const {
    isSaving,
    saveDesign,
    cancelEditing,
    onChange,
    onChangeRow,
    validationErrors,
    modal,
    onCloseModal,
    onConfirmModal,
    onConfirmNoMargin,
    isSolution,
    options,
    defaultValues,
    toggleIsSolution,
    addRow,
    moveRow,
    deleteRow,
    locationZ,
    term,
    leadTime,
    designType,
    items,
    rowOptions,
    pricing,
    getErrors,
  } = useDesignEditor({ design, onToggleIsSolution, onSave, onCancel, ...props });

  // TODO: Some of these better suited as a context component to make available to all rows instead of mapping into all rows and prop drilling through parent compnent
  const passThroughProps = { 
    ...props,
    items,
    design,
    isSolution, 
    term: term?.value,
    leadTime: leadTime?.value, 
    designType: designType?.value,
    addRow,
    moveRow,
    deleteRow,
    locationZ,
    onChangeRow,
    rowOptions,
    pricing,
    validationErrors,
    getErrors,
  };

  const isOrdered = ['ordered', 'manually-ordered'].includes(props.status);

  const getDropdownProps = (stateKey) => ({
    name: stateKey,
    onChange: onChange[stateKey],
    options: options[stateKey],
    defaultValue: defaultValues[stateKey],
    error: validationErrors[stateKey]
  });

  const className = CN('design-editor', {
    'design-editor--not-solution': !isSolution,
    'design-editor--is-solution': !!isSolution,
    'design-editor--is-ordered': isOrdered,
  });

  const { locationPairs, missingLocation, internetItems, testing, serverErrors } = validationErrors;
  
  const DesignItemErrors = Object
    .entries({ locationPairs, missingLocation, internetItems, testing, ...serverErrors })
    .filter(([,error]) => Boolean(error))
    .map(([key, error]) => <li key={key}>{error}</li>);

  return (
    <div id={domId} className={className}>
      { isSaving && <LoadingOverlay /> }
      <div className="design-editor__header-row">
        <div className="design-editor__header-options">
          <Checkbox onChange={toggleIsSolution} checked={isSolution} title="Set design as visible solution for other users" />
          <Lookup label="Product Access Types" { ...getDropdownProps('designType') } />
        </div>

        <div className="design-editor__design-actions">
          <Button disabled={isSaving} onClick={cancelEditing} secondary>Cancel</Button>
          <Button disabled={isSaving} onClick={saveDesign}>Save</Button>
        </div>
      </div>

      {!!DesignItemErrors.length && (
        <Notification.Error className="design-editor__notification-errors">
          <div>There were some errors found validating this design</div>
          <ul>{DesignItemErrors}</ul>
        </Notification.Error>
      )}

      <div className="design-editor__terms-row">
        <Lookup label="Term" { ...getDropdownProps('term') } />
        <Lookup label="Lead Time" { ...getDropdownProps('leadTime') } />
      </div>
      <div className="design-editor__design-items">
        <DesignItemsEditor {...passThroughProps} />
      </div>

      {/* <DesignTotals /> */}

      {(modal === EDITOR_MODALS.CANCEL) && (
        <CancelModal onClose={onCloseModal} onConfirm={onConfirmModal} />
      )}
      {(modal === EDITOR_MODALS.NO_MARGIN) && (
        <NoMarginModal onClose={onCloseModal} onConfirm={onConfirmNoMargin} marginAmt={pricing.marginAmt} currency={design.requestedCurrency} />
      )}
    </div>
  )
}

const CancelModal = ({ onClose, onConfirm }) => {
  const props = {
    onClose,
    id: 'cancelEditModal',
    label: 'Cancel this design?',
    button1: { label: 'No, do not cancel', onClick: onClose },
    button2: { label: 'Yes, cancel', onClick: onConfirm, primary: true }
  };

  return (
    <Popup {...props}>
      <div>By canceling this design, all unsaved data associated with it will be lost.</div>
      <div>Please confirm below.</div>
    </Popup>
  );
};

const NoMarginModal = ({ onClose, onConfirm, marginAmt, currency }) => {
  const boldText = !marginAmt ? ' at cost' : ` ${toCurrency(-1*marginAmt, currency)} below cost`;
  const props = {
    onClose,
    id: 'belowCostModal',
    label: 'This design has no margin',
    button1: { label: 'No, do not save', onClick: onClose },
    button2: { label: 'Yes, save', onClick: onConfirm, primary: true },
    children: (
      <div>This design is being saved <b>{boldText}. </b>
        Are you sure you want to proceed?
      </div>
    )
  }

  return marginAmt > 0 ? null : <Popup {...props} />
};

export default DesignEditor;


import React, { useEffect, useState } from 'react';
import { SortableTable } from 'Atoms';

import { getRateEngineQueue } from 'Actions/ccm/api.js';

const RateEngineQueue = () => {
  const [ rowData=[], setRowData ] = useState();

  useEffect(() => {
    getRateEngineQueue().then(resp => setRowData(resp));
  }, []);

  const COLUMN_DATA = [
    { key: 'processing_Group', header: 'Processing Group' },
    { key: 'pending' },
    { key: 'processing' },
    { key: 'total' },
    { key: 'max_Processing_Age', header: 'Max Processing Age' },
  ];

  return (
    <div className="rate-engine-queue">
      <SortableTable className="rate-engine-queue__table" columnData={COLUMN_DATA} rowData={rowData} />
    </div>
  )
}

export default RateEngineQueue;
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Popup } from 'Molecules';
import { CreateQuote } from 'Widgets';
import { getDefaultOptions } from 'Widgets/CreateQuote/CreateQuoteMapper.js';

import './ImportErrorModal.scss';

const CreateQuoteModal = (props) => {
  const { onClose, onConfirm, requirements, allowUpdateEmail } = props;
  const isEditing = requirements.id;

  const [ isExpanded, setIsExpanded ] = useState(false);

  const history = useHistory();

  const createQuoteRef = useRef();

  const defaultValues = getDefaultOptions(requirements);
  const showPartnerData = defaultValues.pricing;
  const oboOrgId = isEditing ? requirements.orgId : null;

  const onClick = () => createQuoteRef.current.submit();

  const onSubmit = ({ reference, folderName }) => {
    folderName
      ? history.push(`/quote-manager/${encodeURIComponent(folderName)}`) :
    isEditing
      ? window.location.reload()
      : history.push(`/quote/${reference}/`);
    
    onConfirm();
  };

  const onToggleAdvancedOptions = ({ expanded }) => setIsExpanded(expanded);

  const labelButton = isEditing ? 'Requote' : 'Create new quote';
  const labelPopup = isEditing ? 'Edit specifications and requote' : 'Clone specifications and create a new quote';

  const yesButton = { label: labelButton, onClick, primary: true };
  const noButton = { label: 'Cancel', onClick: onClose };

  const createQuoteProps = { onSubmit, defaultValues, showPartnerData, oboOrgId, allowUpdateEmail, onToggleAdvancedOptions };

  return (
    <Popup
      label={labelPopup}
      onClose={onClose}
      button1={noButton}
      button2={yesButton}
      id="import-error-popup"
      className={`popup--is-${isExpanded ? 'expanded' : 'collapsed' }`}
    >
      <CreateQuote ref={createQuoteRef} {...createQuoteProps} />
    </Popup>
  );
}

export default CreateQuoteModal;

import React from 'react';
import { Icon } from 'Atoms';
import SVG from 'react-inlinesvg';

import { DefSvgFullColorLogo, DefFullLogoName, SYSTEM_NAME } from 'Utils/branding.js';

import 'Organisms/navbar.scss';
import './UnsupportedBrowser.scss';

const UnsupportedBrowser = () => {
  const style = { padding: '0 1.8rem'};

  return (
    <div className="unsupported-browser patterned">
      <nav style={style}>
        <h1>
          <a href="/">
            <SVG height="28px" src={DefSvgFullColorLogo} alt={DefFullLogoName} />
          </a>
        </h1>
      </nav>

      <table className="unsupported-browser__table">
        <tbody>
          <tr>
            <td className="unsupported-browser__section">
              <Icon name="unsupported-browser" />
              Unsupported Browser
            </td>
          </tr>
          <tr>
            <td className="recommended-browsers__section">
              <p>To get the most out of using the {SYSTEM_NAME} Platform, please login with one of the supported browsers below.</p>
              <ul className="recommended-browsers__list">
                <li><Icon name="chrome" />Chrome</li>
                <li><Icon name="safari" />Safari</li>
                <li><Icon name="firefox" />Firefox</li>
                <li><Icon name="edge" />Edge</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default UnsupportedBrowser;
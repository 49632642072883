import React, { useEffect, useState, useRef } from 'react';
import { useUserContext } from 'Context/UserContext.js';
import { Redirect } from 'react-router-dom';

import { Page, Tabs, TabPane } from 'Atoms';
import { Lookup } from 'Molecules';

import AdminAudit from './AdminAudit.jsx';
import AdminPricing from './AdminPricing.jsx';
import AdminPops from './AdminPops.jsx';
import QuotingOptions from './QuotingOptions.jsx';
import AdminUsers from './AdminUsers.jsx';

import { getOrganizations, createOrganization } from 'Actions/ccm/api.js';
import { sortByLabel } from 'Utils/array.js';
import { PERMISSIONS } from 'Utils/const.js';

import '../page.scss';
import './Admin.scss';

const AdminPage = () => {
  const user = useUserContext();

  const [ selected, setSelected ] = useState(null);
  const [ organizations, setOrganizations ] = useState([]);

  const inputRef = useRef();  

  const mapOrganizationToOption = (organization, index, arr) => {
    if (!organization) return;

    const getName = key => arr.find(x => x.company_key === key)?.name;
 
    const { name, id, url, option_constraint,
      is_wholesale_partner, mrr_partner_commission_wholesale,
      is_enterprise_partner, mrr_partner_commission_enterprise,
      mrr_margin_percent_cross_connect_end_user, mrr_margin_percent_cross_connect_ug, 
      mrr_margin_percent_end_user, mrr_margin_percent_ug, 
      nrr_markup_fixed_cross_connect_end_user, nrr_markup_fixed_cross_connect_ug, 
      nrr_markup_fixed_end_user, nrr_markup_fixed_ug,
      default_currency_code, has_zero_price_unitas_ip_leg, may_view_design_items,
      no_nid_at_own_pops,
      force_cross_connect_at_own_pops, 
      force_cross_connect_at_own_pops_not_colocated_with_unitas_pops,
      force_additional_cross_connect_unitas_ip,
      requires_order_manager,
      may_request_multiple_bandwidths,
      pricing_rules,
      may_view_provider_connection_vendor,
      settings={},
      has_users, company_key,
    } = organization;

    const iconClassName = `icon-avatar--${ has_users ? 'has-users' : 'no-users' }`;

    const pricing = { 
      is_wholesale_partner, mrr_partner_commission_wholesale,
      is_enterprise_partner, mrr_partner_commission_enterprise,
      mrr_margin_percent_cross_connect_end_user, mrr_margin_percent_cross_connect_ug, 
      mrr_margin_percent_end_user, mrr_margin_percent_ug, 
      nrr_markup_fixed_cross_connect_end_user, nrr_markup_fixed_cross_connect_ug, 
      nrr_markup_fixed_end_user, nrr_markup_fixed_ug,
      default_currency_code, has_zero_price_unitas_ip_leg, may_view_design_items,
      no_nid_at_own_pops,
      force_cross_connect_at_own_pops, 
      force_cross_connect_at_own_pops_not_colocated_with_unitas_pops,
      force_additional_cross_connect_unitas_ip,
      pricing_rules,
      may_view_provider_connection_vendor,
      settings,
    };

    const constraints = {
      ...option_constraint,
      may_request_multiple_bandwidths,
      excluded_carriers: ((settings || {}).excluded_carriers || []).map(key => 
        ({ value: key, label: getName(key) })
      ),
    };

    return {
      label: name, 
      value: id,
      company_key,
      name, 
      icon: 'avatar', 
      iconClassName, 
      hasUsers: has_users,
      url, 
      constraints, 
      pricing, 
      requiresOrderManager: requires_order_manager,
      docusignEnabled: !!settings?.group_permissions?.includes(PERMISSIONS.DOCUSIGN_ENABLED)
    };
  }

  const fetchData = async () => {
    const resp = await getOrganizations();
    const orgs = resp.results.map(mapOrganizationToOption);

    setOrganizations(orgs);
    setSelected(selected ? orgs.find(x => x.value === selected.value) : null);
  }

  const rehydrateSelected = resp => {
    const org = mapOrganizationToOption(resp, null, organizations);
    const orgs = organizations.map(org => org.value !== selected.value ? org : selected);

    setOrganizations(orgs);
    setSelected(org);
  }

  const onChangeOrganization = org => setSelected(org);

  const onCreateOption = name => {    
    createOrganization({ name }).then(resp => {
      if (resp.error) {
        return alert(Object.values(resp.error).join('. '));
      }

      const org = mapOrganizationToOption(resp);

      setSelected(org);
      setOrganizations([...organizations, org].sort(sortByLabel));
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return !user.is_admin ? <Redirect to={'/'} /> : (
    <Page className="admin-page" header="Admin Dashboard">

      <div className="admin-section">
        <div className="header-bar">
          <div className="header-bar-title">Organizations</div>

          <Lookup name="organizations" 
            createable
            isClearable={true}
            isLoading={!organizations.length}
            inputRef={inputRef}
            options={organizations} 
            onChange={onChangeOrganization}
            onCreateOption={onCreateOption} 
            value={selected}
          />
        </div>

        {!!selected && 
          <Tabs>
            <TabPane tab="User Management" key="usersTab">
              <AdminUsers organizationId={selected.value} organizationName={selected.label} requiresOrderManager={selected.requiresOrderManager} docusignEnabled={selected.docusignEnabled} onSave={rehydrateSelected} />
            </TabPane>

            <TabPane tab="Pricing Options" key="marginsTab">
              <AdminPricing organizationId={selected.value} pricing={selected.pricing} onSave={rehydrateSelected} />
            </TabPane>

            <TabPane tab="Service Options" key="optionsTab">
              <QuotingOptions organizationId={selected.value} constraints={selected.constraints} onSave={rehydrateSelected} />
            </TabPane>

            <TabPane tab="PoP Lists" key="popsTab">
              <AdminPops organizationId={selected.value} onSave={rehydrateSelected} />
            </TabPane>

            <TabPane tab="Audit Log" key="auditTab">
              <AdminAudit organizationId={selected.value} onSave={rehydrateSelected} />
            </TabPane>
          </Tabs>
        }
      </div>
    </Page>
  )
}

export default AdminPage;

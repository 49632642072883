import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'Atoms';
import { AddressLookup, ColoLookup, InternetLookup, Lookup, LeastCostByOrganizationLookup } from 'Molecules';
import { getInternetServiceRequirements } from 'Utils/formatter.js';

import './LocationCombo.scss';

const LocationCombo = props => {
  const { name, onChange, onChangeType, label, colos=[], showInternet, showLeastCost, showLeastCostByOrg, error, defaultValue, defaultOptions, internetOptions=[], isDisabled, allowDemarc, includeHubs=false, ipRateSelection, toggleIpRate } = props;
  
  const options = [
    { value: 'address', label: 'End-user Address', icon: 'location' },
    { value: 'colo', label: 'Data center/PoP Locations', icon: 'data-center' },
    { value: 'leastCost', label: 'Least-cost data center/PoP location', icon: 'least-cost' },
    { value: 'leastCostByOrganization', label: 'Least-cost data center/PoP location by organization', icon: 'least-cost'},
    { value: 'internet', label: 'Internet', icon: 'internet' }
  ];
  const [addressOption, dataCenterOption, leastCostOption, leastCostByOrgOption, internetOption] = options;

  const value = (defaultValue || {});
  const initialState = {...(
    (value.value === 'leastCost') ? leastCostOption :
    (getInternetServiceRequirements(value.value)) ? internetOption :
    //(value.data_center_url) ? dataCenterOption :
    (value.popSelectionOrganizationKey)
      ? leastCostByOrgOption
      : addressOption
  ), noFocus: true };

  const [ selected, setSelected ] = useState(initialState);

  const addressRef = useRef(null);
  const coloRef = useRef(null);
  const leastCostByOrgRef = useRef(null);
  const internetRef = useRef();

  const filterOption = ({ data }) => (
    (data.value !== 'leastCost' || !!showLeastCost) 
    && (data.value !== 'internet' || !!showInternet) 
    && (data.value !== 'leastCostByOrganization' || !!showLeastCostByOrg)
  );

  useEffect(function focusInput() {
    const { value, noFocus } = (selected || {});

    (value === 'leastCost') && onChange(selected);
    let ref = (
      (value === 'address') ? addressRef.current.inputRef.current.select.select.select :
      (value === 'colo') ? coloRef.current.inputRef.current.select.select :
      (value === 'leastCostByOrganization') ? leastCostByOrgRef.current.select :
      (value === 'internet') ? internetRef.current.select : null
    );

    value && !noFocus && ref && ref.focus();
  }, [selected])

  const handleOnChangeType = option => {
    onChangeType?.(option);
    setSelected(option);
  };

  const locationTypeProps = {
    options,
    filterOption,
    formatOptionLabel: ({ label, icon }, { context }) => (context === 'value') ? <Icon name={icon} /> : label,
    className: 'location-type',
    name: `location-type-${name}`,
    isSearchable: false,
    value: selected,
    onChange: handleOnChangeType,
    isDisabled
  };

  useEffect(() => {
    if (colos.length && selected?.value !== 'colo' && !!value) {
      const dataCenter = colos.filter(({ name }) => name === value.string_address)[0];
      dataCenter && setSelected({ ...dataCenterOption, noFocus: true });
    }
  }, [colos]);

  const lookupProps = { name, label, icon: null, onChange, error, defaultValue, isDisabled };

  return (
    <div className="location-combo">
      <Lookup {...locationTypeProps} />
      { (selected.value === 'address')
          ? <AddressLookup ref={addressRef} className="address" placeholder="Please enter an address" defaultOptions={defaultOptions} allowDemarc={allowDemarc} includeHubs={includeHubs} {...lookupProps} /> :
        (selected.value === 'colo')
          ? <ColoLookup ref={coloRef} colos={colos} includeHubs={includeHubs} {...lookupProps} /> :
        (selected.value === 'leastCostByOrganization')
          ? <LeastCostByOrganizationLookup ref={leastCostByOrgRef} {...lookupProps} /> :
        (selected.value === 'internet')
          ? <InternetLookup 
              ref={internetRef} 
              options={internetOptions} 
              ipRateSelection={ipRateSelection}
              toggleIpRate={toggleIpRate}
              {...lookupProps} 
            />
          : <Lookup className="location location-combo-value" value={selected} menuIsOpen={false} {...lookupProps} />
      }
    </div>
  );
}

export default LocationCombo;

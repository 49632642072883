import React, { Component } from 'react';

import { InfoIcon } from 'Atoms';
import Tooltip from './Tooltip.jsx';

export default class InfoTooltip extends Component {
  render() {
    return (
      <Tooltip title={this.props.title}>
        <InfoIcon />
      </Tooltip>
    );
  }
}


import {
  PUSH_TO_MODAL_STACK,
  POP_MODAL_STACK,
} from 'Actions';

const initialState = {
  stack: [],
  peek: null,
};

export default function (prevState = initialState, action) {
  switch (action.type) {
    case PUSH_TO_MODAL_STACK:
      return Object.assign({}, prevState, {
        stack: [...prevState.stack, action.data],
        peek: action.data,
      });
    case POP_MODAL_STACK: {
      const poppedStack = prevState.stack.slice(0, -1);
      return Object.assign({}, prevState, {
        stack: poppedStack,
        peek: poppedStack[poppedStack.length - 1], // [][-1] === undefined
      });
    }
    default:
      return prevState;
  }
}

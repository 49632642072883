import React from 'react';
import { Icon } from 'Atoms';
import { getAlpha2 } from 'Utils/countries.js';

import 'Sass/twemoji-flags.scss';

//Note: The following location options display as label text:
// Autocomplete, ValidateAddress (fallback), Provider, Region, Recent Location

// Allow user to input address option via advanced search option
// Dev flagged currently 
export const AdvancedSearchOption = () => {
  return (
    <div className="address-option address-option__advanced-search">
      <div className="fs-12">Not what you're looking for?</div>
      <div>Try <em>advanced address lookup</em></div>
    </div>
  );
}

// Colo group header - Display country flag and name under regions 
export const CountryGroupHeader = ({ countryCode, countryName }) => {
  const alpha2 = (getAlpha2(countryCode) || '').toLowerCase();
  const className = `em-flag em-flag-${alpha2}`;

  return <div className={className}>{countryName}</div>
}

// Address option - Allow user to use input as address option despite other options
export const UnvalidatedOption = () => {
  return (
    <div className="address-option address-option__unvalidated">
      <Icon name="question-mark" />
      <div>Not what you're looking for? Proceed with your entry, though your quote request may require manual pricing.</div>
    </div>
  );
}

// Address option - Allow user to use input as address option when no other options available
export const UnvalidatedOnlyOption = ({ inputValue }) => {
  return (
    <div className="address-option address-option__unvalidated-only">
      <Icon name="exclamation-point-triangle-filled" />
      <div>
        <div className="address-option__input-value">{ inputValue }</div>
        <div className="address-option__not-found">This location cannot be found. If you proceed, your quote request may require manual pricing.</div>
      </div>
    </div>
  );
}

// Address option - Allow user to use input to return floor/suite info when demarc quoting option is enabled for user
export const DemarcOption = props => {
  const { label, floor, suite } = props;

  const floorSuite = [
    floor && (floor.toLowerCase().indexOf('floor') > -1 ? floor : `Floor ${floor}`), 
    suite && (suite.toLowerCase().indexOf('suite') > -1 ? suite : `Suite ${suite}`)
  ].filter(x=>!!x).join(', ');

  return (
    <div className="address-option address-option__demarc">
      <div>{label}</div>
      <div className="address-option__sublabel">{floorSuite}</div>
    </div>
  )
}

// Address/PoP option - Used by PoP autocomplete
export const AddressOption = (props) => {
  const { popName, addressLabel, label } = props;
  const icon = popName ? 'data-center' : 'location';
  const className = `address-option address-option--${icon}`;

  return (
    <div className={className}>
      <div>
        {!!popName && <div className="address-option__pop-name">{popName}</div>}
        <div className="address-option__address">{addressLabel || label}</div>
      </div>
    </div>
  )
}

// Flag option to display country flag
export const SiteCodeOption = (props) => {
  const { popName, addressLabel, countryCode } = props;
  const alpha2 = (getAlpha2(countryCode) || '').toLowerCase();
  const className = `address-option em-flag em-flag-${alpha2}`;
  
  return (
    <div className={className}>
      <div className="address-option__city-state">{popName}</div>
      <div className="address-option__site-code-address">{addressLabel}</div>
    </div>
  )
}

// Flag option to display country flag
export const LatLongOption = ({ label, distance }) => {
  return (
    <div className="address-option address-option__latlong">
      <div>{label}</div>
      <div className="address-option__sublabel">{distance}m away</div>
    </div>
  )
}

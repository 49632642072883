import React, { Component } from 'react'
import Lookup from './Lookup.jsx';

import { getFolders, createFolder } from 'Actions/ccm/api.js';

class FolderDropdown extends Component {
  constructor(props) {
    super(props);

    // NOTE: AsyncSelect does not support isLoading prop yet... 
    // https://github.com/JedWatson/react-select/issues/2986
    this.state = {
      inputValue: '',
      isLoading: false,
      options: [], 
      value: props.defaultValue,
    }

    this.onChange = this.onChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    //this.loadOptions = debounce(this.loadOptions, 500);
    this.handleCreate = this.handleCreate.bind(this);
    this.fetchData = this.fetchData.bind(this);

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { defaultValue={}, ownerId } = this.props;
    const request = { owner_id: ownerId };
    const lookup = this.inputRef.current.select.select;

    this.setState({ isLoading: true });
    getFolders(request)
      .then(resp => {
        const options = (resp.results || []).map(folder => ({
          label: folder.name === '.' ? 'Quote Manager Home' : folder.name,
          value: folder.id + '',
          ...folder
        }));

        const foundIndex = options.findIndex(({ label }) => label === 'Quote Manager Home');
        if (foundIndex > -1) {
          options.splice(0, 0, options.splice(foundIndex, 1)[0]);
        }
        
        const defaultOption = defaultValue && defaultValue.value && options.find(({id}) => id == defaultValue.value);
        if (defaultOption) {
          lookup.setValue(defaultOption);
        }
        this.setState({ options, isLoading: false });
      })
  }

  onChange(option) {
    const value = option || null;
    const inputValue = option ? option.label : '';

    this.setState({ value, inputValue });
    this.props.onChange(option);
  }

  handleCreate(label) {
    this.setState({ value: { label }, isLoading: true });
    const lookup = this.inputRef.current.select.select;

    createFolder(label).then(resp => {
      // TODO: CHECK FOR ERROR
      const option = { label, value: resp.id + '', url: resp.url, id: resp.id, name: resp.name };
      const options = [...this.state.options, option];

      lookup.setValue(option);
      this.setState({ isLoading: false, options, inputValue: label });
    });
  }

  onInputChange(inputValue, { action }) {
    const { value } = this.state;
    
    if (action === 'input-blur') {
      this.setState({ inputValue: !value ? '' : value.label });
    }
    
    else if (action === 'input-change') {
      this.setState({ inputValue });
    }

    return;
  }
  
  render() {
    const { isLoading, options, inputValue='' } = this.state;
    const { label, error, filterOption } = this.props;

    return (
      <Lookup
        name="folder"
        editable={true}
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        inputValue={inputValue}
        ref={this.inputRef}
        label={label}
        icon={"folder"}
        options={options}
        createable={true}
        isDisabled={isLoading}
        isLoading={isLoading}
        isClearable={true}
        onCreateOption={this.handleCreate}
        error={error}
        filterOption={filterOption}
        maxLength={255}
        
        // value={value}
        // defaultValue={defaultValue}
      />
    )
  }
}

export default FolderDropdown;

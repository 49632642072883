import React, { Component, createRef } from 'react';
import { LoadingOverlay } from 'Atoms';
import { Popup } from 'Molecules';
import { createProvider } from 'Actions/ccm/api.js';

import './ModalNewProvider.scss';

class ModalNewProvider extends Component {
  constructor(props={}) {
    super(props)

    this.state = {
      provider: '',
      contact: '',
      address: '',
      phone: '',
      error: null,
      isSaving: false
    }

    this.type = props.type;

    this.focusInputRef = createRef();

    this.setValue = this.setValue.bind(this);
    this.createProvider = this.createProvider.bind(this);
  }

  setValue(key) {
    return (input) => this.setState({ [key]: input.currentTarget.value, error: null });
  }

  createProvider() {
    const { provider='', contact='', address='', phone='' } = this.state;
    const data = { provider, contact, address, phone, type: this.type };

    if (!provider.trim().length) {
      return this.setState({ error: 'Connectivity Provider cannot be blank.' });
    }

    this.setState({ isSaving: true });
    createProvider(data).then(response => {
      
      const  { name, key } = (response.error || {});
      const error = name || key;
      if (!!error) {
        return this.setState({ isSaving: false, error });
      }

      this.setState({ provider: '', contact: '', address: '', phone: '', isSaving: false });
      this.props.onAdd(response);
    });
  }

  componentDidMount() {
    this.focusInputRef.current.focus();
  }

  render() {
    const { provider, isSaving, error } = this.state;
    const { onClose } = this.props;
    const props = {
      className: 'modal-add-new-provider',
      onClose,
      id: 'addNewProviderModal',
      label: 'Add New Connectivity Provider',
      button1: { label: 'Cancel', onClick: onClose },
      button2: { label: 'Add', onClick: this.createProvider, primary: true, disabled: (provider === '' && !!isSaving) }
    }

    return (
      <Popup {...props}>
        { !!isSaving && <LoadingOverlay /> }
        <div>Please enter new provider details below.</div>
        <input type="text" placeholder="Connectivity Provider" className={!error ? '' : 'error'} name="NewProviderName" ref={this.focusInputRef} onInput={this.setValue('provider')} required autoFocus />
        { !!error && <div style={{'color': 'red'}}>{error}</div> }
        <input type="text" placeholder="Contact Name" name="NewProviderContact" onInput={this.setValue('contact')} />
        <input type="text" placeholder="Address" name="NewProviderAddress" onInput={this.setValue('address')}  />
        <input type="text" placeholder="Phone" name="NewProviderPhone" onInput={this.setValue('phone')}  />
      </Popup>
    )
  }
}

export default ModalNewProvider;

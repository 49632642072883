import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { parse } from 'query-string';
import isEqual from 'lodash/isEqual';

import { useLocation, useParams } from 'react-router-dom';
import { useUserContext } from 'Context/UserContext.js';
import { useStorage } from 'Hooks';

import { filterOptionsMapper } from './QuoteManagerMapper.js';
import { decodeURIComponentSafe } from 'Utils/strings.js';
import { STORAGE_KEYS } from 'Utils/const.js';

import { getQuoteFilterOptions } from 'Actions/ccm/api.js';

export const useQuoteManagerFilter = props => {
  const { onChangeFilter } = props;

  // HOOKS
  const user = useUserContext();
  const { search } = useLocation();
  const { folderName } = useParams();

  const abortController = useRef(new AbortController());
  const [ isFetching, setIsFetching ] = useState(false);

  // -- Controlled values and options for Lookups
  const [ values, setValues ] = useState();
  const [ options, setOptions ] = useState();

  // -- Parsed query string values used to compare and set 
  const [ filterValues, setFilterValues ] = useState();
  
  // -- Controlled value of checkbox for external users to view all org users
  const [ showAllOrgUsers, setShowAllOrgUsers ] = useStorage(STORAGE_KEYS.QUOTE_MANAGER_ALL_ORG_USERS);


  // METHODS
  // -- Handler for filter changes updates url
  const handleFilterChange = useCallback(({ folderName, ...changeProps }) => {
    onChangeFilter(changeProps);
  }, [ onChangeFilter ]);


  // -- 
  const updateLookups = useCallback((update, retainFolderName) => {
    // update selected lookup option values
    setValues(prev => {
      const newValues = { ...prev, ...update };
      
      handleFilterChange({ values: newValues, showAllOrgUsers, retainFolderName });
      return newValues;
    });
  }, [ handleFilterChange, showAllOrgUsers ]);

  const updateShowAllUsers = useCallback((e) => {
    const orgId = e.target.checked ? user.organization_id : 0;

    setShowAllOrgUsers(orgId);

    if (orgId) { // Clear out selected user if showing all org users
      setValues(prev => ({ ...prev, user: null }));
    }

    handleFilterChange({ lookupValues: values, showAllOrgUsers: orgId });
  }, [ setShowAllOrgUsers, handleFilterChange, values, user.organization_id ]);
  
  //
  // onChange handler passed to filter form controls
  //
  const onChange = useMemo(() => {
    return {
      org: opt => updateLookups({ org: opt, user: null }, 0),
      user: opt => updateLookups({ user: opt }, 0),
      status: opt => updateLookups({ status: opt }, 1),
      region: opt => updateLookups({ region: opt }, 1),
      service: opt => updateLookups({ service: opt }, 1),

      // checkbox value to allow org manager to show all org users
      showAllUsers: e => updateShowAllUsers(e),
    }
  }, [ updateLookups, updateShowAllUsers ]);

  const setNewValues = useCallback((urlValues, opts) => {
    const findOption = type => opts[type]?.find(x => x.value === urlValues[type]) || null;
    const mergedUrlStatus = urlValues?.status
      ?.replace('manual,error', 'manual|error')
      .split(',')
      .map(x => x === 'manual|error' ? 'manual,error' : x)

    const newValues = {
      org: findOption('org'),
      user: opts?.user?.options?.find(x => x.value === urlValues.user),
      status: opts?.status?.filter(x => mergedUrlStatus?.includes(x.value)),
      region: findOption('region'),
      service: findOption('service'),
    };

    setValues(newValues);
  }, []);

  const updateFilterOptions = useCallback(async (urlValues) => {
    // cancel any previous fetch calls and pass alias for new signal
    abortController.current?.abort();
    const signal = abortController.current?.signal;

    // const { org, status, region, service, folderName } = urlValues;
    const { org } = urlValues;

    const userId = urlValues.user || (!showAllOrgUsers && org && user.id);

    const req = {
      user_id: userId || undefined,
      organization_id: (!userId && org) || undefined,
      // status__in: status?.split(','),
      // state_name: region?.length === 2 ? region : undefined, 
      // country_alpha_3: region?.length === 3 ? region : undefined,
      // folder_name: folderName || undefined,
      // service_type_key: service,
    };

    // 1. fetch new options
    setIsFetching(true);
    
    const resp = await getQuoteFilterOptions(req, { signal });
    const newOptions = filterOptionsMapper(resp);

    setIsFetching(false);

    setOptions(newOptions);

    // 2. set new lookup values from fetched options
    setNewValues(urlValues, newOptions);

  }, [ setNewValues, showAllOrgUsers, user.id ]);


  // onMount fetch quote filter options
  // useEffect(() => {
  //   const qs = queryParsify(location.search);

  //   // fetch options based on filter criteria 
  //   const opts = fetchFilterOptions(changeProps);

  //   // select option value
  //   setDefaultValues(qs, opts)

  //   // callback to fetch new quotes based on new filter criteria
  //   onChangeFilter(changeProps);
  
  //   // on Cleanup, cancel any filter option fetch calls
  //   return () => signal.abort();
  // }, [ fetchFilterOptions, location ])


  // onChange url search
  useEffect(() => {
    const { o, u, s, service, region } = parse(search);
    const folderNameDecoded = decodeURIComponentSafe(folderName);
    const urlFilter = { org: o, user: u, status: s, service, region, folderName: folderNameDecoded };
    const isNewFilter = !isEqual(filterValues, urlFilter);

    const userChanged = (u !== filterValues?.user)
    const orgChanged =  (o !== filterValues?.org) 
    const folderChanged = (folderNameDecoded !== urlFilter?.folderName)

    if (isNewFilter) {
      setFilterValues(urlFilter);
    }

    const fetchOptions = ((!filterValues && !isFetching) || userChanged || orgChanged || folderChanged)
    if (fetchOptions) {
      updateFilterOptions(urlFilter);
    }
  }, [ search, folderName, filterValues, updateFilterOptions, isFetching ]);

  // hook return object
  return {
    values,
    options,
    onChange,
    showAllOrgUsers,
    isFetchingOptions: isFetching,
  }
}
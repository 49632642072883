import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import CN from 'classnames';

import {
  pushToModalStack,
  popModalStack,
} from 'Actions';

import './Modal.scss';

const modalRoot = document.getElementById('modal');
/**
 * The Modal is used as a simple wrapper for modal components. It is not itself
 * exported from Molecules. It handles the area "around" a modal component:
 * dimming the background, event handlers, and keypresses.
 *
 * Please don't "hide" and "show" the modal with css visibility, display, etc.,
 * and this includes any components which uses Modals: Popups, Drawers, and so
 * on. It doesn't work! --Each Modal updates the global modal stack onMount;
 * when only props or style change, this doesn't happen. Instead, conditionally
 * render the modal element: `this.state.modalVisible && <Popup ...`.
 *
 * @param {function} close - the function that closes this modal. This doesn't
 * necessarily mean that the information within is dropped; it just means that
 * the modal is hidden.
 * @param {String | Object} className - additional classnames. See the
 * classnames package's docs for more info.
 */
class Modal extends Component {
  constructor(props) {
    super(props);

    // TODO: here's where we add the action

    this.close = this.close.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
    this.props.pushToModalStack(this.props.id);
    // Yes! We’re directly manipulating the DOM!
    document.body.classList.add('scroll-locked');
  }

  componentWillUnmount() {
    const { popModalStack, stack } = this.props;
    document.removeEventListener('keydown', this.handleKeyDown, false);
    popModalStack();

    if (stack?.length === 1) {
      document.body.classList.remove('scroll-locked');
    }
  }

  close(event) {
    // Remove self from modal stack, /then/ call props.close()
    this.props.close(event);
  }

  handleKeyDown(event) {
    // escape
    if (this.props.id === this.props.peek && event.code === 'Escape') {
      this.props.close(event);
    }
  }

  render() {
    const { children, className='', id } = this.props;

    const classNames = CN('modal-container', className, id && `modal-container-${id}`);

    (!id) && console.warn('Warning: Modal components should have `id`s. Modals without ids may render behind another modal and leak memory.');
    return (
      <ModalPortal>
        <div className={classNames}>
          {children}
        </div>
      </ModalPortal>
    );
  }
}

class ModalPortal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

const mapStateToProps = (state) => {
  const { stack, peek } = state.modalStack;
  return { stack, peek };
};

const mapDispatchToProps = dispatch => ({
  pushToModalStack: (id) => dispatch(pushToModalStack(id)),
  popModalStack: () => dispatch(popModalStack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

import { useState, useEffect } from 'react';

import { upsertQuote } from 'Actions/ccm/api.js';
import { useAnalytics, useConstraints } from 'Hooks';
import { isPFContext } from 'Utils/auth.js';
import { useUserContext } from 'Context/UserContext.js';

export const useCreateQuote = (props={}) => {
  const { defaultValues={}, quoteOptions, createQuoteParams={}, onSubmit } = props;

  const { trackEvent } = useAnalytics();
  const { setConstraints, validateConstraints } = useConstraints();

  const [isLoading, setIsLoading] = useState(!!quoteOptions);
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState({});
  const [formValues, setFormValues] = useState({ ...defaultValues });
  const user = useUserContext();
  
  const optionFilter = ({ value }) => !((value === 'none'));

  const updateForm = (name, value) => {
    setErrors({ ...errors, [name]: undefined });
    setFormValues({ ...formValues, [name]: value });
  }

  const updateCircuitId = (name, e) => updateForm(name, e.target.value);

  const updateFormWithConstraints = (name, value) => {
    const { port, bandwidth } = { ...formValues, [name]: value };
    const constraints = validateConstraints({ port, bandwidth });

    setErrors({ ...errors, port: undefined, bandwidth: undefined });
    setFormValues({ ...formValues, ...constraints.selections });
    setOptions({ ...options, ...constraints.options });
  }

  // Form change methods
  const onChange = {
    currency: val => updateForm('currency', val),
    locationA: val => updateForm('locationA', val),
    locationZ: val => updateForm('locationZ', val),
    bandwidth: val => updateFormWithConstraints('bandwidth', val),
    port: val => updateFormWithConstraints('port', val),
    term: val => updateForm('term', val),
    ipv4: val => updateForm('ipv4', val),
    circuitId: e => updateCircuitId('circuitId', e),
  }

  // Validation before submit
  const validate = (fields) => {
    const validationErrors = {
      locationA: !fields.locationA && 'Please select a "From" location.',
      locationZ: fields.serviceRequirements !== 'internet' && !fields.locationZ && 'Please select a "To" location.',
      bandwidth: !fields.bandwidth && 'Please select a bandwidth speed.',
      port: !fields.port && 'Please select a port speed.',
      term: (!fields.term || !fields.term.length) && 'Please select a monthly term.',
    };

    if (isPFContext() || user.isPF) validationErrors.circuitId = !fields.circuitId && 'Please enter circuit id.';

    // Filter out falsey values and reassemble as object
    const formErrors = Object.entries(validationErrors)
      .filter(([, val]) => !!val)
      .reduce((acc, [key, val]) => { acc[key] = val; return acc; }, {});

    const isValid = !Object.keys(formErrors).length;
    
    setErrors(formErrors);
    return isValid;
  }

  const getValues = () => {
    const val = (fields={}) => (fields && fields.value ? fields.value : null);
    const locVal = (loc) => {
      if (!loc || ['leastCost', 'internet'].includes(loc.value)) return null;
      if (loc.url)  return { data_center_url: loc.url };
      if (loc.id)   return { id: loc.id, additional_details: loc.additionalDetails }
      return loc;
    }

    const { locationA, locationZ, port, bandwidth, term, ipv4, circuitId, serviceClass, currency } = formValues;
    
    return {
      locationA: locVal(locationA),
      locationZ: locVal(locationZ),
      port: val(port),
      bandwidth: val(bandwidth),
      term: (term || []).map(val),
      ipv4: val(ipv4),
      circuitId,
      serviceClass,
      currency: val(currency),
      ...createQuoteParams
    };
  }

  const submit = () => {
    const values = getValues();
    const isValid = validate(values);
    
    if (!isValid) return;

    fetchCreateQuote(values);
  }

  const fetchCreateQuote = (values) => {
    setIsLoading(true);
    upsertQuote(values).then(resp => {
      trackEvent({ category: 'Submit Order', action: 'click' });
      onSubmit(resp);
      setIsLoading(false);
    });
  }

  const getLookupProps = name => ({
    name, 
    onChange: onChange[name], 
    error: errors[name],
    options: options[name],
    value: formValues[name],
    icon: errors[name] && 'error'
  });

  const lookupProps = ['currency', 'locationA', 'locationZ', 'bandwidth', 'port', 'term', 'ipv4', 'circuitId']
    .reduce((acc, name) => ((acc[name] = () => getLookupProps(name)), acc), {});

  useEffect(function setQuoteConstraints() {
    if (quoteOptions) {
      const constraints = setConstraints(quoteOptions, optionFilter);
      constraints.term = constraints.term
        .filter(x => x.value !== "1") // Hide month-to-month option
      setOptions(constraints);
      setIsLoading(false);
    }
  }, [quoteOptions]);

  return { lookupProps, onChange, errors, options, validate, submit, isLoading, values: {...formValues}, updateForm };
}

import React from 'react'
import Lookup from './Lookup.jsx';

const BandwidthDropdown = (props) => {
  const { label="Bandwidth", ...otherProps } = props;

  return (
    <Lookup name="bandwidth" label={label} {...otherProps} />
  );
};

export default BandwidthDropdown;
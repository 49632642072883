import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { arrayOf, shape, string } from 'prop-types';
import { Icon } from 'Atoms';

import './Breadcrumbs.scss';

const Breadcrumb = ({text, link}) => {
  const crumb = link ? (
    <Fragment>
      <Link to={link} title={text}>{text}</Link>
      <Icon color="inherit" name="caret-next" />
    </Fragment>
    ) : <span title={text}>{text}</span>;

    return <li className="breadcrumb">{crumb}</li>;
}

const Breadcrumbs = ({crumbs=[]}) => {
  return (
    <ul className="breadcrumbs">
      {crumbs.map((crumb, i) => <Breadcrumb key={`crumb-${i}`} {...crumb}/>)}
    </ul>
  );
}

Breadcrumbs.propTypes = {
  crumbs: arrayOf(shape({
    text: string,
    link: string
  }))
}

export default Breadcrumbs;
import React from 'react';
import classNames from 'classnames';

import './badge.scss';

/**
 * Creates a nice-looking badge. This is typically for numbers, e.g., ① ② ⑨
 * etc., like the badges used in application notifications. In general, you're
 * able to add a `<Badge value={fooCount} />` anywhere and expect it to slot in
 * nicely in the context. Note that if `fooCount` were 0, the badge wouldn't
 * show--there's nothing to notify about. If do you want to show 0's, set the
 * `showZero` prop.
 *
 * Note that Badges inherit the color of their parents; if you want to change
 * the color of a badge's "background," set the `color` property of the style
 * prop.
 *
 * @param {string | object} className - additional classes
 * @param {Boolean} inverted - shows dark on light, with an outline, rather
 * than the default of white on status-color
 * @param {Boolean} showZero - when value == 0, we'd otherwise not show it.
 * @param {string} status - the status--an easy shorthand for color that
 * matches the API for StatusDot
 * @param {Object} style - a style object to add special styling
 * @param {number | string} value - the contents of the badge
 */
const Badge = (props) => {
  // TODO: move these colors from css into _variables
  // TODO: showZero

  // TODO: need a background "image" or color that inherits from the text color
  // hand-written svg?
  const {
    className,
    inverted,
    showZero,
    status,
    style,
    value,
  } = props;

  // don't show a badge if the value is zero
  if (value === 0 && !showZero) {
    return null;
  }

  return (
    <div
      className={classNames(
      'badge',
      { inverted },
      className,
      status,
      )}
      style={style}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2 2"
        className="background"
        preserveAspectRatio="none"
      >
        <rect width="2" height="2" />
      </svg>
      <span className="value">{value}</span>
    </div>
  );
};

export default Badge;

import React, { Component } from 'react';
import { Icon, LoadingOverlay } from 'Atoms';
import { Popup } from 'Molecules';
import { withUserContext } from 'Context/UserContext.js';

import { getFolders, updateQuote } from 'Actions/ccm/api.js';

import './MoveQuoteModal.scss';

class MoveQuoteModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      folders: [],
      selectedUrl: null,
      isFetching: false
    }

    this.moveQuote = this.moveQuote.bind(this);
    this.renderFolderOption = this.renderFolderOption.bind(this);
  }

  componentDidMount() {
    const request = { owner_id: this.props.userId || this.props.user.id };

    // TODO: Handle errors
    this.setState({ isFetching: true })
    getFolders(request).then(({ results=[{}], error }) => {

      const foundIndex = results.findIndex(({ name }) => name === '.');
      if (foundIndex > -1) {
        results[foundIndex].name = 'Quote Manager Home';
        results[foundIndex].icon = 'home';
        results.splice(0, 0, results.splice(foundIndex, 1)[0]);
      }

      this.setState({ folders: results, isFetching: false });
    });
  }

  renderFolderOption(folder={}) {
    const { url, name, id, icon='folder-large' } = folder;
    const selected = (url && url === this.state.selectedUrl ? '--selected' : '');
    const className = `selectable-folder-item ${selected}`;
    const onClick = () => {
      const unSelecting = (this.state.selectedUrl === url);
      const selectedUrl = (unSelecting ? null : url);
      const selectedName = (unSelecting ? null : name);

      this.setState({ selectedUrl, selectedName });
    };

    return (
      <li className={className} onClick={onClick} key={id}>
        <Icon name={icon} large />{name}
      </li>
    );
  }

  moveQuote() {
    const { selectedUrl } = this.state;
    const { type = 'quote', id, onConfirm } = this.props;

    if (type === 'quote' && id && selectedUrl) {
      this.setState({ isFetching: true });
      updateQuote(id, { folder_url: selectedUrl }).then(onConfirm);
    }
  }

  render() {
    const { onClose } = this.props;
    const { folders, selectedUrl, selectedName, isFetching } = this.state;
    const label = `Move Quote into Folder: ${ selectedName || '...' }`;

    const yesButton = {
      label: 'Move',
      onClick: (event) => {
        // TODO: Handle errors
        if (!selectedUrl) return;
        this.moveQuote();
      },
      primary: true,
      disabled: !selectedUrl || !!isFetching
    };

    const noButton = {
      label: 'Cancel',
      onClick: onClose
    };

    return (
      <Popup label={label} onClose={onClose} button1={noButton} button2={yesButton} id="move-quote-modal">
        <div>
          { isFetching && <LoadingOverlay /> }

          <ul className="selectable-folder-items">
            { folders.map(this.renderFolderOption) }
          </ul>
        </div>
      </Popup>
    )
  }
}

export default withUserContext(MoveQuoteModal);
import React, { Component } from 'react';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Button from 'antd/es/button';
import Tag from 'antd/es/tag';
import { Icon } from 'Atoms';

import 'antd/lib/dropdown/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/tag/style/index.css';
import './dropdown.scss';

export default class TaggedDropdown extends Component {
  addTag(event) {
    const { input } = this.props;
    const tags = input.value || [];
    if (!tags.includes(event.key)) {
      input.onChange([...tags, event.key]);
    }
  }

  removeTag(tag) {
    const { input } = this.props;
    const tags = input.value;
    input.onChange(tags.filter(t => t !== tag));
  }

  createMenu() {
    return (
      <Menu onClick={(event) => { this.addTag(event); }}>
        {this.props.options.map(option => (
          <Menu.Item key={option}>{option}</Menu.Item>
        ))}
      </Menu>
    );
  }

  renderTags() {
    const { input } = this.props;
    const tags = input.value || [];
    return tags.map(tag => (
      <div className="generated-tag" key={`${tag}-tag`}>
        <Tag onClick={() => { this.removeTag(tag); }}>
          {tag} <Icon name="close" title="Edit First Name" />
        </Tag>
      </div>
    ));
  }

  render() {
    return (
      <div className="tagged-dropdown">
        <Dropdown overlay={this.createMenu()} trigger={['click']}>
          <Button>
            {this.props.label}
            <Icon
              name="caret-down"
              className="dropdown-direction"
              role="decorative"
            />
          </Button>
        </Dropdown>
        <div className="tag-list">
          {this.renderTags()}
        </div>
      </div>
    );
  }
}

import React from 'react';
import * as esPopConfirm from 'antd/es/popconfirm';

import 'antd/lib/popover/style/index.css';
import 'antd/lib/button/style/index.css';

const PopConfirm = ({ okText="Confirm", cancelText="Cancel", ...props }) => (
  <esPopConfirm.default okText={okText} cancelText={cancelText} {...props} />
)

export default PopConfirm;
import React, { useEffect, useState, useRef } from 'react';
import { Button, Icon, SortableTable } from 'Atoms';
import { ModalManager, MODALS } from 'Molecules';
import { addOrganizationNotifications, getOrganizationNotifications, removeOrganizationNotifications } from 'Actions/ccm/api.js';

import './AdminOrganizationNotifications.scss';

const AdminOrganizationNotifications = props => {
  const { organizationId } = props;
  const [ users, setUsers ] = useState([]);

  const fetchData = () => {
    getOrganizationNotifications(organizationId)
      .then(({ allNotificationUserDetails }) => {
        setUsers(allNotificationUserDetails)
      });
  }

  useEffect(fetchData, [organizationId])

  const modalRef = useRef();

  const addUser = (selection) => {
    const user = selection.data;
    addOrganizationNotifications(organizationId, {
      allNotificationUserEmails: [user.email]
    }).then(({ error }) => {
      if (error) {
        const errMsg = error?.all_notification_user_emails?.join('\n')
          || 'An Unknown error occurred and the user was not added';

        alert(errMsg);
        // Just in case if there was a concurrent modification
        fetchData();
        return;
      }
      alert('User Added');
      setUsers([ ...users, user ]);
    });
  }

  const removeUser = (user) => {
    removeOrganizationNotifications(organizationId, {
      allNotificationUserEmails: [user.email]
    }).then(({ error }) => {
      if (error) {
        if (error.all_notification_user_emails?.[0]?.includes('is not marked as an all notification user')) {
          // Concurrent modififcation happened.  This is okay though.
          alert('User Removed');
          fetchData();
          return;
        }
        alert('An Unknown error occurred and the user was not removed');
        return;
      }
      alert('User Removed');
      setUsers(users.filter((u) => u.id !== user.id));
    });
  }

  const openAddUserModal = () => {
    modalRef.current.open(MODALS.SELECT_USERS, { 
      className: 'admin-organization-notifications', organizationId, onConfirm: addUser 
    });
  }

  const columnData = [
    { key: 'icon', header: ' ', sortable: false, render: () => <Icon name="avatar" /> },
    { key: 'name', header: 'Name' },
    { key: 'email', header: 'Email' },
    { key: 'remove', header: ' ', sortable: false,
      render: x => <Button icon="subtract-circle-filled" type="inline" onClick={() => removeUser(x)} />
    },
  ]

  return (
    <div className="admin-organization-notification">
      <div className="notifications-description">
        <h4>Pricing Notifications</h4>
        <div className="notifications-groups-subheader">Users will receive all organization pricing and order updates.</div>
      </div>
      {(users.length) 
        ? <SortableTable className="sortable-table__all-email-notification-users" columnData={columnData} rowData={users} defaultSort="name" />
        : <span className="all-email-notification-no-users">No users selected</span>
      }
      <div className="all-email-notification-add-user">
        <Button onClick={openAddUserModal}>Add User</Button>
      </div>
      <ModalManager ref={modalRef} />
    </div>
  )
}

export default AdminOrganizationNotifications;

import React, { Component, createRef } from 'react';
import CN from 'classnames';
import { createFilter } from 'react-select';

import { withUserContext } from 'Context/UserContext.js';
import { Button, Icon, LoadingOverlay, Notification } from 'Atoms';
import { Drawer } from 'Molecules';
import { CustomerLookup, FolderLookup } from 'Molecules';
import PartnerPricing from 'Widgets/CreateQuote/PartnerPricing.jsx';

import { importQuotes, getImportTemplate, getSystemStatusNotification } from 'Actions/ccm/api.js';
import { withAnalytics } from 'Hooks';

import './ImportQuotesModal.scss';

class ImportQuotesModal extends Component {
  constructor(props) {
    super(props);

    const partnerDataRequired = props.user.is_csa || props.user.is_sales_representative;

    this.state = {
      isLoading: false,
      downloadingTemplate: false,
      customer: null,
      folder: props.folder,
      fileName: '',
      partnerType: null,
      partner: null,
      csaRep: null,
      salesRep: null,
      errors: {},
      partnerDataRequired,
      showPartnerData: !!partnerDataRequired,
      outageNotification: false,
      noBid: false
    }

    this.getErrors = this.getErrors.bind(this);
    this.isValid = this.isValid.bind(this);

    this.handleImport = this.handleImport.bind(this);
    this.onChangeCustomer = this.onChangeCustomer.bind(this);
    this.onChangeFolder = this.onChangeFolder.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onTemplateDownload = this.onTemplateDownload.bind(this);

    this.togglePartnerData = this.togglePartnerData.bind(this);
    this.onUpdatePartnerData = this.onUpdatePartnerData.bind(this);

    this.fileInput = createRef();
  }

  componentDidMount() {
    getSystemStatusNotification().then(outageNotification => this.setState({ outageNotification }));
  }

  getErrors() {
    const { customer, fileName, folder, salesRep, partnerType, partnerDataRequired } = this.state;

    return {
      customer: !customer && 'Customer name is required.', 
      fileName: !fileName && 'Import file is required.', 
      folder: !folder && 'Folder is required.',
      upload: null, // Clear upload error on new submit
      partnerData: !!partnerDataRequired && (!partnerType || !salesRep) && 'Please provide required opportunity details to ensure accurate pricing and reporting.',
    };
  }

  togglePartnerData() {
    this.setState({ showPartnerData: !this.state.showPartnerData });
  }

  onUpdatePartnerData(data) {
    const { partnerType, csaRep, salesRep, noBid } = data;
    const { partnerData, ...errors } = this.state.errors || {};

    this.setState({
      partnerType,
      partner: data.partner ? data.partner.id : undefined,
      csaRep,
      salesRep,
      errors,
      noBid,
    });
  }

  isValid() {
    const errors = this.getErrors();
    const hasErrors = Object.values(errors).some(val => !!val);

    if (hasErrors) {
      this.setState({ errors });
    }

    return !hasErrors;
  }

  handleImport() {
    const { errors, folder, customer, partner, partnerType, csaRep, salesRep, noBid } = this.state;
    const importFailMsg = 'There was an error uploading your file.';

    const setErrors = (val) => ({ ...errors, upload: val });

    if (!this.isValid()) return;

    const request = {
      folder: folder.id,
      customer,
      partner,
      partnerType,
      csaRep,
      salesRep,
      noBid,
      file: this.fileInput.current.files[0],
    };

    this.setState({ isLoading: true, errors: setErrors(null) });
    this.props.trackEvent({ category: 'Import Quotes', action: 'click' });

    importQuotes(request)
      .then(({ error }) => {

        (error) 
          ? this.setState({ isLoading: false, errors: setErrors(importFailMsg) })
          : this.setState({ isLoading: false }, () => this.props.onConfirm(folder))
      });
  }

  onChangeCustomer(option) {
    const errors = { ...this.state.errors, customer: null };
    this.setState({ customer: option.id, errors });
  }

  onChangeFolder(option) {
    const errors = { ...this.state.errors, folder: null };
    this.setState({ folder: option, errors });
  }

  onInputChange(event) {
    const fileName = event.target.files[0].name;
    const errors = { ...this.state.errors, fileName: null };

    this.setState({ fileName, errors });
  }

  onTemplateDownload() {
    const fileName = `Quote-Import-Template.xlsx`;

    this.setState({ downloadingTemplate: true });
    return getImportTemplate(fileName).then(() => {
      this.setState({ downloadingTemplate: false });
    });
  }

  render() {
    const { fileInput, togglePartnerData, onUpdatePartnerData, onTemplateDownload, onChangeCustomer, onInputChange, handleImport } = this;
    const { onClose, ownerId, user } = this.props;
    const { folder, isLoading, downloadingTemplate, fileName, errors, showPartnerData, outageNotification, partnerDataRequired } = this.state;
    //const filterOption = (candidate, input) => ((candidate.data.name !== '.') && createFilter()(candidate, input));

    const isInternalUser = !!user.is_internal;
    const partnerPricingErrors = { partnerData: errors.partnerData };

    const drawerProps = {
      label: 'Import Quotes', 
      onCloseOverlay: onClose, 
      button1: { label: 'Cancel', onClick: onClose, secondary: true },
      button2: { label: 'Import', onClick: handleImport, primary: true, disabled: isLoading },
      id: 'importQuotes',
      className: 'import-quotes-modal'
    };

    const folderDropdownProps = {
      label: "Import into Folder",
      ownerId: ownerId || user.id,
      onChange: this.onChangeFolder,
      defaultValue: folder ? { value: folder } : undefined,
      error: errors.folder,
    };

    const classNames = {
      toggler: CN('toggler button-link', { 'toggler--collapse': showPartnerData }),
      toggled: CN('toggleable partner-data-wrapper', { 'toggleable--collapsed': !showPartnerData }),
      fileImportSection: CN('import-quotes-modal-section', { 'import-quotes-modal-section--error': !!errors.fileName })
    };

    const importFileLabel = fileName || (!errors.fileName 
      ? 'No file chosen' 
      : <span className="import-file-label__error">{errors.fileName}</span>
    );

    return (
      <Drawer {...drawerProps}>
        <div className="scrollable-content">
          {!!outageNotification && (
            <Notification.Warning children={outageNotification} />
          )}
          { !!isInternalUser && (
            <div className="partner-data-container">
              {!partnerDataRequired && (
                <Button onClick={togglePartnerData} className={classNames.toggler}>
                  Opportunity Details <Icon name="caret-down" />
                </Button>
              )}
              <div className={classNames.toggled}>
                <PartnerPricing updatePartnerData={onUpdatePartnerData} errors={partnerPricingErrors} />
              </div>
            </div>
          )}

          <div className="import-data-container">
            <CustomerLookup onChange={onChangeCustomer} error={errors.customer} organizationId={user.organization_id} />
            <FolderLookup {...folderDropdownProps} />

            <div className="import-quotes-modal-section-title">Attach File</div>
            <div className={classNames.fileImportSection}>
              <Button className="import-button" secondary onClick={() => fileInput.current.click()} children="Choose File" />
              <div className="import-file-label">{importFileLabel}</div>
              <input type="file" accept=".xlsx, .xls, .csv" ref={fileInput} onInput={onInputChange} hidden />
            </div>

            <div className="import-quotes-modal-section-title">Download Import Template File</div>
            <div className="import-quotes-modal-section">
              <Button className="download-template-button" type="inline" onClick={onTemplateDownload} disabled={downloadingTemplate} icon="spreadsheet" children="Quote-Import-Template.xlsx" />
              { !!downloadingTemplate && <LoadingOverlay /> }
            </div>

            { !!isLoading && <LoadingOverlay children='Uploading your import.' /> }
          </div>
        </div>
      </Drawer>
    );
  }
}

export default withAnalytics(withUserContext(ImportQuotesModal));

import { useState } from 'react';
import has from 'lodash/has';
import set from 'lodash/set';

import { constrainPortAndBandwidth } from 'Utils/ccm.js';
import { toIpv4Option } from 'Utils/formatter.js';

export const useConstraints = () => {
  const [constrainedOptions, setConstrainedOptions] = useState({});
  
  // setConstraints: arrange the quoteOptions into a lookup object and returns lookup options for UI to consume
  // { optionType: { optionTypeValue: { option } } }
  const setConstraints = (quoteOptions, optionFilter = ()=>1) => {
    let _constrainedOptions = {};

    quoteOptions.filter(optionFilter)
      .forEach(quoteOption => {
        set(_constrainedOptions, [quoteOption.optionType, quoteOption.value], quoteOption);
      });

    setConstrainedOptions(_constrainedOptions);

    return { ...getOptions(_constrainedOptions) };
  }

  // getOptions: Sort and map to options for dropdowns
  const getOptions = (opts) => {
    const bySortVal = (a, b) => ((a.sortVal < b.sortVal) ? -1 : (a.sortVal > b.sortVal) ? 1 : 0);
    const toOption = ({ label, value, sortVal }) => ({ label, value, sortVal });

    const options = Object.entries(opts)
      .map(([key, values]) => ({ 
        [key]: Object.values(values).sort(bySortVal).map(toOption)
      }))
      .reduce((acc, item) => {
        const [key, val] = Object.entries(item)[0];
        acc[key] = val; 
        return acc;
      }, {});
    
    options.ipv4 = options.ipv4_addresses.map(toIpv4Option);
    delete options.ipv4_addresses;

    return options;
  }
    
  const validateConstraints = (props) => {
    // additional rules can be added to return a merged object
    const { port, bandwidth } = props;
    const options = getOptions(constrainedOptions);

    return constrainPortAndBandwidth({ port, bandwidth, options });
  }

  const hasOption = (name) => has(constrainedOptions, name);

  return { setConstraints, validateConstraints, hasOption }
}
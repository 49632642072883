import React, { useEffect, useState } from 'react';
import { Checkbox, LoadingOverlay } from 'Atoms';
import { Popup, UserLookup } from 'Molecules';

import { useUserContext } from 'Context/UserContext';
import { getQuoteRequirements, transferQuote } from 'Actions/ccm/api.js';
import { apiUrlIdStripper } from 'Utils/ccm.js';

import './TransferQuoteModal.scss';

const TransferQuoteModal = props => {
  const { onConfirm, onClose, id, reference, organization, organizationKey } = props;
  const [ selectedUser, setSelectedUser ] = useState();
  const [ isFetching, setIsFetching ] = useState(false);
  const [ sendEmail, setSendEmail ] = useState(false);
  const [ ownerId, setOwnerId ] = useState(props.ownerId);

  const user = useUserContext();
  const orgFilter = user.is_pricing_desk ? undefined : (organizationKey || organization); 
  const includeInternal = user.is_pricing_desk ? undefined : true;

  const onChangeUser = (opts) => setSelectedUser(opts);
  const onChangeNotify = e => setSendEmail(e.target.checked);

  const handleSubmit = async () => {
    setIsFetching(true);
    const resp = await transferQuote(id, selectedUser.value, sendEmail);
    setIsFetching(false);

    return (!resp.error) 
      ? onConfirm?.({ ...resp, transferredTo: selectedUser.label, reference })
      : alert('There was an error transferring the quote. Please contact support');
  }

  useEffect(() => {
    if (!ownerId && id) {
      getQuoteRequirements(id).then((requirements={}) => {
        const id = +apiUrlIdStripper(requirements.userUrl || '');
        setOwnerId(id)
      })
    }
  }, [ownerId, id])

  const isOptionDisabled = opt => ownerId && opt.value === ownerId;

  const noButton = { label: 'Cancel', onClick: onClose };
  const yesButton = { label: 'Confirm', onClick: handleSubmit, primary: true, disabled: isFetching || !selectedUser };

  return (
    <Popup label="Transfer ownership of this quote" 
      onClose={onClose} 
      button1={noButton} 
      button2={yesButton} 
      id="transfer-quote-modal"
    >
      { isFetching && <LoadingOverlay /> }
      <UserLookup label="Select user" name="user-transfer" onChange={onChangeUser} managedUsers={false} organization={orgFilter} isOptionDisabled={isOptionDisabled} menuPosition="fixed" includeInternal={includeInternal} />
      <br />
      <Checkbox label="Send email update to new user" onChange={onChangeNotify} checked={sendEmail} />
    </Popup>  
  );
}

export default TransferQuoteModal;
import React, { useEffect, useRef } from 'react';
import { Lookup } from 'Molecules';
import './RelatedDesignsFilter.scss';

const M_TO_MILES = 1609.35;
const OPTIONS = {
  bandwidth: [
    { label: '1 Mbps', value: 1, },
    { label: '10 Mbps', value: 10, }, 
    { label: '25 Mbps', value: 25, }, 
    { label: '50 Mbps', value: 50, },
    { label: '100 Mbps', value: 100, },
    { label: '250 Mbps', value: 250, }, 
    { label: '500 Mbps', value: 500, }, 
    { label: '1 Gbps', value: 1000, }, 
    { label: '2.5 Gbps', value: 2500, },
    { label: '5 Gbps', value: 5000, },
    { label: '10 Gbps', value: 10000, },
    { label: '100 Gbps', value: 100000, },
  ],
  radius: [
    { label: 'at locations', value: 10 },
    { label: 'within a mile', value: 1 * M_TO_MILES },
    { label: 'within 10 miles', value: 10 * M_TO_MILES },
    { label: 'within 25 miles', value: 25 * M_TO_MILES },
    { label: 'within 50 miles', value: 50 * M_TO_MILES },
    { label: 'within 100 miles', value: 100 * M_TO_MILES },
    { label: 'anywhere', value: -1 },
  ],
  maxAge: [
    { label: '7 days', value: 7, },
    { label: '30 days', value: 30, },
    { label: '90 days', value: 90, },
    { label: '180 days', value: 180, },
    { label: '365 days', value: 365, },
    { label: 'Anytime', value: 9999 },
  ],
  service: [
    { label: 'Ethernet', options: [
      { label: 'Ethernet', value: 'ethernet' },
    ]},
    { label: 'Internet', options: [
      { label: 'Internet', value: 'internet', },
      { label: 'Broadband', value: 'internet-broadband', },
      { label: 'DIA', value: 'internet-dedicated-access', },
      { label: 'Unitas IP', value: 'internet-unitas-ip', },
    ]}
  ],
};

const RelatedDesignsFilter = props => {
  const { 
    className='', 
    locationRadius, setLocationRadius,
    minBandwidth, maxBandwidth, 
    setMinBandwidth, setMaxBandwidth,
    maxAge, setMaxAge,
    service, setService=()=>{},
  } = props;

  const values = useRef({});
  const findOption = (arr, val) => val ? arr.find(x => x.value === val) : null;

  useEffect(() => {
    values.current = {
      minBandwidth: findOption(OPTIONS.bandwidth, minBandwidth),
      maxBandwidth: findOption(OPTIONS.bandwidth, maxBandwidth),
      maxAge: findOption(OPTIONS.maxAge, maxAge),
      radius: findOption(OPTIONS.radius, (locationRadius || -1)),
      service: findOption(OPTIONS.service.flatMap(x => x.options), service),
    };
  }, [minBandwidth, maxBandwidth, maxAge, locationRadius, service]);

  const minBandwidths = OPTIONS.bandwidth.filter(x => x.value <= maxBandwidth);
  const maxBandwidths = OPTIONS.bandwidth.filter(x => x.value >= minBandwidth);

  return (
    <div className={`related-designs-filter ${className}`}>
      <Lookup options={OPTIONS.service} value={values.current.service} onChange={x => setService(x.value)} name="service-type" />
      <span>type</span>
      <Lookup options={OPTIONS.radius} value={values.current.radius} onChange={x => setLocationRadius(x.value)} name="location-radius" />
      <span>between</span>
      <Lookup options={minBandwidths} value={values.current.minBandwidth} onChange={x => setMinBandwidth(x.value)} name="min-bandwidth" />
      <span>and</span>
      <Lookup options={maxBandwidths} value={values.current.maxBandwidth} onChange={x => setMaxBandwidth(x.value)} name="max-bandwidth" />
      <span>from the past</span>
      <Lookup options={OPTIONS.maxAge} value={values.current.maxAge} onChange={x => setMaxAge(x.value)} name="quote-age" />
    </div>
  );
}

export default RelatedDesignsFilter;
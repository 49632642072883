import React, { Children, cloneElement } from 'react';
import CN from 'classnames';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import { Icon, Button } from 'Atoms';

import 'react-contexify/dist/ReactContexify.min.css';
import './ContextMenu.scss';

const ContextMenuItem = (props) => {
  const { onClick=()=>{}, icon, label, hidden, disabled=false, isSelected, className='' } = props;

  return (hidden ? null : (
    <Item onClick={onClick} disabled={disabled} className={className} isSelected={isSelected}>
      {!!icon && <Icon name={icon} />}
      {label}
    </Item>
  ))
}

// We need to pass this propsFromTrigger down to the children so that the
// callback on Item components can get the `data` passed to MenuProvider.
// The default Submenu ignores it when creating children.
const DataSubmenu = ({ nativeEvent, children, propsFromTrigger, selectable, ...rest }) => {
  const modifiedChildren = Children.map(
    Children.toArray(children).filter(child => Boolean(child)),
    // Important! Pass down propsFromTrigger.
    child => {
      const selected = (child.props.isSelected !== undefined) &&
        (typeof child.props.isSelected === 'function' 
          ? child.props.isSelected(propsFromTrigger) 
          : child.props.isSelected
        );
      const className = child.props.selectable
        ? CN(child.props.className, { 
            'react-contexify__item--selected': selected,
            'react-contexify__item--not-selected': !selected,
          })
        : child.props.className;

      const selectedIcon = selectable && (selected 
        ? <Icon name="checkmark" /> 
        : <span className="icon icon-blank" /> 
      );
        
      const newChildren = <>{selectedIcon || null}{child.props.children }</>;

      return selectable 
        ? cloneElement(child, { nativeEvent, propsFromTrigger, className }, newChildren)
        : cloneElement(child, { nativeEvent, propsFromTrigger, className })
    }
  );

  const arrow = <Icon name="caret-next" />

  return <Submenu {...rest} arrow={arrow} nativeEvent={nativeEvent} children={modifiedChildren} />
}

const ContextSubmenu = ({ submenu, icon, items=[{}], className='', position='left', selectable, hidden, disabled }) => {
  const cssName = CN(className, { 
    'react-contexify__submenu-wrapper--left': position === 'left',
    'react-contexify__submenu-wrapper--selectable': selectable,
  });
  const label = <>{!!icon && <Icon name={icon} />}{submenu}</>;

  return !hidden && (
    <DataSubmenu label={label} children={ items.map(toMenuChild) } className={cssName} selectable={selectable} disabled={disabled} />
  );
};

const toMenuChild = (item) => (
  item.submenu ? ContextSubmenu(item) : item.separator ? <Separator /> : ContextMenuItem(item)
);

export const ContextMenu = ({ id, className="", items=[{}], position="right" }) => {
  const classNames = CN(className, { 'context-menu--position-right': position === 'right' });

  return <Menu id={id} children={ items.map(toMenuChild) } className={classNames} animation="fade" />
};

export const ContextMenuButton = (props) => {
  const { icon='ellipsis', className='', menuId, onClick, children, ...data } = props;
  const onMenuButtonClick = event => {
    event.stopPropagation();
    onClick && onClick(props);
  };

  return (
    <span className={className} onClick={onMenuButtonClick}>
      <MenuProvider id={menuId} data={data} event='onClick'>
      { children || <Button className="button-transparent context-menu-button" icon={icon} /> }
      </MenuProvider>
    </span>
  );
};

import React from 'react'
import Lookup from './Lookup.jsx';

import { getTermLabel } from 'Utils/formatter.js';

const TermDropdown = ({ isMulti=true, ...props }) => {
  const formatMultiLabel = vals => getTermLabel((vals || []).map(x => x.value));

  return (
    <Lookup name="term" label="Term" 
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti} 
      isSearchable={false} 
      isClearable={false}
      formatMultiLabel={formatMultiLabel}
      multiSortKey="sortVal" 
      {...props} 
    />
  );
}

export default TermDropdown;
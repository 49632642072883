import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { useUserContext } from 'Context/UserContext.js';

import { Breadcrumbs, Button, ContextMenu, ContextMenuButton, Notification } from 'Atoms';
import { PricingDeskWidget } from 'Widgets';
import PricingDeskFilter from './PricingDeskFilter.jsx';
import { PRICING_DESK_TABS, stringifyFilterUrl } from './PricingDeskHelper.js';

import { updateQuote, getAssignableUsers } from 'Actions/ccm/api.js';
import { useModal } from 'Hooks';
import { PERMISSIONS } from 'Utils/const.js';
import { DefFavicon, DefFaviconName, PAGE_TITLE_NAME } from 'Utils/branding.js';

import '../page.scss';
import './PricingDesk.scss';

const CONTEXT_MENU = {
  BULK_ASSIGN_USER: 'context-menu-bulk-assign-user',
  BULK_ASSIGN_FOLLOWUP: 'context-menu-bulk-assign-followup',
};

const FOLLOWUP_CATEGORIES = ['Pending sales', 'Waiting on customer', 'Waiting on vendor'];

const PricingDeskPage = () => {
  const user = useUserContext();
  const history = useHistory();
  const { showModal, MODALS } = useModal();

  const { search } = window.location;

  const FILTERING_ENABLED = user.is_internal;

  // default useState values
  const hasApprovalRole = [
    'pricing_desk', 'network_engineering', 'csa'
  ].find(role => user.permissions.includes(`ccm.${role}_approve_design`));

  const qs = parse(search);
  const defaultFilter = (FILTERING_ENABLED 
    ? { ...qs, unapproved: qs.unapproved || hasApprovalRole }
    : {}
  );
  const defaultShowingFilter = FILTERING_ENABLED && !!Object.keys(qs).filter(x => x !== 'tab').length;
  const defaultTab = FILTERING_ENABLED ? (defaultFilter.tab || PRICING_DESK_TABS.QUOTES) : null;

  // useState
  const [ actionNotification, setActionNotification ] = useState(null);
  const [ showingFilter, setShowingFilter ] = useState(defaultShowingFilter);
  const [ selected, setSelected ] = useState([]);
  const [ filter, setFilter ] = useState(defaultFilter);
  const [ activeTab, setActiveTab ] = useState(defaultTab);
  const [ assignableUsers, setAssignableUsers ] = useState({});

  // useRef
  const widgetRef = useRef();
  const crumbs = useRef([{ text: 'Home', link: '/' }, { text: 'Pricing Desk' }]);


  // useEffect - onMount
  useEffect(() => { 
    getAssignableUsers().then(resp => setAssignableUsers(resp));
  }, []);

  // useEffect - replace url when filter or tab changes
  useEffect(() => {
    const { pathname, search } = window.location;
    const newHref = stringifyFilterUrl(filter, user.is_internal ? activeTab : undefined);

    if (newHref !== pathname + search) {
      history.replace(newHref)
    }
  }, [ filter, activeTab, history, user ])

  // Methods
  const fetchData = () => widgetRef.current?.fetchData();

  const setWidgetFetching = (isLoading) => widgetRef.current?.setState({ isLoading });

  const toggleFilter = () => setShowingFilter(!showingFilter);

  const onSelectItem = items => setSelected(items);

  const onUnselect = () => widgetRef.current.tableRef.current?.clearSelected();

  const onChangeFilter = newFilter => setFilter(newFilter);

  const onDeleteItem = ({ name }) => {
    setActionNotification(<span><b>{name}</b> has been deleted</span>);
    fetchData();
  }

  const onAssignUser = async (id, email) => {
    setWidgetFetching(true);
    await updateQuote(id, { assignee_email: email }).then(resp => resp.error
      ? alert(`Unable to assign quote to user: ${resp.error}`)
      : widgetRef.current?.updateRow(resp)
    );
    setWidgetFetching(false);
  }

  const onFollowupUpdate = async (id, followup) => {
    setWidgetFetching(true);
    await updateQuote(id, { follow_up_category: followup }).then(resp => resp.error 
      ? alert(`Unable to update followup for quote: ${resp.error}`) 
      : widgetRef.current?.updateRow(resp)
    );
    setWidgetFetching(false);
  }

  const onBulkAssignUser = email => {
    const assignUser = (id, email) => updateQuote(id, { assignee_email: email });
    setWidgetFetching(true);

    const bulkAssign = (selected || []).map(q => assignUser(q.id, email));
    Promise.all(bulkAssign).then(fetchData);
  }

  const onBulkAssignCategory = category => {
    const assignCategory = (id, category) => updateQuote(id, { follow_up_category: category });
    setWidgetFetching(true);

    const bulkAssign = (selected || []).map(q => assignCategory(q.id, category));
    Promise.all(bulkAssign).then(fetchData);
  }

  const onTabChange = (tab) => {
    onUnselect();
    setActiveTab(tab);
    setSelected([]);
  }

  const onClick = {
    assign: email => ({ props: { id, assignedEmail } }) => 
      onAssignUser(id, assignedEmail === email ? null : email),
    delete: showModal(MODALS.DELETE, { onConfirm: onDeleteItem }),
    deleteBulk: showModal(MODALS.DELETE_BULK, { onConfirm: fetchData, quotes: selected }),
    followup: followup => ({ props: { id, followupCategory } }) => {
      onFollowupUpdate(id, followupCategory === followup ? null : followup)
    },
    contact: showModal(MODALS.CONTACT_VENDOR, { selected }),
    comment: showModal(MODALS.BULK_COMMENT, { quotes: selected })
  };

  
  const isSelected = {
    assign: name => ({ assigned }) => assigned && assigned === name,
    followup: followup => ({ followupCategory }) => followup && followup === followupCategory,
  };
  
  const assignableUserItems = Object.entries(assignableUsers).map(([email, name]) => ({
    label: name, isSelected: isSelected.assign(name), onClick: onClick.assign(email), email
  }));
  
  const assignableFollowupItems = FOLLOWUP_CATEGORIES.map(label => ({
    label, isSelected: isSelected.followup(label), onClick: onClick.followup(label)
  }));
  
  const bulkAssign = {
    users: [
      { label: 'Unassigned', email: null },
      { separator: true },
      ...assignableUserItems,
    ].map(({ label, email, separator }) => ({ label, onClick: () => onBulkAssignUser(email), separator })
    ),
    followup: [
      { label: 'No Followup', onClick: () => onBulkAssignCategory(null) },
      { separator: true },
      ...FOLLOWUP_CATEGORIES.map(label => ({ label, onClick:() => onBulkAssignCategory(label) })),
    ],
  };
  
  const items = [
    { submenu: "Assign", icon: 'user', selectable: true, hidden: !user.is_pricing_desk, items: assignableUserItems },
    { submenu: 'Followup', icon: 'calendar', selectable: true, hidden: !user.is_pricing_desk, items: assignableFollowupItems },
    { label: 'Delete', icon: 'trash', onClick: onClick.delete },
  ];

  const filterClassName = showingFilter ? '' : '--collapsed';
  
  return (
    <section className="pricing-desk-page">
      <Helmet>
        <title>{PAGE_TITLE_NAME}: Pricing Desk</title>
        <link rel={DefFaviconName} type="image/png" href={DefFavicon} />
      </Helmet>

      { !!actionNotification && 
        <Notification.Success className="action-notification" children={actionNotification} duration={7500} /> 
      }

      <Breadcrumbs crumbs={crumbs.current} />
      <div className="page-content">
        <div className="header-wrap --sticky">
          <h1 className="page-header">Pricing Desk
            {!!activeTab && <span>: {activeTab}</span>}
          </h1>
          <div className="page-actions">
            {!!selected.length && (
            <>
              <BulkActionButton icon="subtract-circle-outline" onClick={onUnselect} label="Unselect" />
              <BulkActionButton icon="trash" onClick={onClick.deleteBulk} label="Delete" selected={selected.length} />
              
              {!!user.is_pricing_desk && (<>
                <ContextMenuButton menuId={CONTEXT_MENU.BULK_ASSIGN_USER}>
                  <BulkActionButton icon="user" label="Assign" selected={selected.length} />
                </ContextMenuButton>
                <ContextMenuButton menuId={CONTEXT_MENU.BULK_ASSIGN_FOLLOWUP}>
                  <BulkActionButton icon="calendar" label="Followup" selected={selected.length} />
                </ContextMenuButton>
                <BulkActionButton type="link" icon="envelope" label="Vendor ICB" selected={selected.length} onClick={onClick.contact} />
                {!!user.hasPermission(PERMISSIONS.BULK_COMMENT) && (
                  <BulkActionButton icon="comments" label="Comment" selected={selected.length} onClick={onClick.comment} />
                )}
              </>)}
            </>
            )}
            {!!FILTERING_ENABLED && ( 
              <Button type="link" icon="filter" onClick={toggleFilter}>Filter</Button>
            )}
          </div>

          {!!FILTERING_ENABLED && (
            <PricingDeskFilter className={filterClassName} onChangeFilter={onChangeFilter} defaultFilter={filter} tab={activeTab} />
          )}
        </div>
        <PricingDeskWidget innerRef={widgetRef} pageSize={50} isSelectable={true} onSelect={onSelectItem} onTabChange={onTabChange} activeTab={activeTab} filter={filter}/>
        <ContextMenu id="pricing-desk_menu" items={items} />
      </div>

      <ContextMenu id={CONTEXT_MENU.BULK_ASSIGN_USER} items={bulkAssign.users} className="pricing-desk__bulk-assign-menu" position="auto" />
      <ContextMenu id={CONTEXT_MENU.BULK_ASSIGN_FOLLOWUP} items={bulkAssign.followup} className="pricing-desk__bulk-assign-menu" position="auto" />
    </section>
  );
}

const BulkActionButton = ({ icon, label, selected, onClick }) => (
  <Button className="bulk-action-button" type="link" icon={icon} onClick={onClick}>
    <div className="bulk-action-wrap">
      <div className="bulk-action-button__label">{label}</div>
      {!!selected && <div className="bulk-action-button__details">{`Selected (${selected})`}</div> }
    </div>
  </Button>
);

export default PricingDeskPage;
import React from 'react';
import * as esAntTree from 'antd/es/tree';

import 'antd/lib/tree/style/index.css';
import './Tree.scss';

const AntTree = esAntTree.default;
const AntTreeNode = AntTree.TreeNode;

const Tree = props => {
  const { data=[], disabledKeys=[], ...treeProps } = props;

  const renderNodes = data => {
    return data.map(item => {
      const disabled = disabledKeys.includes(item.key) || item.disabled;
      const nodeProps = {
        ...item,
        selectable: false,
        disabled,
        disabledCheckbox: disabled,
        dataRef: item,
        children: item.children ? renderNodes(item.children) : null
      }

      return <AntTreeNode {...nodeProps} />;
    });
  }

  const children = renderNodes(data);

  return (
    <AntTree checkable autoExpandParent={true} {...treeProps} children={children} />
  );
}

export default Tree;
import React, { Component, createRef } from 'react'
import CN from 'classnames';
import { v4 as uuidV4 } from 'uuid';

import { Button, Icon, TextInput } from 'Atoms';
import { Lookup, MODALS, ModalManager } from 'Molecules';
import { 
  AddressOption,
  AdvancedSearchOption,
  DemarcOption,
  UnvalidatedOption, 
  UnvalidatedOnlyOption,
  LatLongOption,
} from './Options.jsx';

import { withUserContext } from 'Context/UserContext.js';

import { getAutocomplete, getPlaceDetails, getLatLongLocation } from 'Actions/ccm/api.js';
import { toLatLongOptions, toAutocompleteOption } from './LocationMapper.js';

import poweredByGoogleImg from 'Static/images/powered_by_google_on_white.png';
import './Location.scss';

const INPUT_MIN_LENGTH = 3;

class AddressDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: undefined,
      inputValue: '',
      error: null,
      options: [],
      sessionId: uuidV4(),
      searchType: null,
      allowAdvancedSearch: false && props.user.is_developer,
      floorSuiteCollapsed: true,
      floor: null,
      suite: null, 
    }

    // refs
    this.inputRef = createRef();
    this.modalRef = createRef();
    this.debouncer = null;

    // Methods
    this.onChange = this.onChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.getAutocompleteLocations = this.getAutocompleteLocations.bind(this);
    this.getLatLongLocations = this.getLatLongLocations.bind(this);
    this.noOptionsMessage = this.noOptionsMessage.bind(this);

    // Methods for createable/unvalidated location input
    this.handleCreate = this.handleCreate.bind(this);
    this.handleNewOptions = this.handleNewOptions.bind(this);
    this.formatCreateLabel = this.formatCreateLabel.bind(this);
    this.isValidNewOption = this.isValidNewOption.bind(this);
  }

  onChange(option={}, action={}) {
    const { skip_validation, isValidated, value, label, validationId, additional_details, isLatLong } = (option || {});
    const { allowDemarc, onChange } = this.props;

    const setValue = (opts={}) => this.setState({ 
      value: option, 
      inputValue: label,
      ...opts,
      ...(allowDemarc && { 
        floor: value && !option.data_center_url ? value.floor : undefined, 
        suite: value && !option.data_center_url ? value.suite : undefined,
      }), 
    }, () => this.inputRef.current.blur());

    if (option === null && action.action === 'clear') {
      onChange(null);
      this.setState({ value: option, inputValue: '', floor: null, suite: null });
    }

    else if (skip_validation) {
      onChange(option);
      setValue();
    }

    else if (isValidated || isLatLong) {
      onChange({ ...value, label, additional_details });
      setValue();
    }

    else if (validationId && this.state.inputValue.trim().length) {
      setValue({ isLoading: true });
      getPlaceDetails(validationId, this.state.sessionId).then(details => {

        const newOption = { ...details, label, additional_details };
        onChange(newOption);
        setValue({ ...newOption, isLoading: false });
      });
    }

    else {
      // HOW DO I EVEN GET HERE?
      // Manual Pricing location selection
      this.props.onChange(option);
    }
  }

  onInputChange(inputValue, { action }) {
    const { value } = this.state;
    
    if (action === 'input-blur') {
      this.setState({ inputValue: !value ? '' : value.label });
    }
    
    else if (action === 'input-change') {
      this.setState({ inputValue, error: null });
    }

    return;
  }

  loadOptions(inputValue='', callback) {
    const { isEcx, isPF, is_developer } = this.props.user;

    const latLongRegEx = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
    const isLatLong = latLongRegEx.test(inputValue);

    const handleCallback = opts => this.handleNewOptions(opts, callback);
    const fetchLookup = () => ((isLatLong && is_developer && !isEcx && !isPF)
      ? this.getLatLongLocations(inputValue).then(handleCallback)
      : this.getAutocompleteLocations(inputValue).then(handleCallback)
    );


    if (inputValue.length < INPUT_MIN_LENGTH) {
      this.debouncer = null;
      callback([]);
    } else {
      this.debouncer && clearTimeout(this.debouncer);
      this.debouncer = setTimeout(fetchLookup, 250);
    }
  }

  async getAutocompleteLocations(input) {
    const resp = await getAutocomplete(input, this.state.sessionId, this.props.includeHubs);
    return resp.map(toAutocompleteOption);
  }

  async getLatLongLocations(latLong) {
    const [ latitude, longitude ] = latLong.split(',');

    const { error, results } = await getLatLongLocation({ latitude, longitude });

    // 41.853165, -87.618468
    // 18.572776, 73.886655
    // 52.939694, -6.704741

    return error ? [] : toLatLongOptions(results)
  }

  formatCreateLabel(inputValue) {
    return (
      (this.state.allowAdvancedSearch)
        ? <AdvancedSearchOption /> :
      (this.state.options.length 
        ? <UnvalidatedOption inputValue={inputValue} />
        : <UnvalidatedOnlyOption inputValue={inputValue} />
      )
    );
  }

  handleNewOptions(options, callback) {
    this.setState({ options });
    callback(options);
  }

  isValidNewOption (inputValue, selectValue) {
    // Allow create location if not in colo mode, there is user input, and user input doesn't match existing value
    const selectedValue = (selectValue && selectValue[0]) || {};
    const isLeastCost = selectedValue.value === 'leastCost';

    const isValid = (
      !!inputValue 
      && inputValue.length >= INPUT_MIN_LENGTH
      && !isLeastCost 
      && (!selectedValue || (selectedValue.label !== inputValue))
    );

    return isValid;
  }

  noOptionsMessage({ inputValue='' }) {
    const { value } = this.state;

    //if (!inputValue.length) return 'Type an address...'
    return (
      (inputValue.length < INPUT_MIN_LENGTH)
        ? null : 
      (value && value.skip_validation) 
        ? this.formatCreateLabel() :
      (!value) 
        ? `No results found. Please verify the entry or try using the full address.`
        : null
    );
  }

  handleCreate(label) {
    const value = { label, value: label, string_address: label, skip_validation: true };

    this.state.allowAdvancedSearch 
      ? this.modalRef.current.open(MODALS.LOCATION_SEARCH, { onConfirm: ()=> alert('TODO: API to use advanced location fields...')})
      : this.inputRef.current.select.onChange(value, { action: 'select-option' });
  }

  componentDidMount() {
    const { defaultValue } = this.props;

    if (defaultValue) {
      this.inputRef.current.select.select.select.setValue(defaultValue);
      this.setState({ 
        inputValue: defaultValue.label, 
        value: defaultValue,
        floor: defaultValue.floor,
        suite: defaultValue.suite,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { value, onChange } = this.props;
    if (value !== prevProps.value) onChange(value);
  }

  render() {
    // TODO: discover why we are using prop.value... (This might be a good candidate for mergePropsToState)
    const { name, label, icon="location", value, allowInvalidInput=true, allowDemarc, placeholder, defaultOptions, isDisabled } = this.props;
    const { inputValue, isLoading, searchType, floorSuiteCollapsed, floor, suite } = this.state;
    const { onChange, onInputChange, loadOptions, inputRef, noOptionsMessage, handleCreate, formatCreateLabel, isValidNewOption } = this;
    
    // This allows colo label titles to be shown in value label while navigating colo submenus
    const editable = !!this.state.value;
    const error = this.props.error || this.state.error;

    const formatOptionLabel = (option, { context }) => {
      const showRecentFloorSuite = allowDemarc && !option.data_center_url && (option.floor || option.suite)

      return (
        (context === 'value') 
          ? option.label :
        // context==='menu'
        (option.isLatLong)
          ? <LatLongOption {...option} /> :
        (!option.data_center_url && showRecentFloorSuite)
          ? <DemarcOption {...option} />
          : <AddressOption {...option} />
        )
    }

    let options = {};
    if (value !== undefined) {
      options.value = value;
    }

    const showingFloorSuiteSelector = !!allowDemarc && !!this.state.value && !this.state.value.data_center_url;

    const classNames = {
      lookup: CN('lookup__address', { 'lookup__address--showing-floor-suite': showingFloorSuiteSelector }),
      toggler: CN('toggler button-link', { 'toggler--collapse': !floorSuiteCollapsed }),
      toggled: CN(`toggleable`, { 'toggleable--collapsed': floorSuiteCollapsed }),
      togglerText: 'floor-suite__toggler-text',
      floorSuite: CN('floor-suite', { 'floor-suite--expanded': !floorSuiteCollapsed }),
    }

    const togglerText = !allowDemarc ? null : (floor || suite) 
      ? [
        floor && (floor.toLowerCase().indexOf('floor') > -1 ? floor : `Floor ${floor}`), 
        suite && (suite.toLowerCase().indexOf('suite') > -1 ? suite : `Suite ${suite}`)
      ].filter(Boolean).join(', ') 
      : 'Add floor/suite'; 

    const toggle = () => this.setState({ floorSuiteCollapsed: !floorSuiteCollapsed });
    const onBlur = e => !e.currentTarget.contains(e.relatedTarget) && this.setState({ floorSuiteCollapsed: true });

    const onInput = key => {
      const prevValue = this.state.value;
      const update = val => {
        const valueUpdate = { [key]: val || null };
        const newValue = {...prevValue, floor, suite, ...valueUpdate, id: undefined, value: undefined };
        this.setState({ ...valueUpdate });
        this.props.onChange({...newValue});
      }

      return event => update(event.target.value);
    }

    return (
      <>
        <Lookup
          name={name}
          onChange={onChange}
          onInputChange={onInputChange}
          label={label}
          icon={icon}
          formatOptionLabel={formatOptionLabel}
          defaultOptions={!inputValue ? defaultOptions : null}
          editable={editable}
          async={true}
          loadOptions={loadOptions}
          isLoading={isLoading}
          isDisabled={isLoading || isDisabled}
          isClearable={true}
          tabSelectsValue={!this.state.value}
          openMenuOnFocus={true}
          noOptionsMessage={noOptionsMessage}
          error={error}
          inputRef={inputRef}
          inputValue={inputValue}
          placeholder={placeholder}
          components = {{
            MenuListFooter: inputValue ? <MenuListFooter /> : null
          }}
          createable={allowInvalidInput}
          onCreateOption={handleCreate}
          formatCreateLabel={formatCreateLabel}
          isValidNewOption={isValidNewOption}
          className={classNames.lookup}
          {...options}
        />
        {!!showingFloorSuiteSelector && (
          <div className={classNames.floorSuite} onBlur={onBlur}>
            <Button className={classNames.toggler} onClick={toggle} type="link" title={togglerText}>
              <span className={classNames.togglerText}>{togglerText}</span>
              <Icon name="caret-down" />
            </Button>
            
            {(!floorSuiteCollapsed) && (
              <div className={classNames.toggled}>
                <TextInput label="Floor" id={`${name}-floor-input`} onInput={onInput('floor')} value={floor || ''} className="text-input--small" isClearable={true} maxLength="255" />
                <TextInput label="Suite" id={`${name}-suite-input`} onInput={onInput('suite')} value={suite || ''} className="text-input--small" isClearable={true} maxLength="255" />
              </div>
            )}
          </div>
        )}
        <ModalManager ref={this.modalRef} />
      </>
    );
  }
}

const MenuListFooter = () => (
  <div className="menu-list-footer__google">
    <img src={poweredByGoogleImg} title="Powered by Google" />
  </div>
);


export default withUserContext(AddressDropdown);

export const countryLookup = {
  AFG: { numeric: '004', alpha2: 'AF', alpha3: 'AFG', name: 'Afghanistan' },
  ALA: { numeric: '248', alpha2: 'AX', alpha3: 'ALA', name: 'Åland Islands' },
  ALB: { numeric: '008', alpha2: 'AL', alpha3: 'ALB', name: 'Albania' },
  DZA: { numeric: '012', alpha2: 'DZ', alpha3: 'DZA', name: 'Algeria' },
  ASM: { numeric: '016', alpha2: 'AS', alpha3: 'ASM', name: 'American Samoa' },
  AND: { numeric: '020', alpha2: 'AD', alpha3: 'AND', name: 'Andorra' },
  AGO: { numeric: '024', alpha2: 'AO', alpha3: 'AGO', name: 'Angola' },
  AIA: { numeric: '660', alpha2: 'AI', alpha3: 'AIA', name: 'Anguilla' },
  ATA: { numeric: '010', alpha2: 'AQ', alpha3: 'ATA', name: 'Antarctica' },
  ATG: { numeric: '028', alpha2: 'AG', alpha3: 'ATG', name: 'Antigua and Barbuda' },
  ARG: { numeric: '032', alpha2: 'AR', alpha3: 'ARG', name: 'Argentina' },
  ARM: { numeric: '051', alpha2: 'AM', alpha3: 'ARM', name: 'Armenia' },
  ABW: { numeric: '533', alpha2: 'AW', alpha3: 'ABW', name: 'Aruba' },
  AUS: { numeric: '036', alpha2: 'AU', alpha3: 'AUS', currency: 'AUD', name: 'Australia' },
  AUT: { numeric: '040', alpha2: 'AT', alpha3: 'AUT', currency: 'EUR', name: 'Austria' },
  AZE: { numeric: '031', alpha2: 'AZ', alpha3: 'AZE', name: 'Azerbaijan' },
  BHS: { numeric: '044', alpha2: 'BS', alpha3: 'BHS', name: 'Bahamas' },
  BHR: { numeric: '048', alpha2: 'BH', alpha3: 'BHR', name: 'Bahrain' },
  BGD: { numeric: '050', alpha2: 'BD', alpha3: 'BGD', name: 'Bangladesh' },
  BRB: { numeric: '052', alpha2: 'BB', alpha3: 'BRB', name: 'Barbados' },
  BLR: { numeric: '112', alpha2: 'BY', alpha3: 'BLR', name: 'Belarus' },
  BEL: { numeric: '056', alpha2: 'BE', alpha3: 'BEL', currency: 'EUR', name: 'Belgium' },
  BLZ: { numeric: '084', alpha2: 'BZ', alpha3: 'BLZ', name: 'Belize' },
  BEN: { numeric: '204', alpha2: 'BJ', alpha3: 'BEN', name: 'Benin' },
  BMU: { numeric: '060', alpha2: 'BM', alpha3: 'BMU', name: 'Bermuda' },
  BTN: { numeric: '064', alpha2: 'BT', alpha3: 'BTN', name: 'Bhutan' },
  BOL: { numeric: '068', alpha2: 'BO', alpha3: 'BOL', name: 'Bolivia' },
  BES: { numeric: '535', alpha2: 'BQ', alpha3: 'BES', name: 'Bonaire' },
  BIH: { numeric: '070', alpha2: 'BA', alpha3: 'BIH', name: 'Bosnia' },
  BWA: { numeric: '072', alpha2: 'BW', alpha3: 'BWA', name: 'Botswana' },
  BVT: { numeric: '074', alpha2: 'BV', alpha3: 'BVT', name: 'Bouvet Island' },
  BRA: { numeric: '076', alpha2: 'BR', alpha3: 'BRA', currency: 'BRL', name: 'Brazil' },
  IOT: { numeric: '086', alpha2: 'IO', alpha3: 'IOT', name: 'British Indian Ocean Territory' },
  BRN: { numeric: '096', alpha2: 'BN', alpha3: 'BRN', name: 'Brunei Darussalam' },
  BGR: { numeric: '100', alpha2: 'BG', alpha3: 'BGR', name: 'Bulgaria' },
  BFA: { numeric: '854', alpha2: 'BF', alpha3: 'BFA', name: 'Burkina Faso' },
  BDI: { numeric: '108', alpha2: 'BI', alpha3: 'BDI', name: 'Burundi' },
  CPV: { numeric: '132', alpha2: 'CV', alpha3: 'CPV', name: 'Cabo Verde' },
  KHM: { numeric: '116', alpha2: 'KH', alpha3: 'KHM', name: 'Cambodia' },
  CMR: { numeric: '120', alpha2: 'CM', alpha3: 'CMR', name: 'Cameroon' },
  CAN: { numeric: '124', alpha2: 'CA', alpha3: 'CAN', currency: 'CAD', name: 'Canada' },
  CYM: { numeric: '136', alpha2: 'KY', alpha3: 'CYM', name: 'Cayman Islands' },
  CAF: { numeric: '140', alpha2: 'CF', alpha3: 'CAF', name: 'Central African Republic' },
  TCD: { numeric: '148', alpha2: 'TD', alpha3: 'TCD', name: 'Chad' },
  CHL: { numeric: '152', alpha2: 'CL', alpha3: 'CHL', name: 'Chile' },
  CHN: { numeric: '156', alpha2: 'CN', alpha3: 'CHN', name: 'China' },
  CXR: { numeric: '162', alpha2: 'CX', alpha3: 'CXR', name: 'Christmas Island' },
  CCK: { numeric: '166', alpha2: 'CC', alpha3: 'CCK', name: 'Cocos Islands' },
  COL: { numeric: '170', alpha2: 'CO', alpha3: 'COL', name: 'Colombia' },
  COM: { numeric: '174', alpha2: 'KM', alpha3: 'COM', name: 'Comoros' },
  COG: { numeric: '178', alpha2: 'CG', alpha3: 'COG', name: 'Congo' },
  COD: { numeric: '180', alpha2: 'CD', alpha3: 'COD', name: 'Congo' },
  COK: { numeric: '184', alpha2: 'CK', alpha3: 'COK', name: 'Cook Islands' },
  CRI: { numeric: '188', alpha2: 'CR', alpha3: 'CRI', name: 'Costa Rica' },
  CIV: { numeric: '384', alpha2: 'CI', alpha3: 'CIV', name:`Côte d'Ivoire`},
  HRV: { numeric: '191', alpha2: 'HR', alpha3: 'HRV', name: 'Croatia' },
  CUB: { numeric: '192', alpha2: 'CU', alpha3: 'CUB', name: 'Cuba' },
  CUW: { numeric: '531', alpha2: 'CW', alpha3: 'CUW', name: 'Curaçao' },
  CYP: { numeric: '196', alpha2: 'CY', alpha3: 'CYP', currency: 'EUR', name: 'Cyprus' },
  CZE: { numeric: '203', alpha2: 'CZ', alpha3: 'CZE', name: 'Czechia' },
  DNK: { numeric: '208', alpha2: 'DK', alpha3: 'DNK', name: 'Denmark' },
  DJI: { numeric: '262', alpha2: 'DJ', alpha3: 'DJI', name: 'Djibouti' },
  DMA: { numeric: '212', alpha2: 'DM', alpha3: 'DMA', name: 'Dominica' },
  DOM: { numeric: '214', alpha2: 'DO', alpha3: 'DOM', name: 'Dominican Republic' },
  ECU: { numeric: '218', alpha2: 'EC', alpha3: 'ECU', name: 'Ecuador' },
  EGY: { numeric: '818', alpha2: 'EG', alpha3: 'EGY', name: 'Egypt' },
  SLV: { numeric: '222', alpha2: 'SV', alpha3: 'SLV', name: 'El Salvador' },
  GNQ: { numeric: '226', alpha2: 'GQ', alpha3: 'GNQ', name: 'Equatorial Guinea' },
  ERI: { numeric: '232', alpha2: 'ER', alpha3: 'ERI', name: 'Eritrea' },
  EST: { numeric: '233', alpha2: 'EE', alpha3: 'EST', currency: 'EUR', name: 'Estonia' },
  ETH: { numeric: '231', alpha2: 'ET', alpha3: 'ETH', name: 'Ethiopia' },
  FLK: { numeric: '238', alpha2: 'FK', alpha3: 'FLK', name: 'Falkland Islands' },
  FRO: { numeric: '234', alpha2: 'FO', alpha3: 'FRO', name: 'Faroe Islands' },
  FJI: { numeric: '242', alpha2: 'FJ', alpha3: 'FJI', name: 'Fiji' },
  FIN: { numeric: '246', alpha2: 'FI', alpha3: 'FIN', currency: 'EUR', name: 'Finland' },
  FRA: { numeric: '250', alpha2: 'FR', alpha3: 'FRA', currency: 'EUR', name: 'France' },
  GUF: { numeric: '254', alpha2: 'GF', alpha3: 'GUF', name: 'French Guiana' },
  PYF: { numeric: '258', alpha2: 'PF', alpha3: 'PYF', name: 'French Polynesia' },
  ATF: { numeric: '260', alpha2: 'TF', alpha3: 'ATF', name: 'French Southern Territories' },
  GAB: { numeric: '266', alpha2: 'GA', alpha3: 'GAB', name: 'Gabon' },
  GMB: { numeric: '270', alpha2: 'GM', alpha3: 'GMB', name: 'Gambia' },
  GEO: { numeric: '268', alpha2: 'GE', alpha3: 'GEO', name: 'Georgia' },
  DEU: { numeric: '276', alpha2: 'DE', alpha3: 'DEU', currency: 'EUR', name: 'Germany' },
  GHA: { numeric: '288', alpha2: 'GH', alpha3: 'GHA', name: 'Ghana' },
  GIB: { numeric: '292', alpha2: 'GI', alpha3: 'GIB', name: 'Gibraltar' },
  GRC: { numeric: '300', alpha2: 'GR', alpha3: 'GRC', currency: 'EUR', name: 'Greece' },
  GRL: { numeric: '304', alpha2: 'GL', alpha3: 'GRL', name: 'Greenland' },
  GRD: { numeric: '308', alpha2: 'GD', alpha3: 'GRD', name: 'Grenada' },
  GLP: { numeric: '312', alpha2: 'GP', alpha3: 'GLP', name: 'Guadeloupe' },
  GUM: { numeric: '316', alpha2: 'GU', alpha3: 'GUM', name: 'Guam' },
  GTM: { numeric: '320', alpha2: 'GT', alpha3: 'GTM', name: 'Guatemala' },
  GGY: { numeric: '831', alpha2: 'GG', alpha3: 'GGY', name: 'Guernsey' },
  GIN: { numeric: '324', alpha2: 'GN', alpha3: 'GIN', name: 'Guinea' },
  GNB: { numeric: '624', alpha2: 'GW', alpha3: 'GNB', name: 'Guinea-Bissau' },
  GUY: { numeric: '328', alpha2: 'GY', alpha3: 'GUY', name: 'Guyana' },
  HTI: { numeric: '332', alpha2: 'HT', alpha3: 'HTI', name: 'Haiti' },
  HMD: { numeric: '334', alpha2: 'HM', alpha3: 'HMD', name: 'Heard Island and McDonald Islands' },
  VAT: { numeric: '336', alpha2: 'VA', alpha3: 'VAT', name: 'Holy See' },
  HND: { numeric: '340', alpha2: 'HN', alpha3: 'HND', name: 'Honduras' },
  HKG: { numeric: '344', alpha2: 'HK', alpha3: 'HKG', currency: 'HKD', name: 'Hong Kong' },
  HUN: { numeric: '348', alpha2: 'HU', alpha3: 'HUN', name: 'Hungary' },
  ISL: { numeric: '352', alpha2: 'IS', alpha3: 'ISL', name: 'Iceland' },
  IND: { numeric: '356', alpha2: 'IN', alpha3: 'IND', currency: 'INR', name: 'India' },
  IDN: { numeric: '360', alpha2: 'ID', alpha3: 'IDN', name: 'Indonesia' },
  IRN: { numeric: '364', alpha2: 'IR', alpha3: 'IRN', name: 'Iran' },
  IRQ: { numeric: '368', alpha2: 'IQ', alpha3: 'IRQ', name: 'Iraq' },
  IRL: { numeric: '372', alpha2: 'IE', alpha3: 'IRL', currency: 'EUR', name: 'Ireland' },
  IMN: { numeric: '833', alpha2: 'IM', alpha3: 'IMN', name: 'Isle of Man' },
  ISR: { numeric: '376', alpha2: 'IL', alpha3: 'ISR', name: 'Israel' },
  ITA: { numeric: '380', alpha2: 'IT', alpha3: 'ITA', currency: 'EUR', name: 'Italy' },
  JAM: { numeric: '388', alpha2: 'JM', alpha3: 'JAM', name: 'Jamaica' },
  JPN: { numeric: '392', alpha2: 'JP', alpha3: 'JPN', currency: 'JPY', name: 'Japan' },
  JEY: { numeric: '832', alpha2: 'JE', alpha3: 'JEY', name: 'Jersey' },
  JOR: { numeric: '400', alpha2: 'JO', alpha3: 'JOR', name: 'Jordan' },
  KAZ: { numeric: '398', alpha2: 'KZ', alpha3: 'KAZ', name: 'Kazakhstan' },
  KEN: { numeric: '404', alpha2: 'KE', alpha3: 'KEN', name: 'Kenya' },
  KIR: { numeric: '296', alpha2: 'KI', alpha3: 'KIR', name: 'Kiribati' },
  PRK: { numeric: '408', alpha2: 'KP', alpha3: 'PRK', name: 'Korea' },
  KOR: { numeric: '410', alpha2: 'KR', alpha3: 'KOR', name: 'Korea' },
  KWT: { numeric: '414', alpha2: 'KW', alpha3: 'KWT', name: 'Kuwait' },
  KGZ: { numeric: '417', alpha2: 'KG', alpha3: 'KGZ', name: 'Kyrgyzstan' },
  LAO: { numeric: '418', alpha2: 'LA', alpha3: 'LAO', name: 'Laos' },
  LVA: { numeric: '428', alpha2: 'LV', alpha3: 'LVA', currency: 'EUR', name: 'Latvia' },
  LBN: { numeric: '422', alpha2: 'LB', alpha3: 'LBN', name: 'Lebanon' },
  LSO: { numeric: '426', alpha2: 'LS', alpha3: 'LSO', name: 'Lesotho' },
  LBR: { numeric: '430', alpha2: 'LR', alpha3: 'LBR', name: 'Liberia' },
  LBY: { numeric: '434', alpha2: 'LY', alpha3: 'LBY', name: 'Libya' },
  LIE: { numeric: '438', alpha2: 'LI', alpha3: 'LIE', name: 'Liechtenstein' },
  LTU: { numeric: '440', alpha2: 'LT', alpha3: 'LTU', currency: 'EUR', name: 'Lithuania' },
  LUX: { numeric: '442', alpha2: 'LU', alpha3: 'LUX', currency: 'EUR', name: 'Luxembourg' },
  MAC: { numeric: '446', alpha2: 'MO', alpha3: 'MAC', name: 'Macao' },
  MKD: { numeric: '807', alpha2: 'MK', alpha3: 'MKD', name: 'Macedonia' },
  MDG: { numeric: '450', alpha2: 'MG', alpha3: 'MDG', name: 'Madagascar' },
  MWI: { numeric: '454', alpha2: 'MW', alpha3: 'MWI', name: 'Malawi' },
  MYS: { numeric: '458', alpha2: 'MY', alpha3: 'MYS', name: 'Malaysia' },
  MDV: { numeric: '462', alpha2: 'MV', alpha3: 'MDV', name: 'Maldives' },
  MLI: { numeric: '466', alpha2: 'ML', alpha3: 'MLI', name: 'Mali' },
  MLT: { numeric: '470', alpha2: 'MT', alpha3: 'MLT', currency: 'EUR', name: 'Malta' },
  MHL: { numeric: '584', alpha2: 'MH', alpha3: 'MHL', name: 'Marshall Islands' },
  MTQ: { numeric: '474', alpha2: 'MQ', alpha3: 'MTQ', name: 'Martinique' },
  MRT: { numeric: '478', alpha2: 'MR', alpha3: 'MRT', name: 'Mauritania' },
  MUS: { numeric: '480', alpha2: 'MU', alpha3: 'MUS', name: 'Mauritius' },
  MYT: { numeric: '175', alpha2: 'YT', alpha3: 'MYT', name: 'Mayotte' },
  MEX: { numeric: '484', alpha2: 'MX', alpha3: 'MEX', name: 'Mexico' },
  FSM: { numeric: '583', alpha2: 'FM', alpha3: 'FSM', name: 'Micronesia' },
  MDA: { numeric: '498', alpha2: 'MD', alpha3: 'MDA', name: 'Moldova' },
  MCO: { numeric: '492', alpha2: 'MC', alpha3: 'MCO', name: 'Monaco' },
  MNG: { numeric: '496', alpha2: 'MN', alpha3: 'MNG', name: 'Mongolia' },
  MNE: { numeric: '499', alpha2: 'ME', alpha3: 'MNE', name: 'Montenegro' },
  MSR: { numeric: '500', alpha2: 'MS', alpha3: 'MSR', name: 'Montserrat' },
  MAR: { numeric: '504', alpha2: 'MA', alpha3: 'MAR', name: 'Morocco' },
  MOZ: { numeric: '508', alpha2: 'MZ', alpha3: 'MOZ', name: 'Mozambique' },
  MMR: { numeric: '104', alpha2: 'MM', alpha3: 'MMR', name: 'Myanmar' },
  NAM: { numeric: '516', alpha2: 'NA', alpha3: 'NAM', name: 'Namibia' },
  NRU: { numeric: '520', alpha2: 'NR', alpha3: 'NRU', name: 'Nauru' },
  NPL: { numeric: '524', alpha2: 'NP', alpha3: 'NPL', name: 'Nepal' },
  NLD: { numeric: '528', alpha2: 'NL', alpha3: 'NLD', currency: 'EUR', name: 'Netherlands' },
  NCL: { numeric: '540', alpha2: 'NC', alpha3: 'NCL', name: 'New Caledonia' },
  NZL: { numeric: '554', alpha2: 'NZ', alpha3: 'NZL', name: 'New Zealand' },
  NIC: { numeric: '558', alpha2: 'NI', alpha3: 'NIC', name: 'Nicaragua' },
  NER: { numeric: '562', alpha2: 'NE', alpha3: 'NER', name: 'Niger' },
  NGA: { numeric: '566', alpha2: 'NG', alpha3: 'NGA', name: 'Nigeria' },
  NIU: { numeric: '570', alpha2: 'NU', alpha3: 'NIU', name: 'Niue' },
  NFK: { numeric: '574', alpha2: 'NF', alpha3: 'NFK', name: 'Norfolk Island' },
  MNP: { numeric: '580', alpha2: 'MP', alpha3: 'MNP', name: 'Northern Mariana Islands' },
  NOR: { numeric: '578', alpha2: 'NO', alpha3: 'NOR', name: 'Norway' },
  OMN: { numeric: '512', alpha2: 'OM', alpha3: 'OMN', name: 'Oman' },
  PAK: { numeric: '586', alpha2: 'PK', alpha3: 'PAK', name: 'Pakistan' },
  PLW: { numeric: '585', alpha2: 'PW', alpha3: 'PLW', name: 'Palau' },
  PSE: { numeric: '275', alpha2: 'PS', alpha3: 'PSE', name: 'Palestine' },
  PAN: { numeric: '591', alpha2: 'PA', alpha3: 'PAN', name: 'Panama' },
  PNG: { numeric: '598', alpha2: 'PG', alpha3: 'PNG', name: 'Papua New Guinea' },
  PRY: { numeric: '600', alpha2: 'PY', alpha3: 'PRY', name: 'Paraguay' },
  PER: { numeric: '604', alpha2: 'PE', alpha3: 'PER', name: 'Peru' },
  PHL: { numeric: '608', alpha2: 'PH', alpha3: 'PHL', name: 'Philippines' },
  PCN: { numeric: '612', alpha2: 'PN', alpha3: 'PCN', name: 'Pitcairn' },
  POL: { numeric: '616', alpha2: 'PL', alpha3: 'POL', name: 'Poland' },
  PRT: { numeric: '620', alpha2: 'PT', alpha3: 'PRT', currency: 'EUR', name: 'Portugal' },
  PRI: { numeric: '630', alpha2: 'PR', alpha3: 'PRI', name: 'Puerto Rico' },
  QAT: { numeric: '634', alpha2: 'QA', alpha3: 'QAT', name: 'Qatar' },
  REU: { numeric: '638', alpha2: 'RE', alpha3: 'REU', name: 'Réunion' },
  ROU: { numeric: '642', alpha2: 'RO', alpha3: 'ROU', name: 'Romania' },
  RUS: { numeric: '643', alpha2: 'RU', alpha3: 'RUS', name: 'Russian Federation' },
  RWA: { numeric: '646', alpha2: 'RW', alpha3: 'RWA', name: 'Rwanda' },
  BLM: { numeric: '652', alpha2: 'BL', alpha3: 'BLM', name: 'Saint Barthélemy' },
  SHN: { numeric: '654', alpha2: 'SH', alpha3: 'SHN', name: 'Saint Helena, Ascension and Tristan da Cunha' },
  KNA: { numeric: '659', alpha2: 'KN', alpha3: 'KNA', name: 'Saint Kitts and Nevis' },
  LCA: { numeric: '662', alpha2: 'LC', alpha3: 'LCA', name: 'Saint Lucia' },
  MAF: { numeric: '663', alpha2: 'MF', alpha3: 'MAF', name: 'Saint Martin' },
  SPM: { numeric: '666', alpha2: 'PM', alpha3: 'SPM', name: 'Saint Pierre and Miquelon' },
  VCT: { numeric: '670', alpha2: 'VC', alpha3: 'VCT', name: 'Saint Vincent and the Grenadines' },
  WSM: { numeric: '882', alpha2: 'WS', alpha3: 'WSM', name: 'Samoa' },
  SMR: { numeric: '674', alpha2: 'SM', alpha3: 'SMR', name: 'San Marino' },
  STP: { numeric: '678', alpha2: 'ST', alpha3: 'STP', name: 'Sao Tome and Principe' },
  SAU: { numeric: '682', alpha2: 'SA', alpha3: 'SAU', name: 'Saudi Arabia' },
  SEN: { numeric: '686', alpha2: 'SN', alpha3: 'SEN', name: 'Senegal' },
  SRB: { numeric: '688', alpha2: 'RS', alpha3: 'SRB', name: 'Serbia' },
  SYC: { numeric: '690', alpha2: 'SC', alpha3: 'SYC', name: 'Seychelles' },
  SLE: { numeric: '694', alpha2: 'SL', alpha3: 'SLE', name: 'Sierra Leone' },
  SGP: { numeric: '702', alpha2: 'SG', alpha3: 'SGP', currency: 'SGD', name: 'Singapore' },
  SXM: { numeric: '534', alpha2: 'SX', alpha3: 'SXM', name: 'Sint Maarten' },
  SVK: { numeric: '703', alpha2: 'SK', alpha3: 'SVK', currency: 'EUR', name: 'Slovakia' },
  SVN: { numeric: '705', alpha2: 'SI', alpha3: 'SVN', currency: 'EUR', name: 'Slovenia' },
  SLB: { numeric: '090', alpha2: 'SB', alpha3: 'SLB', name: 'Solomon Islands' },
  SOM: { numeric: '706', alpha2: 'SO', alpha3: 'SOM', name: 'Somalia' },
  ZAF: { numeric: '710', alpha2: 'ZA', alpha3: 'ZAF', currency: 'ZAR', name: 'South Africa' },
  SGS: { numeric: '239', alpha2: 'GS', alpha3: 'SGS', name: 'South Georgia and the South Sandwich Islands' },
  SSD: { numeric: '728', alpha2: 'SS', alpha3: 'SSD', name: 'South Sudan' },
  ESP: { numeric: '724', alpha2: 'ES', alpha3: 'ESP', currency: 'EUR', name: 'Spain' },
  LKA: { numeric: '144', alpha2: 'LK', alpha3: 'LKA', name: 'Sri Lanka' },
  SDN: { numeric: '729', alpha2: 'SD', alpha3: 'SDN', name: 'Sudan' },
  SUR: { numeric: '740', alpha2: 'SR', alpha3: 'SUR', name: 'Suriname' },
  SJM: { numeric: '744', alpha2: 'SJ', alpha3: 'SJM', name: 'Svalbard and Jan Mayen' },
  SWZ: { numeric: '748', alpha2: 'SZ', alpha3: 'SWZ', name: 'Eswatini' },
  SWE: { numeric: '752', alpha2: 'SE', alpha3: 'SWE', name: 'Sweden' },
  CHE: { numeric: '756', alpha2: 'CH', alpha3: 'CHE', name: 'Switzerland' },
  SYR: { numeric: '760', alpha2: 'SY', alpha3: 'SYR', name: 'Syrian Arab Republic' },
  TWN: { numeric: '158', alpha2: 'TW', alpha3: 'TWN', name: 'Taiwan' },
  TJK: { numeric: '762', alpha2: 'TJ', alpha3: 'TJK', name: 'Tajikistan' },
  TZA: { numeric: '834', alpha2: 'TZ', alpha3: 'TZA', name: 'Tanzania' },
  THA: { numeric: '764', alpha2: 'TH', alpha3: 'THA', name: 'Thailand' },
  TLS: { numeric: '626', alpha2: 'TL', alpha3: 'TLS', name: 'Timor-Leste' },
  TGO: { numeric: '768', alpha2: 'TG', alpha3: 'TGO', name: 'Togo' },
  TKL: { numeric: '772', alpha2: 'TK', alpha3: 'TKL', name: 'Tokelau' },
  TON: { numeric: '776', alpha2: 'TO', alpha3: 'TON', name: 'Tonga' },
  TTO: { numeric: '780', alpha2: 'TT', alpha3: 'TTO', name: 'Trinidad and Tobago' },
  TUN: { numeric: '788', alpha2: 'TN', alpha3: 'TUN', name: 'Tunisia' },
  TUR: { numeric: '792', alpha2: 'TR', alpha3: 'TUR', name: 'Turkey' },
  TKM: { numeric: '795', alpha2: 'TM', alpha3: 'TKM', name: 'Turkmenistan' },
  TCA: { numeric: '796', alpha2: 'TC', alpha3: 'TCA', name: 'Turks and Caicos Islands' },
  TUV: { numeric: '798', alpha2: 'TV', alpha3: 'TUV', name: 'Tuvalu' },
  UGA: { numeric: '800', alpha2: 'UG', alpha3: 'UGA', name: 'Uganda' },
  UKR: { numeric: '804', alpha2: 'UA', alpha3: 'UKR', name: 'Ukraine' },
  ARE: { numeric: '784', alpha2: 'AE', alpha3: 'ARE', name: 'United Arab Emirates' },
  GBR: { numeric: '826', alpha2: 'GB', alpha3: 'GBR', currency: 'GBP', name: 'United Kingdom' },
  USA: { numeric: '840', alpha2: 'US', alpha3: 'USA', currency: 'USD', name: 'United States of America' },
  UMI: { numeric: '581', alpha2: 'UM', alpha3: 'UMI', name: 'United States Minor Outlying Islands' },
  URY: { numeric: '858', alpha2: 'UY', alpha3: 'URY', name: 'Uruguay' },
  UZB: { numeric: '860', alpha2: 'UZ', alpha3: 'UZB', name: 'Uzbekistan' },
  VUT: { numeric: '548', alpha2: 'VU', alpha3: 'VUT', name: 'Vanuatu' },
  VEN: { numeric: '862', alpha2: 'VE', alpha3: 'VEN', name: 'Venezuela' },
  VNM: { numeric: '704', alpha2: 'VN', alpha3: 'VNM', name: 'Viet Nam' },
  VGB: { numeric: '092', alpha2: 'VG', alpha3: 'VGB', name: 'Virgin Islands' },
  VIR: { numeric: '850', alpha2: 'VI', alpha3: 'VIR', name: 'Virgin Islands' },
  WLF: { numeric: '876', alpha2: 'WF', alpha3: 'WLF', name: 'Wallis and Futuna' },
  ESH: { numeric: '732', alpha2: 'EH', alpha3: 'ESH', name: 'Western Sahara' },
  YEM: { numeric: '887', alpha2: 'YE', alpha3: 'YEM', name: 'Yemen' },
  ZMB: { numeric: '894', alpha2: 'ZM', alpha3: 'ZMB', name: 'Zambia' },
  ZWE: { numeric: '716', alpha2: 'ZW', alpha3: 'ZWE', name: 'Zimbabwe' }
};

export const getAllCountries = () => Object.values(countryLookup);

export const getAlpha2 = alpha3 => {
  if (!alpha3 || alpha3.length !== 3 || !countryLookup[alpha3]) {
    console.warn(`${alpha3} is not a valid recognized alpha3 country code.`);
    return alpha3;
  }

  return countryLookup[alpha3].alpha2;
}

export const getCountryName = alpha3 => (countryLookup[alpha3] || {}).name;

export const getCurrencyFlag = currency => currency === 'EUR' ? 'eu' : (Object.values(countryLookup).find(x => x.currency === currency) || {}).alpha2; 

export const getCurrency = alpha3 => (countryLookup[alpha3] || {}).currency;

export const emojiFlag = (countryCode='') => {
  const code = (countryCode.length === 3)
      ? (countryLookup[countryCode] || {}).alpha2
      : countryCode;

  if (code.length !== 2)
    throw new Error('Not an alpha2 country code');
  
  const offset = 127397;
  const A = 65;
  const Z = 90;

  const f = code.codePointAt(0);
	const s = code.codePointAt(1);

	if (f > Z || f < A || s > Z || s < A)
		throw new Error('Not an alpha2 country code');

	return String.fromCodePoint(f + offset)
		+String.fromCodePoint(s + offset);
}
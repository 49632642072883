import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import CN from 'classnames';
import { Icon } from 'Atoms';

import './Pager.scss';

class Pager extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { pageCount, onPageClick, className='', initialPage, isLoading } = this.props;
    const classNames = CN(`pager ${className}`, { isLoading });

    return pageCount > 1 ? (
      <ReactPaginate 
        previousLabel={<Icon name="caret-previous" />}
        nextLabel={<Icon name="caret-next" />}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={onPageClick}
        containerClassName={classNames}
        subContainerClassName={"pages pager"}
        activeClassName={"active"}
        // initialPage={initialPage-1}
        disableInitialCallback={true}
        forcePage={initialPage-1} />
    ) : null;
  }
}

const PagerStatus = (props) => {
  const { page, pageSize, totalItems } = props;

  if (!(page && pageSize && totalItems)) 
    return null;

  let showing;
  if (page === 1) {
    showing = `${Math.min(pageSize, totalItems)} of ${totalItems}`
  }
  else {
    const rangeLo = ((page - 1) * pageSize) + 1;
    const rangeHi = Math.min(page * pageSize, totalItems);
    const range = (rangeLo === rangeHi) ? rangeLo :  `${rangeLo}-${rangeHi}`;

    showing = `${range} of ${totalItems}`;
  }

  return <div className="pager-status"><b>{showing}</b> results showing</div>
}

export { Pager, PagerStatus };

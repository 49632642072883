import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useUserContext } from 'Context/UserContext';
import { ContextMenu, ContextMenuButton, Icon, LoadingOverlay, Notification, SortableTable, TimeAgo, Uploader, VisibilityToggle } from 'Atoms';
import { ModalManager, MODALS } from 'Molecules';
import { getAttachments, uploadAttachment, downloadAttachment, updateAttachment } from 'Actions/ccm/api.js';
import { getFileExtension } from 'Utils/strings.js';
import { getFileIcon, formatBytes } from 'Utils/formatter.js';
import { INTERNAL_COMPANY_NAME } from 'Utils/branding.js';

import './QuoteAttachments.scss';

const QuoteAttachments = props => {
  const { setCount } = props;
  const quoteId = props.id;

  const user = useUserContext();

  const [ files, setFiles ] = useState([]);
  const [ isInternal, setIsInternal ] = useState(user.is_internal);
  const [ isFetching, setIsFetching ] = useState(false);
  const [ uploadError, setUploadError ] = useState(null);
  
  const columnData = useRef([
    { key: 'icon', header: ' ', render: ({ icon, fileExtension }) => icon && (
      <div>
        <Icon name={icon} />
        <span className="icon-label">{ fileExtension.replace('.', '') }</span>
      </div> 
    )}, 
    { key: 'name', render: ({ id, name }) => <a href="#" title={name} onClick={() => downloadAttachment(quoteId, id, name)}>{name}</a> },
    { key: 'description' },
    { key: 'date', render: ({ date }) => <TimeAgo date={date} /> }, //, render: ({ date }) => formatDate(date, dateFormat) },
    { key: 'author' },
    { key: 'size' },
    !user.is_internal ? null : { key: 'isInternal',
      header: 'Visibility',
      render: ({ isInternal }) => isInternal
        ? <div className="flex-row"><Icon name="cloud" /><span>{INTERNAL_COMPANY_NAME}</span></div>
        : <div className="flex-row"><Icon name="internet" /><span>All Users</span></div>
    },
    { key: 'actions', header: ' ', sortable: false, render: ({ type, ...data }) => <ContextMenuButton type={type} {...data} icon="ellipsis" /> }
  ].filter(Boolean)).current;

  const modalRef = useRef();

  const onChange = (info) => {
    const status = info.file.status;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      // add uploads to this.state.files
      // update count on tabs
      //message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      // show error
      //message.error(`${info.file.name} file upload failed.`);
    }
  }

  const showModal = (name, props={}) => modalRef.current.open(name, {...props });

  const toItemRow = ({ id, isInternal, name, url, author, authorId, date, description, size }) => {
    const fileExtension = getFileExtension(name);
    return { icon: getFileIcon(fileExtension), id, isInternal, name, url, author, authorId, date, size, menuId: 'attachments_menu', fileExtension, description };
  }

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    const resp = await getAttachments(quoteId);
    setFiles(resp);
    setCount(resp.length);
    setIsFetching(false);
  }, [quoteId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const uploaderProps = {
    accept: '.kmz, .bmp, .gif, .jpeg, .jpg, .png, .vsd, .eml, .msg, .pdf, .ppt, .pot, .pps, .pptx, .pptm, .potx, .odp, .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .ods, .csv, .doc, .dot, .wbk, .docx, .docm, .dotx, .dotm, .odt, .txt, .rtf, .log',
    name: 'file',
    multiple: true,
    onChange,
    text: '',
    hint: 'Click or drop files here to upload. (Maximum supported file size: 10 MB)',
    showUploadList: false,

    onStart(file) {
      //console.log('onStart', file, file.name);
    },
    onSuccess(ret, file) {
      //console.log('onSuccess', ret, file.name);
    },
    onError(err) {
      //console.log('onError', err);
    },
    onProgress({ percent }, file) {
      //console.log('onProgress', `${percent}%`, file.name);
    },

    customRequest: ({ data, file, fileName, onError, onProgress, onSuccess }) => {
      const FILE_UPLOAD_LIMIT = 10 * 1024 * 1024;
      if (file.size > FILE_UPLOAD_LIMIT) {
        alert(`${file.name} is ${formatBytes(file.size)} exceeds the maximum supported file size of 10 MB and could not be uploaded.`);
        return;
      }
      
      uploadAttachment(quoteId, { file, description: "", isInternal })
      .then(json => {
        if (json.error) {
          let errors = ""
          Object.entries(json.error).map(([_, value]) => errors = errors.concat(value));
          setUploadError(errors);
        }
      })
      .then(fetchData);
    }
  };

  const onChangeVisibility = () => setIsInternal(!isInternal);

  const rowData = files.map(toItemRow);

  const onClick = {
    description: ({ props }) => showModal(MODALS.DESCRIPTION, {
      onConfirm: fetchData, quoteId, attachmentId: props.id, description: props.description
    }),
    visibility: ({ props }) => updateAttachment(quoteId, props.id, { is_internal: !props.isInternal }).then(fetchData),
    delete: ({ props }) => showModal(MODALS.DELETE, { 
      onConfirm: fetchData, type: 'file-attachment', quoteId, id: props.id, name: props.name 
    }),
  };

  const disableModification = ({ props }) => !user.is_internal && (props.authorId != user.id);

  const menuItems = [
    { label: 'Delete', icon: 'trash',  onClick: onClick.delete, disabled: disableModification },
    { label: 'Description', icon: 'pencil', onClick: onClick.description, disabled: disableModification },
    { label: 'Toggle Visibility', icon: 'request-review', onClick: onClick.visibility, hidden: !user.is_internal },
  ];

  return (
    <div className="quote-attachments">
      {uploadError && <Notification.Error children={uploadError}  duration={5000} onDismiss={() => setUploadError(null)} />}
      <Uploader {...uploaderProps} />
      {!!user.is_internal && (
        <VisibilityToggle isInternal={isInternal} onChange={onChangeVisibility} />
      )}

      { rowData.length 
          ? <SortableTable columnData={columnData} rowData={rowData} /> : 
        !isFetching 
          ? <Notification.Info children="There are no attachments for this quote." />
          : null
      }
      {!!isFetching && (
        <LoadingOverlay />
      )}
      <ContextMenu id="attachments_menu" items={menuItems} />
      <ModalManager ref={modalRef} />
    </div>
  );
}

export default QuoteAttachments;

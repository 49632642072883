import React from 'react';
import { Lookup } from 'Molecules';

import './ServiceClass.scss';

const ServiceClassDropdown = props => {
  const { name='class-of-service', label='Class of Service', icon='class-of-service', defaultValue, ...rest } = props;

  const options = [
    { value: 'premium', label: 'Premium', description: 'Suitable for highest priority and real-time data (i.e. video and voice)' },
    { value: 'priority', label: 'Priority', description: 'Suitable for business-critical data' },
    { value: 'standard', label: 'Standard', description: 'Suitable for general data and internet' },
    { value: 'best-effort', label: 'Best Effort', description: 'Data streams without guaranteed resources and service guarantees' }
  ];

  const formatOptionLabel = ({ label, description }, { context }) => {
    return (context === 'menu') ? (<>
        <div className="react-select__option-header">{label}</div>
        <div className="react-select__option-description">{description}</div>
      </>) : label;
  };

  const lookupProps = {
    name, label, icon, formatOptionLabel, options,
    defaultValue: (defaultValue && options.find(opt => opt.value === defaultValue.value)) || undefined,
    ...rest
  };

  return <Lookup {...lookupProps} />
}

export default ServiceClassDropdown;
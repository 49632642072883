import React from 'react';
import CN from 'classnames';
import { Icon } from 'Atoms';

import './Steps.scss';

const Step = (props) => {
  const { title, current, index } = props;

  const className = CN(`steps__step ${props.className || ''}`, {
    'steps__step--pending': current < index,
    'steps__step--current': current === index,
    'steps__step--completed': current > index,
  });

  return (
    <div className={className}>
      <div className="steps__step-badge">{(current > index) ? <Icon name="checkmark" /> : index+1 }</div>
      <div className="steps__step-title">{title}</div>
    </div>
  )
}

const Steps = ({ steps=[], current }) => {
  const children = steps.map((step, i) => <Step title={step} index={i} current={current} key={`step_${i}`} />);

  return (
    <div className="steps">
      { children }
    </div>
  );
}

export default Steps;
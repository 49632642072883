import React, { Component } from 'react';
import Tag from 'antd/es/tag';
import { Icon } from 'Atoms';

import "antd/lib/dropdown/style/index.css";
import "antd/lib/button/style/index.css";
import "antd/lib/tag/style/index.css";

import style from './inputs.scss';

export default class TaggedInput extends Component {

  renderTags() {
    return this.props.tags.map(tag => {
      return (
        <div className='generated-tag' key={tag + '-tag'}>
          <Tag onClick={() => this.props.onRemoveTag(tag)}>
            {tag} <Icon name="close" title="Delete tag" />
          </Tag>
        </div>);
    })
  }

  render() {
    const {
      input: { value, onChange },
      id,
      addTag,
    } = this.props;

    return (
      <div className='tagged-input'>
        <input type='text'
          id={id}
          value={this.props.text}
          placeholder="Add"
          onChange={onChange}/>
        <button onClick={addTag}>
          <Icon name="add"/>
        </button>
        <div className='tag-list'>
          {this.renderTags()}
        </div>
      </div>
    )
  }
}
import React from 'react';
import { SupportLinks } from 'Atoms';

import './Support.scss';

const Support = ({ cssClass='', pricingEmail, technicalEmail }) => {

  return (
    <div className={`support-band ${cssClass}`}>
      <span className="support__info">
        <span className="support__text">
          <span className="support__text--contact-us">
          <SupportLinks.Technical className="support__link" to={technicalEmail} />
          </span>
        </span>
      </span>
    </div>
  );
}

export default Support;
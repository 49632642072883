import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import uniqueId from 'lodash/uniqueId';
import findLastIndex from 'lodash/findLastIndex';

import useDesignEditLogger from './useDesignEditLogger.js';

import { getQuoteOptions, getColos, getOrganizations, getProviderProducts } from 'Actions/ccm/api.js';
import { mapItemsToRows, getRowOptions } from './DesignEditorHelper.js';
import { isSameLocation }  from 'Pages/CCM/QuoteView/QuoteViewerMapper.js';

import { getLocationValue, calculateItemTotals } from './DesignItem/DesignItemRowHelper.js';

const UNGROUPABLE_DESIGN_TYPES = ['location', 'provider-connection', 'cross-connect', 'other', 'royalty'];

// Similar but used by addRow
const UNGROUPED_TYPES = ['provider-connection', 'location', 'royalty', 'market-rate-adjustment', 'ipv4-addresses'];

const GROUPABLE_BOUNDRY_TYPES = ['location', 'provider-connection'];

// Used to populate add design item to location

const UNITAS_PROVIDER_TYPES = ['other', 'network-interface-device', 'class-of-service', 'extended-demarc'];


const useDesignItemsManager = (app) => {
  const { design, clearError, ...props } = app;
  const { term, bandwidth, port, locationZ, updateLocationZ } = props;

  const initRows = mapItemsToRows(design, locationZ);
  const [ items, setItems ] = useState(initRows);

  const [ quoteOptions, setQuoteOptions ] = useState({});
  const [ colos, setColos ] = useState([]);
  const [ products, setProducts ] = useState([]);
  const [ unitasUrl, setUnitasUrl ] = useState();

  const [ isLoading, setIsLoading ] = useState(false);

  // EditLogger hooks 
  const { editLog, logEdit, clearEdits, hasEdits } = useDesignEditLogger();

  // state value of quote options not being called when creating new rows, so here we are
  const quoteOptionsRef = useRef();

  // Private method to get row by its rowKey
  const getRow = useCallback(rowKey => {
    const index = items.findIndex(i => i.rowKey === rowKey);
    return { index, item: items[index] };
  }, [ items ]);

  // useMemo - compute options for design rows
  const options = useMemo(() => ({ ...quoteOptions, colos, products }), [ quoteOptions, colos, products ]);

  // useCallback - map fetched callback items
  const quoteOptionsCallback = useCallback((resp) => {
    const allQuoteOptions = getRowOptions(resp, design);

    setQuoteOptions(allQuoteOptions);
    updateSelectedRowOptions(allQuoteOptions);

    quoteOptionsRef.current = allQuoteOptions;
  }, [updateSelectedRowOptions, design]);

  // useEffect - onMount fetch required info
  useEffect(function onMount() {
    const fetchQuoteOptions = async () => {
      setIsLoading(true);
      const resp = await getQuoteOptions();
      setIsLoading(false);
      quoteOptionsCallback(resp);
    };

    const fetchColos = async () => {
      setColos(await getColos({ includeHubs: true }));
    };

    const fetchProducts = async () => {
      setProducts(await getProviderProducts());
    };

    const fetchUnitasUrl = async () => {
      const resp = await getOrganizations({ name: 'Unitas Global' });
      setUnitasUrl(resp?.results?.[0]?.url);
    };

    fetchQuoteOptions();
    fetchColos();
    fetchProducts();
    fetchUnitasUrl();
  }, [quoteOptionsCallback]);
  

  // Row Methods
  const addRow = useCallback(({ value, index=-1, skipEdit=false }) => {
    const rowKey = uniqueId('designItem_');
    const row = newRow({ type: value, rowKey });
    const pos = getNewRowPositionIndex(index, value);

    !skipEdit && logEdit({ 'added': row });
    clearError('designItems');

    setItems(prev => { 
      const current = [...prev];
      current.splice(pos, null, row);
      return current;
    });
  }, [ clearError, logEdit, newRow, getNewRowPositionIndex ]);

  // Row Method
  const deleteRow = useCallback(({ rowKey, callback, preservePrice = false }) => {
    setItems(prev => {
      const newItems = prev.filter(i => i.rowKey !== rowKey);
      const deleted = prev.find(i => i.rowKey === rowKey);
      logEdit({ deleted, preservePrice });

      setTimeout(() => callback?.(newItems), 100);
      return newItems;
    });
    
  }, [ logEdit ]);

  // Row Method
  const moveRow = (rowKey, direction) => {
    const { index, item } = getRow(rowKey);
    const moveTo = index + (direction === 'up' ? -1 : direction === 'down' ? 1 : 0);
    
    if (!isRowMoveable(item, index, moveTo))
      return;

    setItems(prev => {
      const prevIndex = prev.findIndex(x => x.rowKey === rowKey);

      const newItems = [...prev];
      newItems[moveTo] = prev[prevIndex];
      newItems[prevIndex] = prev[moveTo];
      return newItems;
    });
  };

  // Row method
  const updateRow = (rowKey, values) => {
    const { item, index } = getRow(rowKey);
    const newItem = { ...item, ...values };

    delete newItem.error;

    if (values.vendorName && values.vendorName !== item?.vendorName) {
      delete newItem['vendorProductRule'];
      delete newItem['vendorReference'];
      delete newItem['pricingData'];
      delete newItem['pricing_source'];
      delete newItem['features'];
    }

    setItems(prev => {
      const newItems = [...prev];
      newItems[index] = newItem;
      return newItems;
    });

    logEdit({ 'update': values });
  }

  const updateLocation = (rowKey, loc) => {
    const { item, index } = getRow(rowKey);
    const newLocValue = getLocationValue(loc);
    const prevLocValue = getLocationValue(item);
    const isEmptyLoc = l => !l || ['id', 'data-center-url', 'string_address'].every(v => !l[v]);
    const isSame = isSameLocation(prevLocValue, newLocValue) || (isEmptyLoc(prevLocValue) && isEmptyLoc(newLocValue));

    const newItem = { 
      ...items[index], 
      ...newLocValue,
      label: loc?.label || null,
      value: loc,
      defaultValue: loc,
    };

    !isSame && logEdit({ 'update': `${JSON.stringify(prevLocValue)} -> ${JSON.stringify(newLocValue)}` });

    setItems(prev => {
      const newItems = [ ...prev ];
      newItems[index] = newItem;
      return newItems;
    });

    if (item.isLocationZ) {
      updateLocationZ(loc);
    }
  }

  const mapServerErrorsToRows = (design_items=[]) => {
    // Clean copy of items
    const newItems = [...items];

    // Filter out locations
    const nonLocationItems = items.filter(x => x.type !== 'location');

    // Map error to design item if it exists
    const mapErrorToItem = ([errorIndex, error]) => {
      const errorEntry = Object.entries(error)?.[0];
      
      if (!errorEntry) return;
      const [errKey, errMsg] = errorEntry;

      const rowKey = nonLocationItems[+errorIndex].rowKey;
      const { index } = getRow(rowKey);

      newItems[index].error = { [errKey] : errMsg.join() };
    }

    Object.entries(design_items).forEach(mapErrorToItem);
    setItems(newItems);
  };

  const clearRows = () => {
    setItems(!props.id ? [] : items);
  };

  const getNewRowPositionIndex = useCallback((index=-1, type) => {
    // Determine position of new row
    const locZIndex = items.findIndex(x => x.isLocationZ);
    const lastLocIndex = (locZIndex !== -1) 
      ? locZIndex 
      : findLastIndex(items, x => (x.type === 'location'));

    const pos = (
      (index > -1) 
        ? index : 
      (UNGROUPED_TYPES.includes(type) || items[lastLocIndex]?.internetType)
        ? lastLocIndex 
        : items.length
    );

    return pos;
  }, [ items ]);

  const isRowMoveable = (item, index, nextIndex) => {
    const isMoveable = (
      index !== nextIndex
      // Check valid array index
      && !!items[nextIndex] && nextIndex
      // Check that location_z is not internet 
      && !items[nextIndex]?.internetType 
      // Check that item type is not confined to node group
      && ((
        UNGROUPED_TYPES.includes(item?.type) 
        && nextIndex < items.length 
        && UNGROUPABLE_DESIGN_TYPES.includes(item?.type)
        ) || !GROUPABLE_BOUNDRY_TYPES.includes(items[nextIndex]?.type)
      )
    );

    return isMoveable;
  };

  const numVal = (event) => Number(event.target.value) || 0;

  const onChangeRow = (rowKey) => {
    const update = val => updateRow(rowKey, val);
    const { item } = getRow(rowKey);

    const isUnitasProvidedType = () => {
      return UNITAS_PROVIDER_TYPES.includes(item.type)
    };
    
    return {
      lookup: ({ label, value }) => (isUnitasProvidedType()
        ? update({ vendorUrl: unitasUrl, vendorProduct: label })
        : update({ 
          vendorUrl: value, 
          vendorName: label, 
          vendorProductRule: item.type === "ipv4-addresses" 
            ? item.vendorProductRule
            : undefined 
        })
      ),
      product: event => update({ vendorProduct: event.currentTarget.value }),

      proximityA: option => update({ proximityA: (option?.value || null) }),
      proximityZ: option => update({ proximityZ: (option?.value || null) }),
      upload: event => update({ upload: event.target.value }),
      
      mrc: event => update({ mrc: numVal(event) }),
      nrc: event => update({ nrc: numVal(event) }),
      mrv: event => update({ mrv: numVal(event) }),
      nrv: event => update({ nrv: numVal(event) }),
      externalMrc: event => update({ externalMrc: numVal(event) }),
      externalNrc: event => update({ externalNrc: numVal(event) }),
      externalCost: ({ externalMrc, externalNrc }) => update({ externalMrc, externalNrc }),
      bandwidth: option => update({ bandwidth: option }),
      port: option => update({ port: option }),
      term: option => update({ term: option }),
      ipv4: option => update({ 
        ipv4: option,
        vendorProduct: 'IP Address',
        vendorProductRule: `IP Address ${option.value.replace('cidr-', '/')}`,
      }),
      location: option => updateLocation(rowKey, option),
    }
  };

  const updateSelectedRowOptions = useCallback((opts) => {
    const quote = { bandwidth, port, term };
    const byLabel = (arr, label) => arr.find(x => x.label === label);
    const byValue = (arr, value) => arr.find(x => x.value === value);

    const findDefault = (key, item) => item[key]
      ? byLabel(opts[key], item[key])
      : byValue(opts[key], quote[key]);


    setItems(prev => {
      return prev.map(item => ({
        ...item,
        ...(item.type !== 'location' && {
          bandwidth: findDefault('bandwidth', item),
          port: findDefault('port', item),
          term: findDefault('term', item),
        }),
      }));
    });
  }, [ bandwidth, port, term ]);

const newRow = useCallback(({ type, rowKey }) => {
  const hasBandwidth = type !== 'ipv4-addresses';

  const quote = {
    bandwidth: hasBandwidth ? bandwidth : 'none',
    port: hasBandwidth ?  port : 'none',
    term 
  };

  const objArr = quoteOptionsRef.current;

  const fromDesignLabel = (key) => objArr[key].find(x => x.label === design[key]);
  const fromQuoteValue = (key) => objArr[key].find(x => x.value === quote[key]);
  const findDefaultOption = key => design[key] ? fromDesignLabel(key) : fromQuoteValue(key);

  const findMinPort = (opts=[], b) => opts.reduce((acc, opt) => (
    opt.sortVal >= b && (!acc || opt.sortVal < acc.sortVal) ? opt : acc
  ), undefined);

  const defaultBandwidth = findDefaultOption('bandwidth');

  const defaultPort = (port === 'least-cost') 
    ? findMinPort(quoteOptionsRef.current.port, defaultBandwidth.sortVal)
    : findDefaultOption('port');

  const row = {
    type,
    rowKey,
    ...(type !== 'location' && {
      mrc: 0,
      nrc: 0,
      nrr: 0,
      externalMrc: null,
      externalNrc: null,
      bandwidth: defaultBandwidth,
      port: defaultPort,
      term: findDefaultOption('term'),
    }),
    ...(type === 'royalty' && {
      vendorProduct: `Backbone Royalties`,
    }),
  };

  return row;
  }, [ bandwidth, port, term, design ]);

  const itemTotals = useMemo(() => calculateItemTotals(items), [items]);
  
  return {
    isLoading,
    items,
    itemTotals,
    options,
    mapServerErrorsToRows,
    addRow,
    moveRow,
    deleteRow,
    updateRow,
    clearRows,
    hasEdits,
    editLog,
    clearEdits,
    unitasUrl,
    onChangeRow,
    rowOptions: options,
  }
};


export default useDesignItemsManager;

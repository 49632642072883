import React from 'react';
import classNames from 'classnames';

// load /all/ icons from within the icons directory and its subdirectories
const icons = {};
const importAll = (r) => {
  r.keys().forEach((k) => {
    icons[k.substring(k.lastIndexOf('/') + 1, k.lastIndexOf('.svg'))] = r(k).default.id;
  });
};
importAll(require.context('!svg-sprite-loader!../../../icons', true, /\.(svg)$/));

// TODO do better at handling this error than try/catching it
const kebabify = (s) => {
  try {
    return s.split(/[^a-zA-Z0-9]+/).join('-').toLowerCase();
  } catch (e) {
    return '';
  }
};


// TODO: ICON POSITIONING IS JANK BECAUSE OF LINE-HEIGHT
/*
.icon {
  line-height: initial;

  svg { vertical-align: middle }
}


*/
export default function Icon(props) {
  const {
    src,
    children,
    color,
    name,
    title, // used for screenreaders and hover state
    role,
    width,
    height,
    className,
    defaultIcon,
    spinning,
    small,
    big,
    giant,
    huge,
    onClick
  } = props;

  const usedName = kebabify(name);
  const classes = classNames(
    'icon',
    {
      [`icon-${usedName}`]: name,
      small,
      big,
      giant,
      huge,
      spinning,
    },
    className,
  );

  const style = { color, ...(props.style || {}) };

  // replace missing icons (if we're using a name and not an image or
  // props.children) with a 'missing' icon and kvetch on the console. If
  // there's a defaultIcon (string) provided, silently replace the missing
  // name.
  let usedIcon;
  if (usedName && icons[`${usedName}`]) {
    usedIcon = icons[`${usedName}`];
  } else if (defaultIcon) {
    usedIcon = icons[defaultIcon];
  } else {
    usedIcon = icons.missing;
    console.warn(`Warning: an Icon components’s svg file is missing: "${usedName}.svg" (name="${name}").\nIs this a typo?`);
  }

  if (src) {
    return (
      <img
        style={style}
        src={src}
        alt={title ? title : ''}
        className={classes}
        width={width}
        height={height}
      />
    );
  } else if (children) {
    return <span className={classes} role={role} style={style}>
        <svg width={width} height={height}>
          {title && <title>{title}</title>}
          {title && <desc>{title}</desc>}
          {children}
        </svg>
      </span>;
  } else {
    return <span className={classes} role={role} style={style} onClick={onClick}>
        <svg width={width} height={height}>
          {title && <title>{title}</title>}
          {title && <desc>{title}</desc>}
          <use xlinkHref={`#${usedIcon}`} />
        </svg>
      </span>;
  }
}

Icon.defaultProps = {
  role: 'img', // or 'decorative' if it's a fillip on text and not needed itself
  width: '1em',
  height: '1em',
};

import React from 'react';
import classNames from 'classnames';

import './tile.scss';

const Tile = ({ children, className }) => (  
  <div className={classNames('tile', className)}>
    {children}
  </div>
);

export default Tile;
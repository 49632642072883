// One stop shop for branding.

// image file imports:
import _NexusNameAndLogoSvg from 'Static/images/brands/resolute/nexus-color-no-background-logo.svg';
import _NexusNameAndLogoWhiteSvg from 'Static/images/brands/resolute/nexus-white-no-background-logo.svg';
import _NexusFavicon from 'Static/images/brands/resolute/resolute-favicon.png';
import _NexusPricingFavicon from 'Static/images/brands/resolute/resolute-favicon-orange.png';
import _NexusQuotedFavicon from 'Static/images/brands/resolute/resolute-favicon-green.png';
import _NexusManualFavicon from 'Static/images/brands/resolute/resolute-favicon-red.png';
// Image import for email use in UGCORE only.  See CCM-6556 and CCM-6549.  Note that
// while we don't use it in this repo, it must be published as part of the UI build so it can
// be found here:  https://www.unitascloud.com/src/static/images/brands/resolute/nexus-color-white-background.png
import _NexusNameAndLogoPng from 'Static/images/brands/resolute/nexus-color-white-background.png';

import _UnitasNameAndLogoSvg from 'Static/images/brands/unitas/logo-notag-original.svg';
import _UnitasNameAndLogoWhiteSvg from 'Static/images/brands/unitas/unitas-logo-white.svg';
import _UnitasFavicon from 'Static/images/brands/unitas/favicon.png';
import _UnitasPricingFavicon from "Static/images/brands/unitas/favicon-orange.png";
import _UnitasQuotedFavicon from "Static/images/brands/unitas/favicon-green.png";
import _UnitasManualFavicon from "Static/images/brands/unitas/favicon-red.png";

// Set the brand type depending on branch, default to resolute.
let brand = 'resolute';
if (process.env.CI_COMMIT_REF_NAME === 'release-unitas'){
    brand = 'unitas';
}

// Configurable branding values, Resolute / Nexus defaults:
let _defColorSvg = _NexusNameAndLogoSvg;
let _fullWhiteLogo = _NexusNameAndLogoWhiteSvg;
let _defFavicon = _NexusFavicon;
let _pricingFavicon = _NexusPricingFavicon;
let _quotedFavicon = _NexusQuotedFavicon;
let _manualFavicon = _NexusManualFavicon;
let _productName = 'Resolute Nexus';
let _systemName = 'Nexus';
let _companyShortName = 'Resolute-CS';
let _internalCompanyName = 'Internal';
let _legalCompanyName = 'Resolute Connect Systems LLC';
let _companyWebLink = 'https://resolute-cs.com';
let _navBarLogoHeight = '40px';
let _loginLogoHeight = '58px';
// Currently only one other brand, unitas.
if (brand === 'unitas'){
    _defColorSvg = _UnitasNameAndLogoSvg;
    _fullWhiteLogo = _UnitasNameAndLogoWhiteSvg;
    _defFavicon = _UnitasFavicon;
    _pricingFavicon = _UnitasPricingFavicon;
    _quotedFavicon = _UnitasQuotedFavicon;
    _manualFavicon = _UnitasManualFavicon;
    _productName = 'Unitas Global';
    _systemName = 'CCM';
    _companyShortName = 'Unitas Global';
    _internalCompanyName = 'Unitas Global';
    _legalCompanyName = 'Unitas Global';
    _companyWebLink = 'https://unitasglobal.com';
    _navBarLogoHeight = '30px';
    _loginLogoHeight = '48px';
}

// Exports for use in other parts of the code base.
// No branding specific changes should be needed here.
export const DefSvgFullColorLogo = _defColorSvg;
export const DefFullLogoName = 'defaultFullLogo';
export const NavBarLogoHeight = _navBarLogoHeight;
export const LoginLogoHeight = _loginLogoHeight;
export const DefFavicon = _defFavicon;
export const DefFaviconName = "shortcut icon";
export const PricingFavicon = _pricingFavicon;
export const QuotedFavicon = _quotedFavicon;
export const ManualFavicon = _manualFavicon;

export const SvgFullWhiteLogo = _fullWhiteLogo;
export const FullWhiteLogoName = 'whiteFullLogo';

export const APP_PRODUCT_NAME = _productName;
export const SYSTEM_NAME = _systemName;

// For now set the page title to the product name, but it might want to be different in the future.
export const PAGE_TITLE_NAME = APP_PRODUCT_NAME;
export const COMPANY_SHORT_NAME = _companyShortName;
// The internal company name is used in places to inform the user that certain items
// may only be viewable or used by those in / matching the below.
export const INTERNAL_COMPANY_NAME = _internalCompanyName;
export const COMPANY_LEGAL_NAME = _legalCompanyName;
export const COMPANY_WEB_LINK = _companyWebLink;

// Emails
export const PRICING_SUPPORT_EMAIL = 'connectivitypricing@unitasglobal.com';
export const TECH_SUPPORT_EMAIL = 'support@unitascloud.com';

import React from 'react';
import Background from 'Static/images/banner-top-right-corner.svg';

import './banner.scss';

/**
 * Throws a banner into the top right corner of a block-level element it
 * belongs to.
 *
 * @param {ReactNode | String} content - the content of the banner
 */
const Banner = ({ content }) =>
  (
    <div className="banner">
      <img src={Background} alt="" />
      <div className="banner-content">
        {content}
      </div>
    </div>
  );

export default Banner;

import React, { createContext, forwardRef, useCallback, useContext, useRef } from 'react';
import { ModalManager } from 'Molecules';

const ModalContext = createContext(null);

export const ModalProvider =({ children }) => {
  const modalRef = useRef();

  const showModal = useCallback((name, options) => {
    return item => { 
      const { ref, onClick, ...contextMenuData } = (item?.props || {});
      modalRef?.current?.open(name, { ...contextMenuData, ...options });
    }
  }, []);

  return (
    <ModalContext.Provider value={{ showModal }}>
      <ModalManager ref={modalRef} />
      {children}
    </ModalContext.Provider>
  )
}

export const ModalConsumer = ModalContext.Consumer;
export const useModalContext = () => useContext(ModalContext);

export const withModalContext = (WrappedComponent) => (forwardRef((props, ref) => (
  <ModalConsumer>
    { context => <WrappedComponent ref={ref} {...context} {...props} /> }
  </ModalConsumer>
)));

export default ModalContext;
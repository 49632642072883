import React from 'react'
import { Icon } from 'Atoms';
import { getQuoteStatus } from 'Utils/formatter.js';

const StatusIcon = (props) => {
  const { small, status, className='' } = props;
  
  const { icon, text } = getQuoteStatus(status) || {};
  if (!icon || !text) {
    return null;
  }

  const iconName = `${icon}${small ? '-small' : ''}`;

  return (
    <span className={`status-icon status-icon--${status} ${className}`}>
      <Icon name={iconName} />
      <span className="status-icon__text">{props.text || text}</span>
    </span>
  )
}

export default StatusIcon;
import React, { Component } from 'react';
import classNames from 'classnames';

import './Accordion.scss';

/**
 * Creates an accordion component. cf. AccordionPanel, which it uses, but does
 * not require. Although it /expects/ it.
 *
 * @param { boolean } allowAllClosed - Can every panel be closed, or must one
 * always remain open?
 * @param { string | array | object } className - additional classes. See the
 * classnames npm docs for more info.
 * @param { string } context - What is the context of the Accordion, i.e., how
 * should it appear? Possible values are:
 * - `undefined`: the default
 * - "navigation": in the sidebar or global navigation
 * - "inline": a smaller version used within text
 * - "table": a smaller version, for accordions within a table
 * - "widget": a very small version, for use in widgets
 * Additional styling can be added with classes, of course.
 * @param { string | array } expanded - A list of the panels that are expanded
 * @param { boolean } multiExpand - Allow multiple panels to be open at one
 * time. Otherwise, opening panel A will close open panel B.
 * @param { function } onChange(event, name) - a callback function
 * triggered when a panel is expanded. `name` is the name of the panel. N.b:
 * this is _only_ fired when a closed panel is expanded, with the name of the
 * newly-opened panel.
 * @param { boolean } radio - a version with radio buttons in the title.
 * Expanded panels will have the radio button selected. This should _not_ also
 * have the multiExpand prop set.
 * @param {Boolean} small - a smaller version of the accordion
 */

class Accordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: this.props.expanded ? [].concat(this.props.expanded) : [],
    };

    this.togglePanel = this.togglePanel.bind(this);
    this.passPropsToChildren = this.passPropsToChildren.bind(this);
  }

  //  TODO: HACK HACK HACK HACK HACK
  componentWillReceiveProps(nextProps) {
    if (nextProps.allClosed) {
      this.setState({ expanded: [] });
    }
    if (nextProps.expanded){
      this.setState({expanded: [nextProps.expanded]})
    }
  }

  togglePanel(event, name, disabled) {
    // this.props.allowAllClosed
    event.preventDefault();
    if (disabled) { return; }

    // we're treating this as a set, kinda: it's bizarre.
    // if we have multi-expand and all-closeable, we just add/remove the
    // elements. Otherwise, we unset to the front of the deque, and trim
    // as needed.

    const { expanded } = this.state;
    const { allowAllClosed, onToggle, onChange, multiExpand } = this.props;
    if (expanded.includes(name)) {
      if (expanded.length > 1 || allowAllClosed) {
        // close an expanded panel
        onToggle && onToggle(name, true);
        expanded.splice(expanded.indexOf(name), 1);

      }
    } else {
      // expand a closed panel
      onToggle && onToggle(name, false);
      expanded.unshift(name);
      // and fire `onChange` if its present
      if (onChange) {
        onChange(event, name);
      }
    }

    if (!multiExpand) {
      expanded.splice(1); // trim to a 1-element array
    }

    this.setState({ expanded });
  }

  passPropsToChildren(child) {
    if (!child) return null;

    const { radio, small, toggle } = this.props;
    const { name, disabled } = child.props;

    const childProps = {
      expanded: this.state.expanded.includes(name),
      onClick: (e) => this.togglePanel(e, name, disabled),
      radio,
      small,
      toggle
    };

    return React.cloneElement(child, childProps);
  }

  render() {
    const { context, className, radio, small, children } = this.props;
    const accordionClassName = classNames('accordion', context, className, { radio, small });
    const ChildrenWithProps = React.Children.map(children, this.passPropsToChildren);


    return (
      <div className={accordionClassName}>
        {ChildrenWithProps}
      </div>
    );
  }
}

export default Accordion;
import React, { useState } from 'react';
import { Button } from 'Atoms';
import { UserLookup } from 'Molecules';
import { generateUgToken } from 'Actions/ccm/api.js';
import { STORAGE_KEYS } from 'Utils/const.js';

import './UgLoginForm.scss';

const UgLoginForm = () => {
  const [user, setUser] = useState(null);
  const disabled = !user;

  const submit = () => {
    generateUgToken({userId: user.value}).then(({ access }) => {
      if (access) {
        localStorage.removeItem(STORAGE_KEYS.CCM_USER);
        localStorage.removeItem('okta-cache-storage');
        localStorage.removeItem('okta-token-storage');
        // Using window.location here instead of Redirect to force a rerender
        // of AuthProvider
        window.location.href = window.origin + `/implicit/callback#access_token=${access}`;
      } else {
        alert("There was an error generating the token. Please check that the user has permission to perform this action and try again.");
      }
    });
  }

  return (
    <div className="ug-login-form">
      <div className="form-title">Unitas Login POC</div>
      <div className="form-description">Welcome to the Unitas Login POC page. Please select a user to log in as for testing.</div>

      <UserLookup
        onChange={(u) => setUser(u)} 
        includePermissions={false} 
        isClearable={true} 
        managedUsers={false}
      />

      <div className="ug-form-submit-container">
        <Button buttonType="submit" disabled={disabled} children="Generate Token" onClick={submit} />
      </div>
    </div>
  )
}

export default UgLoginForm;

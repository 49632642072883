import React, { useRef, useState, useEffect } from 'react';
import CN from 'classnames';
import { Icon, Button } from 'Atoms';

import './Notification.scss';
import 'Sass/animate.scss';

const NotificationComponent = props => {
  const { className='', icon, children, onDismiss, duration } = props;
  const containerRef = useRef(null);

  const [ dismissed, setDismissed ] = useState(false);

  useEffect(() => {
    duration && setTimeout(() => setDismissed(true), duration);
  }, [duration]);

  useEffect(() => {
    const callback = () => onDismiss && onDismiss();
    dismissed && setTimeout(callback, 800);
  }, [dismissed, onDismiss]);

  const classNames=`notification ${className} animated fast ${dismissed ? 'fadeOutUp' : 'fadeInDown'}`;

  // Set fontSize of notification so that css transitions can use 1em to represent height of element (ie: cookie-banner)
  const style= dismissed ? {'fontSize': containerRef.current && containerRef.current.clientHeight + 'px'} : undefined;
  const onClick = () => setDismissed(true);

  return (
    <div className={classNames} style={style} ref={containerRef}>
      { !!icon && <Icon name={icon} /> }
      <span className="notification__content" children={children} />
      { !!onDismiss && <Button className="notification__dismiss-button" type="inline" onClick={onClick} icon="close" /> }
    </div>
  )
}

const ErrorNotification = ({ className='', ...props }) => (
  <NotificationComponent icon='exclamation-point-circle-outline' className={`notification--error ${className}`} {...props} />
);

const InfoNotification = ({ className='', ...props }) => (
  <NotificationComponent icon='info-circle-outline' className={`notification--info ${className}`} {...props} />
);

const SuccessNotification = ({ className='', ...props }) => (
  <NotificationComponent icon='checkmark-circle-outline' className={`notification--success ${className}`} {...props} />
);

const WarningNotification = ({ className='', ...props }) => (
  <NotificationComponent icon='warning-outline' className={`notification--warning ${className}`} {...props} />
);

const notify = ({ type, ...props }) => {
  switch (type) {
    case 'error':
      return <ErrorNotification {...props} />;
    case 'info': 
      return <InfoNotification {...props} />;
    case 'success': 
      return <SuccessNotification {...props} />;
    case 'warning':
      return <WarningNotification {...props} />;
    default:
      return <NotificationComponent {...props} />;
  }
}

export const Notification = {
  Error: ErrorNotification,
  Info: InfoNotification,
  Success: SuccessNotification,
  Warning: WarningNotification,
  notify,
};

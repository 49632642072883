import React from 'react';
import { getInternetType } from 'Utils/ccm.js';

import {
  BillingContactOrderSection,
  CustomerInformationOrderSection,
  OrderSection,
  DetailsOrderSection,
  ContactOrderSection,
  TechnicalContactOrderSection,
  //EthernetInterconnectInfoOrderSection, 
  EthernetInfoOrderSection,
  InternetInfoOrderSection,
  LocationOrderSection,
  NotesOrderSection,
  DocuSignOrderSection,
  TermsConditionsOrderSection,
  EcxEthernetInfoOrderSection,
  IpRoutingOrderSection,
} from './';

const EthernetOrderForm = ({ locationA, locationZ, product, docusignApprovers }) => {
  const docusignEnabled = docusignApprovers.length;

  return (
    <>
      <DetailsOrderSection />
      <ContactOrderSection />
      <EthernetInfoOrderSection product={product} full />
      <LocationOrderSection locationA={locationA} />
      <LocationOrderSection locationZ={locationZ} />
      <NotesOrderSection full={!docusignEnabled} />
      {!!docusignEnabled && <DocuSignOrderSection docusignApprovers={docusignApprovers} /> }
      <TermsConditionsOrderSection />
    </>
  )
};

const InternetOrderForm = ({ locationA, docusignApprovers }) => {
  const docusignEnabled = docusignApprovers.length;

  return (
    <>
      <DetailsOrderSection />
      <ContactOrderSection />
      <InternetInfoOrderSection full />
      <LocationOrderSection locationA={locationA} />
      <NotesOrderSection full={!docusignEnabled} />
      {!!docusignEnabled && <DocuSignOrderSection docusignApprovers={docusignApprovers} /> }
      <TermsConditionsOrderSection />
    </>
  );
};

const EcxEthernetOrderForm = ({ locationA, locationZ={}, product }) => {
  return (
    <>
      <DetailsOrderSection />
      <ContactOrderSection />
      <EcxEthernetInfoOrderSection product={product} full />
      <LocationOrderSection header="From Location" locationA={locationA} isEcx={true} />
      <OrderSection header="Equinix Fabric Location" children={locationZ.displayName} full />
      <NotesOrderSection />
      <TermsConditionsOrderSection />
    </>
  )
};

const IpOrderForm = ({ ipv4 }) => {
  return (
    <>
      <DetailsOrderSection />
      <ContactOrderSection />
      <IpRoutingOrderSection ipv4={ipv4} />
      <CustomerInformationOrderSection />
      <TechnicalContactOrderSection />
      <BillingContactOrderSection />
      <NotesOrderSection />
      <TermsConditionsOrderSection isIpTransit={true} />
    </>
  )
};

/* Not used yet 
const EthernetInterconnectOrderForm = () => {
  const locationComment = "Unitas Global will provide the LOA/CFA. Cross-connect will be provided by the customer. Customer is required to provide cross-connect completion notice to Unitas Global.";

  return (
    <>
      <DetailsOrderSection />
      <ContactOrderSection full={false} />
      <TechnicalContactOrderSection />
      <EthernetInterconnectInfoOrderSection full />
      <LocationOrderSection locationA={locationA} />
      <LocationOrderSection locationZ={locationZ} header="Unitas Global Location" comment={locationComment} />
      <NotesOrderSection />
      <TermsConditionsOrderSection />
    </>
  );
}
*/

export default function OrderForm({ designType, docusignApprovers, locationA, locationZ, product, isEcx, ipv4 }) {
  const isEcxIp = isEcx && !designType.indexOf('ip-transit');
  const isInternet = !!getInternetType(designType);

  const OrderFormComponent = (
    (isEcxIp) ? IpOrderForm : 
    (isEcx) ? EcxEthernetOrderForm :
    (isInternet) 
      ? InternetOrderForm
      : EthernetOrderForm
  );

  return (
    <OrderFormComponent locationA={locationA} locationZ={locationZ} product={product} designType={designType} docusignApprovers={docusignApprovers} ipv4={ipv4} />
  )
}

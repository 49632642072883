import React from "react";
import { Helmet } from "react-helmet-async";

import { Button } from "Atoms";
import { isEcxContext, isPFContext } from 'Utils/auth.js';
import { SUPPORT_EMAILS } from 'Utils/const.js';
import { DefSvgFullColorLogo, DefFavicon } from 'Utils/branding.js';

import NotFoundImage from "Static/images/404.svg";

import "./error_pages.scss";

const GenericNotFoundPage = () => {
  let mailTo = SUPPORT_EMAILS.UNITAS_GLOBAL;
  if (isEcxContext()) mailTo = SUPPORT_EMAILS.EQUINIX_FABRIC;
  if (isPFContext()) mailTo = SUPPORT_EMAILS.PACKETFABRIC;

  return (
    <div className="container">
      <Helmet>
        <title>404 Not Found</title>
        <body className="error-page not-found-page" />
        <link rel={DefFaviconName} type="image/png" href={DefFavicon} />
      </Helmet>

      <div className="header patterned">
        <img src={NotFoundImage} alt="404" className="banner" />
        <h1>We couldn't find this page.</h1>
      </div>

      <div className="message">
        <p>
          This probably isn’t the page you were looking for, and we are unsure
          what page you were trying to find. Let’s try again, please head to
          the home page to navigate further.
        </p>
        <div className="flex-columns nav-buttons">
          <Button type="transparent" link="/">
            Return to Home
          </Button>
          <a href={`mailto:${mailTo}`}>
            <Button type="transparent">
              Report Issue
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default GenericNotFoundPage;

import React, { useState } from 'react';
import { LoadingOverlay } from 'Atoms';
import { Popup } from 'Molecules';
import { deleteQuote } from 'Actions/ccm/api.js';

import './BulkDeleteModal.scss';

const BulkDeleteModal = props => {
  const { quotes, onConfirm, onClose } = props;
  const [ isFetching, setIsFetching ] = useState(false);

  const deleteAll = async () => {
    setIsFetching(true);
    const bulkDelete = quotes.map(q => deleteQuote(q.id));
    await Promise.all(bulkDelete).then(onConfirm);
    setIsFetching(false);
    onClose();
  }

  const popupProps = {
    label: `Delete Quotes (${quotes.length})`,
    button1: { label: 'No, do not delete', onClick: onClose },
    button2: { label: 'Yes, delete', primary: true, onClick: deleteAll },
    onClose,
    id: 'bulk-delete-modal'
  }

  return (
    <Popup {...popupProps}>
      {!!isFetching && <LoadingOverlay chidren="Deleting quotes..." /> }
      <div>Are you sure you want to delete the selected quotes?</div>
    </Popup>
  );
}

export default BulkDeleteModal;
import React, { useState } from 'react';
import { TextInput } from 'Atoms';
import { Popup } from 'Molecules';
import { updateAttachment } from 'Actions/ccm/api.js';
import { useForm, FormProvider } from 'react-hook-form';

import './DescriptionModal.scss';

const DescriptionModal = props => {
  const { onConfirm, onClose, quoteId, attachmentId } = props;
  const prevDescription = props.description;

  const [ error, setError ] = useState();
  const [ isLoading, setIsLoading ] = useState(false);

  const formMethods = useForm();
  const description = formMethods.watch('attachmentDescription');

  const onSubmit = async () => {
    if (description === prevDescription) return onClose();

    event.preventDefault();
    setIsLoading(true);
    
    const resp = await updateAttachment(quoteId, attachmentId, { description });

    setIsLoading(false);
    resp.error 
      ? setError('Error updating attachment description')
      : onConfirm(resp);
  };

  const button1 = {
    label: "Cancel",
    onClick: onClose,
    secondary: true
  };

  const button2 = {
    label: "Submit",
    onClick: formMethods.handleSubmit(onSubmit),
    disabled: !!isLoading,
    primary: true,
  };

  return (
    <Popup label="Attachment" onClose={onClose} button1={button1} button2={button2} id="fileAttachmentDescriptionPopup">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TextInput 
            placeholder="Enter a Description"
            id="attachmentDescription"
            name="attachmentDescription"
            register={formMethods.register}
            required
            maxLength="200"
            autoFocus
            type="textarea"
            error={error}
            defaultValue={prevDescription}
        />
        </form>
      </FormProvider>
    </Popup>
  );
}

export default DescriptionModal;

import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import CN from 'classnames';
import { acceptUserTerms } from 'Actions/ccm/api.js';
import UserContext from 'Context/UserContext.js';

import { ModalProvider } from 'Context/ModalContext.js';

import { Notification } from 'Atoms';
import { Navbar, Sidebar } from 'Organisms';
import LayoutRouter from 'Routers/LayoutRouter.jsx';
import EcxRouter from 'Routers/EcxRouter.jsx';
import PacketFabricRouter from 'Routers/PacketFabricRouter.jsx';
import { useToggle } from 'Hooks';
import { isEcxContext, isPFContext } from 'Utils/auth.js';
import { COMPANY_SHORT_NAME } from 'Utils/branding.js';

const BANNER_TYPES = {
  EXPIRED: 'EXPIRED',
  UNACCEPTED: 'UNACCEPTED',
}

const Layout = ({ fetchUser }) => {
  const [ sidebarExpanded, toggleSidebar ] = useToggle(false);
  const [ showBanner, setShowBanner ] = useState(false);

  const user = useContext(UserContext) || {};
  const isEcx = isEcxContext() || user.isEcx;
  const isPF = isPFContext() || user.isPF;

  let context = undefined;
  if (isEcx) context = 'ecx';
  else if (isPF) context = 'packetfabric';
  const className = CN('secure-container', { [`secure-container--${context}`]: !!context });

  const onDismiss = () => {
    acceptUserTerms(user.id).then(fetchUser);
    setShowBanner(false);
  };


  useEffect(() => {
    const termsNotAccepted = user.policies_accepted_time === null;
    const termsExpired = user.policies_accepted_time 
      && new Date(user.policies_accepted_time) < new Date(process.env.USAGE_TERMS_UPDATE_DATE);

    termsNotAccepted && setShowBanner(BANNER_TYPES.UNACCEPTED);
    termsExpired && setShowBanner(BANNER_TYPES.EXPIRED);
  }, [user.policies_accepted_time]);

  let Router = <LayoutRouter />;
  if (isEcx) Router = <EcxRouter />;
  else if (isPF) Router = <PacketFabricRouter />;

  return (
    <div className={className}>
      <ModalProvider>
        <Navbar sidebarExpanded={sidebarExpanded} toggleSidebar={toggleSidebar} />
        <div className="secure-content">
          <Sidebar expanded={sidebarExpanded} toggleSidebar={toggleSidebar} />
          <div className="main">
            {!!showBanner && (
              <CookieBanner className="cookie-banner" onDismiss={onDismiss} bannerType={showBanner} />
            )}
            { user.id ? Router : null }
          </div>
        </div>
      </ModalProvider>
    </div>
  );
}

const CookieBanner = ({ bannerType, onDismiss }) => (
  <Notification.Info onDismiss={onDismiss}>
  {(bannerType === BANNER_TYPES.UNACCEPTED) && (
    <>This site uses cookies. Your information will not be shared with {COMPANY_SHORT_NAME} unless you specifically allow it. View our <a href="/usage-and-privacy" target="_blank">Acceptable Use & Privacy Policy</a> to learn more.</>
  )}
  {(bannerType === BANNER_TYPES.EXPIRED) && (
    <>Our Terms & Conditions have been updated. Please take a moment to <a href="/terms-and-conditions" target="_blank">view them here.</a></>
  )}
  </Notification.Info>
)

export default Layout;
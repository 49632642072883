import React from 'react';
import { useUserContext } from 'Context/UserContext.js';
import SVG from 'react-inlinesvg';

import { Icon, NotificationArea, SearchInput } from 'Atoms';
import { DefSvgFullColorLogo, DefFullLogoName, NavBarLogoHeight } from 'Utils/branding.js';
import Logo365dc from 'Static/images/logo-365-data-centers.jpg';

import './navbar.scss';

const Navbar = (props) => {
  const user = useUserContext();
  const { toggleSidebar, sidebarExpanded } = props;
  
  const Logo = (
    (user.organization_name === '365 Data Centers')
      // 365 Data Centers logo
      ? <img src={Logo365dc} height="54px" alt="365 Data Centers Logo" />
      // Default logo
      : <SVG height={NavBarLogoHeight} src={DefSvgFullColorLogo} alt={DefFullLogoName} />
  )

  return (
    <nav>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        <Icon name={sidebarExpanded ? "close-menu" : "menu"} className="sidebar-icon"/>
        <span className="title">Menu</span>
      </button>

      <h1>{ Logo }</h1>
      
      <div className="navbar-right">
        <SearchInput />
        {!!user.is_internal && <NotificationArea />}
      </div>
    </nav>
  );
}

export default Navbar;
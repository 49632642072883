import React from 'react';
import { Link } from 'react-router-dom';

import { useUserContext } from 'Context/UserContext.js';
import { Icon } from 'Atoms';
import { COMPANY_SHORT_NAME } from 'Utils/branding.js';
import { ORGANIZATION_HOMEPAGE } from 'Utils/const.js';

import './WhatsNext.scss';

const WhatsNext = (props) => {
  const { supportClick } = props;
  const { isEcx, isPF } = useUserContext();

  let orgDetails = {href: '', orgName: ''};
  if (isEcx) {
    orgDetails.href = ORGANIZATION_HOMEPAGE.EQUINIX_FABRIC;
    orgDetails.orgName = 'Equinix';
  } else if (isPF) {
    orgDetails.href = ORGANIZATION_HOMEPAGE.PACKETFABRIC;
    orgDetails.orgName = "PacketFabric";
  }

  return (
    <div className="whats-next">
      <header className="whats-next__header">
        <Icon name="question-mark" />
        <span>WHAT DO I DO NEXT?</span>
      </header>

      <ul className="whats-next__links">
        <li>
          <Link to="/">
            <Icon name="redo" />
            <div className="whats-next__link-text">Request another connection quote</div>
          </Link>
        </li>

        <li>
          <Link to="/quote-manager/">
            <Icon name="manage-quote" />
            <div className="whats-next__link-text">Manage my quote requests</div>
          </Link>
        </li>

        <li>
          <button className="button--transparent" onClick={supportClick}>
            <Icon name="question-mark" />
            <div className="whats-next__link-text">Request help</div>
          </button>
        </li>
        
        {!!isEcx || !!isPF && (
          <li>
            <a href={orgDetails.href}>
              <Icon name="stop" />
              <div className="whats-next__link-text">Go back to the<br /> {orgDetails.orgName} portal</div>
            </a>
          </li>
        )}
      </ul>
    </div>
  );
}

export default WhatsNext;
const LABELS = {
  port: 'Port',
  bandwidth: 'Bandwidth',
  term: 'Term',
  mrc: 'MRC',
  ug_mrr: 'MRR',
  mrr: 'MRR',
  nrc: 'NRC',
  ug_nrr: 'NRR',
  nrr: 'NRR',
};

const ORG_OVERRIDES = {
  'SungardAS': {
    mrc: 'SungardAS MRC',
    mrr: 'Listed MRR',
    nrc: 'SungardAS NRC',
    nrr: 'Listed NRR',
  }
};

export const getLabels = user => {
  return {
    ...LABELS,
    ...ORG_OVERRIDES[user.organization_name],
  }
};

export const PERMISSIONS = {
  BULK_COMMENT: 'ccm.comment_group',
  CANCEL_ORDER: 'ccm.cancel_quote_order',
  COPY_DESIGN: 'ccm.copy_design',
  DESIGN_APPROVE_CSA: 'ccm.csa_approve_design',
  DESIGN_APPROVE_ENGINEERING: 'ccm.network_engineering_approve_design',
  DESIGN_APPROVE_PRICING_DESK: 'ccm.pricing_desk_approve_design',
  DESIGN_REJECT_CSA: 'ccm.csa_reject_design',
  DESIGN_REJECT_ENGINEERING: 'ccm.network_engineering_reject_design',
  DOCUSIGN_APPROVER: 'ccm.approve_order',
  DOCUSIGN_ENABLED: 'ccm.orders_use_docusign',
  DOCUSIGN_PRIMARY_APPROVER: 'ccm.primary_order_approver',
  REPRICE_QUOTE: 'ccm.reprice_quote',
  IGNORE_ENDUSER_MARGINS: 'ccm.do_not_apply_end_user_margins_and_markups',
  VIEW_DESIGN_ITEMS: 'ccm.view_designitem',
  VIEW_INTERNAL_AMOUNTS: 'ccm.view_internal_amounts', // can see ug_mrr
  VIEW_LATLONG: 'ccm.view_location_lat_long_in_ui',
  VIEW_VENDOR_COSTS: 'ccm.view_design_vendor_costs', // can see ug mrc of non-unitas items
  VIEW_NON_SOLUTION_DESIGNS: 'ccm.view_non_solution_design',
  VIEW_RELATED_TAB: 'ccm.view_relatedquote',
  VIEW_SYSTEM_DIAGNOSTICS: 'accounts.view_system_diagnostics',
  VIEW_ORG_USERS: 'accounts.view_user:organization',
};

export const ROLES = {
  NETWORK_ENGINEER: 'network_engineer',
  SUNGARD_EMEA: 'sungardas_emea_users',
  SUNGARD_PRICING_DESK: 'sungard_pricing_desk',
};

export const STORAGE_KEYS = {
  CCM_USER: 'ccm-user',
  UG_TOKEN: 'ug-token-storage',
  OKTA_TOKEN: 'okta-token-storage',
  REFERRER_PATH: 'secureRouterReferrerPath',
  MSAL_LOGIN: 'msal-login',
  QUOTE_MANAGER_ALL_ORG_USERS: 'QUOTE_MANAGER_ALL_ORG_USERS',
  PACKETFABRIC_ACCESS_TOKEN: 'packetfabric-access-token',
  PACKETFABRIC_PORTAL_TOKEN: 'packetfabric-portal-token',
}

export const AUTH_TYPES = {
  MSAL: 'MSAL',
  OKTA: 'OKTA',
  UGCORE: 'UGCORE',
}

export const URLS = {
  QUOTE_MANAGER_FOLDER: '/quote-manager/:folderName',
  QUOTE_MANAGER: '/quote-manager',
  PRICING_DESK: '/pricing-desk',
  QUOTE_DESIGN: '/quote/:quoteRef/order/:designId',
  QUOTE: '/quote/:quoteRef',
  ADMIN: '/admin',
  HOME_PAGE: '/',
};

export const SUPPORT_EMAILS = {
  UNITAS_GLOBAL: 'support@unitascloud.com',
  EQUINIX_FABRIC: 'EquinixFabricConnectivity@unitasglobal.com',
  PACKETFABRIC: 'support@packetfabric.com',
};

export const UNITAS_GLOBAL_CONNECTIVITY_PRICING_EMAIL = 'connectivitypricing@unitasglobal.com';

export const ORGANIZATION_HOMEPAGE = {
  EQUINIX_FABRIC: 'https://fabric.equinix.com/dashboard',
  PACKETFABRIC: 'https://packetfabric.com',
};

import React, { useEffect, useState } from 'react';
import { Popup, UserLookup } from 'Molecules';

import './SelectUserModal.scss';

const SelectUserModal = (props) => {

  const [ user, setUser ] = useState();
  const { onClose, onConfirm, organizationId, className='' } = props;

  const popupProps = {
    className,
    label: 'Select user',
    id: 'select-users-popup',
    onClose,
    button1: { label: 'Cancel', onClick: onClose },
    button2: { label: 'Select User', primary: true, disabled: !user, onClick: () => onConfirm(user) },
  };

  return (
    <Popup {...popupProps} >
      <UserLookup onChange={(user) => setUser(user)} managedUsers={false} organization={organizationId} includeInternal={true} />
    </Popup>
  )
}

export default SelectUserModal;

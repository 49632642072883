import React, { Component, createRef } from 'react';
import { ContextMenuButton, Icon, LoadingOverlay, Pager, PagerStatus, QuoteTooltip, Notification, SortableTable, TabBadge, Tabs, TabPane, TimeAgo, Tooltip } from 'Atoms';
import { Link } from 'react-router-dom';

import { getQuotes } from 'Actions/ccm/api.js';

const COLUMN_DATA = [
  {
    key: 'status',
    header: ' ',
    render: ({ status, statusDetails }) => {
      const name = (status === 'review') 
        ? 'exclamation-point-triangle-filled'
        : 'exclamation-point-circle-outline';
      return (
        <Tooltip title={statusDetails}>
          <div><Icon className="pricing-desk__status-icon" name={name} /></div>
        </Tooltip>
      )
    },
    sortable: false
  },
  { key: 'name', render: data => (
    <>
      <QuoteTooltip {...data}>
        <Link to={`/quote/${data.reference}/`} children={data.name} />
      </QuoteTooltip>
      <div className="pricing-desk__quote-reference">Quote Reference: {data.reference}</div>
    </>
  )},
  { key: 'folder'},
  { keys: ['user', 'organization'] },
  { keys: ['updatedTime','createdTime'],
    headers: { updatedTime: 'Last Modified', createdTime: 'Date Created' },
    render: (data, { activeKey, inactiveKey }) => {
      const isSame = data.updatedTime === data.createdTime;
      const secondary = activeKey === 'updatedTime' ? 'Created ' : 'Modified ';

      return (<>
        <div className="sortable_table_column_data--primary"><TimeAgo date={data[activeKey]} /></div>
        {!isSame && (
          <div className="sortable_table_column_data--secondary">{secondary}<TimeAgo date={data[inactiveKey]} /></div>
        )}
      </>)
    }
  },
  { keys: ['assigned', 'followupCategory'],
    headers: { assigned: 'Assigned To', followupCategory: 'Followup' },
    render: ({ assigned, followupCategory }) => {
      return (<>
        <div className="sortable_table_column_data--primary">{assigned}</div>
        <div className="sortable_table_column_data--secondary">{followupCategory}</div>
      </>)
    }
  },
  {
    key: 'actions',
    header: ' ',
    sortable: false,
    render: ({ icon, ...data }) => <ContextMenuButton type={icon} {...data} />
  }
];

const toRowData = quote => {
  const { id, status, status_details, modified_time, name, folder_name, organization_name, partner_organization_name, user_name, reference, location_a, location_z, specification, assignee_email, assignee_name, create_time, follow_up_category } = quote;
  const { advanced=[], service_requirements, bandwidth, port_bandwidth, term=[] } = (specification || {}).option_selections;
  const routeDiverse = advanced.some(x => x === 'route-diverse' || x === 'route-diverse-same-carrier');

  return { 
    id,
    name,
    createdTime: create_time,
    updatedTime: modified_time,
    organization: partner_organization_name || organization_name, 
    user: user_name,
    folder: folder_name,
    reference,
    menuId: 'pricing-desk_menu',
    type: 'quote',
    locationA: location_a,
    locationZ: location_z,
    serviceRequirements: service_requirements,
    bandwidth,
    port: port_bandwidth,
    term: term.join(','),
    assigned: assignee_name || '',
    assignedEmail: assignee_email,
    followupCategory: follow_up_category,
    routeDiverse,
    status,
    statusDetails: status_details && (status_details.review_reasons || []).join(', '),
    className: `sortable-table-row--${status === 'review' ? 'review' : status === 'design-validation' ? 'validation' : 'manual'}`,
  }
}

class PricingDeskTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      rowData: [],
      page: props.page || 1,
      pageSize: props.pageSize || 10,
      ordering: props.ordering || '-modified_time',
      total: 0,
      results: 0,
      hasNext: false,
      hasPrev: false,
      isLoading: false
    };

    this.fetchData = this.fetchData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.onSort = this.onSort.bind(this);

    this.tableRef = createRef();
  }

  fetchData(options = {}) {
    const { page, pageSize, ordering } = this.state;
    const { filter={}, status='manual,error,review' } = this.props;
    const { org, assignee, followup, region, service } = filter;

    const request = { 
      status__in: status, 
      page, 
      page_size: pageSize,
      ordering,
      organization_id: org, 
      assignee_email: assignee,
      follow_up_category: followup,
      state_name: region?.length === 2 ? region : undefined,
      country_alpha_3: region?.length === 3 ? region : undefined,
      location_type_key: region === 'unvalidated' ? region : undefined,
      service_type_key: service,
      visible_in_pricing_queue: true,
      ...options
    };

    this.setState({ isLoading: true });
    getQuotes(request).then(({ error=null, count, results=[] }) => {
      this.props.onSelect && this.tableRef.current && this.tableRef.current.clearSelected();
      
      (!!error && request.page !== 1)
        ? this.fetchData({ ...options, page: 1 })
        : this.setState({ 
          error,
          page: request.page,
          pageSize,
          ordering: request.ordering,
          totalItems: count,
          pageCount: Math.ceil(count/pageSize),
          rowData: results.map(toRowData),
          isLoading: false
        }, () => { this.props.setCount?.(count) });
    });
  }

  updateRow(update) {
    const rowIndex = this.state.rowData.findIndex(row => row.id === update.id);
    if (rowIndex > -1) {
      const rowData = [ ...this.state.rowData ];
      rowData[rowIndex] = toRowData(update);
      this.setState({ rowData });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps) return;
    const prev = (prevProps.filter || {});
    const { org, assignee, followup, region, service } = (this.props.filter || {});

    // Perhaps instead simply compare the stringified objects
    if ((org !== prev.org) 
      || (assignee !== prev.assignee) 
      || (followup !== prev.followup)
      || (region !== prev.region) 
      || (service !== prev.service)) {
      this.fetchData({ page: 1 })
    }
  }

  handlePageClick({ selected }) {
    if (this.state.isLoading) return;

    this.fetchData({ page: selected + 1 });
  }

  onSort(name) {
    const columnName = (
      (name === 'createdTime') ? 'create_time' :
      (name === 'updatedTime') ? 'modified_time' :
      (name === 'user') ? 'user__name' :
      (name === 'organization') ? 'organization__name' :
      (name === 'folder') ? 'folder__name' : 
      (name === 'assigned') ? 'assignee_name' :
      (name === 'followupCategory') ? 'follow_up_category' : name
    );
    const sortDirection = (this.state.ordering === columnName) ? '-' : '';
    const ordering = sortDirection + columnName;

    this.fetchData({ ordering });
    return ordering;
  }

  render() {
    const { rowData=[], error, page, pageSize, pageCount, totalItems, isLoading } = this.state;
    const { onSelect, user, status } = this.props;
    const hasNoRows = !isLoading && !rowData.length;
    const columnData = user.is_internal ? COLUMN_DATA : COLUMN_DATA.filter(c => c.key !== 'assigned');

    const emptyMessage = status === 'design-validation'
      ? 'You have no designs requiring validation.'
      : 'You have no quotes requiring manual pricing.'

    return (
      <div className="pricing-desk">
        {(hasNoRows) 
          ? <Notification.Info children={emptyMessage} />
          : <SortableTable className="pricing-desk__table" columnData={columnData} rowData={rowData} ref={this.tableRef} onSort={this.onSort} isSelectable={!!onSelect} onSelect={onSelect} /> 
        }
        {!!isLoading && <LoadingOverlay />}
        <div className="pager-row">
          <PagerStatus page={page} pageSize={pageSize} totalItems={totalItems} />
          <Pager pageCount={pageCount} initialPage={page} onPageClick={this.handlePageClick} isLoading={isLoading} />
        </div>
      </div>
    )
  }
}

export default PricingDeskTable;

import React from 'react';
import { mailTo } from 'Utils/formatter.js';

const EmailLink = props => {
  const { to, cc, bcc, subject, body, children=to, className='' } = props;

  if (!to) {
    console.warn('`to` is a required prop for an EmailLink.');
  }

  const href = mailTo({ to, cc, bcc, subject, body });

  return <a href={href} className={`link ${className}`} children={children} />;
}

export default EmailLink;
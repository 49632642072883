import React, { Fragment } from 'react';
import * as esAntTabs from 'antd/es/tabs';
import "antd/lib/tabs/style/index.css";

import './tabs.scss';

const AntTabs = esAntTabs.default;
const AntTabPane = AntTabs.TabPane;

/**
 * A very simple wrapper around the Ant Design Tabs
 * (https://ant.design/components/tabs/)
 */
const Tabs = ({ children, ...passedProps }) => {
  const items = children.filter(Boolean).map(({ key, props }) => ({
    key,
    label: props.tab,
    children: props.children,
    forceRender: props.forceRender,
  }))

  return <AntTabs {...passedProps} items={items} />
};

// Simple tab badge to represent number of items to indicate non-empty tabs
const TabBadge = ({ value, children }) => (
  <Fragment>
    { children }
    { !!value && <span className="tab-badge">{value}</span> }
  </Fragment>
);

/**
 * A very simple wrapper around the Ant Design Tabs
 * (https://ant.design/components/tabs/)
 */
const TabPane = ({ children, tabBadge, tab, ...passedProps }) => {
  return (
    <AntTabPane {...passedProps}>
      {children}
    </AntTabPane>
  );  
};

export { Tabs, TabPane, TabBadge };
import groupBy from 'lodash/groupBy';

export const ECX_PORT_LABEL = 'On-Prem Port Speed';
export const ECX_LOCATION_LABEL = 'Equinix Fabric location';

export const mapToEcxColoOptions = (resp) => {
  const REGION_LOOKUP = Object.entries({
    'AMERICAS': ['USA', 'CAN', 'MEX', 'COL', 'BRA'],
    'EMEA': ['BGR', 'FIN', 'FRA', 'DEU', 'IRL', 'ITA', 'NLD', 'OMN', 'POL', 'PRT', 'ESP', 'SWE', 'CHE', 'TUR', 'ARE', 'GBR'],
    'ASIA-PACIFIC': ['AUS', 'CHN', 'HKG', 'IND', 'JPN', 'KOR', 'SGP'],
  }).reduce((acc, [region, countries]) => (
    countries.forEach(country => acc[country] = region), acc
  ), {});

  const byName = (a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
  const groups = groupBy(resp, pop => REGION_LOOKUP[pop.countryCode]);

  return ['AMERICAS', 'EMEA', 'ASIA-PACIFIC'].map(label => ({
    label, options: groups[label].sort(byName)
  }));
}

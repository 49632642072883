import { conjunctionList, toAddress, getServiceRequirements, getBandwidthLabel, getTermLabel, getTermValue, toIpv4Value } from 'Utils/formatter.js';
import { capitalize } from 'Utils/strings.js';
import { getInternetType, apiUrlIdStripper } from 'Utils/ccm.js';
import { getCurrencyFlag } from 'Utils/countries.js';

export const toLocationDesignItem = (loc, opts={}) => {
  return {
    type: 'location', address: toAddress(loc), ...opts
  }
};

export const toDefaultDesignType = (service) => {
  switch (service) {
    case 'internet':
    case 'internet-dedicated-access':
      return 'direct-internet-access';
    case 'internet-broadband':
      return 'broadband-internet';
    case 'internet-unitas-ip':
      return 'ip-transit';
    default:
      return 'aggregated-ethernet';
  }
}

export const isSameLocation = (loc1, loc2) => {
  const sameVal = key => loc1[key] && loc2[key] && loc1[key] === loc2[key];

  return (
    (!loc1 || !loc2) ? true : // one or both are null
    ['id', 'data-center-url', 'string_address'].some(sameVal)
  );
}

export const toDesignStatus = status => (status && ['ordered', 'ordering'].includes(status)) ? status : 'manual';

const toLocations = (locA, locZ) => {
  if (locA === null && locZ === null) {
    console.error('Error: Both locations are null!')
  }

  return (locA === null && locZ !== null)
    ? { locationA: toAddress(locZ), locationZ: toAddress(locA) }
    : { locationA: toAddress(locA), locationZ: toAddress(locZ) }
};

const toDetails = (spec_details=[]) => {
  const details = spec_details.reduce((acc, detail) => {
    const { option_type, key } = detail;
    const type = (option_type === 'advanced') ? key : option_type;

    acc[type] = detail.name;
    return acc;
  }, {});
  
  const { port_bandwidth, bandwidth, service_requirements, class_of_service, bandwidth_upload_mbps } = details;

  return {
    port: port_bandwidth,
    bandwidth,
    upload: bandwidth_upload_mbps,
    serviceRequirements: service_requirements,
    sdWan: details['sd-wan'] ? 'Yes' : 'No',
    serviceClass: class_of_service || 'None',
    QinQ: details['q-in-q'] ? 'Yes': 'No',
    frameMtu: details['jumbo-framing'] ? 'Jumbo' : 'Standard'
  };
};

export const toDesigns = (response) => {
  const designs = (response.results || []).map(result => {
    const { 
      availability, design_items, design_status, design_type_key, design_type_name, 
      design_url, url, distance, distance_direct, id, is_solution, leadtime, location_a, location_z, 
      mrc, mrr, note, nrc, nrr, ug_mrr, ug_nrr, external_mrc, external_nrc, 
      primary_vendor_name, provider_connection_vendor_names=[], product_name, 
      reference, specification, option_selections={}, option_selections_details, 
      amount_currency, requested_currency, vendor_currency, 
      ug_commission_mrc, ug_commission_percentage, ug_mrr_margin_percentage,
      has_order_draft, has_extended_demarc, has_unsent_change_notifications,
      approval_details={}, messages, expire_time,
    } = (result || {});

    const details = toDetails(option_selections_details);
    const term = getTermLabel(option_selections.term || []);
    const isInternet = !!getInternetType(design_type_key);
    const { pricing_desk, network_engineering, csa } = approval_details;
    const validation = {
      pricingDesk: pricing_desk,
      networkEngineer: network_engineering,
      csa,
    };

    const num = n => Number(n) || 0;
    const customerCost = ug_mrr === undefined ? num(mrc) : num(ug_mrr);
    const customerRevenue = num(mrr);

    const userMarginAmt = customerRevenue - customerCost;
    const userMarginPct = !customerRevenue ? 0 : (100 * (userMarginAmt)/customerRevenue);

    return {
      availability,
      id,
      status: design_status,
      hasOrderDraft: has_order_draft,
      hasOrderReview: design_status === 'ordering' && has_order_draft,
      hasExtendedDemarc: has_extended_demarc,
      hasUnsentNotifications: has_unsent_change_notifications,
      designType: design_type_key,
      internetType: isInternet ? design_type_name : null,
      url: design_url || url,
      designItems: responseMapper.toDesignItems(design_items, result),
      expireDate: expire_time,
      routeDistance: distance,
      directDistance: distance_direct,
      isSolution: is_solution,
      leadTime: leadtime,
      mrc,
      nrc,
      mrr: ug_mrr || mrr,
      nrr: ug_nrr || nrr,
      ug_mrr,
      ug_nrr,
      mrv: mrr,
      nrv: nrr,
      external_mrc, 
      external_nrc,
      displayCurrency: amount_currency || 'USD',
      requestedCurrency: requested_currency,
      vendorCurrency: vendor_currency,
      vendor: primary_vendor_name,
      connectivityVendors: conjunctionList(provider_connection_vendor_names),
      productName: product_name,
      reference,
      specification,
      note,
      term,
      marginAmt: num(ug_mrr || mrr) - num(ug_commission_mrc || 0) - num(mrc),
      marginPct: ug_mrr_margin_percentage,
      commissionAmt: ug_commission_mrc,
      commissionPct: num(ug_commission_percentage),
      userMarginPct,
      validation,
      messages,
      ...toLocations(location_a, location_z),
      ...details,
      //create_time: "2018-11-13T16:24:20.918616Z",
      //expire_time: "2018-11-20T16:17:44.273000Z",
      //rank_criteria: ["tcv"],
      //requested_currency_to_usd_rate: 1,

      // save these values to compare against any changed values
      _mrc: mrc,
      _mrr: (ug_mrr || mrr),
      _marginPct: ug_mrr_margin_percentage,
      _commissionPct: num(ug_commission_percentage),
    }
  });

  return designs;
};

const mergeRequirements = (requirements, orgData) => {
  if (!orgData) return requirements;

  const { is_wholesale_partner, mrr_partner_commission_wholesale } = orgData;
  return {
    ...requirements,
    partnerType: is_wholesale_partner ? 'Wholesale' : requirements.partnerType,
    partnerCommission: mrr_partner_commission_wholesale || requirements.partnerCommission,
  };
}

export const toFormattedRequirements = (requirements, orgData) => {
  if (!requirements.id) return {};

  const merged = mergeRequirements(requirements, orgData);

  const { 
    statusDetails, currency, term=[], excludedCarriers=[], extendedDemarc, 
    partnerType, partnerCommission=0.0, serviceType, serviceClass, serviceRequirements, 
    port, bandwidth, sdWan, frameMtu, jumboFraming, QinQ, 
    locationA=null, locationZ=null, userName, orgName, partnerName, createTime,
    kmz, carrierDiverse, routeDiverseSameCarrier, routeDiverse, routeProtection,
  } = merged;

  const commission = Number(partnerCommission) ? ` (${Number(partnerCommission)}%)` : '';
  const tags = [
    sdWan && 'SD-WAN',
    serviceRequirements === 'layer-1-wave' && 'Layer 1 Wave',
    extendedDemarc && 'Extended Demarc',
    kmz && 'KMZ File',
    carrierDiverse && 'Carrier Diverse',
    routeDiverseSameCarrier ? 'Route Diverse (Same Carrier)' : routeDiverse ? 'Route Diverse (Different Carriers)' : false,
    routeProtection && 'Route Protection',
    partnerType && `${capitalize(partnerType)} ${commission}`,
    QinQ && 'Q-in-Q',
    jumboFraming && 'Jumbo Framing',
    serviceClass !== 'best-effort' && `${capitalize(serviceClass)} COS`,
  ].filter(x => !!x);

  const formatted = {
    ...merged,
    ...toLocations(locationA, locationZ),
    statusDetails,
    tags,
    serviceType: capitalize(serviceType),
    serviceClass: serviceClass ? serviceClass.split('-').map(capitalize).join(' ') : 'None',
    serviceRequirements: getServiceRequirements(serviceRequirements),
    port: getBandwidthLabel(port, true),
    bandwidth: getBandwidthLabel(bandwidth),
    term: getTermLabel(term),
    excludedCarriers: excludedCarriers.join(', '),
    sdWan: sdWan ? 'Yes' : 'No',
    QinQ: QinQ ? 'Yes': 'No',
    frameMtu: frameMtu ? 'Jumbo' : 'Standard',
    partnerType: capitalize(partnerType),
    currency,
    currencyOption: currency && { value: currency, flag: getCurrencyFlag(currency) },
    userName, 
    userOrganization: partnerName || orgName, 
    createTime,
  };

  return formatted;
};

export const responseMapper = {
  getQuotes: ({ results=[], error=null}) => {
    if (!!error || results.length !== 1) return { error: true }
    
    const { create_time, id, name, status, status_details, specification, folder_name, user_email, user_name, user_url, organization_name, partner_organization_name, organization_url } = results[0];
    
    const ret = {
      createDate: create_time,
      id, 
      name, 
      status,
      statusDetails: status_details && (status_details.review_reasons || []).join(', '),
      isPricing: ['draft', 'pricing', 'pending'].includes(status),
      specId: specification.id,
      folderName: folder_name !== '.' && folder_name,
      folderUrl: `/quote-manager/${encodeURIComponent(folder_name)}/?u=${apiUrlIdStripper(user_url)}`,
      userName: user_name,
      userEmail: user_email,
      ownerId: apiUrlIdStripper(user_url),
      ownerOrgId: apiUrlIdStripper(organization_url),
      ownerOrgName: organization_name,
      partnerOrgName: partner_organization_name,
    }

    return ret;
  },

  toDesignItems: (items, design) => {
    if (!items) return undefined;

    // 1) Create design items array and location
    const { amount_currency } = design;
    const designItems = [toLocationDesignItem(items[0].location_a, { isLocationA: true })];
    let prevLocation = items[0].location_a;
    let prevProduct = {};

    // 2) Loop through each design item
    items.forEach(item => {
      const { 
        availability,
        create_time,
        description,
        design_item_subtype,
        design_item_type_key, //: "provider-connection"
        design_item_type_name, //: "Provider Connection"
        design_status, //: "manual"
        design_url, //: "https://dev.unitascloud.com/ccm/api/v3/designs/22628/"
        distance, //: 0
        expire_time, //: null
        id, //: 73440
        leadtime, //: "Unknown"
        location_a, //: {id: 8763, floor: "", suite: "", street_number: "910", street: "W Van Buren St", …}
        location_z, //: {id: 11846, floor: "", suite: "", street_number: "350", street: "W Cermak Rd", …}
        mrc, //: "2500.0000"
        mrr, //: "3450.0000"
        external_mrc = null,
        external_nrc = null,
        features,
        markup_applied,
        note, //: ""
        nrc, //: "0.0000"
        nrr, //: "0.0000"
        option_selections, //: {term: Array(1), bandwidth: "10-gbps", port_bandwidth: "10-gb"}
        option_selections_details=[{}], //: (3) [{…}, {…}, {…}]
        price_basis, //: "cost|price|enduserprice"
        pricing_data, //: {}
        pricing_source,
        reference, //: "190116I73440"
        requested_currency, //: "USD"
        requested_currency_to_usd_rate, //: 0
        sequence, //: 1
        ug_mrr, //: "2875.0000"
        ug_nrr, //: "0.0000"
        url, //: "https://dev.unitascloud.com/ccm/api/v3/design-items/73440/"
        vendor_currency, //: "USD"
        vendor_currency_to_usd_rate, //: 0
        vendor_name='', //: "Iron Mountain"
        vendor_product_name='', //: "1gbps"
        vendor_product_rule,
        location_a_proximity,
        location_z_proximity,
        type_2_vendor_name,
        vendor_mrc,
        vendor_nrc,
        vendor_reference, //: ""
        vendor_url, //: "https://dev.unitascloud.com/accounts/api/v1/organizations/45/"}
      } = item;

      const isLocationZ = loc => !design.is_internet && isSameLocation(loc, design.location_z);
      const showingPricingDetails = (ug_mrr !== undefined);
      const details = option_selections_details.reduce((acc, { option_type, name }) => { acc[option_type] = name; return acc; }, {});

      // 3) Determine if new location and if so, add new design item
      const nextLocationA = location_a;
      const isNewLocation = !isSameLocation(nextLocationA, prevLocation);

      // Considered same product if same vendor and product name and if there is a specified vendor product rule
      // TODO: Legacy products required vendor product name though it was not required for users, so ignore "Unspecified" values
      const isNewProduct = (
        // Different vendor
        vendor_name !== prevProduct.vendor_name 
        || !vendor_name
        // ...or different/undefined vendor product name
        || vendor_product_name !== prevProduct.vendor_product_name 
        || !vendor_product_name
        || vendor_product_name === 'Unspecified'
        // ...or no specified vendor product rule
        || !vendor_product_rule
        || design_item_type_key === 'cross-connect'
      );

      if (isNewLocation && isNewProduct) {
        const locationDesignItem = toLocationDesignItem(nextLocationA, { isLocationZ: isLocationZ(nextLocationA) });
        designItems.push(locationDesignItem);
        prevLocation = nextLocationA;
      }

      let vendorProduct = (vendor_product_name || '').replace(vendor_name,'').trim();
      if (vendor_product_rule && vendor_product_name !== vendor_product_rule) {
        const productRule = vendor_product_rule.replace(vendor_name,'').trim();

        vendorProduct = productRule.includes(vendorProduct)
          ? productRule
          : `${vendorProduct} - ${productRule}`
      }

      // 4) Push mapped design item to design items array (also check location id's for design type cross-connect)
      designItems.push({
        type: (design_item_type_key || 'custom'),
        id,
        url,
        leadtime,
        locationA: location_a,
        locationZ: location_z,
        distance,
        term: details.term,
        port: showingPricingDetails ? details.port_bandwidth : '',
        bandwidth: showingPricingDetails ? details.bandwidth : '',
        upload: showingPricingDetails && details.bandwidth_upload_mbps ? details.bandwidth_upload_mbps : '',
        ipv4: showingPricingDetails ? details?.ipv4_addresses : '',
        mrc: mrc,
        mrr: ug_mrr || mrr,
        mrv: showingPricingDetails ? mrr : undefined,
        nrc: nrc,
        nrr: ug_nrr || nrr,
        nrv: showingPricingDetails ? nrr : undefined,
        externalMrc: external_mrc,
        externalNrc: external_nrc,
        displayCurrency: item.amount_currency || amount_currency || 'USD',
        vendorCurrency: vendor_currency || amount_currency,
        requestedCurrency: requested_currency,

        pricingData: pricing_data, 
        vendorName: vendor_name,
        vendorProduct,
        vendorProductName: vendor_product_name,
        vendorProductRule: vendor_product_rule,
        vendorReference: vendor_reference,
        vendorUrl: vendor_url,
        type2Vendor: type_2_vendor_name,
        proximityA: location_a_proximity,
        proximityZ: location_z_proximity,

        design_item_subtype,
        design_item_type_key,
        design_item_type_name,
        expire_time,
        option_selections,
        price_basis,
        pricing_source,
        features,
        markup_applied,
        vendor_mrc,
        vendor_nrc,
      });

      prevProduct = {
        vendor_name,
        vendor_product_name,
        vendor_product_rule,
      };
    });

    // 3) Verify that last design item location was at design endpoint, and if not, then add it
    if (design.location_z && !isSameLocation(prevLocation, design.location_z)) {
      const locationDesignItem = toLocationDesignItem(design.location_z, { isLocationZ: true });
      designItems.push(locationDesignItem);
    }

    return designItems;
  }
};

export const requestMapper = {
  toDesign: (data) => {
    const { id, locationA, locationZ, currency, displayCurrency, requestedCurrency, status, vendorCurrency, designType, term, leadTime, specification, items, total, note, isSolution=true, sendEmail=false, option_selections={}, messages, _mrc, _mrr, _marginPct, _commissionPct } = data;
    const { port={}, bandwidth={}, upload, mrc, mrr, mrv, nrc, nrr, nrv, externalMrc, externalNrc, marginPct, commissionPct } = total;
    const designItems = requestMapper.toDesignItems(data);
    const primaryVendor = requestMapper.getPrimaryVendor(data);

    const isSameMargin = _mrc 
      && Number(mrc) === Number(_mrc)
      && Number(mrr) === Number(_mrr)
      && Number(commissionPct) === Number(_commissionPct);
  
    const margin = isSameMargin ? _marginPct : Number(marginPct || 0).toFixed(4);
 
    const request = {
      id,
      is_solution: isSolution,
      send_manual_design_email: sendEmail,
      design_status: toDesignStatus(status),
      design_type_key: designType.value,
      design_items: items ? designItems : undefined,
      location_a: requestMapper.toLocation(locationA),
      location_z: requestMapper.toLocation(locationZ),
      specification,

      option_selections: {
        port_bandwidth: port.value,
        bandwidth: bandwidth.value,
        term: [term.value],
        bandwidth_upload_mbps: upload || undefined
      },
      pricing_data: {},
      amount_currency: displayCurrency || currency || 'USD',
      requested_currency: requestedCurrency || currency || 'USD',
      vendor_currency: vendorCurrency || currency || 'USD',
      mrc: Number(mrc || 0).toFixed(4),
      ug_mrr: Number(mrr || 0).toFixed(4),
      mrr: Number(mrv || 0).toFixed(4),
      nrc: Number(nrc || 0).toFixed(4),
      ug_nrr: Number(nrr || 0).toFixed(4),
      nrr: Number(nrv || 0).toFixed(4),
      external_mrc: externalMrc === null ? null : Number(externalMrc).toFixed(4),
      external_nrc: externalNrc === null ? null : Number(externalNrc).toFixed(4),
      ug_mrr_margin_percentage: margin,
      ug_commission_percentage: Number(commissionPct || 0).toFixed(4),

      product_name: requestMapper.toProductName(designType.value),
      primary_vendor_url: primaryVendor.url,
      primary_vendor_name: primaryVendor.name,
      vendor_product_name: primaryVendor.product || undefined,
      leadtime: leadTime.value,
      note,
      messages,
    }

    return request;
  },

  toLocation: (loc) => {
    if (loc === null || !!loc.internetType) return null;

    const { id, data_center_url, string_address } = loc;

    return (
      (id) ? { id } :
      (data_center_url) ? { data_center_url } :
      (string_address) ? { string_address, skip_validation: true } :
      null
    );
  },

  toProductName: (designType) => {
    const productNames = {
      'none':	'None',
      'aggregated-ethernet':	'Unitas Ethernet Access Managed',
      'aggregated-ethernet-hairpin':	'Unitas Ethernet Access Managed',
      'aggregated-ethernet-inter-market-ixc':	'Unitas Ethernet Access Managed',
      'aggregated-ethernet-inter-market-ixc-hairpin':	'Unitas Ethernet Access Managed',
      'broadband-internet':	'Unitas Broadband Managed',
      'cross-connect':	'Unitas Cross Connect',
      'direct-internet-access':	'Unitas DIA Managed',
      'end-to-end-ethernet-point-to-point':	'Unitas Ethernet Pt-Pt Managed',
      'end-to-end-ethernet-pop-to-pop':	'Unitas Ethernet PoP-PoP Circuit',
      'end-to-end-ethernet-in-market-hairpin':	'Unitas Ethernet Pt-Pt Managed',
      'end-to-end-ethernet-inter-market-ixc-hairpin':	'Unitas Ethernet Pt-Pt Managed',
      'end-to-end-ethernet-out-of-market-hairpin':	'Unitas Ethernet Pt-Pt Managed',
      'metro-connect':	'Unitas Metro Connect',
      'out-of-market-ethernet':	'Unitas Ethernet Access Managed',
    }

    return productNames[designType] || productNames.none;
  },

  toDesignItems: (data) => {
    const { term, currency, items } = data;
    const designItems = [];
    const designTypesWithTwoLocations = ['provider-connection', 'royalty', 'market-rate-adjustment', 'cross-connect', 'hub'];
    
    // 1) Get the locations from LocA, designItem locations, and LocZ
    const locations = items
      .filter(x => x.type === 'location')
      .map(requestMapper.toLocation);

    // 2) Loop through legs and determine which need loc A and loc Z
    let locationIndex = -1; // locationA should be at index 0

    items.forEach(item => {
      if (item.type === 'location' && locationIndex < locations.length - 1) {
        locationIndex++;
      }
      else {
        let vendorProduct = item.vendorProduct;
        if (vendorProduct && item.vendorProductRule && vendorProduct !== item.vendorProductRule) {
          const suffix = '- ' + `${item.vendorProductRule.replace(item.vendorName, '')}`.trim();
          vendorProduct = vendorProduct.split(suffix)[0].trim();
        }
        const { design_item_subtype, design_item_type_name, expire_time, option_selections={}, features, pricing_source, markup_applied, price_basis } = item;
        
        let ipv4Value;
        if (item.type === 'ipv4-addresses' && item.ipv4) {
          ipv4Value = item.ipv4?.value || toIpv4Value(item.ipv4) || undefined;
        }
        
        designItems.push({
          leadtime: 'Unknown',
          location_a: locations[locationIndex],
          location_z: (designTypesWithTwoLocations.includes(item.type))
            ? locations[locationIndex + 1]
            : locations[locationIndex],
          design_item_type_key: item.type,

          pricing_data: item.pricingData || {},
          vendor_currency: item.vendorCurrency || currency || 'USD',
          requested_currency: item.requestedCurrency || currency || 'USD',
          amount_currency: item.displayCurrency || currency || 'USD',
          mrc: Number(item.mrc || 0).toFixed(4),
          ug_mrr: Number(item.mrr || 0).toFixed(4),
          mrr: Number(item.mrv || 0).toFixed(4),
          nrc: Number(item.nrc || 0).toFixed(4),
          ug_nrr: Number(item.nrr || 0).toFixed(4),
          nrr: Number(item.nrv || 0).toFixed(4),
          external_mrc: item.externalMrc !== null ? Number(item.externalMrc || 0).toFixed(4) : null,
          external_nrc: item.externalNrc !== null ? Number(item.externalNrc || 0).toFixed(4) : null,

          option_selections: {
            port_bandwidth: item?.port?.value || option_selections.port_bandwidth || 'none',
            bandwidth: item?.bandwidth?.value || option_selections.bandwidth || 'none',
            term: getTermValue((item.term || {}).value || term), 
            bandwidth_upload_mbps: item.upload || undefined,
            ipv4_addresses: ipv4Value || undefined,
          },

          // These are conditionally set
          location_a_proximity: item.proximityA,
          location_z_proximity: item.proximityZ,
          vendor_url: item.vendorUrl,
          vendor_name: item.vendorName,
          vendor_product_name: vendorProduct || undefined,
          vendor_product_rule: item.vendorProductRule,
          description: 'Manually Priced Design Item',

          // Pass through existing values... perhaps more to add?
          design_item_subtype,
          design_item_type_name,
          expire_time,
          features,
          price_basis,
          pricing_source,
          markup_applied,
        });
      }
    });

    return designItems;
  },

  getPrimaryVendor: (data) => {
    const { primaryVendorUrl, primaryVendorName, productName, items=[{}] } = data;

    if (primaryVendorUrl) {
      return { 
        url: primaryVendorUrl, 
        name: primaryVendorName, 
        product: productName 
      };
    }

    const primaryVendor = (items.find(x => x.type === 'provider-connection')) || {};

    return { 
      url: primaryVendor.vendorUrl || '', 
      name: primaryVendor.vendorName || '', 
      product: primaryVendor.vendorProduct || ''
    }
  }
};

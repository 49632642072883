import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import CN from 'classnames';

import { Button, Checkbox, ContextMenu, ContextMenuButton, Icon, LoadingOverlay, Notification, Tooltip } from 'Atoms';
import { ModalManager, MODALS } from 'Molecules';
import { 
  getUser, getUsers, updateUser, updateOrganization, 
  addUserPermission, removeUserPermission,
  addUserRole, removeUserRole,
} from 'Actions/ccm/api.js';

import { PERMISSIONS, ROLES } from 'Utils/const.js';
import { INTERNAL_COMPANY_NAME } from 'Utils/branding.js';

import './AdminUsers.scss';

const CONTEXT_MENU_ID = 'admin-users_menu';

const AdminUsers = props => {
  const { organizationId, organizationName, onSave, requiresOrderManager, docusignEnabled } = props;

  const [ user, setUser ] = useState(null);
  const [ users, setUsers ] = useState([]);
  const [ refetchUsers, setRefetchUsers ] = useState(0);
  const [ isFetching, setIsFetching ] = useState(false);

  const missingDocusignApprover = useMemo(() => (
    users.length
    && docusignEnabled
    && !users?.some(u => !!u.permissions?.find(x => x.split(':organization')[0] === PERMISSIONS.DOCUSIGN_APPROVER))
  ), [ users, docusignEnabled ]);

  const modalRef = useRef();

  const refresh = () => setRefetchUsers(refetchUsers+1);

  const showModal = {
    addUsers: () => modalRef.current.open(MODALS.ADD_USERS, { onConfirm: refresh, organizationId }),
    confirmDisable: data => {
      modalRef.current.open(MODALS.CONFIRMATION, {
        label: `Disable ${data.name}'s user account?`,
        children: 'This will prevent the user from accessing the CCM system.',
        onConfirm: () => callUpdateUser({ is_active: false }),
      })
    },
    resetPassword: () => modalRef.current.open(MODALS.RESET_PASSWORD, { user }),
  };

  useEffect(function getUpdatedUsers() {
    // leave is_active undefined to return disabled users
    const request = { page_size: 1000, organization: organizationId, include_permissions: true, include_roles: true };
    const sortByName = (a, b) => (a.name < b.name ? -1 : 1);
    const callback = resp => setUsers((resp.results || []).sort(sortByName));

    setIsFetching(true);
    getUsers(request).then(callback).finally(() => setIsFetching(false));
  }, [ organizationId, refetchUsers ]);

  const callUpdateUser = async request => {
    setIsFetching(true);
    const updatedUser = await updateUser(user.id, request);
    const newUsers = users.map(u => u.id === updatedUser.id ? { ...u, ...updatedUser } : u);
    setUsers(newUsers);
    setIsFetching(false);
  };

  const getContextItems = () => {
    const { is_active, is_internal, is_pricing_desk, is_full_visibility, is_sales_representative, is_csa, is_developer, permissions=[], roles=[] } = (user || {});
    const hasPermission = permission => !!permissions.find(x => x.split(':organization')[0] === permission);
    const hasRole = role => roles.includes(role);

    const togglePermission = async permission => {
      const toggleUserPermission = hasPermission(permission) ? removeUserPermission : addUserPermission;

      setIsFetching(true);
      await toggleUserPermission(user.id, `${permission}:organization=self.organization`);
      const updatedUser = await getUser(user.id);
      setIsFetching(false);
      setUsers(users.map(u => u.id === user.id ? { ...u, ...updatedUser } : u));
    };

    const toggleRole = async role => {
      const toggleUserRole = hasRole(role) ? removeUserRole : addUserRole;

      setIsFetching(true);
      const newRoles = await toggleUserRole(user.id, role);
      const updatedUser = await getUser(user.id);
      setIsFetching(false);
      setUsers(users.map(u => u.id === user.id ? { ...u, ...updatedUser, roles: newRoles.roles } : u));
    };

    const ToggleMenuItem = (name, key, opts={}) => ({
      label: `${user[key] ? 'Disable' : 'Set'} as ${name}`, 
      onClick: () => callUpdateUser({ [key]: !user[key] }),
      ...opts
    });

    const isSelected = selected => CN({'react-contexify__item--selected': selected});
    const setQuotePermissions = (edit, viewAll) => callUpdateUser({ is_pricing_desk: edit, is_full_visibility: viewAll });  

    return [
      !is_internal && ToggleMenuItem('Org Manager', 'is_org_manager'),
      !is_internal && ToggleMenuItem('Order Manager', 'is_order_manager', { hidden: !requiresOrderManager }),
      !is_internal && {
        label: `${hasPermission(PERMISSIONS.DOCUSIGN_APPROVER) ? 'Disable' : 'Set'} as DocuSign Approver`,
        onClick: () => togglePermission(PERMISSIONS.DOCUSIGN_APPROVER),
        hidden: !docusignEnabled,
      },
      !is_internal && ToggleMenuItem('Unitas Design View', 'may_view_full_design'),
      !!is_internal && ToggleMenuItem('Admin', 'is_admin'),
      !!is_internal && {
        submenu: 'Quote Permissions',
        selectable: true,
        items: [
          { 
            label: 'Pricing Desk',
            isSelected: isSelected(is_pricing_desk),
            onClick: () => !is_pricing_desk && setQuotePermissions(1,0)
          }, { 
            label: 'View all quotes', 
            isSelected: isSelected(is_full_visibility && !is_pricing_desk),
            onClick: () => !is_full_visibility && setQuotePermissions(0,1) 
          }, { 
            label: 'View own quotes',
            isSelected: isSelected(!is_full_visibility && !is_pricing_desk),
            onClick: () => (is_full_visibility || is_pricing_desk) && setQuotePermissions(0,0) 
          }
        ]
      },
      !!is_internal && {
        submenu: 'Employee Role',
        selectable: true,
        items: [
          { 
            label: 'Sales Rep',
            isSelected: isSelected(is_sales_representative),
            onClick: () => callUpdateUser({ is_sales_representative: !is_sales_representative })
          }, { 
            label: 'CSA', 
            isSelected: isSelected(is_csa),
            onClick: () => callUpdateUser({ is_csa: !is_csa })
          }, { 
            label: 'Developer',
            isSelected: isSelected(is_developer),
            onClick: () => callUpdateUser({ is_developer: !is_developer })
          }, {
            label: `Network Engineer`,
            isSelected: hasRole(ROLES.NETWORK_ENGINEER),
            onClick: () => toggleRole(ROLES.NETWORK_ENGINEER),
          },
        ]
      },
      !is_internal && (organizationName === 'SungardAS') && {
        submenu: 'Employee Role',
        selectable: true,
        items: [
          { 
            label: 'EMEA',
            isSelected: hasRole(ROLES.SUNGARD_EMEA),
            onClick: () => toggleRole(ROLES.SUNGARD_EMEA),
          },
          { 
            label: 'Sungard Pricing Desk',
            isSelected: hasRole(ROLES.SUNGARD_PRICING_DESK),
            onClick: () => toggleRole(ROLES.SUNGARD_PRICING_DESK),
          },
          
        ]
      },
      { separator: true },
      { label: 'Reset Password', icon: 'reset-password', onClick: ({ props }) => showModal.resetPassword({ ...props.data }) },
      { separator: true },
      (is_active 
        ? { label: 'Disable User', icon: 'not-allowed', onClick: ({ props }) => showModal.confirmDisable({ ...props.data }) }
        : { label: 'Re-enable User', icon: 'add-circle-outline', onClick: () => callUpdateUser({ is_active: true }) }
      ),
    ].filter(Boolean);
  };

  const contextItems = user ? getContextItems() : [];
  const toggleOrderingReq = () => {
    setIsFetching(true);
    updateOrganization(organizationId, { requires_order_manager: !requiresOrderManager })
      .then(onSave)
      .finally(() => setIsFetching(false));
  }

  return (
    <div className="admin-users">
      {!!isFetching && <LoadingOverlay className="loading-overlay__fetch-users"/>}

      <h3>{organizationName} Users</h3>
      <p>Set user permissions, reset user passwords, and add new users</p>
      {(!!missingDocusignApprover) && 
        <p>
          <Notification.Warning>Organization requires orders to be approved via Docusign. Please select at least one user to be a Docusign Approver.</Notification.Warning>
        </p>
      }

      <div className="flex-wrap">
        <Checkbox className="admin-users__checkbox-req-order-mgr" label="Restrict ordering only to ordering managers." checked={requiresOrderManager} onChange={toggleOrderingReq} />
        <Button onClick={showModal.addUsers}>Add new users</Button>
      </div>

      <ul className="admin-users__list">
        { users.map(item => <UserListItem key={item.id} requiresOrderManager={requiresOrderManager} setUser={setUser} {...item} />) }
      </ul>

      <ModalManager ref={modalRef} />
      <ContextMenu id={CONTEXT_MENU_ID} items={contextItems} className="admin-users__context-menu" />
    </div>
  )
}

const UserListItem = (props) => {
  const { setUser, ...user } = props;
  const { id, email, name, requiresOrderManager, is_active, is_admin, is_demo, is_full_visibility, is_internal, is_machine, is_org_manager, is_pricing_desk, is_order_manager, may_view_full_design, permissions=[], roles=[] } = user;
  const hasPermission = permission => !!permissions.find(x => x.split(':organization')[0] === permission)

  const tooltips = useRef({
    'Internal': `${INTERNAL_COMPANY_NAME} employee`,
    'Admin': 'Has ability to access Admin Dashboard to manage users, organizations, and pricing rules.',
    'Pricing Desk': 'Has ability to View and Edit all quotes.',
    'View All Quotes': 'Has abilty to View all quotes.',
    'Unitas Design View': 'User can see all Unitas design costs and details.',
    'Org Manager': 'Has ability to View all of the organization\'s quotes.',
    'Order Manager': 'Has ability to order quotes if organization has restricted ordering access.',
    'DocuSign Approver': 'Has ability to be assigned to approve DocuSign quote orders.',
    'Sungard Pricing Desk': 'Sungard user approved to see underlying vendor costs',
  });

  const userRoles = [
    !!is_internal && 'Internal',
    !!is_admin && 'Admin',
    !!is_pricing_desk && 'Pricing Desk',
    !!roles.includes(ROLES.NETWORK_ENGINEER) && 'Network Engineer',
    !!roles.includes(ROLES.SUNGARD_EMEA) && 'Sungard EMEA',
    !!roles.includes(ROLES.SUNGARD_PRICING_DESK) && 'Sungard Pricing Desk',
    !!is_full_visibility && !is_pricing_desk && 'View All Quotes',
    !!is_org_manager && 'Org Manager',
    !!is_order_manager && !!requiresOrderManager && 'Order Manager',
    !!hasPermission(PERMISSIONS.DOCUSIGN_APPROVER) && 'DocuSign Approver',
    !!may_view_full_design && 'Unitas Design View',
  ].filter(Boolean);

  const onClick = () => setUser(user);
  const className = CN('admin-users__list-item', {
    'admin-users__list-item--disabled': !is_active,
  });

  return (
    <li className={className}>
      <div className="admin-users__list-item-name" title={`User ID: ${id}`}>
        <Icon name="avatar" />
        {name || <i>Unspecified</i>}
        <div className="admin-users__list-item-email">{email}</div>
      </div>
      
      <div className="admin-users__list-item-roles">
        {userRoles.map(role => (
          <Tooltip title={tooltips.current[role]} key={role}>
            <span className="admin-users__list-item-role" data-role={role}>{role}</span>
          </Tooltip>
        ))}
      </div>

      <div className="admin-users__list-item-actions">
        <ContextMenuButton menuId={CONTEXT_MENU_ID} data={props} onClick={onClick} />
      </div>
    </li>
  );
}

export default AdminUsers;
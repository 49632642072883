import React, { useCallback, useRef } from 'react';
import CN from 'classnames';
import { ContextMenu,  ContextMenuButton, LoadingOverlay } from 'Atoms';
import uniqueId from 'lodash/uniqueId';

import { DesignItemLabelsRow, DesignItemLocationRow, DesignItemRowWithInputs } from './index.js';
import DesignTotals from '../DesignTotals.jsx';

import { ADD_LINK_OPTIONS, ADD_ITEM_AT_LOCATION_MENU, DESIGN_ITEM_TYPES }from './DesignItemRowHelper.js';

import { getInternetType } from 'Utils/ccm.js';

import './DesignItems.scss';

const DesignItemsEditor = (props) => {
  // Props are passed in from DesignEditor which uses useDesignItemManager
  const {
    items,
    validationErrors={},
    onChangeRow,
    isLoading,
    locationZ,
    addRow,
    moveRow,
    deleteRow,
    rowOptions,
    design,
    currency,
    pricing,
    partnerName,
    quoteOrgName,
    quoteIsLeastCost,
  } = props;

  // useRef - Menu id for context menu
  const menuKey = design.id || uniqueId();

  const menuIds = useRef({
    addNodes: `manual-pricing-nodes-menu-id__${menuKey}`,
    addLinks: `manual-pricing-links-menu-id__${menuKey}`
  }).current;

  const linkMenuItems = useRef(
    ADD_LINK_OPTIONS.map(({ label, value, icon }) => ({
      label, icon, onClick: ({ props: { rowIndex }}) => addRow({ value, index: rowIndex }),
    })
  )).current;

  // const locZKey = items.find(x => x.isLocationZ)?.rowKey;

  // const onChangeLocationZ = useRef(() => (
  //   opt => onChangeRow(locZKey).locationZ(opt)
  // )).current;

  const onAddNodeClick = val => event => addRow({ value: val, index: event.props.data.rowIndex + 1 });

  const nodeMenuItems = useRef(
    ADD_ITEM_AT_LOCATION_MENU.map(type => {
      const { label, value, icon } = DESIGN_ITEM_TYPES[type];
      return { label, icon, onClick: onAddNodeClick(value) }
    })
  ).current;

  //
  // Helper Methods
  //

  const toRenderDesignRow = ({ rowKey, isEditing=true, isEditable=true, ...item }, i, arr) => {
    const { designType, currency, quoteOrgName, design } = props;

    const _LINK_TYPES = ['provider-connection', 'royalty', 'market-rate-adjustment', 'ipv4-addresses'];
    const isLocation = i => arr[i]?.type === 'location';
    const isLink = i => _LINK_TYPES.includes(arr[i]?.type);
    const isNode = i => DESIGN_ITEM_TYPES[arr[i]?.type]?.isNode;

    // current item is location and next iteam is link type - Node start point
    const isNodeStart = (!i || (isLocation(i)))// && isLink(i-1)));
    
    // current item is link and last item was node type - Node end point
    const isNodeEnd = (i === arr.length - 1  || (!isLink(i) && isLink(i+1)) || (isLocation(i+1)));

    const isLinkLastItemNotLink = i && isLink(i) && !isLink(i-1);
    const isLocLastItemNode = i && isLocation(i) && !isLink(i-1);
    const isLocLastItemLink = i && isLocation(i) && isLink(i-1);
    const showLinkButton = isLinkLastItemNotLink || isLocLastItemNode || isLocLastItemLink;

    const disableMoveUp = isNode(i) && isLocation(i-1);
    const disableMoveDown = !isNode(i+1);

    const onChange = onChangeRow(rowKey);

    const actionProps = {
      onAdd: addRow,
      onMove: moveRow,
      onDelete: deleteRow,
    };

    const rowProps = { key: rowKey, rowKey, rowIndex: i, isEditing, isEditable, menuId: menuIds.addNodes, isNodeStart, isNodeEnd, disableMoveUp, disableMoveDown };

    return (
      <React.Fragment key={rowKey}>
        {(!!showLinkButton) && (
          <AddLinkButton menuId={menuIds.addLinks}
            key={`add-link-button-${rowKey}`}
            showAddLinkAbove={isLocLastItemLink}
            showAddLinkBetween={isLocLastItemNode}
            showAddLinkBelow={isLinkLastItemNotLink}
            rowIndex={i} />
        )}
        {(item.type !== 'location')
        ? <DesignItemRowWithInputs
            {...item}
            {...rowProps}
            {...actionProps}
            designType={designType}
            options={rowOptions}
            currency={currency}
            quoteOrgName={quoteOrgName}
            partnerName={partnerName}
            rowIndex={i}
            key={rowKey}
            onChange={onChange}
            isNew={!design?.id}
          /> :
        (item.isLocationA)
          ? <DesignItemLocationRow
              {...item}
              {...rowProps}
              key={rowKey}
              isEditable={false}
            /> :
        (item.isLocationZ)
          ? <LocationZRow
              {...item}
              {...rowProps}
              key={rowKey}
              designType={designType}
              quoteIsLeastCost={quoteIsLeastCost}
              locationZ={locationZ}
              colos={rowOptions.colos}
              error={validationErrors.locationZ}
              onChange={onChange.location}
            />
          : <DesignItemLocationRow
              {...rowProps}
              {...actionProps}
              key={rowKey}
              defaultValue={item.defaultValue}
              address={item?.defaultValue?.value}
              isEditable={isEditable}
              colos={rowOptions.colos}
              onChange={onChange.location}
            />
        }
      </React.Fragment>
    )
  };

    return (
      <div className="design-items">
        <div className="design-items__header">
          <DesignItemLabelsRow isInternal={true} />
        </div>

        <div className="design-items__rows">
          { !!isLoading && <LoadingOverlay /> }
          {/* { items?.map((item, index) =>
            <DesignItemMapper item={item} index={index} key={item.rowKey} />
          )} */}
          { items?.map(toRenderDesignRow) }
        </div>

        <DesignTotals {...pricing} currency={currency} isEditing isNew={!design.id} quoteOrgName={partnerName || quoteOrgName} validationErrors={validationErrors} />

        <ContextMenu id={menuIds.addNodes} items={nodeMenuItems} position="auto" />
        <ContextMenu id={menuIds.addLinks} items={linkMenuItems} position="auto" />
      </div>
    )
  }

  const LocationZRow = props => {
    const { rowKey, designType, quoteIsLeastCost, locationZ, colos, error, onChange, ...rest } = props;

    const internetType = getInternetType(designType);
    const onChangeZ = useCallback((opt) => onChange(opt), [ onChange ]);

    return <DesignItemLocationRow
      {...rest}
      rowKey={rowKey}
      key={rowKey}
      address={locationZ}
      internetType={internetType}
      isEditable={quoteIsLeastCost}
      {...(quoteIsLeastCost && {
        label: "To:",
        colos,
        includeHubs: false,
        onChange: onChangeZ,
        error,
        defaultValue: (locationZ) ? {
          label: locationZ.label || locationZ.addressLines?.join(', '), 
          value: locationZ,
          ...locationZ,
        } : null,
      })}
    />
  }


const AddLinkButton = ({ menuId, showAddLinkAbove, showAddLinkBetween, showAddLinkBelow, rowIndex }) => {
  const className = CN('context-button-add-link', { 
    'context-button-add-link--above-location': showAddLinkAbove,
    'context-button-add-link--between-locations': showAddLinkBetween,
    'context-button-add-link--below-location': showAddLinkBelow,
  });

  return <ContextMenuButton className={className} icon="add-circle-filled" menuId={menuId} rowIndex={rowIndex} />
}

export default DesignItemsEditor;
import React from 'react'
import Lookup from './Lookup.jsx';

const PortDropdown = (props) => {
  const { label="Port Speed", ...otherProps } = props;

  return (
    <Lookup name="port" label={label} {...otherProps} />
  );
}

export default PortDropdown;
import React, { useState, useEffect, useRef } from 'react';
import { Button, Checkbox } from 'Atoms';
import { Lookup, CurrencyLookup } from 'Molecules';
import { PERMISSIONS } from 'Utils/const.js';
import { toCurrency, toPercentage } from 'Utils/formatter.js';
import AdminOrganizationNotifications from './AdminOrganizationNotifications.jsx';

import { updateOrganization } from 'Actions/ccm/api.js';

import './AdminPricing.scss';

const DEFINED_GROUP_PERMISSIONS = [ 
  PERMISSIONS.DOCUSIGN_ENABLED, 
  PERMISSIONS.VIEW_NON_SOLUTION_DESIGNS 
];

const AdminPricing = ({ organizationId, pricing={}, onSave }) => {
  const pricingRules = useRef({
    noCostEquipment: 'No NID and Router Costs',
    noCostSungardBackbone: 'Zero Cost SungardAS Backbone',
    enableIpDataCenter: 'Price with Unitas IP Data Center Rate', 
    enableIpEndUser: 'Price with Unitas IP End User Rate',
  });
  const ddOptions = useRef({
    forceCrossConnect: [
      { value: 0, label: 'No' },
      { value: 1, label: 'Only at non-Unitas colocated PoPs' },
      { value: 2, label: 'Yes, all PoPs' },
    ],
    ipPricingRate: [
      { value: 0, label: 'Only use Data Center Rate' },
      { value: 1, label: 'Only use End User Rate' },
      { value: 2, label: 'Data Center or End User Rate' },
    ],
  });

  const rules = pricingRules.current;
  const options = ddOptions.current;

  // NOTE: Cross-connect MRR's should match connectivity MRR's
  const {
    // UG margins/markup
    mrr_margin_percent_ug=0,
    mrr_margin_percent_cross_connect_ug=0,
    nrr_markup_fixed_ug=0,
    nrr_markup_fixed_cross_connect_ug=0,

    // Wholesale margins/markup
    is_wholesale_partner=false,
    mrr_partner_commission_wholesale=0,
    mrr_margin_percent_end_user=0,
    mrr_margin_percent_cross_connect_end_user=0,
    nrr_markup_fixed_end_user=0,
    nrr_markup_fixed_cross_connect_end_user=0,
    
    // Enterprise pricing
    is_enterprise_partner=false,
    mrr_partner_commission_enterprise=0,

    // Custom pricing options
    has_zero_price_unitas_ip_leg=false,
    default_currency_code='USD',
    may_view_design_items=false,
    may_view_provider_connection_vendor=false,
    force_additional_cross_connect_unitas_ip=false,
    force_cross_connect_at_own_pops=false, 
    force_cross_connect_at_own_pops_not_colocated_with_unitas_pops=false,
    pricing_rules=[],

    // Ordering
    settings={},
  } = pricing;

  const extraRules = pricing_rules.filter( x => !Object.values(rules).includes(x) );
  const [mrr, setMrr] = useState(0);
  const [nrr, setNrr] = useState(0);
  const [isWholesale, setIsWholesale ] = useState(false);
  const [wholesaleMrr, setWholesaleMrr] = useState(0);
  const [endUserMrr, setEndUserMrr] = useState(0);
  const [endUserNrr, setEndUserNrr] = useState(0);
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [enterpriseMrr, setEnterpriseMrr] = useState(0);
  const [freeIp, setFreeIp] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [viewDesignItems, setViewDesignItems] = useState(false);
  const [viewCarrierName, setViewCarrierName] = useState(false);
  const [forceCrossConnect, setForceCrossConnect] = useState(false);
  const [forceCrossConnectIP, setForceCrossConnectIP] = useState(false);
  const [noNidsOwnPops, setNoNidsOwnPops] = useState(false);
  const [noCostEquipment, setNoCostEquipment] = useState(false);
  const [noCostSungardBackbone, setNoCostSungardBackbone] = useState(false);
  const [ipPricingRate, setIpPricingRate] = useState(false);
  const [docusignEnabled, setDocusignEnabled] = useState(false);
  const [viewNonSolutionDesigns, setViewNonSolutionDesigns] = useState(false);

  const formatAsPercent = (num) => toPercentage(Number(num)/100);
  const hasGroupPermission = p => (settings.group_permissions || []).includes(p);

  useEffect(() => {
    setMrr(formatAsPercent(mrr_margin_percent_ug));
    setNrr(toCurrency(nrr_markup_fixed_ug));
    setIsWholesale(is_wholesale_partner);
    setWholesaleMrr(formatAsPercent(mrr_partner_commission_wholesale));
    setEndUserMrr(formatAsPercent(mrr_margin_percent_end_user));
    setEndUserNrr(toCurrency(nrr_markup_fixed_end_user));
    setIsEnterprise(is_enterprise_partner);
    setEnterpriseMrr(formatAsPercent(mrr_partner_commission_enterprise));
    setFreeIp(has_zero_price_unitas_ip_leg);
    setCurrency(default_currency_code);
    setViewDesignItems(may_view_design_items);
    setViewCarrierName(may_view_provider_connection_vendor);
    setForceCrossConnect(
      force_cross_connect_at_own_pops 
        ? options.forceCrossConnect[2] :
      force_cross_connect_at_own_pops_not_colocated_with_unitas_pops 
        ? options.forceCrossConnect[1]
        : options.forceCrossConnect[0]
    );
    setForceCrossConnectIP(force_additional_cross_connect_unitas_ip);
    setNoCostEquipment(pricing_rules.includes(rules.noCostEquipment));
    setNoCostSungardBackbone(pricing_rules.includes(rules.noCostSungardBackbone));
    setIpPricingRate(
      (pricing_rules.includes(rules.enableIpDataCenter) && pricing_rules.includes(rules.enableIpEndUser))
        ? options.ipPricingRate[2] :
      (pricing_rules.includes(rules.enableIpEndUser))
        ? options.ipPricingRate[1]
        : options.ipPricingRate[0]
    );
    setDocusignEnabled(
      hasGroupPermission(PERMISSIONS.DOCUSIGN_ENABLED)
    );
    setViewNonSolutionDesigns(
      hasGroupPermission(PERMISSIONS.VIEW_NON_SOLUTION_DESIGNS)
    );
  }, [organizationId]);
  
  const save = () => {
    // Filter out toggleable options and add back if enabled
    const nonDefinedGroupPermissions = settings?.group_permissions?.filter(x => !DEFINED_GROUP_PERMISSIONS.includes(x));

    const data = {
      mrr_margin_percent_ug: mrr.replace('%',''),
      mrr_margin_percent_cross_connect_ug: mrr.replace('%',''),
      nrr_markup_fixed_ug: nrr.replace('$',''),
      nrr_markup_fixed_cross_connect_ug: 0,
      is_wholesale_partner: isWholesale,
      mrr_partner_commission_wholesale: isWholesale ? wholesaleMrr.replace('%','') : 0,
      mrr_margin_percent_end_user: endUserMrr.replace('%',''),
      mrr_margin_percent_cross_connect_end_user: endUserMrr.replace('%',''),
      nrr_markup_fixed_end_user: endUserNrr.replace('$',''),
      is_enterprise_partner: isEnterprise,
      mrr_partner_commission_enterprise: isEnterprise ? enterpriseMrr.replace('%','') : 0,
      has_zero_price_unitas_ip_leg: freeIp,
      default_currency_code: currency,
      may_view_design_items: viewDesignItems,
      may_view_provider_connection_vendor: viewCarrierName,
      force_additional_cross_connect_unitas_ip: forceCrossConnectIP,
      force_cross_connect_at_own_pops: (forceCrossConnect.value === 2),
      force_cross_connect_at_own_pops_not_colocated_with_unitas_pops: (forceCrossConnect.value === 1),
      no_nid_at_own_pops: noNidsOwnPops,
      pricing_rules: [
        (noCostEquipment && rules.noCostEquipment),
        (noCostSungardBackbone && rules.noCostSungardBackbone),
        (ipPricingRate?.value === 0 || ipPricingRate?.value === 2) && rules.enableIpDataCenter, 
        (ipPricingRate?.value === 1 || ipPricingRate?.value === 2) && rules.enableIpEndUser,
        ...extraRules,
      ].filter(x=>!!x),
      settings: {
        ...settings,
        group_permissions: [
          ...nonDefinedGroupPermissions, 
          (docusignEnabled && PERMISSIONS.DOCUSIGN_ENABLED),
          (viewNonSolutionDesigns && PERMISSIONS.VIEW_NON_SOLUTION_DESIGNS),
        ].filter(Boolean)
      }
    };

    updateOrganization(organizationId, data).then(resp => {
      const message = (resp.error ? `Error: ${resp.error}. Could not save.` : 'Pricing changes saved');
      alert(message);
      
      !resp.error && onSave(resp);
    });
  };

  // TODO: REFACTOR WITH USETOGGLE HOOK
  const onChangeMrr = (e) => setMrr(e.target.value);
  const onChangeNrr = (e) => setNrr(e.target.value);
  const onChangeIsWholesale = () => setIsWholesale(!isWholesale);
  const onChangeWholesaleMrr = (e) => setWholesaleMrr(e.target.value);
  const onChangeEndUserMrr = (e) => setEndUserMrr(e.target.value);
  const onChangeEndUserNrr = (e) => setEndUserNrr(e.target.value);
  const onChangeIsEnterprise = () => setIsEnterprise(!isEnterprise);
  const onChangeEnterpriseMrr = (e) => setEnterpriseMrr(e.target.value);
  const onChangeFreeIp = (e) => setFreeIp(e.target.checked);
  const onChangeCurrency = (e) => setCurrency(e.value);
  const onChangeViewDesignItems = (e) => setViewDesignItems(e.target.checked);
  const onChangeViewCarrierName = (e) => setViewCarrierName(e.target.checked);
  const onChangeForceCrossConnect = (opt) => setForceCrossConnect(opt);
  const onChangeIpRate = (opt) => setIpPricingRate(opt);
  // const onChangeForceCrossConnectIP = (e) => setForceCrossConnectIP(e.target.checked);
  // const onChangeNoNidOwnPops = (e) => setNoNidsOwnPops(e.target.checked);
  const onChangeNoCostEquipment = (e) => setNoCostEquipment(e.target.checked);
  const onChangeNoCostSungardBackbone = (e) => setNoCostSungardBackbone(e.target.checked);
  const onChangeDocusignEnabled = (e) => setDocusignEnabled(e.target.checked);
  const onChangeViewNonSolutions = (e) => setViewNonSolutionDesigns(e.target.checked);

  return (
    <div className="admin-pricing">
      <h4>Unitas Margins</h4>
      <div className="margins-container">
        <label>MRR Margin<input type="text" value={mrr} onChange={onChangeMrr} /></label>
        <label>NRR Markup<input type="text" value={nrr} onChange={onChangeNrr} /></label>
      </div>

      <hr />

      <h4>
        Wholesale Partner Pricing
        <Checkbox checked={isWholesale} onChange={onChangeIsWholesale} children="Enable as Wholesale partner" />
      </h4>

      {(isWholesale) && (
        <div className="margins-container">
          <label>Agent Commission<input type="text" value={wholesaleMrr} onChange={onChangeWholesaleMrr} /></label>
          <label>Enduser MRR Margin<input type="text" value={endUserMrr} onChange={onChangeEndUserMrr} /></label>
          <label>Enduser NRR Markup<input type="text" value={endUserNrr} onChange={onChangeEndUserNrr} /></label>
        </div>
      )}
      
      <hr />

      <h4>
        Enterprise Partner Pricing
        <Checkbox checked={isEnterprise} onChange={onChangeIsEnterprise} children="Enable as Enterprise partner" />
      </h4>

      {(isEnterprise) && (
        <div className="margins-container">
          <label>Agent Commission<input type="text" value={enterpriseMrr} onChange={onChangeEnterpriseMrr} /></label>
        </div>
      )}

      <hr />

      <h4>
        Currency
        <CurrencyLookup label="Default Currency" onChange={onChangeCurrency} defaultValue={currency} />
      </h4>
      <hr />

      <h4>
        IP Pricing Rules
        <Lookup name="ipPricingRate" label="IP Pricing Rate" onChange={onChangeIpRate} value={ipPricingRate} options={options.ipPricingRate} />
      </h4>
      <div className="margins-container">
        <Checkbox children="Unitas Global IP legs pricing set to $0" onChange={onChangeFreeIp} checked={freeIp} />
      </div>
      <hr />

      <div className="admin-pricing-options">
        <Checkbox children="Allow organization to view design items" onChange={onChangeViewDesignItems} checked={viewDesignItems} />
        <Checkbox children="Allow organization to view carrier name" onChange={onChangeViewCarrierName} checked={viewCarrierName} />
        <Checkbox children="Allow organization to view non-solution designs" onChange={onChangeViewNonSolutions} checked={viewNonSolutionDesigns} />
        <br />
        {/* <Checkbox children="Force cross-connect for Unitas IP" onChange={onChangeForceCrossConnectIP} checked={forceCrossConnectIP} /> */}
        <Lookup name="forceCrossConnect" label="Force cross-connect for organization PoPs" options={options.forceCrossConnect} onChange={onChangeForceCrossConnect} value={forceCrossConnect} isSearchable={false} />
        <br />
        {/* <Checkbox children="No NID at own PoPs" onChange={onChangeNoNidOwnPops} checked={noNidsOwnPops} /> */}
        <Checkbox children="No NID and Router Costs" onChange={onChangeNoCostEquipment} checked={noCostEquipment} />
        <br />
        <Checkbox children="Zero Cost SungardAS Backbone" onChange={onChangeNoCostSungardBackbone} checked={noCostSungardBackbone} />
        <br />
        <Checkbox children="Enable DocuSign quote order integration" onChange={onChangeDocusignEnabled} checked={docusignEnabled} />
      </div>
      <hr />

      <AdminOrganizationNotifications organizationId={organizationId} />

      <div style={{ textAlign: 'center' }}>
        <Button onClick={save}>Save</Button>
      </div>
    </div>
  )
}

export default AdminPricing;

import React, { useEffect, useState, useRef } from 'react';
import { useUserContext } from 'Context/UserContext.js';
import { Link } from 'react-router-dom';
import { Page, Support } from 'Atoms';
import { CreateQuote, PricingDeskWidget, QuoteManager } from 'Widgets';
import { PAGE_TITLE_NAME } from 'Utils/branding.js';

import { getQuotesAndFolders } from 'Actions/ccm/api.js';

import './Dashboard.scss';
import '../page.scss';

const DashboardPage = () => {
  const user = useUserContext();
  const unapproved = [
    'pricing_desk', 'network_engineering', 'csa'
  ].find(role => user.permissions.includes(`ccm.${role}_approve_design`));
  const defaultTab = unapproved === 'network_engineering' ? 'orders' : 'quotes';

  const [ items, setItems ] = useState([]);
  const [ pricingDeskTab, setPricingDeskTab ] = useState(defaultTab);
  const [ loadingMessage, setLoadingMessage ] = useState(' ');

  const fetchData = () => {
    getQuotesAndFolders({ pageSize: 25 }).then(data => { 
      setLoadingMessage(null)
      setItems(data.items) 
    });
  }

  useEffect(() => {
    fetchData();

    return () => setItems();
  }, []);

  const userType = user.is_internal ? 'internal' : 'external';
  const className = 'dashboard-content dashboard patterned';
  const title = `${PAGE_TITLE_NAME}: Home`;
  const widgetsClassName = `dashboard__widgets dashboard__widgets--${userType}`;
  const crumbs = useRef([{ text: `Welcome, ${user.name || 'user'}` }]).current;
  const onTabChange = tab => setPricingDeskTab(tab);

  return (
    <Page className={className} crumbs={crumbs} title={title} >
      <div className={widgetsClassName}>
        <div className="widget widget--pricing-desk">
          <h2>Pricing Desk</h2>
          <div className="widget-wrapper">
            <WidgetScroller unwrap={user.is_internal}>
              <PricingDeskWidget pageSize={25} onTabChange={onTabChange} filter={{unapproved}} activeTab={pricingDeskTab} />
            </WidgetScroller>
            <Link className="link widget-button-link" to={`/pricing-desk?tab=${pricingDeskTab}`}>View All</Link>
          </div>
        </div>
        
        <div className="widget widget--quote-manager">
          <h2>Quote Manager</h2>
          <div className="widget-wrapper">
            <WidgetScroller>
              <QuoteManager items={items} loadingMessage={loadingMessage} onHandleImportError={fetchData} />
            </WidgetScroller>
            <Link className="link widget-button-link" to="/quote-manager">View All</Link>
          </div>
        </div>

        <div className="widget widget--request-quote">
          <h2>Request a Quote</h2>
          <CreateQuote />
        </div>
      </div>
      
      <Support className="support--full-width" />
    </Page>
  )
}

const WidgetScroller = ({ unwrap, children }) => (unwrap 
  ? children 
  : <div className="widget-scroller" children={children} />
);

export default DashboardPage;

import React, { useState } from 'react';
import { TextInput } from 'Atoms';
import { Drawer } from 'Molecules';
import { createFolder } from 'Actions/ccm/api.js';
import { useForm, FormProvider } from 'react-hook-form'

import './NewFolderModal.scss';

const NewFolderModal = props => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const formMethods = useForm();
  const folderName = formMethods.watch('folderName');
  const { onConfirm, onClose } = props;

  const onSubmit = () => {
    if (!isLoading && folderName.length) {
      event.preventDefault();
      setIsLoading(true);
      setError(null);
      createFolder(folderName)
        .then(({ error, ...folder }) => {
          if (error) {
            setError((error.name || []).join(', '));
            setIsLoading(false);
          } else {
            setIsLoading(false);
            onConfirm(folder);
          }
        });
    }
  }

  const inputProps = {
    label: 'Folder Name',
    id: 'newFolderInput',
    name: "folderName",
    register: formMethods.register,
    required: true,
    maxLength: '255',
    autoFocus: true
  };

  const button1 = {
    label: "Cancel",
    onClick: onClose,
    secondary: true
  };

  const button2 = {
    label: "Create New Folder",
    onClick: formMethods.handleSubmit(onSubmit),
    disabled: isLoading || (!folderName || !folderName.length),
    primary: true,
  };

  return (
    <Drawer label="New Folder" onCloseOverlay={onClose} button1={button1} button2={button2} id="newFolderPopup">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TextInput {...inputProps} />
          { !!error && <div className="text-input__error">{error}</div> }
        </form>
      </FormProvider>
    </Drawer>
  );
}

export default NewFolderModal;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { SecureRoute as OktaSecureRoute } from '@okta/okta-react';

import { useAuthContext } from 'Context/AuthContext.js';
import { msalLoginRequest, isPFContext } from 'Utils/auth.js';
import { AUTH_TYPES } from 'Utils/const.js';

export const SecureRoute = props => {
  if (isPFContext()) return <Route {...props}><props.component /></Route>;

  const { authType } = useAuthContext();
  const isMSalAuthenticated = authType === AUTH_TYPES.MSAL;
  const RouteComponent = isMSalAuthenticated ? MsalSecureRoute : OktaSecureRoute;

  return <RouteComponent {...props} />
}

const MsalSecureRoute = ({ component, ...props }) => {
  const Component = component;

  return (
    <Route {...props}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={msalLoginRequest}
      >
        <Component />
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <Redirect to={'/login' + window.location.search} />
      </UnauthenticatedTemplate>
    </Route>
  );
};

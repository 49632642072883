import React, { createContext, forwardRef, useContext } from 'react';

const UserContext = createContext(null);

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;
export const useUserContext = () => useContext(UserContext);

export const withUserContext = (WrappedComponent) => (forwardRef((props, ref) => (
  <UserConsumer>
    { user => <WrappedComponent ref={ref} user={user} {...props} /> }
  </UserConsumer>
)));

export default UserContext;
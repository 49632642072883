import React, { useRef } from 'react';
import CN from 'classnames';
import { useUserContext } from 'Context/UserContext';
import { PercentageInput, CurrencyInput, Tooltip, Icon } from 'Atoms';
import { toCurrency } from 'Utils/formatter.js';
import { getTotalLabels } from './DesignEditorHelper.js';

import './DesignTotals.scss';

// const DEFINITIONS = {
//   MARGIN: 'Margin applied to sum of design cost and partner referral fees',
//   COMMISSION: 'Partner referral fees taken as percentage from MRR',
// }

const DesignTotals = (props) => {
  const user = useUserContext();
  const labels = useRef(getTotalLabels(user)).current;

  const {
    mrc, nrc,
    // externalMrc, externalNrc,
    mrr, nrr,
    mrv, nrv,
    marginAmt, marginPct, markupAmt,
    commissionAmt, commissionPct,
    userMarginPct, userMarkupAmt,
    quoteOrgName,
    validationErrors={},
    onChange, isEditing,
    hasMrc,
    hasMrr,
    hasUgMrr,
  } = props;

  const currency = props.currency || props.displayCurrency || 'USD';

  const className = CN('design-totals', {
    'design-totals__editing': isEditing
  });

  //const toPct = n => new Intl.NumberFormat({ maximumFractionDigits: 2, trailingZeroDisplay: 'stripIfInteger' }).format(n);
  const toPct = n => isNaN(n) ? 0 : Math.round(n);

  const showCommission = (isEditing) || 
    (hasUgMrr && !!(Number(commissionPct) || Number(commissionPct)));

  const showUgMargin = (hasUgMrr || isEditing);
  
  const showEndUserMargin = (hasMrr || isEditing) 
    && (userMarginPct || quoteOrgName === 'SungardAS');

  // const comCalc = `${toPct(commissionPct)}% of MRR ${toCurrency(mrr, currency)} = ${toCurrency(commissionAmt, currency)}`
  // const mgnCalc = `
  //   ${toPct(marginPct)}% margin of (MRC ${toCurrency(mrc, currency)} 
  //   + Agent Fee ${toCurrency(commissionAmt, currency)}) = ${toCurrency(marginAmt, currency)}`

  return (
    <div className={className}>
      {(!!hasMrc || isEditing) && (
        <div className="design-totals__cost">
          <div className="design-totals--label">
            {hasUgMrr ? labels.cost : 'MRC / NRC'}
          </div>
          <div className="design-totals--pct"> </div>
          <div className="design-totals--mr">
            {toCurrency(mrc, currency)}
          </div>
          <div className="design-totals--nr">
            {toCurrency(nrc, currency)}
          </div>
        </div>
      )}

      {!!showCommission && (
        <div className="design-totals__commission">
          <div
            className="design-totals--label"
            style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}
          >
            <span>{`+ ${toPct(commissionPct)}% Agent Commission Fee`}</span>
            {/* <PricingTooltip definition={DEFINITIONS.COMMISSION} calculation={comCalc} /> */}
          </div>
          <div className="design-totals--pct">
            {isEditing ? <PercentageInput value={commissionPct/100} onChange={onChange.commissionPct} error={validationErrors.commissionPct}/> : null }
          </div>
          <div className="design-totals--mr">
            {toCurrency(commissionAmt, currency)}
          </div>
          <div className="design-totals--nr">-</div>
        </div>
      )}

      {!!showUgMargin && (
        <>
          <div className="design-totals__margin">
            <div
              className="design-totals--label"
              style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}
            >
              <span>{`+ ${toPct(marginPct)}% Margin`}</span>
              {/* <PricingTooltip definition={DEFINITIONS.MARGIN} calculation={mgnCalc} /> */}
            </div>
            <div className="design-totals--pct">
              {isEditing ? <PercentageInput value={marginPct/100} onChange={onChange.marginPct} error={validationErrors.marginPct} /> : null }
            </div>
            <div className="design-totals--mr">
              {toCurrency(marginAmt, currency)}
            </div>
            <div className="design-totals--nr">
              {toCurrency(markupAmt, currency)}
            </div>
          </div>

          <hr />

          <div className="design-totals__rate">
            <div className="design-totals--label">
              { labels.ugRate }
            </div>
            <div className="design-totals--pct"></div>
            <div className="design-totals--mr">
            {isEditing
              ? <CurrencyInput currency={currency} value={mrr} onChange={onChange.mrr} />
              : toCurrency(mrr, currency)
            }
            </div>
            <div className="design-totals--nr">
            {isEditing
              ? <CurrencyInput currency={currency} value={nrr} onChange={onChange.nrr} />
              : toCurrency(nrr, currency)
            }
            </div>
          </div>
        </>
      )}

      {!user.is_internal && !!showEndUserMargin && (
      <>
        <div className="design-totals__enduser-margin">
          <div className="design-totals--label">
            {`+ ${toPct(userMarginPct)}% Margin`}
          </div>
          <div className="design-totals--pct"></div>
          <div className="design-totals--mr">
          </div>
          <div className="design-totals--nr">
            {toCurrency(userMarkupAmt, currency)}
          </div>
        </div>

        <hr />

        <div className="design-totals__enduser-rate">
          <div className="design-totals--label">{labels.rate}</div>
          <div className="design-totals--pct"></div>
          <div className="design-totals--mr">
            {toCurrency(mrv, currency)}
          </div>
          <div className="design-totals--nr">
            {toCurrency(nrv, currency)}
          </div>
        </div>
      </>
      )}

      {!!user.is_internal && !!showEndUserMargin && (
        <div className="design-totals__internal-enduser-view">
          <div className="design-totals--label">
            {`${quoteOrgName} list price (${toPct(userMarginPct)}% margin)`}
          </div>
          <div className="design-totals--pct">
            {/* {!!isEditing && (
              <PercentageInput value={userMarginPct/100} onChange={onChange.userMarginPct} error={validationErrors.userMarginPct}/> 
            )} */}
          </div>
          <div className="design-totals--mr">
          {isEditing
            ? <CurrencyInput currency={currency} value={mrv} onChange={onChange.mrv} />
            : toCurrency(mrv, currency)
          }
          </div>
          <div className="design-totals--nr">
          {isEditing
            ? <CurrencyInput currency={currency} value={nrv} onChange={onChange.nrv} />
            : toCurrency(nrv, currency)
          }
          </div>
        </div>
      )}
    </div>
  )
}

// TODO: Consider adding tooltips to give better explanation of calculation
// const PricingTooltip = ({ definition, calculation }) => (
//     <Tooltip
//       title={
//         <div>
//           <div style={{ fontSize: '.75rem', lineHeight: '1'}}>{definition}</div>
//           <div>{calculation}</div>
//         </div>
//       }
//     >
//       <div style={{ display: 'inline-block', fontSize: '1.25rem' }}><Icon name="info-circle-outline" /></div>
//     </Tooltip>
// );

export default DesignTotals;
import React, { useState } from 'react';
import { Popup } from 'Molecules';

import './DesignRejectionModal.scss';

const DesignRejectionModal = (props) => {
  const { onConfirm, onClose } = props;
  const [ reason, setReason ] = useState('');


  const onInput = e => setReason(e.target.value);
  const onClick = () => onConfirm(reason);

  const popupProps = {
    label: 'Are you sure you want to reject this design?',
    id: 'reject-design-modal',
    onClose,
    button1: { label: 'Cancel', onClick: onClose },
    button2: { label: 'Reject', onClick, primary: true, disabled: !reason }
  };

  return (
    <Popup {...popupProps}>
      <div>Rejecting this design indicates that this circuit requires further review and will need additional mediation.</div>
      <br />
      <div>Are you sure you want to reject this design validation?</div>
      <textarea name="details" placeholder="Reason for rejecting design" onChange={onInput} value={reason} maxLength="2000" />
    </Popup>
  );
}

export default DesignRejectionModal;
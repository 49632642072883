import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from "react-hook-form";

import { Flag, TextInput } from 'Atoms';
import { Lookup, Popup } from 'Molecules';
import { putFirst } from 'Utils/array.js';
import { getAllCountries } from 'Utils/countries.js';

import './AdvancedLocationModal.scss';

const AdvancedLocationModal = props => {
  const { onClose, onConfirm } = props;

  const [ countryOptions, setCountryOptions ] = useState([]);
  const formMethods = useForm();

  const formatOptionLabel = opt => <><Flag alpha2={opt.alpha2} />{opt.name}</>
  const toOption = opt => ({ ...opt, value: opt.alpha3, label: opt.name });
  const isAmerica = x => x.alpha3 === 'USA';

  useEffect(() => {
    const options = putFirst(getAllCountries(), isAmerica).map(toOption);
    setCountryOptions(options);
  }, []);

  const popupProps = {
    label: `Advanced Location Search`,
    button1: { label: 'Cancel', onClick: onClose },
    button2: { label: 'Submit', primary: true, onClick: onConfirm },
    onClose,
    id: 'advanced-location-modal'
  };

  return (
    <Popup {...popupProps}>
      <FormProvider {...formMethods}>
        <div className="advanced-location">
          <Lookup name="adv_country" options={countryOptions} label="Country" formatOptionLabel={formatOptionLabel} />
          <TextInput label={"Street Address"} name="adv_street"/>
          <div className="flex-row flex-row--2-col">
            <TextInput label={"Apartment, suite, unit, etc."} name="adv_line2_unit" />
            <TextInput label={"Floor"} name="adv_line2_floor" />
          </div>
          <div className="advanced-location__mpoe">* Quotes do not price inner-building information, only to the point of entry.</div>
          <TextInput label={"City"} name="adv_city" />
          <div className="flex-row flex-row--2-col">
            <TextInput label={"State/Province/Region"} name="adv_region" />
            <TextInput label={"Postal/Zip Code"} name="adv_postalCode" />
          </div>
        </div>
      </FormProvider>
    </Popup>
  )
}

export default AdvancedLocationModal;
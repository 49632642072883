import { msalInstance } from "../index.js";
import { STORAGE_KEYS } from 'Utils/const.js';

const { UG_TOKEN, OKTA_TOKEN, MSAL_LOGIN, PACKETFABRIC_ACCESS_TOKEN } = STORAGE_KEYS;

export const getTokensFromStorage = async (keys=[]) => {
  let accessToken;
  try {
    keys.every((key) => {
      const tokenStorage = JSON.parse(localStorage.getItem(key));
      accessToken = tokenStorage?.accessToken;
      return !accessToken;
    })
  } catch (e) {
    keys.forEach(key => localStorage.removeItem(key));
  }

  return new Promise(resolve => resolve(accessToken));
}

export const isEcxContext = () => window.location.hostname.startsWith('equinixfabric');

export const isPFContext = () => window.location.hostname.startsWith('packetfabric');

export const parseTokenFromUrl = () => {
  const tokenKey = '#access_token=';
  const hash = window.location.hash;

  return hash && hash.startsWith(tokenKey) ? hash.replace(tokenKey, '') : null;
}

export const isUgAuth = () => {
  const storage = window.localStorage;

  if (isEcxContext()) {
    return true;
  }
  const token = parseTokenFromUrl();
  if (token) {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const issuer = payload['iss'];
    if (issuer) {
      return issuer.includes('/accounts/api/v1/token/');
    }
  }
  return !!storage.getItem(UG_TOKEN) && !storage.getItem(OKTA_TOKEN);
}

const getPacketFabricToken = () => {
  const tokenStorage = JSON.parse(localStorage.getItem(PACKETFABRIC_ACCESS_TOKEN));
  const accessToken = tokenStorage?.accessToken;
  return accessToken;
};

export const getBearerToken = async () => {
  if (isPFContext()) return getPacketFabricToken();

  const msalLoggedIn = !!await window.localStorage.getItem(MSAL_LOGIN);
  const account = msalLoggedIn && msalInstance.getActiveAccount();
  const storageKeys = [ UG_TOKEN, !isEcxContext() && !isPFContext() && OKTA_TOKEN ].filter(Boolean);

  const token = (account) 
    ? await msalInstance.acquireTokenSilent({ ...msalLoginRequest, account })
    : await getTokensFromStorage([...storageKeys]);

  return token?.accessToken;
}

export const msalLoginRequest = {
  scopes: [ process.env.MSAL_SCOPE ]
}
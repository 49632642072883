import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Icon } from 'Atoms';

import './button.scss';

/**
 * A typical button component. Used for interactivity as well as form
 * submission.
 *
 * @param {String} buttonType - the type of the actual button element. Can be
 * one of "button", "submit", and "reset". "button" is the default. Only use the
 * others if this button is actually within a form where it can perform these
 * actions. Having an onClick prop and a non-"button" buttonType will log a
 * warning (and probably not work). Having a link prop and a non-"button"
 * buttonType will also log a warning (and probably not work).
 * @param {String | Object} className - additional class names
 * @param {Boolean} disabled - is the button interactive?
 * @param {String} icon - optional icon to show in button
 * @param {String} link - the location this button links to. This is used to
 * style links as buttons: make sure that this stylistic decision doesn't lead
 * to UX confusion!
 * @param {Function} onClick - the triggered function. For buttons, this is also
 * triggered on keypresses to the enter key and spacebar when the button is in
 * focus.
 * @param {Boolean} primary - the default button style
 * @param {Boolean} secondary - if there are a choice of buttons, the
 * less-important ones are distinguished with this prop
 * @param {String} size - the size of the button. Can be one of "default",
 * "small".
 * @param {String} status - a stylistic status marker. Added to the className.
 * @param {String} type - the style of the button. Can be one of:
 * * default: the default appearance
 * * tile: fills the width of its container and has a dashed border
 * * transparent: fills the width of its container and has a dashed border.
 *   You'll need to set the :hover `color` yourself.
 * * flat: neither background nor border is visible until the button is hovered
 *   or in focus. There _is_ the appropriate amount of padding around the
 *   content of the button, however. Flat buttons don't "respond" to the
 *   secondary prop; there should be no visual distinction between flat
 *   neighbors.
 * * link: appears as a text-like link, with only a little extra padding. Use
 *   this instead of styling with an anchor tag. Link buttons don't "respond"
 *   to the secondary prop; there should be no visual distinction between flat
 *   neighbors.
 * @param {Object} props - all other props are passed straight down to the
 * underlying <button> or <a> element.
 */

export default class Button extends Component {
  constructor(props) {
    super(props);

    this.state = { focused: false };
    this.handleKeyDown = this.handleKeyDown.bind(this);    
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleKeyDown(event) {
    if (this.state.focused && event.code === 'Space') {
      if (this.props.onClick) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onClick();
      }

      if (this.props.link) {
        window.location.href = this.props.link;
      }
    }
  }

  render() {
    const {
      buttonType='button',
      children,
      className,
      disabled,
      link,
      onClick,
      primary,
      secondary,
      size,
      status,
      type,
      icon,
      ...passedProps
    } = this.props;

    /* Prop validity checks */

    // check for a valid buttonType
    if (buttonType && !['button', 'submit', 'reset'].includes(buttonType)) {
      console.warn(`buttonType ${buttonType} is a non-standard type.`);
    }

    // check for supported sizes
    if (size && !['default', 'small'].includes(size)) {
      console.warn(`size ${size} is an unknown size.`);
    }

    // check for supported types
    if (type &&
      ![
        'default',
        'flat',
        'link',
        'tile',
        'transparent',
        'button',
        'inline'
      ].includes(type)
    ) {
      console.warn(`type ${type} is an unknown type. Guess what? You're getting "default."`);
    }

    /* Semantic clarity checks */

    if (secondary && primary) {
      console.warn('This button is both "secondary" and "primary"? Okay....');
    }

    if (link && onClick) {
      console.warn(
        `This button has a link (to ${link}) and an onClick handler. Do you want these both to fire?`,
      );
    }

    if (onClick && buttonType !== 'button') {
      console.warn(
        `This button has an onClick handler and a form trigger (${buttonType}). Do you want these both to fire?`,
      );
    }

    const buttonClassName = [
      { secondary: secondary && !primary },
      size,
      status,
      type && `button-${type}`,
    ];

    if (link) {
      // TODO: add spacebar handler
      // TODO: link addresses should be relative paths
      return (
        <Link
          to={link}
          className={classNames(
            'button',
            { disabled },
            buttonClassName,
            className
          )}
          type={buttonType}
          onFocus={() => { this.setState({ focused: true }); }}
          onBlur={() => { this.setState({ focused: false }); }}
          {...passedProps}
        >
          {children}
        </Link>
      );
    }

    return (
      <button
        className={classNames('button', buttonClassName, className)}
        onClick={onClick}
        type={buttonType}
        disabled={disabled}
        {...passedProps}
      >
        {!!icon && <Icon name={icon} /> }
        {children}
      </button>
    );
  }
}

import React, { Component, Fragment } from 'react';
import Chart from 'react-google-charts';
import ReactDOMServer from 'react-dom/server';

import { LoadingOverlay } from 'Atoms';

import { getQuoteTrace } from 'Actions/ccm/api.js';

import './QuoteTrace.scss';

class QuoteTrace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      traces: [],
      isFetching: true
    };

    this.getQuoteTrace = this.getQuoteTrace.bind(this);
  }

  getQuoteTrace() {
    this.setState({ isFetching: true });
    getQuoteTrace(this.props.id).then(traces => {
      (traces.error)
        ? this.setState({ isFetching: false, error: 'There was an error retrieving the quote tracing details.' })
        : this.setState({ isFetching: false, traces });
    });
  }

  componentDidMount() {
    this.getQuoteTrace();
  }

  render() {
    const { traces, error, isFetching } = this.state;

    // Map to Google Chart 
    const chartData = [[
      { type: 'string', id: 'ID' },
      { type: 'string', id: 'Name' },
      { type: 'string', id: 'style', role: 'style' },
      { type: 'string', role: 'tooltip', 'p': {'html': true} },
      { type: 'date', id: 'Start' },
      { type: 'date', id: 'End' },
    ]];

    const itemStyle = {
      'root' : 'color: #2E86C1',
      'product' : 'color: #229954',
      'product_rule' : 'color: #21618C',
      'vendor_api' : 'color: #D35400',
      'other' : 'color: #707B7C'
    };

    traces.forEach(trace => {
      chartData.push([
        trace.node_name, 
        trace.description,
        itemStyle[trace.node_type],
        ReactDOMServer.renderToStaticMarkup(<TraceLineToolTip {...trace } />),
        new Date(trace.created_date), 
        new Date(trace.completed_date)
      ]);
    });

    const height = (traces.length * 41) + 50 + 'px';

    return (
      <div className="quote-trace">
        {(!!error) && (
          <div className="quote-trace__error">{error}</div> 
        )}

        {(!!isFetching) && (
          <LoadingOverlay children="Retrieving quote trace information" />
        )}

        {(!isFetching && !traces.length) && (
          <div className="quote-trace__no-data">No trace data found.</div> 
        )}

        {(!isFetching || !!traces.length) && (
          <Chart
            width={'100%'}
            height={height}
            chartType="Timeline"
            loader={<LoadingOverlay children="Retrieving quote trace information" />}
            data={ chartData }
            options={{ showRowNumber: true }}
          />
        )}
      </div>
    );
  }
}

const TraceLineToolTip = ({ node_name, description, code_position, created_date, completed_date, duration,  style, function_name, file_name, line_number, messages, stack_trace  }) => {
  return (
    <div>
      <div className="trace-header">{description}</div>
      <div className="trace-info">
        <div className="trace-line">
          <div className="trace-label">
            Function
            <div className="trace-value">{function_name}</div>
          </div>
          <div className="trace-label">
            File
            <div className="trace-value">{file_name}</div>
          </div>
          <div className="trace-label">
            Line
            <div className="trace-value">{line_number}</div>
          </div>
        </div>
        <div className="trace-line">
          <div className="trace-label">
            Start
            <div className="trace-value">{created_date}</div>
          </div>
          <div className="trace-label">
            End
            <div className="trace-value">{completed_date}</div>
          </div>
          <div className="trace-label">
            Duration
            <div className="trace-value">{duration} seconds</div>
          </div>
        </div>

        {/* <dl className="trace-line">
          <dt>Function</dt>
          <dd>{function_name}</dd>
          <dt>File</dt>
          <dd>{file_name}</dd>
          <dt>Line</dt>
          <dd>{line_number}</dd>
          <dt>Start</dt>
          <dd>{created_date}</dd>
          <dt>End</dt>
          <dd>{completed_date}</dd>
          <dt>Duration</dt>
          <dd>{duration} seconds</dd>
        </dl> */}

      </div>
      <TraceMessages messages={messages} />
      <StackTrace stacktrace={stack_trace} />
    </div>
  )
}

const TraceMessages = ({ node_name, messages=[] }) => {
  return (messages.length > 0) && (
    <div>
      <div>Messages</div>
      { messages.map((message, index) =>
        <div className='trace-message' key='{node_name}_m{index}'>{message}</div>
      )}
    </div>
  );
}

const StackTrace = ({ node_name, stacktrace=[] }) => {
  return (stacktrace.length > 0) && (
    <div>
      <div>Stack Trace</div>
      <code>
        { stacktrace.map((stackTraceLine, index) => 
          <Fragment key='{node_name}_st{index}'>{stackTraceLine}<br /></Fragment>
        )}
      </code>
      {/* 
      { stacktrace.map(stackTraceLine =>
      <div className='trace-message'><code>{stackTraceLine}</code></div>
      )} */}
    </div>
  );
}

export default QuoteTrace;
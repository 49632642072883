import React, { Component } from 'react';
import { withMsal } from "@azure/msal-react";
import { withAuth } from '@okta/okta-react';
import { AuthProvider } from 'Context/AuthContext.js';
import { isUgAuth, msalLoginRequest } from 'Utils/auth.js';

import { AUTH_TYPES, STORAGE_KEYS } from 'Utils/const.js';

const { CCM_USER, MSAL_LOGIN } = STORAGE_KEYS;
const { OKTA_TOKEN, UG_TOKEN, MSAL } = AUTH_TYPES;

/* 
x setBearerTokenMethods, authType on login
x Move authMethods to context/hook
x Replace Securtiy component - onMount/set auth type/bearertokenmethod
x Replace withAuth HOC
x Move MSAL security config into DOCKER env variables
x Update login widget to hide okta login if currently authenticated with msal
x Handle redirect
x Handle logout from nav menu
*/

// purpose: sit below the auth contexts to identify auth type and introduce new Context HOC / hook to use with existing components
export default withMsal(withAuth(
  class AuthManager extends Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      const msalLoggedIn = !!window.localStorage.getItem(MSAL_LOGIN);
      const isMsalAuthenticacted = async () => await this.props.msalContext.instance.ssoSilent(msalLoginRequest);
      
      return msalLoggedIn && isMsalAuthenticacted();
    }

    render() {
      const { auth, msalContext, children } = this.props;
      const msalLoggedIn = !!window.localStorage.getItem(MSAL_LOGIN);

      const resolvedPromise = () => new Promise(resolve => resolve(true));

      const account = msalContext.instance.getActiveAccount();

      const authType = (msalLoggedIn && account) ? MSAL : isUgAuth ? UG_TOKEN : OKTA_TOKEN;

      const msalLogout = async (href="/") => {

        const postLogoutRedirectUri = `/logout`;

        // const onRedirectNavigate = () => {
        // window.localStorage.removeItem(CCM_USER);
        //   //window.location.href =  `${href}#msal`;
        //   return false;
        // };

        // await msalContext.instance.logoutRedirect({ 
        //   account,
        //   //onRedirectNavigate,
        //   postLogoutRedirectUri,
        // });

        window.localStorage.removeItem(CCM_USER);
        window.localStorage.removeItem(MSAL_LOGIN);
        window.location.href =  `${href}#msal`;
      }

      const contextValue = {
        authType,

        isAuthenticated: authType === MSAL 
          ? resolvedPromise
          : auth.isAuthenticated,

        redirect: authType === MSAL 
          ? resolvedPromise
          : auth.redirect,

        logout: authType === MSAL 
          ? msalLogout
          : auth.logout,
      }

      return <AuthProvider value={contextValue}>{children}</AuthProvider>
    }
  }
));
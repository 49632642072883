import React, { forwardRef } from 'react';
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';

export const useAnalytics = () => {
  const history = useHistory();

  const trackPageview = ({ pathname, search }) => {
    ReactGA.pageview(pathname + search);
  };

  const initAnalytics = () => {
    const debug = false; // (process.env.NODE_ENV === 'development'); // so chatty
    const trackingId = process.env.GOOGLE_ANALYTICS_ID;

    ReactGA.initialize(trackingId, { debug });

    history.listen(trackPageview);
  };

  // https://github.com/react-ga/react-ga#reactgaeventargs
  const trackEvent = (event) => {
    // const { category, action, value, label, nonInteraction, transport } = event
    ReactGA.event({ ...event })
  }

  const setUserTracking = (user) => {
    ReactGA.set({
      userId: user.id,
      orgId: user.organization_id,
      isInternal: user.is_internal
    });
  };

  return {
    initAnalytics,
    setUserTracking,
    trackEvent
  };
}

export const withAnalytics = (Component) => (forwardRef((props, ref) => {
    const { trackEvent } = useAnalytics();
    return <Component {...props} ref={ref} trackEvent={trackEvent} />;
  })
);
import groupBy from 'lodash/groupBy';
import { parse, stringifyUrl } from 'query-string';

import { sortByLabel } from 'Utils/array.js';
import { getAlpha2 } from 'Utils/countries.js';
import { getQuoteStatus, getStateCode } from 'Utils/formatter.js';

const MANUAL_STATUS = ['manual', 'error'];

// These are passed back as option lookups so map to option format
export const filterOptionsMapper = resp => {
  const { assignee, country, follow_up_category, location_type=[], organization, service_type, state, status, user } = resp;

  const toOption = ({ id, name }) => ({ 
    label: name, 
    value: (id !== undefined ? id : name).toString(),
  });

  const toCountryOption = ({ id, name }) => ({ 
    label: name, 
    value: id, 
    flag: getAlpha2(id).toLowerCase(),
  });

  const toStateOption = ({ name }) => ({ 
    label: name, 
    value: getStateCode(name),
  });

  const toStatusOption = ({ name }) => ({
    label: getQuoteStatus(name)?.text || name,
    value: name,
  });

  const toUserOption = ({ name, id, organization_name }) => ({
    label: name,
    value: id,
    orgName: organization_name,
  });

  const toUserGroupOption = ([label, users]) => ({ 
    label,
    options: users.map(toUserOption).sort(sortByLabel),
  });

  const groupedUsers = groupBy(user, 'organization_name');
  const filteredStatus = status?.filter(s => !MANUAL_STATUS.includes(s.name)).map(toStatusOption);
  const manualStatus = (status?.length !== filteredStatus?.length) 
    && { ...toStatusOption({ name: MANUAL_STATUS[0] }), value: MANUAL_STATUS.join(',') };

  return {
    assignee: assignee?.map(x => ({ 
      label: x.name, value: x.email 
    })),
    followup: follow_up_category?.map(toOption)
      .map(x => x.value !== 'No followup' ? x : { ...x, value: null }),
    region: [
      ...country.filter(x => x.id === 'USA').map(toCountryOption),
      ...state.map(toStateOption).sort(sortByLabel),
      ...country.filter(x => x.id !== 'USA').map(toCountryOption).sort(sortByLabel),
      ...location_type.filter(x => x.id === 'unvalidated')
        .map(x => ({ ...toOption(x), icon: 'question-mark' })),
    ],
    org: organization?.map(toOption).sort(sortByLabel),
    service: service_type?.map(toOption).filter(x => x.value !== 'all'),
    status: [ ...filteredStatus, manualStatus ].filter(Boolean).sort(sortByLabel),
    user: Object.entries(groupedUsers)?.map(toUserGroupOption).sort(sortByLabel),
  }
};

export const queryStringify = ({ values={}, showAllOrgUsers, retainFolderName }) => {
  const { org, user, status, region, service } = values;
  const { pathname } = window.location;

  const query = {
    o: showAllOrgUsers || org?.value,
    u: showAllOrgUsers ? null : user?.value,
    s: status?.length ? status.map(x => x.value).join(',') : undefined,
    region: region?.value,
    service: service?.value,
  }

  const url = !retainFolderName && (query.u || query.o) ? '/quote-manager/' : pathname;
  return stringifyUrl({ url, query }, { skipNull: true });
};

export const queryParsify = (search) => {
  const { o, u, s, region, service } = parse(search);
  return { org: o, user: u, status: s, region, service };
};

// const getStatusOptions = (isInternal) => {
//   const STATUS_OPTIONS = {
//     internal: ['pending', 'manual', 'quoted', 'manually-quoted', 'review', 'edited', 'ordering-draft-saved', 'ordering-review', 'ordering-pending-approval', 'design-validation', 'ordered', 'manually-ordered', 'validation-error', 'expired', 'no-bid', 'closed'],
//     external: ['pending', 'manual', 'quoted', 'manually-quoted', 'review', 'edited', 'ordering-draft-saved', 'ordering-review', 'ordering-pending-approval', 'ordered', 'manually-ordered', 'validation-error', 'expired', 'no-bid']
//   };
//   const options = isInternal ? STATUS_OPTIONS.internal : STATUS_OPTIONS.external;
//   const toStatusOption = status => ({ label: getQuoteStatus(status).text, value: status });
  
//   return options.map(toStatusOption);
// }
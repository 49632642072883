import React, { Component, createRef } from 'react';
import UserContext from 'Context/UserContext.js';
import debounce from 'lodash/debounce';

import Lookup from './Lookup.jsx';

import { getRecentCustomers, getCustomers, createCustomer } from 'Actions/ccm/api.js';
import { sortByLabel } from 'Utils/array.js';

import './Customer.scss';

class CustomerDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultOptions: [],
      inputValue: '',
      isLoading: undefined,
      value: props.value,
    };

    this.inputRef = createRef();

    this.onChange = this.onChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.loadOptions = debounce(this.loadOptions, 200).bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.fetchRecentCustomers = this.fetchRecentCustomers.bind(this);
  }
 
  componentDidMount() {
    const { defaultValue } = this.props;

    if (!this.state.value && defaultValue) {
      this.onChange({ label: defaultValue, value: defaultValue });
    } else {
      this.fetchRecentCustomers()
    }
  }

  fetchRecentCustomers() {
    this.setState({ isLoading: true });

    getRecentCustomers().then((results=[]) => {
      const defaultOptions = results.length ? [{
        label: 'Recently Used Customers',
        icon: 'history',
        options: results.map(({ name, id, url }) => ({ label: name, value: name, id, url }))
      }] : false;

      this.setState({ defaultOptions });
    }).then(() => this.setState({ isLoading: false }));
  }

  onChange(option) {
    const value = option || null;
    const inputValue = option ? option.label : '';

    this.setState({ value, inputValue });
    this.props.onChange(option);
  }

  handleCreate(label) {
    this.setState({ value: { label }, isLoading: true });
    const { organizationId } = this.props;

    createCustomer(label, organizationId).then(resp => {
      const value = { label, value: label, id: resp.id, url: resp.url };
      this.setState({ value, isLoading: false });
      this.props.onChange(value);
    });
  }

  onInputChange(inputValue, { action }) {
    const { value } = this.state;
    
    if (action === 'input-blur') {
      this.setState({ inputValue: !value ? '' : value.label });
    }
    
    else if (action === 'input-change') {
      this.setState({ inputValue });
    }

    return;
  }

  loadOptions(inputValue="", callback) {
    const user = this.context;
    const orgId = this.props.organizationId || user.organization_id;

    const request = { 
      name__icontains: inputValue, 
      owner_organization_id: orgId,
    };

    getCustomers(request)
      .then(({ results=[] }) => {
        const options = results
          .map(({ name, id, url }) => ({ label: name, value: name, id, url }))
          .sort(sortByLabel)
        callback(options);
      });
  }

  render() {
    const { defaultOptions, isLoading, value, inputValue } = this.state;
    const { isDisabled } = this.props;

    return (
      <Lookup
        name="customer"
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        inputValue={inputValue}
        label={"For Customer"}
        icon={"customer"}
        editable={true}
        defaultOptions={defaultOptions}
        async={true}
        createable={true}
        loadOptions={this.loadOptions}
        isClearable={true}
        isDisabled={isLoading || isDisabled}
        isLoading={isLoading}
        onCreateOption={this.handleCreate}
        value={value}
        noOptionsMessage={()=>'Enter a customer name'}
        error={this.props.error}
        ref={this.inputRef}
      />
    )
  }
}
CustomerDropdown.contextType = UserContext;

export default CustomerDropdown;

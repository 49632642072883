import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, Icon, LabelledInput, LoadingOverlay, SortableTable, StatusIcon } from 'Atoms';

import { getQuoteVendors } from 'Actions/ccm/api.js';

import './QuoteVendors.scss';

const QuoteVendorReport = ({ specId }) => {
  const columnData = useRef([
    { key: 'icon', header: ' ', render: i => <Icon name={i.rateIcon} /> },
    { keys: ['vendor', 'product'], header: 'Vendor Product' },
    { key: 'productType', header: 'Product Type' },
    { key: 'proximityA', render: x => <ProximityIcon text={x.proximityA} src={x.proximityASrc} val={x.proximityAVal} /> },
    { key: 'proximityZ', render: x => <ProximityIcon text={x.proximityZ} src={x.proximityZSrc} val={x.proximityZVal} /> },
    { key: 'result', render: x => <StatusIcon status={x.resultIcon} text={x.result} /> },
    { keys: ['criterion', 'description'], header: 'Description' }
  ]);

  const [ isFetching, setIsFetching ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ showUnsupportedCountry, setShowUnsupportedCountry ] = useState(false);
  const [ rows, setRows ] = useState([]);

  useEffect(function getVendorReport() {
    setIsFetching(true)
    getQuoteVendors(specId)
      .then(({ results, error }) => {
        error ? alert(`Error: ${error}`) : setRows(results)
      })
      .finally(() => setIsFetching(false));
  }, [specId]);


  const onChangeSearch = e => setSearch(e.target.value);
  const onChangeShowUnsupportedCountry = e => setShowUnsupportedCountry(e.target.checked);

  const filteredRows = useMemo(() => {
    const needle = search.trim().toLowerCase();
    
    return rows.filter(row => {
      const description = row.description || '';
      const fields = [row.result, row.criterion, row.proximity, description, row.product, row.productType, row.vendor];
      const regionFilter = showUnsupportedCountry || row.criterion !== 'Quote Country';

      return regionFilter && fields.some(field => (field || '').toLowerCase().includes(needle));
    })
  }, [rows, search, showUnsupportedCountry]);

  return (
    <div className="quote-vendor-report">
      <div className="quote-vendor-report__header">  
        <h3>Vendor Summary</h3>
        <LabelledInput value={search} onChange={onChangeSearch} placeholder="Search" type="search" label={<Icon name="magnifying-glass" />} /> 
        <Checkbox checked={showUnsupportedCountry} onChange={onChangeShowUnsupportedCountry} label="Show out of region products"/>
      </div>
      {!!isFetching && <LoadingOverlay /> }

      {(!!isFetching || !!rows.length) 
        ? <SortableTable columnData={columnData.current} rowData={filteredRows} defaultSort='vendor' />
        : <div>No Vendor Report Available</div>
      }
    </div>
  )
}

const ProximityIcon = ({ text, src='', val='' }) => {
  const className = `proximity proximity--${text}`;
  const descVal = val && val !== text ? val : '';
  const descSrc = src ? `via ${src}` : '';

  const description = [descVal, descSrc].filter(Boolean).join(' ');

  return (!text ? '' : 
    <>
      <span className={className}>{text}</span>
      <div className="proximity__via">{`(${description})`}</div>
    </>
  );
};

export default QuoteVendorReport;
import React from 'react';
import Upload from 'antd/es/upload/';

import "antd/lib/upload/style/index.css";

const Dragger = Upload.Dragger;

const Uploader = props => {
  const { text='Click or drag file to this area to upload', hint='Support for a single or bulk upload.', ...restProps } = props;

  return (
    <Dragger {...restProps}>
      {/* <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p> */}
      { !!text && ( <p className="ant-upload-text">{text}</p> )}
      { !!hint && ( <p className="ant-upload-hint">{hint}</p> )}
    </Dragger>
  )
}

export default Uploader;
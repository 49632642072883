import React, { useState, useEffect, useRef } from 'react';
import CN from 'classnames';
import { useUserContext } from 'Context/UserContext.js';
import { Checkbox, ErrorIcon } from 'Atoms';
import { Lookup } from 'Molecules';

import { getUsers, getOrganizations } from 'Actions/ccm/api.js';
import { sortByLabel } from 'Utils/array.js';

import './PartnerPricing.scss';

const PartnerPricing = props => {
  const { className='', updatePartnerData, updateNoBid, defaultValues={}, errors={} } = props;
  const [organizations, setOrganizations] = useState([]);
  const [csas, setCsas] = useState([]);
  const [reps, setReps] = useState([]);

  const [isEnterprise, setIsEnterprise] = useState((defaultValues.partnerType || 'wholesale') === 'enterprise');
  const [partner, setPartner] = useState(defaultValues.partner);
  const [csaRep, setCsaRep] = useState(defaultValues.csaRep);
  const [salesRep, setSalesRep] = useState(defaultValues.salesRep);
  const [noBid, setNoBid] = useState(!!defaultValues.noBid);

  const partnerInputRef = useRef(null);
  const salesInputRef = useRef(null);
  const csaInputRef = useRef(null);

  const user = useUserContext();

  const showNoBid = user.is_internal;

  // onMount: Get user dropdown data
  useEffect(function onMount() {
    const toUserOptions = results => results
      .map(({ name, id }) => ({ label: name, value: id }))
      .sort(sortByLabel);

    getUsers({ is_active: true, is_csa: true, page_size: 1000 })
      .then(({ results }) => { 
        const users = toUserOptions(results);
        const defaultOption = defaultValues.csaRep || users.find(x => x.value === defaultValues.csaRepId);

        setCsas(users);
        setCsaRep(defaultOption);
        //csaInputRef.current && defaultOption && csaInputRef.current.select.setValue(defaultOption);
      });
      
    getUsers({ is_active: true, is_sales_representative: true, page_size: 1000 }).then(({ results }) => {
      const users = toUserOptions(results);
      const defaultOption = defaultValues.salesRep || users.find(x => x.value === defaultValues.salesRepId);

      setReps(users);
      setSalesRep(defaultOption);
      //salesInputRef.current && defaultValues.csaRep && salesInputRef.current.select.setValue(defaultOption);
    });

    const getWholesale = getOrganizations({ 'is_wholesale_partner': true });
    const getEnterprise = getOrganizations({ 'is_enterprise_partner': true, 'is_wholesale_partner': false });
    const toPartnerOption = org => ({ label: org.name, value: org.id, ...org });

    Promise.all([getWholesale, getEnterprise]).then(([ wholesale, enterprise ]) => {
      const options = [...wholesale.results, ...enterprise.results].map(toPartnerOption).sort(sortByLabel);
      setOrganizations(options);

      if (partnerInputRef.current && defaultValues.partner) {
        const selected = options.find(x => x.id === defaultValues.partner.id);
        partnerInputRef.current.select.setValue(selected);
      }
    });

  }, [defaultValues.csaRep, defaultValues.salesRep, defaultValues.partner]);

  // Send state updates to prop method `updatePartnerData`
  useEffect(function partnerDataValuesUpdated() {
    const partnerData = {
      partner: partner || undefined,
      partnerType: (partner ? (isEnterprise ? 'enterprise' : 'wholesale') : undefined),
      csaRep: ((csaRep && partner) ? csaRep.value : undefined),
      salesRep: ((salesRep && partner) ? salesRep.value : undefined),
      noBid: noBid
    };
    updatePartnerData(partnerData);

  }, [isEnterprise, partner, csaRep, salesRep, updatePartnerData, noBid]);

  const onChangeIsEnterprise = e => setIsEnterprise(e.target.checked);
  const onChangeNoBid = e => {
    setNoBid(e.target.checked);
    updateNoBid(e.target.checked);
  }

  const onChangePartner = option => {
    setPartner(option);
    setIsEnterprise(option?.is_enterprise_partner && !option?.is_wholesale_partner);
  };

  const onChangeCsaRep = option => setCsaRep(option);
  const onChangeSalesRep = option => setSalesRep(option);

  const partnerCommission = (!partner ? null : isEnterprise 
    ? `Enterprise Agent Commission: ${Number(partner.mrr_partner_commission_enterprise)}%`
    : `Wholesale Agent Commission: ${Number(partner.mrr_partner_commission_wholesale)}%`
  );

  const classNames = {
    partner: CN({ 'error': errors.partnerData && !partner }),
    csaRep: CN({ 'error': errors.partnerData && !csaRep }),
    commission: CN('partner-pricing-commission', { 'partner-pricing-commission--visible': !!partnerCommission })
  };

  const showingCheckbox = !!partner && !!partner.is_enterprise_partner && !!partner.is_wholesale_partner;

  return (
    <div className={`partner-pricing ${className}`}>
      <div className="partner-pricing-options lookups">
        <Lookup icon="customer" label="Price as Organization" name="partner-lookup" onChange={onChangePartner} isLoading={!organizations.length} options={organizations} className={classNames.partner} ref={partnerInputRef} />
        <Lookup icon="user" label="Select a Sales Rep" name="sales-rep-lookup" onChange={onChangeSalesRep} options={reps} isLoading={!reps.length} value={salesRep} className={classNames.csaRep} ref={salesInputRef} />
        <Lookup icon="user" label="Select a CSA" name="csa-rep-lookup" onChange={onChangeCsaRep} options={csas} isLoading={!csas.length} value={csaRep} ref={csaInputRef} />
      </div>

      <div className="partner-pricing__row">
        <div className="partner-pricing-type">
          {!!showingCheckbox && <Checkbox checked={isEnterprise} onChange={onChangeIsEnterprise} label="Enterprise" />}
          {!!partnerCommission && <div className={classNames.commission}>{partnerCommission}</div> }
        </div>
        {!!showNoBid && (
          <div className="partner-pricing__no-bid">
            <Checkbox checked={noBid} onChange={onChangeNoBid} label="Skip Manual Pricing" />
          </div>
        )}
      </div>
      {!!errors.partnerData && <div className="partner-pricing-validation-error"><ErrorIcon />{errors.partnerData}</div>}
    </div>
  )
};

export default PartnerPricing;

import React, { useEffect, useState } from 'react';
import { Icon, LoadingOverlay, Timeline } from 'Atoms';
import { getQuoteEvents } from 'Actions/ccm/api.js';
import { formatDate } from 'Utils/formatter.js';

import './QuoteHistory.scss';

const QuoteHistory = ({ id }) => {
  const [ events, setEvents ] = useState([]);
  const [ isFetching, setIsFetching ] = useState(false);

  useEffect(() => {
    const FILTER_TYPES = ['quote_created', 'ordering-draft-saved', 'quote_order_draft_updated'];
    const eventMapper = results => results
      .filter(x => !FILTER_TYPES.includes(x.type));

    setIsFetching(true);
    getQuoteEvents(id).then(events => {
      events.error
        ? alert('Sorry there was an error. Please try again.')
        : setEvents(eventMapper(events));
    });
    setIsFetching(false);
  }, [ id ]);

  const toDescription = event => {
    const { type, userName, eventTime, details = {} } = event;
    const { from_user_name, from_organization_name, to_user_name } = details;

    return (type === 'quote_transferred' && details)
      ? <>{formatDate(eventTime)} from <b>{from_user_name}</b> of <b>{from_organization_name}</b> to <b>{to_user_name}</b></>
      : <>{formatDate(eventTime)} by <b>{userName}</b></>
  }

  const items = events.map(event => {
    const { id, type, message, icon } = event;
    return {
      label: message,
      description: toDescription(event),
      dot: <Icon className={`quote-history-icon--${type.replace('_', '-')}`} name={icon} />,
      key: id,
    }
  });

  return (
    <div className="quote-history">
      {!!isFetching && <LoadingOverlay />}
      <h3>Quote History</h3>
      <Timeline items={items} />
    </div>
  );
}

export default QuoteHistory;
import React, { useEffect, useState } from 'react';
import CN from 'classnames';
import { Checkbox, LoadingOverlay } from 'Atoms';
import { Popup } from 'Molecules';

import { getQuoteOptions, requestSupport } from 'Actions/ccm/api.js';
import { getPermissions, toSortedQuoteOptions } from 'Widgets/CreateQuote/CreateQuoteMapper';

import './SupportModal.scss';

const CHECKBOX_OPTIONS = {
  quote_question: "I have a question about my quote.",
  different_datacenter: "I’m looking for a different data center to connect to.",
  multi_site: "I’m looking for a multi-site WAN opportunity.",
  carrier_diversity: "I have carrier diversity needs.",
  other: "Other, please describe below.",
};

const SupportModal = (props) => {
  const { onConfirm, onClose, quoteId } = props;
  
  const [ isFetching, setIsFetching ] = useState(false);
  const [ checked, setChecked ] = useState([]);
  const [ details, setDetails ] = useState('');
  const [ hasDiversity, setHasDiversity ] = useState();

  useEffect(() => {
    getQuoteOptions().then(resp => {
      const options = toSortedQuoteOptions(resp);
      const permissions = getPermissions(options);

      setHasDiversity(!!permissions['carrier-diverse']);
    });
  }, []);

  const onInput = e => setDetails(e.target.value);

  const onClick = () => {
    const formData = {
      information_options: checked.map(c => CHECKBOX_OPTIONS[c]),
      details: details || undefined,
      url: location.href,
      quote_id: quoteId || undefined
    };

    setIsFetching(true);
    requestSupport(formData).then(({ error }) => {
      (error) ? alert(error) : (onConfirm && onConfirm());
      setIsFetching(false);
    });
  }

  const popupProps = {
    label: 'What are you looking for?',
    id: 'support-modal',
    onClose,
    button1: { label: 'Cancel', onClick: onClose },
    button2: { label: 'Submit', onClick, primary: true, disabled: isFetching }
  };

  return (
    <Popup {...popupProps}>
      <>
        <div>Select from the options below and we'll be in touch with you soon.</div>
        <SupportCheckboxes checked={checked} setChecked={setChecked} hasDiversity={hasDiversity} />
        <textarea name="details" placeholder="Provide your details here." onChange={onInput} value={details} maxLength="2000" />
      </>
    </Popup>
  );
}

const SupportCheckboxes = ({ checked, setChecked, hasDiversity }) => {  
  const onChange = ({ target }) => setChecked(target.checked ? [...checked, target.name] : checked.filter(x => x !== target.name));
  const isFetching = hasDiversity === undefined;
  const className = CN('support-modal__checkboxes', { 
    'support-modal__checkboxes--fetching': isFetching,
  });

  return (
    <div className={className}>
      { Object.entries(CHECKBOX_OPTIONS)
        .filter(([key,]) => (key !== 'carrier_diversity' || !hasDiversity))
        .map(([key, label]) => (
          <Checkbox key={key} name={key} label={label} checked={checked.includes(key)} onChange={onChange} />
        ))}
      { !!isFetching && <LoadingOverlay /> }
    </div>
  ); 
}

export default SupportModal;
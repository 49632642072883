import React, { useRef } from 'react';
import CN from 'classnames';

import './LabelledInput.scss';

const LabelledInput = props => {
  const inputRef = useRef(null);

  const { label, className='', labelPosition = "right", error, ...inputProps } = props;

  const componentClassName = CN(`labelled-input ${className}`, { 
    'labelled-input--label-left': labelPosition==='left',
    'labelled-input--has-error': !!error,
  });

  const onClick = () => inputRef.current.select();

  return (
    <div className="labelled-input__wrapper">
      <div className={componentClassName} onClick={onClick}>
        <div className="labelled-input__input-container">
          <input ref={inputRef} className="labelled-input__input" type="text" {...inputProps} />
        </div>
        <label className="labelled-input__label" htmlFor={name}>{label}</label>
      </div>
      {!!error && <div className="labelled-input__error">{error}</div> }
    </div>
  );  
}

export default LabelledInput;
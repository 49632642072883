import React from 'react';
import classNames from 'classnames';
import * as esAntRadio from 'antd/es/radio';

import 'antd/lib/radio/style/index.css';
import './radios.scss';

const AntRadio = esAntRadio.default;
const AntRadioGroup = AntRadio.Group;
const AntRadioButton = AntRadio.Button;

const RadioGroup = props => (
  <AntRadioGroup
    value={props.value}
    onChange={props.onChange}
    className={classNames('radio-group', props.className)}
    name={props.name}
    defaultValue={props.options[0].value}
  >
    {props.options.map(option => (
      <AntRadio
        key={option.value}
        value={option.value}
        disabled={option.disabled}
        className={classNames(option.className)}
      >
        {option.label}
      </AntRadio>
    ))}
  </AntRadioGroup>
);


const RadioButtonGroup = ({ value, onChange, className, name, options=[], defaultValue=options[0].value } ) => (
  <AntRadioGroup value={value}
    onChange={onChange}
    className={classNames('radio-group', className)}
    name={name}
    defaultValue={defaultValue}
  >
    { options.map(({ value, disabled, className='', label }) => (
      <AntRadioButton key={value} value={value} disabled={disabled} className={className} children={label} />
    )) }
  </AntRadioGroup>
);

export { RadioGroup, RadioButtonGroup };
import React from 'react';
import classNames from 'classnames';

import './toggle.scss';

/**
 * Creates an iOS-looking toggle
 *
 * @param {string | object } className - additional classes for this Toggle
 * @param {Boolean} disabled - disabled Toggles are uninteractable and gray
 * @param {string} id - the id passed down to the form
 * @param { object } label - contains `yes` and `no` properties which are
 * responsible for the labels. The defaults are yes: 'Yes' and no: 'No'.
 * @param {Boolean} readOnly - passed down directly to the input element. If
 * the Toggle is controlled (and thus has no event handlers itself), this
 * should be true.
 * @param {Boolean} small - make the Toggle small. This fits in nicely within
 * other small components, Accordions, say.
 * @param {Boolean} value - the state of the Toggle
 */
const Toggle = (props) => {
  const {
    className,
    disabled,
    id,
    label = { yes: 'Yes', no: 'No' },
    onChange,
    readOnly,
    small,
    value,
  } = props;

  return (
    <label
      className={classNames('toggle', { disabled, small }, className)}
      htmlFor={id}
    >
      <input
        type="checkbox"
        name={id}
        id={id}
        checked={value}
        disabled={disabled}
        onChange={onChange}
        readOnly={readOnly}
      />
      <div className="slider round" />
      {!!value ? label.yes : label.no}
    </label>
  );
};

export default Toggle;

import React, { useState, useEffect, useRef } from 'react';
import CN from 'classnames';
import { Helmet } from 'react-helmet-async';
import SVG from 'react-inlinesvg';
import { Button, Icon, Notification } from 'Atoms';
import { MODALS, ModalManager } from 'Molecules';
import CreateQuote from './CreateQuote.jsx';

import { getQuoteOptions, getSystemStatusNotification } from 'Actions/ccm/api.js';

import './EcxHomePage.scss';
import { DefFavicon, DefFaviconName, SvgFullWhiteLogo, FullWhiteLogoName, COMPANY_SHORT_NAME, PAGE_TITLE_NAME, APP_PRODUCT_NAME } from 'Utils/branding.js';

const EcxHomePage = () => {
  const [ outageNotification, setOutageNotification ] = useState(null);
  const [ notificationMessage, setNotificationMessage ] = useState();
  const [ activeForm, setActiveForm ] = useState(0);
  const [ quoteOptions, setQuoteOptions ] = useState();

  const modalRef = useRef();

  const onClick = () => modalRef.current.open(MODALS.SUPPORT, {
    onConfirm: () => setNotificationMessage(`Your information request has been sent! A ${COMPANY_SHORT_NAME} representative will be in touch with you shortly.`)
   });

  const onDismiss = () => setNotificationMessage(null);

  useEffect(() => {
    getSystemStatusNotification().then(msg => setOutageNotification(msg));
    getQuoteOptions({ organizationKey: 'equinix-ecx' }).then(resp => !resp.error && setQuoteOptions(resp))
  }, [])

  const classNames = {
    form1: CN('form1 ecx-home__form', { 'form1--expanded': activeForm===1, }),
    space: CN('space', { 'space--hidden': activeForm===0 }),
    form2: CN('form2 ecx-home__form', { 'form2--expanded': activeForm===2 }),
  }

  const CloseButton = () => (
    <Button className="close-button" icon="close" onClick={() => setActiveForm(0) } />
  )

  return (
    <div className="ecx-home">
      <Helmet>
        <title>{PAGE_TITLE_NAME}: Last Mile Access</title>
        <link rel={DefFaviconName} type="image/png" href={DefFavicon} />
      </Helmet>

      {!!outageNotification && (<Notification.Warning children={outageNotification} />)}
      {!!notificationMessage && (<Notification.Success duration={7500} onDismiss={onDismiss} children={notificationMessage} />)}

      <section className="ecx-home__content">
        <div className="ecx-home__content-underlay patterned--dark"></div>
        <h1>Get Last Mile Access and Dedicated Internet on Platform Equinix<sup>®</sup> with {APP_PRODUCT_NAME}</h1>
        <div className="ecx-home__forms">
          <div className={classNames.form1}>
            <div className="ecx-home__form-icons"><Icon name="customer" /><Icon name="long-arrow" /><Icon name="cloud-data-center" /></div>
            <p>Connect from any location to an Equinix Fabric location</p>
            { activeForm === 1 
              ? <><CloseButton /><CreateQuote type="ethernet" quoteOptions={quoteOptions} /></>
              : <Button className="button--rounded" onClick={()=> setActiveForm(1)}>Get a quote for last mile access</Button>
            }
          </div>
          <div className={classNames.space}></div>
          <div className={classNames.form2}>
            <div className="ecx-home__form-icons"><Icon name="cloud-data-center" /><Icon name="long-arrow" /><Icon name="internet-signal" /></div>
            <p>Add global internet access on Platform Equinix via Cross Connect, Equinix Fabric™ or from your virtual Network Edge Device</p>
            { activeForm === 2
              ? <><CloseButton /><CreateQuote type="IP" quoteOptions={quoteOptions} /></>
              : <Button className="button--rounded" onClick={()=> setActiveForm(2)}>Get a quote for internet</Button>
            }
          </div>
        </div>
      </section>

      <section className="ecx-home-section ecx-home-section--footer">
        <div className="ecx-home-section__support-questions">
          <ul>
            <li>Have a question about your quote?</li>
            <li>Looking for a different data center to connect to?</li>
            <li>Looking for a multi-site WAN opportunity?</li>
            <li>Have carrier diversity needs?</li>
          </ul>
          <Button onClick={onClick} icon="support" children="Let us help" />
        </div>

        <div className="text__service-delivery">Services are delivered by</div>
        <SVG src={SvgFullWhiteLogo} alt={FullWhiteLogoName} />
      </section>

      <ModalManager ref={modalRef} />
    </div>
  )
}

export default EcxHomePage;

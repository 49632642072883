import React, { Component } from 'react';
import uniqueId from 'lodash/uniqueId';

import { Icon } from 'Atoms';

import './checkbox.scss';

/**
 * Creates a nice-looking checkbox
 *
 * @param { string } name - the name for the form. This and id are probably the
 * same.
 * @param { string } color - color of the checkbox
 * @param { string } id - the id for the form
 * @param { boolean } disabled - is it clickable? Modifiable?
 * @param { boolean } checked - is it checked? Default: false
 * @param { boolean } indeterminate - is it indeterminate? Default: false;
 * The indeterminate state is pretty rare; it's only used when a checkbox
 * depends on the state of "child" checkboxes. Unfortunately, this "state" can
 * only be set with JavaScript, which is why this class is so complicated
 * @param { string } label - The label to the right of the checkbox
 * @param {Function} onChange - the change event handler
 * @param {Boolean} title - set the title text attribute
 * @param {Boolean} readOnly - if the checkbox is "controlled," that is, if its
 * state is set by React (which is generally the case), this should be true.
 */
export default class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.id = props.id || uniqueId('checkbox_');
  }

  componentDidMount() {
    this.el.indeterminate = this.props.indeterminate;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.el.indeterminate = this.props.indeterminate;
    }
  }

  render() {
    const {
      name = this.id,
      disabled,
      indeterminate,
      checked,
      color,
      // checked = indeterminate ? checked : !!checked, // Fix uncontrolled input react warnings
      label,
      onChange = ()=>{},
      readOnly,
      children,
      className='',
      value,
      style,
      title=''
    } = this.props;

    const renderCheckmark = () => {
      if (indeterminate) {
        return <Icon name="checkbox-indeterminate" color={color} />;
      } else if (checked) {
        return <Icon name="checkbox-checkmark" color={color} />;
      } else {
        return null;
      }
    };

    return (
      <div className={`checkbox ${className}`} htmlFor={this.id} title={title} style={style}>
        <input
          type="checkbox"
          name={name || this.id}
          id={this.id}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          readOnly={readOnly}
          value={value}
          ref={(el) => { this.el = el; }}
        />
        <label htmlFor={this.id}>
          <span className="checkbox-icon">
            {renderCheckmark()}
          </span>
          <span className="checkbox-label">
            {label || children}
          </span>
        </label>
      </div>
    );
  }
}


import React, { useEffect, useState } from 'react';
import { SortableTable, TimeAgo } from 'Atoms';

import { getRateEngineContainer } from 'Actions/ccm/api.js';

const RateEngineContainer = () => {
  const [ rowData=[], setRowData ] = useState();

  useEffect(() => {
    getRateEngineContainer().then(resp => setRowData(resp));
  }, []);

  const COLUMN_DATA = [
    { key: 'name' },
    { key: 'processing' },
    { key: 'max_Processing_Age', header: 'Max Processing Age' },
    { 
      key: 'container_Created', 
      header: 'Created', 
      render: ({ container_Created }) => <TimeAgo date={container_Created} /> 
    },
  ];

  return (
    <div className="rate-engine-container">
      <SortableTable className="rate-engine-container__table" columnData={COLUMN_DATA} rowData={rowData} />
    </div>
  )
}

export default RateEngineContainer;
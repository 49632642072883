import React from 'react';
import * as esTooltip from 'antd/es/tooltip';

import 'antd/lib/tooltip/style/index.css';
import 'Sass/animate.scss';
import './Tooltip.scss';

const Tooltip = props => {
  const popupProps = {
    overlayClassName: "tooltip", //" animated fadeIn fast",
    arrowPointAtCenter: true,
    //onVisibleChange: viewable => viewable ? setVisible(true) : setHiding(true),
    ...props 
  }

  return props.title ? <esTooltip.default {...popupProps} /> : props.children;
};

export default Tooltip;
import React, { PureComponent, forwardRef } from 'react';
import { MODALS, getModal } from './Modals';

class ModalManager extends PureComponent {
  constructor() {
    super();

    this.state = {
      name: null,
      props: {}
    };

    this.open = this.open.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  open(name, props) {
    this.setState({ name, props });
  }

  //TODO: Handle animations
  onClose() {
    this.state.props.onClose && this.state.props.onClose();
    this.setState({ name: null, props: {} });
  }

  onConfirm() {
    this.state.props.onConfirm && this.state.props.onConfirm(...arguments);
    this.onClose();
  }

  render() {
    const { name, props } = this.state;
    const { onClose, onConfirm } = this;

    const Modal = getModal(name);

    if (name && !Modal) {
      console.warn(`Unrecognized modal name `, name);
    }

    return Modal ? <Modal {...props} onClose={onClose} onConfirm={onConfirm} /> : null;
  }
}

export default forwardRef((props, ref) => <ModalManager ref={ref} {...props} />);

export { MODALS as MODALS };
import React, { useState } from 'react';
import { Icon, LoadingIcon, Tooltip } from 'Atoms';
import { getQuoteRequirements } from 'Actions/ccm/api.js';
import { getBandwidthLabel, getServiceRequirements } from 'Utils/formatter.js';

import './QuoteTooltip.scss';

export const QuoteTooltip = props => {
  const { locationA, locationZ, serviceRequirements='', bandwidth, port, term, children, onOpenChange, mouseEnterDelay=.5 } = props;
  // LOOK FOR DATA CENTER URL

  const locationIcon = x => (!x ? null :
    (['pop', 'hub', 'colocation'].includes(x.location_type || x.locationType) || x.data_center_url) 
    ? 'data-center' : 'location');

  const locZ = !locationA ? null : locationZ ? { 
    icon: locationIcon(locationZ), label: locationZ.display_name || locationZ.string_address,
  } : serviceRequirements.indexOf('internet') > -1 ? {
    icon: 'internet', label: getServiceRequirements(serviceRequirements)
  } : {
    icon: 'least-cost', label: 'Least-cost'
  };

  const termLabel = !term ? '' : Array.isArray(term) ? term.sort().join(',') : term; 

  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
  }

  return <Tooltip mouseEnterDelay={mouseEnterDelay} title={(!locationA ? <LoadingIcon /> :
    <div className="quote-tooltip" onClick={onClick}>
      <div className="quote-tooltip__location"><Icon name={locationIcon(locationA)} />{locationA.display_name || locationA.string_address}</div>
      <div className="quote-tooltip__location"><Icon name={locZ.icon} />{locZ.label}</div>
      <div className="quote-tooltip__specification">
        <div className="flex-row"><Icon name="bandwidth" /> {getBandwidthLabel(bandwidth)}</div>
        <div className="flex-row"><Icon name="port" /> {getBandwidthLabel(port, true)}</div>
        <div className="flex-row"><Icon name="calendar" /> {termLabel}</div>
      </div>
    </div>
    )}
    overlayClassName='tooltip tooltip__quote-tooltip'
    onOpenChange={onOpenChange}
    children={children}
  /> 
}

export const AsyncQuoteTooltip = ({ id, children }) => {
  const [ quoteData, setQuoteData ] = useState();
  const [ isFetching, setIsFetching ] = useState(false);

  const onOpenChange = (visible) => {
    if (visible && !quoteData && !isFetching) {
      setIsFetching(true);
      getQuoteRequirements(id).then(resp => {
        setQuoteData(resp);
        setIsFetching(false);
      });
    }
  };

  return <QuoteTooltip {...quoteData} onOpenChange={onOpenChange} children={children} />
}
import { GET, UPLOAD, DOWNLOAD } from '../service.js';

export const getRateTests = (request) => {
    return GET(`/analysis/api/rateTest/`, request);
  }

export const getRateTest = (rateTestId, request) => {
    return GET(`/analysis/api/rateTest/${rateTestId}/`, request);
  }

export const importRateTest = ({environment, file}) => {

    const formData = new FormData();
    formData.append('environment', environment);
    formData.append('file', file, file.name);
  
    return UPLOAD('/analysis/api/rateTest/import', formData);
  }

  export const exportRateTest = ({fileName, folderId}) => {

    const formData = new FormData();
    formData.append('fileName', fileName);
    formData.append('folderId', folderId);
  
    return DOWNLOAD('/analysis/api/rateTest/exportfolder', fileName, formData);
  }

  export const getCcmEnvironments = (request) => {
    return GET(`/analysis/api/ccmenvironment/`, request);
  }
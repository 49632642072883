import groupBy from 'lodash/groupBy';
import pickBy from 'lodash/pickBy';
import { getInternetServiceRequirements, toIpv4Option } from 'Utils/formatter.js';
import { DIVERSITY_OPTIONS } from 'Molecules/Lookups';

export const getDefaultOptions = (reqs) => {
  if (!reqs) return {};

  const { id, name, autoName, reference, serviceRequirements,
    popSelectionOrganizationKey, customerName, folderId, locationA, locationZ, 
    bandwidth, port, term, crossConnectA, crossConnectZ, excludedCarriers,
    carrierDiverse, routeDiverse, routeDiverseSameCarrier,
    currency, priceAsOrganizationKey, orgId,
    QinQ, jumboFraming, serviceClass, sdWan, ipv4,
    kmz, routeProtection, unitasIpEndUserRate,
  } = reqs;

  const INTERNET_OPTIONS = [
    { value: 'internet', label: 'Internet' },
    { value: 'internet-broadband', label: 'Internet - Broadband' }, 
    { value: 'internet-dedicated-access', label: 'Internet - DIA' },
    { value: 'internet-unitas-ip', label: 'Internet - Unitas Global IP' },
  ];

  const internetOption = INTERNET_OPTIONS.find(x => x.value === serviceRequirements);
  const leastCostOption = { label: "Least-cost data center/POP location", mode: "least-cost", value: "leastCost" };

  const toTermOption = (terms=[]) => ([].concat(terms)).map(term => ({ label: term+'', optionType: 'term', sortVal: term, value: term, valueType: 'list', constraints: {} }));
  const toLocationOption = (location) => location ? ({ label: (location.display_name || location.string_address), ...location }) : null;
  const toLocationZOption = () => (
    (locationZ === 'Least Cost') 
      ? leastCostOption :
    (internetOption) 
      ? internetOption :
    (locationZ) 
      ? toLocationOption(locationZ) :
    (popSelectionOrganizationKey)
      ? { popSelectionOrganizationKey } 
      : leastCostOption
  );

  const toPricingOptions = () => {
    const { partnerType, partnerId, partnerUrl, partnerName, salesRepId, salesRepEmail, salesRepName, csaId, csaEmail, csaName, partnerCommission } = reqs || {};
    const commission = (
      partnerType === 'enterprise' ? { mrr_partner_commission_enterprise: partnerCommission } :
      partnerType === 'wholesale' ? { mrr_partner_commission_wholesale: partnerCommission } : {}
    );

    return (!partnerType) ? {} 
      : { pricing: {
        partnerType,
        partner: { label: partnerName, value: partnerUrl, url: partnerUrl, id: partnerId, ...commission },
        csaRep: csaId ? { label: csaName, value: csaId } : undefined,
        salesRep: salesRepId ? {label: salesRepName, value: salesRepId } : undefined,
      }
    };
  }

  const diversityValue = (
    carrierDiverse ? 'carrier-diverse' :
    routeDiverse ? 'route-diverse' :
    routeDiverseSameCarrier ? 'route-diverse-same-carrier' : ''
  );

  // Map values, remove falsey values
  const defaultValues = pickBy({
    id,
    reference,
    autoName,
    name,
    customer: customerName,
    folder: folderId && { value: folderId },
    locationA: locationA && toLocationOption(locationA),
    locationZ: toLocationZOption(),
    locationZType: internetOption ? 'internet' : undefined,
    priceAsOrganizationKey,
    popSelectionOrganizationKey,
    bandwidth: bandwidth && { value: bandwidth },
    port: port && { value: port },
    term: term && toTermOption(term),
    ipv4: ipv4 && toIpv4Option({ value: ipv4 }),
    crossConnectA,
    crossConnectZ,
    excludedCarriers: (excludedCarriers || []).map(value => ({ value })),
    currency,
    QinQ,
    jumboFraming,
    serviceClass: serviceClass && { value: serviceClass },
    sdWan,
    layerOne: serviceRequirements === 'layer-1-wave',
    kmz,
    diversity: DIVERSITY_OPTIONS.find(({ value }) => value === diversityValue),
    carrierDiverse,
    routeDiverseSameCarrier,
    routeDiverse: routeDiverse || routeDiverseSameCarrier,
    routeProtection,
    unitasIpEndUserRate,
    ...toPricingOptions(),
  });

  return defaultValues;
}

export const toSortedQuoteOptions = options => {
  const isValidOption = ({ value }) => (value !== 'none');
  const bySortVal = (a, b) => ((a.sortVal < b.sortVal) ? -1 : (a.sortVal > b.sortVal) ? 1 : 0);

  return groupBy(options.filter(isValidOption).sort(bySortVal), 'optionType');
}

export const getPermissions = options => {
  const { advanced=[], service_requirements=[], class_of_service } = options;
  const serviceClass = Object.keys(class_of_service).length > 1;

  return [...advanced, ...service_requirements].reduce((acc, item) => { acc[item.value] = true; return acc; }, { serviceClass });
}

export const getInternetOptions = (options={}) => {
  return (options.service_requirements || [])
    .filter(x => !!getInternetServiceRequirements(x.value))
    .sort((a, b) => a.label < b.label ? -1 : 1);
}

// export const getValidationErrors = ( data={}, errors={} ) => {
//   return {
//     locationA: !errors.locationA ? undefined : data.locationA 
//       ? `Unrecognized address: ${data.locationA}` 
//       : 'Please select a "From" location.',
//     locationZ: (!errors.locationZ && !errors.popSelectionOrganizationKey) ? undefined : 
//       (data.locationZ)
//         ? `Unrecognized address: ${data.locationZ}` : 
//       (errors.popSelectionOrganizationKey)
//         ? `Unrecognized least-cost pop organization: ${data.popSelectionOrganizationKey}`
//         : 'Please select a "To" location.',
//     bandwidth: !errors.bandwidth ? undefined : data.bandwidth 
//       ? `Invalid bandwidth speed: ${data.bandwidth}` 
//       : 'Please select a bandwidth speed.',
//     port: !errors.port ? undefined : data.port 
//       ? `Invalid port speed: ${data.port}` 
//       : 'Please select a port speed.',
//     term: !errors.term ? undefined : data.term 
//       ? `Invalid monthly term: ${data.term}` 
//       : 'Please select a monthly term.',
//   };
// }
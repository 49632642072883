import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'Atoms';
import { generateTestToken } from 'Actions/ccm/api.js';

import './JwtLoginForm.scss';

const submit = (data) => {
  generateTestToken(data).then(({ token }) => {
    if (data.tokenType === 'equinix-fabric') {
      window.location.href = `/ccm/redirect/?eqix_jwt=${encodeURIComponent(token)}`;
    } else {
      let form = document.createElement("FORM");
      form.method = "POST";
      form.action = "/ccm/redirect/";
      form.style.display = "none";
      document.body.appendChild(form);
      let input = document.createElement("INPUT");
      input.type = "hidden";
      input.name = "token";
      input.value = token;
      form.appendChild(input);
      form.submit();
    }
  });
}

const JwtLoginForm = (props) => {
  const { tokenType } = props;
  const { watch, handleSubmit, register } = useForm();
  const { firstName, lastName, email } = watch(['firstName', 'lastName', 'email']);
  const disabled = !firstName || !lastName || !email;

  return (
    <form className="jwt-form" onSubmit={handleSubmit((data) => submit({tokenType: tokenType, ...data}))}>
      <div className="form-title">Generate Test Token</div>
      <div className="form-description">Welcome to the Test Token Generation page. Please sign in to continue.</div>

      <label>
        <div>First name</div>
        <input type="text" placeholder="Enter First name" name="firstName" ref={register} />
      </label>
      <label>
        <div>Last name</div>
        <input type="text" placeholder="Enter Last name" name="lastName" ref={register} />
      </label>
      <label>
        <div>Email</div>
        <input type="text" placeholder="Enter Email" name="email" ref={register} />
      </label>

      <div className="jwt-poc-submit-container">
        <Button buttonType="submit" disabled={disabled} children="Generate Token" />
      </div>
    </form>
  )
}

export default JwtLoginForm;

import React, { useCallback } from 'react';
import CN from 'classnames';
import { useUserContext } from 'Context/UserContext.js';

import { Checkbox } from 'Atoms';
import { Lookup } from 'Molecules';
import { useQuoteManagerFilter } from './useQuoteManagerFilter.js';
import { PERMISSIONS } from 'Utils/const.js';

import './QuoteManagerFilter.scss';

const QuoteManagerFilter = (props => {
  const { className='', getFolderName, onChangeFilter } = props;

  const user = useUserContext();
  const isInternal = !!user.is_internal;
  const showUserFilter = isInternal || user.hasPermission(`${PERMISSIONS.VIEW_ORG_USERS}=${user.organization_id}`);

  const { values, options, isFetching, onChange, showAllOrgUsers } = useQuoteManagerFilter({ onChangeFilter, getFolderName });

  const classNames = {
    component: `quote-manager-filter ${className}`,
    user: CN({ 'hidden': !showUserFilter}),
    checkbox: 'quote-manager-filter__all-org-users-checkbox',
  };

  const lookupProps = type => ({
    name: `${type}-qm-filter`,
    className: classNames?.[type],
    options: options?.[type], 
    value: values?.[type], 
    onChange: onChange?.[type], 
    isClearable: true, 
    isLoading: isFetching,
  });

  const formatRegionOptionLabel = useCallback(({ label, flag }) => {
    const className = flag ? `em-flag em-flag-${flag}` : '';

    return <div className={className}>{label}</div>
  }, []);


  return (
    <div className={classNames.component}>
      {!!isInternal && (
        <Lookup label="Organization" icon="customer" {...lookupProps('org')} />
      )}
      <div>
        {!!showUserFilter && (
          <Lookup isDisabled={!!showAllOrgUsers} label="User" icon="user" {...lookupProps('user')} />
        )}
        {!isInternal && showUserFilter && (
          <Checkbox 
            className={classNames.checkbox}
            label={`Show all ${user.organization_name} quotes`}
            onChange={onChange.showAllUsers} 
            checked={!!showAllOrgUsers} 
          />
        )}
      </div>
      <Lookup label="Region" icon="flag" formatOptionLabel={formatRegionOptionLabel} {...lookupProps('region')} />
      <Lookup label="Service Type" icon="settings-filled" {...lookupProps('service')} />      
      <Lookup label="Status" icon="checkmark-circle-filled" isMulti {...lookupProps('status')} />
    </div>
  )
});

export default QuoteManagerFilter;

import React, { Component, Fragment, createRef } from 'react';
import { LoadingOverlay } from 'Atoms';
import { Popup } from 'Molecules';
import { CreateQuote } from 'Widgets';
import { getQuoteError, deleteQuoteError } from 'Actions/ccm/api.js';

import './ImportErrorModal.scss';

class ImportErrorModal extends Component {
  constructor() {
    super();

    this.state = {
      isFetching: true,
      defaultValues: {},
      validationErrors: {},
      showPartnerData: false,
    }

    this.createQuoteRef = createRef();
  }

  componentDidMount() {
    getQuoteError(this.props.id).then(({ validationErrors, defaultValues, showPartnerData }) => {
      this.setState({ isFetching: false, validationErrors, defaultValues, showPartnerData })
    });
  }

  render() {
    const { defaultValues, validationErrors, isFetching, showPartnerData } = this.state;
    const { onClose, onConfirm, name, id } = this.props;
    const onClick = () => this.createQuoteRef.current.submit();

    const onSubmit = () => {
      deleteQuoteError(id).then(onConfirm);
    };

    const yesButton = { label: 'Update', onClick, primary: true };
    const noButton = { label: 'Cancel', onClick: onClose };

    return (
      <Popup
        label={`Resolve errors: ${name}`}
        onClose={onClose}
        button1={noButton}
        button2={yesButton}
        id="import-error-popup"
      >
        {!!isFetching && (
          <Fragment>
            <LoadingOverlay children="Retrieving values" />
            <CreateQuote noFetch />
          </Fragment>
        )}
        {!isFetching && <CreateQuote ref={this.createQuoteRef} onSubmit={onSubmit} defaultValues={defaultValues} validationErrors={validationErrors} showPartnerData={showPartnerData} allowUpdateEmail /> }
      </Popup>
    );
  }
}

export default ImportErrorModal;

import { useMemo, useState } from 'react';

const getQuoteOptions = (type) => {
  const QUOTE_OPTIONS = {
    service: [
      { title: 'Ethernet', key: 'ethernet' },
      { title: 'Internet', key: 'internet', children: [
        { title: 'Internet - Broadband', key: 'internet-broadband' },
        { title: 'Internet - DIA', key: 'internet-dedicated-access' },
        { title: 'Internet - Unitas Global IP', key: 'internet-unitas-ip' },
      ]},
      { title: 'Layer 1', key: 'layer-1-wave' },
    ],

    advanced: [
      //{ title: 'SD-WAN', key: 'sd-wan' },
      { title: 'Class Of Service Level', key: 'cos' },
      { title: 'Cross-Connect at source', key: 'sourcecrossconnect' },
      { title: 'Cross-Connect at destination', key: 'destcrossconnect' },
      { title: 'Q-in-Q', key: 'q-in-q' },
      { title: 'Jumbo Framing', key: 'jumbo-framing' },
      { title: 'Diversity', key: 'diversity' },
      { title: 'KMZ File', key: 'kmz-file' },
      { title: 'Route Protection', key: 'route-protection' },
      { title: 'Allow extended demarc requests', key: 'end-user-extended-demarc' },      
    ],

    port: [
      { title: 'Least Cost', key: 'least-cost' },
      { title: 'FE/100 Mbps', key: 'fe100-mb', mbps: 100 },
      { title: 'GigE', key: 'gige', mbps: 1000 },
      { title: '10 GigE', key: '10-gb', mbps: 10000 },
      { title: '100 GigE', key: '100-gb', mbps: 100000 },
    ],

    bandwidth: [
      { title: '< 100 Mbps', key: 'lt-100mbps', excluded: true, children: [
        { title: 'DS1', key: 'ds1', mbps: 1.544 },
        { title: '3 Mbps', key: '3-mbps', mbps: 3 },
        { title: '5 Mbps', key: '5-mbps', mbps: 5 },
        { title: '10 Mbps', key: '10-mbps', mbps: 10 },
        { title: '15 Mbps', key: '15-mbps', mbps: 15 },
        { title: '20 Mbps', key: '20-mbps', mbps: 20 },
        { title: '30 Mbps', key: '30-mbps', mbps: 30 },
        { title: '40 Mbps', key: '40-mbps', mbps: 40 },
        { title: '50 Mbps', key: '50-mbps', mbps: 50 },
        { title: '60 Mbps', key: '60-mbps', mbps: 60 },
        { title: '70 Mbps', key: '70-mbps', mbps: 70 },
        { title: '80 Mbps', key: '80-mbps', mbps: 80 },
        { title: '90 Mbps', key: '90-mbps', mbps: 90 }
      ]},
      { title: '100 Mbps', key: '100-mbps', mbps: 100 },
      { title: '120-900 Mbps', key: '120-400-mbps', excluded: true, children: [
        { title: '120 Mbps', key: '120-mbps', mbps: 120 },
        { title: '150 Mbps', key: '150-mbps', mbps: 150 },
        { title: '200 Mbps', key: '200-mbps', mbps: 200 },
        { title: '250 Mbps', key: '250-mbps', mbps: 250 },
        { title: '300 Mbps', key: '300-mbps', mbps: 300 },
        { title: '400 Mbps', key: '400-mbps', mbps: 400 },
        { title: '500 Mbps', key: '500-mbps', mbps: 500 },
        { title: '600 Mbps', key: '600-mbps', mbps: 600 },
        { title: '700 Mbps', key: '700-mbps', mbps: 700 },
        { title: '800 Mbps', key: '800-mbps', mbps: 800 },
        { title: '900 Mbps', key: '900-mbps', mbps: 900 },
      ]},
      { title: '1 Gbps', key: 'gige', mbps: 1000 },
      { title: '2-9 Gbps', key: '2-9-gbps', excluded: true, children: [
        { title: '2 Gbps', key: '2-gbps', mbps: 2000 },
        { title: '2.5 Gbps', key: '25-gbps', mbps: 2500 },
        { title: '3 Gbps', key: '3-gbps', mbps: 3000 },
        { title: '4 Gbps', key: '4-gbps', mbps: 4000 },
        { title: '5 Gbps', key: '5-gbps', mbps: 5000 },
        { title: '6 Gbps', key: '6-gbps', mbps: 6000 },
        { title: '7 Gbps', key: '7-gbps', mbps: 7000 },
        { title: '8 Gbps', key: '8-gbps', mbps: 8000 },
        { title: '9 Gbps', key: '9-gbps', mbps: 9000 },
      ]},
      { title: '10 Gbps', key: '10-gbps', mbps: 10000 },
      { title: '20-90 Gbps', key: '20-90-gbps', excluded: true, children: [
        { title: '20 Gbps', key: '20-gbps', mbps: 2000 },
        { title: '30 Gbps', key: '30-gbps', mbps: 3000 },
        { title: '40 Gbps', key: '40-gbps', mbps: 4000 },
        { title: '50 Gbps', key: '50-gbps', mbps: 5000 },
        { title: '60 Gbps', key: '60-gbps', mbps: 6000 },
        { title: '70 Gbps', key: '70-gbps', mbps: 7000 },
        { title: '80 Gbps', key: '80-gbps', mbps: 8000 },
        { title: '90 Gbps', key: '90-gbps', mbps: 9000 },
      ]},
      { title: '100 Gbps', key: '100-gbps', mbps: 100000 }
    ]
  };

  return QUOTE_OPTIONS[type] || {};
}

const DIVERSITY_VALUES = ['carrier-diverse', 'route-diverse-same-carrier', 'route-diverse'];

const getLeafs = (tree) => {
  let leafs = [];

  tree.forEach(({ excluded, children, ...branch }) => {
    !excluded && leafs.push(branch);
    children && children.forEach(grandChild => leafs.push(grandChild))
  });

  return leafs;
}

const getTreeData = (tree, hidden) => {
  const disableHiddenLeafs = leaf => {
    leaf.disabled = hidden.includes(leaf.key);
    return leaf;
  };

  return tree.map(branch => (branch && !branch.children)
    ? disableHiddenLeafs(branch)
    : { ...branch, children: getTreeData(branch.children, hidden) }
  )
}

const getKeys = (tree) => getLeafs(tree).map(x => x.key);

const getDefaultSelections = (data, defaultConstraints={}) => {
  const { whitelisted_selections, blacklisted_selections=[], hidden_selections=[] } = defaultConstraints;

  const exclusive = (whitelisted_selections || getKeys(data));
  const excluded = blacklisted_selections;
  
  return {
    checked: exclusive.filter(key => !excluded.includes(key)),
    hidden: hidden_selections,
  }
};

export const useQuoteOptions = (type) => {
  const [ checkedKeys, setCheckedKeys ] = useState([]);
  const [ hiddenKeys, setHiddenKeys ] = useState([]);
 
  // Service tree returns object { checked: [], half-checked:[] }, only interested in checked
  const getChecked = () => (checkedKeys.checked || checkedKeys);

  const data = getQuoteOptions(type);

  const treeProps = useMemo(() => ({ 
    data: getTreeData(data, hiddenKeys),
    checkedKeys, 
    onCheck: checked => setCheckedKeys(checked), 
    selectable: false 
  }), [ checkedKeys, hiddenKeys, data ]);

  const setConstraints = (constraints) => {
    const { checked, hidden } = getDefaultSelections(data, constraints);
    setCheckedKeys(checked);
    setHiddenKeys(hidden);
  };

  const getSelections = () => {
    const checked = getChecked();
    const whitelist = getKeys(data).filter(key => checked.includes(key));
    return { 
      whitelisted_selections: whitelist,
      hidden_selections: hiddenKeys.length ? hiddenKeys : undefined,
    };
  };

  const leafs = getLeafs(data);

  const formatter = (type === 'service') ? serviceSelections : countedSelections;
    //.filter(x => (checkedKeys.checked || checkedKeys).includes(x.key))

  return {
    leafs, 
    checkedKeys, 
    setCheckedKeys, 
    setConstraints, 
    getSelections, 
    treeProps,
    selected: formatter(leafs, getChecked())
  };
}

export const mergeAdvanced = (advanced, classOfService={}) => {
  const advSet = new Set(advanced?.whitelisted_selections);

  const hasDiversity = DIVERSITY_VALUES.some(x => advSet.has(x));
  if (hasDiversity) {
    DIVERSITY_VALUES.forEach(x => advSet.delete(x));
    advSet.add('diversity');
  }

  // If there is a class of service value whitelisted, then it is restricted and uncheck.
  const cosRestricted = classOfService?.whitelisted_selections?.length;
  if (!cosRestricted) {
    advSet.add('cos');
  }

  return { ...advanced, whitelisted_selections: [...advSet] };
};

// pass in advanced.checked
export const unmergeAdvanced = (advancedCheckedKeys) => {
  const advSet = new Set(advancedCheckedKeys);

  const hasCos = advSet.has('cos');
  const sdWanHidden = !advSet.has('sd-wan');
  const hasDiversity = advSet.has('diversity');

  if (hasDiversity) {
    advSet.delete('diversity');
    DIVERSITY_VALUES.forEach(x => advSet.add(x));
  }

  // SD-WAN
  // 1. If sd-wan is not checked, then add it to both whitelist and hiddenlist
  sdWanHidden && advSet.add('sd-wan');
  hasCos && advSet.delete('cos');

  return {
    advanced: {
      whitelisted_selections: [...advSet],
      hidden_selections: sdWanHidden ? ['sd-wan'] : undefined,
    },
    class_of_service: hasCos
      ? undefined
      : { whitelisted_selections: ['best-effort'] },
  };
};

export const mergeConstraints = (constraints=[]) => {
  const listTypes = ['whitelisted_selections', 'blacklisted_selections', 'hidden_selections'];
  let merged = {};
  constraints.filter(Boolean).forEach((constraint) => {
    Object.entries(constraint).forEach(([listType, values]) => {
      if (listTypes.includes(listType)) {
        merged[listType] = [...(merged[listType] || []), ...values];
      }
    })
  });
  return merged;
};

// export const unmergeProducts = ({ checked=[] }) => {
//   const options = getQuoteOptions('service');
//   let services=[], products=[];

//   options.forEach(({ key, children=[] }) => {
//     const checkedProducts = children.map(x => x.key).filter(x => checked.includes(x))
//     if (checked.includes(key) || checkedProducts.length) {
//       services.push(key);
//       products = [...products, ...checkedProducts];
//     }
//   });

//   return { 
//     service_requirements: { whitelisted_selections: services },
//     product_type: { whitelisted_selections: products },
//   };
// }

const serviceSelections = (leafs=[], checked=[]) => {
  const INTERNET_LABELS = {
    'internet': 'Any',
    'internet-broadband': 'Broadband',
    'internet-dedicated-access': 'DIA',
    'internet-unitas-ip': 'Unitas IP',
  };

  const getInternetLabel = arr => (
    (!arr.length) ? '' : `Internet (${arr.map(x=> INTERNET_LABELS[x.key]).join(', ')})`
  );

  const internets = leafs.filter(({ key }) => INTERNET_LABELS[key] && checked.includes(key));
  
  return [ 
    checked.includes('ethernet') && 'Ethernet',
    getInternetLabel(internets),
    checked.includes('layer-1-wave') && 'Layer 1',
  ].filter(x=>!!x).join(', ');
}

const countedSelections = (leafs=[], checked=[]) => {
  // need to compare against leafs to ensure parents aren't included
  const numerator = leafs.filter(leaf => checked.includes(leaf.key)).length;
  return `(${numerator} of ${leafs.length} selected)`
}

import React, { Component } from 'react';
import style from './status_dot.scss';

import { Icon } from 'Atoms';

export default class StatusDot extends Component {

  translateStatus(status) {
    switch (status.toLowerCase()) {
      case 'online':
        return 'blue';
      case 'active':
      case 'exceptions approved':
        return 'green';
      case 'suspended':
      case 'exceptions pending':
        return 'yellow';
      case 'error':
        return 'red';
      default:
        return 'gray';
    }
  }
  
  render() {
    const statusColor = this.translateStatus(this.props.status.toLowerCase())
    return <Icon name='dot' className={`status-dot ${statusColor}`} role='decorative'/>
  }
}

import React, { useState, useEffect, useRef } from 'react';
import CN from 'classnames';
import { Button, Checkbox, Icon, LoadingOverlay, SortableTable, Tree } from 'Atoms';
import { CarrierLookup } from 'Molecules';

import { useToggle } from 'Hooks';
import { updateOrganization } from 'Actions/ccm/api.js';
import { useQuoteOptions, mergeAdvanced, mergeConstraints, unmergeAdvanced } from './useQuoteOptions.js';

import './QuotingOptions.scss';

const QuotingOptions = ({ organizationId, constraints={}, onSave }) => {
  const [ multiBandwidth, setMultiBandwidth ] = useState(constraints.may_request_multiple_bandwidths);
  const [ excludedCarriers, setExcludedCarriers ] = useState(constraints.excluded_carriers || []);
  const [ isSaving, setIsSaving ] = useState(false);

  const serviceTreeData = useQuoteOptions('service');
  const advancedTreeData = useQuoteOptions('advanced');
  const portTreeData = useQuoteOptions('port');
  const bandwidthTreeData = useQuoteOptions('bandwidth');

  // TODO: integrate into useQuoteOptions hooks?
  const descriptions = useRef({
    service: 'Select which type of service should be available when pricing quotes in the system.',
    speeds: 'Customize the port speed and bandwidth options available.',
    advanced: 'Choose which advanced quoting options to turn on or off for users of this organization, and set defaults for each.',
    excluded: 'Choose which carriers will not be allowed to price for all quoting activity for this organization.',
  }).current;
  
  useEffect(() => {
    const { advanced, class_of_service, service_requirements, product_type, port_bandwidth, bandwidth, may_request_multiple_bandwidths, excluded_carriers } = constraints;
    const advancedConstraints = mergeAdvanced(advanced, class_of_service);
    const serviceConstraints = mergeConstraints([ service_requirements, product_type ]);

    serviceTreeData.setConstraints(serviceConstraints);
    portTreeData.setConstraints(port_bandwidth);
    bandwidthTreeData.setConstraints(bandwidth);
    advancedTreeData.setConstraints(advancedConstraints);
    setMultiBandwidth(may_request_multiple_bandwidths);
    setExcludedCarriers(excluded_carriers);

  }, [organizationId, constraints]);

  const onChangeMultiBandwidth = () => {
    save({ may_request_multiple_bandwidths: !multiBandwidth });
    setMultiBandwidth(!multiBandwidth);
  }

  const onChangeExcludedCarriers = opt => {
    !!opt && !Array.isArray(opt) && setExcludedCarriers([...excludedCarriers, opt ]);
  }

  const removeExcludedCarrier = opt => {
    setExcludedCarriers(excludedCarriers.filter(x => x.value !== opt.value));
  }

  const onClickSave = () => {
    const { advanced, class_of_service } = unmergeAdvanced([...advancedTreeData.checkedKeys]);
    
    const { may_request_multiple_bandwidths, excluded_carriers, ...otherConstraints } = constraints;
    const option_constraint = {
      ...otherConstraints,
      service_requirements: serviceTreeData.getSelections(), 
      bandwidth: bandwidthTreeData.getSelections(), 
      port_bandwidth: portTreeData.getSelections(),
      advanced,
      class_of_service,
    };

    const settings = {
      excluded_carriers: excludedCarriers.map(x => x.value)
    };

    save({ option_constraint, settings });
  };

  const save = (req) => {
    setIsSaving(true);
    updateOrganization(organizationId, req).then(resp => {
      const message = resp.error ? `Could not save. ${Object.values(resp.error).join('. ')}` : 'Settings saved.';
      alert(message);
      
      !resp.error && onSave(resp);
    }).finally(() => setIsSaving(false))
  };

  const excludedToggleText = excludedCarriers && excludedCarriers.length ? excludedCarriers.map(x => x.label).join(', ') : 'None';
  const filterOption = ({ value, label }, input) => !excludedCarriers.find(x => x.value === value) && (!input || label.toLowerCase().includes(input.toLowerCase()))

  return (
    <div className="admin-quoting-options">
      {!!isSaving && <LoadingOverlay /> }
      <div className="quote-options-groups">
        <QuoteOptionsGroup header="Service Types" description={descriptions.service}>
          <TreeToggler label="Service Types" toggleText={serviceTreeData.selected} treeProps={serviceTreeData.treeProps} defaultExpandedKeys={['internet']} checkStrictly={true} />
        </QuoteOptionsGroup>

        <QuoteOptionsGroup header="Speeds" description={descriptions.speeds}>
          <TreeToggler label="Port Speeds" treeProps={portTreeData.treeProps} toggleText={portTreeData.selected}/>
          <TreeToggler label="Bandwidth Speeds" treeProps={bandwidthTreeData.treeProps} toggleText={bandwidthTreeData.selected} />

          <div className="quote-options-groups-subheader" style={{ marginBottom: '.25rem' }}>Multiple Bandwidth Selection</div>

          <Checkbox label={"Allow organization to select multiple bandwidths when quoting"} onChange={onChangeMultiBandwidth} checked={multiBandwidth} disabled={isSaving} />
        </QuoteOptionsGroup>
        
        <QuoteOptionsGroup header="Advanced Options" description={descriptions.advanced}>
          <TreeToggler label="Advanced Options" toggleText={advancedTreeData.selected} treeProps={advancedTreeData.treeProps} />
        </QuoteOptionsGroup>
                
        <QuoteOptionsGroup header="Exclude Carriers from Pricing" description={descriptions.excluded}>
          <TreeToggler label="Excluded Carriers" toggleText={excludedToggleText}>
            <div className="admin-quoting-options__excluded-carrier-header">
              <span className="admin-quoting-options__excluded-carrier-header-text">
                {excludedCarriers.length 
                  ? 'The following carriers will be excluded from pricing:' 
                  : 'None selected. All carriers available for pricing.'
              }</span>
              <CarrierLookup 
                className="admin-quoting-options__excluded-carrier-lookup"
                isMulti={false} 
                controlShouldRenderValue={false} 
                onChange={onChangeExcludedCarriers}
                filterOption={filterOption}
                blurInputOnSelect={true}
                label="Add Excluded Carrier"
                icon="customer"
              />
            </div>
            
            {!!excludedCarriers && !!excludedCarriers.length && (
              <ExcludedCarrierTable removeItem={removeExcludedCarrier} items={[...excludedCarriers]} />
            )}
          </TreeToggler>
          <br />
          <br />
          <br />
        </QuoteOptionsGroup>
      </div>
    
      <div className="quote-options-save-container">
        <Button onClick={onClickSave} disabled={isSaving}>Save</Button>
      </div>
    </div>
  )
}

const QuoteOptionsGroup = ({ header, description, children }) => {
  return (
    <div className="quote-options__group">
      <div className="quote-options__group-description">
        <h4>{header}</h4>
        <div>{description}</div>
      </div>
      <div className="quote-options__group-data" children={children} />
    </div>
  )
}

const TreeToggler = ({ treeProps, label, toggleText='', children, ...rest}) => {
  const [ collapsed, toggle ] = useToggle(true);

  const toggler = CN('toggler button-link', { 'toggler--collapse': !collapsed });
  const toggled = CN(`toggleable`, { 'toggleable--collapsed': collapsed });
  
  return (
    <>
      <div className="quote-options-groups-subheader">
        {label}
        <Button onClick={toggle} className={toggler}>
          {toggleText}<Icon name="caret-down" />
        </Button>
      </div>
      <div className={toggled}>
        {!!treeProps && <Tree { ...treeProps } { ...rest } /> }
        { children }
      </div>
    </>
  )
}

const ExcludedCarrierTable = ({ items, removeItem }) => {
  const columnData = ([
    { key: 'icon', header: ' ', sortable: false, render: () => <Icon name="customer" /> },
    { key: 'label', header: 'Name' },
    { key: 'remove', header: ' ', sortable: false, 
      render: x => <Button icon="subtract-circle-filled" type="inline" onClick={() => removeItem(x)} /> 
    },
  ]);

  return (
    <SortableTable className="sortable-table__excluded-carriers" columnData={columnData} rowData={items} />
  )
}

export default QuotingOptions;

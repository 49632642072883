import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useUserContext } from 'Context/UserContext.js';
import { useFilter } from 'Hooks';

import { Lookup } from 'Molecules';
import { getQuoteFilterOptions, getDesignFilterOptions } from 'Actions/ccm/api.js';
import { PRICING_DESK_TABS } from './PricingDeskHelper.js';
import { getStateCode } from 'Utils/formatter.js';
import { getAlpha2 } from 'Utils/countries.js';

import './PricingDeskFilter.scss';

const PricingDeskFilter = props => {
  const { className='', onChangeFilter, defaultFilter={}, tab } = props;

  // hooks
  const user = useUserContext();
  const isInternal = !!user.is_internal;

  // useState
  const [ options, setOptions ] = useState({});
  const { values={}, updateFilter } = useFilter({ onChangeFilter });

  // useRef
  const staticOptions = useRef({
    unapproved: [
      { label: 'Pricing Desk', value: 'pricing_desk' },
      { label: 'Network Engineer', value: 'network_engineering' },
      { label: 'CSA', value: 'csa' },
    ]
  });

  const formatRegionOptionLabel = useCallback(({ label, flag }) => {
    const className = flag ? `em-flag em-flag-${flag}` : '';

    return <div className={className}>{label}</div>
  }, []);

  const getDefaultValues = useCallback((opts) => {
    const defaultValues = { tab };

    Object.entries(defaultFilter).forEach(([ key, val ]) => {
      defaultValues[key] = opts[key]?.find(x => x.value === val)
    });

    return defaultValues;
  }, [ tab, defaultFilter ]);


  // TODO: move to helper
  useEffect(() => {
    const fetchOptions = async () => {
      const mergeArrays = (arr1=[], arr2=[]) => {
        const merged = [ ...arr1, ...arr2 ];
        return merged.filter((x, i) => i === merged.findLastIndex(y => y?.name === x?.name));
      };

      const toOption = ({ id, name }) => ({ label: name, value: (id !== undefined ? id : name).toString() });
      const toCountryOption = ({ id, name }) => ({ 
        label: name, 
        value: id, 
        flag: getAlpha2(id).toLowerCase(),
      });
      const toStateOption = ({ name }) => ({ label: name, value: getStateCode(name) })

      const byName = (a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;

      const [ quoteResp, orderResp ] = await Promise.all([
        getQuoteFilterOptions({ status__in: 'manual,error,review' }),
        getDesignFilterOptions({ design_status: 'design-validation' }),
      ].filter(Boolean))

      const { assignee, follow_up_category } = quoteResp;

      const organization = mergeArrays(quoteResp.organization, orderResp.organization);
      const state = mergeArrays(quoteResp.state, orderResp.state);
      const country = mergeArrays(quoteResp.country, orderResp.country);
      const service_type = mergeArrays(quoteResp.service_type, orderResp.service_type);
      const location_type = mergeArrays(quoteResp.location_type, orderResp.location_type);

      const newOptions = {
        assignee: assignee?.sort(byName).map(({ name, email }) => ({ 
          label: name, 
          value: email || 'null', // Unassigned
        })),
        followup: follow_up_category?.map(({ name }) => ({
          label: name, 
          value: (name === 'No followup' ? 'null' : name),
        })),
        region: [
          ...country.filter(x => x.id === 'USA').map(toCountryOption),
          ...state.map(toStateOption),
          ...country.filter(x => x.id !== 'USA').sort(byName).map(toCountryOption),
          ...location_type.filter(x => x.id === 'unvalidated')
            .map(x => ({ ...toOption(x), icon: 'question-mark' })),
        ],
        org: organization?.sort(byName).map(toOption),
        service: service_type.map(toOption).filter(x => x.value !== 'all'),
        unapproved: staticOptions.current.unapproved,
      }

      setOptions(newOptions);

      const newFilter = getDefaultValues(newOptions);
      updateFilter(newFilter);
    }

    fetchOptions();
  }, [])


  const onChange = useCallback((type) => (
    opt => updateFilter({ [type]: opt, tab })
  ), [updateFilter, tab])

  const FilterLookup = ({ type, icon, label, formatOptionLabel }) => {

    return !type ? null : <Lookup name={`${type}-filter`}
      icon={icon}
      label={label}
      value={values[type]}
      onChange={onChange(type)}
      options={options[type]}
      formatOptionLabel={formatOptionLabel}
      isLoading={!Object.keys(options).length}
      isClearable />
  }

  return (
    <div className={`pricing-desk-filter ${className}` }>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '.5rem' }}>
        {!!isInternal && (
          <FilterLookup type="org" icon="customer" label="Organization" />
        )}
        <FilterLookup type="region" label="Region" icon="flag" formatOptionLabel={formatRegionOptionLabel} /> 
        <FilterLookup type="service" label="Service Type" icon="settings-filled" />

        {(tab === PRICING_DESK_TABS.QUOTES && isInternal) && (
          <>
            <FilterLookup type="assignee" label="Assignee" icon="user" />
            <FilterLookup type="followup" label="Followup" icon="calendar" />
          </>
        )}
        {(tab === PRICING_DESK_TABS.ORDERS && isInternal) && (
          <FilterLookup type="unapproved" label="Approval needed" icon="request-review" />
        )}
      </div>
    </div>
  )
};

export default PricingDeskFilter;

import React, { useRef, forwardRef } from 'react'

import { Checkbox } from 'Atoms';
import { Lookup } from 'Molecules';

import './Internet.scss';

const InternetLookup = props => {
  const { 
    name='internet-select', 
    label='To', 
    icon='internet', 
    options=[], 
    defaultValue,
    ipRateSelection,
    toggleIpRate,
    ...selectProps
  } = props;
  const _defaultValue = defaultValue || (options.length && options[0]) || undefined;
  const isDisabled = props.isDisabled || options.length === 1;

  const inputRef = useRef();

  const onClick = e => {
    toggleIpRate?.(e);

    e.preventDefault();
    e.stopPropagation();
  }

  const MenuListFooter = !toggleIpRate ? null : (
    <div className="menu-list-footer__ip-pricing-checkbox" onClick={onClick}>
      <IpPricingCheckbox checked={ipRateSelection} />
    </div>
  );

  return <Lookup
    defaultValue={_defaultValue}
    icon={icon}
    isDisabled={isDisabled}
    isSearchable={false} 
    label={label} 
    name={name} 
    openMenuOnFocus={!isDisabled}
    options={options}
    ref={inputRef}
    components={{ MenuListFooter }}
    {...selectProps} 
  />
}

const IpPricingCheckbox = ({ checked, onChange }) => (
  <Checkbox label="Use End-User rate for Unitas IP pricing" checked={checked} onChange={onChange} />
)

export default forwardRef((props, ref) => <InternetLookup inputRef={ref} {...props} />);

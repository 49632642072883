import React, { useState, useEffect } from 'react';
import { LabelledInput } from 'Atoms';
import { toCurrencySymbol } from 'Utils/formatter.js';

const CurrencyInput = (props) => {
  const { value, currency, onChange, ...otherProps } = props;
  const [ isFocused, setIsFocused ] = useState(false);
  const [ textVal, setTextVal ] = useState(value);
  const [ fixed, setFixed ] = useState(2);

  const onFocus = () => setIsFocused(true);

  const onBlur = () => {
    setTextVal(Number(value || 0).toFixed(fixed));
    setIsFocused(false);
  }

  const onInputChange = e => {
    setTextVal(e.target.value);
    props.onChange(e);
  }

  // Update the text value for unfocused inputs
  useEffect(() => { if (!isFocused) setTextVal(Number(value || 0).toFixed(fixed)); }, [isFocused, fixed, value]);
  useEffect(() => { if (currency === 'JPY') setFixed(0) }, [currency]);

  const formatted = isFocused ? textVal : (Number(value || 0).toFixed(fixed));
  const label = toCurrencySymbol(currency) || '$';

  return (
    <LabelledInput className="currency-input" value={formatted} label={label} labelPosition="left" type="number" onBlur={onBlur} onFocus={onFocus} onChange={onInputChange} {...otherProps} />
  )
}

export default CurrencyInput;
import React, { useState } from 'react';
import { Icon, LoadingOverlay, TextInput } from 'Atoms';
import { Popup } from 'Molecules';
import { createBulkComment } from 'Actions/ccm/api.js';
import { count } from 'Utils/strings.js';
import { INTERNAL_COMPANY_NAME } from 'Utils/branding.js';

import './BulkCommentModal.scss';

const BulkCommentModal = props => {
  const { comment, quotes, folderId, status, onConfirm, onClose } = props;

  const [ isFetching, setIsFetching ] = useState(false);
  const [ input, setInput ] = useState(comment);

  const sendComment = async () => {
    const req = { 
      comment: input,
      quotes: quotes?.map(x => x.id),
      folderId,
      status: status?.split(',')
        .map(s => s === 'manual' ? 'manual,error' : s)
        .join(','),
    };

    const { error, ...resp } = await createBulkComment(req);
    
    (error) 
      ? alert('Unable to send comment')
      : onConfirm?.(resp)

    setIsFetching(false);
  }

  const onChange = ({ currentTarget: { value }}) => setInput(value);

  const onFocus = ({ currentTarget }) => {
    const length = currentTarget.value?.length || 0;
    currentTarget.setSelectionRange(length, length);
  }

  const quoteCount = (props.count || quotes?.length || 0);

  const popupProps = {
    label: `Create Quote Comment (${count(quoteCount, 'quote')})`,
    button1: { label: 'Cancel', onClick: onClose },
    button2: { 
      label: 'Submit', 
      primary: true, 
      disabled: (isFetching || !input), 
      onClick: sendComment 
    },
    onClose,
    id: 'bulk-comment-modal'
  }

  return (
    <Popup {...popupProps}>
      {!!isFetching && <LoadingOverlay chidren="Submitting comment..." /> }
      <p className="flex-row">
        <Icon name="info-circle-outline" />Please note that these comments will only be visible to other {INTERNAL_COMPANY_NAME} users.
      </p>
      <TextInput autoFocus name="comment" onFocus={onFocus} placeholder="Quote comment..." type="textarea" value={input} onChange={onChange} maxLength="1000" />
    </Popup>
  );
}

export default BulkCommentModal;
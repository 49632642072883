import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { apiUrlIdStripper } from 'Utils/ccm.js';
import { PERMISSIONS } from 'Utils/const.js';
import { getBandwidthLabel, getTermLabel, toCurrency, toAddress } from 'Utils/formatter.js';

// Extend dayjs
dayjs.extend(customParseFormat).extend(isSameOrAfter);

const toIndeterminateBoolean = x => x === undefined ? undefined : !!x;
const toIndeterminateNumber = x => x === undefined ? undefined : +x;

const requestMapper = {
  toEthernetOptions: (details) => {
    if (!details) return undefined;

    const { frameMtu, vlanTag, vlanId, l2cp, QinQ, autoNegotiation } = details;
    
    return {
      "frame_mtu": frameMtu,
      "vlan_tag": vlanTag,
      "vlan_id": vlanId,
      "l2cp": toIndeterminateBoolean(l2cp),
      "q_in_q": toIndeterminateBoolean(QinQ),
      "auto_negotiation": toIndeterminateBoolean(autoNegotiation),
    };
  },

  toInternetOptions: (details) => {
    if (!details) return undefined;

    const { redundantPort, vlanTag, serviceType='Fixed', ipVersion, ipAddresses, routingProtocol } = details;

    return {
      "redundant_port": toIndeterminateBoolean(redundantPort),
      "vlan_tag": vlanTag,
      "service_type": serviceType,
      "ip_version": ipVersion,
      "cidr_ip_block_size": ipAddresses,
      "routing_protocol": routingProtocol
    };
  },

  toIpOptions: (details) => {
    if (!details) return undefined;
    const { routing, ipv4, asn, irr, fullRoutes=null, defaultRoutes=null } = details;

    return {
      "routing_protocol" : routing,
      "cidr_ip_block_size" : ipv4?.replace('cidr-', ''),
      ...(routing === 'BGP4' && {
          "asn": asn,			//max 50
          "irr_as_set": irr,		//max 50
          "receive_full_routes": toIndeterminateBoolean(fullRoutes),
          "receive_default_routes": toIndeterminateBoolean(defaultRoutes),
        })
      }
  },

  toLocationOptions: (details, form) => {
    if (!details) return undefined;

    const {
      companyName, loaCfa, address2,
      requestDemarc, handoff, connector,
      powerType, powerSource, mounting,
      siteContactName, siteContactPhone, siteContactEmail, 
      shippingName, shippingPhone, shippingEmail, shippingAddress,
      onsiteIsOrderContact,
    } = details;
    const  { orderContactName, orderContactEmail, orderContactPhone } = form;

    return {
      "loa_cfa_company_name": loaCfa,
      "company_name": companyName,
      "interior_details": address2,
      "demarc_beyond_mpoe": toIndeterminateBoolean(requestDemarc),
      "physical_hand_off": handoff,
      "on_site_contact_name": onsiteIsOrderContact ? orderContactName : siteContactName,
      "on_site_contact_phone": onsiteIsOrderContact ? orderContactPhone : siteContactPhone,
      "on_site_contact_email": onsiteIsOrderContact ? orderContactEmail : siteContactEmail,
      "shipping_contact_name": shippingName,
      "shipping_contact_phone": shippingPhone,
      "shipping_contact_email": shippingEmail,
      "shipping_address_string": shippingAddress,
      "equipment_connector_type": connector,
      "equipment_power_requirement": powerType,
      "equipment_power_source": powerSource,
      "equipment_mounting": mounting
    }
  }
}

const responseMapper = {
  toLocation: (location) => {
    const { line1, line2, displayName } = (toAddress(location) || {});
    return { 
      line1, line2, displayName, 
      requiresEquipment: location && location.requires_equipment !== false, // true || undefined
      isCustomerNni: location && location.data_center_is_client_nni,
    };
  },

  toEthernetOptions: (details) => {
    if (!details) return undefined;

    const { frame_mtu, vlan_tag, vlan_id, l2cp, q_in_q, auto_negotiation } = details;

    return {
      frameMtu: frame_mtu,
      vlanTag: vlan_tag,
      vlanId: vlan_id,
      l2cp: toIndeterminateNumber(l2cp),
      QinQ: toIndeterminateNumber(q_in_q),
      autoNegotiation: toIndeterminateNumber(auto_negotiation),
    };
  },
  
  toInternetOptions: (details) => {
    if (!details) return undefined;

    const { redundant_port, vlan_tag, service_type, ip_version, routing_protocol } = details;

    return {
      redundantPort: toIndeterminateNumber(redundant_port),
      vlanTag: vlan_tag,
      serviceType: service_type,
      ipVersion: ip_version,
      // ipv4 block size no longer tracked at quote order level.
      //   ipAddresses: cidr_ip_block_size,
      routingProtocol: routing_protocol
    };
  },

  toIpOptions: (details) => {
    if (!details) return undefined;

    const { routing_protocol, asn, irr_as_set, receive_full_routes, receive_default_routes } = details;

    return {
      routing: routing_protocol,
      // addresses: cidr_ip_block_size,
      ...(routing_protocol === 'BGP4' && {
          asn,			//max 50
          irr: irr_as_set,	//max 50
          fullRoutes: toIndeterminateNumber(receive_full_routes),
          defaultRoutes: toIndeterminateNumber(receive_default_routes),
        })
      }
  },

  toLocationOptions: (details, form) => {
    if (!details) return undefined;

    const {
      company_name, loa_cfa_company_name, interior_details,
      demarc_beyond_mpoe, physical_hand_off, equipment_connector_type,
      equipment_power_requirement, equipment_power_source, equipment_mounting,
      on_site_contact_name, on_site_contact_phone, on_site_contact_email,
      shipping_contact_name, shipping_contact_phone, shipping_contact_email, shipping_address_string
    } = details;

    const { order_contact_name, order_contact_email, order_contact_phone } = form;
    const onsiteIsOrderContact = (
      on_site_contact_name === order_contact_name && 
      on_site_contact_phone === order_contact_phone && 
      on_site_contact_email === order_contact_email
    );
    const shippingIsSiteContact = (
      on_site_contact_name === shipping_contact_name && 
      on_site_contact_phone === shipping_contact_phone && 
      on_site_contact_email === shipping_contact_email
    );

    return {
      companyName: company_name,
      loaCfa: loa_cfa_company_name,
      address2: interior_details,
      requestDemarc: toIndeterminateNumber(demarc_beyond_mpoe),
      handoff: physical_hand_off,
      siteContactName: on_site_contact_name,
      siteContactPhone: on_site_contact_phone,
      siteContactEmail: on_site_contact_email,
      shippingName: shipping_contact_name,
      shippingPhone: shipping_contact_phone,
      shippingEmail: shipping_contact_email,
      shippingAddress: shipping_address_string,
      connector: equipment_connector_type,
      powerType: equipment_power_requirement,
      powerSource: equipment_power_source,
      mounting: equipment_mounting,
      onsiteIsOrderContact,
      shippingIsSiteContact,
    }
  }
}

export const toResumeOrder = (form) => {
  const {
    id, purchase_order_number, requested_installation_date, note, accept_terms_of_service, approver_email, modified_time,
    order_contact_name, order_contact_email, order_contact_phone,
    company_name, company_phone, company_website, company_address,
    technical_contact_name, technical_contact_phone, technical_contact_email,
    billing_contact_name, billing_contact_phone, billing_contact_email, billing_contact_address,
    ethernet_options, internet_options, ip_transit_options, location_a_options, location_z_options,
  } = form;

  return {
    id,
    orderNumber: purchase_order_number,
    installDate: requested_installation_date,
    orderContactName: order_contact_name,
    orderContactEmail: order_contact_email,
    orderContactPhone: order_contact_phone,

    // Note: These are IP specific
    companyName: company_name,
    companyPhone: company_phone,
    companyUrl: company_website,
    companyAddress: company_address,
    techName: technical_contact_name,
    techEmail: technical_contact_email,
    techPhone: technical_contact_phone,
    billingName: billing_contact_name,
    billingEmail: billing_contact_email,
    billingPhone: billing_contact_phone,
    billingAddress: billing_contact_address,

    notes: note,
    ethernet: responseMapper.toEthernetOptions(ethernet_options),
    internet: responseMapper.toInternetOptions(internet_options),
    ip: responseMapper.toIpOptions(ip_transit_options),
    locationA: responseMapper.toLocationOptions(location_a_options, form),
    locationZ: responseMapper.toLocationOptions(location_z_options, form),
    acceptTerms: accept_terms_of_service,
    approverEmail: approver_email,
    lastModified: modified_time,
  }
}
  
export const toOrderReview = ({ designId, quote, formData }) => {
  const {
    orderNumber, installDate, notes, acceptTerms, approverEmail,
    orderContactName, orderContactEmail, orderContactPhone,
    companyName, companyPhone, companyUrl, companyAddress,
    techName, techEmail, techPhone, billingName, billingPhone, billingEmail, billingAddress,
    ethernet, internet, ip, locationA, locationZ
  } = formData;

  const req = {
    "design_id": designId,
    "purchase_order_number": orderNumber,
    "requested_installation_date": installDate,
    "order_contact_name": orderContactName,
    "order_contact_email": orderContactEmail,
    "order_contact_phone": orderContactPhone,

    // Note: These are IP specific
    "company_name": companyName,		//max 255
    "company_phone": companyPhone,		//max 20
    "company_website": companyUrl,	//max 255
    "company_address": companyAddress,	//max 255
    "technical_contact_name": techName,	//max 255
    "technical_contact_email": techEmail,	//max 254
    "technical_contact_phone": techPhone,	//max 20
    "billing_contact_name": billingName,	//max 255
    "billing_contact_email": billingEmail,	//max 254
    "billing_contact_phone": billingPhone,	//max 20
    "billing_contact_address": billingAddress,	//max 255

    "note": notes,
    "ethernet_options": requestMapper.toEthernetOptions(ethernet),
    "internet_options": requestMapper.toInternetOptions(internet),
    "ip_transit_options": requestMapper.toIpOptions({ ...ip, ipv4: quote.ipv4 }),
    "location_a_options": requestMapper.toLocationOptions(locationA, formData),
    "location_z_options": requestMapper.toLocationOptions(locationZ, formData),
    "approver_email": approverEmail,
    "accept_terms_of_service": acceptTerms,
    "requires_review": true,
  }

  return req;
}

export const toGetDesignResponse = (resp) => {
  const { error, id, mrc, nrc, ug_mrr, ug_nrr, mrr, nrr, option_selections, location_a={}, location_z={}, design_type_key, design_type_name, requested_currency, quote_url, design_status, has_order_draft } = resp;
  if (error) return { error };

  return { 
    id,
    quoteId: +apiUrlIdStripper(quote_url),
    mrc: toCurrency(mrc, requested_currency), 
    mrr: toCurrency(ug_mrr, requested_currency),
    mrv: toCurrency(mrr, requested_currency),
    nrc: toCurrency(nrc, requested_currency),
    nrr: toCurrency(ug_nrr, requested_currency),
    nrv: toCurrency(nrr, requested_currency),
    bandwidth: getBandwidthLabel(option_selections.bandwidth),
    port: getBandwidthLabel(option_selections.port_bandwidth, true),
    term: getTermLabel(option_selections.term),
    locationA: responseMapper.toLocation(location_a),
    locationZ: responseMapper.toLocation(location_z),
    product: design_type_name,
    designType: design_type_key,
    designStatus: design_status,
    hasOrderDraft: has_order_draft,
    hasOrderReview: design_status.startsWith('ordering'),
  };
};

// Option values are hard-coded to match API
export const getOrderOptions = (opts={}) => {
  const allowIndeterminate = opts.isEcx;

  const INDETERMINATE_OPTION = { 
    label: <i>{'Unsure/To be determined'}</i>, 
    value: 'Unsure/To be determined', 
    isDefault: true 
  };

  const orderOptions = {
    powerTypes: [
      { label: 'AC', value: 'AC' },
      { label: 'DC', value: 'DC' },
      {...INDETERMINATE_OPTION, isDefault: true },
    ],
    powerSources: [
      { label: 'Single', value: 'Single' },
      { label: 'Dual', value: 'Dual' },
      {...INDETERMINATE_OPTION, isDefault: true },
    ],
    mountings: [
      { label: 'Wall Mount', value: 'Wall Mount' },
      { label: 'Rack Mount - 19"', value: 'Rack Mount - 19"' },
      { label: 'Rack Mount - 23"', value: 'Rack Mount - 23"' },
      {...INDETERMINATE_OPTION, isDefault: true },
    ],
    frameMtus: [
      { label: 'Standard (1500)', value: '1500' },
      { label: 'Standard (1522)', value: '1522', isDefault: true },
      { label: 'Jumbo (1600)', value: '1600' },
      { label: 'Jumbo (2000)', value: '2000' },
      { label: 'Jumbo (9000)', value: '9000' },
      { label: 'Other', value: 'Other' },
      (allowIndeterminate && INDETERMINATE_OPTION),
    ],
    frameTypes: [
      { label: '802.1q (Standard Tag)', value: 'standard' },
      { label: '802.1q (Q-in-Q Tag)', value: 'q-in-q' },
      (allowIndeterminate && INDETERMINATE_OPTION),
    ],
    maxLinks: [
      { label: '1', value: '1' },
    ],
    vlanTags: [
      { label: 'Tagged', value: 'Tagged' },
      { label: 'Untagged', value: 'Untagged', isDefault: true },
      (allowIndeterminate && INDETERMINATE_OPTION),
    ],
    serviceTypes: [
      { label: 'Fixed', value: 'Fixed', isDefault: true },
      // { label: (
      //   <Fragment>
      //     Burstable
      //     <div className="quote-order-option-byline">(if available)</div>
      //   </Fragment>
      // ), value: 'burstable' },
      allowIndeterminate && { ...INDETERMINATE_OPTION, isDefault: false },
    ],
    ipVersions: [
      { label: 'IPv4', value: 'IPv4' },
      { label: 'IPv6', value: 'IPv6' },
      (allowIndeterminate && INDETERMINATE_OPTION),
    ],
    ipAddresses: [
      { label: '/30 (4 IPs)', value: '30' },
      { label: '/29 (8 IPs)', value: '29' },
      { label: '/28 (16 IPs)', value: '28' },
      { label: '/27 (32 IPs)*', value: '27', requiresForm: true },
      { label: '/26 (64 IPs)*', value: '26', requiresForm: true },
      { label: '/25 (128 IPs)*', value: '25', requiresForm: true },
      { label: '/24 (256 IPs)*', value: '24', requiresForm: true },
      (allowIndeterminate && INDETERMINATE_OPTION),
    ],
    routingProtocols: [
      { label: 'Static', value: 'Static' },
      { label: 'BGP4 (Standard directly-peered)', value: 'BGP4' },
      { label: 'eBGP Multihop', value: 'eBGP Multihop' },
      (allowIndeterminate && INDETERMINATE_OPTION),
    ],
    handOffs: [
      { label: 'Ethernet - Electrical', value: 'Ethernet - Electrical' },
      { label: 'Fiber - Optical', value: 'Fiber - Optical' },
      (allowIndeterminate && INDETERMINATE_OPTION),
    ],
    connectorTypes: [
      { label: 'RJ45 - Ethernet', value: 'RJ45 - Ethernet' },
      { label: 'SC - Singlemode', value: 'SC - Singlemode' },
      { label: 'SC - Multimode', value: 'SC - Multimode' },
      { label: 'LC - Singlemode', value: 'LC - Singlemode' },
      { label: 'LC - Multimode', value: 'LC - Multimode' },
      (allowIndeterminate && INDETERMINATE_OPTION),
    ],
    yesNo: [
      { label: 'Yes', value: 1 },
      { label: 'No', value: 0 },
    ]
  };

  const optionsECX = {
    frameMtus: [
      { label: 'Standard (1500)', value: '1500', isDefault: true },
      { label: 'Standard (1522)', value: '1522' },
      { label: 'Jumbo (1600)', value: '1600' },
      { label: 'Jumbo (2000)', value: '2000' },
      { label: 'Jumbo (9000)', value: '9000' },
      { label: 'Other', value: 'Other' },
      allowIndeterminate && { ...INDETERMINATE_OPTION, isDefault: false },
    ],
    vlanTags: [
      { label: 'Multiple services (tagged)', value: 'Tagged' },
      { label: 'Single service (untagged)', value: 'Untagged' },
      (allowIndeterminate && INDETERMINATE_OPTION),
    ],
    connectorTypes: [
      { label: 'RJ45 - Ethernet', value: 'RJ45 - Ethernet' },
      { label: 'LC - Singlemode', value: 'LC - Singlemode' },
      { label: 'LC - Multimode', value: 'LC - Multimode' },
      (allowIndeterminate && INDETERMINATE_OPTION),
    ],
    routingProtocols: [
      { label: 'Static', value: 'Static' },
      { label: 'BGP', value: 'BGP4' },
    ],
    ipAddressesBgp: [
      { label: '/30', value: '30' },
      { label: '/29', value: '29' },
      { label: 'Other', value: 'Other' },
    ],
    ipAddressesStatic: [
      { label: '/30', value: '30' },
      { label: '/29', value: '29' },
      { label: '/30 + routed /29', value: '30 + routed 29' },
      { label: '/28', value: '28' },
      { label: '/30 + routed /28', value: '30 + routed 28' },
      { label: '/27', value: '27' },
      { label: '/30 + routed /27', value: '30 + routed 27' },
      { label: 'Other', value: 'Other' },
    ],
  };

  return opts.isEcx 
    ? { ...orderOptions, ...optionsECX } 
    : Object.entries(orderOptions).reduce(((acc, [ key, val ]) => (
      acc[key] = val.filter(x => !!x), acc
    )), {});
};

export const getFormOptions = (user, quote, design) => {
  const defaultValues = ['ordering', 'ordered'].includes(design.status) 
  ? {} : {
    orderContactName: user.name,
    orderContactEmail: user.email,
  };

  const validationSchema = getValidationSchema(user, quote, design);
  const resolver = yupResolver(validationSchema);

  return { mode: 'onTouched', defaultValues, resolver };
}

export const getValidationSchema = (user, quote={}, design={}) => {
  const isValidFutureIsoDate = isoDateString => {
    if (!isoDateString) return true;
    const reqInstallDate = dayjs(isoDateString);
    const today = dayjs().startOf('day');
    return reqInstallDate.isValid() && reqInstallDate.isSameOrAfter(today);
  };

  const isEcx = quote.priceAsOrganizationKey === 'equinix-ecx';
  const isInternet = (design.designType || '').indexOf('internet') > -1 || (design.designType || '').indexOf('ip-transit') > -1;
  const isEcxIp = isEcx && isInternet;
  // const options = getOrderOptions({ isEcx });

  const requiredLocationFields = (location) => {
    //debugger;
    return (isEcx || !location || location.isCustomerNni) ? {} : {
      ...(location.requiresEquipment ? {
        companyName: yup.string().required('*Required'),
        requestDemarc: yup.number().required('*Required'),
        siteContactName: yup.string().required('*Required'),
        siteContactPhone: yup.string().required('*Required'),
        siteContactEmail: yup.string().required('*Required').email('*Invalid email format'),
        handoff: yup.string().required('*Required'),
        connector: yup.string().required('*Required'),
        // shippingName: yup.string().required('*Required'),
        // shippingPhone: yup.string().required('*Required'),
        shippingEmail: yup.string().email('*Invalid email format'),
        // shippingAddress: yup.string().required('*Required'),
        powerType: yup.string().required('*Required'),
        powerSource: yup.string().required('*Required'),
        mounting: yup.string().required('*Required'),
      } : {
        loaCfa: yup.string().required('*Required'),
      })
    };
  }

  const invalidVlanId = '*Invalid: Please enter a number between 2-4094';

  return yup.object().shape({
    orderContactName: yup.string()
      .required('*Required'),
    orderContactEmail: yup.string()
      .required('*Required')
      .email('*Invalid email format'),
    orderContactPhone: yup.string()
      .required('*Required'),
    installDate: yup.string()
      .nullable()
      .test('valid-future-iso-date',
        '*Invalid date - please choose a future date',
        isValidFutureIsoDate
    ),
    locationA: yup.object().shape({
      siteContactEmail: yup.string().email('*Invalid email format'),
      shippingEmail: yup.string().email('*Invalid email format'),
      ...(requiredLocationFields(design.locationA))
    }),
    ...(!!isInternet && !isEcx && {
      internet: yup.object().shape({
        vlanTag: yup.string().required('*Required'),
        redundantPort: yup.number().required('*Required'),
      })
    }),
    ...((!isInternet) && {
      locationZ: yup.object().shape({
        siteContactEmail: yup.string().email('*Invalid email format'),
        shippingEmail: yup.string().email('*Invalid email format'),
        ...(requiredLocationFields(design.locationZ)),
      }),
      ethernet: yup.object().default({}).shape({
        frameMtu: yup.string().required('*Required'),
        vlanTag: yup.string().required('*Required'),
        vlanId: yup.number().when('vlanTag', (vlanTag, schema) => {
          return (user.isEcx && vlanTag === 'Tagged')
            ? schema.required('*Required').integer(invalidVlanId).min(2, invalidVlanId).max(4094, invalidVlanId)
            : schema;
        }),
        ...(!isEcx && {
          l2cp: yup.number().required('*Required'),
          QinQ: yup.number().required('*Required'),
          autoNegotiation: yup.number().required('*Required'),
        }),
      }),
    }),
    ...((user.permissions || []).includes(PERMISSIONS.DOCUSIGN_ENABLED) && {
      approverEmail: yup.string().required('*Required'),
    }),
    ...(isEcxIp ? {
      companyName: yup.string()
        .required('*Required'),
      companyPhone: yup.string()
        .required('*Required'),
      companyUrl: yup.string()
        .required('*Required'),
      companyAddress: yup.string()
        .required('*Required'),
      techName: yup.string()
        .required('*Required'),
      techEmail: yup.string()
        .required('*Required')
        .email('*Invalid email format'),
      techPhone: yup.string()
        .required('*Required'),
      billingName: yup.string()
        .required('*Required'),
      billingEmail: yup.string()
        .required('*Required')
        .email('*Invalid email format'),
      billingPhone: yup.string()
        .required('*Required'),
      billingAddress: yup.string()
        .required('*Required'),
      ip: yup.object().shape({
        routing: yup.string()
          .required('*Required'),
        // addresses: yup.string()
        //   .required('*Required')
          // .when('routing', { 
          //   is: 'BGP4',
          //   then: yup.string().oneOf(options.ipAddressesBgp.map(x => x.value)),
          //   otherwise: yup.string().oneOf(options.ipAddresses.map(x => x.value)),
          //}),
        // conditional fields
        asn: yup.string().when('routing', { 
          is: 'BGP4', then: yup.string().required('*Required'),
        }),
        irr: yup.string().when('routing', { 
          is: 'BGP4', then: yup.string().required('*Required'),
        }),
        fullRoutes: yup.number().when('routing', { 
          is: 'BGP4', then: yup.number().required('*Required'),
        }),
        defaultRoutes: yup.number().when('routing', { 
          is: 'BGP4', then: yup.number().required('*Required'),
        }),
      })
    } : {})
  });
}

import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';

import { Lookup } from 'Molecules';
import { AddressOption, CountryGroupHeader } from './Options.jsx';

import { 
  toColoProviderOption, 
  toColoRegionOption, 
  toColoLocationOption,
  toSiteCodeOptions,
} from './LocationMapper.js';

import { withUserContext } from 'Context/UserContext.js';

import { getPopAutocomplete } from 'Actions/ccm/api.js';
import { getCountryName } from 'Utils/countries.js';
import { getStateName } from 'Utils/formatter.js';
import { sortByLabel } from 'Utils/array.js';

import './Location.scss';

class ColoDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: undefined,
      inputValue: '',
      mode: null,
      colo: {},
      closeMenuOnSelect: true,
      error: null,
      options: [],
    }

    // refs
    this.inputRef = React.createRef();

    // Methods
    this.onChange = this.handleColoOption.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.getDefaultOptions = this.getDefaultOptions.bind(this);
    this.onMenuClose = this.onMenuClose.bind(this);
    this.formatOptionLabel = this.formatOptionLabel.bind(this);
    
    // Methods for colo container
    this.getColoDefaultOptions = this.getColoDefaultOptions.bind(this);
    this.handleColoOption = this.handleColoOption.bind(this);
    this.onClickColoBack = this.onClickColoBack.bind(this);
    this.fetchPopAutocomplete = debounce(this.fetchPopAutocomplete, 250).bind(this);
  }

  getDefaultOptions() {
    const { colos=[], className } = this.props;
    const { menuIsOpen, value } = this.state;

    if ((value && value.coloState) || colos.length) {
      // scroll menu to top
      const selector = `.colo-lookup ${className ? '.' + className : ''} .react-select__menu-list`;
      const dropdown = menuIsOpen && document.querySelector(selector);
      dropdown && dropdown.scrollTo(0, 0);

      return this.getColoDefaultOptions();
    }
  }

  onInputChange(inputValue, { action }) {
    const { value } = this.state;

    if (action === 'input-blur') {
      const newInputValue = !value ? '' : this.formatOptionLabel(value, { context: 'value' });
      this.setState({ inputValue: newInputValue });
    }
    
    else if (action === 'input-change') {
      this.setState({ inputValue, error: null });
    }

    return;
  }

  loadOptions(inputValue='', callback) {
    const needle = inputValue ? inputValue.toLowerCase() : '';
    const foundNeedle = (stack, fromBegin = false) => (
      fromBegin ? stack.toLowerCase().startsWith(needle) : stack.toLowerCase().includes(needle)
    );

    const filterRegions = (countries) => (
      countries.map(group => {
        if (foundNeedle(group.label.props.countryName, true)) {
          return group;
        }

        const regions = group.options.filter(region => foundNeedle(region.label));
        return !regions.length ? null : { label: group.label, options: regions };
      }).filter(x=>!!x)
    );

    const filterOptions = (options) => {
      const isCountryGrouping = get(options, '0.label.props.countryName');
      return isCountryGrouping 
        ? filterRegions(options) 
        : options.filter(({ label }) => foundNeedle(label));
    };

    if (inputValue.length >= 3) 
      this.fetchPopAutocomplete(inputValue, callback);
    else {
      const coloOptions = this.getColoDefaultOptions();
      const options = coloOptions.length ? filterOptions(coloOptions) : [];
      this.setState({ options });
      callback(options);
    }
  }

  fetchPopAutocomplete(inputValue, callback) {
    const userOrgName = this.props.user.organization_name;
    const includeHubs = this.props.includeHubs;

    getPopAutocomplete(inputValue, includeHubs).then(({ error, results=[] }) => {
      const options = error ? [] : toSiteCodeOptions(results, userOrgName).map(x => ({ ...x,... x.value }));
      this.setState({ options });
      callback(options);
    });
  }

  // NOTE: onMenuClose is triggered before react-select onChange
  onMenuClose() {
    const { value, menuIsOpen } = this.state;
    const mode = (value && value.coloState) ? 'colo' : null;

    if (menuIsOpen && value) {
      this.setState({ mode, colo: {}, menuIsOpen: false });
    }
  }

  formatOptionLabel(option, { context }) {
    if (this.props.formatOptionLabel) {
      return this.props.formatOptionLabel(option, { context });
    }

    const showAddressMenuOption = (context === 'menu' && option.addressLabel);
    return (showAddressMenuOption) 
      ? <AddressOption {...option} />
      : option.label;
  }

  handleCreate(label) {
    const value = { label, value: label, string_address: label, skip_validation: true };
    this.inputRef.current.select.onChange(value, { action: 'select-option' });
  }
  
  // COLO METHODS
  getColoDefaultOptions() {
    const { value } = this.state;
    const { colos=[] } = this.props;

    const colo = (value && value.coloState) || this.state.colo;
    const region = colo.region;
    
    const byPopName = (a, b) => (a.popName.toLowerCase() < b.popName.toLowerCase() ? -1 : 1);
    const byCountryGroup = (a, b) => {
      const val = (arr=[]) => (getCountryName(arr[0]) || arr[0] || '').toLowerCase();
      return (a[0] === 'USA' || val(a) < val(b)) ? -1 : 1;
    };
    const byStateOrRegionLabel = (({ countryCode, state, region }) => {
      return countryCode === 'USA' ? getStateName(state) : region
    });
    
    const providerColos = groupBy(colos, 'provider');
    const providers = Object.keys(providerColos) || [];

    // Use selected provider or default provider if there is only one
    const provider = colo.provider || (providers.length === 1 && providers[0]);

    // Group provider locations into regions if there are more than 50 locations
    const providerRegionColos = (provider && providerColos[provider].length > 50 && groupBy(providerColos[provider], byStateOrRegionLabel));

    // Show Colocations if there is a designated provider and provider has <= 50 locations or user indicated a region
    const isShowingColocations = (provider && (providerColos[provider].length <= 50 || !!region));
    
    if (isShowingColocations) {
      const colocations = (provider && region && providerRegionColos) 
        ? providerRegionColos[region].filter(x => !colo.countryCode || x.countryCode === colo.countryCode)
        : providerColos[provider];

      const options = colocations.map(toColoLocationOption).sort(byPopName);

      // // TODO: not working yet...
      // if (options.length === 1) {
      //   return this.handleColoOption(options[0]);
      // }

      return options;
    }

    // Show Provider Regions
    if (providerRegionColos) {
      const countries = groupBy(providerColos[provider], 'countryCode');
      const getOptions = (vals, countryCode) => uniq(vals.map(byStateOrRegionLabel))
        .map(region => toColoRegionOption(region, countryCode))
        .sort(sortByLabel);

      return Object.entries(countries)
        .sort(byCountryGroup)
        .map(([key, vals]) => ({
          label: <CountryGroupHeader countryCode={key} countryName={getCountryName(key)} />,
          options: getOptions(vals, key)
        }));
    }

    // Show Providers
    return (providers).map(toColoProviderOption).sort(sortByLabel);
  }


  handleColoOption(option={}, action={}) {
    const { value } = this.state;
    const { onChange=()=>{} } = this.props;
    let autoSelect;

    if (option === null && action.action === 'clear') {
      onChange(null);
      return this.setState({ value: option, inputValue: '', menuIsOpen: false, mode: null, colo: {} }, () => {
        setTimeout(() => this.inputRef.current.select.select.blur(), 0);
      });
    }

    const colo = {
      ...((option.colo) ? { [option.colo]: option.value } : {}),
      ...this.state.colo,
      countryCode: option.countryCode
    };

    if (option.colo !== 'id') {
      const remainingOptions = this.props.colos.filter(x => ((x.provider === colo.provider) 
        && (!colo.region || (colo.region === x.region && colo.countryCode === x.countryCode)) 
      ));

      autoSelect = (remainingOptions.length === 1) && toColoLocationOption(remainingOptions[0]);
    }

    if (option.colo === 'id' || option.optionType === "siteCode" ||  autoSelect) {
      // Use existing values for region and/or provider if there is already a value
      const { id, ...coloState } = ((value && value.coloState) || colo);
      const coloOption = { coloState, ...option, ...autoSelect };
      const inputValue = this.formatOptionLabel(coloOption, { context: 'value'});

      onChange(coloOption);
      this.setState({ mode: 'colo', colo, menuIsOpen: false, inputValue, value: coloOption }, () => {
        this.inputRef.current.select.select.blur()
      });
    }

    else {
      this.setState({ mode: 'colo', inputValue: '', colo, menuIsOpen: true });
    }
  }

  // TODO: Implement this go back functionality in choosing colo
  onClickColoBack() {
    const { colo } = this.state;
    const { provider, region } = colo;

    if (region) {
      return this.setState({ colo: { provider }, value: provider });
    }

    return (provider) 
      ? this.setState({ colo: {} })
      : this.setState({ mode: null, value: null });
  }
  // END COLO METHODS

  componentDidMount() {
    if (this.props.defaultValue) {
      const value = this.props.colos.filter(({ name }) => name === this.props.defaultValue.string_address)[0];
      if (value) {
        this.inputRef.current.select.onChange(toColoLocationOption(value), { action: 'select-option' });
      }
    }
  }

  render() {
    // TODO: discover why we are using prop.value... (This might be a good candidate for mergePropsToState)
    const { name, label, value, defaultValue, icon, className='', isDisabled } = this.props;
    const { menuIsOpen, inputValue, isLoading } = this.state;
    const { onChange, onInputChange, loadOptions, onMenuClose, inputRef, formatOptionLabel, noOptionsMessage } = this;
    
    // This allows colo label titles to be shown in value label while navigating colo submenus
    const editable = !!this.state.value;
    const error = this.props.error || this.state.error;

    let options = {};
    if (menuIsOpen) {
      options.menuIsOpen = menuIsOpen;
    }
    if (value !== undefined) {
      options.value = value;
    }
    if (defaultValue !== undefined) {
      options.defaultValue = defaultValue;
    }
    
    const defaultOptions = this.getDefaultOptions();
 
    return (
      <Lookup
        className={`colo-lookup ${className}`}
        name={name}
        onChange={onChange}
        onInputChange={onInputChange}
        label={label}
        icon={icon}
        defaultOptions={defaultOptions}
        formatOptionLabel={formatOptionLabel}
        editable={editable}
        async={true}
        loadOptions={loadOptions}
        isLoading={isLoading}
        isDisabled={isLoading || isDisabled}
        isClearable={true}
        tabSelectsValue={!this.state.value}
        closeMenuOnSelect={false}
        openMenuOnFocus={true}
        onMenuClose={onMenuClose}
        noOptionsMessage={noOptionsMessage}
        error={error}
        inputRef={inputRef}
        inputValue={inputValue}
        blurInputOnSelect={false}
        {...options}
      />
    );
  }
}

// owner_name + provider_location_name

// const DefaultMenuHeader = ({ label, onBack }) => (
//   <div className="lookup-menu-header">
//     <button onClick={onBack}>&lt;</button>
//     <div>{label}</div>
//   </div>
// );

export default withUserContext(ColoDropdown);

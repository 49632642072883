import React, { useCallback, useState } from 'react';
import { Button, Icon, LoadingOverlay, TextInput } from 'Atoms';
import { Lookup, Popup } from 'Molecules';
import { useUserContext } from 'Context/UserContext.js';

import { acceptOrderReview, downloadOrderReviewPdf } from 'Actions/ccm/api.js';

import './SendOrderReviewModal.scss';

const SendOrderReviewModal = props => {
  const { onClose, onConfirm, orderId, designId, quoteId, quoteRef } = props;

  const user = useUserContext();

  const [ toEmail, setToEmail ] = useState([]);
  const [ ccEmail, setCcEmail ] = useState([]);
  const [ bodyMsg, setBodyMsg ] = useState();

  const [ loadingMsg, setLoadingMsg ] = useState('');

  const fileName = `Quote Order ${quoteRef} Review.pdf`;
  
  const onSubmit = async () => {
    setLoadingMsg('Sending copy of order for review');
    const resp = await acceptOrderReview(quoteId, {
      accept: false,
      remove_draft: false,
      design_id: designId,
      review_to_emails: toEmail,
      review_cc_emails: [...ccEmail, user.email],
      //review_bcc_emails: ["zxcv@email.com"],
      review_message: bodyMsg
    });
    resp.error ? alert(resp.error) : onConfirm();
    setLoadingMsg('');
  }

  const onChangeTo = (val=[{}]) => setToEmail(val.map(x => x.label));
  const onChangeCc = (val=[{}]) => setCcEmail(val.map(x => x.label));
  const onChangeMsg = e => setBodyMsg(e.currentTarget.value);


  const onClickLink = async () => {
    setLoadingMsg('Generating PDF');
    await downloadOrderReviewPdf(orderId, fileName);
    setLoadingMsg('');
  }

  const popupProps = {
    label: `Email copy of this order to be reviewed`,
    button1: { label: 'Cancel', onClick: onClose },
    button2: { label: 'Send for review', onClick: onSubmit, primary: true, disabled: !toEmail.length || !!loadingMsg },
    onClose,
    id: 'send-order-review-modal'
  };

  return (
    <Popup {...popupProps}>
      {!!loadingMsg && <LoadingOverlay><div>{loadingMsg}</div></LoadingOverlay>}
      <div className="send-order-review">
        <div className="send-order-review__note">A copy will also be emailed to you for your records.</div>
        <Emailer name="to" label="To email address" onChange={onChangeTo} />
        <Emailer name="cc" label="CC" onChange={onChangeCc} />
        <TextInput name="body" label="Optional message" type="textarea" onChange={onChangeMsg} />
        <div className="send-order-review__download">
          <Icon name="paperclip" color="#999" />
          <Button type="link" onClick={onClickLink} children={fileName} />
        </div>
      </div>
    </Popup>
  )
}

const Emailer = props => {
  const isValidEmail = useCallback((input) => /^[^\s@]+@[^\s@]+$/.test(input), []);
  const components = {
    DropdownIndicator: () => null,
    Menu: () => null,
  }
  return <Lookup icon="envelope" isMulti createable asChips isValidNewOption={isValidEmail} components={components} {...props} />
}

export default SendOrderReviewModal;
import React, { useCallback } from 'react';
import { Lookup } from 'Molecules';

import './ServiceClass.scss';

export const DIVERSITY_OPTIONS = [
  { value: 'carrier-diverse', label: 'Carrier Diversity', description: 'Require solutions from distinct carriers' },
  { value: 'route-diverse', label: 'Route Diversity (Different Carriers)', description: 'Manual request to verify route diversity with multiple carriers. Note this may take up to 14 days.' },
  { value: 'route-diverse-same-carrier', label: 'Route Diversity (Same Carrier)', description: 'Manual request to verify route diversity with the same carrier. Note this may take up to 14 days.' },
];

const DiversityDropdown = props => {
  const { name='diversity', label='Diversity', icon='diversity', defaultValue, ...rest } = props;

  const formatOptionLabel = useCallback(({ label, description }, { context }) => {
    return (context === 'menu') ? (<>
        <div className="react-select__option-header">{label}</div>
        <div className="react-select__option-description">{description}</div>
      </>) : label;
  }, []);

  const lookupProps = {
    name, label, icon, formatOptionLabel, 
    options: DIVERSITY_OPTIONS,
    isClearable: true,
    defaultValue: (defaultValue && DIVERSITY_OPTIONS.find(opt => opt.value === defaultValue.value)) || undefined,
    ...rest
  };

  return <Lookup {...lookupProps} />
}

export default DiversityDropdown;
import React from 'react';
import Loadable from 'react-loadable';

// TODO: Add loading components to display while bundle downloads
const Loading = () => <div>Loading...</div>;

const LoadablePages = {
  Documentation: Loadable({ 
    loader: () => import('Routers/DocsRouter.jsx'), 
    loading: Loading
  }),
  Login: Loadable({
    loader: () => import('Auth/Login.jsx'),
    loading: Loading
  }),
  ServiceMetrics: Loadable({
    loader: () => import('Pages/ServiceMetrics/ServiceMetricsPage.jsx'),
    loading: Loading
  }),
  StyleGuide: Loadable({ 
    loader: () => import('Pages/Styleguide/Styleguide.jsx'), 
    loading: Loading
  }),
  TermsAndConditions: Loadable({
    loader: () => import('Pages/Docs/CCM/TermsAndConditions.jsx'),
    loading: Loading
  }),
  UsageAndPrivacy: Loadable({
    loader: () => import('Pages/Docs/CCM/UsageAndPrivacy.jsx'),
    loading: Loading
  })
}

export default LoadablePages;
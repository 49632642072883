import React, { Component } from 'react';
import { getAllCountries, emojiFlag } from 'Utils/countries.js';

import Lookup from './Lookup.jsx';

const toOption = (item => {
  const emoji = emojiFlag(item.alpha2);
  return { emoji, mode: 'country', label: `${emoji} ${item.name}`, value: item.alpha3, ...item }
});

class CountryDropdown extends Component {
  constructor() {
    super();

    this.countries = getAllCountries.map(toOption);
    this.textInput = React.createRef();
  }

  //TODO: better filtering on names and relevence
  filter() {
      if (this.state.mode === 'country') {

      const options = this.countries.filter(c => {
        return (c.name.toUpperCase().indexOf(needle) > -1)
      })

      return callback(options);
    }
  }

  render() {
    const { defaultCountry, onChange, inputRef } = this.props;
    const country = defaultCountry && this.countries.find(c => c.alpha3 === defaultCountry);
    const defaultValue = country ? toOption(country) : undefined;

    return (
      <Lookup 
        name="country" 
        label="Country" 
        defaultValue={defaultValue}
        onChange={onChange} 
        options={this.countries}
        autoFocus={true}
        ref={inputRef}
        />
    )
  }
};

export default React.forwardRef((props, ref) => <CountryDropdown inputRef={ref} {...props} />);
import React from 'react'
import { JumboLoadingIcon } from 'Atoms';

import './LoadingOverlay.scss';

const LoadingOverLay = (props) => {
  const { className='', children } = props;
  
  return (
    <div className={`loading-overlay ${className}`}>
      <div className="loading-overlay__positioning">
        <JumboLoadingIcon />
        { children }
      </div>
    </div>
  );
}

export default LoadingOverLay;
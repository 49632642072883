import React, { useState, useEffect } from 'react';
import { useInterval, useStorage } from 'Hooks';
import { ORGANIZATION_HOMEPAGE, STORAGE_KEYS } from 'Utils/const.js';
import { loginPacketFabric } from 'Actions/ccm/api.js';
import { APP_PRODUCT_NAME } from 'Utils/branding.js';

import './PacketFabricLoginPage.scss';

const { PACKETFABRIC_PORTAL_TOKEN } = STORAGE_KEYS;

const PacketFabricLoginPage = () => {
  const LOGIN_URL = ORGANIZATION_HOMEPAGE.PACKETFABRIC;
  const TIMER_DURATION = 10;
  const [ counter, setCounter ] = useState(TIMER_DURATION);
  const [ loggingIn, setLoggingIn ] = useState(false);
  const [portalToken, _] = useStorage(PACKETFABRIC_PORTAL_TOKEN);

  useInterval(() => setCounter(counter - 1), (counter > 0 ? 1000 : null));

  useEffect(() => {
    if (portalToken && !loggingIn) {
      setLoggingIn(true);
      loginPacketFabric({ token: portalToken })
      .then(data => {
        window.localStorage.removeItem(PACKETFABRIC_PORTAL_TOKEN);
        const url = new URL(data.redirect_url)
        window.location.href = `${url.origin}${window.location.search}${url.hash}`;
        setLoggingIn(false);
      });
    }
  }, [loggingIn]);

  useEffect(() => {
    if (!counter && !loggingIn) window.location.href = LOGIN_URL;
  }, [counter, loggingIn]);

  return (
    <div className="packetfabric-login-page">
      <div className="packetfabric-redirect-text-container">
        {loggingIn ? (
          <>
            <div className="packetfabric-redirect-text--login">Please wait while we log you in.</div>
            <div className="packetfabric-redirect-text--wait">Logging you in…</div>
          </>
        ) : (
          <>
            <div className="packetfabric-redirect-text--reauthenticate">In order to access your {APP_PRODUCT_NAME} information, we need to redirect you to Packetfabric to login and re-authenticate your identity.</div>
            {!!counter && (
              <div className="packetfabric-redirect-text--wait">Please wait while we redirect you…</div>
            )}
            <div className="packetfabric-redirect-text--redirect">
              {(counter 
                ? `If you haven’t been redirected in ${TIMER_DURATION} seconds, please click below.`
                : "Please click below to be redirected."
              )}
            </div>
            <div>
              <a href={LOGIN_URL} className="packetfabric-redirect-link">Continue</a>
            </div>
          </>
        )}
        
      </div>
    </div>
  )
}

export default PacketFabricLoginPage;

import React, { Component } from 'react';
import { withUserContext } from 'Context/UserContext.js';

import { Button, Icon, LoadingIcon, JumboLoadingIcon, Pager, PagerStatus, Notification } from 'Atoms';

import Design from './Design.jsx';
import DesignEditor from './DesignEditor.jsx';
import DesignRollup from './DesignRollup.jsx';
import ManualPricing from './ManualPricing.jsx';

import { getQuoteDesigns, getQuoteRequirements, getOrganization, requestManualPricing, sendQuoteNotifications } from 'Actions/ccm/api.js';
import { toDesigns, toLocationDesignItem, toDefaultDesignType } from 'Pages/CCM/QuoteView/QuoteViewerMapper.js';
import { toAddress, getInternetServiceRequirements } from 'Utils/formatter.js';
import { apiUrlIdStripper, getInternetType } from 'Utils/ccm.js';
import { PERMISSIONS } from 'Utils/const.js';

import './QuoteDesigns.scss';

// TODO: Create scss file and disentangle from QuoteViewer.scss

const STATUSES_WITHOUT_DESIGNS = ['draft', 'pending', ''];
const ORDER_LOCKED_STATUS = ['expired'];

const MANUAL_STATUSES = ['manual', 'manual-pricing-needed', 'error', 'error-pricing'];
const MANUAL_PRICING_EDITOR_ID = 'MANUAL_PRICING_EDITOR_ID';

const getInitialState = (props={}) => {
  const designEditors = MANUAL_STATUSES.includes(props.specStatus) ? [{ key: MANUAL_PRICING_EDITOR_ID }] : [];
  return {
    page: 1,
    pageCount: 0,
    totalItems: 0,
    isLoading: true,
    isPricing: false,
    status: '',
    requirements: {},
    designs: [],
    newDesigns: [],
    designEditors,
    focusedDesignId: null,
    hasUnsentNotifications: false,
    showMarginPct: true,
    ...props
  }
};

class QuoteDesigns extends Component {
  constructor(props) {
    super (props);

    this.state = getInitialState();

    this.updateQuoteDesigns = this.updateQuoteDesigns.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.toggleShowMarginPct = this.toggleShowMarginPct.bind(this);
    this.onSendDesignNotifications = this.onSendDesignNotifications.bind(this);
    this.onRequestPricing = this.onRequestPricing.bind(this);

    this.onClickAddDesign = this.onClickAddDesign.bind(this);
  }

  updateQuoteDesigns(resp) {
    const { count, error } = resp;
    const pageSize = 10;
    const designs = toDesigns(resp);

    this.setState({
      designs: toDesigns(resp),
      error,
      totalItems: count,
      pageSize,
      pageCount: Math.ceil(count/pageSize),
      isLoading: false,
      hasUnsentNotifications: designs.some(x => x.hasUnsentNotifications && x.isSolution),
    });
  }

  handlePageClick({selected=-1}) {
    const page = selected + 1;
    const { isLoading, id } = this.state;

    if (isLoading || page < 1) return;

    this.setState({ page, isLoading: true, newDesigns: [] });
    getQuoteDesigns(id, page).then(this.updateQuoteDesigns);
  }

  onCopy(designData) {
    const { designEditors } = this.state;
    const { user = {} } = this.props;
    const { id, designItems, ...designProps } = (designData || {});
    // remove id's from design items
    const designItemsCopy = designItems.map(({ id, ...itemProps}) => ({ ...itemProps, key: id }));
    const designCopy = {
      ...designProps,
      designItems: designItemsCopy,
      note: `Copied from design id: ${id} by user id: ${user.id} (${user.name})`,
      status: 'manual',
    };

    const key = 'designEditor_' + designEditors.length;

    this.setState({ designEditors: [{ key, domId: key, design: designCopy }, ...designEditors ] }, () => {
      const el = document.getElementById(key);
      el && el.scrollIntoView({ behavior: "smooth" });
    });
  }

  onSave(saveData) {
    const { designEditors, designs, newDesigns, id } = this.state;
    const { designEditorId, isNewDesign = !!designEditorId, design, error } = (saveData || {});

    // TODO: HANDLE ERROR
    if (error) {
      alert(error);
      return;
    }

    return getQuoteRequirements(id)
      .then(({ status, hasUnsentNotifications }) => {
        const stateChange = (isNewDesign)
          ? { // Remove quote editor, display in recently added quotes
            designEditors: designEditors.filter(x => x.key !== designEditorId),
            newDesigns: [...newDesigns, design],
            status,
            hasUnsentNotifications,
          }
          : { // Rehydrate designs with response data
            designs: design ? [...designs.map(item => (item.id === design.id) ? design : item)] : designs,
            newDesigns: design ? newDesigns.map(item => (item.id === design.id) ? design : item) : newDesigns,
            status,
            hasUnsentNotifications,
          }

        // TODO: show notification
        return this.props.setStatus(status, () => this.setState(stateChange));
      });
  }

  onCancel({ designEditorId }) {
    if (designEditorId && designEditorId !== MANUAL_PRICING_EDITOR_ID) {
      this.setState({ designEditors: this.state.designEditors.filter(x => x.key !== designEditorId) });
    }
  }

  onClickAddDesign() {
    const { designEditors, requirements } = this.state;
    const { locationA, locationZ, serviceRequirements } = requirements;

    const key = 'designEditor_' + designEditors.length;
    const designType = toDefaultDesignType(serviceRequirements);

    const newDesignEditor = {
      key,
      domId: key,
      design: { 
        locationA: toAddress(locationA), 
        locationZ: (!locationZ || getInternetType(designType)) ? null : toAddress(locationZ),
        designType,
        designItems: [
          toLocationDesignItem(locationA, { isLocationA: true })
        ],
      }
    };

    this.setState({ designEditors: [...designEditors, newDesignEditor] }, () => {
      const el = document.getElementById(key);
      el && el.scrollIntoView({ behavior: "smooth" });
    });
  }

  onSendDesignNotifications() {
    const { id, page } = this.state;

    this.setState({ isLoading: true, newDesigns: [] });

    sendQuoteNotifications(id).then(({ error }) => {
      if (error) {
        alert(`Unable to send notifications: ${error}`);
        return this.setState({ isLoading: false });
      }

      getQuoteDesigns(id, page).then(this.updateQuoteDesigns);
      this.setState({ hasUnsentNotifications: false });
    });
  }

  toggleShowMarginPct() {
    this.setState({ showMarginPct: !this.state.showMarginPct });
  }

  async onRequestPricing() {
    this.setState({ requestingPricing: true });
    await requestManualPricing(this.state.id);
    window.location.reload();
  }


  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.id) {
      return getInitialState(props);
    }

    const hasNewRequirements = (!Object.keys(state.requirements).length && !!Object.keys(props.requirements).length);    
    if (props.status !== state.status || hasNewRequirements) {
      return Object.assign({}, state, props);
    }

    if (props.focusedDesignId && props.focusedDesignId !== state.focusedDesignId) {
      return Object.assign({}, state, { focusedDesignId: props.focusedDesignId });
    }

    return null;
  }

  componentDidMount() {
    getOrganization(this.props.user.organization_id).then(resp => {
      this.setState({ orgRequiresOrderManager: resp.requires_order_manager })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const donePricing = (
    //   !!this.state.status && prevState.status !== this.state.status
    //   && STATUSES_WITHOUT_DESIGNS.includes(prevState.status)
    //   && !STATUSES_WITHOUT_DESIGNS.includes(this.state.status)
    // ) || 
    
      (prevProps.isPricing === null) || (prevProps.isPricing === true && this.props.isPricing === false)
    );

    // Poll for new designs every 10 seconds
    const pollForNewDesigns = (
      !donePricing
      && this.props.pollingDuration !== prevProps.pollingDuration
      && this.props.pollingDuration % 10 === 0
    );

    if (!!this.state.id && (donePricing || pollForNewDesigns)) {
      // (donePricing)
      //   ? console.log('Quote has completed rate engine pricing.')
      //   : console.log(`Polling for new designs after waiting ${this.props.pollingDuration} seconds...`);

      getQuoteDesigns(this.state.id, this.state.page).then(this.updateQuoteDesigns);
    }

    if (this.state.focusedDesignId && this.state.focusedDesignId !== prevState.focusedDesignId) {
      const containsFocusedDesign = this.state.designs.find(x => x.id === this.state.focusedDesignId);

      const scroller = () => {
        const { designs, focusedDesignId } = this.state;

        const expandFocusedDesign = designs.map(design => (
          design.id !== focusedDesignId ? design : { ...design, isExpanded: true }
        ));

        this.setState({ designs: expandFocusedDesign }, () => {
          const domEl = document.getElementById(`design_${this.state.focusedDesignId}`);
          domEl && setTimeout(() => {
            domEl.scrollIntoView({ behavior: 'smooth' })
            const resetIsExpandedDesign = designs.map(design => (
              design.id !== focusedDesignId ? design : { ...design, isExpanded: undefined }
            ));
            this.setState({ designs: resetIsExpandedDesign });
          }, 200);
        });
      };

      const refetchDesigns = () => {
        this.setState({ isLoading: true });
        getQuoteDesigns(this.state.id, this.state.page)
          .then(this.updateQuoteDesigns)
          .then(scroller);
      }

      containsFocusedDesign ? scroller() : refetchDesigns();
    }
  }

  render() {
    const { 
      id, quoteRef, isLoading, isPricing, status, 
      designs=[], newDesigns=[], designEditors=[], requirements={}, 
      page, pageCount, pageSize, totalItems, 
      requestingPricing, orgRequiresOrderManager, hasUnsentNotifications, showMarginPct, 
    } = this.state;

    const { locationA, locationZ, extendedDemarc } = requirements;
    const { user = {} } = this.props;
    const isPricingDeskUser = !!user.is_pricing_desk;
    const isManualPricing = MANUAL_STATUSES.includes(status);
    const hasDesignItems = !!designs?.[0]?.designItems;
    const hasBroadbandDesigns = designs?.some(x => x.designType === "broadband-internet");
    const quoteIsLeastCost = !locationZ && !getInternetServiceRequirements(requirements.serviceRequirements);
    const allowAddDesigns = (!isPricing && !isLoading && !!isPricingDeskUser && status !== 'no-bid');
    const noBidMessage = requirements.statusDetails || 'No designs returned by the Rate Engine.';

    const orderManagerRequiredMsg = (orgRequiresOrderManager && !user.is_order_manager && 'The ability to order has been restricted by your administrator. For questions, please contact connectivity@unitasglobal.com');
    const inReviewMsg = status === 'review' && 'Cannot order while quote is still under review.'

    const viewDesignItems = user.is_internal || (hasDesignItems && user.hasPermission(PERMISSIONS.VIEW_DESIGN_ITEMS));

    const QuoteDesign = viewDesignItems ? DesignRollup : Design;

    const loadingMessage = 
      (isPricing) ? "We're pricing your quote" : 
      (isLoading) ? "We're getting your quote information" : false;

    // * Our system was unable to price this quote. Click below if you would like to have our pricing desk manually review and price this quote.

    const orderingDisabled = (
      !! isLoading 
      || !!isPricing 
      || ORDER_LOCKED_STATUS.includes(status)
      || inReviewMsg 
      || orderManagerRequiredMsg
    );

    const designProps = {
      user,
      quoteCurrency: requirements.currency,
      quoteId: id,
      quoteOrgName: requirements.orgName, 
      quoteOrgId: +apiUrlIdStripper(requirements?.partnerUrl) || requirements.orgId,
      quoteRef,
      quoteStatus: status,
      quoteServiceRequirement: requirements.serviceRequirements,
      quoteIsLeastCost,
      quoteOwnerId: +apiUrlIdStripper(requirements.userUrl || ''),
      orgRequiresOrderManager,
      partnerType: requirements.partnerType,
      partnerName: requirements.partnerName,
      partnerId: requirements.partnerId,
      locationA,
      locationZ,
      extendedDemarc,
      bandwidth: requirements.bandwidth,
      port: requirements.port,
      term: requirements.term,
      currency: requirements.currency,
      onSave: this.onSave,
      onCopy: this.onCopy,
      orderingDisabled,
      specification: requirements.specId,
      showMarginPct,
      toggleShowMarginPct: this.toggleShowMarginPct,
    };

    const designEditorProps = {
      ...designProps,
      leadTime: requirements.leadTime,
      onCancel: this.onCancel
    };

    return (
      <div className="quote-designs">
        {(!!isPricing && !!designs.length) && (
          <Notification.Warning className="quote-designs__notification--still-pricing">
            <span>This result may not be the lowest cost. Please wait as the system is still finding the lowest-cost solution.</span>
            <LoadingIcon />
          </Notification.Warning>
        )}

        {(!!hasBroadbandDesigns) && (
          <Notification.Info className="quote-designs__notification--broadband">
            Please note, broadband service availability and speed is only guaranteed after order placement and confirmation from underlying provider.
          </Notification.Info>
        )}

        {/* NOT PRICED YET AND NO DESIGNS */}
        {(!!isLoading || !!isPricing) && !designs.length && status !== 'no-bid' && (
          <div className="pricing-quote-container">
            <JumboLoadingIcon />
            <div className="pricing-quote-headline">{loadingMessage}</div>
            <div>{isPricing && "This may take a few minutes. We'll send you an email when it's ready."}</div>
          </div>
        )}

        { !!hasUnsentNotifications && isPricingDeskUser && (
          <div className="quote-designs__unsent-designs-message">
            <div>There are unsent design changes</div>
            <Button className="quote-designs__unsent-designs-button" type="link" icon="envelope" children="Send Email Update" onClick={this.onSendDesignNotifications} disabled={isLoading} />
          </div>
        )}
        
        {/* Manual Pricing */}
        {(!isLoading && isManualPricing) && (
          <ManualPricing status={status} locationA={locationA} locationZ={locationZ} quoteRef={quoteRef} requirements={requirements} />
        )}

        {(!!designs.length) &&
          // SOLUTION SETS
          <div className="solutions-sets">
            {(designs || []).map((design) => (
              <QuoteDesign domId={`design_${design.id}`} isExpanded={design.isExpanded} design={design} key={design.reference} {...designProps} />
            ))}

            <div className="pager-row">
              <PagerStatus page={page} pageSize={pageSize} totalItems={totalItems} />
              <Pager pageCount={pageCount} initialPage={page} onPageClick={this.handlePageClick} isLoading={isLoading} />
            </div>
          </div>
        }

        {/* NEWLY CREATED AND SAVED DESIGNS  */}
        { !isLoading && !isPricing && !!newDesigns.length && (
          <div className="new-designs">
            <div className="new-designs__header">Newly added</div>
            {/* MAP TO DESIGN ITEMS */}
            {newDesigns.map(design => (
              <QuoteDesign domId={`design_${design.id}`} isExpanded={design.isExpanded} design={design} key={design.reference} {...designProps} />
            ))}
          </div>
        )}

        {/* DESIGN EDITORS */}
        {!!allowAddDesigns && (
          <div className="design-editors">
            { designEditors.map(({key, design}) => <DesignEditor domId={design.id ? `design_${design.id}` : key} key={key} design={design} designEditorId={key} {...designEditorProps} />)}

            {/* ADD DESIGN BUTTON */}
            <div style={{textAlign: 'center'}}>
              <Button className="design-editors__button--add-design" secondary onClick={this.onClickAddDesign}>
                <Icon name="add-circle-outline" size="large" /> Add Design
              </Button>
            </div>
          </div>
        )}

        {/* NO BID */}
        {(status === 'no-bid') && (
          <div className="quote-designs__no-bid">
            <Notification.Info>{noBidMessage}</Notification.Info>

            {!!user.is_internal && (
              <p>
                <Button onClick={this.onRequestPricing} disabled={requestingPricing}>Request Manual Pricing</Button>
              </p>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default withUserContext(QuoteDesigns);

import queryString from 'query-string';
//import { getBearerToken } from 'Auth/AuthService.js';
import { getBearerToken } from 'Utils/auth.js';

let authRedirectTimer;

const fetchRequest = async ({ url, method, body, options={} }) => {
  const { html, unauth, signal, acceptHeader } = options;

  (!url.endsWith('/')) && console.warn('Did you forget to add a "/" to the end of your url?');
  
  const hasFormData = (body instanceof FormData);
  const headers = {
    [`Accept-Type`]: 'application/json',
    'mode': 'no-cors'
  };

  if (acceptHeader) headers.Accept = acceptHeader;
 
  if (!unauth) {
    const accessToken = await getBearerToken();

    if (!accessToken) {
      //debugger;
      return handleUnauthRequest();
    }

    headers[`Authorization`] = 'Bearer ' + accessToken;
  }

  if (!hasFormData) {
    headers[`Content-Type`] = 'application/json';
  }

  if (html) {
    headers[`Content-Type`] = 'text/html';
  }

  const request = { headers, method, signal };
  
  if (body) {
    if (method === 'GET') {
      // NOTE: This will remove parameters with undefined values
      url = `${ url.trim('/') }?${ queryString.stringify(body) }`;
    }
    else {
      request.body = hasFormData ? body : JSON.stringify(body);
    }
  }

  return fetch(url, request);
};

const handleBlob = (resp) => {
  if (resp && resp.status === 200) {
    return resp.blob();
  }
  throw Error('error');
};

const handleJson = (resp) => {
  const { ok, status, statusText } = resp;

  if (ok) return status === 204 ? {} : resp.json();

  return (status >= 500)
    ? { error: statusText, status, statusText }
    : resp.json().then(error => ({ error, status, statusText }));
};

const handleUnauthRequest = (message) => {
  const { pathname, search } = window.location;
  const qs = queryString.stringify({ msg: message, redirectUri: pathname + search });

  window.location.href = `/logout?${qs}`;
};

const handleServerError = ({ error={}, status }) => {
  if (status === 401 && !authRedirectTimer) {
    const { detail } = error;
    let message;

    if (detail && detail.includes('Unrecognized organization')) {
      message = `Unrecognized organization. Please contact a system administrator.`;
    }
    else if (detail && detail.includes('Access not assigned to user')) {
      message = `Access not assigned to user. Please contact a system administrator.`;
    }
    else {
      message = 'Session token expired. Please log back in to continue...';
    }
    
    authRedirectTimer = setTimeout(() => { authRedirectTimer = null }, 5000);
    return handleUnauthRequest(message);
  }

  if (status >= 500) {
    alert('Sorry! Looks like something went wrong on our end. Please try again in a bit.');
  }
}

const handleThrownError = error => {
  console.error(error);
  return { error };
}

const downloadBlob = (blob, fileName) => {
  // Create link to url 
  const a = document.createElement("a");
  const url = window.URL.createObjectURL(blob);

  // Setup link
  a.style = "display: none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);

  // Click link
  a.click();

  // Cleanup
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 500);
}

const getJson = ({ url, body, method, options={} }) => {
  return fetchRequest({ url, body, method, options })
    .then(handleJson)
    .then(json => {
      if (json.error && !options.ignoreErrors) {
        handleServerError(json);
      }

      console.log(json);
      return json;
    })
    .catch(handleThrownError);
}

const getHtml = ({ url, body, method, options={} }) => {
  return fetchRequest({ url, body, method, options })
    .then(resp => resp.text())
    .catch(handleThrownError);
}

export function GET(url, body, options={}) { return options.html
  ? getHtml({ url, body, method: 'GET', options })
  : getJson({ url, body, method: 'GET', options }); 
}

export function POST(url, body, options={}) { return getJson({url, body, method: 'POST', options}); }

export function PUT(url, body)  { return getJson({url, body, method: 'PUT'}); }

export function PATCH(url, body)  { return getJson({url, body, method: 'PATCH'}); }

export function DELETE(url, body)  { return fetchRequest({ url, body, method: 'DELETE' }); }

export function UPLOAD(url, body) {
  return getJson({ url, body, method: 'POST'});
}

// export function downloader(url, fileName) {
//   return fetchRequest({url, method: 'POST'})
//     .then(handleBlob)
//     .then(blob => downloadBlob(blob, fileName))
//     .catch(handleThrownError);
// }

export function DOWNLOAD(url, fileName, body={}, method='POST') {
  return fetchRequest({ url, method, body })
    .then(handleBlob)
    .then(blob => downloadBlob(blob, fileName))
    .catch(handleThrownError);
}

import React from 'react'
import { Button } from 'Atoms';
import { useHistory } from 'react-router-dom';

import './NotificationArea.scss';

const NotificationArea = () => {
  const history = useHistory();
  const onClick = () => history.push('/comments/');

  return (
    <div className="notification-area">
      <Button className="notification-area__button" type="inline" onClick={onClick} icon="comments" title="View Quote Comments" />
      <div className="notification-area__window">
        
      </div>
    </div>
  )
}

export default NotificationArea
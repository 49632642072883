import React, { Component } from 'react'

import { Link } from 'react-router-dom';
import { Popup } from 'Molecules';

import { deleteQuote, deleteFolder, deleteQuoteError, deleteAttachment } from 'Actions/ccm/api.js';

import './DeleteModal.scss';

class DeleteModal extends Component {
  constructor() {
    super();

    this.state = {
      error: null,
      deniedQuotes: [],
      isFetching: false
    };

    this.deleteItem = this.deleteItem.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  deleteItem(event) {
    const { id, quoteId, type, onConfirm, name } = this.props;

    const callback = (resp) => (resp.error) ? this.handleError(resp) : onConfirm(resp);

    event.preventDefault();
    event.stopPropagation();

    if (type === 'quote') {
      this.setState({ isFetching: true });
      return deleteQuote(id).then(callback);
    }

    if (type === 'folder') {
      this.setState({ isFetching: true });
      return deleteFolder(id).then(callback);
    }

    if (type === 'import-error') {
      this.setState({ isFetching: true });
      return deleteQuoteError(id).then(() => callback({ name }));
    }

    if (type === 'file-attachment') {
      this.setState({ isFetching: true });
      return deleteAttachment(quoteId, id).then(callback);
    }
  }

  handleError(resp) {
    const { type } = this.props;

    // Get combined denied quotes from error response if any
    const deniedQuotes = [
      ...(resp.error.transition_denied_quotes || []), 
      ...(resp.error.permission_denied_quotes || [])
    ];

    const error = (
      (type === 'folder' && resp.status === 400)
        ? 'The following quotes can not be deleted and will prevent the ability to delete this folder.' :
      (type === 'quote' && resp.status === 400) 
        ? 'This quote can not be deleted because it is in a status that does not permit deletion.'
        : Object.values(resp.error)
    );

    this.setState({ error, deniedQuotes, isFetching: false })
  }

  render() {
    const { error, deniedQuotes=[], isFetching } = this.state;
    const { name, type, onClose, message='Deleting this item is permanent and can not be undone. Please confirm below.' } = this.props;

    const yesButton = { label: 'Yes, delete', onClick: this.deleteItem, primary: true, disabled: isFetching || !!error };
    const noButton = { label: 'No, do not delete', onClick: onClose };

    return (
      <Popup
        label={`Delete this ${type}?`}
        onClose={onClose}
        button1={noButton}
        button2={yesButton}
        id="delete-item-popup"
      >
        <div>
          <b>{name}</b>
          <br />
          <br />
          <div>{message}</div>
        </div>

        { !!error && <div className='delete-item-modal__error'>{error}</div> }

        {/* TODO: REMOVE TABLE HTML */}
        { !!deniedQuotes.length && (
          <div className="delete-item-modal__denied-quotes">
            <table className="sortable-table">
              <thead>
                <tr>
                  <th>NAME</th>
                  <th>REFERENCE</th>
                </tr>
              </thead>
              <tbody>
                { deniedQuotes.map(({ id, name, reference }) => (
                  <tr key={id}>
                    <td><Link to={`/quote/${reference}`}>{name}</Link></td>
                    <td>{reference}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Popup>
    )
  }
}

export default DeleteModal;
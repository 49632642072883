import { getStateCode, toAddress } from 'Utils/formatter.js';
import { sortByLabel } from 'Utils/array.js';

const toCityState = (city, state) => [city, state].filter(x=>!!x).join(', ');

export const toColoProviderOption = (label) => {
  return { 
    label, 
    value: label, 
    colo: 'provider', 
    className: 'react-select__option-text--next' 
  };
}

export const toColoRegionOption = (label, countryCode) => {
  return { 
    label, 
    value: label, 
    colo: 'region',
    countryCode,
    className: 'react-select__option-text--next' 
  };
}

export const toColoLocationOption = (colo) => {
  const { popName, address, addressLabel, city, state, countryCode, id, url, region, name } = colo;
  const cityState = toCityState(city, state);

  return { 
    label: `${popName}, ${addressLabel}`,
    value: id+'',
    colo: 'id',
    icon: 'data-center',
    iconClassName: 'address-option__icon--pop',
    url, 
    data_center_url: url, 
    dataCenterId: id,
    address,
    addressLabel: addressLabel,
    cityState,
    city,
    state,
    countryCode,
    region,
    name,
    popName,
  };
};

// export const toAutoCompleteOption = (suggestion) => {
//   const { countryCode, locationId, address } = suggestion;
//   const { houseNumber, street, city, state } = address;
//   const cityState = toCityState(city, state);

//   return { 
//     label: `${houseNumber} ${street}, ${cityState}`,
//     value: locationId,
//     address1: `${houseNumber} ${street}`,
//     cityState,
//     countryCode,
//     country_alpha_3: countryCode,
//     ...address 
//   };
// }

// export const toValidateAddressOption = (address) => {
//   if (!address || address.error) return null;

//   const { city, state, country_alpha_3, street, street_number } = address;
//   const stateCode = (state && country_alpha_3 === 'USA') ? getStateCode(state) : state;
//   const cityState = toCityState(city, stateCode);
//   const label = `${street_number} ${street}, ${cityState}`;

//   return {
//     label,
//     value: label,
//     address1: `${street_number} ${street}`,
//     cityState,
//     countryCode: country_alpha_3,
//     ...address
//   };
// }

export const toSiteCodeOption = (result) => {
  const { datacenter_id, owner, country_alpha_3='USA',
    provider_location_formatted_address,
    provider_location_formatted_address_no_suite_floor,
    provider_location_name,
    site_code,
    ug_location_name
  } = result;

  const url = `/ccm/api/v3/data-centers/${datacenter_id}/`;
  const popName = `${owner} ${site_code || provider_location_name}`;

  return {
    optionType: 'siteCode',
    popName,
    icon: 'data-center',
    iconClassName: 'address-option__icon--pop',
    label: `${popName}, ${provider_location_formatted_address}`,
    value: { url, data_center_url: url },
    addressLabel: provider_location_formatted_address, 
    countryCode: country_alpha_3,
    isValidated: true,
    result
  };
}

export const toRecentLocationOptions = results => {
  return !results && results.length ? [] : [{
    label: 'Recently Used Locations',
    icon: 'history',
    options: results.map(result => {
      const address = toAddress(result);
      const { popName, displayName, line1, line2, id, data_center_url } = address;

      return { 
        popName,
        addressLabel: popName ? [line1, line2].join(', ') : displayName,
        label: displayName,
        icon: popName ? 'data-center' : 'location',
        iconClassName: `address-option__icon--${popName ? 'pop' : 'address'}`,
        value: { id, data_center_url },
        isValidated: true,
        optionType: 'recent-location',
        className: `address-option__recent-location`,
        ...result,
        additional_details: {
          autocomplete_selection: result.display_name || '???'
        }
      }
    })
  }]
}

export const toSiteCodeOptions = (results, userOrg) => {
  const isUnitas = org => org.indexOf('Unitas Global') > -1;

  const owner = [], unitas = [], other = [];

  results.forEach(result => {
    const ownerName = result.owner;
    
    const group = (
      (ownerName === userOrg) ? owner : (isUnitas(ownerName)) ? unitas : other
    );
    
    group.push(toSiteCodeOption(result));
  });

  return [
    ...owner.sort(sortByLabel),
    ...unitas.sort(sortByLabel),
    ...other.sort(sortByLabel),
  ]
}

export const toAutocompleteOption = result => {
  const { provider_location_name, description, identifier, additional_details, id_kind } = result;
  const popName = provider_location_name;
  const label = [ popName, description ].filter(Boolean).join(', ');
  const icon = popName ? 'data-center' : 'location';
  const validationId = `@${id_kind}:${identifier}`;

  return {
    label,
    value: validationId,
    icon,
    iconClassName: `address-option__icon--${popName ? 'pop' : 'address'}`,
    popName,
    additional_details,
    validationId,
  }
}

export const toLatLongOptions = results => [{
  isLatLong: true,
  label: 'Nearby Locations',
  options: results?.map(r => ({
    label: r.formatted_address,
    value: { id: r.id },
    icon: r.kind === 'end_site' || r.kind === 'known' ? 'location' : 'data-center',
    distance: Math.round(r.distance),
    isLatLong: true,
  }))
}];
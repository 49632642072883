import React, { Fragment, useEffect, useState } from 'react';
import { Icon, LoadingOverlay, Timeline } from 'Atoms';

import { getAuditLog } from 'Actions/ccm/api.js';
import { formatDate } from 'Utils/formatter.js';

import './AdminAudit.scss';

const AdminAudit = ({ organizationId }) => {
  const [ isFetching, setIsFetching ] = useState();
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    const toTimelineItem = ({ id, timestamp, differences_reading, user_name, object_type, message, action_type }) => ({
      description: (
        <div>{
          (!differences_reading.length) ? (
            <div><Icon big name={
              action_type === 'creation' ? 'add' :
              action_type === 'password_reset' ? 'reset-password' :
              'exclamation-point'
            }/>{message.charAt(0).toUpperCase() + message.slice(1)}</div>
          ) : 
          differences_reading.map((diff, i) => {
            const icon = (
              (diff.endsWith('added') || diff.endsWith('enabled'))
                ? { name: 'add' } :
              (diff.endsWith('removed') || diff.endsWith('disabled'))
                ? { name: 'subtract' } 
                : { name: 'pencil' }
            );
            return (
              <div key={i}>
                <Icon big {...icon} />
                {diff}{object_type === 'user' ? ` for ${message}` : ''}
              </div>
          )}) 
        }</div>
      ),
      label: <>{formatDate(timestamp)} by <b>{user_name}</b></>,
      dot: <Icon name={object_type === 'user' ? 'user' : 'settings-filled'} />,
      key: id,
    })

    const fetchData = async () => {
      setIsFetching(true);
      const { results } = await getAuditLog({ organization_id: organizationId, page_size: 1000 });

      setItems(results.map(toTimelineItem))
      setIsFetching(false);
    }

    fetchData();
  }, [organizationId]);

  return (
    <div className="admin-audit">
      <h4>Audit Log</h4>
      {isFetching 
        ? <LoadingOverlay />
        : <Timeline items={items} />
      }
    </div>
  );

}

export default AdminAudit;
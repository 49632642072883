import React from 'react';
import { Icon, RadioButtonGroup } from 'Atoms';
import { INTERNAL_COMPANY_NAME } from 'Utils/branding.js';

import './VisibilityToggle.scss';

const VisibilityToggle = props => {
  const { isInternal, onChange } = props;

  const options = [
    { value: 1, label: <Icon name="cloud" /> }, 
    { value: 0, label: <Icon name="internet" /> },
  ];

  const value = isInternal ? 1 : 0;
  const label = isInternal 
    ? `Viewable only by ${INTERNAL_COMPANY_NAME} users`
    : 'Viewable by all users';

  return (
    <div className="visibility-toggle">
      <RadioButtonGroup value={value} options={options} onChange={onChange} />
      <div className="visibility-toggle__label">{label}</div>
    </div>
  )
}

export default VisibilityToggle;
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthServiceProvider from '../Auth/AuthProvider.jsx';
import AppRouter from 'Routers/AppRouter.jsx';
import store from '../../store';
import { HelmetProvider } from 'react-helmet-async';


import 'Sass/base.scss';

const App = ({ msalInstance }) => {
  return (
    <HelmetProvider>
      <Router>
        <AuthServiceProvider msalInstance={msalInstance}>
          <ReduxProvider store={store}>
            <AppRouter />
          </ReduxProvider>
        </AuthServiceProvider>
      </Router>
    </HelmetProvider>
  )
}

export default App;
import React, { createRef, Component } from 'react'
import CN from 'classnames';
import SortableTable from 'Atoms/Tables/SortableTable.jsx';
import { withUserContext } from 'Context/UserContext.js';
import { AsyncQuoteTooltip, Icon, LoadingOverlay, Pager, PagerStatus, Notification, StatusIcon, TimeAgo, Tooltip } from 'Atoms';
import { Link } from 'react-router-dom';

import { getDesigns } from 'Actions/ccm/api.js';

import './PricingDesk.scss';

const COLUMN_DATA = [
  {
    key: 'status',
    header: ' ',
    render: ({ status, statusDetails }) => {
      return (
        <Tooltip title={statusDetails}>
          <div>
            <Icon className="pricing-desk__status-icon" name="request-review" />
            {/* <Icon className="pricing-desk__status-icon" name={name} /> */}
          </div>
        </Tooltip>
      )
    },
    sortable: false
  },

  { key: 'name', render: data => {
    const { networkEngineer, pricingDesk, csa } = data.validation;
    const unapproved = [
      !pricingDesk && 'Pricing',
      !networkEngineer && 'Engineering',
      !csa && 'CSA', 
    ].filter(Boolean).join(', ');

    return (
      <>
        <AsyncQuoteTooltip id={data.quoteId}>
          <Link to={`/quote/${data.quoteRef}/`}>{data.name}</Link>
        </AsyncQuoteTooltip>
        <div className="pricing-desk__quote-reference">Design Reference: {data.reference}</div>
        <div className="order-validation-table__missing-approvals">
          <RejectedDetails label="Engineering" details={networkEngineer} />
          <RejectedDetails label="CSA" details={csa} />
          <UnapprovedDetails label={unapproved} />
        </div>
      </>
      )
    }
  },
  //   { key: 'validation',
  //   header: 'Required validation',
  //   render: ({ validation:{networkEngineer, pricingDesk, csa }}) => {
  //     const missingValidations = [
  //       !networkEngineer && <Icon name="settings-circle-outline" />, 
  //       !pricingDesk && <Icon name="other-charges" />,
  //       !csa && <Icon name="pencil" />,
  //     ].filter(Boolean);

  // },
  { key: 'folder'},
  { keys: ['user', 'organization'] },
  { keys: ['updatedTime','createdTime'],
    headers: { updatedTime: 'Last Modified', createdTime: 'Date Created' },
    render: (data, { activeKey, inactiveKey }) => {
      const isSame = data.updatedTime === data.createdTime;
      const secondary = activeKey === 'updatedTime' ? 'Created ' : 'Modified ';

      return (<>
        <div className="sortable_table_column_data--primary"><TimeAgo date={data[activeKey]} /></div>
        {!isSame && (
          <div className="sortable_table_column_data--secondary">{secondary}<TimeAgo date={data[inactiveKey]} /></div>
        )}
      </>)
    }
  },
  // { keys: ['assigned', 'followupCategory'],
  //   headers: { assigned: 'Assigned To', followupCategory: 'Followup' },
  // },
  // {
  //   key: 'actions',
  //   header: ' ',
  //   sortable: false,
  //   render: ({ icon, ...data }) => <ContextMenuButton type={icon} {...data} />
  // }
];


class OrderValidationTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      rowData: [],
      page: props.page || 1,
      pageSize: props.pageSize || 10,
      ordering: props.ordering || '-modified_time',
      total: 0,
      results: 0,
      hasNext: false,
      hasPrev: false,
      isLoading: false
    };

    this.fetchData = this.fetchData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.onSort = this.onSort.bind(this);

    this.tableRef = createRef();
  }

  fetchData(options = {}) {
    const { page, pageSize, ordering } = this.state;
    const { org, unapproved, region, service } = (this.props.filter || {});

    const approvalDetails = (
      !unapproved 
        ? {} :
      (unapproved === 'pricing_desk')
        ? { pricing_desk_actionable: true } 
        : { [`${unapproved}_approved`]: unapproved ? false : undefined,
            [`${unapproved}_rejected`]: unapproved ? false : undefined,
          }
    );

    const request = { 
      page, 
      page_size: pageSize,
      ordering,
      design_status: 'design-validation',
      organization_id: org || undefined,
      state_name: region?.length === 2 ? region : undefined,
      country_alpha_3: region?.length === 3 ? region : undefined,
      location_type_key: region === 'unvalidated' ? region : undefined,
      service_type_key: service,
      ...approvalDetails,
      ...options,
    };

    this.setState({ isLoading: true });
    getDesigns(request).then(({ error=null, count, results=[] }) => {
      this.props.onSelect && this.tableRef.current && this.tableRef.current.clearSelected();

      //    className: `sortable-table-row--${status === 'review' ? 'review' : status === 'design-validation' ? 'validation' : 'manual'}`,

      (!!error && request.page !== 1)
        ? this.fetchData({ ...options, page: 1 })
        : this.setState({ 
          error,
          page: request.page,
          pageSize,
          ordering: request.ordering,
          totalItems: count,
          pageCount: Math.ceil(count/pageSize),
          rowData: results,
          isLoading: false
        }, () => { this.props.setCount?.(count) });
    });
  }

  // updateRow(update) {
  //   const rowIndex = this.state.rowData.findIndex(row => row.id === update.id);
  //   if (rowIndex > -1) {
  //     const rowData = [ ...this.state.rowData ];
  //     rowData[rowIndex] = toRowData(update);
  //     this.setState({ rowData });
  //   }
  // }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps) return;
    const prev = (prevProps.filter || {});
    const { org, unapproved, region, service } = (this.props.filter || {});

    if ((org !== prev.org)
      || (unapproved !== prev.unapproved) 
      || (region !== prev.region) 
      || (service !== prev.service)
    ) {
      this.fetchData({ page: 1 })
    }
  }

  handlePageClick({ selected }) {
    if (this.state.isLoading) return;

    this.fetchData({ page: selected + 1 });
  }

  onSort(name) {
    const columnName = (
      (name === 'createdTime') ? 'create_time' :
      (name === 'updatedTime') ? 'modified_time' :
      (name === 'user') ? 'user__name' :
      (name === 'organization') ? 'organization__name' :
      (name === 'folder') ? 'folder__name' : 
      (name === 'assigned') ? 'assignee_name' :
      (name === 'followupCategory') ? 'follow_up_category' : name
    );
    const sortDirection = (this.state.ordering === columnName) ? '-' : '';
    const ordering = sortDirection + columnName;

    this.fetchData({ ordering });
    return ordering;
  }

  render() {
    const { rowData=[], error, page, pageSize, pageCount, totalItems, isLoading } = this.state;
    const { user, filter={} } = this.props;
    const hasNoRows = !isLoading && !rowData.length;
    const columnData = user.is_internal ? COLUMN_DATA : COLUMN_DATA.filter(c => c.key !== 'assigned');
    const className = CN('pricing-desk__table pricing-desk__orders-table', {
      [`pricing-desk__orders-table--${filter.unapproved}_unapproved`]: filter.unapproved,
    });

    const emptyMessage = 'You have no designs requiring validation.';

    return (
      <div className="pricing-desk">
        {(hasNoRows) 
          ? <Notification.Info children={emptyMessage} />
          : <SortableTable className={className} columnData={columnData} rowData={rowData} ref={this.tableRef} onSort={this.onSort} /> 
        }
        {!!isLoading && <LoadingOverlay />}
        <div className="pager-row">
          <PagerStatus page={page} pageSize={pageSize} totalItems={totalItems} />
          <Pager pageCount={pageCount} initialPage={page} onPageClick={this.handlePageClick} isLoading={isLoading} />
        </div>
      </div>
    )
  }
}

const UnapprovedDetails = ({ label, details={} }) => {
  return (details.status) 
    ? null
    : <StatusIcon status="design-validation" text={label} className="approval-detail approval-detail--unapproved" />
};

const RejectedDetails = ({ label, details={} }) => {
  const { user_name, timestamp, reason } = details;

  if (details.status !== 'rejected') return null;
  
  const tooltip = <div>{user_name} <TimeAgo date={timestamp} />:<br />{reason}</div>;

  return (
    <Tooltip title={tooltip} >
      <b><StatusIcon status="error" text={label} className="approval-detail approval-detail--rejected" /></b>
    </Tooltip>
  );
}

export default withUserContext(OrderValidationTable);

import React, { Component, useContext } from 'react';
import { withAuthContext } from "Context/AuthContext.js";
import UserContext from 'Context/UserContext.js';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { Icon, SupportLinks } from 'Atoms';
import { isEcxContext, isPFContext } from 'Utils/auth.js';
import { COMPANY_LEGAL_NAME } from 'Utils/branding.js';
import { PERMISSIONS, SUPPORT_EMAILS } from 'Utils/const.js';

import './sidebar.scss';

class SidebarButton extends Component {
  constructor(props) {
    super(props);

    this.state = { collapsed: true };

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown() {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  }

  render() {
    const {
      disabled,
      location,
      name,
      selected,
      title,
      subpaths,
      onClick
    } = this.props;

    const { collapsed } = this.state;

    const calculatedHeight = (1 + (subpaths ? subpaths.length : 0)) * 3.75 + 1;
    const maxHeight = (collapsed ? calculatedHeight : 3.75) + 'rem';
    const className = {
      section: classNames('sidebar-section', { collapsed }),
      button: classNames('sidebar-button', { selected, disabled })
    };

    return (
      <div className={className.section} style={{ maxHeight }}>
        <Link className={className.button} to={disabled ? '#' : location} title={title} onClick={onClick}>
          <Icon name={name} className="sidebar-icon" />
          <span className="title">{title}</span>
        </Link>

        {/* {!disabled && subpaths &&
          <div className={classNames('control', { collapsed })} onClick={this.toggleDropdown}>
            <Icon name="caret-down" title={collapsed ? 'Collapse' : 'Expand'}/>
          </div>
        }
        {!disabled && subpaths &&
          subpaths.map(subpath => (
            <Link
              key={subpath.location}
              className={classNames('sidebar-button', 'subroute', { disabled: subpath.disabled })}
              to={!subpath.disabled && `${location}/${subpath.location}`}
              title={subpath.title}
            >
              <span className="title">{subpath.title}</span>
            </Link>
          ))
        } */}
      </div>
    );
  }
}

const Sidebar = props => {
  const { expanded, toggleSidebar, logout } = props;
  const { pathname='' } = useLocation();
  const user = useContext(UserContext) || {};
  const isEcx = isEcxContext() || user.isEcx;
  const isPF = isPFContext() || user.isPF;
  const buildInfo = `Build: ${process.env.CI_PIPELINE_ID} (${process.env.BUILD_TIME})`;
  const year = new Date().getFullYear();

  const selected = {
    admin: pathname.startsWith('/admin'),
    home: (pathname === '/' || pathname.startsWith('/dashboard')),
    pricingDesk: pathname.startsWith('/pricing-desk'),
    quoteManager: pathname.startsWith('/quote-manager'),
    requestQuote: pathname.startsWith('/request-quote'),
    rateTesting: pathname.startsWith('/rate-testing'),
    systemDiagnostics: pathname.startsWith('/diagnostics'),
  };

  let pricingEmail = undefined;
  let technicalEmail = undefined;

  if (isEcx) {
    pricingEmail = SUPPORT_EMAILS.EQUINIX_FABRIC;
    technicalEmail = SUPPORT_EMAILS.EQUINIX_FABRIC;
  }
  else if (isPF) {
    pricingEmail = SUPPORT_EMAILS.PACKETFABRIC;
    technicalEmail = SUPPORT_EMAILS.PACKETFABRIC;
  }

  const emailLinks = {
    pricingEmail: { to: pricingEmail },
    technicalEmail: { to: technicalEmail },
  };

  const logoutProps = { location: "\logout", onClick: logout };

  return (
    <div className={classNames('sidebar no-select', { expanded })} aria-hidden={!expanded}>
      <SidebarButton title="Home" name="home" selected={selected.home} location="/" onClick={toggleSidebar} />
      { !isEcx && !isPF && (
        <SidebarButton title="Pricing Desk" name="exclamation-point-circle-outline" selected={selected.pricingDesk} location="/pricing-desk" onClick={toggleSidebar} />
      )}
      <SidebarButton title="Quote Manager" name="folder" selected={selected.quoteManager} location="/quote-manager" onClick={toggleSidebar} />
      { !isEcx && !isPF && (
        <SidebarButton title="Request a Quote" name="quote" selected={selected.requestQuote} location="/request-quote" onClick={toggleSidebar} />
      )}
      { user.is_developer && (
        <SidebarButton title="Login POC" name="data-center" location="/login-poc" onClick={toggleSidebar} />
      )}
      { user.is_developer && (
        <SidebarButton title="Rate Testing" name="file-spreadsheet" selected={selected.rateTesting} location="/rate-testing" onClick={toggleSidebar} />
      )}
      { user.hasPermission(PERMISSIONS.VIEW_SYSTEM_DIAGNOSTICS) && (
        <SidebarButton title="System Diagnostics" name="cloud-performance" selected={selected.systemDiagnostics} location="/diagnostics" onClick={toggleSidebar} />
      )}
      { user.is_admin && (
        <SidebarButton title="Admin Dashboard" name="manage" selected={selected.admin} location="/admin" onClick={toggleSidebar} />
      )}

      <SidebarButton title="Sign out" name="sign-out" {...logoutProps} />

      <div className="sidebar-footer">
        <div className="colophon">

          <div className="sidebar-support">
            <div className="sidebar-support-icon">
              <Icon name="support" />
            </div>
            <div className="sidebar-support-links">
              <SupportLinks.Pricing {...emailLinks.pricingEmail} />
              <SupportLinks.Technical {...emailLinks.technicalEmail} />
            </div>
          </div>

          <p title={buildInfo}>&copy; {year} {COMPANY_LEGAL_NAME}</p>
          
          <div className="sidebar-footer-legal-links">
            <a href="/usage-and-privacy" target="_blank">Acceptable Use & Privacy Policy</a>
            <a href="/terms-and-conditions" target="_blank">Terms of Service</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuthContext(Sidebar);

import React from "react";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router-dom";

import { Button } from "Atoms";
import { isEcxContext } from 'Utils/auth.js';
import { SUPPORT_EMAILS } from 'Utils/const.js';

import { DefSvgFullColorLogo, DefFavicon } from 'Utils/branding.js';
import UnexpectedErrorImage from "Static/images/unexpected-error.svg";

import "./error_pages.scss";

const UnexpectedErrorPage = ({ location }) => {
  let mailTo = SUPPORT_EMAILS.UNITAS_GLOBAL;
  if (isEcxContext()) mailTo = SUPPORT_EMAILS.EQUINIX_FABRIC;
  if (isPFContext()) mailTo = SUPPORT_EMAILS.PACKETFABRIC;

  return (
    <div className="container">
      <Helmet>
        <body className="error-page unexpected-error-page" />
        <link rel={DefFaviconName} type="image/png" href={DefFavicon} />
      </Helmet>

      <div className="header patterned">
        <img src={UnexpectedErrorImage} alt="404" className="banner" />
        <h1>Unexpected Error</h1>
      </div>

      <div className="message">
        <p>
          We know this isn't what you expected to happen and we apologize. Our
          system is experiencing technical issues which we are working to resolve.
        </p>
        <div className="flex-columns nav-buttons">
          <a href={location.pathname}>
            <Button type="transparent">
              Reload the page
            </Button>
          </a>
          <a href={`mailto:${mailTo}`}>
            <Button type="transparent">Report Issue</Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default withRouter(UnexpectedErrorPage);

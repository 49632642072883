import React, { PureComponent, createRef, useEffect, useMemo, useState } from 'react';
import CN from 'classnames';
import startCase from 'lodash/startCase';
import { Button, ContextMenuButton, CurrencyInput, PercentageInput, InfoIcon, Icon, LabelledInput, TextInput, Tooltip } from 'Atoms';
import { BandwidthLookup, PortLookup, Lookup, LocationCombo } from 'Molecules';
import ModalNewProvider from './ModalNewProvider.jsx';
import { getOrganizations } from 'Actions/ccm/api.js';
import { useUserContext } from 'Context/UserContext.js';
import { AddExternalCostViewButton, ExternalCostView } from './';
import { toCurrency, getTermValue } from 'Utils/formatter.js';
import { getLabels, PERMISSIONS } from 'Utils/const.js';
import { useModal } from 'Hooks';
import {
  getDesignItemTypeConfig,
  sortProviderOptions,
  toMarginPercentage, 
  getRowClassName,
  DEFINED_PRODUCT_LIST,
  PROXIMITY_OPTIONS,
} from './DesignItemRowHelper.js';
import { isSameLocation } from '../../../QuoteViewerMapper.js';

import './DesignItemRow.scss';

const ORG_SEES_CARRIER_COSTS = ['SungardAS', '365 Data Centers'];

export const DesignItemRow = (props) => {
  const { type, mrc, mrr, mrv, nrc, nrr, nrv, externalMrc, externalNrc, 
    vendorName, vendorProduct, vendorReference, 
    locationA, locationZ, type2Vendor,
    port, bandwidth, upload, term, 
    displayCurrency, proximityA, proximityZ, 
    description, features={}, commission, lineTitle, 
  } = props;

  const user = useUserContext();
  const featureItems = Object.entries(features);
  const vendorTooltip = !!user.is_internal && (vendorReference || featureItems.length) && (<>
    {featureItems.map(([key, val]) => 
      <div key={key}>{`${startCase(key)}: ${val}`}</div> 
    )}
    {!!vendorReference && (
      <div>Vendor Reference: {vendorReference}</div> 
    )}
  </>);

  const { label, icon, isLink, isNode } = getDesignItemTypeConfig(type);
  const isInternal = (mrv !== undefined) && (mrv !== null);
  const showLocationIds = user.is_developer && 0;
  const locationIds = locationA && ` (${locationA.id || 'unvalidated'}${
    !isSameLocation(locationA, locationZ) ? ' ➔ ' + (locationZ && locationZ.id || 'unvalidated') : ''
  })`;
  const termLabel = getTermValue(term);
  const hasExternalCost = [externalMrc, externalNrc].some(n => !isNaN(n) && n !== null);
  const uploadSpeed = !upload ? 0 : upload >= 1000 ? `${upload/1000} Gbps` : `${upload} Mbps`;
  const demarcIncluded = (type === 'extended-demarc' && Number(mrr) === 0 && Number(nrr) === 0);
  const title = label || lineTitle;
  const rowClassName = getRowClassName({ ...props, isNode, isLink });

  const showingVendorMrcTooltip = !!Number(props.vendor_mrc) && !isInternal && user.hasPermission(PERMISSIONS.VIEW_VENDOR_COSTS);

  const num = n => Number(n) || 0;

  const marginAmt = num(mrc) - num(props.vendor_mrc);
  const marginPct = !num(props.vendor_mrc) ? 0 : (100 * (marginAmt)/mrc);

  const VendorCostTooltipTitle = showingVendorMrcTooltip && (
    <>
      <div>{`Vendor cost: ${toCurrency(props.vendor_mrc, displayCurrency)}`}</div>
      <div>{`(${Math.round(marginPct)}% Margin)`}</div>
    </>
  );

  const classNames = {
    mrc: CN('design-item-mrc', { 'design-item-mrc--has-vendor-cost': showingVendorMrcTooltip }),
    mrcTooltip: 'tooltip design-item-mrc__tooltip',
  }

  return (
    <div className={rowClassName}>
      <div className="design-item-group design-item-group--type">
        {!!(type || lineTitle) && (
          <>
            <div className="design-item-row__icon">
              {!!icon && (
                <DesignItemIcon icon={icon} locationA={locationA} proximityA={proximityA} locationZ={locationZ} proximityZ={proximityZ} />
              )}
            </div>

            <div className="design-item-row__type">
              <div className="design-item-row__title" style={{ position: 'relative' }}>
                {title ? `${title}: ` : ''}
                <span>{showLocationIds ? locationIds : ''}</span>
              </div>
              <div>{vendorName}</div>
              {!!vendorProduct && (
                <div>
                  {vendorProduct} 
                  {!!vendorTooltip && (
                    <Tooltip title={vendorTooltip}>
                      <span className="vendor-reference-icon"><InfoIcon /></span>
                    </Tooltip>
                  )}
                </div>
              )}
              {!!type2Vendor && isInternal && (
                <div className="design-item-row__type2-vendor">({type2Vendor})</div> 
              )}
              {!!description && (
                <div className="design-item__description">{description}</div>
              )}
              {!!demarcIncluded && (
                <div className="design-item__included-demarc">*Included in connectivity provider costs</div>
              )}
            </div>
          </>
        )}
      </div>
      
      <div className="design-item-row__specifications">
        <div className="design-item-row__specifications-row">
          <div className="design-item-group design-item-group--speeds">
            {(!uploadSpeed || !bandwidth) ? (
              <div>{bandwidth}</div>
            ) : (
              <div className="design-item--broadband-bandwidth">
                <div title="Download speed">{bandwidth} <Icon name="arrow-down-small" /></div>
                <div title="Upload speed">{uploadSpeed} <Icon name="arrow-up-small" /></div>
              </div>
            )}
            <div>{port}</div>
            <div className="design-item--term">{term}</div>
          </div>

          <div className="design-item-group design-item-group--pricing">
            <div className="price-margin">
              <Tooltip overlayClassName={classNames.mrcTooltip} title={VendorCostTooltipTitle}>
                <div className={classNames.mrc}>{commission ? '' : toCurrency(mrc, displayCurrency)}</div>
              </Tooltip>
            </div>
          </div>

          <div className="design-item-group design-item-group--pricing">
            <div className="price-margin">
              <div>{commission ? '' : toCurrency(nrc, displayCurrency)}</div>
            </div>

          </div>
        </div>
        {!!(hasExternalCost) && <ExternalCostView {...props} /> }
      </div>
    </div>
  )
}

export class DesignItemRowWithInputs extends PureComponent {
  constructor(props) {
    super(props);

    const { type, vendorName, vendorProduct, vendorUrl } = props;

    const selectedOption = (
      (DEFINED_PRODUCT_LIST[type] || []).includes(vendorProduct)
        ? { label: vendorProduct, value: vendorProduct } : 
      (vendorName && vendorUrl) 
        ? { label: vendorName, value: vendorUrl } 
        : undefined
    );
    
    this.lookupRef = createRef();

    this.state = {
      options: [],
      selectedOption,
      modal: null,
    }

    this.onAddProvider = this.onAddProvider.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onLookupChange = this.onLookupChange.bind(this);
    // this.getOptionValue = this.getOptionValue.bind(this);
  }

  onAddProvider({ name, id }) {
    const selectedOption = { label: name, value: id };

    this.props.onChange.lookup(selectedOption);
    this.setState({ selectedOption, options: [selectedOption, ...this.state.options], modal: null });
  }

  onCloseModal() {
    this.setState({ modal: null });
  }

  onLookupChange(option) {
    const { type, rowKey, onDelete, onAdd, onChange, rowIndex } = this.props;
    this.setState({ selectedOption: option });

    // TODO: SWAP ROW ADD ROW to fix race condition since useState is async and used for onDelete and onAdd functions
    (type === 'other' && !['other', 'expedite-charge'].includes(option.value))
      ? onDelete(rowKey, 
        () => onAdd(option, option.value === 'class-of-service' ? rowIndex : undefined)
      )
      : onChange.lookup(option);
  }

  componentDidMount() {
    const { selectedOption } = this.state;
    const { type, isEditable=true } = this.props;

    const definedOptions = DEFINED_PRODUCT_LIST[type];

    // Static options
    if (definedOptions) {
      const mappedOptions = definedOptions.map((opt, i) => typeof opt === 'object' ? opt : ({ label: opt, value: i+"" }));
      this.setState({ options: mappedOptions });
    }

    // async options
    else {
      getOrganizations({ providerType: type })
        .then(sortProviderOptions)
        .then(options => {
          const foundSelected = selectedOption && options.find(x => x.value === selectedOption.value);
          const selected = foundSelected || (options.length === 1 ? options[0] : undefined);

          this.setState({ options, selectedOption: selected });
      });
    }
  }

  render() {
    const { type, options={}, description, onChange={}, isEditable=false, onMove, onDelete, rowKey, vendorProduct, designType, upload, currency, allowRowActions=true, externalMrc, externalNrc, quoteOrgName, mrr, nrr, locationA, locationZ, proximityA, proximityZ, error, isNodeStart, isNodeEnd, partnerName, isNew } = this.props;
    const { selectedOption, modal } = this.state;
    const disabled = (!isEditable) || undefined;

    const hasExternalCost = [externalMrc, externalNrc].some(n => !isNaN(n) && n !== null);
    const canSeeCarrierCost = ORG_SEES_CARRIER_COSTS.some(x => quoteOrgName === x || partnerName === x);

    const canAddExternalCost = (canSeeCarrierCost && !hasExternalCost && type === 'provider-connection');
    const confirmDelete = !!(!isNew && mrr && nrr);

    const showingProductField = (!['cross-connect', 'network-interface-device', 'class-of-service', 'port', 'extended-demarc', 'other', 'ipv4-addresses'].includes(type))
      || (type === 'other' && !!selectedOption && selectedOption.label === 'Other...');
    const showNewProviderModal = () => this.setState({ modal: 'newProvider' });
    const showingUpload = (['provider-connection', 'totals'].includes(type) && designType === 'broadband-internet');
    const canAddNewProvider = ['cross-connect', 'provider-connection', 'sd-wan', 'network-allocation', 'ipv4-addresses'].includes(type);

    const { label, icon, isLink, isNode } = getDesignItemTypeConfig(type);
    const rowClassName = getRowClassName({ ...this.props, isNode, isLink, isEditing: true });
    const disableMoveUp = isNode && isNodeStart || (this.props.disableMoveUp);
    const disableMoveDown = isNode && isNodeEnd || (this.props.disableMoveDown);

    const lookupProps = {
      label,
      ref: this.lookupRef,
      onChange: this.onLookupChange,
      options: this.state.options,
      isLoading: !this.state.options.length,
      // isDisabled: !!disabled,
      name:`${rowKey}_lookup`,
      value: selectedOption,
      noOptionsMessage: () => '',
      components: !canAddNewProvider ? undefined : { 
        MenuListFooter: <div className="react-select__option react-select__option--add-provider" role="option" tabIndex="-1" onClick={showNewProviderModal}><Icon name="add-circle-filled" />New provider</div>
      },
      error: error?.vendor_url,
    };

    const inputProps = (propName) => ({
      value: this.props[propName],
      onChange: onChange[propName] || (() => {}),
      required: true,
      disabled,
      readOnly: !!disabled,
      currency
    });

    const dropdownProps = (optionType) => {
      const val = this.props[optionType];
      return {
        isSearchable: true,
        options: options[optionType],
        onChange: onChange[optionType],
        label: null, 
        components: { DropdownIndicator: null },
        isDisabled: !isEditable,
        name: `${rowKey}_${optionType}`,
        id: `${rowKey}_${optionType}`,
        value: (typeof val === 'object') ? val : (typeof val === 'string') ? { label: val, value: val } : val
      }
    };

    return (
      <div className={rowClassName}>
        <div className="design-item-group design-item-group--type">
          {(!!type && type !== 'totals') && (
            <>
              <div className="design-item-row__icon">
                {!!icon && (
                  <DesignItemIcon icon={icon} locationA={locationA} proximityA={proximityA} locationZ={locationZ} proximityZ={proximityZ} />
                )}
              </div>

              <div className="design-item-row__type">
                <Lookup {...lookupProps} />
                { !!showingProductField && (
                  <ProductInput type={type} vendor={selectedOption} value={vendorProduct} onChange={onChange.product} vendorProducts={options.products} rowKey={rowKey} />
                )}
                {type === 'ipv4-addresses' && (
                  <Lookup {...dropdownProps('ipv4')} label="IPv4 Addresses" className="design-item-row__product-input" />
                )}
                {(type === 'provider-connection') && (
                  <div className="design-item-row__proximity-inputs">
                    <Lookup {...dropdownProps('proximityA')} label="Proximity A" options={PROXIMITY_OPTIONS} isClearable />
                    <Lookup {...dropdownProps('proximityZ')} label="Proximity Z" options={PROXIMITY_OPTIONS} isClearable />
                  </div>
                )}
              {!!description && (
                <div className="design-item__description">{description}</div>
              )}
              </div>

              {(modal === 'newProvider') && 
                <ModalNewProvider onAdd={this.onAddProvider} onClose={this.onCloseModal} type={type} />
              }
            </>
          )}
        </div>
        <div className="design-item-row__specifications">
          <div className="design-item-row__specifications-row">
            <div className="design-item-group design-item-group--speeds">
              { !showingUpload ? ( 
                <BandwidthLookup {...dropdownProps('bandwidth')} />
                ) : (
                <div className="design-item-group__bandwidth--with-upload">
                  <div className="flex-row centered">
                    <BandwidthLookup {...dropdownProps('bandwidth')} />
                    <Icon name="arrow-down-small" />
                  </div>
                  <div className="flex-row centered">
                    <LabelledInput className="design-item__upload" name="upstream_bandwidth" onChange={onChange.upload} value={upload} label="Mbps" />
                    <Icon name="arrow-up-small" />
                  </div>
                </div>
              )}
              <PortLookup {...dropdownProps('port')} />
              <Lookup {...dropdownProps('term')} />
            </div>

            <div className="design-item-group design-item-group--pricing">
              <div className="price-margin">
                <CurrencyInput {...inputProps('mrc')} />
              </div>
            </div>

            <div className="design-item-group design-item-group--pricing">
              <div className="price-margin">
                <CurrencyInput {...inputProps('nrc')} />
              </div>
            </div>
          </div>
          {!!hasExternalCost && (
            <ExternalCostView {...this.props} />
          )}
          {!!canAddExternalCost && (
            <AddExternalCostViewButton {...this.props} />
          )}
        </div>

        {(!!allowRowActions) && (
          <RowActionButtons rowKey={rowKey} onMove={onMove} onDelete={onDelete} disableMoveUp={disableMoveUp} disableMoveDown={disableMoveDown} confirmDelete={confirmDelete} />
        )}
      </div>
    )
  }
}

export const DesignItemLocationRow = React.memo((props) => {
  const user = useUserContext();

  const { address, onChange=()=>{}, onDelete, onMove, rowKey, rowIndex,
    isEditable=false, includeHubs=true, colos=[], 
    label="To:", defaultValue, value, internetType, 
    extendedDemarc, error, isEditing, menuId,
    isNodeStart, isNodeEnd,
  } = props;

  const { floor, suite, buildingName, popName, line1, line2, id, latLong } = (address || {});
  
  const allowRowActions = !!(onMove && onDelete);

  const icon = internetType ? 'internet' : 'location';
  const addressLabel = [ popName, line1, line2 ].filter(Boolean).join(', ');
  const intraBuildingLabel = [
    buildingName && `Building ${buildingName}`,
    floor && `Floor ${floor}`,
    suite && `Suite ${suite}`,
  ].filter(Boolean).join(', ');

  const showLatLong = user.hasPermission(PERMISSIONS.VIEW_LATLONG) && !!latLong;

  const className = CN('design-item-row design-item-row--location design-item-row--node', {
    'design-item-row--node-start': isNodeStart,
    'design-item-row--node-end': isNodeEnd,
  });

  return (
    <div className={className} key={`design_row_${rowKey}`}>
      <div className="design-item-group design-item-group--type">
        {!isEditable && (
          <div className="design-item-row__icon">
            <Icon name={icon} width="1em" height="1.6em" />
          </div>
        )}
        <div className="design-item-row__type">
          {(isEditable)
            ? <LocationCombo propKey={`${rowKey}_lookup`} label={label} icon={null} onChange={onChange} name={`${rowKey}_lookup`} colos={colos} defaultValue={defaultValue} value={value} error={error} includeHubs={includeHubs} />
            : (internetType) ? (
              <div className="design-item-row__location-label--internet">
                <b>Internet</b>
                <div>{ internetType }</div>
              </div>
            ) : (
              <div className="design-item-row__location-label--readonly">
                {/* <div className="design-item-row__title">{label}</div> */}
                <div>
                  {addressLabel}
                  {/* {user.is_developer && (
                    <span> ({id || 'unvalidated'})</span>
                  )} */}
                  {!!showLatLong && (
                    <div className="design-item-row__location-label-latlong">{latLong}</div>
                  )}
                </div>
                {(user.is_internal || extendedDemarc) && !!intraBuildingLabel && (
                  <div className="location-field--intrabuilding">{intraBuildingLabel}</div>
                )}
              </div>
            )
          }
        </div>
        {!!isEditing && !internetType && (
          <ContextMenuButton 
            className="design-item-row__add-item-button"
            icon="add-circle-outline" 
            menuId={menuId}
            data={{ rowIndex }} />
        )}
      </div>
      {!!allowRowActions && (
        <RowActionButtons rowKey={rowKey} onMove={onMove} onDelete={onDelete} confirmDelete={false} />
      )}
    </div>
  )
});

export const DesignItemLabelsRow = (props) => {
  const { children=null, className="", showingRate=false, showingEndUserRate=false, showingBandwidth=true, isInternal=false } = props;
  const user = useUserContext();
  const labels = getLabels(user);

  // const showingInternalAmounts = user.hasPermission(PERMISSIONS.VIEW_INTERNAL_AMOUNTS);

  return (
    <div className={`design-item-row design-item-row--labels ${className}`}>
      <div className="design-item-group design-item-group--type">
        { children }
      </div>
      <div className="design-item-group design-item-group--speeds">
        {!!showingBandwidth && <div>Bandwidth</div> }
        {!!showingBandwidth && <div>Port Speed</div> }
        {!!showingBandwidth && <div className="design-item--term" style={{ whiteSpace: 'nowrap' }}>Vendor Term</div>}
      </div>
      {(showingEndUserRate || isInternal) ? (
      <>
        <div className="design-item-group design-item-group--pricing">
          <div className="price-margin">{labels.mrc}</div>
        </div>
        <div className="design-item-group design-item-group--pricing">
          <div className="price-margin">{labels.nrc}</div>
       </div>
      </>
      ) : (
      <>
        <div className="design-item-group design-item-group--pricing">
          <div className="price-margin">MRC</div> 
            {!!showingRate && (
              <div className="price-margin">MRR</div>
            )}
          </div>
        <div className="design-item-group design-item-group--pricing">
          <div className="price-margin">NRC</div>
          {!!showingRate && (
            <div className="price-margin">NRR</div>
          )}
        </div>
      </>
      )}

      <div className="design-item-group design-item-group--actions">
        {/* actions */}
      </div>
    </div>
  );
}

const ConnectivityIcon = ({ locationA, proximityA, locationZ, proximityZ }) => {
  let className = 'connectivity-icon ';
  
  const showProximity = proximityA || proximityZ;
  const tooltip = showProximity && (
    <>
      { !!proximityA && locationA && <div>{proximityA} from {locationA && locationA.city}</div> }
      { !!proximityZ && locationZ && <div>{proximityZ} to {locationZ && locationZ.city}</div> }
    </>
  );

  if (proximityA && proximityZ && proximityA !== proximityZ) {
    className += `connectivity-icon--${proximityA} connectivity-icon--loc-z-${proximityZ}`;
  } else if (showProximity) {
    className += `connectivity-icon--${proximityA || proximityZ}`;
  }

  return (
    <Tooltip placement="topLeft" title={tooltip}>
      <span className={className} />
    </Tooltip>
  );
}

const DesignItemIcon = ({ icon, ...connectityProps }) => (
  !icon ? null : (icon === 'proximity') 
    ? <ConnectivityIcon {...connectityProps} />
    : <Icon name={icon} />
);

const ProductInput = props => {
  const { rowKey, type, onChange, vendor, vendorProducts, value, disabled } = props;
  const [ options, setOptions ] = useState([]);

  useEffect(() => {
    if (vendorProducts) {
      const toOption = label => ({ label, value: label });
      const products = vendorProducts?.[vendor?.label] || [];

      setOptions(products.map(toOption));
    }
  }, [vendor, vendorProducts]);

  const inputProps = {
    label: 'Vendor Product',
    className:"design-item-row__product-input",
    value,
    readOnly: !!disabled,
    onChange,
    name: `product_${rowKey}`,
  };

  const lookupProps = (type === 'provider-connection') && {
    ...props,
    label: 'Vendor Product',
    className: 'design-item-row__product-input',
    options,
    isClearable: true,
    components: { ClearIndicator: null },
    onChange: opt => onChange({ currentTarget: { value: opt?.label || null }}),
    value: value ? { value, label: value } : undefined,
    formatCreateLabel: label => label
  };

  return (type === 'provider-connection'
    ? <Lookup {...lookupProps} createable />
    : <TextInput {...inputProps} /> 
  );
}

const RowActionButtons = ({ rowKey, onMove, onDelete, disableMoveUp, disableMoveDown, confirmDelete }) => {
  const disableArrows = disableMoveUp && disableMoveDown;
  const moveUp = () => onMove(rowKey, 'up');
  const moveDown = () => onMove(rowKey, 'down');
  const onDeleteRow = (preservePrice) => onDelete({ rowKey, preservePrice });

  const classNames = {
    actions: CN('design-item__actions', { 'design-item__actions--arrows-disabled': disableArrows }),
    moveUp: CN('design-item__action--move-up', { 'design-item__action--disabled': disableMoveUp }),
    moveDown: CN('design-item__action--move-down', { 'design-item__action--disabled': disableMoveDown }),
  }

  return (
    <div className="design-item-group design-item-group--actions">
      <div className={classNames.actions}>
        {!disableArrows && (
          <Button type="inline" icon="arrow-up-large" className={classNames.moveUp} onClick={moveUp} />
        )}
        <DeleteRowButton onDelete={onDeleteRow} enableConfirm={confirmDelete} />
        {!disableArrows && (
          <Button type="inline" icon="arrow-down-large" className={classNames.moveDown} onClick={moveDown} />
        )}
      </div>
    </div>
  )
}

const DeleteRowButton = props => {
  const { enableConfirm, onDelete } = props;
  const { showModal, MODALS } = useModal();
  
  const onClick = () => {
    enableConfirm 
      ? showModal(MODALS.DELETE_DESIGN_ITEM, { onDelete })()
      : onDelete({ preservePrice: false })
  }

  return (
    <Button type="inline" icon="subtract-circle-filled" className="design-item__action--delete" onClick={onClick} />
  )
}
import React from 'react';
import { Popup } from 'Molecules';

const DeleteDesignItemModal = (props) => {
  const { onClose, onDelete } = props;

  const onConfirm = preservePrice => {
    onDelete(preservePrice);
    onClose();
  }

  const popupProps = {
    label: 'Delete Design Item',
    id: 'select-users-popup',
    onClose,
    button1: { label: 'Keep Customer Price', onClick: () => onConfirm(1), primary: true },
    button2: { label: 'Adjust Customer Price', onClick: () => onConfirm(0), primary: true },
    button3: { label: 'Cancel', onClick: onClose },
  };

  return (
    <Popup {...popupProps} >
      Would you like to <b>keep</b> the quoted customer price or <b>adjust</b> the customer price to reflect the removed item?
    </Popup>
  )
}

export default DeleteDesignItemModal;

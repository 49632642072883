import CN from 'classnames';
import { toPercentage, getMargin } from 'Utils/formatter.js';
import { sortByLabel } from 'Utils/array.js';

export const DESIGN_ITEM_TYPES = {
  'provider-connection': {
    label: 'Connectivity Provider',
    icon: 'proximity',
    isLink: true,
    value: 'provider-connection',
  },
  'class-of-service': {
    label: 'Class of Service',
    icon: 'class-of-service',
    isNode: true,
    products: [
      'COS Best Effort',
      'COS Priority',
      'COS Standard',
      'COS Premium',
    ],
    value: 'class-of-service',
  },
  'cross-connect': {
    label: 'Cross Connect',
    icon: 'cross-connect',
    isNode: true,
    value: 'cross-connect',
  },
  'extended-demarc': {
    label: 'Extended Demarc',
    icon: 'extended-demarc',
    isNode: true,
    value: 'extended-demarc',
  },
  'network-allocation': {
    label: 'Network Allocation',
    icon: 'network-allocation',
    isNode: true,
    value: 'network-allocation',
  },
  'network-interface-device': {
    label: 'Network Interface Device',
    icon: 'nid',
    isNode: true,
    products: [
      'Unitas Default NID', 
      'Unitas Default Copper NID', 
      'Unitas Default Fiber NID',
    ],
    value: 'network-interface-device'
  },
  'out-of-band': {
    label: 'Out of Band',
    icon: 'out-of-band',
    isNode: true,
    value: 'out-of-band',
  },  
  'port': {
    label: 'Port Charge',
    icon: 'port',
    isNode: true,
    value: 'port',
  },
  'other': {
    label: 'Other Charges',
    icon: 'other-charges',
    isNode: true,
    value: 'other',
  },
  'router': {
    label: 'Router',
    icon: 'router',
    isNode: true,
    value: 'router',
  },
  'royalty': {
    label: 'Royalty',
    isLink: true,
    value: 'royalty',
    icon: 'royalty',
  },
  'market-rate-adjustment': {
    label: 'Market Rate Adjustment',
    isLink: true,
    value: 'market-rate-adjustment',
    icon: 'least-cost',
  },
  'sd-wan': {
    label: 'SD-WAN',
    icon: 'sd-wan',
    isNode: true,
    value: 'sd-wan',
  },
  'ipv4-addresses': {
    label: 'IPv4 Address Provider',
    icon: 'port',
    isLink: true,
    value: 'ipv4-addresses',
  }
};

export const DEFINED_PRODUCT_LIST = {
  'class-of-service': [
    'COS Best Effort', 
    'COS Priority', 
    'COS Standard', 
    'COS Premium'
  ],
  'network-interface-device': [
    'Unitas Default NID', 
    'Unitas Default Copper NID', 
    'Unitas Default Fiber NID'
  ],
  'other': [
    { label: 'Class of Service', value: 'class-of-service' },
    { label: 'Expedite Charge', value: 'expedite-charge' },
    { label: 'Extended Demarc', value: 'extended-demarc' },
    { label: 'Other...', value: 'other' },
  ],
};

export const PROXIMITY_OPTIONS = [
  { label: 'On-Net', value: 'On-Net'},
  { label: 'Near-Net', value: 'Near-Net'},
  { label: 'Off-Net', value: 'Off-Net'}
];

export const ADD_LINK_OPTIONS = [
  { label: 'Connectivity Provider', value: 'provider-connection', icon: 'route' },
  { label: 'Location', value: 'location', icon: 'location' },
  { label: 'IPv4 Addresses', value: 'ipv4-addresses', icon: 'port' },
  { label: 'Royalty', value: 'royalty', icon: DESIGN_ITEM_TYPES['royalty'].icon },
];

export const ADD_ITEM_AT_LOCATION_MENU = [
  'cross-connect', 
  'network-interface-device', 
  'network-allocation', 
  'extended-demarc', 
  'out-of-band', 
  'port', 
  'router', 
  'sd-wan', 
  'other'
];

export const getDesignItemTypeConfig = type => (DESIGN_ITEM_TYPES[type] || {});

export const getTypeLabel = (type) => (type && (DESIGN_ITEM_TYPES[type] || {}).label) || '';

export const toMarginPercentage = (price, cost) => {
  const val = getMargin(price, cost);
  return !val ? '0%' : toPercentage(val);
}

export const sortProviderOptions = ({ results=[{}] }) => {
  // TODO: figure out why royalty filter was originally used...
  //const isRoyalty = (type === 'royalty');
  //.filter(x => !isRoyalty || x.label === 'Unitas Global');

  const options = results
    .map(({ name, url }) => ({ label: name, value: url }))
    .sort(sortByLabel);
  
  // Set Unitas as first option
  const unitasOptionIndex = options.findIndex(({ label }) => label === 'Unitas Global');
  if (unitasOptionIndex > -1) {
    options.splice(0, 0, options.splice(unitasOptionIndex, 1)[0]);
  }

  return options;
}

export const calculateItemTotals = (items=[]) => {  
  const isLegSpeed = type => ['provider-connection', 'cross-connect'].includes(type);
  const minSpeed = (items=[{}]) => (items.filter(item => !!item && !!item.sortVal).sort((a, b) => (a.sortVal < b.sortVal) ? -1 : 1)[0] || {});
  const maxTerm = (items=[{}]) => (items.filter(item => !!item && !!item.value).sort((a, b) => (a.value > b.value) ? -1 : 1)[0] || {});
  const minUpload = items => items.filter(item => !!item).sort((a, b) => a < b ? -1 : 1).find(a => a > 0) || 0;

  const ports = [], bandwidths = [], uploads = [], terms = [];

  const filtered = items.filter(item => item.type !== 'location');
  const totals = filtered.reduce((total, item) => {
    const { mrc=0, mrr=0, mrv=0, nrc=0, nrr=0, nrv=0, externalMrc, externalNrc, port, bandwidth, upload, term, type } = item;

    total.mrc += Number(mrc);
    total.mrr += Number(mrr);
    total.mrv += Number(mrv);
    total.nrc += Number(nrc);
    total.nrr += Number(nrr);
    total.nrv += Number(nrv);
    total.externalMrc += Number((externalMrc === null || isNaN(externalMrc)) ? mrc : externalMrc);
    total.externalNrc += Number((externalNrc === null || isNaN(externalNrc)) ? nrc : externalNrc);

    if (isLegSpeed(type)) { 
      !!port && ports.push(port);
      !!bandwidth && bandwidths.push(bandwidth);
      !!upload && uploads.push(upload);
      !!term && terms.push(term);
    }

    return total;
  }, { mrc: 0, mrr: 0, mrv: 0, nrc: 0, nrr: 0, nrv: 0, externalMrc: 0, externalNrc: 0 });

  totals.port = minSpeed(ports);
  totals.bandwidth = minSpeed(bandwidths);
  totals.upload = minUpload(uploads);
  totals.term = maxTerm(terms);

  if (totals.externalMrc === totals.mrc && totals.externalNrc === totals.nrc) {
    totals.externalMrc = null;
    totals.externalNrc = null;
  }
  
  return totals;
}

export const getLocationValue = loc => {
  const nestedVal = key => loc?.value?.[key] || loc?.[key];

  return { 
    id: nestedVal('id'),
    data_center_url: nestedVal('data_center_url'),
    string_address: nestedVal('string_address'),
  };
}

export const getRowClassName = props => {
  const { className='', type, isNode, isNodeStart, isNodeEnd, isLink, isEditing, proximityA, proximityZ } = props;
  
  // TODO: only show if internal!!!
  const isOffNet = (proximityA === 'Off-Net' || proximityZ === 'Off-Net');

  return CN(`design-item-row design-item-row--${type} ${className}`, {
    'design-item-row--offnet': isOffNet,
    'design-item-row--node': isNode,
    'design-item-row--node-start': isNodeStart,
    'design-item-row--node-end': isNodeEnd,
    'design-item-row--link': isLink,
    'design-item-row--is-editing': isEditing,
  });

}
//import set from 'lodash/set';
import queryString from 'query-string';
import { capitalize, count } from 'Utils/strings.js';

const defaultDateFormat = {
  year: 'numeric', 
  month: 'short', 
  day: 'numeric', 
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short'
};

const dateOnlyFormat = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
};

const states = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AS': 'America Samoa',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District of Columbia',
  'FM': 'Micronesia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MH': 'Marshall Islands',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PW': 'Palau',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VI': 'Virgin Island',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
};

const stateAbbrLookup = Object.entries(states)
  .reduce((acc, [key, value]) => { 
    acc[value] = key;
    return acc;
  }, {});

export function formatDistance(distance) {
  return count(Math.ceil(distance), 'mile', 'miles', '-');
}

export function formatDate(date, format=defaultDateFormat) {
  return new Date(date).toLocaleDateString('en-US', format);
}

export function formatDateNoTime(date) {
  return formatDate(date, dateOnlyFormat);
}

function isoStringToDate(isoStringDate) {
  var b = isoStringDate.split(/\D+/);
  return new Date(Date.UTC(b[0], --b[1], b[2]));
}

function utcDateNow() {
    var now = new Date();
    return Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
}

export function isExpiredIsoStringDate(isoStringDate) {
    if ( !isoStringDate )
        return false;
    return isoStringToDate(isoStringDate) < utcDateNow();
}

export function formatPercentage(decimal) {
  const percent = (decimal * 100)
    .toFixed(2)
    .trim('0')
    .trim('.');

  return percent  + '%';
}

export function toCurrency(val=0, currency) {
  const formatted = new Intl.NumberFormat('en', { style: 'currency', currency: (currency || 'USD') }).format(val);
  return normalizeCurrencySymbol(formatted);
}

export function toCurrencyParts(val=0, currency) {
  return new Intl.NumberFormat('en', { style: 'currency', currency: (currency || 'USD') }).formatToParts(val);
}

export function toCurrencySymbol(code) {
  const parts = toCurrencyParts(0, code);
  const symbol = (parts && (parts.find(x => x.type === 'currency') || {}).value);
  return !symbol ? symbol : normalizeCurrencySymbol(symbol);
}

export function normalizeCurrencySymbol(currency) {
  return currency
    .replace('CA$', '$')
    .replace('A$', '$')
    .replace('HK$', '$')
    .replace('SGD', '$')
    .replace('ZAR', 'R')
}

export function toPercentage(val=0) {
  return new Intl.NumberFormat('en', { style: 'percent' }).format(val);
}

export function formatLocation(address) {
  const [line1, ...otherLines] = address.split(',');
  
  return line1 + '\n' + otherLines.join(',');
}

export function getMargin(price, cost) {
  return !price ? 0 : (1 - cost/price);
} 

export function getRate(cost, margin) {
  return (!(1 - margin) ? 0 : cost / (1 - margin));
}

export function getStateCode(state) {
  return stateAbbrLookup[state] || state;
}

export function getStateName(code) {
  return states[code] || code;
}

export function getQuoteStatus(status) {
  const quoteStatus = {
    'draft': { text: 'Draft', icon: 'warning-outline' },
    'review': { text: 'In Review', icon: 'warning-outline'},
    'expired': { text: 'Expired', icon: 'exclamation-point-circle-filled' },
    'pending': { text: 'Pricing', icon: 'exclamation-point-circle-outline' },
    'pricing': { text: 'Pricing', icon: 'exclamation-point-circle-outline' },
    'manual-pricing-needed': { text: 'Manual Pricing Required', icon: 'exclamation-point-circle-outline' },
    'manual': { text: 'Manual Pricing Required', icon: 'exclamation-point-circle-outline' },
    'error': { text: 'Manual Pricing Required', icon: 'exclamation-point-circle-outline' },
    'error-pricing': { text: 'Manual Pricing Required', icon: 'exclamation-point-circle-outline' },
    'validation-error': { text: 'Validation Error', icon: 'exclamation-point-circle-outline' },
    'manually-quoted': { text: 'Manually Quoted', icon: 'checkmark-circle-outline' },
    'no-bid': { text: 'No Bid', icon: 'add-circle-outline' },
    'edited': { text: 'Edited', icon: 'checkmark-circle-outline' },
    'quoted': { text: 'Quoted', icon: 'checkmark-circle-outline' },
    'ordering-draft-saved': { text: 'Ordering: Draft Saved', icon: 'checkmark-circle-filled' },
    'ordering-review': { text: 'Ordering: In Review', icon: 'checkmark-circle-filled' },
    'ordering-pending-approval': { text: 'Ordering: Pending Approval', icon: 'checkmark-circle-filled' },
    'ordering': { text: 'Ordering: In Review', icon: 'checkmark-circle-filled' },
    'design-validation': { text: 'Design Validation', icon: 'exclamation-point-circle-outline' },
    'ordered': { text: 'Ordered', icon: 'checkmark-circle-filled' },
    'manually-ordered': { text: 'Manually Ordered', icon: 'checkmark-circle-filled' },
    'closed': { text: 'Deleted', icon: 'trash' }
  };

  if (!quoteStatus[status]) {
    console.warn('Unrecognized status: ', status);
  }

  return quoteStatus[status];
}

export function conjunctionList(array, conjunction = 'and') {
  const length = (array && array.length) || 0;
  
  return (
    (length === 0) ? '' :
    (length === 1) ? array[0] :
    (length === 2) ? `${array[0]} ${conjunction} ${array[1]}` :
      `${array.slice(0, length - 1).join(', ')}, ${conjunction} ${array[length - 1]}`
  );
}

export function formatBytes(bytes, decimals = 1) {
  if (bytes === 0) return '0 bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getBandwidthLabel(key='', isPort) {
  const deriveLabel = (speed) => speed.replace('-m', ' M').replace('-g', ' G');

  const portKeys = {
    'fe10-mb': 'FE/10 Mbps',
    'fe100-mb': 'FE/100 Mbps',
    'gige': 'GigE',
    '10-gb': '10 GigE',
    '100-gb': '100 GigE',
    'least-cost': 'Least cost',
  };

  const bandwidthKeys = {
    'gige': '1 Gbps',
    '25-gbps': '2.5 Gbps',
    '10-gb': '10 Gbps',
    '100-gb': '100 Gbps',
    'ds1': 'DS1',
  };

  return (isPort ? portKeys[key] : bandwidthKeys[key]) || deriveLabel(key);
}

export function getTermLabel(term=[]) {
  const label = term.sort().join(', ');
  return label === '1' ? 'Month-to-Month' : label + ' Months';
}

export function getTermValue(term) {
  return term && [term?.replace(' Months', '')?.replace('Month-to-Month', '1')];
}

export const toAddress = (location) => {
  if (location === null) { return null; }

  const { display_name, skip_validation, string_address, street_number='', street, address2, city, country_alpha_3, id, location_type, postal_code, state, floor, suite, building_name='', data_center_url, additional_details, latitude, longitude } = location;
  let line1, line2, popName, floorSuite;

  if (string_address && !id) {
    const [split1, ...split2] = string_address.split(',');
    line1 = split1;
    line2 = split2.join(',');
  } else {
    popName = (display_name && data_center_url) ? display_name : '';
    // Add a PoP or Colo prefix to address line1 if the popName is showing as
    // "PoP" or "Colo".  The are specially shown by the api when the external
    // user is not able to see the display of this pop but we want to still
    // show it as a generic pop or colo.
    const genericNames = ["PoP", "Colo"];
    line1 = `${street_number || building_name} ${street}`.trim();
    if (genericNames.includes(popName)) {
      line1 = `${popName} at ${line1}`;
      popName = '';
    }
    line2 = [city, state, postal_code, country_alpha_3].filter(a=>!!a).join(', ');
    floorSuite = [
      floor && (floor.toLowerCase().indexOf('floor') > -1 ? floor : `Floor ${floor}`), 
      suite && (suite.toLowerCase().indexOf('suite') > -1 ? suite : `Suite ${suite}`)
    ].filter(x=>!!x).join(', ');
  }

  return {
    displayName: display_name,
    address1: line1,
    address2,
    city,
    country: country_alpha_3,
    id,
    locationType: location_type,
    data_center_url, 
    postalCode: postal_code,
    state,
    popName,
    line1,
    floorSuite,
    line2,
    addressLines: [ popName, line1, floorSuite, line2 ].filter(x=>!!x),
    skip_validation,
    string_address,
    floor,
    suite,
    buildingName: building_name,
    additional_details,
    latLong: latitude && longitude ? `Lat: ${latitude}, Long: ${longitude}` : '',
    latitude,
    longitude,
  };
};

export const mailTo = ({ to, cc, bcc, subject, body }) => {
  const qs = queryString.stringify({ cc, bcc, subject, body });
  const email = qs ? `?${qs}` : '';

  return `mailto:${to}${email}`;
};

export const getInternetServiceRequirements = req => {
  const TYPES = {
    'internet': 'Internet',
    'internet-broadband': 'Internet - Broadband', 
    'internet-dedicated-access': 'Internet - DIA',
    'internet-unitas-ip': 'Internet - Unitas Global IP',
  }

  return TYPES[req];
}

export const getServiceRequirements = req => {
  return getInternetServiceRequirements(req) || capitalize(req);
}

export const getFileIcon = (fileExtension) => {
  const imageList = ['.bmp', '.gif', '.jpeg', '.jpg', '.png', '.kmz'];
  const presentationList = ['.ppt', '.pot', '.pps', '.pptx', '.pptm', '.potx', '.odp'];
  const spreadsheetList = ['.odp', '.xls', '.xlt', '.xlm', '.xlsx', '.xlsm', '.xltx', '.xltm', '.ods', '.csv'];
  const documentList = ['.doc', '.dot', '.wbk', '.docx', '.docm', '.dotx', '.dotm', '.log', '.odt', '.txt', '.rtf'];
  const emailList = ['.eml', '.msg'];

  return (
    (fileExtension === '.vsd') ? 'file-diagram' :
    (fileExtension === '.pdf') ? 'file-pdf' :
    (emailList.includes(fileExtension)) ? 'file-email' :
    (imageList.includes(fileExtension)) ? 'file-image' :
    (presentationList.includes(fileExtension)) ? 'file-presentation' :
    (spreadsheetList.includes(fileExtension)) ? 'file-spreadsheet' :
    (documentList.includes(fileExtension)) ? 'file-document' :
    'missing'
  );
};

export const toDesignApprovers = (validation, status) => [
  (validation?.pricingDesk?.status === status) && "Pricing",
  (validation?.networkEngineer?.status == status) && "Engineering", 
  (validation?.csa?.status == status) && "CSA",
].filter(Boolean).join(', ');

const getIpv4Count = ip => {
  const lookup = {
    'cidr-30': 1,
    'cidr-29': 5,
    'cidr-28': 13,
    'cidr-27': 29,
    'cidr-26': 61,
    'cidr-25': 125,
    'cidr-24': 253,
  }

  return lookup[ip];
}

export const toIpv4Option = ({ value }) => {
  const ips = getIpv4Count(value);
  const label = ips ? `${ips} Usable IPs (${value.replace('cidr-', '/')})` : value;

  return { value, label }
}

export const toIpv4Value = label => {
  const split = label?.split('(/');
  return split?.length > 1 ? `cidr-${split[1].slice(0, -1)}` : ''
}
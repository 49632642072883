import React from 'react';
import Slider from 'antd/es/radio';

import "antd/lib/slider/style/index.css";
import style from './sliders.scss';

const CustomSlider = props => {
  return (
    <Slider
      marks={props.marks}
      defaultValue={0}
      
      />
  )
}

export default CustomSlider;
import React, { useEffect, useState, useRef } from 'react';
import CN from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'Context/UserContext.js';

import { Button, Notification, LoadingOverlay, TimeAgo } from 'Atoms';
import { MODALS, ModalManager } from 'Molecules';

import { OrderForm } from './OrderForms';

import { toOrderReview, toResumeOrder } from './QuoteOrderMapper.js';
import { orderQuote, rejectOrder, createQuoteOrderDraft, updateQuoteOrderDraft, deleteQuoteOrderDraft, acceptOrderReview } from 'Actions/ccm/api.js';
import { useAnalytics } from 'Hooks'
import { ECX_PORT_LABEL } from 'Utils/ecx.js';

import './QuoteOrder.scss';

const CUSTOMER_ORDERED_STATUS = ['ordered', 'design-validation'];

const QuoteOrder = props => {
  const { designId=props.id, designStatus, term, port, bandwidth, mrc, mrr, nrc, nrr, locationA, locationZ, product, designType='', formOptions={}, quote, savedDraft, hasOrderReview, docusignApprovers=[], fetchData, isFetching } = props;
  const [ isOrdering, setIsOrdering ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ savedOrder, setSavedOrder ] = useState(savedDraft);
  const [ showingSuccessNotification, setShowingSuccessNotification ] = useState(false);
  const [ showingErrorNotification, setShowingErrorNotification ] = useState(false);

  const { trackEvent } = useAnalytics();
  const history = useHistory();
  const modalRef = useRef();
  const formMethods = useForm(formOptions);
  const { reset, getValues } = formMethods;

  const redirect = () => history.push(`/quote/${quote.reference}`);

  const rehydrate = () => {
    fetchData();
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500);
  }

  const onSubmitDraft = formData => {
    setIsOrdering(true);
    trackEvent({ category: 'Submit Review', action: 'click' });
    onSubmitToReview(formData).then(resp => {
      setIsOrdering(false);
      if (resp.error) return alert('There was an error ordering your quote. Please contact support.');
      rehydrate();
    });
  };

  const onSubmitReview = () => {
    setIsOrdering(true);
    trackEvent({ category: 'Submit Order', action: 'click' });
    onSubmitToOrder().then(resp => {
      setIsOrdering(false);
      if (resp.error) return alert('There was an error ordering your quote. Please contact support.');
      rehydrate();

      if (hasOrderReview && !docusignApprovers.length) {
        modalRef.current.open(MODALS.ORDER_CONFIRMATION, { timeOrdered: resp.create_time, designType, onConfirm: redirect })
      }
    });
  };

  const saveDraft = () => {
    const formData = getValues({ nest: true });
    const request = toOrderReview({ designId, quote, formData });

    const save = () => (savedOrder 
      ? updateQuoteOrderDraft(quote.id, savedOrder.id, request)
      : createQuoteOrderDraft(quote.id, request)
    );

    setIsSaving(true);
    save().then(resp => {
      const saved = toResumeOrder(resp);

      setSavedOrder(saved);
      setShowingSuccessNotification(!resp.error);
      setShowingErrorNotification(!!resp.error);
      setIsSaving(false);
    });
  };

  const onCancelOrder = () => {
    (designStatus === 'ordering-pending-approval') ?
      modalRef.current.open(MODALS.CONFIRMATION, {
        label: 'Are you sure you want to cancel this order?',
        children: 'Canceling this order will void the DocuSign order and remove all order details from this quote. This change is permanent and can not be undone.',
        cancelText: 'No, do not cancel this order',
        confirmText: 'Yes, cancel this order',
        onConfirm: () => rejectOrder(quote.id, { design_id: designId, remove_draft: true }).then(redirect)
      }) :
    (designStatus === 'ordering-review')
      ? acceptOrderReview(quote.id, { design_id: designId, accept: false, remove_draft: true }).then(redirect) :
    (savedOrder
      ? deleteQuoteOrderDraft(quote.id, savedOrder.id).then(redirect)
      : redirect()
    );
  };

  const onBackToDraft = () => { 
    const goBack = () => acceptOrderReview(quote.id, { design_id: designId, accept: false, remove_draft: false }).then(rehydrate);

    (designStatus !== 'ordering-pending-approval') ? goBack() :
      modalRef.current.open(MODALS.CONFIRMATION, {
        label: 'Are you sure you want to go back and edit the order details?',
        children: 'Going back to edit the order details will void the DocuSign order. This change is permanent and can not be undone.',
        cancelText: 'No, do not go back',
        confirmText: 'Yes, go back',
        onConfirm: () => rejectOrder(quote.id, { design_id: designId, remove_draft: false }).then(rehydrate)
      })
  };

  const onSendReview = () => {
    savedOrder && modalRef.current.open(MODALS.SEND_REVIEW, {
      orderId: savedOrder.id,
      designId,
      quoteId: quote.id,
      quoteRef: quote.reference,
    });
  };

  const onDismiss = () => {
    setShowingSuccessNotification(null);
    setShowingErrorNotification(null);
  };

  const onSubmitToReview = formData => orderQuote(quote.id, toOrderReview({ designId, quote, formData }));
  const onSubmitToOrder = () => acceptOrderReview(quote.id, { design_id: designId, accept: true });

  // on savedDraft prop update - called async by parent to determine if design has saved draft
  useEffect(() => { 
    savedDraft && setSavedOrder(savedDraft) 
  }, [savedDraft]);

  // on setSavedOrder state update - resets form (with draftId)
  useEffect(() => {
    savedOrder && reset(savedOrder);
  }, [savedOrder, reset]);

  // Enable Submit button when terms are accepted
  const className = CN('quote-order', { 'quote-order--ordered': designStatus === 'ordered' });
  const acceptedTerms = formMethods.watch('acceptTerms');
  const orderingDisabled = !acceptedTerms || !!isOrdering || designStatus === 'ordered';
  const isEcx = quote.priceAsOrganizationKey === 'equinix-ecx';
  const portLabel = isEcx ? ECX_PORT_LABEL : 'Port Speed';
  const isEcxIp = isEcx && !designType.indexOf('ip-transit');
  const isCustomerOrdered = CUSTOMER_ORDERED_STATUS.includes(props.designStatus); 
  const user = useUserContext();
  const showMrrAsMrc = user.may_view_full_design && quote.orgName === 'SungardAS';
  const readOnly = ['ordering-review', 'ordering-pending-approval', 'ordered', 'design-validation'].includes(designStatus);
  const { order_rejection_data=[] } = (quote.statusDetails || {});
  const { reason, message='' } = order_rejection_data.filter(row => row.design_id === designId).shift() || {};

  const rejectionType = reason;
  const rejectionMsg = message && `Reason: "${message}"`;

  const labels = {
    mrc: showMrrAsMrc ? 'SungardAS MRC' : 'MRC',
    nrc: showMrrAsMrc ? 'SungardAS NRC' : 'NRC',
  }

  const hasErrors = Object.keys(formMethods.errors).length;
  if (hasErrors) { console.log(JSON.stringify(formMethods.errors, null, 2)); }

  return (
    <>
      {!!(props.designStatus === 'ordering-pending-approval' && savedDraft) && (
        <Notification.Warning  className="quote-order__pending-approval-notification">
          The order request has been received <TimeAgo date={savedDraft.lastModified} />.
          A DocuSign contract has been sent to <b>{savedDraft.approverEmail}</b> to be signed and accepted.
        </Notification.Warning>
      )}

      {!!(props.designStatus === 'ordering-review' && savedDraft) && (
        <Notification.Info  className="quote-order__pending-approval-notification">
          Please review and confirm the order details. You may go back to edit any of the information if needed. 
        </Notification.Info>
      )}

      {!!(rejectionType) && (
        <Notification.Warning className="quote-order__pending-approval-notification">
          This order has been placed into a saved draft because the approval 
          { rejectionType === 'expired' ? ' expired. ' :
            rejectionType === 'declined' ? ' was declined. ' : ' was declined. ' }
          Please edit the form and resend for approval.
          { rejectionMsg && <div className="order-rejection-message">{rejectionMsg}</div> }
        </Notification.Warning>
      )}

      {(designStatus === 'design-validation') && (
        <Notification.Warning className="quote-order__design-validation-notification">
          This order requires validation.
        </Notification.Warning>
      )}

      {(designType === 'broadband-internet') && (
        <Notification.Info className="quote-order__notification--broadband">
          Please note, broadband service availability and speed is only guaranteed after order placement and confirmation from underlying provider.
        </Notification.Info>
      )}

      <div className={className}>
        <header className="quote-order__header">
          <div className="quote-order__header-info">
            <div>
              <div>{quote.name}</div>
              <div><b>Quote Reference: {quote.reference}</b></div>
            </div>
            {!isCustomerOrdered && (
              <Button secondary onClick={onCancelOrder} children="Cancel Order" />
            )}
          </div>

          <div className="quote-order__header-details">
            <QuoteOrderHeaderDetail label="Term" value={term} type="term" />
            <QuoteOrderHeaderDetail label="Bandwidth" value={bandwidth} type="bandwidth" />
            {!isEcxIp && <QuoteOrderHeaderDetail label={portLabel} value={port} type="port" /> }
            <QuoteOrderHeaderDetail label={labels.mrc} value={showMrrAsMrc ? mrr : mrc} type="mrc" />
            {!isEcxIp && <QuoteOrderHeaderDetail label={labels.nrc} value={showMrrAsMrc ? nrr : nrc} type="nrc" /> }
          </div>

          {!!showingSuccessNotification && (
            <Notification.Success className="notification-order-saved" onDismiss={onDismiss} duration={5000} children="Your changes have been saved." />
          )}
          {!!showingErrorNotification && (
            <Notification.Error className="notification-order-saved" onDismiss={onDismiss} duration={5000} children="There was an error saving your draft. Please contact support or try again later." />
          )}
        </header>

        <FormProvider {...formMethods} readOnly={readOnly}>
          {/* <pre>
              { JSON.stringify(formMethods.errors, null, 2) }
              </pre> */}

          {!!(isSaving || isFetching) && <LoadingOverlay /> }

          <form onSubmit={formMethods.handleSubmit(onSubmitDraft)} autoComplete="off">
            <div className={CN('quote-order-sections', { 'quote-order-sections--read-only': !!readOnly })}>
              {(!!locationA) && (
                <OrderForm locationA={locationA} locationZ={locationZ} product={product} designType={designType} docusignApprovers={docusignApprovers} isEcx={isEcx} ipv4={quote.ipv4} />
              )}
            </div>
            {!isCustomerOrdered && (
              <div className="quote-order-actions">
                {!(hasOrderReview) ? (
                  <>
                    <Button secondary onClick={saveDraft} children={isSaving ? 'Saving...' : 'Save Changes'} />
                    <Button buttonType="submit" children="Continue" disabled={orderingDisabled} />
                  </>
                ) : (
                  <>
                    <Button secondary onClick={onBackToDraft} children={isSaving ? 'Going back...' : 'Go back'} disabled={isSaving || isOrdering} />
                    <Button secondary onClick={onSendReview} children={'Send for review'} />
                    {designStatus !== 'ordering-pending-approval' && (
                      <Button onClick={onSubmitReview} children={!docusignApprovers.length ? 'Submit Order' : 'Submit to DocuSign'} disabled={isSaving || isOrdering} />
                    )}
                  </>
                )}
              </div>
            )}
          </form>
        </FormProvider>
      </div>
      <ModalManager ref={modalRef} />
    </>
  );
}

const QuoteOrderHeaderDetail = ({ label, value, type='' }) => (
  <div className={'quote-order__header-detail' + (type ? ` quote-order__header-detail--${type}` : '')}>
    <div className="quote-order__header-detail-label">{label}</div>
    <div className="quote-order__header-detail-value">{value}</div>
  </div>
);

export default QuoteOrder;

import React from 'react';
import CN from 'classnames';
import { useHistory } from 'react-router-dom';
import { Button, Icon, StatusIcon, Tooltip } from 'Atoms';
import { useUserContext } from 'Context/UserContext.js';
import { useAnalytics } from 'Hooks';
import { toCurrencyParts, normalizeCurrencySymbol } from 'Utils/formatter.js';
import { getLabels } from 'Utils/const.js';
import { ECX_PORT_LABEL, ECX_LOCATION_LABEL } from 'Utils/ecx.js';

import './Design.scss';

const VIEW_ORDER_STATUSES = ['ordering', 'ordered'];

const Design = props => {
  const { domId, design, quoteRef, quoteStatus, orderingDisabled, extendedDemarc, orgRequiresOrderManager } = props;
  const { id, locationA, locationZ, status, reference, connectivityVendors,
    term, bandwidth, port, upload, internetType, designType,
    mrc, mrr, nrc, nrr, ug_mrr, ug_nrr, displayCurrency, hasOrderDraft,
    messages=[],
  } = design;

  const history = useHistory();
  const user = useUserContext();
  const labels = getLabels(user);
  const { trackEvent } = useAnalytics();

  //ip-transit, ip-transit-with-access'
  const isEcxIp = user.isEcx && !designType.indexOf('ip-transit'); 
  const portLabel = user.isEcx ? ECX_PORT_LABEL : 'Port';
    
  const orderQuote = () => {
    trackEvent({ category: 'Order Quote', action: 'click' });
    history.push(`/quote/${quoteRef}/order/${id}`);
  };

  const className = CN('design', { 'design--ordered': status === 'ordered'});
  const tooltip = (typeof orderingDisabled === 'string' && orderingDisabled);
  const disabled = !!tooltip || (!!orderingDisabled && !VIEW_ORDER_STATUSES.includes(status)); // allow users to view existing orders 

  const buttonText = (
    (status === 'ordered') 
      ? 'View Order' :
    (status === 'ordering-pending-approval')
      ? 'View Pending DocuSign Order' :
    (hasOrderDraft) 
      ? 'Resume Order' 
      : 'Order'
  );
  
  const statusIcon = (
    status !== 'ordered'
      ? null :
    status === 'ordering-pending-approval'
      ? 'ordering-pending-approval' :
    quoteStatus === 'manually-ordered'
      ? 'manually-ordered'
      : 'ordered'
  );

  const disableViewOrder = (statusIcon && orgRequiresOrderManager && !user.is_order_manager);

  return (
    <div id={domId} className={className}>
      <div className="design-row row1">
        <DesignEndpoint icon="location" label="From:" line1={locationA.line1} line2={locationA.line2} floorSuite={extendedDemarc && locationA.floorSuite} />

        <div className="design-actions">
        {disableViewOrder 
          ? <StatusIcon status={statusIcon} className="design-actions__ordered-status" />
          : (
            <Tooltip title={tooltip}>
              <Button primary onClick={orderQuote} disabled={disabled} children={buttonText} />
            </Tooltip>
          )}
        </div>
      </div>

      <div className="design-row row2">
        <div className="design-pricing">
          <ul className="design-pricing-labels">
            <li />
            <li>Term</li>
            <li>Bandwidth</li>
            {!isEcxIp && <li>{portLabel}</li> }

            { !isNaN(mrc) && <li>{!isNaN(ug_mrr) ? labels.mrc : 'MRC'}</li> }
            { !isNaN(ug_mrr) && <li>{labels.ug_mrr}</li> }
            { !isNaN(mrr) && (mrr !== ug_mrr) && <li>{labels.mrr}</li> }

            { !isNaN(nrc) && !isEcxIp && <li>{!isNaN(ug_nrr) ? labels.nrc : 'NRC'}</li> }
            { !isNaN(ug_nrr) && <li>{labels.ug_nrr}</li> }
            { !isNaN(nrr) && (nrr !== ug_nrr) && <li>{labels.nrr}</li> }
          </ul>

          <ul className="design-pricing-totals tfoot totals">
            <li />
            <li>{term}</li>
            <li><Bandwidth bandwidth={bandwidth} upload={upload} /></li>
            {!isEcxIp && <li>{port}</li> }

            { !isNaN(mrc) && <li>{Price(mrc, displayCurrency)}</li> }
            { !isNaN(ug_mrr) && <li>{Price(ug_mrr, displayCurrency)}</li> }
            { !isNaN(mrr) && (mrr !== ug_mrr) && <li>{Price(mrr, displayCurrency)}</li> }

            { !isNaN(nrc) && !isEcxIp && <li>{Price(nrc, displayCurrency)}</li> }
            { !isNaN(ug_nrr) && <li>{Price(ug_nrr, displayCurrency)}</li> }
            { !isNaN(nrr) && (nrr !== ug_nrr) && <li>{Price(nrr, displayCurrency)}</li> }
          </ul>
        </div>

        {internetType 
          ? <DesignEndpoint icon="internet" label="Internet" line1={internetType} />
          : (user.isEcx
            ? <DesignEndpoint icon="data-center" label={`${ECX_LOCATION_LABEL}:`} line1={locationZ.displayName} />
            : <DesignEndpoint icon="location" label="To:" line1={locationZ.line1} line2={locationZ.line2} floorSuite={extendedDemarc && locationZ.floorSuite} />
          )
        }
      </div>

      <div className="design__tracking-info">
        <div>
          {!!connectivityVendors && (
            <div className="design__carrier">
              <Icon name="customer" />
              <span className="design__carrier-name">{connectivityVendors}</span>
              
            </div>
          )}
          {!!messages?.length && (  
            <div>
              {messages.map((msg, i) => (
                <div className="design__message" key={i}>
                  <Icon className="warning-icon" name="exclamation-point-triangle-filled" />
                  <span>{msg}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="design__design-reference">{`ID: ${reference}`}</div>
      </div>
    </div>
  )
}

const DesignEndpoint = ({ label, icon, line1, line2, floorSuite }) => (
  <div className="design-endpoint">
    <Icon name={icon} width="1em" height="1.6em" />  
    <b>{label}</b>
    <div>{ line1 }</div>
    {!!floorSuite && <div>{floorSuite}</div>}
    {!!line2 && <div>{line2} </div>}
  </div>
);

const Price = (amount, currencyCode) => {
  if (isNaN(amount)) return null;

  const currencyParts = toCurrencyParts(amount, currencyCode);

  // join values and get fraction component
  const { currency, fraction } = Object.values(currencyParts).reduce((acc, { type, value }) => {
    (type === 'fraction') ? acc.fraction += value : acc.currency += value;
    return acc;
  }, { currency: '', fraction: '' });

  const formatted = normalizeCurrencySymbol(currency);

  return (
    <span className="price">
      {formatted}<sup>{fraction}</sup>
    </span>
  );
}

const Bandwidth = ({ bandwidth, upload }) => {
  const uploadSpeed = !upload ? 0 : upload >= 1000 ? `${upload/1000} Gbps` : `${upload} Mbps`;

  return !uploadSpeed 
    ? <div>{bandwidth}</div>
    : (
      <div className="design-item--broadband-bandwidth">
        <div title="Download speed">{bandwidth} <Icon name="arrow-down-small" /></div>
        <div title="Upload speed">{uploadSpeed} <Icon name="arrow-up-small" /></div>
      </div>
    )
}

export default Design;
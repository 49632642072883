import { useEffect, useCallback, useMemo, useReducer } from 'react';
import { getOrganization, getUser } from 'Actions/ccm/api.js';

import { PERMISSIONS } from 'Utils/const.js';

const PRICING_ACTIONS = {
  'UPDATE_DEFAULT_MARGINS': 'UPDATE_DEFAULT_MARGINS',
  'UPDATE_COST': 'UPDATE_COST',
  'UPDATE_COMMISSION_PCT': 'UPDATE_COMMISSION_PCT',
  'UPDATE_MRR': 'UPDATE_MRR',
  'UPDATE_NRR': 'UPDATE_NRR',
  'UPDATE_MRR_MARGIN_PCT': 'UPDATE_MRR_MARGIN_PCT',
  'UPDATE_MRV': 'UPDATE_MRV',
  'UPDATE_NRV': 'UPDATE_NRV',
};

const num = n => Number(n || 0);

const pricingReducer = (state, action) => {
  const { 
    mrc, nrc, externalMrc, externalNrc,
    mrr, nrr, mrv, nrv,
    commissionPct, commissionAmt,
    marginPct, markupAmt,
    userMarginPct, userMarkupAmt,
  } = state;

  const { type, isNew, costs, orgMargins, payload, preservePrice } = action;

  console.log(action)

  const getMargin = ({ cost, revenue }) => !revenue ? 100 : (100 * (revenue - cost) / revenue);

  const getPrice = ({ cost, margin }) => {
    const bottom = (100 - margin) * .01;
    return !bottom ? 0 : cost/bottom;
  }

  const calculateNewMrr = ({ mrc=state.mrc, commissionPct=state.commissionPct, marginPct=state.marginPct }) => {
    const newMrr = getPrice({ cost: mrc, margin: marginPct + commissionPct });
    const newCommissionAmt = .01 * num(commissionPct) * newMrr;

    const ret = {
      mrr: newMrr,
      mrv: getPrice({ cost: newMrr, margin: userMarginPct }),
      commissionAmt: newCommissionAmt,
      marginAmt: newMrr - newCommissionAmt - mrc,
    }
    return ret;
  }

  const getRecurringPcts = ({ mrc=state.mrc, mrr=state.mrr, commissionPct=state.commissionPct }) => {
    const newCommissionAmt = .01 * num(commissionPct) * mrr;
    const cost = newCommissionAmt + mrc;

    const ret = {
      commissionAmt: newCommissionAmt,
      marginPct: getMargin({ cost, revenue: mrr }),
      marginAmt: mrr - cost,
      mrv: getPrice({ cost: mrr, margin: state.userMarginPct }),
    }
    return ret;
  };

  switch (type) {
    case PRICING_ACTIONS.UPDATE_COST:
      return {
        ...state,
        ...costs,
        ...(!preservePrice 
          ? { // If creating new design, then added design item total applies default margin
            ...calculateNewMrr({ mrc: costs.mrc }),
            // New design so add default markup amount to nrr and nrv
            nrr: costs.nrc + markupAmt,
            nrv: costs.nrc + markupAmt + userMarkupAmt,
          } : { 
            // Else changes to cost of existing design likely indicates cost optimization,
            // so keep quoted price and apply cost differences as new margin
            ...getRecurringPcts({ mrc: costs.mrc }),
            // Edit existing design to retain quoted rates and adjust the markup amount
            markupAmt: nrr - costs.nrc,
          }
      )}

    case PRICING_ACTIONS.UPDATE_COMMISSION_PCT:
      return {
        ...state,
        commissionPct: payload,
        ...(isNew
          // If creating new design, then maintain default margin and adjust rate with new commission percentage
          ? calculateNewMrr({ commissionPct: payload })
          // If editing existing design, retain price and adjust the margin to maintain the quoted price 
          : getRecurringPcts({ mrc, mrr, commissionPct: payload })
      )}

    case PRICING_ACTIONS.UPDATE_MRR_MARGIN_PCT:
      // Updated margin adjusts mrr, commission amount, and total applicable enduser list price
      return {
        ...state,
        marginPct: payload,
        ...calculateNewMrr({ marginPct: payload })
      };

    case PRICING_ACTIONS.UPDATE_MRR: {
      // MRR updates must first recompute commission and treated as a new cost for margin computation
      const newCommissionAmt = .01 * commissionPct * payload;
      const cost = mrc + newCommissionAmt;

      return {
        ...state,
        mrr: payload,
        commissionAmt: newCommissionAmt,
        marginAmt: payload - cost,
        marginPct: getMargin({ cost, revenue: payload }),
        mrv: getPrice({ cost: payload, margin: userMarginPct }),
      };
    }

    case PRICING_ACTIONS.UPDATE_NRR:
      // NRR updates compute markup and affect nonrecurring customer enduser list price
      return { 
        ...state, 
        nrr: payload, 
        markupAmt: payload - nrc, 
        nrv: payload + userMarkupAmt, 
      };

    case PRICING_ACTIONS.UPDATE_MRV:
      // Update customer's enduser list price to specified amount and show their margin
      return { 
        ...state, 
        mrv: payload,
        userMarginPct: payload ? 100 * (payload - mrr) / payload : 0,
      };

    case PRICING_ACTIONS.UPDATE_NRV: 
      // Update customer's enduser non-recurring list price to specified amount
      return { 
        ...state, 
        nrv: payload, 
        userMarkupAmt: payload - nrr,
      };

    case PRICING_ACTIONS.UPDATE_DEFAULT_MARGINS: {
      // Fetch quoting org and user to set default margins
      return {
        ...state,
        ...orgMargins,
      }
    }

    default: 
      console.error(`${type} not recognized with payload `, payload)
    }
}

const useDesignPricing = props => {
  const { id, quoteOrgId, quoteOwnerId, partnerType, clearError } = props;
  const isNew = !id;

  const initState = {
    mrc: props.mrc,
    nrc: props.nrc,
    externalMrc: props.externalMrc,
    externalNrc: props.externalNrc,

    mrv: num(props.mrv),
    mrr: num(props.mrr),
    nrr: num(props.nrr),
    nrv: num(props.nrv),

    userMarginPct: props.userMarginPct,
    userMarkupAmt: props.userMarkupAmt || 0,

    marginPct: props.marginPct,
    markupAmt: Math.max(props.nrr - props.nrc, 0),
    commissionPct: props.commissionPct || 0,
  }

  const [ state, dispatch ] = useReducer(pricingReducer, initState);

  useEffect(() => {
    const fetchOrgMargins = async () => {
      let applyEnduserMargin = true;

      const org = await getOrganization(quoteOrgId);

      const commissionPct = num(!partnerType ? 0 :
        (partnerType === 'enterprise')
          ? org.mrr_partner_commission_enterprise
          : org.mrr_partner_commission_wholesale
      );

      const isSungard = (org.name === 'SungardAS');
      if (isSungard) {
        const { permissions } = await getUser(quoteOwnerId);
        applyEnduserMargin = !permissions.includes(PERMISSIONS.IGNORE_ENDUSER_MARGINS);
      }

      const orgMargins = {
        // mrc: 0,
        // nrc: 0,
        // mrr: 0,
        // nrr: 0,
        commissionPct,
        marginPct: num(org.mrr_margin_percent_ug),
        markupAmt: num(org.nrr_markup_fixed_ug),
  
        // Apply wholesale enduser margins for organization as long as quote owner permissions allow it
        ...(applyEnduserMargin && {
          userMarginPct: num(org.mrr_margin_percent_end_user),
          userMarkupAmt: num(org.nrr_markup_fixed_end_user),
        }),
      };

      dispatch({ type: PRICING_ACTIONS.UPDATE_DEFAULT_MARGINS, orgMargins });
    };

    quoteOrgId && isNew && fetchOrgMargins();
  }, [quoteOrgId, quoteOwnerId, partnerType, isNew]);


  const dispatcher = useCallback((type, e) => dispatch({ type, payload: Number(e.target.value), isNew }), [ isNew ])

  const onChange = useMemo(() => ({
    marginPct: e => {
      dispatcher(PRICING_ACTIONS.UPDATE_MRR_MARGIN_PCT, e);
      clearError('marginPct');
    },

    commissionPct: e => {
      dispatcher(PRICING_ACTIONS.UPDATE_COMMISSION_PCT, e);
      clearError('commissionPct');
    },

    mrr: e => dispatcher(PRICING_ACTIONS.UPDATE_MRR, e),
    nrr: e => dispatcher(PRICING_ACTIONS.UPDATE_NRR, e),
    mrv: e => dispatcher(PRICING_ACTIONS.UPDATE_MRV, e),
    nrv: e => dispatcher(PRICING_ACTIONS.UPDATE_NRV, e),
  }), [ clearError, dispatcher ]);

  const updateTotals = useCallback(({ bandwidth, port, term, upload, ...costs }, editLog) => {
    const costAdjustments = item => ({
      ...costs,
      mrr: costs.mrr + (Number(item.mrr) || 0),
      mrv: costs.mrv + (Number(item.mrv) || 0),
      nrr: costs.nrr + (Number(item.nrr) || 0),
      nrv: costs.nrv + (Number(item.nrv) || 0),
    })

    // If the cost changed, then determine if we determine the new margin or the new rate
    // Edited designs are generally price optimizations meaning we want to keep the same quoted MRR, 
    // but at a lower cost to us so we keep the MRR 
    // If it is a newly constructed design or if the edited design has deleted an item, 
    // then we want to recalculate the cost to the user with the designated margin percentage
    const { deleted, preservePrice } = (editLog?.at(-1) || {});
    const preserveDeletedPrice = (deleted && preservePrice);

    dispatch({ 
      type: PRICING_ACTIONS.UPDATE_COST, 
      // preserve price if editing or deleted row explicitly indicates it
      preservePrice: (!isNew && !deleted) || preserveDeletedPrice,
      costs: preserveDeletedPrice ? costAdjustments(deleted) : costs,
    });
  }, [ isNew ]);

  return {
    ...state,
    // mrc,
    // nrc,
    // externalMrc,
    // externalNrc,
    // mrr,
    // nrr,
    // marginAmt,
    // marginPct,
    // markupAmt,
    // commissionAmt,
    // commissionPct,
    // userMarginPct,
    // userMarkupAmt,
    // mrv,
    // nrv,

    onChange,
    updateTotals,
  }
}

// TODO: Consider making these computed fields
  // const commissionAmt = useMemo(() => {
  //   return .01 * commissionPct * mrr;
  // }, [mrr, commissionPct]);

  // const marginAmt = useMemo(() => {
  //   return mrr - mrc - commissionAmt;
  // }, [mrr, mrc, commissionAmt]);

export default useDesignPricing;
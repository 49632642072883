import React, { useEffect, useState } from 'react';
import { Page } from 'Atoms';
import { Helmet } from 'react-helmet-async';
import SVG from 'react-inlinesvg';

import { DefSvgFullColorLogo, DefFullLogoName, PAGE_TITLE_NAME } from 'Utils/branding.js';

import './ServiceMetricsPage.scss';

const ServiceMetricsGrid = () => {

  const [ headers, setHeaders ] = useState([]);
  const [ rowData, setRowData ] = useState([]);

  useEffect(function fetchData() {
    fetch('https://dc-aggregation-api.unitas.dev/data')
      .then(resp => resp.json())
      .then(json => {
        setHeaders( Object.keys(json) );
        setRowData( Object.values(json) );
      })
      .catch(() => { alert('error fetching data') });
  }, []);

  return (
    <table className="service-metrics-grid">
      <thead>
      <tr className="service-metrics-grid__row service-metrics-grid__row--header">
        <th className="service-metrics-grid__keystone"></th>
        { headers.map(header => 
          <GridHeader key={header} label={header} />
        )}
      </tr>
      </thead>
      <tbody>
      { rowData.map((row, i) => (
        <GridRow key={`row_${i}`} headers={headers} row={row} />
      ))}
      </tbody>
    </table>
  )
}

const GridHeader = ({ label }) => <th className="service-metrics-grid__header">{label}</th>

const GridRow = ({ headers=[], row }) => {
  const start = row?.[0]?.start_location;
  const index = start && headers.indexOf(start);
  if (!start || index === -1) {
    console.warn('Unfound location')
  }

  const RowCells = [];
  for (let rowIndex=0, colIndex=0, length=headers.length; colIndex < length; colIndex++) {
    const misMatch = (rowIndex !== length && row[rowIndex]?.end_location !== headers[colIndex]);
    const key=`${index}-${colIndex}`;

    RowCells.push(
      misMatch ? <td key={key} className="no-hover" /> : <GridCell key={key} {...row[rowIndex]} />
    );

    rowIndex += (!misMatch);
  }

  return (
    <tr className="service-metrics-grid__row">
      <td className="service-metrics-grid__row-header">{start}</td>
      { RowCells }
    </tr>
  );
}

const GridCell = ({ start_location, end_location, jitter, latency, packet_loss }) => (
  <td className="service-metrics-grid__cell">
    <div className="tooltip expand">
      <div>{start_location} ↩ {end_location}</div>
      <ul>
        <li>Latency: {latency}</li>
        <li>Jitter: {jitter}</li>
        <li>Packet Loss: {packet_loss}</li>
      </ul>
    </div>
  </td>
);

const ServiceMetricsPage = () => (
  <div>
    <Helmet>
      <title>{PAGE_TITLE_NAME}: Acceptable Use and Privacy Policy</title>
    </Helmet>

    <nav style={{ padding: '0 1.8rem'}}>
      <h1><SVG height="28px" src={DefSvgFullColorLogo} alt={DefFullLogoName} /></h1>
    </nav>
    <Page header="Service Metrics" className="service-metrics-page patterned">
      <ServiceMetricsGrid />
    </Page>
  </div>
)

export default ServiceMetricsPage;
import React, { Component } from 'react';

export default class LoadingIcon extends Component {
  render() {
    return (
      <span className="icon loading-icon spinning">
        <svg width="1em" height="1em">
          <title>{this.props.title ? this.props.title : "Loading"}</title>
          <desc>{this.props.title ? this.props.title : "Loading"}</desc>
          <use xlinkHref="#loading" />
        </svg>
      </span>
    );
  }
}

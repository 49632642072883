import React from 'react';
import * as esAntRadio from 'antd/es/radio';

import './radios.scss';

/**
 * A styled radio button. This is a very thin wrapper over the ant.d Radio
 * component, specified _only_ so that we can have radio buttons outside of
 * ant.d's RadioGroups styled à la our standard green radios.
 *
 * @param { Boolean } checked - is the button checked.
 * @param { Boolean } disabled - Whether the radio button is interactive
 * @param { String } label - The (also interactive) text to the right of the
 * button
 * @param { String } name - The name of the form field. Used by all "connected"
 * radios.
 * @param {String } value - the unique name of this radio button
 */

const AntRadio = esAntRadio.default;

const Radio = props => (
  <AntRadio
    checked={props.checked}
    disabled={props.disabled}
    key={props.value}
    name={props.name}
    value={props.value}
  >
    {props.label}
  </AntRadio>
);

export default Radio;
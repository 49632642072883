import React, { useState } from 'react';
import { default as ReactTimeAgo } from 'react-timeago';

import { Tooltip } from 'Atoms';
import { formatDate } from 'Utils/formatter.js';

const TimeAgo = props => {
  const { date, title, ...rest } = props;
  
  const [ tooltip ] = useState(formatDate(date));

  const abbr = unit => unit
    .replace('minute', 'min')
    .replace('hour', 'hr')
    .replace('year', 'yr');

  const formatter = (value, unit, suffix) => (unit === "second") 
    ? 'Just now' 
    : `${value} ${abbr(unit) + (value > 1 ? "s" : "")} ${suffix}`;

  !date && console.warn('date prop is required!');
  
  return (
    <Tooltip title={tooltip}>
      <ReactTimeAgo date={date} formatter={formatter} {...rest} />
    </Tooltip>
  )
}

export default TimeAgo;
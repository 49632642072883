import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withAuthContext } from "Context/AuthContext.js";
import Layout from 'Templates/Layout.jsx';
import { setUser as reduxSetUser } from 'Actions';
import { getUserMe } from 'Actions/ccm/api.js';
import { useAnalytics, useStorage } from 'Hooks';
import { UserProvider } from 'Context/UserContext.js';
import { STORAGE_KEYS } from 'Utils/const.js';

const AuthUserContainer = (props) => {
  const { logout } = props;
  const [ user, setUser ] = useState();
  const [ userStorage, setUserStorage ] = useStorage(STORAGE_KEYS.CCM_USER);
  const { setUserTracking } = useAnalytics();


  const fetchUser = () => {
    getUserMe().then(resp => {
      if (!resp || resp.error) {
        logout('/login' + window.location.search);
      }

      setUserStorage(resp);
      setUser(resp);
    });
  };

  const retrieveUser = () => (userStorage && userStorage.id) ? setUser(userStorage) : fetchUser();

  // onMount: get the user info from the ID token
  useEffect(retrieveUser, []);

  // user state update: update redux, GA
  useEffect(() => {
    const onUserUpdate = async () => {
      await props.setUser(user);
      setUserTracking(user);
    };

    user && !props.user.id && onUserUpdate();
  }, [user, props, setUserTracking]);
  // user is for context, prop.user is for redux value
  // NOTE: redux still used in many places as dependency so do not render until that has populated
  //const hasUser = !!(user && user.id && props.user && props.user.id);

  const value = { 
    ...user, 
    refresh: fetchUser,
    hasPermission: permission => user?.permissions?.includes(permission),
  };

  // render
  return (
    <UserProvider value={value}>
      <Layout fetchUser={fetchUser} />
    </UserProvider>
  );
}

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => ({
  setUser: (userObj) => dispatch(reduxSetUser(userObj))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuthContext(AuthUserContainer));

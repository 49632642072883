import React, { useContext } from 'react'
import UserContext from 'Context/UserContext.js';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute } from 'Auth/SecureRoute.jsx';
import { GenericNotFoundPage } from 'Pages';
import { DashboardPage, PricingDeskPage, QuoteManagerPage, CreateQuotePage, QuoteOrderPage, QuoteViewPage, AdminPage, NotificationsPage, DiagnosticsPage } from 'Pages/CCM';
import EcxHomePage from 'Pages/ECX/EcxHomePage.jsx';
import LoginPocPage from 'Pages/CCM/LoginPoc/LoginPocPage.jsx';
import RateTestLanding from 'Pages/Analysis/RateTestLanding/RateTestLanding.jsx';
import RateTestPage from 'Pages/Analysis/RateTest/RateTest.jsx';

const LayoutRouter = () => {
  const user = useContext(UserContext) || {};

  return (
    <Switch>
      <SecureRoute path="/dashboard" component={DashboardPage} />
      <SecureRoute path="/quote-manager/:folderName" component={QuoteManagerPage} />
      <SecureRoute path="/quote-manager" component={QuoteManagerPage} />
      <SecureRoute path="/pricing-desk" component={PricingDeskPage} />
      <SecureRoute path="/request-quote" component={CreateQuotePage} />
      <SecureRoute path="/quote/:quoteRef/order/:designId" component={QuoteOrderPage} />
      <SecureRoute path="/quote/:quoteRef" component={QuoteViewPage} />
      <SecureRoute path="/admin" component={AdminPage} />
      <SecureRoute path="/comments" component={NotificationsPage} />
      {/* for testing... */}
      <SecureRoute path="/ecx" component={EcxHomePage} />
      {!!user.is_developer && (
        <SecureRoute path="/login-poc" component={LoginPocPage} />  
      )}
      {!!user.is_developer && (
        <SecureRoute path="/diagnostics" component={DiagnosticsPage} />
      )}
      {/* for testing end */}
      <SecureRoute path="/rate-testing" component={RateTestLanding} exact />
      <SecureRoute path="/rate-testing/:runReference" component={RateTestPage} />
      <Route exact path="/" component={DashboardPage} />
      <Route component={GenericNotFoundPage} />
    </Switch>
  );
};

export default LayoutRouter;


/// UGTOKEN AUTH
import React from 'react';
import { Security } from '@okta/okta-react';

import { parseTokenFromUrl } from 'Utils/auth.js';
import { STORAGE_KEYS } from 'Utils/const.js';

const storage = window.localStorage;

const { CCM_USER, UG_TOKEN, REFERRER_PATH } = STORAGE_KEYS;

// UGTOKEN users are set up to use a token configured to work with the Okta Security component
const authProp = { 
  handleAuthentication: async () => {
    const accessToken = parseTokenFromUrl();
    //const accessToken = decodeJwt(token);

    if (accessToken) {
      await storage.removeItem(CCM_USER);
      await storage.setItem(UG_TOKEN, JSON.stringify({ accessToken: { accessToken } }));
      return { accessToken };
    }
  },

  // LOGIN is handled by ECX
  login: () => {
    console.log('ugcore token login')
  },
  
  logout: (href="/") => {
    storage.removeItem(UG_TOKEN);
    storage.removeItem(CCM_USER);
    // TODO: remove page history of logout
    window.location.href = href;
  },

  isAuthenticated: async () => {
    // If there could be tokens in the url
    const containsAuthTokens = /id_token|access_token|code/;
    if (location && location.hash && containsAuthTokens.test(location.hash)) return null;
    
    return await !!authProp.getAccessToken();
  },

  getIdToken: () => undefined,

  getAccessToken: async () => {
    try {
      return await JSON.parse(storage.getItem(UG_TOKEN));

    } catch (err) {
      // The user no longer has an existing SSO session in the browser.
      // (OIDC error `login_required`)
      // Ask the user to authenticate again.
      console.warn('Access Token not found')
      return undefined;
    }
  },
  getUser: () => undefined,

  redirect: () => new Promise(resolve => resolve(true)),

  getFromUri: () => {
    const location = JSON.parse(storage.getItem(REFERRER_PATH) || '{ "pathname": "/" }');
    storage.removeItem(REFERRER_PATH);
    return location;
  }
};

const UgcoreTokenSecurity = props => <Security auth={authProp}>{props.children}</Security>

export default UgcoreTokenSecurity;
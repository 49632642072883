import AddUsersModal from './AddUsersModal.jsx';
import AdvancedLocationModal from './AdvancedLocationModal.jsx';
import BulkCommentModal from './BulkCommentModal.jsx';
import BulkDeleteModal from './BulkDeleteModal.jsx';
import CancelOrderModal from './CancelOrderModal.jsx';
import CloneFolderModal from './CloneFolderModal.jsx';
import ConfirmationModal from './ConfirmationModal.jsx';
import ContactVendorModal from './ContactVendorModal.jsx';
import CreateQuoteModal from './CreateQuoteModal.jsx';
import DeleteModal from './DeleteModal.jsx';
import DeleteDesignItemModal from './DeleteDesignItemModal.jsx';
import DescriptionModal from './DescriptionModal.jsx';
import DesignRejectionModal from './DesignRejectionModal.jsx';
import ImportErrorModal from './ImportErrorModal.jsx';
import ImportQuotesModal from './ImportQuotesModal.jsx';
import MoveQuoteModal from './MoveQuoteModal.jsx';
import NewFolderModal from './NewFolderModal.jsx';
import OrderConfirmationModal from './OrderConfirmationModal.jsx';
import QuoteReviewModal from './QuoteReviewModal.jsx';
import RenameItemModal from './RenameItemModal.jsx';
import ResetPasswordModal from './ResetPasswordModal.jsx';
import SelectUserModal from './SelectUserModal.jsx';
import SendOrderReviewModal from './SendOrderReviewModal.jsx';
import SupportModal from './SupportModal.jsx';
import TransferFolderModal from './TransferFolderModal.jsx';
import TransferQuoteModal from './TransferQuoteModal.jsx';

export const MODALS = {
  ADD_USERS: 'ADD_USERS',
  BULK_COMMENT: 'BULK_COMMENT',
  CANCEL_ORDER: 'CANCEL_ORDER',
  CLONE_FOLDER: 'CLONE_FOLDER',
  CONFIRMATION: 'CONFIRMATION',
  CONTACT_VENDOR: 'CONTACT_VENDOR',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  DELETE_BULK: 'DELETE_BULK',
  DELETE_DESIGN_ITEM: 'DELETE_DESIGN_ITEM',
  DESCRIPTION: 'DESCRIPTION',
  DESIGN_REJECTION: 'DESIGN_REJECTION',
  IMPORT: 'IMPORT',
  IMPORT_ERROR: 'IMPORT_ERROR',
  LOCATION_SEARCH: 'LOCATION_SEARCH',
  MOVE: 'MOVE',
  NEW_FOLDER: 'NEW_FOLDER',
  ORDER_CONFIRMATION: 'ORDER_CONFIRMATION',
  RENAME: 'RENAME',
  REQUEST_REVIEW: 'REQUEST_REVIEW',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SELECT_USERS: 'SELECT_USERS',
  SEND_REVIEW: 'SEND_REVIEW',
  SUPPORT: 'SUPPORT',
  TRANSFER_FOLDER: 'TRANSFER_FOLDER',
  TRANSFER_QUOTE: 'TRANSFER_QUOTE',
};

const MODAL_LOOKUP = {
  [MODALS.ADD_USERS]: AddUsersModal,
  [MODALS.BULK_COMMENT]: BulkCommentModal,
  [MODALS.CANCEL_ORDER]: CancelOrderModal,
  [MODALS.CLONE_FOLDER]: CloneFolderModal,
  [MODALS.CONFIRMATION]: ConfirmationModal,
  [MODALS.CONTACT_VENDOR]: ContactVendorModal,
  [MODALS.CREATE]: CreateQuoteModal,
  [MODALS.DELETE]: DeleteModal,
  [MODALS.DELETE_BULK]: BulkDeleteModal,
  [MODALS.DELETE_DESIGN_ITEM]: DeleteDesignItemModal,
  [MODALS.DESCRIPTION]: DescriptionModal,
  [MODALS.DESIGN_REJECTION]: DesignRejectionModal,
  [MODALS.IMPORT]: ImportQuotesModal,
  [MODALS.IMPORT_ERROR]: ImportErrorModal,
  [MODALS.LOCATION_SEARCH]: AdvancedLocationModal,
  [MODALS.MOVE]: MoveQuoteModal,
  [MODALS.NEW_FOLDER]: NewFolderModal,
  [MODALS.ORDER_CONFIRMATION]: OrderConfirmationModal,
  [MODALS.RENAME]: RenameItemModal,
  [MODALS.REQUEST_REVIEW]: QuoteReviewModal,
  [MODALS.RESET_PASSWORD]: ResetPasswordModal,
  [MODALS.SELECT_USERS]: SelectUserModal,
  [MODALS.SEND_REVIEW]: SendOrderReviewModal,
  [MODALS.SUPPORT]: SupportModal,
  [MODALS.TRANSFER_FOLDER]: TransferFolderModal,
  [MODALS.TRANSFER_QUOTE]: TransferQuoteModal,
};

export const getModal = name => MODAL_LOOKUP[name];

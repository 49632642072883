import React from 'react';

import { Button, Icon } from 'Atoms';
import Modal from '../Modal.jsx';
import ModalButton from '../ModalButton.jsx';

import './Drawer.scss';

/**
 * A modal drawer that opens from the right. Drawers are "opened" by
 * conditionally rendering them, e.g.:
 * 
 * ```
 * {this.props.openDrawer && <Drawer ... /> }
 * ```
 * 
 * They're dismissed by changing that rendering condition, or by clicking on the
 * 'X' in the top _left_ (because it opens from the right), clicking anywhere
 * outside of the drawer, or by hitting escape. See Modal.jsx for more on that.
 * You can also pass props.onCloseOverlay down to children.
 *
 * @param  {Function} onCloseOverlay - the function triggered by closing
 * @param {Any} id - a unique identifier, such as a string, to disambiguate this
 * modal from all others. See Modal.jsx for more info.
 */
const Drawer = props => {
  const { onCloseOverlay, id, children, label, button1, button2 } = props;

  return (
    <Modal className="drawer-container" close={onCloseOverlay} id={id}>
      <div className="drawer">
        <div className="drawer-header">
          <Button type="link" title="Close Drawer" className="close-drawer" onClick={onCloseOverlay}>
            <Icon name="close-modal" title="Close Drawer" />
          </Button>
          <span className="drawer-label">{ label }</span>
        </div>

        <div className="drawer-content">
          {children}
        </div>

        <div className="drawer-footer">
          {button1 && <ModalButton {...button1} /> }
          {button2 && <ModalButton {...button2} /> }
        </div>
      </div>
    </Modal>
  )
};

export default Drawer;

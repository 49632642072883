import React from 'react';
import { Icon } from 'Atoms';

const IconLabel = props => {
  const { children, className='', label, icon, ...iconProps } = props;

  return (
    <span className={`${className} icon-label`}>
      <Icon name={icon} {...iconProps}  />
      { children || <span className="icon-label__label" children={label} />}
    </span>
  )
}

export default IconLabel;
import React from 'react';
import * as esTimeline from 'antd/es/timeline';

import "antd/lib/timeline/style/index.css";
import './Timeline.scss';

const AntTimeline = esTimeline.default;
const AntTimelineItem = AntTimeline.Item;

const Timeline = ({ items=[], ...props }) => (
  <AntTimeline {...props}>
    { items.map(({ dot, label, description, key }) => 
      <AntTimelineItem key={key} dot={dot} description={description}>
        <div className="timeline-item__label">{label}</div>
        <div className="timeline-item__description">{description}</div>
      </AntTimelineItem>
    )}
  </AntTimeline>
);

export default Timeline;
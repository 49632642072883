import React, { createContext, forwardRef, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;
export const useAuthContext = () => useContext(AuthContext);

export const withAuthContext = (WrappedComponent) => (forwardRef((props, ref) => (
  <AuthConsumer>
    { context => <WrappedComponent ref={ref} {...props} {...context} /> }
  </AuthConsumer>
)));

export default AuthContext;
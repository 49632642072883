import React, { useState } from 'react';
import { Checkbox, LoadingOverlay } from 'Atoms';
import { Popup, UserLookup } from 'Molecules';

import { transferFolder } from 'Actions/ccm/api.js';

import './TransferQuoteModal.scss';

const TransferFolderModal = props => {
  const { onConfirm, onClose, id } = props;
  const [ selectedUser, setSelectedUser ] = useState();
  const [ isFetching, setIsFetching ] = useState(false);
  const [ sendEmail, setSendEmail ] = useState(false);
  const [ ownerId, setOwnerId ] = useState(props.ownerId);

  const onChangeUser = (opts) => setSelectedUser(opts);
  const onChangeNotify = e => setSendEmail(e.target.checked);

  const handleSubmit = async () => {
    setIsFetching(true);
    const resp = await transferFolder(id, selectedUser.value, sendEmail);
    setIsFetching(false);

    return (!resp.error) 
      ? onConfirm?.({ ...resp, transferredTo: selectedUser.label })
      : alert('There was an error transferring the folder. Please contact support');
  }

  const isOptionDisabled = opt => ownerId && opt.value === ownerId;

  const noButton = { label: 'Cancel', onClick: onClose };
  const yesButton = { label: 'Confirm', onClick: handleSubmit, primary: true, disabled: isFetching || !selectedUser };

  return (
    <Popup label="Transfer ownership of this folder" 
      onClose={onClose} 
      button1={noButton} 
      button2={yesButton} 
      id="transfer-folder-modal"
    >
      { isFetching && <LoadingOverlay /> }
      <UserLookup label="Select user" name="folder-transfer" onChange={onChangeUser} managedUsers={false} isOptionDisabled={isOptionDisabled} menuPosition="fixed" />
      <br />
      <Checkbox label="Send email update to new user" onChange={onChangeNotify} checked={sendEmail} />
    </Popup>  
  );
}

export default TransferFolderModal;
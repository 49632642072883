import React, { useContext } from 'react';

import { EmailLink, Icon } from 'Atoms';
import { Popup } from 'Molecules';
import UserContext from 'Context/UserContext.js';
import { formatDate } from 'Utils/formatter.js';
import { SUPPORT_EMAILS } from 'Utils/const.js';

import './OrderConfirmationModal.scss';

const OrderConfirmationModal = props => {
  const { timeOrdered, designType, onConfirm } = props;
  const user = useContext(UserContext);
  const button = { label: 'Back to Quote', onClick: onConfirm, primary: true };
  const displayDate = formatDate(timeOrdered, { month: 'long', day: 'numeric', year: 'numeric', hour:'numeric', minute: '2-digit', timeZoneName: 'short' });

  const PopupLabel = (
    <>
      <Icon name="checkmark-circle-outline"/>
      <span>Order submitted!</span>
    </>
  );

  let PopupMessage = <ConfirmationMessage displayDate={displayDate} />;

   if (user.isEcx) PopupMessage = <EcxConfirmationMessage displayDate={displayDate} designType={designType} />;
   else if (user.isPF) PopupMessage = <PacketFabricConfirmationMessage displayDate={displayDate} />;

  return (
    <Popup label={PopupLabel} onClose={onConfirm} button1={button} id="order-quote-confirmation-popup">
      { PopupMessage }
    </Popup>
  )
};

const ConfirmationMessage = ({ displayDate }) => (
  <>
    <p><b>Thank you for placing your order.</b></p>
    <p>Your order was placed on {displayDate}. You will receive an email confirmation of the order you submitted.</p>
    <p><b>What happens next?</b></p>
    <p>Additional technical details may be needed before your order can be fully processed. We will notify you when/if these details will be needed.</p>
    <p>If you have any questions, please contact us at: <EmailLink to="servicedelivery@unitasglobal.com" /></p>
  </>
);

const EcxConfirmationMessage = ({ displayDate, designType='' }) => {
  const docHref = designType.indexOf('ip-transit') > -1 
    ? 'https://docs.equinix.com/en-us/Content/Interconnection/NE/user-guide/NE-global-internet-access-Unitas.htm'
    : 'https://docs.equinix.com/en-us/Content/Interconnection/ECXF/ECXF-unitas-global.htm'

  return (
    <>
      <p><b>Thank you for placing your order.</b></p>
      <p>Your order was placed on {displayDate}. You will receive an email confirmation of the order you submitted.</p>
      <p><b>What happens next?</b></p>
      <p>
        To extend this connection over Equinix Fabric, you will need to return to the Equinix Fabric Portal and create a connection from your Equinix Fabric port or virtual device to the Unitas service profile.<br />
        <a href={docHref} target="_blank">Click here for detailed instructions</a>
      </p>
      <p><a className="button primary" href="https://fabric.equinix.com/">Return to Equinix Fabric Portal</a></p>
      <p>If you have any questions, please contact us at: <EmailLink to="EquinixFabricOrders@unitasglobal.com" /></p>
    </>
  );
}

const PacketFabricConfirmationMessage = ({ displayDate }) => {
  const docHref = 'https://docs.packetfabric.com/';

  return (
    <>
      <p><b>Thank you for placing your order.</b></p>
      <p>Your order was placed on {displayDate}. You will receive an email confirmation of the order you submitted.</p>
      <p><b>What happens next?</b></p>
      <p>
        To extend this connection over PacketFabric, you will need to return to the PacketFabric Portal and create a connection from your PacketFabric port or virtual device to the Unitas service profile.<br />
        <a href={docHref} target="_blank">Click here for detailed instructions</a>
      </p>
      <p><a className="button primary" href="https://packetfabric.com/">Return to PacketFabric Portal</a></p>
      <p>If you have any questions, please contact us at: <EmailLink to={SUPPORT_EMAILS.PACKETFABRIC} /></p>
    </>
  );
}

export default OrderConfirmationModal;